import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Modal, { ModalPropsType } from '../Modal/Modal';

type PropsType = ModalPropsType & {
  path: string;
  onClose?: () => void;
};

const RouterModal = (props: PropsType) => {
  const { ['*']: locationPath } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const [previousLocationPath, setPreviousLocationPath] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (locationPath !== props.path) {
      setPreviousLocationPath(location.pathname);
    }
    setShowModal(
      props.path.endsWith(locationPath ?? '##') && locationPath !== '',
    );
  }, [location, location.pathname, props.path]);

  const onClose = () => {
    if (previousLocationPath === location.pathname) {
      navigate(-1);
    } else {
      navigate(previousLocationPath);
    }
  };

  return (
    <Modal onClose={props.onClose || onClose} isOpen={showModal} {...props} />
  );
};

export default RouterModal;
