/* eslint react/prop-types: 0 */

import React from "react";
import { axiosFetch } from "../../hooks/useAxios";
import { useModal } from "contexts/index";

const DownloadLink = (props) => {
  const { showModal } = useModal();
  const handleClick = () => {
    axiosFetch(
      { url: props.href, responseType: "blob", withCredentials: true },
      null,
      false
    )
      .then((response) => {
        const fileName = response.headers["content-disposition"]
          ? response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0]
          : "filename_local";
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName.replace(/"/g, ""));
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(() =>
        showModal({
          title: "Beklager",
          infoText: "Det skjedde en feil",
        })
      );
  };

  return <a onClick={(event) => handleClick(event)}>{props.children}</a>;
};

export default DownloadLink;
