import { uiRecentUserNotificationsURL } from 'api/core/core_api';
import List from 'components/List/List';
import ListItem from 'components/ListItem/ListItem';
import Loader from 'components/Loader/Loader';
import { useSignalR } from 'contexts/index';
import {
  RecentUserNotificationDto,
  UserNotificationDto,
} from 'contexts/SignalRContext';
import useAxios from 'hooks/useAxios';
import moment from 'moment';
import { useEffect, useState } from 'react';

import NotificationsColumns from './NotificationColumns';

const NotificationsListSignalR = () => {
  const { sendRequest } = useAxios();
  const {
    notifications: signalRNotifications,
    setNotifications: setSignalRNotifications,
  } = useSignalR();

  const [recentNotifications, setRecentNotifications] =
    useState<RecentUserNotificationDto | null>(null);
  const [vms, setVms] = useState<any>();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [checkedPages, setCheckedPages] = useState<number[]>([]);
  const [note, setNote] = useState('');
  const [initialize, setInitialize] = useState(false);

  const handleAcknowledge = async (id) => {
    await sendRequest({
      method: 'PATCH',
      url: `${uiRecentUserNotificationsURL}/${id}`,
    });
  };

  const sortArray = (notifications: UserNotificationDto[]) => {
    // Sort by Created
    return notifications.sort((a, b) => {
      // Assuming Created is a Date string you can compare
      return (
        Date.parse(b.Created.toString()) - Date.parse(a.Created.toString())
      );
    });
  };

  const fetchNotifications = async (page) => {
    await sendRequest(
      {
        method: 'GET',
        url: uiRecentUserNotificationsURL,
        params: { skipCount: page * 10, takeCount: 10 },
      },
      (r: RecentUserNotificationDto) =>
        setRecentNotifications((prevState) => {
          if (prevState) {
            // Merge the arrays
            const mergedArray = [...prevState.Items, ...r.Items];

            // Create an object to store unique elements
            const uniqueById = {};

            // Filter out duplicates by Id
            const uniqueArray = mergedArray.filter((item) => {
              if (!uniqueById[item.Id]) {
                uniqueById[item.Id] = true;
                return true;
              }
              return false;
            });

            const updatedRecentNotifications: RecentUserNotificationDto = {
              ...prevState,
              Items: sortArray(uniqueArray),
            };

            return updatedRecentNotifications;
          }
          return r;
        }),
    ).finally(() => setInitialize(true));
  };

  useEffect(() => {
    const signalRNotificationIds = signalRNotifications.map((sn) => sn.Id);

    if (recentNotifications) {
      const removeSignalRNotifications = recentNotifications.Items.filter(
        (rn) => !signalRNotificationIds.includes(rn.Id),
      );

      const updatedRecentNotifications = {
        ...recentNotifications,
        Items: sortArray([
          ...removeSignalRNotifications,
          ...signalRNotifications,
        ]),
      };

      setRecentNotifications(updatedRecentNotifications);
    }
  }, [signalRNotifications]);

  useEffect(() => {
    if (recentNotifications) {
      setVms(
        recentNotifications.Items.map((n) => {
          return {
            New: n.Acknowledged ? '' : '🔴',
            Id: n.Id,
            Created: moment(n.Created).format('DD.MM.YYYY HH:mm '),
            Message: n.Message,
            Source: n.NotificationSource,
          };
        }),
      );
    }
  }, [recentNotifications]);

  useEffect(() => {
    const hash = window.location.hash;
    const queryString = hash.split('?')[1];
    const searchParams = new URLSearchParams(queryString);
    const n = searchParams.get('note');
    setNote(n?.length ? n : '');
  }, [window.location.hash]);

  useEffect(() => {
    if (!checkedPages.includes(pageNumber)) {
      setCheckedPages((prevState) => [...prevState, pageNumber]);
      fetchNotifications(pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    let timeoutId;

    if (vms && recentNotifications) {
      const ids = recentNotifications.Items.filter((n) => !n.Acknowledged).map(
        (n) => n.Id,
      );

      if (ids && ids.length > 0) {
        timeoutId = setTimeout(() => {
          Promise.all(ids.map((id) => handleAcknowledge(id)));
          setSignalRNotifications([]);
          recentNotifications.Items.map((rn) => {
            if (!rn.Acknowledged) {
              rn.Acknowledged = true;
            }
          });
        }, 5000);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [vms]);

  const renderItem = (item) => {
    return (
      <ListItem
        key={item.Id}
        columns={NotificationsColumns}
        item={item}
        highlight={note === item.Id}
      />
    );
  };

  return (
    <div>
      <Loader isLoading={!vms || !initialize}>
        {vms && recentNotifications && (
          <List
            columns={NotificationsColumns}
            renderItem={renderItem}
            items={vms}
            clientPaging={{
              pageNumber,
              pageCount,
              pageSize: recentNotifications.PageSize,
              numItems: recentNotifications.ItemsTotalCount,
              onChangePageNumber: (number) => setPageNumber(number),
              onSetPageCount: (number) => setPageCount(number),
            }}
            noItemsMessage={
              <p>
                <strong>Ingen varsler</strong>
              </p>
            }
          />
        )}
      </Loader>
    </div>
  );
};

export default NotificationsListSignalR;
