import { sortHousingAllocations } from "actions/housingAdminActions";
import List from "components/List/List";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { DeliberateAny } from "types/DelibrateAny";

import HousingAllocationsColumns from "./HousingAllocationsColumns";
import HousingAllocationsColumnsPreview from "./HousingAllocationsColumnsPreview";
import HousingAllocationsItem from "./HousingAllocationsItem";

type PropType = {
  items: DeliberateAny[];
  isPreview?: boolean;
};

const HousingAllocationsList: FC<PropType> = ({ items, isPreview }) => {
  const dispatch = useDispatch();

  const renderItem = (item) => (
    <HousingAllocationsItem
      key={item.ApplicationId}
      item={item}
      columns={
        isPreview ? HousingAllocationsColumnsPreview : HousingAllocationsColumns
      }
    />
  );
  return (
    <List
      columns={
        isPreview ? HousingAllocationsColumnsPreview : HousingAllocationsColumns
      }
      renderItem={renderItem}
      items={items}
      uncontrolledSortOptions={{
        sortColumnOnMount: "ApplicationState",
        sortDescending: true,
        onSortList: (sortedItems) =>
          dispatch(sortHousingAllocations(sortedItems)),
      }}
    />
  );
};
export default HousingAllocationsList;
