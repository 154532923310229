// @flow

import { call, put, select, take } from "redux-saga/effects";
import generateFormData from "helpers/fileHelpers";
import { END, eventChannel } from "redux-saga";
import {
  establishmentsURL,
  buildingsURL,
  registerQuestionURL,
} from "../api/basenproff/basenproff_api";
import { axiosFetch } from "../hooks/useAxios";

function createUploadFileChannel(formData, url) {
  return eventChannel((emitter) => {
    const onProgress = (e) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };

    axiosFetch({
      url,
      data: formData,
      method: "POST",
      onUploadProgress: onProgress,
    }).then(
      (data) => {
        emitter({ success: true, data });
        emitter(END);
      },
      (data) => {
        emitter({ err: true, data });
        emitter(END);
      }
    );

    return () => {};
  });
}

const getRegion = (state) => state.reporting.search.filter.region;
const getDistrict = (state) => state.reporting.search.filter.distrikt;

function* handleUpdateReportingFilter(action) {
  // action.value might be string or number
  if (action.value && action.value.toString().length > 0) {
    if (action.key === "distrikt" || action.key === "region") {
      yield put({ type: "FETCHING_REPORTING_FILTER_ESTABLISHMENTS" });
      const url = establishmentsURL;
      const query = { MarketingArea: action.value };
      try {
        const data = yield call(axiosFetch, { url, data: query });
        yield put({
          type: "RECEIVE_REPORTING_FILTER_ESTABLISHMENTS",
          value: data,
          key: action.key,
        });
      } catch (e) {
        yield put({ type: "SAGA_ERROR", e, url, query });
      }
    } else if (action.key === "establishment") {
      yield put({ type: "FETCHING_REPORTING_FILTER_BUILDINGS" });
      let district = yield select(getDistrict);
      if (!district) district = yield select(getRegion);

      const url = buildingsURL;
      const query = { MarketingArea: district, Establishment: action.value };
      try {
        const data = yield call(axiosFetch, { url, data: query });
        yield put({
          type: "RECEIVE_REPORTING_FILTER_BUILDINGS",
          value: data,
          key: action.key,
        });
      } catch (e) {
        yield put({ type: "SAGA_ERROR", e, url, query });
      }
    }
  }
}

function* handleSendReporting(action) {
  const payload = action.formHolder
    ? { serviceOrder: action.formHolder }
    : yield select((state) => ({ serviceOrder: state.reporting.reporting }));

  const url = action.url ? action.url : registerQuestionURL;
  //  query = { serviceOrder: query };
  const files = yield select((state) => state.reporting.files);
  // const url = registerSOURL;

  const formData = generateFormData(
    payload.serviceOrder,
    files,
    "serviceOrder",
    "Documents"
  );
  const channel = yield call(createUploadFileChannel, formData, url);
  while (true) {
    const response = yield take(channel);

    if (response) {
      const { progress = 0, err, success } = response;
      if (err) {
        yield put({
          type: "SAGA_ERROR_REPORTING_ORDER",
          e: response.data,
          url,
          query: payload,
        });
        return;
      }
      if (success) {
        yield put({ type: "SEND_REPORTING_SUCCESS" });
        return;
      }
      yield put({ type: "PROGRESS_CHANGED", percentComplete: progress });
    }
  }
}

export { handleSendReporting, handleUpdateReportingFilter };
