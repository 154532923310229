import Slide from "components/Slides/Slide";
import { HousingQuarter } from "contexts/HousingContext";
import { useHousing, useSlides, useUser } from "contexts/index";

import {
  housing,
  housingTitle,
  paths,
  quarter,
  quarterTitle,
} from "../HousingSection";
import PersonalInfo from "../PersonalInfo";

const PersonalInfoSlide = ({ appType }) => {
  const { isLoading, user } = useUser();
  const { applicationType } = useHousing();
  const { goToSlide } = useSlides();

  return (
    <Slide
      path={`${appType === HousingQuarter.Housing ? housing : quarter}${
        paths.personalInfo
      }`}
      title={appType === HousingQuarter.Housing ? housingTitle : quarterTitle}
      loading={isLoading}
      showCount
    >
      <PersonalInfo
        goToSlide={goToSlide}
        applicationType={applicationType}
        userData={user}
        nextSlidePath={`${
          appType === HousingQuarter.Housing ? housing : quarter
        }${
          appType === HousingQuarter.Housing
            ? paths.familySituation
            : paths.livingCondition
        }`}
        defaultTo={`${appType === HousingQuarter.Housing ? housing : quarter}${
          paths.applicantCategory
        }`}
      />
    </Slide>
  );
};

export default PersonalInfoSlide;
