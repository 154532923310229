 
import DOMPurify from "dompurify";
import useFetch from "hooks/useFetch";
import { getInformation } from "api/cms/cms_api";
import Loader from "components/Loader/Loader";
import "./Information.scss";

const InformationPage = () => {
  const { isLoading, data: information } = useFetch<string | Node>(
    getInformation
  );

  return (
    <Loader isLoading={isLoading}>
      {information && (
        <span
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(information) }}
        />
      )}
    </Loader>
  );
};

export default InformationPage;
