import './RentalColumns.scss';

const rentalColumns = [
  { label: 'Leie-ID', name: 'RentalId', className: 'rental-id' },
  { label: 'Navn på bygg', name: 'BuildingName', className: 'building-name' },
  { label: 'Adresse', name: 'Address', className: 'address' },
  { label: 'Boområde', name: 'HousingArea', className: 'housing-area' },
  { label: 'Kontraktstatus', name: 'ContractStatus', className: 'contract-status' },
];

export default rentalColumns;
