import NumberInput from 'components/NumberInput/NumberInput';
import SafeHtmlRenderer from 'components/SafeHtmlRenderer';
import { useState } from 'react';
import ReactImageGallery from 'react-image-gallery';

const RoomFacilities = ({ room, numberOfRooms, handleChange }) => {
  const [description, setDescription] = useState(
    room.RoomImages.length > 0
      ? room.RoomImages[0].Description
      : 'Bilde mangler',
  );
  const [imageIndex, setImageIndex] = useState(0);

  const renderRoomImages = () => {
    const onSlide = (index) => {
      setImageIndex(index);
      setDescription(room.RoomImages[index].Description);
    };
    if (room.RoomImages.length > 0) {
      return (
        <div className="photo">
          <ReactImageGallery
            items={room.RoomImages.map((image) => ({
              original: image.Url,
              originalAlt: image.AltText,
            }))}
            showPlayButton={false}
            showThumbnails={false}
            showFullscreenButton={false}
            startIndex={imageIndex}
            onSlide={onSlide}
          />
          <p style={{ textAlign: 'center' }}>{description}</p>
        </div>
      );
    }
    return (
      <div className="photo">
        <img src="/assets/img/room_no_image.png" alt="" />
        <p>Bilde mangler</p>
      </div>
    );
  };

  return (
    <div className="room-description wrapper-grey flex-row defined-widths">
      <div className="room-info">
        <div className="room-type margin">
          {room.RoomIcon && (
            <img
              alt="room-icon"
              className="room-icon"
              src={room.RoomIcon.Url}
            />
          )}
          <h3>{room.Name}</h3>
          {room.PriceList.length > 0 && (
            <div>
              <span className="room-price red">
                {' '}
                {`${Math.round(
                  room.PriceList.find((price) => price.Persons === 1)
                    .PriceValue,
                )},-`}
              </span>
              <div>
                <span className="room-price red">(for valgt periode)</span>
              </div>
            </div>
          )}
        </div>
        {room.AvailableCount || numberOfRooms ? (
          <NumberInput
            name={room.Name}
            title={`Antall rom (${room.AvailableCount} ledige)`}
            value={numberOfRooms}
            minValue={0}
            maxValue={room.AvailableCount}
            small
            onChange={handleChange}
          />
        ) : (
          <div className="room-count">
            <p style={{ fontStyle: 'italic' }}>
              Ingen tilgjenglige rom <br /> valgt dato.
            </p>
          </div>
        )}
      </div>
      <div className="room-facilities">
        <h3>På rommet</h3>
        <SafeHtmlRenderer response={unescape(room.RoomFacilities)} />
      </div>
      <div className="room-images">
        <div className="img-wrapper">
          <div className="carousel">
            {/* <ul className="bxslider"> */}
            {renderRoomImages()}
            {/* </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoomFacilities;
