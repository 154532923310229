import './LabelValueList.scss';

import { Fragment, ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { DeliberateAny } from '../../types/DelibrateAny';

export type LabelValueListItemType = {
  label: DeliberateAny;
  value:
    | number
    | string
    | ReactElement
    | readonly { list: LabelValueListItemType }[];
  suffix?: string;
};

type PropsType = {
  items: Array<DeliberateAny>;
  style?: {
    color?: string;
    border?: boolean;
    padding?: boolean;
    receiptStyle?: boolean;
    styleInHalf?: boolean;
    headerBreakLine?: boolean;
  };
};

const multilines = (_text: DeliberateAny) => {
  try {
    let text = '';
    if (_text.toString().indexOf('<br>') > -1) {
      text = _text.replace(/<br>/g, '<br/>');
    } else {
      text = _text;
    }
    if (text.toString().indexOf('<br/>') === -1) return text;
    const newText = text.split('<br/>').map((item) =>
      item.length > 2 ? (
        <Fragment key={uuidv4()}>
          <p>{item}</p>
        </Fragment>
      ) : (
        <Fragment key={uuidv4()}>
          <br />
        </Fragment>
      ),
    );
    return newText;
  } catch (error: DeliberateAny) {
    return <p>{error.toString()}</p>;
  }
};

const renderItem = (item: LabelValueListItemType, index: number) => {
  if (!item.label || item.label === 0) return null;
  if (!item.value || item.value === 0) return null;
  return (
    <li key={uuidv4()}>
      <span className="label">{item.label.toString()}:</span>
      <span className="value">
        {multilines(item.value)}
        {item.suffix && ` ${item.suffix}`}
      </span>
    </li>
  );
};

const renderSubList = (label: string, value: DeliberateAny) => (
  <li className="sub-list" key={label}>
    <span className="header label">{label}:</span>
    <ul>
      {value.map((val: DeliberateAny) =>
        val.list.map((listItem: DeliberateAny, index: number) => {
          return renderItem(listItem, index);
        }),
      )}
    </ul>
  </li>
);

const LabelValueList = (props: PropsType) => (
  <div
    className={` \
            label-value-list \
            ${props.style && props.style.color ? props.style.color : ''} \
            ${props.style && props.style.border ? 'border' : ''} \
            ${props.style && props.style.padding ? 'padding' : ''} \
            ${
              props.style && props.style.receiptStyle
                ? 'receipt-style border padding'
                : ''
            } \
            ${props.style && props.style.styleInHalf ? 'list-half-width' : ''} \
            ${
              props.style && props.style.headerBreakLine
                ? 'header-break-line receipt-style'
                : ''
            } \
          `}
  >
    <ul className="label-value-list">
      {props.items.map((item, index) =>
        Array.isArray(item.value)
          ? renderSubList(item.label, item.value)
          : renderItem(item, index),
      )}
    </ul>
  </div>
);

export default LabelValueList;
