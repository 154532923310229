import './TabMyProfile.scss';

import {
  confirmTwoFactorURL,
  updatePasswordURL,
  updateProfileURL,
  verifyEmailURL,
} from 'api/cms/cms_api';
import HelpText from 'components/HelpText/HelpText';
import { useModal, useUser } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import UserNotificationPreference from '../../../components/UserNotificationPreference/UserNotificationPreference';

const TabMyProfile = ({ userData }) => {
  const { showModal } = useModal();
  const { fetchUser } = useUser();
  const { sendRequest } = useAxios();
  const [username, setUsername] = useState(userData.Username);
  const [firstName, setFirstName] = useState(userData.FirstName);
  const [lastName, setLastName] = useState(userData.LastName);
  const [email, setEmail] = useState(userData.Email);
  const [secondaryEmail, setSecondaryEmail] = useState(userData.SecondaryEmail);
  const [countryCode, setCountryCode] = useState(userData.CountryCode);
  const [phone, setPhone] = useState(userData.Phone);
  const [employeeNo, setEmployeeNo] = useState(userData.EmployeeNumber);
  const [receiveHousingSms] = useState(userData.ReceiveHousingSms === 'true');
  const [profilePassword, setProfilePassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [hiddenSingleUseCode, setHiddenSingleUseCode] = useState(true);
  const [singleUseCode, setSingleUseCode] = useState('');
  const [validPasswordField, setValidPasswordField] = useState({
    password: true,
    passwordRepeat: true,
    oldPassword: true,
  });
  const [validProfileField, setValidProfileField] = useState({
    username: true,
    firstName: true,
    lastName: true,
    email: true,
    secondaryEmail: true,
    countryCode: true,
    phone: true,
    profilePassword: true,
  });
  const [invalidClassName, setInvalidClassName] = useState({
    firstName: '',
    lastName: '',
    email: '',
    secondaryEmail: '',
    phone: '',
    profilePassword: '',
    password: '',
    passwordRepeat: '',
    oldPassword: '',
  });

  const verifyUserEmail = {
    username,
    primaryemail: email,
  };

  const userProfile = {
    username,
    firstname: firstName,
    lastname: lastName,
    primaryemail: email,
    secondaryemail: secondaryEmail,
    countrycode: countryCode,
    phone,
    employeenumber: employeeNo,
    profilePassword,
    receiveHousingSms,
  };

  const userPassword = {
    username,
    oldPassword,
    password,
    passwordRepeat: repeatPassword,
  };

  const [sessionToken, setSessionToken] = useState('');

  const validateProfileUpdate = () => {
    const obj = { ...validProfileField };
    obj.firstName =
      /^[a-zA-ZøØåÅæÆéÉèÈÜüöÖäÄôÔÎîìÌëË -.]+$/.test(firstName) &&
      firstName.length > 0;
    obj.lastName =
      /^[a-zA-ZøØåÅæÆéÉèÈÜüöÖäÄôÔÎîìÌëË -.]+$/.test(lastName) &&
      lastName.length > 0;

    const emailPattern =
      /^[\w+-]+(\.[\w+-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;

    obj.email = emailPattern.test(email);
    obj.secondaryEmail =
      userData.IsGuardOnlyUser === 'True' || emailPattern.test(secondaryEmail);

    obj.countryCode = /^(\+?\d{1,3}|\d{1,4})$/.test(countryCode);
    obj.phone = /^(?=.*\d)(?:[\d ]+)$/.test(phone) && phone.length >= 8;
    obj.profilePassword =
      /[a-zæøå]/g.test(profilePassword) &&
      /[A-ZÆØÅ]/g.test(profilePassword) &&
      /[0-9]/g.test(profilePassword) &&
      profilePassword.length >= 8;

    setValidProfileField(obj);

    const classNames = { ...invalidClassName };
    if (firstName.length > 0) {
      classNames.firstName = 'invalid-text invalid';
    } else {
      classNames.firstName = 'invalid';
    }
    if (lastName.length > 0) {
      classNames.lastName = 'invalid-text invalid';
    } else {
      classNames.lastName = 'invalid';
    }
    if (email.length > 0) {
      classNames.email = 'invalid-text invalid';
    } else {
      classNames.email = 'invalid';
    }
    if (secondaryEmail.length > 0) {
      classNames.secondaryEmail = 'invalid-text invalid';
    } else {
      classNames.secondaryEmail = 'invalid';
    }
    if (phone.length > 0) {
      classNames.phone = 'invalid-text invalid';
    } else {
      classNames.phone = 'invalid';
    }
    if (profilePassword.length > 0) {
      classNames.profilePassword = 'invalid-text invalid';
    } else {
      classNames.profilePassword = 'invalid';
    }
    setInvalidClassName(classNames);

    return (
      obj.username &&
      obj.firstName &&
      obj.lastName &&
      obj.email &&
      obj.secondaryEmail &&
      obj.countryCode &&
      obj.phone &&
      obj.profilePassword
    );
  };

  const validatePasswordUpdate = () => {
    const obj = { ...validPasswordField };

    obj.oldPassword =
      /[a-zæøå]/g.test(oldPassword) &&
      /[A-ZÆØÅ]/g.test(oldPassword) &&
      /[0-9]/g.test(oldPassword) &&
      oldPassword.length >= 8;

    obj.password =
      /[a-zæøå]/g.test(password) &&
      /[A-ZÆØÅ]/g.test(password) &&
      /[0-9]/g.test(password) &&
      password.length >= 8;
    obj.passwordRepeat = repeatPassword === password;

    setValidPasswordField(obj);
    const classNames = { ...invalidClassName };
    if (oldPassword.length > 0) {
      classNames.oldPassword = 'invalid-text invalid';
    } else {
      classNames.oldPassword = 'invalid';
    }
    if (password.length > 0) {
      classNames.password = 'invalid-text invalid';
    } else {
      classNames.password = 'invalid';
    }
    if (repeatPassword.length > 0) {
      classNames.passwordRepeat = 'invalid-text invalid';
    } else {
      classNames.passwordRepeat = 'invalid';
    }

    setInvalidClassName(classNames);

    return obj.oldPassword && obj.password && obj.passwordRepeat;
  };

  const updateUserProfile = async () => {
    await sendRequest(
      {
        method: 'PUT',
        data: userProfile,
        url: updateProfileURL,
        withCredentials: true,
      },
      fetchUser,
    );
  };

  const updateUserPassword = async () => {
    await sendRequest(
      {
        method: 'POST',
        data: userPassword,
        url: updatePasswordURL,
        withCredentials: true,
      },
      fetchUser,
    );
  };

  const verifyEmail = async () => {
    await sendRequest(
      {
        method: 'POST',
        url: verifyEmailURL,
        data: {
          profileData: verifyUserEmail,
          primaryEmail: verifyUserEmail.primaryemail,
        },
      },
      (response: DeliberateAny) => {
        if (response) {
          setHiddenSingleUseCode(false);
          setSessionToken(response.SessionToken);
          showModal({
            title: response.Title,
            infoText: response.Text,
            show: true,
          });
        }
      },
      null,
      (error) => {
        if (error) {
          showModal({
            title: '',
            infoText: error.response.data,
            show: true,
          });
        }
      },
    );
  };

  const handleProfileUpdateSubmit = () => {
    setRepeatPassword('');
    setPassword('');
    setOldPassword('');
    const profileUpdateIsValid = validateProfileUpdate();
    if (profileUpdateIsValid) {
      void updateUserProfile();
      setProfilePassword('');
    }
  };

  const handlePasswordUpdateSubmit = () => {
    const passwordUpdateIsValid = validatePasswordUpdate();
    if (passwordUpdateIsValid) {
      void updateUserPassword();
      setRepeatPassword('');
      setPassword('');
      setOldPassword('');
    }
  };

  const confirmTwoFactor = async () => {
    await sendRequest(
      {
        method: 'POST',
        url: confirmTwoFactorURL,
        data: {
          twofactkey: singleUseCode,
          sessionId: sessionToken,
        },
      },
      () => {
        fetchUser();
        showModal({
          title: 'Ny epost er verifisert',
          infoText:
            'Ny epost er verifisert som godkjent Forsvarsdomene og brukeren din er blitt autentisert.',
          show: true,
        });
      },
    );
  };

  return (
    <div style={{ padding: '22px 0 0 0', maxWidth: '800px' }}>
      <h2>Kontaktinformasjon</h2>
      <div className="content-group grey padded">
        <form autoComplete="off">
          <div className="flex-row margin">
            <label
              className={
                validProfileField.firstName ? '' : invalidClassName.firstName
              }
            >
              <span>Fornavn</span>
              <input
                type="text"
                name="firstname"
                value={firstName}
                data-testid="mypage-input-firstname"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </label>
            <label
              className={
                validProfileField.lastName ? '' : invalidClassName.lastName
              }
            >
              <span>Etternavn</span>
              <input
                type="text"
                name="lastname"
                data-testid="mypage-input-lastname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </label>
          </div>
          <div className="flex-row margin">
            <label className={validProfileField.email ? '' : 'invalid email'}>
              <HelpText
                helpText="På denne adressen vil du også få tilsendt
                dine reservasjoner
                og kvitteringer."
              >
                <div>Primær e-post</div>
              </HelpText>
              <input
                type="text"
                name="email"
                data-testid="mypage-input-email"
                value={email || ' '}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            {userData.IsValidatedUser !== 'True' && (
              <label>
                <span>&nbsp;</span>
                <input
                  type="button"
                  onClick={verifyEmail}
                  className="btn red"
                  value="Valider epost"
                  style={{ width: '100%' }}
                />
              </label>
            )}
          </div>
          <div className={hiddenSingleUseCode ? 'hidden' : ''}>
            <div className="flex-row margin">
              <label>
                <HelpText helpText="Fyll inn engangskode sendt på mail">
                  <div>Engangskode</div>
                </HelpText>
                <input
                  type="text"
                  name="singleUseCode"
                  value={singleUseCode}
                  onChange={(e) => setSingleUseCode(e.target.value)}
                />
              </label>
              <label>
                <span>&nbsp;</span>
                <input
                  type="button"
                  onClick={confirmTwoFactor}
                  className="btn red"
                  value="Bekreft engangskode"
                  style={{ width: '100%' }}
                />
              </label>
            </div>
          </div>
          <div className={userData.IsGuardOnlyUser === 'True' ? 'hidden' : ''}>
            <div className="row margin">
              <label
                className={
                  validProfileField.secondaryEmail
                    ? ''
                    : invalidClassName.secondaryEmail
                }
              >
                <HelpText
                  helpText="På denne adressen vil du også få tilsendt
                dine reservasjoner
                og kvitteringer."
                >
                  <div>Sekundær e-post</div>
                </HelpText>
                <input
                  type="text"
                  name="secondaryemail"
                  data-testid="mypage-input-secondaryEmail"
                  value={secondaryEmail}
                  onChange={(e) => setSecondaryEmail(e.target.value)}
                />
              </label>
            </div>
          </div>
          <div className="flex-row margin defined-widths">
            <label
              className={
                validProfileField.countryCode
                  ? 'third'
                  : 'third invalid-text invalid'
              }
            >
              <span>Landskode</span>
              <input
                type="text"
                name="countrycode"
                max="4"
                data-testid="mypage-input-countrycode"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
              />
            </label>
            <label
              className={
                validProfileField.phone
                  ? 'two-thirds'
                  : 'two-thirds' + invalidClassName.phone
              }
            >
              <span>Mobilnummer</span>
              <input
                type="text"
                name="phone"
                min="8"
                max="24"
                value={phone}
                data-testid="mypage-input-phone"
                onChange={(e) => setPhone(e.target.value)}
              />
            </label>
          </div>
          <div className="flex-row margin defined-widths">
            <label className="half">
              <span>Ansattnummer</span>
              <input
                type="text"
                name="employeenumber"
                disabled
                max="24"
                data-testid="mypage-input-employeeNumber"
                value={employeeNo}
                onChange={(e) => setEmployeeNo(e.target.value)}
              />
            </label>
          </div>

          <div className="flex-row margin">
            <label
              className={
                validProfileField.profilePassword
                  ? ''
                  : invalidClassName.profilePassword
              }
            >
              <span>
                Passord{' '}
                {!validProfileField.profilePassword ? 'er obligatorisk' : ''}
              </span>
              <input
                type="password"
                name="profilePassword"
                value={profilePassword}
                data-testid="mypage-input-password"
                onChange={(e) => setProfilePassword(e.target.value)}
              />
            </label>
          </div>
        </form>
        <input
          type="button"
          onClick={handleProfileUpdateSubmit}
          className="btn red"
          value="Lagre profil"
          data-testid="mypage-button-save"
        />
      </div>
      <div className="divider" />
      <h2>Bytt passord</h2>
      <div className="content-group grey padded row margin">
        <div className="row margin">
          <label>
            <span className="inline-helper-wrapper">Brukernavn</span>
            <input
              type="text"
              name="username"
              disabled
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
        </div>
        <div className="row margin">
          <label
            className={
              validPasswordField.oldPassword ? '' : invalidClassName.oldPassword
            }
          >
            <span>Nåværende passord</span>
            <input
              type="password"
              name="oldPassword"
              value={oldPassword}
              data-testid="mypage-change-password-old"
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </label>
        </div>
        <div className="row margin">
          <label
            className={
              validPasswordField.password ? '' : invalidClassName.password
            }
          >
            <span>Nytt passord</span>
            <input
              type="password"
              name="password"
              value={password}
              data-testid="mypage-change-password-new"
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        </div>
        <div className="row margin">
          <label
            className={
              validPasswordField.passwordRepeat
                ? ''
                : invalidClassName.passwordRepeat
            }
          >
            <span>Gjenta nytt passord</span>
            <input
              type="password"
              name="passwordRepeat"
              value={repeatPassword}
              data-testid="mypage-change-password-new-repeat"
              onChange={(e) => setRepeatPassword(e.target.value)}
            />
          </label>
        </div>
        <p className="margin">
          Passordet må ha minst 8 tegn. Passordet må bestå av både små og store
          bokstaver fra A-Å og minst et tall.
        </p>
        <input
          type="button"
          onClick={handlePasswordUpdateSubmit}
          className="btn red"
          value="Lagre passord"
          data-testid="mypage-change-password-submit"
        />
      </div>
      <div className="divider" />
      <UserNotificationPreference />
    </div>
  );
};

export default TabMyProfile;

TabMyProfile.propTypes = {
  userData: PropTypes.objectOf(PropTypes.string),
};
