import {
  createUserURL,
  getEtatData,
  getTempUserInfo,
  registrerURL,
  sendTwoFactorCodeURL,
} from 'api/cms/cms_api';
import { postalCodeLookup } from 'api/core/core_api';
import Button from 'components/buttons/Button/Button';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Select from 'components/form/Select/Select';
import SafeHtmlRenderer from 'components/SafeHtmlRenderer';
import { useLogin, useUser } from 'contexts/index';
import { LoginPath } from 'contexts/LoginContext';
import { ConfirmUserProfileDto } from 'contexts/UserContext';
import env from 'env';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { DeliberateAny } from 'types/DelibrateAny';

import BackButton from './BackButton';

const NewUser = () => {
  const { setPath, path, actionKey, setShow } = useLogin();
  const { user, updateUser, hasUser } = useUser();
  const { sendRequest } = useAxios();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  // SETUP
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [employeeNumber, setEmployeeNumber] = useState('');

  const [secondaryEmail, setSecondaryEmail] = useState('');

  const [dateOfBirth, setDateOfBirth] = useState('');

  const [currentHousingAddress, setCurrentHousingAddress] = useState('');

  const [currentHousingZipCode, setCurrentHousingZipCode] = useState('');
  const [currentHousingCity, setCurrentHousingCity] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  useDebounce(() => setDebouncedValue(currentHousingZipCode), 1000, [
    currentHousingZipCode,
  ]);

  const [countryCode, setCountryCode] = useState('+47');
  const [phone, setPhone] = useState('');

  const [twoFactKey, setTwoFactKey] = useState('');

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [consentConfirmed, setConsentConfirmed] = useState(false);

  const [etat, setEtat] = useState<string>();
  const [etatOptions, setEtatOptions] = useState<DeliberateAny>([]);

  const [errors, setErrors] = useState<DeliberateAny>({});
  const [response, setResponse] = useState<string>();

  const [isBasic, setIsBasic] = useState<boolean>();
  const [userName, setUserName] = useState<string>();

  const lookupPostalcode = async () => {
    if (currentHousingZipCode.length !== 4) return;

    setCurrentHousingCity('');
    await sendRequest(
      {
        method: 'GET',
        url: postalCodeLookup + '?postalCode=' + currentHousingZipCode,
        withCredentials: true,
      },
      (res: DeliberateAny) => {
        setCurrentHousingCity(res.PostalLocation);
      },
    );
  };

  const fetchEtatOptions = async () => {
    setCurrentHousingCity('');
    await sendRequest(
      {
        method: 'GET',
        url: getEtatData,
      },
      (res) => {
        setEtatOptions(res);
      },
    );
  };

  const sendTwoFactorCode = async () => {
    if (!countryCode || !phone) return;

    function validatePhone() {
      const tempErrors: DeliberateAny = {};
      // Country code (optional check for numeric and up to 6 digits)
      if (!countryCode.match(/^\+?\d{1,6}$/)) {
        tempErrors.countryCode = 'Landskode kan kun være opptil 6 siffer';
      }

      // Phone (assuming it should be 8 to 10 digits)
      if (!phone.match(/^\d{8,10}$/)) {
        tempErrors.phone = 'Mobilnummer må være mellom 8 og 10 siffer';
      }

      setErrors(tempErrors);
      if (tempErrors.countryCode || tempErrors.phone) {
        return false;
      }
      return true;
    }
    if (!validatePhone()) return;
    await sendRequest(
      {
        method: 'POST',
        url: sendTwoFactorCodeURL,
        data: {
          CountryCode: countryCode,
          Phone: phone,
        },
      },
      (res: DeliberateAny) => {
        if (res) setTwoFactKey(res);
      },
    );
  };

  const fetchTempUserInfo = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: getTempUserInfo,
      },
      (res) => {
        setIsBasic(res.IsBasic);
        setEmail(res.Email);
      },
    );
  };
  useEffect(() => {
    sendTwoFactorCode();
    fetchEtatOptions();
    if (path === LoginPath.setup) {
      fetchTempUserInfo();
    }
  }, []);

  useEffect(() => {
    lookupPostalcode();
  }, [debouncedValue]);

  const returnToRoot = async () => {
    window.location.href = '/#';
    window.location.reload();
  };

  const handleNewUser = async () => {
    function validateEmail(email: string): boolean {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(email);
    }

    if (!validateEmail(email)) {
      setEmailError('Ugyldig epost!');
      return;
    }

    const fullEmail = email;
    setResponse('');
    await sendRequest(
      {
        url: registrerURL,
        method: 'POST',
        data: { FullEmail: fullEmail, Html: false, LocalUrl: env['LOCAL_URL'] },
      },
      () => {
        setEmail('');
        setPath(LoginPath.requestSent);
      },
      null,
      (error) => setResponse(error.response.data),
    );
  };

  const validateFields = () => {
    const tempErrors: DeliberateAny = {};
    // Username is readonly, no need to validate

    // Validate first name (must not be empty)
    if (!firstName.trim()) {
      tempErrors.firstName = 'Fornavn må fylles ut';
    }

    // Validate last name (must not be empty)
    if (!lastName.trim()) {
      tempErrors.lastName = 'Etternavn må fylles ut';
    }

    if (isBasic && !etat) {
      tempErrors.etat = 'Velg etat';
    }

    // Validate email (simple regex for email validation)
    if (!secondaryEmail.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      tempErrors.secondaryEmail = 'E-post må fylles ut og være gyldig';
    }

    // Employee number (assuming it should not be empty)
    if (!employeeNumber.trim().match(/^[0-9]{1,6}$/)) {
      tempErrors.employeeNumber = 'Ansattnummer er ikke riktig format';
    }

    // Date of birth (simple date check)
    if (
      path !== LoginPath.update &&
      !(dateOfBirth?.match(/^\d{2}\.\d{2}\.\d{4}$/) ?? false)
    ) {
      tempErrors.dateOfBirth = 'Fødselsdato må være i formatet DD.MM.ÅÅÅÅ';
    }

    // Current housing address (assuming it should not be empty)
    if (path !== LoginPath.update && !currentHousingAddress.trim()) {
      tempErrors.currentHousingAddress = 'Postadresse må fylles ut';
    }

    // Current housing zip code (must be exactly 4 digits)
    if (path !== LoginPath.update && !currentHousingZipCode.match(/^\d{4}$/)) {
      tempErrors.currentHousingZipCode = 'Postnummer er 4 siffer';
    }

    // Country code (optional check for numeric and up to 6 digits)
    if (countryCode && !countryCode.match(/^\+?\d{1,6}$/)) {
      tempErrors.countryCode = 'Landskode kan kun være opptil 6 siffer';
    }

    // Phone (assuming it should be 8 to 10 digits)
    if (!phone.match(/^\d{8,10}$/)) {
      tempErrors.phone = 'Mobilnummer må være mellom 8 og 10 siffer';
    }

    // Two-factor authentication key (must be exactly 6 digits)
    if (
      path !== LoginPath.update &&
      !twoFactKey
        .toString()
        .trim()
        .match(/^\d{6}$/)
    ) {
      tempErrors.twoFactKey = 'Engangskode fra SMS må være 6 siffer';
    }

    // Password validation (at least 8 characters, at least one number, one uppercase letter, and one lowercase letter)
    if (
      path !== LoginPath.update &&
      !password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
    ) {
      tempErrors.password =
        'Passordet må ha minst 8 tegn og inkludere små og store bokstaver samt minst ett tall';
    }

    // Check if password and password repeat are identical
    if (path !== LoginPath.update && password !== passwordRepeat) {
      tempErrors.passwordRepeat = 'Passordene stemmer ikke overens';
    }

    // Consent checkbox validation
    if (!consentConfirmed) {
      tempErrors.consentConfirmed =
        'Du må godkjenne lagring av personopplysninger';
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // returns true if no errors
  };

  const handleSubmitConsent = async () => {
    const result = await updateUser({
      ...user,
      ...{
        HasConsentedStoringPersonalData: consentConfirmed,
        PrimaryEmail: user.Email,
        FirstName: firstName,
        LastName: lastName,
        CountryCode: countryCode,
        Phone: phone,
      },
    } as ConfirmUserProfileDto);

    if (!result) setErrors('jfjfjfj');
    setShow(false);
    setPath(undefined);
  };

  const handleSubmit = async () => {
    setResponse('');
    if (!validateFields()) return;
    if (path === LoginPath.update) handleSubmitConsent();
    else
      await sendRequest(
        {
          url: createUserURL,
          method: 'POST',
          data: {
            ActionKey: actionKey,
            CountryCode: countryCode,
            EmployeeNumber: employeeNumber,
            FirstName: firstName,
            LastName: lastName,
            HasConsentedStoringPersonalData: consentConfirmed,
            Password: password,
            PasswordRepeat: passwordRepeat,
            SecondaryEmail: secondaryEmail,
            TwoFactFailureCount: 0,
            TwoFactKey: twoFactKey,
            Phone: phone,
            Etat: etat,
          },
        },
        (res) => {
          setUserName(res.NewUserName);
          setPath(LoginPath.registerSuccess);
        },
        null,
        (error) => setResponse(error.response.data),
      );
  };

  const addUserToForm = () => {
    setFirstName(user.FirstName);
    setLastName(user.LastName);
    setEmployeeNumber(user.EmployeeNumber);
    setEmail(user.Email);
    setSecondaryEmail(user.SecondaryEmail);
    setDateOfBirth(user.DateOfBirth);
    setCountryCode(user.CountryCode);
    setPhone(user.Phone);
  };

  useEffect(() => {
    if (hasUser && path === LoginPath.update) addUserToForm();
  }, [hasUser, path]);

  return (
    <>
      {path === LoginPath.register && (
        <div data-flip-id="new-user" className="flip-card-front scrollable">
          <BackButton />
          <h2>Ny bruker</h2>
          <div className="form">
            <div className="row trailer flex-row">
              <label className={emailError ? 'invalid' : ''}>
                <span>
                  {emailError} Dersom du har en epostadresse utstedt av
                  forsvarssektoren, benytt denne
                </span>
                <input
                  type="text"
                  placeholder="Eks: jonas.svendsen@forsvarssektor.com"
                  id="fullEmail"
                  name="fullEmail"
                  data-validators="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError('');
                  }}
                />
              </label>
            </div>
          </div>

          <div className="row trailer">
            <button
              disabled={!email || !!emailError}
              className={!email || !!emailError ? 'gray btn' : 'btn red'}
              onClick={handleNewUser}
            >
              Fortsett
            </button>
          </div>

          <div
            className="form-message invalid"
            style={{ fontSize: 20, fontWeight: 800 }}
          >
            <SafeHtmlRenderer response={response ? response : ''} />
          </div>

          <div className="modal-servicecenter">
            <p>
              <strong>Forsvarsbygg servicesenter</strong>
              <br />
              <strong className="red">468 70 400</strong>
            </p>
          </div>
        </div>
      )}
      {path === LoginPath.requestSent && (
        <div className="flip-card-front scrollable">
          <BackButton />
          <div className="confirm">
            <h2>Forespørselen er sendt!</h2>
            <div className="row">
              <p className="red">
                <strong>
                  Du vil om kort tid motta en e-post sendt til følgende
                  e-postadresse:
                </strong>
              </p>
              <p>
                <strong className="registered-email"></strong>
              </p>
              <p>
                Der vil du finne en lenke du må trykke på for å fullføre
                registreringen.
              </p>
            </div>
          </div>
        </div>
      )}

      {(path === LoginPath.setup || path === LoginPath.update) && (
        <div data-flip-id="setup-user" className="flip-card-front scrollable">
          {/* <!--<a href="#" className="js-flip-back flip-back icon-arrow-left">Tilbake</a>--> */}
          <BackButton close={path === LoginPath.update} />

          <h2>
            {path === LoginPath.update
              ? 'Bekreft din kontaktinformasjon'
              : 'Opprett bruker'}
          </h2>

          {path === LoginPath.update && (
            <p>
              {user.HasConsentedStoringPersonalData === 'True'
                ? 'Forsvarsbygg har oppdatert vilkår og personvernerklæring for bruk av Basen. Vennligst les denne og bekreft under.'
                : user.ProfileCheck === 'True'
                  ? 'Det er ett år siden sist du oppdaterte profilen din. Stemmer din kontaktinformasjon fremdeles?'
                  : ''}
            </p>
          )}

          <div className="form">
            <div className="flex-row margin defined-widths">
              <div className="half no-margin">
                <label>
                  {errors.firstName ? (
                    <span className="invalid">{errors.firstName}</span>
                  ) : (
                    <span>Fornavn</span>
                  )}

                  <input
                    data-val="true"
                    data-val-required="Fornavn&#32;må&#32;fylles&#32;ut"
                    data-validators="person-name"
                    id="FirstName"
                    name="FirstName"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </label>
              </div>
              <div className="half">
                <label>
                  {errors.lastName ? (
                    <span className="invalid">{errors.lastName}</span>
                  ) : (
                    <span>Etternavn</span>
                  )}
                  <input
                    data-val="true"
                    data-val-required="Etternavn&#32;må&#32;fylles&#32;ut"
                    data-validators="person-name"
                    id="LastName"
                    name="LastName"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div className="row margin">
              <label>
                {errors.employeeNumber ? (
                  <span className="invalid">{errors.employeeNumber}</span>
                ) : (
                  <span>Ansattnummer</span>
                )}
                <input
                  readOnly={path === LoginPath.update}
                  disabled={path === LoginPath.update}
                  data-val="true"
                  data-val-required="Ansattnummer&#32;må&#32;fylles&#32;ut"
                  id="EmployeeNumber"
                  name="EmployeeNumber"
                  type="text"
                  value={employeeNumber}
                  onChange={(e) => setEmployeeNumber(e.target.value)}
                />
              </label>
            </div>
            <div className="row margin">
              <label>
                <span>E-post</span>
                <input
                  data-value="false"
                  id="Email"
                  name="Email"
                  type="text"
                  disabled={true}
                  value={email}
                />
              </label>
            </div>
            <div className="row margin">
              <label>
                {errors.secondaryEmail ? (
                  <span className="invalid">{errors.secondaryEmail}</span>
                ) : (
                  <span data-secondary-email-help-text>Sekundær e-post</span>
                )}
                <input
                  data-val="true"
                  data-val-required="E-post&#32;må&#32;fylles&#32;ut"
                  data-validators="email"
                  id="SecondaryEmail"
                  name="SecondaryEmail"
                  type="text"
                  value={secondaryEmail}
                  onChange={(e) => setSecondaryEmail(e.target.value)}
                />
              </label>
            </div>
            {isBasic && path !== LoginPath.update && (
              <div className="row margin">
                <label>
                  <span>Etat</span>
                  <div className="custom-select">
                    <Select
                      data-val="true"
                      data-val-required="Velg etat"
                      data-validators="etat"
                      defaultChoice="Velg etat"
                      name="etat"
                      value={etat}
                      onChange={(n, v) => setEtat(v?.toString())}
                      options={etatOptions.map((e) => {
                        return { key: e, value: e };
                      })}
                    />
                  </div>
                </label>
              </div>
            )}

            {path !== LoginPath.update && (
              <>
                <div className="row margin">
                  <label>
                    {errors.dateOfBirth ? (
                      <span className="invalid">{errors.dateOfBirth}</span>
                    ) : (
                      <span data-date-of-birth-help-text>
                        Fødselsdato (DD.MM.ÅÅÅÅ)
                      </span>
                    )}
                    <input
                      data-val="false"
                      data-val-date="The&#32;field&#32;DateOfBirth&#32;must&#32;be&#32;a&#32;date."
                      data-validators="date"
                      id="DateOfBirth"
                      name="DateOfBirth"
                      type="text"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                    />
                  </label>
                </div>

                <div className="row margin">
                  <label>
                    {errors.currentHousingAddress ? (
                      <span className="invalid">
                        {errors.currentHousingAddress}
                      </span>
                    ) : (
                      <span data-current-housing-address-help-text>
                        Postadresse
                      </span>
                    )}
                    <input
                      id="CurrentHousingAddress"
                      name="CurrentHousingAddress"
                      type="text"
                      value={currentHousingAddress}
                      onChange={(e) => setCurrentHousingAddress(e.target.value)}
                    />
                  </label>
                </div>
                <div className="flex-row margin defined-widths">
                  <label className="half">
                    {errors.currentHousingZipCode ? (
                      <span className="invalid">
                        {errors.currentHousingZipCode}
                      </span>
                    ) : (
                      <span>Postnummer</span>
                    )}
                    <input
                      data-val="true"
                      data-validators="number,&#32;min-length=4,&#32;max-length=4"
                      id="CurrentHousingZipCode"
                      name="CurrentHousingZipCode"
                      type="text"
                      value={currentHousingZipCode}
                      onChange={(e) => setCurrentHousingZipCode(e.target.value)}
                    />
                  </label>
                  <label className="half">
                    <span>Poststed</span>
                    <input
                      id="CurrentHousingCity"
                      name="CurrentHousingCity"
                      type="text"
                      readOnly
                      value={currentHousingCity}
                    />
                  </label>
                </div>
              </>
            )}

            <div className="flex-row align-end margin" data-id="validate-phone">
              <label className="third">
                {errors.countryCode ? (
                  <span className="invalid">{errors.countryCode}</span>
                ) : (
                  <span>Landskode</span>
                )}
                <input
                  data-validators="number,&#32;max-length=6"
                  id="CountryCode"
                  name="CountryCode"
                  type="text"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                />
              </label>
              <label className="">
                {errors.phone ? (
                  <span className="invalid">{errors.phone}</span>
                ) : (
                  <span>Mobilnummer</span>
                )}
                <input
                  data-help="Få&#32;bestillingsbekreftelser&#32;tilsendt&#32;på&#32;SMS"
                  data-val="true"
                  data-val-required="Mobilnummer&#32;må&#32;fylles&#32;ut"
                  data-validators="phone,&#32;max-length=10"
                  id="Phone"
                  name="Phone"
                  title="Få&#32;bestillingsbekreftelser&#32;tilsendt&#32;på&#32;SMS"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </label>
            </div>
            {path !== LoginPath.update && (
              <>
                <div className="row margin">
                  <Button
                    disabled={!phone || !countryCode}
                    onClick={() => sendTwoFactorCode()}
                    color={'red'}
                  >
                    Send engangskode på SMS
                  </Button>
                </div>
                <div className="row margin">
                  <label>
                    {errors.twoFactKey ? (
                      <span className="invalid">{errors.twoFactKey}</span>
                    ) : (
                      <span>Engangskode fra SMS</span>
                    )}
                    <input
                      data-val="true"
                      data-val-required="Engangskode&#32;må&#32;fylles&#32;ut"
                      data-validators="integer,&#32;length=6"
                      id="TwoFactKey"
                      name="TwoFactKey"
                      type="text"
                      value={twoFactKey}
                      onChange={(e) => setTwoFactKey(e.target.value)}
                    />
                  </label>
                </div>
                <div className="row margin">
                  <label>
                    {errors.password ? (
                      <span className="invalid">{errors.password}</span>
                    ) : (
                      <span>Velg passord</span>
                    )}
                    <input
                      data-val="true"
                      data-val-required="Passordet&#32;må&#32;ha&#32;minst&#32;8&#32;tegn.&#32;Passordet&#32;må&#32;bestå&#32;av&#32;både&#32;små&#32;og&#32;store&#32;bokstaver&#32;fra&#32;A-Z&#32;og&#32;minst&#32;et&#32;tall."
                      data-validators="password"
                      id="Password"
                      name="Password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </label>
                </div>
                <div className="row margin">
                  <label>
                    {' '}
                    {errors.passwordRepeat ? (
                      <span className="invalid">{errors.passwordRepeat}</span>
                    ) : (
                      <span>Gjenta passord</span>
                    )}
                    <input
                      data-val="true"
                      data-val-required="Passordene&#32;stemmer&#32;ikke&#32;overens"
                      data-validators="identical-to=Password"
                      id="PasswordRepeat"
                      name="PasswordRepeat"
                      type="password"
                      value={passwordRepeat}
                      onChange={(e) => setPasswordRepeat(e.target.value)}
                    />
                  </label>
                </div>
                <p className="margin">
                  Passordet må ha minst 8 tegn. Passordet må bestå av både små
                  og store bokstaver fra A-Å og minst et tall.
                </p>
              </>
            )}

            <div className="row margin">
              <p>
                <b>Godkjenn lagring av personopplysninger</b>
              </p>
              <p>
                Etter det nye personopplysningsloven (GDPR) kan vi ikke lagre
                dine personopplysninger uten din godkjennelse. Vi trenger derfor
                din godkjennelse på at vi kan lagre dine opplysninger, slik at
                du kan benytte deg av ulike tjenester i Basen.
              </p>
              <p>
                Hvis du ikke samtykker vil du ikke kunne benytte deg av
                tjenestene i Basen.&nbsp;
              </p>
              <p>
                Trykk her for å lese&nbsp;
                <a
                  href="/link/e91f6647791144649d4faed21740ce88.aspx"
                  target="_blank"
                  title="Forsvarsbyggs personvernerklæring"
                >
                  Forsvarsbyggs personvernerklæring
                </a>
              </p>
              <input
                type="checkbox"
                id="consent-confirmation"
                value="consent-confirmation"
                data-name="consent-confirmation"
                name="consent-confirmation"
                title="Samtykke"
                className="onoff-radio"
                data-required
                checked={consentConfirmed}
                onChange={(e) => setConsentConfirmed(e.target.checked)}
              />
              <label
                htmlFor="consent-confirmation"
                className={
                  'onoff-checkbox-label ' +
                  (errors && errors.consentConfirmed ? 'invalid' : '')
                }
              >
                Jeg godtar betingelsene i personvernerklæringen
              </label>
            </div>
            <div
              className="form-message invalid"
              style={{ fontSize: 20, fontWeight: 800 }}
            >
              <SafeHtmlRenderer response={response} />
            </div>

            <ButtonRow>
              <ButtonRow.Right onClick={handleSubmit} color="red">
                Fortsett
              </ButtonRow.Right>
            </ButtonRow>
          </div>
          {/* </form> */}
        </div>
      )}

      {path === LoginPath.registerSuccess && (
        <div className="flip-card-front scrollable">
          <div className="confirm">
            <h2>Registreringen er fullført!</h2>
            <div className="row">
              <p>
                Du er nå logget inn som{' '}
                <strong className="registered-email">{userName}</strong> og har
                tilgang til alle dine tjenester og funksjoner.
              </p>
              <p>Velkommen til Basen!</p>
            </div>
            <button className="btn red" onClick={returnToRoot}>
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default NewUser;
