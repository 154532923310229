// @flow
import type {
  InspectionQuestionType,
  InspectionType,
} from "../types/inspectionTypes";
import { RENTAL_CONTRACT_TYPE_HOUSING } from "../helpers/housingAdminHelpers";

const questionTypes = (questionType: string) =>
  ({
    yesNo: 0,
    number: 1,
  }[questionType]);

export const QUESTION_TYPE_YESNO = questionTypes("yesNo");
export const QUESTION_TYPE_NUMBER = questionTypes("number");
export const FORMID = (caption: string, question: InspectionQuestionType) =>
  `${caption}_${question.Code}`;
export const MOVING_INTO_QUARTER = (
  contractType: number,
  isMovingIn: boolean
) => contractType !== RENTAL_CONTRACT_TYPE_HOUSING && isMovingIn;
export const RETURN_CONTRACT_TYPE_STRING = (contractType: number) =>
  contractType === RENTAL_CONTRACT_TYPE_HOUSING ? "Housing" : "Quarter";

export const STOREDRESPONSES = (inspection: InspectionType) => {
  const formObj = {};

  if (inspection.Groups) {
    const GroupsWithElements = inspection.Groups.filter((g) => g.Elements);

    GroupsWithElements.forEach((group) => {
      group.Elements.forEach((question) => {
        const FormId = FORMID(group.Caption, question);

        formObj[FormId] = question;
      });
    });
  }
  return formObj;
};

export const SHOW_RESPONSE = (question: InspectionQuestionType) => {
  if (question) {
    if (question.ElementType === QUESTION_TYPE_NUMBER) {
      return question.Response ? question.Response.Number : 0;
    }
    if (question.Response) {
      if (question.Response.NotRelevant) {
        return "Ikke aktuelt";
      } else if (question.Response.YesNo) {
        return "Ja";
      }
      return "Nei";
    }
  }
  return "";
};

export const CREATEOBJECTRESPONSE = (
  value: string | number,
  isComment?: boolean
) => {
  let responseObj;

  if (!isNaN(value)) {
    responseObj = {
      NotRelevant: false,
      YesNo: false,
      Number: value,
      Comment: null,
    };
  } else {
    responseObj = {
      NotRelevant: value === "Ikke aktuelt",
      YesNo: value === "Ja",
      Number: 1,
      Comment:
        ["Ikke aktuelt", "Ja", "Nei"].indexOf(value) >= 0 && !isComment
          ? null
          : value,
    };
  }

  return responseObj;
};
