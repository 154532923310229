const ReservationColumns = [
  { label: "Status", name: "BookingState", className: "guard-guest-name" },
  { label: "Navn", name: "Bookee", className: "guard-icon" },
  { label: "Dato fra", name: "ArrivalDate", className: "guard-from-date" },
  { label: "Dato til", name: "DepartureDate", className: "guard-from-date" },
  { label: "Arena", name: "Location", className: "guard-to-date" },
  { label: "Type", name: "TypeOfTravel", className: "guard-phone" },
  { label: "Gjest/rom", name: "GuestString", className: "guard-status" },
  {
    label: "Innsendt dato",
    name: "BookingDate",
    className: "guard-guest-name",
  },
  { label: "Betaling", name: "GuestPayment", className: "guard-room" },
];

export default ReservationColumns;
