import { approveHousingAllocationsURL } from 'api/housing/housing_api';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Modal from 'components/modals/Modal/Modal';
import { useModal, useSlides } from 'contexts/index';
import { getRoundTitle } from 'helpers/housingAdminHelpers';
import { axiosFetch } from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';

import HousingAllocationList from './HousingAllocationsList/HousingAllocationsList';

const ConfirmationModal = ({ isOpen, setModalIsOpen, onSubmit }) => (
  <Modal
    title="Forhåndsvisning av tildelingsliste"
    isOpen={isOpen}
    size="medium"
    onClose={() => setModalIsOpen(false)}
    submit={{ onClick: () => onSubmit(), text: 'Lagre' }}
    cancel={{
      onClick: () => setModalIsOpen(false),
      text: 'Avbryt',
      color: 'white',
    }}
  >
    <div>
      <h2>Godkjenn tildelingsliste</h2>
      <p>Ved å trykke «lagre» vil søkerne informeres om tildelingen.</p>
    </div>
  </Modal>
);

const HousingAdminApproveAllocations = () => {
  const { showModal } = useModal();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const params = useParams();
  const { goToSlide } = useSlides();

  const housingArea = useSelector(
    (state: DeliberateAny) => state.housingAdmin.housingArea,
    shallowEqual
  );

  useEffect(() => {
    if (!housingArea.selectedAllocationRound) {
      goToSlide(`/boligsekretær/boligsøknader/${params.housingAreaId}`);
    }
  }, []);

  const approveHousingAllocations = () => {
    const url = approveHousingAllocationsURL;
    const query = {
      allocationRoundId: housingArea.selectedAllocationRound.Round.Id,
    };
    axiosFetch({ url, data: query, method: 'POST' })
      .catch((error) => {
        showModal({
          title: error.response.data.Text,
          html: error.response.data.Title,
          size: 'medium',
          show: true,
        });
        return;
      })
      .finally(() => {
        goToSlide(`/boligsekretær/boligsøknader/${params.housingAreaId}`);
      });
  };

  return (
    <div>
      {housingArea.registeredHousingAllocations && (
        <div>
          <h2>
            {getRoundTitle(
              housingArea.selectedAllocationRound,
              housingArea.housingRoundDataOptions
            )}
          </h2>
          <div className="approve-allocations-content">
            <div className="top-content">
              <p>
                Boligrådets tildeling sendes til søkerne, som har 14 dagers
                svarfrist. <br />
              </p>
              <ButtonRow>
                <ButtonRow.Left
                  color="red"
                  onClick={() => setShowConfirmationModal(true)}
                >
                  Godkjenn tildeling
                </ButtonRow.Left>
              </ButtonRow>
            </div>
          </div>
          <h3>Tildelingsliste</h3>
          <HousingAllocationList
            items={housingArea.registeredHousingAllocations}
            isPreview
          />
        </div>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          setModalIsOpen={setShowConfirmationModal}
          onSubmit={() => approveHousingAllocations()}
        />
      )}
    </div>
  );
};

export default HousingAdminApproveAllocations;

// props => (
//   <Route
//     location={props.location}
//     path={`${props.match.url}/:housingAreaId`}
//     component={HousingAdminApproveAllocations}
//   />
// );
