import Slide from "components/Slides/Slide";
import { HousingQuarter } from "contexts/HousingContext";
import { useHousing, useSlides, useUser } from "contexts/index";

import ApplicationSummary from "../ApplicationSummary";
import {
  housing,
  housingTitle,
  paths,
  quarter,
  quarterTitle,
} from "../HousingSection";

const ApplicationSummarySlide = ({ appType }) => {
  const { isLoading, user } = useUser();
  const { applicationType, loading } = useHousing();
  const { goToSlide } = useSlides();

  return (
    <Slide
      path={`${appType === HousingQuarter.Housing ? housing : quarter}${
        paths.livingCondition
      }`}
      title={appType === HousingQuarter.Housing ? housingTitle : quarterTitle}
      loading={loading || isLoading}
      showCount
    >
      <ApplicationSummary
        goToSlide={goToSlide}
        applicationType={applicationType}
        userData={user}
        defaultTo={`${appType === HousingQuarter.Housing ? housing : quarter}${
          paths.applicantCategory
        }`}
      />
    </Slide>
  );
};

export default ApplicationSummarySlide;
