/* eslint react/prop-types: 0 */

import { useState, useEffect } from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { proffDeadlinesURL } from "../../../../api/basenproff/basenproff_api";
import { axiosFetch } from "../../../../hooks/useAxios";

const WidgetDeadlines = ({ deleteModule, saveModule, id }) => {
  const [deadlinesInfo, setDeadlinesInfo] = useState();
  const [widgetSettings, setWidgetSettings] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    const url = proffDeadlinesURL;
    axiosFetch({ url }).then((response) => {
      if (isSubscribed) {
        setDeadlinesInfo(response);
      }
    });
    // eslint-disable-next-line
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleSave = () => {
    setWidgetSettings(false);
    saveModule(id);
  };

  return (
    <div
      className={`widget deadlines carousel${
        widgetSettings ? " show-settings" : ""
      }`}
    >
      <div className="widget-wrapper">
        <div className="widget-inner-wrapper">
          <div className="header-wrapper">
            <header>
              <button
                className="btn-widget-settings"
                onClick={() => setWidgetSettings(!widgetSettings)}
              />
              <h3>Frister</h3>
            </header>
            <div className="widget-settings-wrapper">
              <div className="widget-settings">
                <div className="widget-specific-settings margin" />
                <button className="btn white" onClick={() => deleteModule(id)}>
                  Fjern Modul
                </button>
                <button
                  className="btn white align-right"
                  onClick={() => handleSave()}
                >
                  Lagre
                </button>
              </div>
            </div>
            <div
              className={`widget-content-container${
                !deadlinesInfo ? " widget-loading" : ""
              }`}
            >
              <div className="widget-loader" />
              <div className="widget-content">
                <ul className="widget-list">
                  <li>
                    <div className="deadlines-header">
                      <div className="column-title"> Saker </div>
                      <div className="column-date"> Frist </div>
                    </div>
                  </li>
                  {deadlinesInfo &&
                    deadlinesInfo.map((el) => (
                      <li key={uuidv4()}>
                        <div className="deadlines-overview">
                          <div className="column-title">
                            <span>{el.Description}</span>
                          </div>
                          <div className="column-rental-protocol-notice"> </div>
                          <div className="column-date">
                            {moment(el.DueDate).format("DD.MM.YY")}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetDeadlines;
