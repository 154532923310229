import DOMPurify from 'dompurify';
import { FC } from 'react';

interface ISafeHtmlRenderer {
  response: string;
  className?: string;
}

const SafeHtmlRenderer: FC<ISafeHtmlRenderer> = ({ response, className }) => {
  return /<[^>]+>/.test(response ?? '') ? (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(response ?? ''),
      }}
    />
  ) : (
    <div>{response}</div>
  );
};

export default SafeHtmlRenderer;
