import {
    addChild, clearPetInfo, removeChild, removeChildren, updateAllApplicationErrors,
    updateApplication, updateSingleApplicationError
} from 'actions/housingActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import Input from 'components/form/Input/Input';
import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import Select from 'components/form/Select/Select';
import TextArea from 'components/form/TextArea/TextArea';
import HelpText from 'components/HelpText/HelpText';
import connect from 'helpers/connectWithRouter';
import moment from 'moment';
import ChildInput from 'pages/Housing/ChildInput';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropSlideNavigation } from 'types/PropNavigationBase';
import { getSiblingPath } from 'utils/helpers';
import * as yup from 'yup';

import CohabitantInput from './CohabitantInput';

import type { KeyValueType } from 'types/commonTypes';
import type { HousingApplicationType, InfoTextType } from 'types/housingTypes';

type PropsType = PropSlideNavigation & {
  application: HousingApplicationType;
  applicationType: string;
  infoText: InfoTextType;
  maritalStatuses: Array<KeyValueType>;
  addChild: () => void;
  removeChild: () => void;
  removeChildren: () => void;
  clearPetInfo: () => void;
  updateSingleApplicationError: () => void;
  updateAllApplicationErrors: () => void;
  updateApplication: (name: string, value: boolean | number | string) => void;
  errors?: DeliberateAny;
  nextSlidePath: string;
  defaultTo: string;
  housingDataOptionsLoaded: boolean;
  options: DeliberateAny;
};

export class UnconnectedFamilySituation extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    if (!this.props.housingDataOptionsLoaded) {
      this.props.goToSlide(
        getSiblingPath(this.props.location.pathname, this.props.defaultTo),
        true,
      );
    }
  }

  onSubmit = () => {
    this.props.goToSlide(
      getSiblingPath(this.props.location.pathname, this.props.nextSlidePath),
    );
  };

  handlePetInfo = (name: string, checkedOption: boolean | number | string) => {
    this.props.updateApplication(name, checkedOption);
    if (!checkedOption) {
      this.props.clearPetInfo();
    }
  };

  addChildrenRow = (name: string, checkedOption: boolean | number | string) => {
    this.props.updateApplication(name, checkedOption);
    if (checkedOption) {
      this.props.addChild();
    } else {
      this.props.removeChildren();
    }
  };

  renderCohabitant = () => {
    return (
      <div className="cohabitant-wrapper">
        <CohabitantInput
          helpText={this.props.infoText.CohabitationHelpText}
          nameDateOfBirth={'significantOtherBirthdate'}
          nameLongTerm={'isCohabitationLongTerm'}
          errors={this.props.errors}
          onChange={this.props.updateApplication}
          valueDateOfBirth={
            this.props.application.significantOtherBirthdate ?? ''
          }
          valueIsLongTerm={this.props.application.isCohabitationLongTerm ?? ''}
        />
      </div>
    );
  };

  renderChildren = () => (
    <div className="children-wrapper">
      {this.props.application.children &&
        this.props.application.children.map((child, i) => (
          <ChildInput
            errors={this.props.errors}
            key={i}
            index={i}
            dateOfBirth={child.dateOfBirth?.toString()}
            onChange={this.props.updateApplication}
            removeChild={this.props.removeChild}
          />
        ))}
      <ButtonRow>
        <ButtonRow.Left onClick={this.props.addChild} color="red">
          Legg til barn
        </ButtonRow.Left>
      </ButtonRow>
    </div>
  );

  validationSchema = yup
    .object({
      applicantCategory: yup.string().required('required'),
      maritalStatus: yup.number().required('required').min(0, 'min'),
      significantOtherBirthdate: yup.string().when('maritalStatus', {
        is: (status) => status === 4 || status === 5,
        then: (schema) =>
          schema
            .required('required')
            .matches(/^([0-2][0-9]|(3)[0-1]).(0[1-9]|1[0-2]).\d{4}$/, 'invalid')
            .test(
              'max-date',
              {
                rule: 'date.max',
                max: `${moment().subtract(16, 'years').format('DD.MM.YYYY')}`,
              },
              (value) =>
                moment(value, 'DD.MM.YYYY').isBefore(
                  moment().subtract(16, 'years'),
                ),
            ),
      }),
      isCohabitationLongTerm: yup.boolean().when('maritalStatus', {
        is: (status) => status === 4 || status === 5,
        then: (schema) => schema.required('required'),
      }),
      hasChildren: yup.boolean().required('required'),
      isPregnant: yup.boolean().required('required'),
      hasPets: yup.boolean().required('required'),
      petCount: yup.number().when('hasPets', {
        is: true,
        then: (schema) => schema.required('required').min(1, 'min'),
      }),
      petInfo: yup.string().when('hasPets', {
        is: true,
        then: (schema) => schema.required('required'),
      }),
      children: yup.array().of(
        yup.object().shape({
          dateOfBirth: yup
            .string()
            .required('required')
            .matches(/^([0-2][0-9]|(3)[0-1]).(0[1-9]|1[0-2]).\d{4}$/, {
              rule: 'date.format',
              format: 'DD.MM.YYYY',
            })
            .test(
              'max-date',
              { rule: 'date.max', max: `${moment().format('DD.MM.YYYY')}` },
              (value) => moment(value, 'DD.MM.YYYY').isBefore(moment()),
            )
            .test(
              'min-date',
              {
                rule: 'date.min',
                min: `${moment().startOf('year').subtract(20, 'years').format('DD.MM.YYYY')}`,
              },
              (value) =>
                moment(value, 'DD.MM.YYYY').isAfter(
                  moment().startOf('year').subtract(20, 'years'),
                ),
            ),
        }),
      ),
    })
    .defined();

  render() {
    const formProps = {
      errors: this.props.errors,
      form: {
        ...this.props.application,
      },
      onSubmit: this.onSubmit,
      onChange: this.props.updateApplication,
      updateAllValidationErrors: this.props.updateAllApplicationErrors,
      updateOneValidationError: this.props.updateSingleApplicationError,
      schema: this.validationSchema,
    };

    return (
      <div>
        <FormWrapper {...formProps} unclassifiedStamp>
          <h1>Familiesituasjon</h1>

          <p>{this.props.infoText.FamilyRelationshipInfo}</p>

          <div className="row margin">
            <HelpText helpText={this.props.infoText.FamilyMaritialStatusInfo}>
              <Select
                loading={!this.props.maritalStatuses}
                defaultChoice="Velg sivilstand"
                name="maritalStatus"
                label="Sivilstand for fremtidig boforhold"
                options={this.props.maritalStatuses}
                value={this.props.application.maritalStatus}
                data-testid="input-application-marital-status"
                onChange={this.props.updateApplication}
                errors={this.props.errors}
              />
            </HelpText>
            {(this.props.application.maritalStatus === 4 ||
              this.props.application.maritalStatus === 5) &&
              this.renderCohabitant()}
          </div>
          <div className="row margin">
            <HelpText helpText={this.props.infoText.FamilyChildCountInfo}>
              <RadioButtonGroup
                legend="Har du barn under 20 år?"
                name="hasChildren"
                options={[
                  {
                    label: 'Ja',
                    value: true,
                  },
                  {
                    label: 'Nei',
                    value: false,
                  },
                ]}
                checkedOption={this.props.application.hasChildren}
                onChange={this.addChildrenRow}
                errors={this.props.errors}
              />
            </HelpText>
            {this.props.application.hasChildren && this.renderChildren()}
          </div>
          <div className="row margin">
            <HelpText helpText={this.props.infoText.FamilyPregnantInfo}>
              <RadioButtonGroup
                legend="Venter du eller din partner barn? Vi lagrer ikke informasjonen, men spør fordi
              hvis dette er tilfellet får du 200 ekstra søknadspoeng."
                name="isPregnant"
                options={[
                  {
                    label: 'Ja',
                    value: true,
                  },
                  {
                    label: 'Nei',
                    value: false,
                  },
                ]}
                checkedOption={this.props.application.isPregnant}
                onChange={this.props.updateApplication}
                errors={this.props.errors}
              />
            </HelpText>
          </div>
          <div className="row margin">
            <HelpText helpText={this.props.infoText.FamilyPetCountInfo}>
              <RadioButtonGroup
                legend="Har du husdyr?"
                name="hasPets"
                options={[
                  {
                    label: 'Ja',
                    value: true,
                  },
                  {
                    label: 'Nei',
                    value: false,
                  },
                ]}
                checkedOption={this.props.application.hasPets}
                onChange={this.handlePetInfo}
                errors={this.props.errors}
              />
            </HelpText>
            {this.props.application.hasPets && (
              <div className="pets-wrapper">
                <div className="flex-row">
                  <Input
                    name="petCount"
                    title="Hvor mange dyr har du?"
                    type="number"
                    onChange={this.props.updateApplication}
                    value={this.props.application.petCount}
                    errors={this.props.errors}
                  />

                  <Input
                    title="Hva slags type husdyr?"
                    name="petInfo"
                    type="text"
                    onChange={this.props.updateApplication}
                    value={this.props.application.petInfo}
                    errors={this.props.errors}
                  />
                </div>
                <TextArea
                  name="otherInfo"
                  title="Andre vesentlige opplysninger om ditt husdyrhold."
                  onChange={this.props.updateApplication}
                  value={this.props.application.otherInfo}
                  errors={this.props.errors}
                />
              </div>
            )}
          </div>
          <ButtonRow>
            <ButtonRow.Right color="red" type="submit" onClick={this.onSubmit}>
              Neste
            </ButtonRow.Right>
          </ButtonRow>
        </FormWrapper>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny, props: PropsType) => ({
    application: state.housing[`${props.applicationType}Application`],
    errors: state.housing.errors,
    infoText: state.housing.housingInfoText,
    housingDataOptionsLoaded: state.options.housingDataOptionsLoaded,
  }),
  (dispatch, props) =>
    bindActionCreators(
      {
        updateApplication: (...args: [string, string | boolean]) =>
          updateApplication(props.applicationType, ...args),
        updateAllApplicationErrors: (...args: [DeliberateAny]) =>
          updateAllApplicationErrors(props.applicationType, ...args),
        updateSingleApplicationError: (...args: [string, DeliberateAny]) =>
          updateSingleApplicationError(props.applicationType, ...args),
        addChild,
        removeChildren,
        removeChild,
        clearPetInfo: () => clearPetInfo(props.applicationType),
      },
      dispatch,
    ),
)(UnconnectedFamilySituation);
