import { getErrorMsgBooking } from '../../helpers/bookingHelpers';

export const validateFields = (
  roomInfo,
  setValidField,
  isBlock,
  allowEmpty,
  showModal,
) => {
  if (isBlock) {
    return true;
  }

  let obj;

  if (allowEmpty) {
    obj = roomInfo.map((room) => ({
      id: room.Id,
      guests: room.NameListInfo.NameListItems.map((guest) => ({
        firstName:
          /^[a-zA-ZøØåÅæÆéÉèÈÜüöÖäÄôÔÎîìÌëË -.]+$/.test(guest.FirstName) ||
          guest.FirstName?.length === 0,
        lastName:
          /^[a-zA-ZøØåÅæÆéÉèÈÜüöÖäÄôÔÎîìÌëË -.]+$/.test(guest.LastName) ||
          guest.LastName?.length === 0,
        phone:
          guest.FirstName || guest.LastName
            ? /^\+\d+$/.test(guest.Phone) && guest.Phone?.length >= 8
            : guest.Phone === '+47' ||
              guest.Phone === null ||
              guest.Phone?.length === 0,
        email:
          /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(
            guest.Email ? guest.Email : '',
          ) ||
          guest.Email === null ||
          guest.Email?.length === 0,
      })),
    }));
  } else {
    obj = roomInfo.map((room) => ({
      id: room.Id,
      guests: room.NameListInfo.NameListItems.map((guest) => ({
        firstName: /^[a-zA-ZøØåÅæÆéÉèÈÜüöÖäÄôÔÎîìÌëË -.]+$/.test(
          guest.FirstName ? guest.FirstName : '',
        ),
        lastName: /^[a-zA-ZøØåÅæÆéÉèÈÜüöÖäÄôÔÎîìÌëË -.]+$/.test(guest.LastName),
        phone: /^\+\d+$/.test(guest.Phone) && guest.Phone?.length >= 8,
        email:
          /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/.test(
            guest.Email ? guest.Email : '',
          ),
      })),
    }));
  }

  setValidField(obj);

  if (
    obj.find((field) =>
      field.guests.find(
        (guest) =>
          guest.firstName === false ||
          guest.lastName === false ||
          guest.phone === false,
      ),
    )
  ) {
    let errorText;

    if (
      roomInfo.find((room) =>
        room.NameListInfo.NameListItems.find(
          (guest) =>
            guest.FirstName === null ||
            guest.FirstName?.length === 0 ||
            guest.LastName === null ||
            guest.LastName?.length === 0 ||
            guest.Phone === null ||
            guest.Phone?.length === 0,
        ),
      )
    ) {
      errorText = getErrorMsgBooking();
    } else {
      errorText = getErrorMsgBooking();
      errorText += ' Endre felt og prøv igjen.';
    }

    showModal({ title: 'Feil i utfylling', text: errorText, show: true });
    return false;
  }
  return true;
};

export const bedsVsGuests = (bedCount, guestCount, isBlock, showModal) => {
  let title = '';
  let errorMessage = '';

  if (guestCount !== bedCount) {
    if (isBlock) {
      if (guestCount > bedCount) {
        title = 'Flere gjester enn rom';
        errorMessage =
          'For at deltakerne selv kan velge plasseringen av gjestene må du velge ' +
          'et rom per gjest.';
      } else {
        title = 'Flere rom enn gjester';
        errorMessage = 'Du har valgt flere rom enn antall gjester.';
      }
    } else if (guestCount > bedCount) {
      title = 'Flere gjester enn sengeplasser.';
      errorMessage =
        'Du har valgt flere gjester enn sengeplasser, pass på at alle får hver ' +
        'sin sengeplass.';
    } else {
      title = 'Flere sengeplasser enn gjester';
      errorMessage = 'Du har valgt flere sengeplasser enn antall gjester.';
    }

    showModal({ title: title, text: errorMessage, show: true });
    return false;
  }

  return true;
};

export const calculateBeds = (roomInfo, isBlock) => {
  let count = 0;
  if (isBlock) {
    count = roomInfo.length;
  } else {
    roomInfo.forEach((room) =>
      room.NameListInfo.NameListItems.forEach(() => {
        count += 1;
      }),
    );
  }
  return count;
};

export const roomsVsGuests = (roomCount, guestCount, showModal) => {
  if (guestCount < roomCount) {
    showModal({
      title: 'Obs!',
      text: 'Du har lagt til flere rom enn antall gjester.',
      show: true,
    });
    return false;
  }
  return true;
};

export const validateRooms = (roomInfo, guestCount, isBlock, showModal) => {
  if (roomInfo.length === 0) {
    showModal({
      title: 'Velg rom',
      text: 'Du må velge minst ett rom før du går videre',
      show: true,
    });
    return false;
  }

  // WHen doing a block booking we are only counting one person per room. The user making the
  // block booking can't know in advance how the users will distribute their attendants.
  // For instance, A user that uses the ref code can choose to put only one person in a double
  // room with extra bed, so despite the room having a max capacity of 3, we have to assume
  // only the minimum capacity is actually going to be filled up.
  if (isBlock) {
    return bedsVsGuests(roomInfo.length, guestCount, isBlock, showModal);
  }

  const roomCount = roomInfo.length;
  const bedCount = calculateBeds(roomInfo, isBlock);
  if (
    !bedsVsGuests(bedCount, guestCount, isBlock, showModal) ||
    !roomsVsGuests(roomCount, guestCount, showModal)
  ) {
    return false;
  }
  return true;
};
