import './ArrivalListHeader.scss';

import Button from 'components/buttons/Button/Button';
import DateInput from 'components/DateInput/DateInput';
import ComboMultiSelect, { ValueOption } from 'components/form/ComboMultiSelect/ComboMultiSelect';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { KeyValueType } from 'types/commonTypes';

import { ArrivalType } from '../TabArrivalListPage';

interface IArrivalListProps {
  setSelectedFilters: (item: any) => void;
  selectedDate: Date;
  setSelectedDate: (item: Date) => void;
  triggerListUpdate: () => void;
  lastUpdated: Date;
  arrivals: { totalArrivals: ArrivalType[]; filteredArrivals: ArrivalType[] };
  printArrivalsList: () => void;
}

const ArrivalListHeader: FC<IArrivalListProps> = ({
  setSelectedFilters,
  selectedDate,
  setSelectedDate,
  triggerListUpdate,
  lastUpdated,
  arrivals: { totalArrivals, filteredArrivals },
  printArrivalsList,
}) => {
  const [totalGuestNumber, setTotalGuestNumber] = useState<number>();
  const [filteredGuestNumber, setFilteredGuestNumber] = useState<number>();
  const [checkInFilterOptions, setCheckInFilterOptions] = useState<
    KeyValueType[]
  >([]);
  const [selectedCheckInStatus, setSelectedCheckInStatus] = useState<
    KeyValueType[]
  >([]);
  const [buildingFilterOptions, setBuildingFilterOptions] = useState<
    KeyValueType[]
  >([]);
  const [selectedBuildings, setSelectedBuildings] = useState<KeyValueType[]>(
    [],
  );

  const formatDateTime = (date) => {
    return `${new Date(date).toLocaleDateString('no', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })} - ${new Date(date).toLocaleTimeString('no', {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  };

  useEffect(() => {
    fetchCheckInFilterOptions();
    fetchBuildingFilterOptions();
  }, [selectedDate, totalArrivals]);

  useEffect(() => {
    setSelectedFilters({
      selectedBuildings: selectedBuildings,
      selectedCheckInStatus: selectedCheckInStatus,
    });
  }, [selectedBuildings, selectedCheckInStatus]);

  useEffect(() => {
    setTotalGuestNumber(totalArrivals.length);
    setFilteredGuestNumber(filteredArrivals.length);
  }, [totalArrivals, filteredArrivals]);

  // Fetch filter options
  const fetchCheckInFilterOptions = async () => {
    const uniqueList = Array.from(
      new Set(
        totalArrivals.map((person) => {
          return person.checkinStatus ? person.checkinStatus : '';
        }),
      ),
    ).map((status) => {
      return { key: status, value: status };
    });
    setCheckInFilterOptions([{ key: 'alle', value: 'Alle' }, ...uniqueList]);
  };

  const fetchBuildingFilterOptions = () => {
    const uniqueList = Array.from(
      new Set(
        totalArrivals.map((person) => {
          return person.buildingId ? person.buildingId : '';
        }),
      ),
    ).map((buildingId) => {
      if (!buildingId || buildingId == '') {
        return { key: 'unset', value: 'Ikke tilordnet' };
      }
      return { key: buildingId, value: buildingId };
    });
    setBuildingFilterOptions([{ key: 'alle', value: 'Alle' }, ...uniqueList]);
  };

  // Reset filters
  const resetFilters = () => {
    setSelectedCheckInStatus([]);
    setSelectedBuildings([]);
  };

  // Event handlers
  const onDateSelected = (date) => {
    setSelectedDate(date);
  };

  const updateList = () => {
    triggerListUpdate();
  };

  const render = () => (
    <div className="arrival-list-header grey">
      <div className="left-side">
        <div className="date-input no-print">
          <div className="date-input-field">
            <DateInput
              title="Dato"
              name="arrival"
              selectedDate={moment(selectedDate)}
              selectionSpan={365}
              onSelectDate={onDateSelected}
            />
          </div>
          <Button
            style={{ height: 'fit-content', marginTop: '1em' }}
            onClick={() => setSelectedDate(new Date())}
            disabled={moment().isSame(selectedDate, 'day')}
          >
            I dag
          </Button>
        </div>
        <div className="number-of-guests">
          {filteredGuestNumber != totalGuestNumber && (
            <div>
              <span>Antall gjester i filter:</span>
              <span>{filteredGuestNumber}</span>
            </div>
          )}
          <div>
            <span>Totalt antall ankommende gjester til lokasjon:</span>
            <span>{totalGuestNumber}</span>
          </div>
        </div>
        <div>
          <div className="last-updated">
            <div>{`Sist oppdatert: `}</div>
            <div>{formatDateTime(lastUpdated)}</div>
          </div>
          <Button
            className="no-print"
            style={{ marginTop: '1.5em' }}
            onClick={updateList}
          >
            Oppdater liste
          </Button>
        </div>
      </div>
      <div className="right-side">
        <div className="check-in-input">
          <span>Innsjekkstatus</span>
          <span className="check-in-input-field no-print">
            <ComboMultiSelect
              initial="Velg noe"
              name="selectCheckInStatus"
              placeHolder="Velg filtre"
              values={selectedCheckInStatus}
              onChange={(values) => setSelectedCheckInStatus(values)}
              helpText={'Innsjekkstatus'}
              options={checkInFilterOptions.map((option) => {
                return {
                  key: option.key,
                  value: option.value,
                } as ValueOption;
              })}
            />
          </span>
        </div>
        <div className="print-only">
          {selectedCheckInStatus?.length === 0
            ? checkInFilterOptions
                .filter((x) => !['alle', 'unset'].includes(x.key))
                .map((x) => x.value)
                .join(', ')
            : selectedBuildings.map((x) => x.value).join(', ')}
        </div>
        <div className="building-input">
          <span>Bygg</span>
          <span className="building-input-field no-print">
            <ComboMultiSelect
              initial="Velg noe"
              name={'selectBuildings'}
              placeHolder="Velg filtre"
              helpText="Bygning"
              options={buildingFilterOptions.map((option) => {
                return {
                  key: option.key,
                  value: option.value,
                } as ValueOption;
              })}
              values={selectedBuildings}
              onChange={(values) => setSelectedBuildings(values)}
            />
          </span>
        </div>
        <div className="print-only">
          {selectedBuildings?.length === 0
            ? buildingFilterOptions
                .filter((x) => !['alle', 'unset'].includes(x.key))
                .map((x) => x.value)
                .join(', ')
            : selectedBuildings.map((x) => x.value).join(', ')}
        </div>
        <div className="button-group no-print margin-top-auto">
          <Button onClick={() => resetFilters()}>Tøm filter</Button>
          <Button color="red" onClick={() => printArrivalsList()}>
            Skriv ut ankomstliste
          </Button>
        </div>
      </div>
    </div>
  );

  return render();
};

export default ArrivalListHeader;
