import './BookingOrder.scss';

import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Counter from 'components/Counter/Counter';
import ComboSelectBox from 'components/FormComponents/ComboSelectBox';
import DateSelector, {
  DatePickerMode,
} from 'components/FormComponents/DateSelector';
import RadioButtonGroup from 'components/FormComponents/RadioButtonGroup';
import { useNewBooking } from 'contexts/index';
import { UpdateFormByName } from 'helpers/quickly';
import { labelYupError } from 'helpers/yupLocales';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';

import { IBookingFormProps } from '../Booking';

const BookingOrder: FC<IBookingFormProps> = ({ next, validation }) => {
  const fieldNames = {
    orderType: 'Bestillingstype',
    location: 'Søk etter by eller sted',
    guestCount: 'Antall gjester',
    arriveDeparture: 'Ankomst - Avreise',
  };

  const {
    arenas,
    errors,
    setErrors,
    bookingSearch,
    setBookingSearch,
    location,
    unavailableDates,
  } = useNewBooking();

  const handleSubmit = async () => {
    try {
      if (validation)
        await validation.validate(bookingSearch, {
          abortEarly: false,
          context: fieldNames,
        });
      setErrors({});
      if (next) next();
    } catch (err: DeliberateAny) {
      const validationErrors = {};
      err.inner.forEach(
        (error) =>
          (validationErrors[error.path] = labelYupError(error, fieldNames)),
      );
      setErrors(validationErrors);
    }
  };

  return (
    <div className="fb-new fb-bo-container space">
      <p>
        Du kan bestille for deg selv, en gruppe eller som arrangør.{' '}
        <Link to={'/info/booking-terms'}>Les vilkår for overnatting</Link>
      </p>

      <div className="fb-form">
        <RadioButtonGroup
          name="orderType"
          label={fieldNames.orderType}
          errors={errors}
          formHolder={bookingSearch}
          updateForm={(id, value) =>
            UpdateFormByName(id, value, setBookingSearch)
          }
          helpInfo={<p>Info om Bestillingstype!</p>}
          options={[
            { label: 'Uten referansekode', value: '0' },
            { label: 'Med referansekode', value: '1' },
            { label: 'Jeg trenger referansekode', value: '2' },
          ]}
        />

        <ComboSelectBox
          name="location"
          label={fieldNames.location}
          options={
            arenas?.map((arena) => {
              return { value: arena.locationId, label: arena.name };
            }) ?? []
          }
          required
          placeHolder="Søk etter..."
          formHolder={bookingSearch}
          updateForm={(id, value) =>
            UpdateFormByName(id, value, setBookingSearch)
          }
          errors={errors}
          requiredChars={2}
          openOnDown
        />

        <Counter
          name="guestCount"
          label={fieldNames.guestCount}
          errors={errors}
          formHolder={bookingSearch}
          updateForm={(id, value) =>
            UpdateFormByName(id, value, setBookingSearch)
          }
          min={1}
          defaultValue={1}
        />

        <DateSelector
          placeHolder="Velg dato"
          mode={DatePickerMode.Range}
          disabled={!location?.locationId}
          name={'arriveDeparture'}
          label={fieldNames.arriveDeparture}
          formHolder={bookingSearch}
          updateForm={(id, value) =>
            UpdateFormByName(id, value, setBookingSearch)
          }
          errors={errors}
          helpPanel={
            <AlertMessage variant={AlertType.Info}>
              <p>
                For bestilling med under to dager til ankomst, vennligst kontakt{' '}
                <Link to={'/'}> Forsvarsbygg Servicesenter</Link>
              </p>
            </AlertMessage>
          }
          minDate={new Date()}
          unavailableDays={unavailableDates}
        />
      </div>
      <ButtonRow>
        <ButtonRow.Right className="primary" onClick={handleSubmit}>
          Søk etter overnatting
        </ButtonRow.Right>
      </ButtonRow>
    </div>
  );
};

export default BookingOrder;
