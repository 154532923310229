import useLocalStorage from 'hooks/useLocalStorage';
import { FC, ReactElement, useEffect, useState } from 'react';

import { useUser } from '../../contexts';
import Loader from '../Loader/Loader';
import NotFound from 'src/pages/NotFound';

export enum FeatureEnum {
  BookingV2 = 'booking-v2',
}

interface FeatureRouteProps {
  feature: FeatureEnum;
  children: ReactElement;
}

const FeatureRoute: FC<FeatureRouteProps> = ({ feature, children }) => {
  const [jwt] = useLocalStorage('jwtToken', '');
  const { requestLoading } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(requestLoading);
  }, [requestLoading]);

  const hasFeature = () => {
    try {
      const base64Url = jwt.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

      let jsonPayload;

      // Use atob if in a browser environment
      jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      const parsedJsonPayload = JSON.parse(jsonPayload);

      let features = [] as string[];
      for (const key in parsedJsonPayload) {
        if (
          Object.prototype.hasOwnProperty.call(parsedJsonPayload, key) &&
          key.includes('claims/feature')
        ) {
          features.push(parsedJsonPayload[key]);
        }
      }

      return features.includes(feature);
    } catch (error) {
      return;
    }
  };

  if (isLoading) {
    return <Loader isLoading={true} />;
  }

  return hasFeature() ? children : <NotFound />;
};

export default FeatureRoute;
