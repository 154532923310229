import './NotificationsColumns.scss';

const NotificationsColumns = [
  {
    label: '',
    name: 'New',
    className: 'c5',
  },
  {
    label: 'Dato',
    name: 'Created',
    className: 'c15',
  },
  {
    label: 'Beskjed',
    name: 'Message',
    className: 'c60',
  },
  {
    label: 'Kilde',
    name: 'Source',
    className: 'c10',
  },
];

export default NotificationsColumns;
