import { createContext, FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IWrapper } from "src/interfaces/IWrapper";

export const SlidesContext = createContext({});

interface ISlideProps extends IWrapper {
  baseUrl: string;
  rootUrl?: string;
}

export const SlidesContextProvider: FC<ISlideProps> = ({
  children,
  baseUrl,
  rootUrl,
}) => {
  const navigate = useNavigate();

  const [currentSlide, setCurrentSlide] = useState<string>("");
  const [slideHistory, setSlideHistory] = useState<string[]>([]);

  const goToSlide = (slidePath: string, reset?: boolean) => {
    setCurrentSlide(slidePath);
    if (reset) setSlideHistory([]);
    else
      setSlideHistory((prev) =>
        prev && prev.length > 0 ? [...prev, slidePath] : ["", slidePath]
      );
    navigate(slidePath);
  };

  const canNavigateBack = (): boolean => slideHistory.length > 0;

  const navigateBack = (previousSlideOverrule?: string) => {
    if (previousSlideOverrule) {
      const newHistory = [...slideHistory];
      newHistory.pop();
      setSlideHistory(newHistory);

      navigate(previousSlideOverrule);
      return;
    }
    if (canNavigateBack()) {
      const newHistory = [...slideHistory];
      newHistory.pop();
      setSlideHistory(newHistory);

      const previousSlide = newHistory[newHistory.length - 1];
      if (previousSlide) {
        navigate(previousSlide);
        return;
      }
    }
    return navigate(rootUrl ? `/${rootUrl}` : `/${baseUrl}`);
  };

  const values = useMemo(
    () => ({
      currentSlide,
      slideHistory,
      baseUrl,
      setSlideHistory,
      goToSlide,
      navigateBack,
      canNavigateBack,
    }),
    [currentSlide, slideHistory]
  );

  return (
    <SlidesContext.Provider value={values}>
      <>{children}</>
    </SlidesContext.Provider>
  );
};

export default SlidesContext;
