// @flow

import './ListItem.scss';

import DownloadLink from 'components/DownloadLink/DownloadLink';
import LabelValueList from 'components/LabelValueList/LabelValueList';
import { mediaQueries, MediaQuery } from 'helpers/mediaQuery';
import { formatDate, isServerDate } from 'helpers/valueHelpers';
import { Component, ReactNode } from 'react';
import { UnmountClosed } from 'react-collapse';
import { DeliberateAny } from 'types/DelibrateAny';
import {
  ActionButtonType,
  DetailsButtonType,
  ListColumnType,
  ListDetailType,
} from 'types/listTypes';
import { v4 } from 'uuid';

import OverviewContainer, {
  OverviewContainerPropsType,
} from './OverviewContainer/OverviewContainer';

type PropsType = {
  columns: Array<ListColumnType>;
  renderDetailsFunction?: () => ReactNode | ReactNode[];
  extendedDetailsContainer?: ReactNode | ReactNode[];
  details?: Array<ListDetailType>;
  customDetailsButton?: DetailsButtonType;
  actionButton?: ActionButtonType;
  item: DeliberateAny;
  mountAsExpanded?: boolean;
  minimalDetails?: boolean;
  passStylingToList?: {
    color?: string;
    border?: boolean;
    padding?: boolean;
    receiptStyle?: boolean;
    styleInHalf?: boolean;
  };
  highlight?: boolean;
  counter?: DeliberateAny;
  related?: DeliberateAny;
  xpandDescription?: string;
  documentList?: DeliberateAny[];
};

class ListItem extends Component {
  declare props: PropsType;

  state: {
    showDetails: boolean;
    xpandDescriptionIsOpen: boolean;
  };

  constructor(props: PropsType) {
    super(props);

    this.state = {
      showDetails: false,
      xpandDescriptionIsOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.mountAsExpanded) {
      this.toggleDetails();
    }
  }

  onCustomDetailsClick = () => {
    if (this.props.customDetailsButton) {
      this.props.customDetailsButton.onClick(this.props.item);
    }
  };

  setupDetailsButton = () => {
    if (this.props.customDetailsButton) {
      return {
        ...this.props.customDetailsButton,
        onClick: this.onCustomDetailsClick,
      };
    } else if (this.shouldRenderDetails()) {
      return {
        expanded: this.state.showDetails,
        onClick: this.toggleDetails,
      };
    }
    return undefined;
  };

  getColumnsWithValue = () => {
    const item = this.props.columns.map((column) => ({
      ...column,
      value: this.props.item[column.name],
    }));
    return item;
  };

  getDetailsWithValue = () => {
    if (this.props.details && this.props.details.length > 0) {
      return this.props.details.map((detail) => ({
        ...detail,
        value: this.props.item[detail.name],
      }));
    }
    return [];
  };

  toggleDetails = () => {
    this.setState({
      showDetails: !this.state.showDetails,
    });
  };

  toggleXpandDescription = () =>
    this.setState({
      xpandDescriptionIsOpen: !this.state.xpandDescriptionIsOpen,
    });

  shouldRenderDetails = () =>
    (this.props.details && this.props.details.length > 0) ||
    this.props.renderDetailsFunction;

  formatIfDateField = (item) =>
    !['RoomNumber', 'RegionNumber', 'PricePerMonth', 'Region'].includes(
      item.name,
    ) && isServerDate(item.value)
      ? formatDate(item.value)
      : item.value;

  renderDetails = () => {
    let details;
    let styling;

    if (this.props.minimalDetails) {
      details = this.getDetailsWithValue();
    } else {
      details = [...this.getColumnsWithValue(), ...this.getDetailsWithValue()];
    }
    if (this.props.passStylingToList) {
      styling = this.props.passStylingToList;
    }

    return (
      <LabelValueList
        items={details.map((item) => ({
          label: item.label,
          value: this.formatIfDateField(item),
          suffix: item.suffix,
        }))}
        style={styling}
      />
    );
  };

  renderRelated = () => {
    let styling;
    if ((this.props?.related?.length ?? 0) < 1) {
      return;
    }
    if (this.props.passStylingToList) {
      styling = this.props.passStylingToList;
    }
    const rets: ReactNode[] = [];
    this.props.related.forEach((el) => {
      rets.push(
        <div key={v4()}>
          <h3 className="label-value-list-header">{el.title}</h3>
          <LabelValueList
            items={el.data.map((item) => ({
              label: item.label,
              value: this.formatIfDateField(item),
              suffix: '',
            }))}
            style={styling}
          />
        </div>,
      );
    });
     
    return rets;
  };

  renderDocumentlist = () => {
    if ((this.props.documentList?.length ?? 0) < 1) {
      return [];
    }
    let styling;
    if (this.props.passStylingToList) {
      styling = this.props.passStylingToList;
    }
    const rets: ReactNode[] = [];
    this.props.documentList?.forEach((doc) => {
      rets.push(
        <LabelValueList
          key={doc.code}
          items={[
            {
              label: 'Vedlegg',
              value: <DownloadLink href={doc.path}>{doc.name}</DownloadLink>,
              suffix: '',
            },
          ]}
          style={styling}
        />,
      );
    });
    return rets;
  };

  render() {
    const detailsButton = this.setupDetailsButton();
    const columns = this.getColumnsWithValue();
    const renderDetailsFunction = this.shouldRenderDetails()
      ? this.props.renderDetailsFunction || this.renderDetails
      : false;
    const overviewContainerProps: OverviewContainerPropsType = {
      item: this.props.item,
      columns,
      detailsButton,
      actionButton: this.props.actionButton,
      counter: this.props.counter,
      highlight: this.props.highlight,
    };

    return (
      <li>
        <MediaQuery query={mediaQueries.mediumUp}>
          <OverviewContainer {...overviewContainerProps} />
        </MediaQuery>

        <MediaQuery query={mediaQueries.small}>
          <OverviewContainer {...overviewContainerProps} small />
        </MediaQuery>

        {renderDetailsFunction && (
          <UnmountClosed isOpened={this.state.showDetails}>
            <div className="details-container">
              {this.props.extendedDetailsContainer &&
              !this.props.minimalDetails ? (
                <div className="details-divider">{renderDetailsFunction()}</div>
              ) : (
                renderDetailsFunction()
              )}
              {this.props.documentList && (
                <ul className="label-value-list">
                  {this.renderDocumentlist()}
                </ul>
              )}
              {this.props.related && <div>{this.renderRelated()}</div>}
              {this.props.extendedDetailsContainer}
            </div>
          </UnmountClosed>
        )}
      </li>
    );
  }
}

export default ListItem;
