import moment from "moment";

const initialState = {
  arrivalDate: moment("2018-05-12"),
  departureDate: moment(),
  numberOfGuests: 1,
  numberOfRooms: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_ARRIVAL_DATE":
      return { ...state, arrivalDate: action.date };

    case "SET_DEPARTURE_DATE":
      return { ...state, departureDate: action.date };

    case "SET_NUMBER_OF_GUESTS":
      return { ...state, numberOfGuests: action.value };

    case "SET_NUMBER_OF_ROOMS":
      return { ...state, numberOfRooms: action.value };

    default:
      return state;
  }
};
