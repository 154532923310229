import './Counter.scss';

import { AddIcon, SubtractIcon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import { IFormPropBase } from 'components/FormComponents/FormPropBase';
import HelpText from 'components/HelpText';
import { FC, useEffect } from 'react';

export enum CountAction {
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT',
}

interface ICounterProps extends IFormPropBase {
  min?: number;
  max?: number;
  defaultValue?: number;
}

const Counter: FC<ICounterProps> = ({
  name,
  label,
  formHolder,
  updateForm,
  errors,
  helpInfo,
  disabled,
  defaultValue,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
}) => {
  const parseValue = () => parseInt(formHolder[name] ?? defaultValue, 10) || 0;

  useEffect(() => {
    if (defaultValue) updateForm(name, parseValue());
  }, []);

  const handleCountChange = (action: CountAction) => {
    const currentValue = parseValue();
    let newValue = currentValue;

    if (action === CountAction.INCREMENT && currentValue < max) {
      newValue = currentValue + 1;
    } else if (action === CountAction.DECREMENT && currentValue > min) {
      newValue = currentValue - 1;
    }

    updateForm(name, newValue);
  };

  return (
    <div className="fb-counter">
      <HelpText content={helpInfo}>
        <label className={`fb-form-label ${errors[name] ? 'error' : ''}`}>
          {errors[name] ? errors[name] : label ? label : name}
        </label>
      </HelpText>
      <div className="fb-controller">
        {/* Decrement Button */}
        <BasenIcon
          className={`icon-button ${parseValue() <= min ? 'disabled' : ''}`}
          onClick={() => handleCountChange(CountAction.DECREMENT)}
          icon={SubtractIcon}
          disabled={parseValue() <= min || disabled}
        />

        {/* Input Field */}
        <input
          className="counter-no"
          value={parseValue()}
          onChange={(e) => {
            const inputValue =
              e.target.value === '' ? 0 : parseInt(e.target.value, 10);
            if (inputValue <= max && inputValue >= min) {
              updateForm(name, inputValue);
            }
          }}
          disabled={disabled}
        />

        {/* Increment Button */}
        <BasenIcon
          className={`icon-button ${parseValue() >= max ? 'disabled' : ''}`}
          onClick={() => handleCountChange(CountAction.INCREMENT)}
          icon={AddIcon}
          disabled={parseValue() >= max || disabled}
        />
      </div>
    </div>
  );
};

export default Counter;
