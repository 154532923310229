import './DocumentForSigningItem.scss';

import { createSignatureJobForXpandDocument } from 'actions/signatureActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import AdditionalSigningModal from 'components/modals/AdditionalSigningModal/AdditionalSigningModal';
import {
  SIGNATURE_STATE_ERROR,
  SIGNATURE_STATE_REJECTION,
  SIGNATURE_STATE_SUCCESS,
} from 'constants/signatureConstants';
import connect from 'helpers/connectWithRouter';
import queryString from 'query-string';
import { Component } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';
import { SignatureJobXpandRequestType } from 'types/signatureTypes';

type PropsType = {
  document: DeliberateAny;
  serviceOrderID: string;
  createSignatureJobForXpandDocument: (
    jobRequest: SignatureJobXpandRequestType,
  ) => string;
  location: Location;
  navigate: NavigateFunction;
};

export class UnconnectedAdditionalServicesServiceOrderDocumentForSigningItem extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
  }

  createSignatureJob = (ssn: string, rejectionReason?: string  ) => {
    const createUrl = (signingState) =>
      `#/basen-proff/tab-additionalservices?${queryString.stringify({
        serviceorderID: this.props.serviceOrderID,
        documentId: this.props.document.DocumentNumber,
        revision: this.props.document.Revision,
        signingState,
      })}`;
    const completionUrl = createUrl(SIGNATURE_STATE_SUCCESS);
    const errorUrl = createUrl(SIGNATURE_STATE_ERROR);
    const rejectionUrl = createUrl(SIGNATURE_STATE_REJECTION);

    this.props.createSignatureJobForXpandDocument({
      identityNumber: ssn,
      completionUrl,
      rejectionUrl,
      errorUrl,
      documentId: this.props.document.DocumentNumber,
      fileName: this.props.document.Name,
      revision: this.props.document.Revision,
      rentalId: this.props.serviceOrderID,
    });
  };

  startSigningModalPath = () =>
    `${this.props.location.pathname}/${this.props.document.DocumentNumber}/start-signering`;

  acceptSigning = (ssn) => {
    this.createSignatureJob(ssn);
  };

  handleOpenStartSigningModal = () => {
    this.props.navigate(this.startSigningModalPath());
  };

  AdditionalSigningModal = () => (
    <AdditionalSigningModal
      title="Start elektronisk signering"
      ariaLabel="Start elektronisk signering"
      path={this.startSigningModalPath()}
      documentNumber={this.props.document.DocumentNumber}
      acceptSigning={this.acceptSigning}
      description={''}
    />
  );

  render() {
    return (
      <div className="document-for-signing-item">
        <ButtonRow>
          <ButtonRow.Left
            color="red"
            onClick={this.handleOpenStartSigningModal}
          >
            Start signering
          </ButtonRow.Left>
        </ButtonRow>
        <this.AdditionalSigningModal />
      </div>
    );
  }
}

export default connect(null, {
  createSignatureJobForXpandDocument,
})(UnconnectedAdditionalServicesServiceOrderDocumentForSigningItem);
