/* eslint react/prop-types: 0 */

import Loader from "components/Loader/Loader";
import ExportList from "components/serviceorders/ServiceOrderExportList";
import ServiceOrderList from "components/serviceorders/ServiceOrderList";
import { useModal } from "contexts/index";
import useAxios from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import { proffSearchURL } from '../../../../../../api/basenproff/basenproff_api';

const PortfolioCaseResultsList = ({ building, typeFilter }) => {
  const [items, setItems] = useState([]);
  const [pageNumber, setPageNumber] = useState([]);
  const [skipCount, setSkipCount] = useState<DeliberateAny>(0);

  const { requestLoading, requestError, sendRequest } = useAxios();
  const { showModal } = useModal();

  const takeCount = 5;
  useEffect(() => {
    const fetchSearchFault = async () => {
      await sendRequest(
        {
          method: "GET",
          url: proffSearchURL,
          params: {
            typeFilter,
            building,
            takeCount,
            skipCount,
          },
        },
        setItems
      );
    };
    fetchSearchFault();
  }, [skipCount]);

  useEffect(() => {
    if (requestError) {
      showModal({
        title: "Beklager",
        infoText: "Det skjedde en feil",
      });
    }
  }, [requestError]);

  const serverPaging: DeliberateAny = {
    pageNumber,
    pageSize: 10,
    numItems: items.length,
    onChangePageNumber: (number) => {
      setPageNumber(number);
      navigateToPage(number);
    },
  };

  const navigateToPage = (pagenum) => {
    const page = { SkipCount: (pagenum - 1) * takeCount };
    setSkipCount(page);
  };

  return (
    <Loader isLoading={requestLoading}>
      <div className="button-row half-margin">
        <p className="no-margin">
          <ExportList items={items} />
        </p>
      </div>
      <ServiceOrderList
        items={items}
        serverPaging={serverPaging}
        urlRoot="/basenproff"
      />
    </Loader>
  );
};
export default PortfolioCaseResultsList;
