import {
  buildingsURL,
  establishmentsURL,
  reportingUsersURL,
} from 'api/basenproff/basenproff_api';
import Input from 'components/form/Input/Input';
import Select from 'components/form/Select/Select';
import { ModalTypes } from 'constants/modalTypes';
import { useModal } from 'contexts/index';
import useAxios, { axiosFetch } from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

const ServiceOrderFilter = ({
  setFilterAndSearch,
  opstat,
  optype,
  loading,
  opbras,
  opdifs,
  opmarketingareas,
}) => {
  const initialStates = {
    StateFilter: -1,
    TypeFilter: -1,
    ServiceOrderNumber: '',
    MarketingArea: -1,
    Bra: -1,
    Dif: -1,
    Establishment: -1,
    Building: -1,
    BuildingType: 0,
    Reporter: -1,
  };
  // const [reporter, setReporter] = useState({});
  const [toggleExtraFilters, setToggleExtraFilters] = useState(false);
  const [establishmentOptions, setEstablishmentOptions] = useState<
    DeliberateAny[]
  >([]);
  const [buildingOptions, setBuildingOptions] = useState<DeliberateAny[]>([]);
  const [reporterState, setReporterState] = useState<DeliberateAny>();
  const [reportingUsers, setReportingUsers] = useState<string>();
  const { sendRequest: fetchReportingUsersOptions } = useAxios();

  // filter-Objekt som sendes med submit
  const [filterState, setFilterState] = useState<DeliberateAny>(initialStates);

  const { showModal } = useModal();

  useEffect(() => {
    fetchReportingUsersOptions(
      {
        method: 'GET',
        url: reportingUsersURL,
      },
      setReportingUsers,
    );
  }, []);

  useEffect(() => {
    if (establishmentOptions.length === 0)
      setFilterState({ ...filterState, Establishment: -1 });
  }, [establishmentOptions]);

  useEffect(() => {
    if (buildingOptions.length === 0)
      setFilterState({ ...filterState, Building: -1, BuildingType: 0 });
  }, [buildingOptions]);

  const handleFilterChange = (name, value) => {
    setFilterState({ ...filterState, [name]: value });

    if (name === 'MarketingArea') {
      axiosFetch({
        url: establishmentsURL,
        data: {
          MarketingArea: value,
        },
      }).then((response) => setEstablishmentOptions(response));
    } else if (name === 'Establishment') {
      axiosFetch({
        url: buildingsURL,
        data: {
          Establishment: value,
          MarketingArea: filterState.MarketingArea,
        },
      }).then((response) =>
        setBuildingOptions(
          response.map((building) => ({
            Key: building.Code,
            Value: building.Value,
            Type: building.Type,
          })),
        ),
      );
    }
  };

  const handleBuildingFilterChange = (name, value) => {
    if (value === -1) {
      setFilterState({
        ...filterState,
        [name]: value,
        BuildingType: 0,
      });
    } else {
      const selectedOption = buildingOptions.find(
        (bo) => bo.Key.toString() === value.toString(),
      );
      if (selectedOption) {
        setFilterState({
          ...filterState,
          [name]: value,
          BuildingType: selectedOption.Type,
        });
      }
    }
  };

  const handleUnselectedValues = (values) => {
    const object = initialStates;
    Object.entries(values).forEach((val) => {
      const key = val[0];
      const value = val[1];
      if (value === -1) {
        object[key] = '';
      } else {
        object[key] = value;
      }
    });
    return object;
  };

  const handleSubmit = () => {
    setFilterAndSearch(handleUnselectedValues(filterState));
  };

  const handleClear = () => {
    setFilterState(initialStates);
    setFilterAndSearch(handleUnselectedValues(initialStates));
  };

  const reporterModal = () => {
    const handleReporterState = (data) => {
      if (data) {
        setFilterState({
          ...filterState,
          Reporter: data.Key,
        });
        setReporterState({ name: data.Value, key: data.Key });
      } else {
        setFilterState({
          ...filterState,
          Reporter: '',
        });
        setReporterState(null);
      }
    };

    showModal({
      modalType: ModalTypes.SearchableList,
      title: 'Velg innmelder',
      data: reportingUsers,
      submitAction: (data) => handleReporterState(data),
    });
  };

  const [showOnMobile, setShowOnMobile] = useState(false);

  return (
    <div>
      <div className="row double-margin">
        <div className="case-filter form">
          <header className="hide-for-medium hide-for-large buttons margin">
            <button
              className="btn light-grey toggle-filters"
              onClick={() => setShowOnMobile(!showOnMobile)}
            >
              Filtrer
            </button>
          </header>
          <div
            className={`small-expandable ${
              showOnMobile ? 'mobile-filter-visible' : ''
            }`}
          >
            <div className="content-group grey padded expand-container">
              <div className="input-container">
                <div className="flex-row margin">
                  <Input
                    title="SO-nummer"
                    name="ServiceOrderNumber"
                    type="text"
                    id="ServiceOrderNumber"
                    value={filterState.ServiceOrderNumber}
                    onChange={handleFilterChange}
                  />
                  <Select
                    name="StateFilter"
                    label="Status"
                    helpText="Status beskrivelser nytt"
                    options={opstat.map((el) => {
                      return {
                        key: el.Key,
                        value: el.Value,
                      };
                    })}
                    value={filterState.StateFilter}
                    onChange={handleFilterChange}
                    showAllOption
                    loading={loading}
                  />
                  <label>
                    <span>Innmelder</span>
                    <button
                      className="people-picker-button"
                      onClick={() => reporterModal()}
                    >
                      {reporterState ? (
                        <span className="name" key={reporterState.key}>
                          {reporterState.name}
                        </span>
                      ) : (
                        <span className="name">{'Vis alle'}</span>
                      )}
                    </button>
                  </label>
                </div>
                {toggleExtraFilters && (
                  <div className="expandable">
                    <div className="expand-container">
                      <div className="flex-row margin">
                        <Select
                          name="Dif"
                          label="DIF"
                          options={opdifs?.map((el) => ({
                            key: el.Key,
                            value: el.Value,
                          }))}
                          value={filterState.Dif}
                          onChange={handleFilterChange}
                          showAllOption
                          loading={loading}
                        />
                        <Select
                          name="Bra"
                          label="BRA"
                          options={opbras?.map((el) => ({
                            key: el.Key,
                            value: el.Value,
                          }))}
                          value={filterState.Bra}
                          onChange={handleFilterChange}
                          showAllOption
                          loading={loading}
                        />
                        <Select
                          name="TypeFilter"
                          label="Type"
                          options={optype?.map((el) => ({
                            key: el.Key,
                            value: el.Value,
                          }))}
                          value={filterState.TypeFilter}
                          onChange={handleFilterChange}
                          showAllOption
                          loading={loading}
                        />
                      </div>
                      <div className="flex-row margin">
                        <Select
                          name="MarketingArea"
                          label="Distrikt"
                          options={opmarketingareas}
                          value={filterState.MarketingArea}
                          onChange={handleFilterChange}
                          showAllOption
                          loading={loading}
                        />
                        <Select
                          name="Establishment"
                          label="Etablissement"
                          options={
                            establishmentOptions.length &&
                            establishmentOptions.map((el) => ({
                              key: el.Key,
                              value: el.Value,
                            }))
                          }
                          value={filterState.Establishment}
                          onChange={handleFilterChange}
                          loading={
                            filterState.MarketingArea > -1 &&
                            establishmentOptions.length === 0
                          }
                          dependentOn="distrikt"
                          showAllOption
                        />
                        <Select
                          name="Building"
                          label="Bygning/Uteareal"
                          options={
                            buildingOptions.length &&
                            buildingOptions.map((el) => ({
                              key: el.Key,
                              value: el.Value,
                            }))
                          }
                          value={filterState.Building}
                          onChange={handleBuildingFilterChange}
                          loading={
                            filterState.Establishment > -1 &&
                            buildingOptions.length === 0
                          }
                          dependentOn="establishment"
                          showAllOption
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="button-row">
                <div>
                  <button className="btn red" onClick={handleSubmit}>
                    Filtrer
                  </button>
                  <button className="btn grey" onClick={handleClear}>
                    Tøm filter
                  </button>
                </div>
                <button
                  className="btn red"
                  onClick={() => setToggleExtraFilters(!toggleExtraFilters)}
                >
                  {toggleExtraFilters ? 'Færre filter' : 'Flere filter'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceOrderFilter;
