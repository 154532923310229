import moment from "moment";

const initialState = {
  search: {
    items: [],
    filter: {
      pageSize: 10,
      pageNumber: 0,
      pageCount: 0,
      sortByColumn: "Name",
      BookingStatus: 0,
      checkInStatusLoaded: false,
    },
    isLoading: true,
    ModalGuestBeenCheckedInn: false,
    checkInStatusFilter: [],
    firstSearch: true,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_GUESTS_FILTER":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            [action.key]: action.value,
          },
        },
      };
    case "RECEIVE_GUESTS_SEARCH":
    case "RECEIVE_LOCATION_GUESTS": {
      /*      const bookingId = action.BookingId; */
      const items = action.data.map((item) => ({
        ...item,
        Reference: item.Reference,
        GuestName: item.GuestName,
        GuestPhone: item.GuestPhone,
        GuestReference: item.GuestReference,
        Location: item.Location,
        FromDate: moment(item.ArrivalDate).format("YYYY-MM-DD"),
        ToDate: moment(item.DepartureDate).format("YYYY-MM-DD"),
        HasCheckedIn: item.HasCheckedIn,
        expandMe: action.data.length === 1,
        Origin: item.Origin,
        ShowCheckInButton: item.ShowCheckInButton,
        ShowNewKeyButton: item.ShowNewKeyButton,
      }));
      return {
        ...state,
        search: {
          ...state.search,
          items,
          isLoading: false,
        },
      };
    }

    case "FETCH_GUESTS_SEARCH":
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: true,
          firstSearch: false,
        },
      };

    case "UPDATE_CHECKIN_STATUS":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            BookingStatus: action.value,
          },
        },
      };

    case "UPDATE_GUEST_PHONE":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            requestNewKey: action.value,
          },
        },
      };

    case "SET_FROM_DATE":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            fromDate: action.date,
          },
        },
      };

    case "SET_TO_DATE":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            toDate: action.date,
          },
        },
      };

    case "RESET_GUESTS_FILTER":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...initialState.search.filter,
          },
        },
      };

    case "FETCH_CHECKIN_STATUS":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
          },
        },
      };

    case "RECEIVE_CHECKIN_STATUS":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            checkInStatusLoaded: true,
          },
          checkInStatusFilter: action.data,
        },
      };

    case "RECEIVE_GUEST_CHECKIN_RULES":
      return {
        ...state,
        search: {
          ...state.search,
          guestsInfoText: action.data,
        },
      };

    case "FETCH_GUESTS_NEW_CHECKIN_STATUS":
      return state;
    case "RECEIVE_GUESTS_NEW_CHECKIN_STATUS":
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
        },
      };
    case "SORT_GUEST_LIST":
      return {
        ...state,
        search: {
          ...state.search,
          items: action.sorted,
        },
      };
    case "MODAL_GUEST_CHECKED_INN":
      return {
        ...state,
        search: {
          ...state.search,
          ModalGuestBeenCheckedInn: !state.search.ModalGuestBeenCheckedInn,
          ModalMessage: action.message,
        },
      };

    case "FETCH_GUESTS_NEW_KEY":
      return state;
    case "RECEIVE_GUESTS_NEW_KEY":
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};
