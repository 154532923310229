// @flow

import List from 'components/List/List';
import { v4 as uuidv4 } from 'uuid';

import ServiceOrderColumns from './ServiceOrderColumns';
import ServiceOrderItem from './ServiceOrderItem';

import type { ServiceOrderType } from '../../types/serviceOrderTypes';
import type { ServerPagingType } from '../../types/listTypes';
type PropsItemType = {
  item: ServiceOrderType;
  urlRoot: string;
};

type PropsServiceOrderListType = {
  items: Array<ServiceOrderType>;
  serverPaging: ServerPagingType;
  urlRoot?: string;
  update?: () => void;
};

const ServiceOrderList = ({
  items,
  serverPaging,
  update,
}: PropsServiceOrderListType) => {
  const renderItem = (item: ServiceOrderType) => {
    return (
      <ServiceOrderItem
        key={uuidv4()}
        item={item}
        columns={ServiceOrderColumns}
        update={update}
      />
    );
  };

  return (
    <List
      columns={[
        ...ServiceOrderColumns,
        { label: '', value: '', className: 'space30' },
      ]}
      renderItem={renderItem}
      items={items}
      noItemsMessage={<p>Ingen saker</p>}
      serverPaging={serverPaging}
    />
  );
};

export default ServiceOrderList;
