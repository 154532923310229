 
import { rapporterSakURL } from 'api/basenproff/basenproff_api';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Dropzone from 'components/Dropzone/Dropzone';
import Checkbox from 'components/form/Checkbox/Checkbox';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import HasPetConfirmation from 'components/form/HasPetConfirmation/HasPetConfirmation';
import Input from 'components/form/Input/Input';
import SubcontractorConfirmation from 'components/form/SubcontractorConfirmation/SubcontractorConfirmation';
import TextArea from 'components/form/TextArea/TextArea';
import UnclassifiedConfirmation from 'components/form/UnclassifiedConfirmation/UnclassifiedConfirmation';
import Loader from 'components/Loader/Loader';
import ApplicationSummaryModal from 'components/ReportIssue/ApplicationSummaryModal';
import Consent from 'components/ReportIssue/Consent';
import ContactDetails from 'components/ReportIssue/ContactDetails';
import LocationInfoSelect from 'components/ReportIssue/LocationInfoSelect';
import generateFormData from 'helpers/fileHelpers';
import useAxios from 'hooks/useAxios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';
import * as yup from 'yup';

const NoAuthReporting = () => {
  const navigate = useNavigate();
  const { sendRequest, requestLoading } = useAxios();
  const [formErrors, setFormErrors] = useState<DeliberateAny[]>([]);
  const [form, setForm] = useState<DeliberateAny>({});

  const [files, setFiles] = useState<DeliberateAny>([]);
  const [deliveryDateCheckbox, setDeliveryDateCheckbox] = useState(false);
  const [confirmData, setConfirmData] = useState<DeliberateAny>();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onFormChange = (key: string, value: string) => {
    setForm((x) => {
      x[key] = [value];
      return x;
    });
  };

  const updateFormHolder = (name: string, value: DeliberateAny) => {
    setForm((fh) => {
      return { ...fh, [name]: value };
    });
  };

  const updateSingleError = (error) => {
    setFormErrors((x) => [...x, error]);
  };

  const updateAllErrors = (errors: DeliberateAny) => {
    setFormErrors(errors);

     
    const formElements = Object.keys(formProps.form);
    const firstInputValidationError = formElements.find((el) =>
      Object.keys(errors).includes(el),
    );
    const scrollElement = document.getElementById(
      `error_${firstInputValidationError}`,
    );
    if (scrollElement) scrollElement.scrollIntoView();
  };

  const handleDeliveryDateCheckbox = () => {
    // if user sets checkbox from true to false, reset deliveryDate
    if (deliveryDateCheckbox) {
      updateFormHolder('requestedDeliveryTime', '');
    }
    setDeliveryDateCheckbox(!deliveryDateCheckbox);
  };

  const handleShowConfirmModal = () => {
    const getModalData = () => {
      const getBuilding = () => {
        return {
          label: 'Bygning/bygningsnummer',
          value: form.building,
        };
      };

      const getLocation = () =>
        form.location && {
          label: 'Leir eller boligadresse',
          value: form.location,
        };

      const getDeliveryDate = () =>
        deliveryDateCheckbox && {
          label: 'Ønsket leveringsdato',
          value: form.requestedDeliveryTime,
        };

      return [
        { label: 'Overskrift', value: form.title },

        getBuilding(),
        getLocation(),
        {
          label: 'Beskriv hva henvendelsen gjelder',
          value: form.description,
        },
        getDeliveryDate(),
        {
          label:
            'Samtykke til at Forsvarsbygg eller underleverandør kan låse seg inn i boenhet',
          value: form.ConsentSubcontractorAccess ? 'Ja' : 'Nei',
        },
        { label: 'Hund i bo-enheten', value: form.hasPet ? 'Ja' : 'Nei' },
        { label: 'Antall vedlegg', value: files.length },
      ];
    };

    const data: DeliberateAny = getModalData();
    setConfirmData(data);
    setModalIsOpen(true);
  };

  const generateValidatorConfig = () => {
    const validatorConfig = {
      title: yup.string().required('required'),
      location: yup.string().required('required'),
      building: yup.string().required('required'),
      description: yup.string().required('required'),
      sendCopyToEmail: yup.string().email('invalid'),
      email: yup.string().email('invalid').required('required'),
      requestedDeliveryTime: yup.string().when([], {
        is: () => deliveryDateCheckbox,
        then: (s) => s.required('required'),
        otherwise: (s) => s,
      }),
      isUnclassified: yup.boolean().when([], {
        is: () => true,
        then: (s) => s.required('required'),
        otherwise: (s) => s.oneOf([true, false], 'required'),
      }),
      reporter: yup.string().required('required'),
      phone: yup.string().required('required'),
      personalConsent: yup.boolean().required('required'),
    };

    return yup.object().shape(validatorConfig).defined();
  };

  const formProps = {
    errors: formErrors,
    form,
    onChange: onFormChange,
    updateAllValidationErrors: updateAllErrors,
    updateOneValidationError: updateSingleError,
    onSubmit: () => handleShowConfirmModal(),
    schema: generateValidatorConfig(),
  };

  const handleResponse = (result: DeliberateAny) => {
    if (result) {
      navigate('/reporting/confirmation');
    }
  };

  const handleSubmit = async () => {
    const formData = generateFormData(
      { ...form },
      files,
      'serviceOrder',
      'Documents',
    );

    await sendRequest(
      {
        method: 'POST',
        url: rapporterSakURL,
        data: formData,
      },
      handleResponse,
    );
  };

  const addAttachment = (file) => {
    setFiles((state) => [
      ...state,
      {
        title: file.name,
        file,
      },
    ]);
  };

  const removeAttachment = (file) => {
    setFiles((prevState) =>
      prevState.filter((e) => e.file.preview !== file.file.preview),
    );
  };

  const updateAttachmentDescription = (fileName, fileTitle) => {
    setFiles((state) => {
      return state.map((item) =>
        item.file.name === fileName ? { ...item, title: fileTitle } : item,
      );
    });
  };

  return (
    <div>
      <Loader isLoading={requestLoading}>
        <FormWrapper {...formProps}>
          <div className="scrollable sticky-middle max-width clientsquare-report">
            <h2 className="unclassified-stamp">Ugradert</h2>
            <h1>Meld inn sak</h1>

            <div className="row margin">
              <div className="content-group grey padded">
                <div className="row margin" id="error_title">
                  <Input
                    type="text"
                    maxLength={60}
                    placeholder="Maks. 60 tegn"
                    name="title"
                    value={form.title}
                    onChange={(name, val) => updateFormHolder(name, val)}
                    title="Overskrift"
                    data-testid="serviceorder-input-title"
                    errors={formErrors}
                  />
                </div>
                <LocationInfoSelect
                  userLoggedIn={false}
                  otherPlacement={true}
                  formHolder={form}
                  regionNumber={form.regionNumber}
                  establishment={form.establishment}
                  building={form.building}
                  location={form.location}
                  errors={formErrors}
                  updateFormHolder={updateFormHolder}
                  datatestIdPrefix={'serviceorder-input'}
                />
                <div className="row margin" id="error_description">
                  <TextArea
                    name="description"
                    rows={5}
                    errors={formErrors}
                    value={form.description}
                    onChange={(name, val) => updateFormHolder(name, val)}
                    title="Beskriv hva henvendelsen gjelder"
                    data-testid="serviceorder-input-description"
                  />
                </div>
                <div className="row margin">
                  <Checkbox
                    errors={formErrors}
                    name={'deliveryDateCheckbox'}
                    label={'Ønsket leveringsdato (ikke påkrevd)'}
                    onChange={handleDeliveryDateCheckbox}
                    value={deliveryDateCheckbox}
                  />
                </div>
                {deliveryDateCheckbox && (
                  <div className="row margin dateinput">
                    <Input
                      errors={formErrors}
                      title="Ønsket leveringsdato"
                      name="requestedDeliveryTime"
                      value={form.requestedDeliveryTime}
                      onChange={(name, val) => updateFormHolder(name, val)}
                    />
                  </div>
                )}
              </div>
            </div>

            <ContactDetails
              errors={formErrors}
              formHolder={form}
              updateFormHolder={updateFormHolder}
              setFormHolder={setForm}
              alternativeNames={{
                name: 'reporter',
                mobile: 'phone',
                copy: 'copyEmailAddress',
              }}
            />
            <div className="row margin">
              <div className="content-group grey padded">
                Vedlegg
                <Dropzone
                  files={files}
                  addFile={addAttachment}
                  removeFile={removeAttachment}
                  updateFileDescription={updateAttachmentDescription}
                  formKey="attachments"
                  maxFiles={10}
                  showSizeInKB
                  deleteButtonStyle="nocolor"
                />
              </div>
            </div>

            <div className="row margin">
              <div id="error_isUnclassified">
                <UnclassifiedConfirmation
                  errors={formErrors}
                  checked={form.isUnclassified}
                  onChange={() =>
                    updateFormHolder('isUnclassified', !form.isUnclassified)
                  }
                />
              </div>
            </div>

            <div className="row margin">
              <SubcontractorConfirmation
                checked={form.ConsentSubcontractorAccess}
                onChange={() =>
                  updateFormHolder(
                    'ConsentSubcontractorAccess',
                    !form.ConsentSubcontractorAccess,
                  )
                }
              />
            </div>

            <div className="row margin">
              <HasPetConfirmation
                checked={form.hasPet}
                onChange={() => updateFormHolder('hasPet', !form.hasPet)}
              />
            </div>

            <div className="row margin">
              <Consent
                errors={formErrors}
                formHolder={form}
                updateFormHolder={updateFormHolder}
              />
            </div>

            <div className="divider" />
            <ButtonRow>
              <ButtonRow.Right
                color="red"
                className="btn"
                key="1"
                onClick={() => handleShowConfirmModal()}
                type="submit"
                data-testid="serviceorder-button-submit"
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                >
                  Send inn
                </div>
              </ButtonRow.Right>
            </ButtonRow>
          </div>
        </FormWrapper>

        {/* <Button onClick={handleFormData}>FORM</Button> */}
      </Loader>
      {modalIsOpen && (
        <ApplicationSummaryModal
          isOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          data={confirmData && confirmData.filter((el) => el)}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default NoAuthReporting;
