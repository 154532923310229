import './forms.scss';

import { useEffect, useState } from 'react';

import { DeliberateAny } from '../../types/DelibrateAny';

type PropsType = {
  rule: DeliberateAny;
  errortext?: string;
};

const getErrorMessage = (error: DeliberateAny, errortext?: string) => {
  const hasCustomText = typeof errortext !== 'undefined';

  switch (error.rule) {
    case 'required':
      return 'er obligatorisk!';
    case 'number':
      return 'må være et nummer!';
    case 'length.max':
      return `er for lang, maks ${error.max} tegn!`;
    case 'length.min':
      return `er for kort, min ${error.min} tegn!`;
    case 'length.exact':
      return `må være eksakt ${error.exact} tegn!`;
    case 'date.format':
      return `er ikke gyldig. Må være på formatet ${error.format}`;
    case 'date.max':
      return `er en ugyldig dato. Må være før ${error.max}`;
    case 'date.min':
      return `er en ugyldig dato. Må være etter ${error.min}`;
    case 'postalCode':
      return 'må bestå av nøyaktig fire siffer.';
    case 'postalServerError':
      return error.serverMessage ? error.serverMessage : 'Ugyldig';
    case 'wordBlacklist':
      if (error.field === 'rank') {
        return 'Ugyldig tjenestestilling';
      }
      return 'Ugyldig innhold';
    case 'rememberComment':
      return 'Husk at kommentar er obligatorisk når du svarer nei.';
    case 'invalid':
      return 'Ugyldig';
    case 'email':
      return 'Ugyldig';
    case 'phone':
      return 'Ugyldig';
    case 'wrongFormat':
      return 'er ugyldig';
    case 'length':
      return 'Ikke riktig format';
    case 'ugyldigKostnadsramme':
      return 'Ugyldig kostnadsramme';
    case 'duplicates':
      return 'er allerede brukt';
    case 'numeric':
      return 'må være et tall';
    case 'mainapplicant':
      return hasCustomText ? ` - ${errortext}` : 'er ikke hovedsøker';
    default:
      return errortext ?? '';
  }
};

const FormErrors = ({ rule, errortext }: PropsType) => {
  const [invalidText, setInvalidText] = useState<string>();
  useEffect(() => {
    setInvalidText(getErrorMessage(rule, errortext));
  }, [rule, errortext]);

  return <span className="invalid"> {invalidText}</span>;
};

export default FormErrors;
