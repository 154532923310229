import FBHelpers from "helpers/_helpers";
import { getTotalNumberOfRooms, getTotalPrice } from "helpers/bookingHelpers";

const BookingSummary = ({
  arrivalDate,
  departureDate,
  roomInfo,
  numberOfGuests,
  selectedLocation,
  roomInfoPerRoomType,
  isBlockBooking,
}) => {
  const prettyDate = () => (
    <p className="no-margin">
      <span>
        {`${FBHelpers.Date.dayString(arrivalDate.day(), true)}.`}
        <span className="red">{arrivalDate.date()}</span>
        {` ${FBHelpers.Date.monthString(arrivalDate.month(), true)}`}
      </span>
      {" - "}
      <span>
        {`${FBHelpers.Date.dayString(departureDate.day(), true)}.`}
        <span className="red">{departureDate.date()}</span>
        {` ${FBHelpers.Date.monthString(departureDate.month(), true)}`}
      </span>
    </p>
  );

  return (
    <div className="booking-summary-wrapper full">
      <div>
        <p className="booking-summary-location">{selectedLocation.Name}</p>
      </div>
      <div className="booking-summary-duration">{prettyDate()}</div>
      <div className="booking-summary-rooms-count">
        <span className="red">
          {getTotalNumberOfRooms(roomInfoPerRoomType)}
        </span>
        <span> rom</span>
      </div>
      <div className="booking-summary-guest-count">
        <span className="red">{numberOfGuests}</span> gjest(er)
      </div>
      <div className="booking-summary-price">
        <span>
          <strong>
            Kr{" "}
            <span className="red">{`${getTotalPrice(
              roomInfo,
              selectedLocation.Rooms,
              isBlockBooking
            )},-`}</span>
          </strong>
        </span>
      </div>
    </div>
  );
};
export default BookingSummary;
