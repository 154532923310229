// @flow

import { call, put, take } from "redux-saga/effects";
import generateFormData from "helpers/formHelpers";
import { eventChannel, END } from "redux-saga";
import moment from "moment";
import {
  getBookingsURL,
  getBookingStatusesURL,
  receptionTextsURL,
  requestNewKeyURL,
  checkInBookingURL,
} from "../api/lock/lock_api";
import { axiosFetch } from "../hooks/useAxios";

function* handleLocationGuests(action) {
  const url = getBookingsURL;
  const query = action.filter ? action.filter : {};
  try {
    const data = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_LOCATION_GUESTS", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR_LOCATION_GUESTS", e, url });
  }
}

function* handleGuestsSearch(action) {
  const url = getBookingsURL;
  const query = {
    name: action.filter.name,
    bookingStatus: action.filter.BookingStatus,
    fromDate: moment(action.filter.fromDate).format(),
    toDate: moment(action.filter.toDate).format(),
  };
  try {
    const data = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_GUESTS_SEARCH", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleGuestStatusCheck(action) {
  const url = getBookingStatusesURL;
  const query = { checkInStatus: action.checkInStatus };

  try {
    const data = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_CHECKIN_STATUS", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleGuestInfotext() {
  const url = receptionTextsURL;
  try {
    const data = yield call(axiosFetch, { url });
    yield put({ type: "RECEIVE_GUEST_CHECKIN_RULES", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function createUploadFileChannel(formData, url) {
  return eventChannel((emitter) => {
    const onProgress = (e) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };

    axiosFetch(
      { url, data: formData, method: "POST", onUploadProgress: onProgress } // TODO: check if onUploadProgress activates
    )
      .then((data) => {
        emitter({ success: true, data });
        emitter(END);
      })
      .catch((data) => {
        emitter({ err: true, data });
        emitter(END);
      });

    return () => {};
  });
}

function* handleNewStatusForGuest(action) {
  const item = action.item;
  const query = {
    Reference: item.Reference,
    Location: item.Location,
    ArrivalDate: item.ArrivalDate,
    CheckInStatus: item.CheckInStatus,
    DepartureDate: item.DepartureDate,
    GuestName: item.GuestName,
    GuestPhone: item.GuestPhone,
    GuestReference: item.GuestReference,
    HasCheckedIn: item.HasCheckedIn,
    Origin: item.Origin,
    ShowCheckInButton: item.ShowCheckInButton,
    ShowNewKeyButton: item.ShowNewKeyButton,
  };

  const formData = generateFormData(query);
  const channel = yield call(
    createUploadFileChannel,
    formData,
    checkInBookingURL
  );

  while (true) {
    const response = yield take(channel);
    if (response) {
      const { progress = 0, err, success, data } = response;
      if (err) {
        yield put({ type: "ERROR", message: data });
        return;
      }
      if (success) {
        yield put({ type: "MODAL_GUEST_CHECKED_INN", message: data });

        // yield put({ type: 'FETCH_GUESTS_SEARCH', filter: action.filter });

        return;
      }
      yield put({ type: "PROGRESS_CHANGED", percentComplete: progress });
    }
  }
}

function* handleGuestsNewKey(action) {
  const url = requestNewKeyURL;
  const query = {
    Origin: action.item.Origin,
    Reference: action.item.Reference,
    PhoneNumber: action.newKey,
    GuestReference: action.item.GuestReference,
  };

  const formData = generateFormData(query);
  const channel = yield call(createUploadFileChannel, formData, url);

  while (true) {
    const response = yield take(channel);
    if (response) {
      const { progress = 0, err, success, data } = response;
      if (err) {
        yield put({ type: "ERROR", message: data });
        return;
      }
      if (success) {
        yield put({ type: "RECEIVE_GUESTS_NEW_KEY", data });
        return;
      }
      yield put({ type: "PROGRESS_CHANGED", percentComplete: progress });
    }
  }
}

export {
  handleLocationGuests,
  handleGuestsSearch,
  handleGuestStatusCheck,
  handleGuestInfotext,
  handleNewStatusForGuest,
  handleGuestsNewKey,
};
