// @flow

import type {
  PortfolioItemType,
  PortfolioFilterType,
  PortfolioFilterMetaDataType,
} from "../types/myPortfolioTypes";
import type { PlanItemType } from "../types/planTypes";
import type { KeyValueType } from "../types/commonTypes";

export function fetchPortfolioFavorites() {
  return { type: "FETCH_PORTFOLIO_FAVORITES" };
}

export function sortPortfolioFavorites(sortedItems: Array<PortfolioItemType>) {
  return { type: "SORT_PORTFOLIO_FAVORITES", sortedItems };
}

export function updatePortfolioFavoritesMetaData(key: string, value: number) {
  return { type: "UPDATE_PORTFOLIO_FAVORITES_METADATA", key, value };
}

export function addPortfolioFavorite(item: PortfolioItemType) {
  return { type: "ADD_PORTFOLIO_FAVORITE", item };
}

export function togglePlanFavorite(item: PlanItemType) {
  return { type: "TOGGLE_PLAN_FAVORITE", item };
}

export function removePortfolioFavorite(item: PortfolioItemType) {
  return { type: "REMOVE_PORTFOLIO_FAVORITE", item };
}

export function fetchPortfolioSearch(
  filter: PortfolioFilterType,
  metaData: PortfolioFilterMetaDataType
) {
  return { type: "FETCH_PORTFOLIO_SEARCH", filter, metaData };
}

export function updatePortfolioFilter(
  key: string,
  value: string | number | boolean
) {
  let filterValue: string | number | boolean | undefined = value;

  if (value === -1) {
    filterValue = undefined;
  }

  return { type: "UPDATE_PORTFOLIO_FILTER", key, value: filterValue };
}

export function updatePortfolioFilterMetaData(key: string, value: string) {
  return { type: "UPDATE_PORTFOLIO_FILTER_METADATA", key, value };
}

export function resetPortfolioFilter() {
  return { type: "RESET_PORTFOLIO_FILTER" };
}

export function fetchPortfolioRenters(
  inventoryNumber: string,
  searchType: string
) {
  return { type: "FETCH_PORTFOLIO_RENTERS", inventoryNumber, searchType };
}

export function fetchPlans(inventoryNumber: string) {
  return { type: "FETCH_PORTFOLIO_PLANS", inventoryNumber };
}

export function openPortfolioPlanModal(selectedPlanId: string) {
  return { type: "OPEN_PORTFOLIO_PLAN_MODAL", selectedPlanId };
}

export function closePortfolioPlanModal() {
  return { type: "CLOSE_PORTFOLIO_PLAN_MODAL" };
}

export function setPortfolioBrasOptions(bras: Array<KeyValueType>) {
  return { type: "SET_PORTFOLIO_BRAS_OPTIONS", bras };
}

export function fetchBuildingTypes() {
  return { type: "FETCH_BUILDING_TYPES" };
}
