import { FC, useEffect, useState } from 'react';
import { DeliberateAny, SetState } from 'types/DelibrateAny';
import TextInput from 'components/FormComponents/TextInput';
import { BookingOptions } from 'contexts/BookingContextNew';
import useAxios from 'hooks/useAxios';
import { orgnrBRlookup, postalCodeLookup } from 'api/core/core_api';

export interface IInvoiceClaim {
  orgNumber: string;
  orgName: string;
  invoiceAddress: string;
  postalCode: string;
  postalCity: string;
  invoiceResponsible: string;
}

interface IConfirmWithInvoiceFormProps {
  bookingOptions: BookingOptions;
  invoiceOptions: IInvoiceClaim;
  setInvoiceOptions: SetState<IInvoiceClaim>;
  bookingError: any;
}

const ConfirmWithInvoiceForm: FC<IConfirmWithInvoiceFormProps> = ({
  invoiceOptions,
  setInvoiceOptions,
  bookingError,
}) => {
  const { sendRequest } = useAxios();
  const [postalCity, setPostalCity] = useState<string>('');
  const [orgName, setOrgName] = useState<string>('');

  const updatePaymentInfo = async (id: string, value: string) => {
    setInvoiceOptions((prevForm) => ({
      ...prevForm,
      [id]: value,
    }));
  };

  useEffect(() => {
    if (invoiceOptions.postalCode?.length >= 4) {
      getPostalCity(invoiceOptions.postalCode);
    } else {
      setPostalCity('');
    }
  }, [invoiceOptions.postalCode]);

  useEffect(() => {
    updatePaymentInfo('postalCity', postalCity);
  }, [postalCity]);

  const getPostalCity = async (postalCode: string) => {
    await sendRequest(
      {
        method: 'GET',
        url: `${postalCodeLookup}?postalCode=${postalCode}`,
        withCredentials: true,
      },
      (res: DeliberateAny) => {
        setPostalCity(res.PostalLocation);
      },
    );
  };

  useEffect(() => {
    if (invoiceOptions.orgNumber?.length === 9) {
      getOrganisationName(invoiceOptions.orgNumber);
    } else {
      setOrgName('');
    }
  }, [invoiceOptions.orgNumber]);

  useEffect(() => {
    updatePaymentInfo('orgName', orgName);
  }, [orgName]);

  const getOrganisationName = async (orgNumber: string) => {
    await sendRequest(
      {
        method: 'GET',
        url: `${orgnrBRlookup}/${orgNumber}`,
      },
      (response) => {
        setOrgName(response.Name);
      },
    );
  };

  return (
    <div className="fb-form">
      <p>Betaling med faktura</p>
      <div className="flex gap">
        <TextInput
          name={'orgNumber'}
          label={'Organisasjonsnummer'}
          placeHolder="9 siffer - Eks. 986105174"
          formHolder={invoiceOptions}
          updateForm={(id, value) => updatePaymentInfo(id, value)}
          errors={bookingError}
          required
        />
        <TextInput
          name={'orgName'}
          label={'Virksomhet'}
          formHolder={invoiceOptions}
          updateForm={(id, value) => {}}
          errors={bookingError}
          required
          disabled
        />
      </div>
      <div className="flex gap">
        <TextInput
          name={'postalCode'}
          label={'Postnummer'}
          placeHolder="Fyll ut postnummer"
          formHolder={invoiceOptions}
          updateForm={(id, value) => updatePaymentInfo(id, value)}
          errors={bookingError}
          required
        />
        <TextInput
          name={'postalCity'}
          label={'Poststed'}
          formHolder={invoiceOptions}
          updateForm={(id, value) => {}}
          errors={bookingError}
          required
          disabled
        />
      </div>
      <div className="flex gap">
        <TextInput
          name={'invoiceAddress'}
          label={'Adresse'}
          formHolder={invoiceOptions}
          updateForm={(id, value) => updatePaymentInfo(id, value)}
          errors={bookingError}
          required
        />
        <TextInput
          name={'invoiceResponsible'}
          label={'Navn på ansvarlige'}
          formHolder={invoiceOptions}
          updateForm={(id, value) => updatePaymentInfo(id, value)}
          errors={bookingError}
          required
        />
      </div>
    </div>
  );
};

export default ConfirmWithInvoiceForm;
