import { call, put, select } from "redux-saga/effects";

import {
  establishmentsURL,
  myPortfolioURL,
  buildingInformationURL,
  registerBuildingRentalTerminationServiceOrderURL,
  registerBuildingAdministrativeServiceOrderURL,
} from "../api/basenproff/basenproff_api";
import { axiosFetch } from "../hooks/useAxios";
import {
  addPortfolioFavoriteURL,
  removePortfolioFavoriteURL,
} from "api/cms/cms_api";

function* handlePortfolioFavorites() {
  const url = myPortfolioURL;

  try {
    const data = yield call(axiosFetch, { url });
    yield put({ type: "RECEIVE_PORTFOLIO_FAVORITES", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleBuildingTypes() {
  const searchType = yield select(
    (state) => state.myPortfolio.search.filterMetaData.searchType
  );
  const dif = yield select((state) => state.myPortfolio.search.filter.dif);
  const url = `${buildingInformationURL}${
    searchType === "buildingSearch" ? "Building" : "Surface"
  }Types`;

  const query = {
    dif,
  };

  try {
    const buildingTypes = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_BUILDING_TYPES", buildingTypes });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleAddPortfolioFavorite(action) {
  const url = addPortfolioFavoriteURL;
  const query = {
    ItemId: action.item.InventoryNumber,
    ReferenceType: action.item.ReferenceType,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({ type: "RECEIVE_ADD_PORTFOLIO_FAVORITE", item: action.item });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleRemovePortfolioFavorite(action) {
  const url = removePortfolioFavoriteURL;
  const query = {
    ItemId: action.item.InventoryNumber,
    ReferenceType: action.item.ReferenceType,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({ type: "RECEIVE_REMOVE_PORTFOLIO_FAVORITE", item: action.item });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handlePortfolioSearch(action) {
  const url = `${buildingInformationURL}${action.metaData.searchType}`;
  const query = action.filter;

  try {
    const data = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_PORTFOLIO_SEARCH", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleFetchPortfolioRenters(action) {
  const url = `${buildingInformationURL}${action.searchType}Tenants`;
  const query = { inventoryNumber: action.inventoryNumber };

  try {
    const data = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_PORTFOLIO_RENTERS", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleUpdatePortfolioFilter(action) {
  if (action.key === "region" && action.value.length > 0) {
    yield put({ type: "FETCHING_PORTFOLIO_FILTER_ESTABLISHMENTS" });
    const url = establishmentsURL;
    const query = { MarketingArea: action.value };

    try {
      const data = yield call(axiosFetch, { url, data: query });
      yield put({
        type: "RECEIVE_PORTFOLIO_FILTER_ESTABLISHMENTS",
        value: data,
      });
    } catch (e) {
      yield put({ type: "SAGA_ERROR", e, url, query });
    }
  }
}

function* handlePortfolioPlans(action) {
  // TODO: Apifiseres eller fjernes hvis ubrukt
  const url = "/planinnsyn/sok";
  const query = {
    IncludeNotPublished: true,
    TakeCount: 100,
    InventoryNumber: action.inventoryNumber,
  };

  try {
    const data = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_PORTFOLIO_PLANS", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleSendResignationServiceOrder(action) {
  yield put({ type: "SENDING_RESIGNATION_SERVICE_ORDER" });
  const url = registerBuildingRentalTerminationServiceOrderURL;
  const query = action.serviceOrder;

  try {
    const data = yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({ type: "RECEIVE_RESIGNATION_SERVICE_ORDER", response: data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleSendDecreasedActivityServiceOrder(action) {
  yield put({ type: "SENDING_DECREASED_ACTIVITY_SERVICE_ORDER" });
  const url = registerBuildingAdministrativeServiceOrderURL;
  const query = action.serviceOrder;

  try {
    const data = yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "RECEIVE_DECREASED_ACTIVITY_SERVICE_ORDER",
      response: data,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

// function createUploadFileChannel(formData) {
//   return eventChannel((emitter) => {
//     const onProgress = (e) => {
//       if (e.lengthComputable) {
//         const progress = e.loaded / e.total;
//         emitter({ progress });
//       }
//     };

//     axiosFetch({
//       url: registerSOURL,
//       dta: formData,
//       method: "POST",
//       onUploadProgress: onProgress,
//     })
//       .then((data) => {
//         emitter({ success: true, data });
//         emitter(END);
//       })
//       .catch((data) => {
//         emitter({ err: true, data });
//         emitter(END);
//       });

//     return () => {};
//   });
// }

// function* handleSendFaultServiceOrder(action) {
//   yield put({ type: "SENDING_FAULT_SERVICE_ORDER" });

//   const formData = generateFormData(
//     action.serviceOrder,
//     action.files,
//     "serviceOrder",
//     "Documents"
//   );
//   const channel = yield call(createUploadFileChannel, formData);

//   while (true) {
//     const response = yield take(channel);
//     if (response) {
//       const { progress = 0, err, success, data } = response;
//       if (err) {
//         yield put({ type: "ERROR", message: data });
//         return;
//       }
//       if (success) {
//         yield put({ type: "RECEIVE_FAULT_SERVICE_ORDER", response: data });
//         return;
//       }
//       yield put({ type: "PROGRESS_CHANGED", percentComplete: progress });
//     }
//   }
// }

// function* handleSendDocumentServiceOrder(action) {
//   yield put({ type: "SENDING_DOCUMENT_SERVICE_ORDER" });
//   const url = registerSOURL;
//   const query = action.serviceOrder;

//   try {
//     const data = yield call(axiosFetch, { url, data: query, method: "POST" });
//     yield put({ type: "RECEIVE_DOCUMENT_SERVICE_ORDER", response: data });
//   } catch (e) {
//     yield put({ type: "SAGA_ERROR", e, url, query });
//   }
// }

export {
  handlePortfolioSearch,
  handleBuildingTypes,
  handleUpdatePortfolioFilter,
  handlePortfolioFavorites,
  handleAddPortfolioFavorite,
  handleRemovePortfolioFavorite,
  handleFetchPortfolioRenters,
  handlePortfolioPlans,
  handleSendResignationServiceOrder,
  handleSendDecreasedActivityServiceOrder,
};
