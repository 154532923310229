import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './UserNotificationPreference.scss';
import Checkbox from '../form/Checkbox/Checkbox';
import useAxios from '../../hooks/useAxios';
import {
  getNotificationPreferencesURL,
  updateNotificationPreferencesURL,
} from '../../api/cms/cms_api';
import Button from '../buttons/Button/Button';
import Loader from '../Loader/Loader';
import { NotificationPreferencesType } from 'types/notificationPreferencesTypes';
import Modal from 'components/modals/Modal/Modal';

const textIntructions =
  'Velg hvordan du ønsker å bli varslet av Basen.';
const categoryRequirements = "Hver kategori må minimum ha ett alternativ valgt.";

const UserNotificationPreference = () => {
  const { requestLoading, sendRequest } = useAxios();
  const [notificationPreferences, setNotificationPreferences] = useState<NotificationPreferencesType[]>();

  const sortSettings = (a, b) => {
    if (a.NotificationDomain < b.NotificationDomain) {
      return -1;
    }
    if (a.NotificationDomain > b.NotificationDomain) {
      return 1;
    }
    return 0;
  };

  const fetchSettings = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: getNotificationPreferencesURL,
      },
      (r) => {
        setNotificationPreferences(r.sort(sortSettings));
      },
    );
  };

  const updateSettings = async () => {
    await sendRequest({
      method: 'PUT',
      url: updateNotificationPreferencesURL,
      data: notificationPreferences,
      withCredentials: true
    }, () => {
      <Modal ariaLabel={''} title="Varslingsinstillinger lagret"/>
    });
  };

  const validation = () => {
    if(notificationPreferences){
      for (let index = 0; index < notificationPreferences.length; index++) {
        const setting = notificationPreferences[index];
        if (!setting.PrimaryEmail && !setting.SecondaryEmail && !setting.Sms)
          return true;
      }
    }

    return false;
  };

  const validationSettings = (setting: NotificationPreferencesType) => {
    return !setting.PrimaryEmail && !setting.SecondaryEmail && !setting.Sms;
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleCheck = (name, value) => {
    const identifiers = name.split('-');

    if(notificationPreferences){
      const settingToChange = notificationPreferences.find(
        (x) => x.NotificationDomain === identifiers[0],
      );
      if(settingToChange){
        settingToChange[identifiers[1]] = value;
        const newSettings = [
          ...notificationPreferences.filter(
            (x) => x.NotificationDomain !== identifiers[0],
          ),
          settingToChange,
        ];
        setNotificationPreferences(newSettings.sort(sortSettings));
    }
    }
  };

  return (
    <Fragment>
      <h2 data-testid="user-notification-settings">Varslingsinnstillinger</h2>
      <div className="content-group grey padded">
        <p className="margin">{textIntructions}</p>
        <Loader isLoading={requestLoading}>
          <div className="unp-container">
            {notificationPreferences &&
              notificationPreferences.map((settings) => {
                return (
                  <div
                    key={settings.NotificationDomain}
                    className={`content-group ${
                      validationSettings(settings) ? 'red' : ''
                    }`}
                  >
                    <h3>
                      {settings.DisplayName
                        ? settings.DisplayName
                        : settings.NotificationDomain}
                    </h3>
                    <div className={'cb-list'}>
                      <Checkbox
                        name={`${settings.NotificationDomain}-PrimaryEmail`}
                        label={'Primær epost'}
                        checked={settings.PrimaryEmail}
                        value={settings.PrimaryEmail}
                        onChange={handleCheck}
                      />
                      <Checkbox
                        name={`${settings.NotificationDomain}-SecondaryEmail`}
                        label={'Sekundær epost'}
                        checked={settings.SecondaryEmail}
                        value={settings.SecondaryEmail}
                        onChange={handleCheck}
                      />
                      <Checkbox
                        name={`${settings.NotificationDomain}-Sms`}
                        label={'SMS'}
                        checked={settings.Sms}
                        value={settings.Sms}
                        onChange={handleCheck}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </Loader>
        <div className="footer">
          <p className="margin">{categoryRequirements}</p>
          <Button
            disabled={!notificationPreferences || validation()}
            onClick={updateSettings}
            className="btn red"
          >
            Lagre innstillinger
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default UserNotificationPreference;

UserNotificationPreference.propTypes = {
  userData: PropTypes.objectOf(PropTypes.string),
};
