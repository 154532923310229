import { useModal } from 'contexts/index';

export type PropsType = {
  title: string;
  topRight?: boolean;
  invertedColors?: boolean;
  onClick?: () => void;
};

const BoxClose = (props: PropsType) => {
  const { close } = useModal();
  return (
    <button
      title={props.title}
      className={`box-close ${props.topRight ? "top-right" : ""} ${
        props.invertedColors ? "inverted-colors" : ""
      }`}
      onClick={() => {
        if (props.onClick) props.onClick();
        close();
      }}
    />
  );
};

export default BoxClose;
