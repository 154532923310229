import { ValueOption } from 'components/FormComponents/FormPropBase';
import RadioButtonGroup from 'components/FormComponents/RadioButtonGroup';
import { IPaymentInfo, BookingOptions } from 'contexts/BookingContextNew';
import { FC, useEffect, useState } from 'react';
import { SetState } from 'types/DelibrateAny';

interface IPaymentInfoProps {
  bookingOptions?: BookingOptions;
  bookingError: any;
  paymentInfo: IPaymentInfo;
  setPaymentInfo: SetState<IPaymentInfo>;
}

const PaymentInfo: FC<IPaymentInfoProps> = ({
  bookingOptions,
  bookingError,
  paymentInfo,
  setPaymentInfo,
}) => {
  const [paymentOptions, setPaymentOptions] = useState<ValueOption[]>([]);

  useEffect(() => {
    if (bookingOptions) {
      setPaymentOptions(
        bookingOptions.availablePaymentOptions.map((item) => {
          return { label: item.heading, value: item.identity } as ValueOption;
        }),
      );
    }
  }, [bookingOptions]);

  const updateForm = (id: string, value: string | number) => {
    const updatedPaymentInfo = {
      ...paymentInfo,
      [id]: value,
    };
    setPaymentInfo(updatedPaymentInfo);
  };

  return (
    <div className="payment-info">
      <span className="payment-header">Betalingsopplysninger</span>
      <div className="payment-content">
        <div>Beløpet trekkes etter gjennomført opphold.</div>
        <RadioButtonGroup
          options={paymentOptions}
          name={'paymentOption'}
          label={' '}
          initial={paymentOptions.find(
            (item) => item.value === paymentInfo.paymentOption,
          )}
          formHolder={paymentInfo}
          updateForm={updateForm}
          errors={bookingError}
          required
        />
      </div>
    </div>
  );
};

export default PaymentInfo;
