// @flow

import _ from "lodash/fp";
import { DeliberateAny } from "types/DelibrateAny";

export const updateSingleError = (
  existingErrors: DeliberateAny,
  currentError: DeliberateAny
) =>
  _.omitBy(_.isNil, {
    ...existingErrors,
    [currentError.name]: currentError.error,
  });

export const mapObjectKeysToCamelCase = (object: object) =>
  _.mapKeys(_.camelCase, object);

export const mapArrayObjectsToCamel = (listOfItems: Array<any>) =>
  listOfItems.map((item) => mapObjectKeysToCamelCase(item));

export const ucfirst = (str: string) => _.upperFirst(str);
