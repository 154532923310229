import './Select.scss';

import { CheckIcon, DownIcon, UpIcon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import HelpText from 'components/HelpText';
import { FC, useState } from 'react';

import { IFormPropBase, ValueOption } from './FormPropBase';

interface ISelectProps extends IFormPropBase {
  initial?: string;
  options: ValueOption[];
  open?: boolean;
}

const Select: FC<ISelectProps> = ({
  name,
  label,
  formHolder,
  updateForm,
  errors,
  helpInfo,
  options,
  disabled,
  initial,
  open,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen && !disabled);

  const handleItemClick = (item: ValueOption) => {
    if (item.disabled) return;
    updateForm(name, item.value);
    setIsOpen(false);
  };

  return (
    <div className="dropdown-container">
      <HelpText content={helpInfo}>
        <label className={`fb-form-label ${errors[name] ? 'invalid' : ''}`}>
          {errors[name] ? errors[name] : label ? label : name}
        </label>
      </HelpText>
      <div className={`dropdown ${isOpen ? 'open' : ''} `}>
        <div
          className={`dropdown-header ${isOpen ? 'open' : ''} ${disabled ? 'disabled' : ''}`}
          onClick={toggleDropdown}
        >
          {options.find((o) => o.value === formHolder[name])?.label ??
            initial ?? <span></span>}
          <span className="arrow">
            {isOpen ? (
              <BasenIcon icon={UpIcon} />
            ) : (
              <BasenIcon icon={DownIcon} />
            )}
          </span>
        </div>
        {(isOpen || open) && (
          <ul className="dropdown-list">
            {options.map((item, index) => {
              return (
                <li
                  key={`${item.value}_${index}`}
                  className={`dropdown-item ${item.disabled ? 'disabled' : ''}`}
                  onClick={() => handleItemClick(item)}
                >
                  {item.label}
                  {item.value === formHolder[name] && (
                    <BasenIcon icon={CheckIcon} size={'s'} />
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select;
