import './Button.scss';

import BasenIcon from 'components/BasenIcon/BasenIcon';
import Spinner from 'components/Spinner/Spinner';
import { ReactNode } from 'react';
import { DeliberateAny } from 'src/types/DelibrateAny';

export type ButtonPropsType = {
  color?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  name?: string;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  children?: ReactNode | Array<ReactNode>;
  className?: string;
  stopPropagation?: boolean;
  'data-testid'?: string;
  style?: React.CSSProperties;
  icon?: string;
  iconRight?: boolean;
  loading?: boolean;
};

const Button: React.FC<ButtonPropsType> = (props) => {
  const {
    children,
    color,
    className,
    type,
    icon,
    iconRight,
    style,
    disabled,
    loading,
  } = props;

  const handleOnKeyPress = (event: DeliberateAny) => {
    if (event.key === 'Enter') {
      if (props.onClick) props.onClick(event);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleOnClick = (event: DeliberateAny) => {
    if (props.stopPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (props.onClick) props.onClick(event);
  };

  return (
    <button
      disabled={disabled}
      style={style}
      className={`btn ${color ?? 'grey'} ${className ?? ''} ${children ? '' : 'icon-only'}`}
      type={type ?? 'button'}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyPress}
    >
      {icon && !loading && !iconRight && <BasenIcon icon={icon} />}
      {loading && !iconRight && (
        <Spinner white={className?.includes('primary')} />
      )}
      {children}
      {icon && !loading && iconRight && <BasenIcon icon={icon} />}
      {loading && iconRight && (
        <Spinner white={className?.includes('primary')} />
      )}
    </button>
  );
};

export default Button;
