import { CalendarIcon, CloseIcon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import HelpText from 'components/HelpText';
import { useLogin } from 'contexts/index';
import FBHelpers from 'helpers/_helpers';
import { FC, ReactNode, useRef, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { norwegianLocale } from 'src/locale/norwegian';
import { DeliberateAny } from 'types/DelibrateAny';

import { IFormPropBase } from './FormPropBase';

function CustomInput({ value }) {
  return <input className="fb-date-input" value={value} />;
}

export enum DatePickerMode {
  Single,
  Range,
}

interface IDateProps extends IFormPropBase {
  mode?: DatePickerMode;
  helpPanel?: ReactNode;

  minDate?: Date | number;
  maxDate?: Date | number;

  unavailableDays?: (Date | number)[];
}

interface IWrapper {
  children?: ReactNode;
  position?: DeliberateAny;
}

const Plugin: FC<IWrapper> = ({ children }) => {
  return <div className="fb-wrapper">{children}</div>;
};

const DateSelector: FC<IDateProps> = ({
  label,
  helpInfo,
  name,
  mode,
  formHolder,
  helpPanel,
  errors,
  updateForm,
  minDate,
  maxDate,
  unavailableDays,
  placeHolder,
  disabled,
}) => {
  const { setMsg } = useLogin();
  const datePicker = useRef<DeliberateAny>();
  const [selectedDate, setSelectedDate] = useState<DeliberateAny>(
    formHolder[name],
  );

  const handleUpdate = (name: string, value: DeliberateAny) => {
    if (updateForm) updateForm(name, value);
  };

  function isDateUnavailable(dateToCheck: Date) {
    const timestampToCheck = new Date(dateToCheck).setHours(0, 0, 0, 0);
    return unavailableDays?.some((timestamp) => {
      const unavailableTimestamp = new Date(timestamp).setHours(0, 0, 0, 0);
      return timestampToCheck === unavailableTimestamp;
    });
  }

  const disableUnavailableDays = (date: DateObject) => {
    const props: any = {};

    const calendarDate = date.toDate();
    const calendarDateTimeStamp = calendarDate.setHours(0, 0, 0, 0);

    if (minDate) {
      const minDateTimeStamp = new Date(minDate).setHours(0, 0, 0, 0);
      if (calendarDateTimeStamp < minDateTimeStamp) {
        props.disabled = true;
      }
    }

    if (maxDate) {
      const maxDateTimeStamp = new Date(maxDate).setHours(0, 0, 0, 0);
      if (calendarDateTimeStamp > maxDateTimeStamp) {
        props.disabled = true;
      }
    }

    if (isDateUnavailable(calendarDate)) {
      props.disabled = true;
      props.style = {
        color: '#7a868f',
        backgroundColor: '#e8e9eb',
      };
      props.onClick = () =>
        setMsg('Valgt dato innholder dager uten tilgjenglighet.');
    }

    return props;
  };

  const onDateChange = (value: DateObject[]) => {
    setSelectedDate(value);
    if (value.length > 1) {
      updateForm(name, value);
    }
  };

  return (
    <div className={`fb-form-field ${disabled ? 'disabled' : ''}`}>
      <HelpText content={helpInfo}>
        <label className={`fb-form-label ${errors[name] ? 'error' : ''}`}>
          {errors[name] ? errors[name] : label ? label : name}
        </label>
      </HelpText>
      {mode === DatePickerMode.Range ? (
        <div className="fb-date-input">
          <DatePicker
            disabled={disabled}
            className={FBHelpers.isMobile() ? 'rmdp-mobile' : ''}
            placeholder={placeHolder}
            showOtherDays={true}
            ref={datePicker}
            numberOfMonths={FBHelpers.isMobile() ? 1 : 2}
            format="DD.MM.YYYY"
            locale={norwegianLocale}
            value={selectedDate}
            onChange={onDateChange}
            range
            dateSeparator=" - "
            monthYearSeparator=" "
            rangeHover
            mobileLabels={{ OK: 'Velg dato', CANCEL: '-' }}
            plugins={
              helpPanel
                ? [
                    <Plugin position="top" key={'plugin-1'}>
                      <div className="date-picker-top">
                        Velg dato
                        <BasenIcon
                          onClick={() => datePicker.current.closeCalendar()}
                          className="top-right"
                          icon={CloseIcon}
                        />
                      </div>
                    </Plugin>,
                    <Plugin key={'plugin-2'} position="bottom">
                      {helpPanel}
                    </Plugin>,
                  ]
                : undefined
            }
            highlightToday={false}
            mapDays={({ date }) => {
              return disableUnavailableDays(date);
            }}
          />
          <BasenIcon
            className="fb-icon-cal"
            icon={CalendarIcon}
            onClick={() => datePicker.current.openCalendar()}
          />
        </div>
      ) : (
        <div className="fb-date-input single">
          <DatePicker
            placeholder={placeHolder}
            disabled={disabled}
            ref={datePicker}
            showOtherDays={true}
            format="DD.MM.YYYY"
            locale={norwegianLocale}
            value={formHolder[name]}
            onChange={(values) => handleUpdate(name, values)}
            monthYearSeparator=" "
            mobileLabels={{ OK: 'Velg dato', CANCEL: '-' }}
            plugins={
              helpPanel
                ? [
                    <Plugin position="top" key={'plugin-1'}>
                      <div className="date-picker-top">
                        Velg dato
                        <BasenIcon
                          onClick={() => datePicker.current.closeCalendar()}
                          className="top-right"
                          icon={CloseIcon}
                        />
                      </div>
                    </Plugin>,
                    <Plugin key={'plugin-2'} position="bottom">
                      {helpPanel}
                    </Plugin>,
                  ]
                : undefined
            }
            mapDays={({ date }) => {
              return disableUnavailableDays(date);
            }}
          />
          <BasenIcon
            className="fb-icon-cal"
            icon={CalendarIcon}
            onClick={() => datePicker.current.openCalendar()}
          />
        </div>
      )}
    </div>
  );
};

export default DateSelector;
