import { useContext } from 'react';

import { ContextTexts } from '../static';
import { DeliberateAny } from '../types/DelibrateAny';
import {
  ApplicationContext,
  ApplicationContextState,
} from './ApplicationContext';
import { BookingContext } from './BookingContext';
import {
  BookingContext as NewBookingContext,
  BookingContextState as NewBookingContextState,
} from './BookingContextNew';
import { HousingContext } from './HousingContext';
import InactivityContext from './InactivityContext';
import { LoginContext } from './LoginContext';
import { ModalContext } from './ModalContext';
import SlidesContext from './SlidesContext';
import { UserContext } from './UserContext';
import { SignalRContext, SignalRContextProps } from './SignalRContext';
import { MyPageContext, MyPageContextState } from './MyPageContext';
import { ToastContext, ToastContextState } from './ToastContext';

const useApplicationContext = (): ApplicationContextState => {
  const context = useContext(ApplicationContext);

  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('ApplicationContext'));
  }
  return context;
};

export const useApplication = useApplicationContext;

const useModalContext = (): DeliberateAny => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('ModalContext'));
  }
  return context;
};

export const useModal = useModalContext;

const useUserContext = (): DeliberateAny => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('UserContext'));
  }
  return context;
};

export const useUser = useUserContext;

const useLoginContext = (): DeliberateAny => {
  const context = useContext(LoginContext);

  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('LoginContext'));
  }
  return context;
};

export const useLogin = useLoginContext;

const useBookingContext = (): DeliberateAny => {
  const context = useContext(BookingContext);

  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('BookingContext'));
  }
  return context;
};

export const useBooking = useBookingContext;

const useNewBookingContext = (): NewBookingContextState => {
  const context = useContext(NewBookingContext);

  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('BookingContext'));
  }
  return context;
};

export const useNewBooking = useNewBookingContext;

const useSlidesContext = (): DeliberateAny => {
  const context = useContext(SlidesContext);

  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('SlidesContext'));
  }
  return context;
};

export const useSlides = useSlidesContext;

const useHousingContext = (): DeliberateAny => {
  const context = useContext(HousingContext);

  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('HousingContext'));
  }
  return context;
};

export const useHousing = useHousingContext;

const useInactivityContext = (): DeliberateAny => {
  const context = useContext(InactivityContext);

  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('InactivityContext'));
  }
  return context;
};

export const useInactivity = useInactivityContext;

const useSignalRContext = (): SignalRContextProps => {
  const context = useContext(SignalRContext);

  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('SignalRContext'));
  }
  return context;
};

export const useSignalR = useSignalRContext;

const useMyPageContext = (): MyPageContextState => {
  const context = useContext(MyPageContext);

  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('MyPageContext'));
  }
  return context;
};

export const useMyPage = useMyPageContext;

const useToastContext = (): ToastContextState => {
  const context = useContext(ToastContext);

  if(context === undefined){
    throw new Error(ContextTexts.OutOfContextText("ToastContext"));
  }
  return context;
}

export const useToast = useToastContext;
