// @flow

import './RentalDocumentsList.scss';

import DetailsDivider from 'components/DetailsDivider/DetailsDivider';
import DownloadFileArea from 'components/DownloadFileArea/DownloadFileArea';
import DownloadLink from 'components/DownloadLink/DownloadLink';
import Link from 'components/Link/Link';
import RouterModal from 'components/modals/RouterModal/RouterModal';
import { SIGNED_RENTAL_CONTRACT_WARNING } from 'constants/rentalsConstants';
import { withRouterUsingChildren } from 'helpers/connectWithRouter';
import React, { Component } from 'react';

import { proffBaseUrl } from '../../../../../../api/basenproff/basenproff_api';
import DocumentForSigningItem from './DocumentForSigningItem';

import type { RentalDocumentType } from 'types/myRentalsTypes';
const SigningStates = {
  FOR_SIGNING: 0,
  SIGNED: 1,
};

type PropsType = {
  rentalId: string;
  documents: Array<RentalDocumentType>;
  location: Location;
};

class RentalDocumentsList extends Component {
  props!: PropsType;

  SignedDocumentModal = (document, modalPath) => {
    return (
      <RouterModal
        path={modalPath}
        ariaLabel="Dokument untatt offentlighet"
        cancel={{
          hide: true,
        }}
        submit={{
          text: 'Lukk',
          color: 'grey',
        }}
        size="medium"
      >
        <p>{SIGNED_RENTAL_CONTRACT_WARNING}</p>
        <DownloadFileArea
          to={`${proffBaseUrl}/${document.Path}`}
          name={document.DisplayName}
        />
      </RouterModal>
    );
  };

  render() {
    const documentsForSigning = this.props.documents.filter(
      document => document.State === SigningStates.FOR_SIGNING,
    );

    const signedDocuments = this.props.documents.filter(
      document => document.State === SigningStates.SIGNED,
    );

    const otherDocuments = this.props.documents.filter(
      document =>
        document.State !== SigningStates.FOR_SIGNING &&
        document.State !== SigningStates.SIGNED,
    );

    return (
      <React.Fragment>
        <DetailsDivider white>
          <div className="rental-documents-list">
            <h3 className="no-margin">Dokumenter til signering</h3>
            {documentsForSigning.length > 0 ? (
              <ul className="no-borders">
                {documentsForSigning.map(document => (
                  <li key={document.Path}>
                    <DocumentForSigningItem
                      key={document.DocumentId}
                      rentalId={this.props.rentalId}
                      document={document}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <p>[Ingen dokumenter]</p>
            )}
          </div>
        </DetailsDivider>

        <DetailsDivider white>
          <h3 className="no-margin">Mine dokumenter</h3>
          {signedDocuments.length + otherDocuments.length > 0 ? (
            <ul>
              {signedDocuments.map(document => {
                const modalPath = `${this.props.location.pathname}/signert-dokument/${document.FileName}`;

                return (
                  <li key={document.Path}>
                    <p>
                      <Link to={modalPath}>{document.DisplayName}</Link>
                    </p>
                    {this.SignedDocumentModal(document, modalPath)}
                  </li>
                );
              })}
              {otherDocuments.map(document => (
                <li key={document.Path}>
                  <p>
                    <DownloadLink href={`${proffBaseUrl}/${document.Path}`}>
                      {document.FileName}
                    </DownloadLink>
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>[Ingen dokumenter]</p>
          )}
        </DetailsDivider>
      </React.Fragment>
    );
  }
}

export default withRouterUsingChildren(RentalDocumentsList);
