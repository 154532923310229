import './Menu.scss';

import {
  BedIcon,
  BookIcon,
  ChatIcon,
  CloseIcon,
  DocIcon,
  FlagIcon,
  HomeIcon,
  MenuIcon,
  ProfileIcon,
  ShieldLockIcon,
} from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import Button from 'components/buttons/Button/Button';
import { useLogin, useUser } from 'contexts/index';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DevTool from 'src/modules/DevTool/DevTool';
import { isDev } from 'utils/helpers';

import fbBlackLogo from '../assets/icons/fblogo.svg';
import MenuItem from './MenuItem';
import MenuSpacing from './MenuSpacing';
import NotificationsSignalR from './NotificationsSignalR';

export type NavigationItem = {
  Title: string;
  Url: string;
  Restricted: boolean;
  inactive?: boolean;
};

const Menu = () => {
  const navigate = useNavigate();
  const { setShow, logout } = useLogin();
  const { user, hasUser } = useUser();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleNavigate = (url: string) => {
    setMenuOpen(false);
    navigate(url);
  };

  return (
    <>
      <div className="fb-menu">
        <NotificationsSignalR />
        {menuOpen ? (
          <BasenIcon
            icon={CloseIcon}
            size={'l'}
            onClick={() => {
              setMenuOpen(false);
            }}
          />
        ) : (
          <BasenIcon
            icon={MenuIcon}
            size={'l'}
            onClick={() => {
              setMenuOpen(true);
            }}
          />
        )}
        {isDev() && <DevTool />}
      </div>

      {user && (
        <nav className={`fb-menu-full ${menuOpen ? 'open' : ''}`}>
          {hasUser && (
            <>
              <MenuItem
                title="Min side"
                sub={user.Username}
                iconBackground
                icon={ProfileIcon}
                onClick={() => handleNavigate('/my-page')}
              />
              <MenuSpacing />
              <MenuItem
                sub="Bestill overnatting"
                icon={BedIcon}
                onClick={() => handleNavigate('/booking')}
              />
              <MenuItem
                sub="Søk om bolig og kvarter"
                icon={HomeIcon}
                onClick={() => handleNavigate('/forsvarsbolig')}
              />
              <MenuItem
                sub="Meld inn feil og mangler"
                icon={FlagIcon}
                onClick={() => handleNavigate('/reporting')}
              />
              <MenuItem sub="Bolighåndboken" icon={BookIcon} />
              <MenuItem
                sub="Vaktside"
                icon={ShieldLockIcon}
                onClick={() => handleNavigate('/vaktside')}
              />
              <MenuItem
                sub="Avtaler og vedlegg"
                icon={DocIcon}
                onClick={() => handleNavigate('/documents')}
              />
              <MenuItem
                sub="Kontakt oss"
                icon={ChatIcon}
                onClick={() => handleNavigate('/contact')}
              />
            </>
          )}
          {isDev() && (
            <MenuItem
              sub={'Sandbox'}
              onClick={() => handleNavigate('/sandbox')}
            />
          )}
          {!user || user.EmployeeNumber === '0000' ? (
            <Button
              className="secondary btn-full"
              onClick={() => setShow(true)}
            >
              Logg inn
            </Button>
          ) : (
            <Button className="secondary btn-full" onClick={logout}>
              Logg ut
            </Button>
          )}
          <MenuSpacing />
          <img className="fb-logo" src={fbBlackLogo} />
        </nav>
      )}
    </>
  );
};

export default Menu;
