export function isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

export function isValidPaymentUrl(url: string): boolean {
  const isProduction = process.env.NODE_ENV === 'production';
  const requiredDomain = isProduction
    ? 'prod-emea.checkout.verifone.cloud'
    : 'cst.checkout.vficloud.net';

  if (!url.includes(requiredDomain)) return false;

  return isValidUrl(url);
}
