import './GridContainer.scss';

import React, { CSSProperties, FC, ReactNode } from 'react';

interface GridContainerProps {
  children: ReactNode[];
  cols: number;
  gap?: number;
  style?: CSSProperties;
  centerItems?: boolean;
}

const GridContainer: FC<GridContainerProps> = ({
  children,
  cols,
  style,
  centerItems,
  gap,
}) => {
  const gridStyle: React.CSSProperties = {
    display: 'grid',
    gridTemplateColumns: `repeat(${cols}, 1fr)`,
    gap: gap ?? 10,
    justifyContent: centerItems ? 'center' : 'start',
    alignItems: centerItems ? 'center' : 'start',
    ...style,
  };

  return <div style={gridStyle}>{children}</div>;
};

export default GridContainer;
