import { useLogin } from 'contexts/index';
import { LoginPath } from 'contexts/LoginContext';

import Confirm from './Confirm';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import NewUser from './NewUser';
import SetNewPassword from './SetNewPassword';
import Verify from './Verify';

const LoginForm = () => {
  const { show, setShow, setPath, path } = useLogin();
  if (!show) return null;
  return (
    <div id="serverLogin" className={`modal ${show ? 'visible' : ''}`}>
      <div
        className="overlay"
        onClick={() => {
          if (
            // lock overlay from closing modal when filling in for a new user
            !(
              path === LoginPath.register ||
              path === LoginPath.requestSent ||
              path === LoginPath.setup ||
              path === LoginPath.update ||
              path === LoginPath.registerSuccess
            )
          ) {
            setPath(undefined);
            setShow(false);
          }
        }}
      ></div>

      <div className="content flip-card active">
        {(!path || path === LoginPath.code || path === LoginPath.login) && (
          <Login />
        )}
        {(path === LoginPath.register ||
          path === LoginPath.requestSent ||
          path === LoginPath.setup ||
          path === LoginPath.update ||
          path === LoginPath.registerSuccess) && <NewUser />}
        {path === LoginPath.verify && <Verify />}
        {path === LoginPath.confirm && <Confirm />}
        {(path === LoginPath.forgot || path === LoginPath.newPassword) && (
          <ForgotPassword />
        )}
        {path === LoginPath.newPasswordSet && <SetNewPassword />}
      </div>
    </div>
  );
};

export default LoginForm;
