import './Slides.scss';
import './slide-navigation.scss';
import './slide.scss';

import Loader from 'components/Loader/Loader';
import { useSlides } from 'contexts/index';
import { FC, ReactNode } from 'react';

interface SlideProps {
  path: string;
  parentMatchPath?: string; // Optional if it might not be provided
  children?: ReactNode | ReactNode[]; // ReactNode type is used for children to allow any valid React child
  slideSize?: string;
  slideCount?: string;
  backgroundImage?: boolean;
  hidePrevLink?: boolean;
  title?: string;
  position?: string; // Assuming position is a required prop for determining the slide number
  prevSlidePath?: string; // Optional previous slide path
  showCount?: boolean;
  loading?: boolean;
}

const Slide: FC<SlideProps> = ({
  children,
  slideSize = "",
  backgroundImage = false,
  hidePrevLink = false,
  title,
  loading,
  prevSlidePath = null,
}) => {
  const { navigateBack } = useSlides();
  const size = `max-width${slideSize ? `-${slideSize}` : ""}`;

  const slideContent = (content) => (
    <div
      className={
        backgroundImage ? "sticky-middle-background-image" : "sticky-middle"
      }
    >
      <div className={`${size} margin`}>{content}</div>
    </div>
  );

  return (
    <div>
      {title && (
        <div className="navigation" id="nav">
          <h2>{title}</h2>
          {!hidePrevLink && (
            <a
              onClick={(e) => {
                e.preventDefault();
                prevSlidePath ? navigateBack(prevSlidePath) : navigateBack();
              }}
              className="prev-slide"
            >
              <span className="hide-for-small">Tilbake</span>
            </a>
          )}
        </div>
      )}
      <Loader isLoading={loading}>{slideContent(children)}</Loader>
    </div>
  );
};

export default Slide;
