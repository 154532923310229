export const TransactionEvents = {
  REGISTERED: 0,
  AUTHORIZED: 1,
  SALE: 2, // Not in use by, applies mostly for bank terminals.
  CAPTURE: 3,
  CREDIT: 4,
  ANNUL: 5,
  NULL: 6,
};

export const PaymentStates = {
  NOTPAID: 0,
  PAID: 1,
  DUECREDIT: 2,
  DUEDEBIT: 3,
  DOINVOICE: 4,
  CANCELLED: 5,
};

export const BookingStates = {
  NULL: 0,
  NEW: 1,
  CONFIRMED: 2,
  REJECTED: 3,
  CANCELLED: 4,
  CLOSED: 5,
  POSTPONED: 6,
  DELETED: 7,
  ROOMRESERVATION: 8,
  NOSHOW: 9,
  CANCELLATIONREQUESTED: 10,
};

export const PaymentMethods = {
  NOTSELECTED: 0,
  PAYBYCARD: 1,
  PAYBYINVOICE: 2,
  PAYBYSALARYDEDUCTION: 3,
  PAYATARRIVAL: 4,
  PREPAID: 5,
};

export const BookingModes = {
  STANDARD: "standard",
  BLOCK: "block",
  REFERENCE: "reference",
};
