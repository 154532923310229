// @flow

import { rejectSigning } from "actions/myRentalsActions";
import SigningRejectionReasonSelect from "components/form/SigningRejectionReasonSelect/SigningRejectionReasonSelect";
import connect from "helpers/connectWithRouter";
import { Component } from "react";

import RouterModal from "../RouterModal/RouterModal";

type PropsType = {
  path: string;
  documentId: string;
  title: string;
  ariaLabel: string;
  description: string;
  rejectSigning: (documentId: string, rejectionReason: string) => void;
};

export class UnconnectedRejectSigningModal extends Component {
  declare props: PropsType;

  state: {
    selectedRejectionReason: string;
  };

  constructor(props: PropsType) {
    super(props);
    this.state = { selectedRejectionReason: "" };
  }

  rejectSigning = () =>
    this.props.rejectSigning(
      this.props.documentId,
      this.state.selectedRejectionReason
    );

  render() {
    return (
      <RouterModal
        title={this.props.title}
        ariaLabel={this.props.ariaLabel}
        description={this.props.description}
        path={this.props.path}
        submit={{
          text: "Avslå",
          onClick: this.rejectSigning,
          disabled: this.state.selectedRejectionReason === "",
        }}
      >
        <div className="margin">
          <SigningRejectionReasonSelect
            name="rejectionReasonSelect"
            onChange={(rejectionReason: string) =>
              this.setState({ selectedRejectionReason: rejectionReason })
            }
          />
        </div>
      </RouterModal>
    );
  }
}

export default connect(null, {
  rejectSigning,
})(UnconnectedRejectSigningModal);
