 
import './FaultForm.scss';

import { trimTitle } from 'helpers/serviceOrderHelpers';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import {
  addFileReporting,
  handleCloseReportingConfirmationSent,
  handleSendReporting,
  removeFileReporting,
  updateFileDescriptionReporting,
  updateReporting,
  updateReportingAllErrors,
  updateReportingOneError,
} from '../../actions/reportingActions';
import ButtonRow from '../../components/buttons/ButtonRow/ButtonRow';
import DateInput from '../../components/DateInput/DateInput';
import Dropzone from '../../components/Dropzone/Dropzone';
import FormWrapper from '../../components/form/FormWrapper/FormWrapper';
import Input from '../../components/form/Input/Input';
import TextArea from '../../components/form/TextArea/TextArea';
import UnclassifiedConfirmation from '../../components/form/UnclassifiedConfirmation/UnclassifiedConfirmation';
import ApplicationSummaryModal from '../../components/ReportIssue/ApplicationSummaryModal';
import { DeliberateAny } from '../../types/DelibrateAny';

const QuestionForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const files = useSelector((state: DeliberateAny) => state.reporting.files);
  const reportingSent = useSelector(
    (state: DeliberateAny) => state.reporting.reportingSent,
  );

  if (reportingSent) {
    dispatch(handleCloseReportingConfirmationSent());
    navigate('/reporting/confirmation');
  }

  // API for å hente statiske elementer til bruk i dropdown menyer.
  const reportingErrors = useSelector(
    (state: DeliberateAny) => state.reporting.errors,
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmData, setConfirmData] = useState<DeliberateAny[]>();
  const [formHolder, setFormHolder] = useState<DeliberateAny>({
    title: '',
    description: '',
    requestedDeliveryTime: moment(),
    isUnclassified: false,
    serviceOrderType: 'Administrativ serviceordre',
    applicationType: 'Spørsmål',
    reporter: 'John Doe',
  });
  const updateFormHolder = (name: string, value: DeliberateAny) => {
    setFormHolder({ ...formHolder, [name]: value });
  };

  /* Disse kan kanskje kjøres inline i onClicken uten useCallback */
  const addAttachment = useCallback(
    (file: DeliberateAny) => dispatch(addFileReporting(file)),
    [dispatch],
  );

  const removeAttachment = useCallback(
    (file: DeliberateAny, index: string) =>
      dispatch(removeFileReporting(file, index)),
    [dispatch],
  );

  const updateAttachmentDescription = useCallback(
    (file: DeliberateAny, title: string, index: string) =>
      dispatch(updateFileDescriptionReporting(file, title, index)),
    [dispatch],
  );

  const dispatchErrors = (errors: DeliberateAny[]) => {
    dispatch(updateReportingAllErrors(errors));
  };

  const dispatchSingleError = (error: DeliberateAny) => {
    dispatch(updateReportingOneError('NA', error)); // NA er en dummy verdi
  };

  const setFieldValues = () =>
    Object.keys(formHolder).forEach((key) => {
      const field = formHolder[key];
      if (field && key === 'title') {
        dispatch(updateReporting(key, trimTitle(field)));
      } else if (field !== null) {
        dispatch(updateReporting(key, field));
      }
    });

  const handleShowConfirmModal = () => {
    setConfirmData([
      // Felter som skal vises i bekreftelsesmodal
      { label: 'Overskrift', value: formHolder.title },
      { label: 'Beskrivelse', value: formHolder.description },
      {
        label: 'Ønsket leveringsdato',
        value: moment(formHolder.requestedDeliveryTime).format('DD.MM.YYYY'),
      },
      { label: 'Antall vedlegg', value: files.length },
      {
        label: 'Gradert informasjon',
        value: formHolder.isUnclassified ? 'Ja' : 'Nei',
      },
    ]);
    setModalIsOpen(true);
    // Copy local form data to store. The tricky part with the code in
    // this task is that most APIs are using lowercase attributes.
    // But the RegisterServiceOrder is using a capital letter API for some,
    // but not all attributes. Taken care of in updateReporting which loops through
    // all formHolder variables and adds them to the store when click on "Send inn"-button
    setFieldValues();
  };

  const generateValidatorConfig = () => {
    const formValues = {
      title: formHolder.title,
      description: formHolder.description,
      isUnclassified: formHolder.isUnclassified,
    };

    const validatorConfig = {
      title: yup.string().required('required'),
      description: yup.string().required('required'),
      isUnclassified: yup.boolean().when([], {
        is: () => true,
        then: (s) =>
          s
            .oneOf([true, false], 'required')
            .test('acceptFalse', 'required', function () {
              if (
                !formValues.isUnclassified &&
                !formValues.isUnclassified.acceptFalse
              ) {
                return this.createError({ message: 'required' });
              }
              return true;
            }),
        otherwise: (s) => s,
      }),
    };

    return yup.object().shape(validatorConfig).defined();
  };

  const formProps = {
    // formProps er parametere til <FormWrapper
    // errors-props blir inserted i alle kjente typer children av <FormWrapper
    // Kjente typer er Input, Select, Textarea mm. Se FormWrapper.js. Ergo så behøver
    // vi ikke provide error attribute til disse form-komponenten
    // errors er en array som inneholder alle input-names med feil.
    errors: reportingErrors,
    // valideringen sjekkes mot innholdet i form. Når formTitle er blank
    // så vil valideringen trigge på title.
    form: {
      title: formHolder.title,
      description: formHolder.description,
      isUnclassified: formHolder.isUnclassified,
    },
    onChange: updateReporting,
    // dispatchErrors vil ta alle elemntene i error-arrayen og endre store.state.errors
    updateAllValidationErrors: dispatchErrors,
    // dispatchSingleError kjøres som dispatchErrors, men endrer bare en array. Gjerne
    // etter en endring i et input felt sånn at rød alideringsmarkeringen i front-end forsvinner
    updateOneValidationError: dispatchSingleError,
    onSubmit: handleShowConfirmModal,
    // Her er configen for hvordan valideringen skal skje. objektnavn (title, region) er samme
    // som formens input-name
    schema: generateValidatorConfig(),
  };

  const toggleIsUnclassified = () => {
    updateFormHolder('isUnclassified', !formHolder.isUnclassified);
  };

  return (
    <div>
      <FormWrapper {...formProps}>
        <div className="scrollable sticky-middle max-width clientsquare-report">
          <h2 className="unclassified-stamp">Ugradert</h2>
          <h2>Spørsmål</h2>
          <div className="row margin">
            <div className="content-group grey padded">
              <div className="row margin">
                <Input
                  type="text"
                  maxLength={60}
                  placeholder="Maks. 60 tegn"
                  name="title"
                  value={formHolder.title}
                  onChange={(name, val) => updateFormHolder(name, val)}
                  title="Overskrift"
                />
              </div>
              <div className="row margin">
                <TextArea
                  name="description"
                  rows={5}
                  value={formHolder.description}
                  onChange={(name, val) => updateFormHolder(name, val)}
                  title="Beskriv hva henvendelsen gjelder"
                />
              </div>
              <div className="row margin dateinput">
                <DateInput
                  showYear
                  title="Ønsket leveringsdato"
                  name="requestedDeliveryTime"
                  minDate={moment()}
                  selectedDate={formHolder.requestedDeliveryTime}
                  full
                  disabled={false}
                  /*
                  onSelectDate={setFormDeliveryDate}
                  */
                  onChange={(name, val) => updateFormHolder(name, val)}
                />
              </div>
            </div>
          </div>
          <div className="row margin">
            <div className="content-group grey padded">
              <div className="row margin">
                Vedlegg
                <Dropzone
                  files={files}
                  addFile={addAttachment}
                  removeFile={removeAttachment}
                  updateFileDescription={updateAttachmentDescription}
                  formKey="attachments"
                  maxFiles={10}
                  showSizeInKB
                  deleteButtonStyle="nocolor"
                />
              </div>
            </div>
          </div>
          <UnclassifiedConfirmation
            checked={formHolder.isUnclassified}
            onChange={toggleIsUnclassified}
          />
          <div className="divider" />
          <ButtonRow>
            <ButtonRow.Right
              type="submit"
              color="red"
              className="btn"
              key="1"
              onClick={handleShowConfirmModal}
            >
              Send inn
            </ButtonRow.Right>
          </ButtonRow>
        </div>
      </FormWrapper>
      {modalIsOpen && (
        <ApplicationSummaryModal
          isOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          data={confirmData && confirmData.filter((el: DeliberateAny) => el)}
          onSubmit={() => dispatch(handleSendReporting(formHolder, ''))}
        />
      )}
    </div>
  );
};

export default QuestionForm;
