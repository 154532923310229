import { bookingOptionsURL } from 'api/booking/booking_api';
import MapPickerModal from 'components/modals/MapPickerModal/MapPickerModal';
import { useUser } from 'contexts/index';
// import '../Booking/Location.scss';
import { processHit, searchInObject } from 'helpers/bookingHelpers';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

const LocationSelector = ({
  setLocationInput,
  handleOnSelect,
  className,
}: DeliberateAny) => {
  const { user } = useUser();
  const { sendRequest } = useAxios();

  const [showMap, setShowMap] = useState(false);

  const [searchLocationInput, setSearchLocationInput] = useState({
    name: '',
    id: '',
  });

  const [searchOptions, setSearchOptions] = useState<DeliberateAny[]>([]);
  const [bookingOptions, setBookingOptions] = useState<DeliberateAny[]>([]);

  const fetchBookingOptions = async () => {
    const processBookingOptions = (res) => {
      setBookingOptions(res.Locations);
    };

    await sendRequest(
      {
        method: 'GET',
        url: bookingOptionsURL,
      },
      processBookingOptions,
      user,
    );
  };
  useEffect(() => {
    fetchBookingOptions();
  }, []);

  const locationLookup = (str) => {
    setSearchLocationInput({ name: str, id: '' });
    if (!str || str.length < 2) {
      setSearchOptions([]);
      return;
    }
    const hits: DeliberateAny[] = [];
    bookingOptions.forEach((bookingOption) => {
      const hit = searchInObject(str, bookingOption);
      if (hit) {
        const message = processHit(hit);
        hits.push({
          id: hit.LocationId,
          name: hit.Name,
          message,
          selected: hits.length === 0 ? 'selected' : '',
        });
      }
    });
    setSearchOptions(hits);
  };

  const handleSelectLocation = (name, id) => {
    setSearchOptions([]);
    setSearchLocationInput({ name, id });
    setLocationInput({
      name,
      id,
    });
    handleOnSelect(id);
  };

  return (
    <div className={className}>
      <div className={'book-city location-input '}>
        <label>
          <span>Velg by eller sted</span>
          <div className="input-with-button">
            <input
              autoComplete="off"
              onChange={(e) => locationLookup(e.target.value)}
              value={searchLocationInput.name}
              className="input-with-button"
              type="text"
              name="location-input"
              placeholder="Eks. Kolsås leir"
            />
            <button className="btn white" onClick={() => setShowMap(true)} />
          </div>
        </label>
        <div className="autocomplete-results">
          <ul>
            {searchOptions.map((option) => (
              <li
                key={option.id}
                className={`${option.selected === 'selected' ? 'selected' : ''} ${
                  option.message && option.message.length > 0 && 'unavailable'
                }`}
                role="article"
              >
                <div
                  role="presentation"
                  onClick={() => handleSelectLocation(option.name, option.id)}
                >
                  {option.name}
                  {option.message && option.message.length > 0 && (
                    <span className="unavailable-message">
                      {option.message}
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <MapPickerModal
        modalData={{
          onClose: () => {
            setShowMap(false);
          },
          isOpen: showMap,
          modalType: 'map-picker',
          arenas: bookingOptions,
          size: 'large',
          scrollable: true,
          extraClass: 'no-padding',
          onYes: (arena) => {
            handleSelectLocation(arena.Name, arena.LocationId);
          },
        }}
      />
    </div>
  );
};

export default LocationSelector;
