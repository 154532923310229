import {
  arenaAvailableRoomsUrl,
  bookingByIdUrl,
  bookingUpcomingUrl,
  reservationConfirmWithDelayedPaymentUrl,
  reservationConfirmWithIoUrl,
  reservationUpdateByIdUrl,
  reservationUrl,
} from 'api/booking/booking_api_v2';
import * as I from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import Button from 'components/buttons/Button/Button';
import Select from 'components/FormComponents/Select';
import Modal from 'components/ModalComponents/Modal';
import Spinner from 'components/Spinner/Spinner';
import { useToast } from 'contexts/index';
import { ToastType } from 'contexts/ToastContext';
import useAxios from 'hooks/useAxios';
import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TabContainer from 'src/layout/TabContainer/TabContainer';
import { BookingInfo } from 'types/bookingRequests';

const locationId = '297b46ef-3e54-45d2-b2f1-27161ac75777';

const DevToolContent = ({ onClose }) => {
  const { sendRequest, requestLoading, requestError } = useAxios();
  const { createToastMessage, createPromiseToastMessage } = useToast();
  const nav = useNavigate();

  const [active, setActive] = useState('Booking');
  const [info, setInfo] = useState<ReactNode>('');
  const [bookings, setBookings] = useState<BookingInfo[]>();
  const [selectedBooking, setSelectedBooking] = useState('');

  const loadingMsg = (msg, loading = true) => {
    setInfo(
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 10,
        }}
      >
        {loading && <Spinner />} <strong>{msg}</strong>
      </div>,
    );
  };

  const handleCreateBooking = async () => {
    if (requestLoading) {
      createToastMessage('Wait for the previous call to finish...');
      return;
    }

    const oneWeekFromNow = moment().add(7, 'days').format('YYYY-MM-DD');
    const twoWeekFromNow = moment().add(14, 'days').format('YYYY-MM-DD');

    let availableRooms;

    const getAvailableRoomsPromise = sendRequest(
      {
        method: 'GET',
        url: arenaAvailableRoomsUrl(locationId),
        params: {
          arrivalDate: oneWeekFromNow,
          departureDate: twoWeekFromNow,
          numberOfGuests: 2,
        },
      },
      (res) => {
        availableRooms = res;
      },
    );

    createPromiseToastMessage(getAvailableRoomsPromise, {
      pending: 'Getting rooms...',
      success: 'Rooms found',
      error: 'Could not get rooms',
    });

    let reservation;

    const createReservationPromise = sendRequest(
      {
        method: 'POST',
        url: reservationUrl,
        data: {
          arrivalDate: oneWeekFromNow,
          departureDate: twoWeekFromNow,
          locationId,
          quoteId: availableRooms.quoteId,
          rooms: [
            {
              roomTypeId: availableRooms.availableRooms[0].roomType.roomTypeId,
              numberOfRooms: 2,
            },
          ],
        },
      },
      (res) => {
        reservation = res;
      },
    );

    createPromiseToastMessage(createReservationPromise, {
      pending: 'Reserving rooms...',
      success: 'Rooms reserved',
      error: 'Could not reserve rooms',
    });

    const creatingGuestsPromise = sendRequest({
      method: 'PATCH',
      url: reservationUpdateByIdUrl(reservation.reservationId),
      data: {
        guestListPostponed: false,
        unnamedGuests: false,
        civilGuests: false,
        rooms: [
          {
            roomTypeId: reservation.rooms[0].roomTypeId,
            index: reservation.rooms[0].index,
            heading: reservation.rooms[0].heading,
            guests: [
              {
                fullName: 'Guest number 1',
                mobile: '+4794949494',
                additionalInfo: 'Created by DevTool',
                isUnnamed: false,
              },
            ],
          },
          {
            roomTypeId: reservation.rooms[1].roomTypeId,
            index: reservation.rooms[1].index,
            heading: reservation.rooms[1].heading,
            guests: [
              {
                fullName: 'Guest number 2',
                mobile: '+4794949495',
                additionalInfo: 'Created by DevTool',
                isUnnamed: false,
              },
            ],
          },
        ],
      },
    });

    createPromiseToastMessage(creatingGuestsPromise, {
      pending: 'Creating guests...',
      success: 'Guests created',
      error: 'Could not create guests',
    });

    let bookingId;

    const confirmingReservationPromise = sendRequest(
      {
        method: 'POST',
        url: reservationConfirmWithDelayedPaymentUrl(reservation.reservationId), //reservationConfirmWithIoUrl(reservation.reservationId),
        data: {
          purpose: 'Lorem ipsum',
          description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, iure eaque quia nam quidem ex laudantium beatae magni necessitatibus molestias.',
          ioNumber: '4794949495',
          department: 'Obcaecati',
        },
      },
      (res) => (bookingId = res.bookingId),
    );

    createPromiseToastMessage(confirmingReservationPromise, {
      pending: 'Confirming reservation...',
      success: 'Reservation confirmed',
      error: 'Could not confirm reservation',
    });
  };

  const handleGetBookings = async () => {
    if (requestLoading) {
      createToastMessage('Wait for the previous call to finish...');
      return;
    }

    setSelectedBooking('');

    const getBookingsPromise = sendRequest(
      {
        method: 'GET',
        url: bookingUpcomingUrl(1, 50),
      },
      (res) => {
        setBookings(res.bookings);
      },
    );

    createPromiseToastMessage(getBookingsPromise, {
      pending: 'Loading bookings',
      success: 'Loaded bookings',
      error: 'Could not load bookings',
    });
  };

  const handleDeleteBooking = async () => {
    if (requestLoading) {
      createToastMessage(
        'Wait for the previous call to finish...',
        ToastType.Warning,
      );
      return;
    }

    const deleteBookingPromise = sendRequest(
      {
        method: 'DELETE',
        url: bookingByIdUrl(selectedBooking),
      },
      () => {
        setBookings((x) => x?.filter((b) => b.id !== selectedBooking));
        setSelectedBooking('');
      },
    );

    createPromiseToastMessage(deleteBookingPromise, {
      pending: 'Deleting booking...',
      success: 'Successfully deleted booking',
      error: 'Could not delete booking',
    });
  };

  const handleChangeBooking = async () => {
    if (requestLoading) {
      createToastMessage(
        'Wait for the previous call to finish...',
        ToastType.Warning,
      );
      return;
    }

    nav(`/mypage/booking/${selectedBooking}`);
    setTimeout(() => onClose(), 500);
  };

  useEffect(() => {
    if (active === 'Booking' && !bookings) handleGetBookings();
  }, [active]);

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          backgroundColor: info ? 'pink' : 'transparent',
          top: 120,
          right: 120,
          padding: 8,
          borderRadius: 4,
          fontSize: 24,
          maxWidth: 350,
        }}
      >
        {info}
      </div>

      <TabContainer
        activeTab={active}
        onTabChange={setActive}
        content={[
          {
            id: 'Booking',
            content: (
              <>
                <hr style={{ marginBottom: 10 }} />
                <div
                  className="flex"
                  style={{
                    flexDirection: 'column',
                    gap: 8,
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  <Select
                    name="selectedBooking"
                    label=" "
                    initial="Velg booking"
                    formHolder={{ selectedBooking }}
                    updateForm={(_, v) => setSelectedBooking(v)}
                    errors={{}}
                    options={
                      (bookings ?? []).length > 0
                        ? (bookings ?? []).map((b) => {
                            return {
                              label: `${b.locationName} - Rooms: ${b.numberOfRooms} - ${b.status.text} - ${b.arrivalDate} - ${b.departureDate} - ${b.purpose}`,
                              value: b.id,
                            };
                          })
                        : [
                            {
                              label: 'Not loaded, click Get bookings',
                              value: '',
                            },
                          ]
                    }
                  />
                  <div
                    className="flex"
                    style={{
                      gap: 2,
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}
                  >
                    <Button onClick={handleCreateBooking} className="primary">
                      Create booking
                    </Button>

                    <Button onClick={handleGetBookings} className="secondary">
                      Get bookings
                    </Button>
                    <Button
                      onClick={handleDeleteBooking}
                      disabled={!selectedBooking}
                      className="secondary"
                    >
                      Delete booking
                    </Button>
                    <Button
                      onClick={handleChangeBooking}
                      disabled={!selectedBooking}
                      className="secondary"
                    >
                      Change booking
                    </Button>
                  </div>
                </div>
              </>
            ),
          },
          { id: 'Housing', content: <div></div> },
        ]}
      />
    </div>
  );
};

const DevTool = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <BasenIcon
        icon={I.GearIcon}
        size={'l'}
        onClick={() => {
          setOpen(true);
        }}
      />
      {open && (
        <Modal
          header="Developer tools"
          content={
            <DevToolContent
              onClose={() => {
                setOpen(false);
              }}
            />
          }
          showCancelIcon
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default DevTool;
