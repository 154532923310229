import { registerRentalServiceOrderURL } from 'api/basenproff/basenproff_api';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Checkbox from 'components/form/Checkbox/Checkbox';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import Loader from 'components/Loader/Loader';
import ApplicationSummaryModal from 'components/ReportIssue/ApplicationSummaryModal';
import ContactDetails from 'components/ReportIssue/ContactDetails';
import FaultTypeSelect from 'components/ReportIssue/FaultTypeSelect';
import RentalsContractsSelect from 'components/ReportIssue/RentalsContractsSelect';
import generateFormData from 'helpers/fileHelpers';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Space from 'src/layout/Space';
import { DeliberateAny } from 'types/DelibrateAny';
import * as yup from 'yup';

import {
  AdditionalConfirmations,
  AttachmentSection,
  InputField,
  TextAreaField,
} from './ReportingFormComponents';

const FaultFormRental = ({
  formHolder,
  setFormHolder,
  rentalContracts,
  contractsLoading,
  lastUpdate,
  setLoading,
}: DeliberateAny) => {
  const { sendRequest, requestLoading } = useAxios();

  const navigate = useNavigate();
  const { id, type } = useParams();
  const [formErrors, setFormErrors] = useState<DeliberateAny[]>([]);
  const [files, setFiles] = useState<DeliberateAny>([]);

  const [deliveryDateCheckbox, setDeliveryDateCheckbox] = useState(false);
  const [confirmData, setConfirmData] = useState<DeliberateAny>();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [_, setErrorMessageTypes] = useState([]);

  const updateFormHolder = (name: string, value: DeliberateAny) => {
    setFormHolder((fh: DeliberateAny) => {
      return { ...fh, [name]: value };
    });
  };

  useEffect(() => {
    if (!formHolder.rentalId && rentalContracts && rentalContracts.length > 0)
      updateFormHolder('rentalId', rentalContracts[0].RentalId);
  }, [lastUpdate, rentalContracts]);

  useEffect(() => {
    if (!formHolder.rentalId && id && type && type === 'Leieforhold')
      updateFormHolder('rentalId', id);
  }, [id]);

  const handleResponse = (result: DeliberateAny) => {
    if (result) {
      navigate('/reporting/confirmation');
    }
  };

  const cleanFormData = (formData: DeliberateAny) => {
    const propertiesToRemove = [
      'Reporter',
      'Phone',
      'EmployeeNumber',
      'Email',
      'RegionNumber',
      'Establishment',
      'Building',
      'IsUnclassified',
      'ServiceOrderType',
      'ServiceOrderErrorTypeId',
      'DistrictId',
      'EstablishmentId',
      'BuildingCode',
      'CategoryId',
      'IoNumber',
      'IsPriceEnquiry',
    ].map((p) => p.toLowerCase());
    const cleanedData = { ...formData };
    Object.keys(cleanedData).forEach((key) => {
      if (propertiesToRemove.includes(key.toLowerCase())) {
        delete cleanedData[key];
      } else if (key.startsWith('_')) {
        delete cleanedData[key];
      }
    });
    return cleanedData;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const manipulatedFormValues = { ...formHolder };

    delete manipulatedFormValues['ServiceOrderErrorTypeId'];

    const formData = generateFormData(
      { ...manipulatedFormValues },
      files,
      'serviceOrder',
      'Documents',
      cleanFormData,
    );

    await sendRequest(
      {
        method: 'POST',
        url: registerRentalServiceOrderURL,
        data: formData,
        withCredentials: true,
      },
      handleResponse,
    ).finally(() => setLoading(false));
  };

  const handleDeliveryDateCheckbox = () => {
    // if user sets checkbox from true to false, reset requestedDeliveryTime
    if (deliveryDateCheckbox) {
      updateFormHolder('requestedDeliveryTime', '');
    }
    setDeliveryDateCheckbox(!deliveryDateCheckbox);
  };

  const updateAllErrors = (errors: DeliberateAny) => {
    setFormErrors(errors);

     
    const formElements = Object.keys(formProps.form);
    const firstInputValidationError = formElements.find((el) =>
      Object.keys(errors).includes(el),
    );
    const scrollElement = document.getElementById(
      `error_${firstInputValidationError}`,
    );
    if (scrollElement) scrollElement.scrollIntoView();
  };

  const updateSingleError = (name, error) => {
    const newerrors = { ...formErrors };
    newerrors[name] = error;
    setFormErrors(newerrors);
  };

  const handleShowConfirmModal = () => {
    const rental = rentalContracts.find(
      (x) => x.RentalId === formHolder.rentalId,
    );
    setFormHolder((x) => {
      return {
        ...x,
        HousingArea: rental.HousingArea,
        CaseTypeId: x.serviceOrderErrorTypeId,
      };
    });

    const getModalData = () => {
      const getRegion = () => {
        const rentalContract = rentalContracts.find(
          (x) => x.RentalId === formHolder.rentalId,
        );

        return {
          label: 'Distrikt',
          value: rentalContract.HousingArea,
        };
      };

      const getEstablishment = () => {
        const label = 'Etablissement';

        const rentalContract = rentalContracts.find(
          (x) => x.RentalId === formHolder.rentalId,
        );
        if (rentalContract && rentalContract.EstablishmentName) {
          return {
            label,
            value: `${rentalContract.EstablishmentName}`,
          };
        }

        return {};
      };

      const getBuilding = () => {
        if (formHolder.building < 0) return {};

        const rentalContract = rentalContracts.find(
          (x) => x.RentalId === formHolder.rentalId,
        );

        const buildingName = rentalContract && rentalContract.BuildingName;

        return {
          label: 'Bygning/Uteareal',
          value: `${buildingName}`,
        };
      };

      const getDeliveryDate = () => {
        return formHolder.requestedDeliveryTime
          ? {
              label: 'Ønsket leveringsdato',
              value: formHolder.requestedDeliveryTime,
            }
          : {};
      };

      const getLocation = () => {
        return formHolder.location
          ? {
              label: 'Lokasjon',
              value: formHolder.location,
            }
          : {};
      };

      return [
        { label: 'Overskrift', value: formHolder.title },
        getRegion(),
        getEstablishment(),
        getBuilding(),
        getLocation(),
        {
          label: 'Beskriv hva henvendelsen gjelder',
          value: formHolder.description,
        },
        {
          label: 'Feilmeldingstype',
          value: formHolder.serviceOrderType,
        },
        getDeliveryDate(),
        {
          label:
            'Samtykke til at Forsvarsbygg eller underleverandør kan låse seg inn i boenhet',
          value: formHolder.ConsentSubcontractorAccess ? 'Ja' : 'Nei',
        },
        { label: 'Hund i bo-enheten', value: formHolder.hasPet ? 'Ja' : 'Nei' },
        { label: 'Antall vedlegg', value: files.length },
      ];
    };

    const data: DeliberateAny = getModalData();
    setConfirmData(data);
    setModalIsOpen(true);
  };

  const addAttachment = (file) => {
    setFiles((state) => [
      ...state,
      {
        title: file.name,
        file,
      },
    ]);
  };

  const removeAttachment = (file) => {
    setFiles((prevState) =>
      prevState.filter((e) => e.file.preview !== file.file.preview),
    );
  };

  const updateAttachmentDescription = (fileName, fileTitle) => {
    setFiles((state) => {
      return state.map((item) =>
        item.file.name === fileName ? { ...item, title: fileTitle } : item,
      );
    });
  };

  const validationSchema = yup
    .object()
    .shape({
      rentalId: yup.string().required('required'),
      title: yup.string().required('required'),
      description: yup.string().required('required'),
      sendCopyToEmail: yup.string().email('valid'),
      email: yup.string().email('valid').required('required'),
      requestedDeliveryTime: yup.string().when('$deliveryDateCheckbox', {
        is: true,
        then: (schema) => schema.required('required'),
        otherwise: (schema) => schema,
      }),
      isUnclassified: yup.boolean().required('required'),
      reporter: yup.string().required('required'),
      phone: yup.string().required('required'),
    })
    .defined();

  const formProps = {
    errors: formErrors,
    form: formHolder,
    onChange: updateFormHolder,
    updateAllValidationErrors: updateAllErrors,
    updateOneValidationError: updateSingleError,
    onSubmit: () => handleShowConfirmModal(),
    schema: validationSchema,
    schemaContext: {
      deliveryDateCheckbox,
    },
  };

  return (
    <Loader isLoading={requestLoading}>
      <FormWrapper {...formProps}>
        <div className="content-group grey padded">
          <RentalsContractsSelect
            contracts={rentalContracts}
            rentalId={formHolder.rentalId}
            setRentalId={(value) => updateFormHolder('rentalId', value)}
            loading={contractsLoading}
            showOther={true}
            errors={formErrors}
          />
          <InputField
            id="location"
            label="Lokasjon (valgfritt)"
            placeholder="Eksempel: Skivebua til venstre for flaggstanga"
            value={formHolder.location}
            updateField={updateFormHolder}
          />
          <FaultTypeSelect
            formHolder={formHolder}
            updateFormHolder={updateFormHolder}
            setErrorMessageTypes={setErrorMessageTypes}
          />
        </div>

        <Space height="2rem" />

        <div className="content-group grey padded">
          <InputField
            id="title"
            label="Overskrift"
            placeholder="Maks. 60 tegn"
            value={formHolder.title}
            updateField={updateFormHolder}
            maxLength={60}
          />
          <TextAreaField
            id="description"
            label="Beskriv hva henvendelsen gjelder"
            rows={5}
            value={formHolder.description}
            updateField={updateFormHolder}
          />
          <Checkbox
            errors={formErrors}
            name="deliveryDateCheckbox"
            label="Ønsket leveringsdato (valgfritt)"
            onChange={handleDeliveryDateCheckbox}
            value={deliveryDateCheckbox}
          />
          {deliveryDateCheckbox && (
            <InputField
              id="requestedDeliveryTime"
              value={formHolder.requestedDeliveryTime}
              updateField={updateFormHolder}
            />
          )}
        </div>
        <Space height="2rem" />
        <ContactDetails
          errors={formErrors}
          formHolder={formHolder}
          updateFormHolder={updateFormHolder}
          setFormHolder={setFormHolder}
          alternativeNames={{
            name: 'reporter',
            mobile: 'phone',
            copy: 'copyEmailAddress',
          }}
        />
        <AttachmentSection
          files={files}
          addAttachment={addAttachment}
          removeAttachment={removeAttachment}
          updateAttachmentDescription={updateAttachmentDescription}
        />
        <AdditionalConfirmations
          formHolder={formHolder}
          updateFormHolder={updateFormHolder}
          errors={formErrors}
        />
        <ButtonRow>
          <ButtonRow.Right
            color="red"
            className="btn"
            key="1"
            onClick={() => handleShowConfirmModal()}
            type="submit"
            data-testid="serviceorder-button-submit"
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              Send inn
            </div>
          </ButtonRow.Right>
        </ButtonRow>
      </FormWrapper>
      {modalIsOpen && (
        <ApplicationSummaryModal
          isOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          data={confirmData && confirmData.filter((el) => el)}
          onSubmit={handleSubmit}
        />
      )}
    </Loader>
  );
};

export default FaultFormRental;
