// @flow

import { DeliberateAny } from "../types/DelibrateAny";

export function fetchStaticFilterOptions() {
  return { type: "FETCH_STATIC_FILTER_OPTIONS" };
}

export function fetchHousingDataOptions() {
  return { type: "FETCH_HOUSING_DATA_OPTIONS" };
}

export function fetchMyDifs() {
  return { type: "FETCH_MY_DIFS" };
}

export function fetchDropzoneOptions() {
  return { type: "FETCH_DROPZONE_OPTIONS" };
}

export function fetchLoggedInUser(user: DeliberateAny) {
  return { type: "FETCH_LOGGEDIN_USER", user };
}
export function updateJwtForLoggedInUser(
  user: DeliberateAny,
  JwtToken: DeliberateAny
) {
  const updatedUser = { ...user, JwtToken };
  return { type: "RECEIVE_LOGGEDIN_USER", user: updatedUser };
}
