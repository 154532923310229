import {
  fetchHousingInfoText,
  setServiceArea,
  updateAllApplicationErrors,
  updateApplication,
  updateSingleApplicationError,
} from 'actions/housingActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import DateInput from 'components/DateInput/DateInput';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import Input from 'components/form/Input/Input';
import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import Select from 'components/form/Select/Select';
import HelpText from 'components/HelpText/HelpText';
import Loader from 'components/Loader/Loader';
import connect from 'helpers/connectWithRouter';
import _ from 'lodash/fp';
import moment from 'moment';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropSlideNavigation } from 'types/PropNavigationBase';
import { getSiblingPath } from 'utils/helpers';
import * as yup from 'yup';

import type {
  InfoTextType,
  HousingApplicationType,
  QuarterApplicationType,
  ServiceAreaType,
} from 'types/housingTypes';

type PropsType = PropSlideNavigation & {
  applicationType: string;
  nextSlidePath: string;
  housingDataOptionsLoaded: boolean;
  fetchHousingInfoText: () => void;
  isLoading: boolean;
  errors: object;
  application: HousingApplicationType | QuarterApplicationType;
  infoText: InfoTextType;
  serviceAreas: Array<ServiceAreaType>;
  updateApplication: (key: string, value: DeliberateAny) => void;
  setServiceArea: (
    applicationType: string,
    serviceArea: ServiceAreaType,
  ) => void;
  updateAllApplicationErrors: (errors: object) => void;
  updateSingleApplicationError: (name: string, errors: object) => void;
  defaultTo: string;
  userData: DeliberateAny;
};

export class UnconnectedPersonalInfo extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    if (!this.props.housingDataOptionsLoaded || !this.props.infoText) {
      this.props.goToSlide(
        getSiblingPath(this.props.location.pathname, this.props.defaultTo),
      );
    }
    this.props.updateApplication('fullName', this.props.userData.Name);
    this.props.updateApplication('cellPhone', this.props.userData.Phone);
    this.props.updateApplication(
      'secondaryEmail',
      this.props.userData.SecondaryEmail,
    );
    this.props.updateApplication('primaryEmail', this.props.userData.Email);
    this.props.updateApplication('applicantId', this.props.userData.Username);
    this.props.updateApplication(
      'employeeNumber',
      this.props.userData.EmployeeNumber,
    );
    // FIXME: dateOfBirth is probably no longer in use, as we don't save that info in the db
    if (!this.props.application.dateOfBirth) {
      this.props.updateApplication(
        'dateOfBirth',
        this.props.userData.DateOfBirth &&
          this.props.userData.DateOfBirth !== ''
          ? this.props.userData.DateOfBirth
          : '',
      );
    }
  }

  onSubmit = () => {
    if (!this.props.application.assignmentFromDate) {
      this.props.updateApplication('assignmentFromDate', moment());
    }
    this.props.goToSlide(
      getSiblingPath(this.props.location.pathname, this.props.nextSlidePath),
    );
  };

  handleSetServiceArea = (_: string, serviceAreaId: string | number) => {
    const serviceArea = this.props.serviceAreas.find(
      (area) => area.key === serviceAreaId,
    );

    if (serviceArea) {
      this.props.setServiceArea(this.props.applicationType, serviceArea);
    }
  };

  render() {
    const validatorConfig = yup
      .object()
      .shape({
        dateOfBirth: yup
          .string()
          .required('required')
          .matches(/^([0-2][0-9]|(3)[0-1]).(0[1-9]|1[0-2]).\d{4}$/, {
            rule: 'date.format',
            format: 'DD.MM.YYYY',
          })
          .test(
            'max-date',
            { rule: 'date.max', max: `${moment().format('DD.MM.YYYY')}` },
            (value) => moment(value, 'DD.MM.YYYY').isBefore(moment()),
          ),
        assignmentFromDate: yup.string(),
        assignmentToDate: yup.string(),
        isDefenceEmployee: yup.boolean().required('required'),
      })
      .defined();

    const formProps = {
      errors: this.props.errors,
      form: {
        ...this.props.application,
      },
      onSubmit: this.onSubmit,
      onChange: this.props.updateApplication,
      updateAllValidationErrors: this.props.updateAllApplicationErrors,
      updateOneValidationError: this.props.updateSingleApplicationError,
      schema: validatorConfig,
    };

    if (!this.props.userData) return null;

    return (
      <Loader
        isLoading={this.props.isLoading || !this.props.housingDataOptionsLoaded}
      >
        <FormWrapper {...formProps} unclassifiedStamp>
          <h1>Persondata og beordring</h1>

          <div className="row margin">
            <Input
              disabled
              name="name"
              value={this.props.userData.Name}
              title="Navn"
            />
          </div>

          <div className="row margin">
            <Input
              disabled
              name="phone"
              value={this.props.userData.Phone}
              title="Mobiltelefon"
            />
          </div>

          <div className="row margin">
            <Input
              disabled
              name="email"
              value={this.props.userData.Email}
              title="E-postadresse"
            />
          </div>

          <div className="row margin">
            <Input
              disabled
              name="secondaryemail"
              value={this.props.userData.SecondaryEmail}
              title="Sekundær e-postadresse"
            />
          </div>

          <div className="row margin">
            <Input
              disabled
              name="employeenumber"
              value={this.props.userData.EmployeeNumber}
              title="Ansattnummer"
            />
          </div>

          <div className="row margin">
            <Input
              name="dateOfBirth"
              value={this.props.application.dateOfBirth?.toString()}
              title={
                <HelpText helpText={this.props.infoText.BirthDateInfo}>
                  Fødselsdato (DD.MM.YYYY)
                </HelpText>
              }
              data-testid="input-application-dateofbirth"
              onChange={this.props.updateApplication}
              errors={this.props.errors}
            />
          </div>

          {this.props.application.isRenewal && (
            <div className="row margin">
              <Select
                onChange={this.handleSetServiceArea}
                defaultChoice="Velg tjenestested"
                name="serviceAreaId"
                label="Velg tjenestested"
                options={this.props.serviceAreas}
                value={this.props.application.serviceAreaId}
              />
            </div>
          )}

          <div className="row margin">
            <RadioButtonGroup
              legend="Arbeidsgiver"
              options={[
                {
                  label: this.props.infoText.EmployeeTypeDefenseText,
                  value: 'true',
                },
                {
                  label: this.props.infoText.EmployeeTypeOtherText,
                  value: 'false',
                },
              ]}
              name="isDefenceEmployee"
              checkedOption={this.props.application?.isDefenceEmployee ?? false}
              onChange={(n, v) => {
                this.props.updateApplication(n, v.toString());
              }}
              errors={this.props.errors}
            />
          </div>

          <div className="row half-margin">
            <div className="flex-row half">
              <DateInput
                showYear
                name="assignmentFromDate"
                selectedDate={this.props.application.assignmentFromDate}
                title="Beordringsdato fra"
                minDate={moment('2000-01-01')}
                onChange={(n, v) => {
                  this.props.updateApplication(n, v);
                }}
              />
              <DateInput
                showYear
                optional={{
                  checkedDate:
                    this.props.application.assignmentFromDate &&
                    moment(this.props.application.assignmentFromDate).add(
                      2,
                      'y',
                    ),
                  uncheckedDate: this.props.application.assignmentFromDate,
                }}
                name="assignmentToDate"
                selectedDate={this.props.application.assignmentToDate}
                minDate={this.props.application.assignmentFromDate}
                title="Beordringsdato til"
                onChange={(n, v) => {
                  this.props.updateApplication(n, v);
                }}
              />

              {!_.isEmpty(this.props.infoText.PersonalInfoDateHelpText) && (
                <div
                  style={{
                    display: 'contents',
                  }}
                >
                  <HelpText
                    helpText={this.props.infoText.PersonalInfoDateHelpText}
                  />
                </div>
              )}
            </div>
          </div>

          <p>{this.props.infoText.AssignmentDateInfo}</p>
          <ButtonRow>
            <ButtonRow.Right type="submit" color="red">
              Neste
            </ButtonRow.Right>
          </ButtonRow>
        </FormWrapper>
      </Loader>
    );
  }
}

export default connect(
  (state: DeliberateAny, props: PropsType) => ({
    isLoading: state.housing.isLoading,
    errors: state.housing.errors,
    application: state.housing[`${props.applicationType}Application`],
    infoText: state.housing.housingInfoText,
    serviceAreas: state.housing.serviceAreas,
    housingDataOptionsLoaded: state.options.housingDataOptionsLoaded,
  }),
  (dispatch, props) =>
    bindActionCreators(
      {
        updateApplication: (...args: [string, string | boolean]) =>
          updateApplication(props.applicationType, ...args),
        updateAllApplicationErrors: (...args: [DeliberateAny]) =>
          updateAllApplicationErrors(props.applicationType, ...args),
        updateSingleApplicationError: (...args: [string, DeliberateAny]) =>
          updateSingleApplicationError(props.applicationType, ...args),
        fetchHousingInfoText,
        setServiceArea,
      },
      dispatch,
    ),
)(UnconnectedPersonalInfo);
