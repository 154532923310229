import DetailsCard, { DetailsCardType } from "./DetailsCard/DetailsCard";

type PropsType = {
  cards: Array<DetailsCardType>;
  expandable?: boolean;
  rowAlignment?: boolean;
  extraStyling?: string;
};

const DetailsCards = (props: PropsType) => (
  <div className="details-cards">
    <div className={`${props.rowAlignment ? "row-alignment" : ""}`}>
      {props.cards.map((card, i) => (
        <DetailsCard
          key={card.header + i}
          expandable={props.expandable}
          extraStyling={props.extraStyling || ""}
          {...card}
        />
      ))}
    </div>
  </div>
);

export default DetailsCards;
