import './SigningModal.scss';

import Input from 'components/form/Input/Input';
import { validateNorwegianSSN } from 'helpers/ssn';
// @flow
import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { DeliberateAny } from 'types/DelibrateAny';

import RouterModal from '../RouterModal/RouterModal';

type PropsType = {
  path: string;
  documentId: string;
  title: string;
  ariaLabel: string;
  description: string;
  acceptSigning: (documentId: string, rejectionReason?: string) => void;
};

const SigningModal = ({
  path,
  title,
  ariaLabel,
  description,
  acceptSigning,
}: PropsType) => {
  const [ssn, setSsn] = useState<DeliberateAny>();
  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState(false);

  const errorRules = {
    WrongFormat: { rule: "wrongFormat" },
    Required: { rule: "required" },
  };

  const validate = () => {
    if (ssn === "") {
      setErrors({ ssn: errorRules.Required });
      setValid(false);
      return;
    }

    const ssnOkay = validateNorwegianSSN(ssn);

    if (!ssnOkay) {
      setErrors({ ssn: errorRules.WrongFormat });
      setValid(false);
      return;
    }

    setErrors({});
    setValid(true);
  };

  useUpdateEffect(() => {
    validate();
  }, [ssn]);

  return (
    <RouterModal
      title={title}
      ariaLabel={ariaLabel}
      description={description}
      path={path}
      submit={{
        text: "Signer",
        disabled: !valid,
        onClick: () => {
          if (acceptSigning) acceptSigning(ssn);
        },
      }}
    >
      <div className="margin sign-flex">
        <p>
          Merk at signerte husleiekontrakter er unntatt offentlighet jmfr.
          Offentlighetsloven § 13, 1. ledd. Hvis kontrakten lastes ned etter
          signering må den derfor behandles ihht gjeldende rutiner for
          dokumenter unntatt offentlighet.
        </p>
        <p>
          Skriv inn ditt fødselsnummer for å kunne signere med BankID på mobil,
          vanlig BankID eller Buypass ID.
        </p>
        <div>
          <Input
            name="ssn"
            value={ssn}
            title="Fødselsnummer (11 siffer)"
            placeholder="Skriv inn fødselsnummer"
            onChange={(undef, val) => {
              const onlyDigits = val.replace(/\D/g, "");
              setSsn(onlyDigits);
            }}
            errors={errors}
          />
        </div>
        <p>Fødselsnummeret vil ikke lagres i Basen.</p>
      </div>
    </RouterModal>
  );
};

export default SigningModal;
