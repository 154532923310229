import './HelpText.scss';

import { CloseIcon, HelpFullIcon, HelpIcon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import { FC, ReactNode, useEffect, useState } from 'react';

interface IHelpTextProps {
  children: ReactNode | ReactNode[];
  content?: ReactNode | ReactNode[];
}

const HelpText: FC<IHelpTextProps> = ({ children, content }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (content)
      setTimeout(() => {
        setIsLoaded(true);
      }, 1000);
  }, [content]);

  if (!content) return children;

  return (
    <div className="help-text-wrapper">
      {children}
      <BasenIcon
        icon={HelpIcon}
        hoverIcon={HelpFullIcon}
        isHover={show}
        onClick={() => setShow((x) => !x)}
      />
      <div
        className={`help-text-container shadow ${show ? 'fade-in-5' : 'fade-out-5'} ${isLoaded ? '' : 'invisible'}`}
      >
        <div className="help-text-body">
          <div className="help-text-close">
            <BasenIcon
              icon={CloseIcon}
              size={'s'}
              onClick={() => setShow(false)}
            />
          </div>
          {content}
        </div>
      </div>
    </div>
  );
};

export default HelpText;
