import { labelYupError } from 'helpers/yupLocales';
import { DeliberateAny } from 'types/DelibrateAny';
import { AnyObject, ObjectSchema } from 'yup';

import Yup from './yupExtensions';

export interface IValidationResponse {
  success: boolean;
  errors: AnyObject;
}

export const bookingValidations = {
  arrivalDeparture: Yup.array().of(Yup.number()).required().dateRange(),
  tooFewRooms: Yup.bool().when('rooms', {
    is: (rooms: number) => rooms > 0,
    then: (schema) =>
      schema.oneOf([false], 'Det er for få rom for antall gjester'),
    otherwise: (schema) => schema,
  }),
  tooManyRooms: Yup.bool().when('rooms', {
    is: (rooms: number) => rooms > 0,
    then: (schema) =>
      schema.oneOf([false], 'Det er for mange rom for antall gjester'),
    otherwise: (schema) => schema,
  }),
  rooms: Yup.array()
    .of(
      Yup.object().shape({
        guests: Yup.array().of(
          Yup.object().shape({
            fullName: Yup.string().required(
              'Påkrevd å skrive inn alle gjestenavn',
            ),
            mobile: Yup.string().required(
              'Påkrevd å skrive inn alle mobilnumre',
            ),
          }),
        ),
      }),
    )
    .min(1, 'Det må velges minst ett rom før du kan gå videre'),
  purposeOption: Yup.string().required('Valg av formål er påkrevd'),
  purposeDescription: Yup.string().required('Beskrivelse av formål er påkrevd'),
};

export const handleValidation = async (
  schema: ObjectSchema<DeliberateAny, AnyObject, DeliberateAny, ''>,
  object: AnyObject,
  errorMessages: AnyObject,
): Promise<IValidationResponse> => {
  let validationResponse: IValidationResponse = {
    success: true,
    errors: {},
  };
  try {
    await schema.validate(object, {
      abortEarly: false,
      context: errorMessages,
    });
    return validationResponse;
  } catch (err: DeliberateAny) {
    const validationErrors = {};
    err.inner.forEach(
      (error) =>
        (validationErrors[error.path] = labelYupError(error, errorMessages)),
    );

    validationResponse.success = false;
    validationResponse.errors = validationErrors;
    return validationResponse;
  }
};
