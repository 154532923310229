import './Summary.scss';

import { FC, ReactNode } from 'react';

export type SummarySection = {
  header: string;
  content: ReactNode;
  columnSpan?: number;
  className?: string;
};

interface ISummaryPropsType {
  sections: SummarySection[];
  className?: string;
}

const Summary: FC<ISummaryPropsType> = ({ sections, className }) => {
  const renderSummary = () => {
    const mappedSections = sections.map((section, i) => {
      return (
        <div
          key={`section_${section.header}-${i}`}
          className={`section-container ${section.className}`}
          style={{ gridColumn: `span ${section.columnSpan}` }}
        >
          <strong>{section.header}</strong>
          <div className="section-content">{section.content}</div>
        </div>
      );
    });
    return (
      <div className={`summary-container ${className}`}>{mappedSections}</div>
    );
  };

  return renderSummary();
};

export default Summary;
