import ReactResponsive from "react-responsive";

export const smallBreakpointValue = 544;
export const msBreakpointValue = 720;
export const mediumBreakpointValue = 1024;
export const largeBreakpointValue = 1200;

const smallBreakpointValuePlus1 = smallBreakpointValue + 1;
const mediumBreakpointValuePlus1 = mediumBreakpointValue + 1;
const largeBreakpointValuePlus1 = largeBreakpointValue + 1;

export const MediaQuery = (props) => (
  <ReactResponsive
    {...props}
    values={{ width: window.innerWidth, height: window.innerHeight }}
  />
);

export const mediaQueries = {
  xlarge: `(min-width: ${largeBreakpointValuePlus1}px)`,
  large: `(min-width: ${mediumBreakpointValuePlus1}px) and (max-width: ${largeBreakpointValue}px)`,
  largeUp: `(min-width: ${mediumBreakpointValuePlus1}px)`,
  largeDown: `(max-width: ${largeBreakpointValue}px)`,
  medium: `(min-width: ${smallBreakpointValuePlus1}px) and (max-width: ${mediumBreakpointValue}px)`,
  mediumDown: `(max-width: ${mediumBreakpointValue}px)`,
  mediumUp: `(min-width: ${smallBreakpointValuePlus1}px)`,
  mediumLarge: `(min-width: ${smallBreakpointValuePlus1}px) and (max-width: ${largeBreakpointValue}px)`,
  ms: `(min-width: ${smallBreakpointValuePlus1}px) and (max-width: ${msBreakpointValue}px)`,
  msDown: `(max-width: ${msBreakpointValue}px)`,
  small: `(max-width: ${smallBreakpointValue}px)`,
};
