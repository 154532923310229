// @flow

import { ReactElement } from 'react';
import './ModalWrapper.scss';

type PropsType = {
  children?: ReactElement | Array<ReactElement>,
};

const ModalWrapper = (props: PropsType) => (
  <div className="modal-wrapper">
    {props.children}
  </div>
);

export default ModalWrapper;

