import "../Select/Select.scss";

import PropTypes from "prop-types";

import { DeliberateAny } from "../../../types/DelibrateAny";
import FormErrors from "../FormErrors";
import RegionSelect from "./RegionSelect";

const RegionSelectErrorWrapper = ({
  onChange,
  value,
  initvalue,
  defaultChoice,
  includeShowAll,
  autoSelectFirstRegion,
  name,
  errors,
  title,
  options,
  "data-testid": dataTestId,
}: DeliberateAny) => {
  const error = errors && errors[name];

  return (
    <label className={`${error ? "invalid" : ""}`} htmlFor={name}>
      <span>
        {title} {error && <FormErrors rule={error} />}
      </span>
      <RegionSelect
        options={options}
        onChange={onChange}
        value={value}
        name={name}
        includeShowAll={includeShowAll}
        autoSelectFirstRegion={autoSelectFirstRegion}
        initvalue={initvalue}
        defaultChoice={defaultChoice}
        data-testid={dataTestId}
      />
    </label>
  );
};

export default RegionSelectErrorWrapper;

RegionSelectErrorWrapper.propTypes = {
  onChange: PropTypes.func,
  initvalue: PropTypes.string,
  defaultChoice: PropTypes.string,
  includeShowAll: PropTypes.bool,
  autoSelectFirstRegion: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string,
  options: PropTypes.array,
  "data-testid": PropTypes.string,
};
