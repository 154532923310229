import {
  getNotificationPreferencesURL,
  updateNotificationPreferencesURL,
} from "api/cms/cms_api";
import Checkbox from "components/form/Checkbox/Checkbox";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import useAxios from "../../hooks/useAxios";
import Tooltip from "../Tooltip/Tooltip";

const UserNotificationSmsCheckbox = (props) => {
  const { sendRequest } = useAxios();
  const { onChange, checked } = props;
  const { notificationDomain, tooltipPosition, ...checkBoxProps } = props;
  const [currentNotification, setCurrentNotification] =
    useState<DeliberateAny>();
  const [notificationPreferences, setNotificationPreferences] = useState<
    DeliberateAny[]
  >([]);

  const [value, setValue] = useState(checked);

  const fetchSettings = async () => {
    await sendRequest(
      {
        method: "GET",
        url: getNotificationPreferencesURL,
      },
      (r) => {
        if (r && r.length > 0) {
          setNotificationPreferences(r);
          const notification = r.find(
            (n) => n.NotificationDomain === notificationDomain
          );
          if (notification) {
            setCurrentNotification(notification);
            setValue(notification.Sms);
          }
        }
      }
    );
  };

  const updateSettings = async () => {
    const currentSetting = notificationPreferences.find(
      (n) => n.NotificationDomain === notificationDomain
    );
    if (!currentSetting) return;
    const newSetting = { ...currentSetting, Sms: value };

    const newSettings = [
      ...notificationPreferences.filter(
        (n) => n.NotificationDomain !== notificationDomain
      ),
      newSetting,
    ];

    if (
      newSettings.filter((n) => !n.PrimaryEmail && !n.SecondaryEmail && !n.Sms)
        .length > 0
    )
      return;

    setNotificationPreferences(newSettings);
    await sendRequest({
      method: "PUT",
      url: updateNotificationPreferencesURL,
      data: newSettings,
    });
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    if (notificationPreferences && notificationPreferences.length > 0)
      updateSettings();
  }, [value]);

  const handleChange = (n, c) => {
    onChange(n, c);
    setValue(c);
  };

  const isDisabled = () => {
    return (
      checkBoxProps.disabled ||
      (currentNotification &&
        !currentNotification.PrimaryEmail &&
        !currentNotification.SecondaryEmail)
    );
  };

  return (
    <Tooltip
      message="For å kunne slå av varsling på SMS må du gå til min side og velge en epostadresse som varslingsmetode."
      disabled={!isDisabled()}
      position={tooltipPosition || "center"}
      help
    >
      <Checkbox
        {...checkBoxProps}
        disabled={isDisabled()}
        checked={value}
        onChange={handleChange}
      />
    </Tooltip>
  );
};

export default UserNotificationSmsCheckbox;

UserNotificationSmsCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.element,
   
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  "data-testid": PropTypes.string,
  notificationDomain: PropTypes.string,
  tooltipPosition: PropTypes.oneOf([
    "top",
    "bottom",
    "left",
    "right",
    "center",
  ]),
};
