import { myTabsURL } from 'api/cms/cms_api';
import Loader from 'components/Loader/Loader';
import Nav from 'components/Nav/Nav';
import { useUser } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { MyTabsType } from 'types/myTabsTypes';

function MyPage() {
  const { user, fetchUser, requestLoading: isLoading } = useUser();

  const { sendRequest, requestLoading } = useAxios();

  const [myTabs, setTabs] = useState<MyTabsType>({} as MyTabsType);

  useEffect(() => {
    if (!user && !isLoading) fetchUser();
  }, [user]);

  const fetchMyTabs = async () => {
    await sendRequest(
      {
        url: myTabsURL,
        method: 'GET',
        params: {
          _: Date.now(),
        },
      },
      setTabs,
    );
  };

  useEffect(() => {
    fetchMyTabs();
  }, [user]);

  return (
    <div>
      <Loader isLoading={isLoading || requestLoading}>
        <div>
          <h1>Min side</h1>
        </div>
        <div className="tabs-wrapper">
          <div className="tabs">
            <ul className="navlinkWrapper">
              <Nav
                to="/my-page/tab-my-profile"
                text="Profil"
                alsoActiveWith={['/my-page']}
              />
              {myTabs.ShowServiceOrdersTab && (
                <Nav to="/my-page/tab-my-service-orders" text="Saker" />
              )}
              {myTabs.ShowBookingsTab && (
                <Nav to="/my-page/tab-my-bookings" text="Overnattinger" />
              )}
              {myTabs.ShowBookingsV2Tab && (
                <Nav to="/mypage/booking" text="Overnattinger (ny)" />
              )}
              {myTabs.ShowApplicationsTab && (
                <Nav
                  to="/my-page/tab-my-housing-applications"
                  text="Mine søknader"
                />
              )}
              {myTabs.ShowRentalsTab && (
                <Nav to="/my-page/tab-my-rentals" text="Mine leieforhold" />
              )}
              {myTabs.ShowLocationQrCodeTab && (
                <Nav
                  to="/my-page/tab-location-qr-codes"
                  text="Lokasjon QR-koder"
                />
              )}
              <Nav to="/my-page/tab-my-notifications" text="Mine varsler" />
            </ul>
          </div>
          <Outlet />
        </div>
      </Loader>
    </div>
  );
}

export default MyPage;
