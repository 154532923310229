import { AlertType } from 'components/AlertMessage/AlertMessage';
import Badge from 'components/Badge/Badge';
import * as I from 'components/BasenIcon';
import RoomFacilities from 'components/BookingComponents/RoomFacilities';
import Button from 'components/buttons/Button/Button';
import Counter from 'components/Counter/Counter';
import ExpandSection from 'components/ExpandSection/ExpandSection';
import Image from 'components/ImageComponents/Image';
import GuestInfoForm from 'components/ModalComponents/ChangeBooking/GuestInfoForm';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { GuestInfo, RoomWithGuest } from 'types/bookingRequests';
import { AvailableRoomType } from 'types/BookingTypes/roomTypes';
import { DeliberateAny, SetState } from 'types/DelibrateAny';

interface IRoomFormProps {
  errors: DeliberateAny;
  arrivalDeparture: DateObject[];
  roomInfo: AvailableRoomType[];
  roomCount: { [key: string]: number };
  setRoomCount: SetState<{ [key: string]: number }>;
  itemRooms: RoomWithGuest[];
  setItemRooms: SetState<RoomWithGuest[]>;
}

const RoomForm: FC<IRoomFormProps> = ({
  errors,
  arrivalDeparture,
  roomInfo,
  roomCount,
  setRoomCount,
  itemRooms,
  setItemRooms,
}) => {
  const [showAllFacilitiesItem, setShowAllFacilitiesItem] = useState<string[]>(
    [],
  );

  const addShowFacilities = (roomTypeId: string) => {
    setShowAllFacilitiesItem([...showAllFacilitiesItem, roomTypeId]);
  };

  const removeShowFacilities = (roomTypeId: string) => {
    setShowAllFacilitiesItem(
      showAllFacilitiesItem.filter((item) => item != roomTypeId),
    );
  };

  const fetchRoomAvailability = (roomTypeId: string) => {
    const room = roomInfo?.find(
      (room) => room.roomType.roomTypeId == roomTypeId,
    );
    return room ? room.availableCount : 0;
  };

  const getNrOfDays = () => {
    if (arrivalDeparture) {
      return moment(arrivalDeparture?.[1]?.toDate()).diff(
        moment(arrivalDeparture?.[0]?.toDate()),
        'days',
      );
    }
    return 0;
  };

  const createRoomWithGuest = (
    roomTypeId: string,
    index: number,
  ): RoomWithGuest => {
    return {
      roomTypeId,
      index,
      heading: roomInfo?.find((r) => r.roomType.roomTypeId === roomTypeId)
        ?.roomType.heading,
      guests: [
        {
          isUnnamed: false,
          fullName: '',
          mobile: '',
        } as GuestInfo,
      ] as GuestInfo[],
    };
  };

  const handleAddGuest = (roomTypeId: string, roomIndex: number) => {
    setItemRooms((prevItemRooms) => {
      return prevItemRooms.map((room) => {
        if (room.roomTypeId === roomTypeId && room.index === roomIndex) {
          return {
            ...room,
            guests: [
              ...(room?.guests ?? []),
              {
                isUnnamed: true,
                fullName: '',
                mobile: '',
                additionalInfo: '',
              } as GuestInfo,
            ],
          };
        }
        return room;
      });
    });
  };

  const addRoomToRoomList = (updatedRoomCount, roomTypeId) => {
    const roomToAdd = createRoomWithGuest(
      roomTypeId,
      updatedRoomCount[roomTypeId] + 1,
    );

    setItemRooms((prevItemRooms) => {
      const allRooms = [...prevItemRooms, roomToAdd];

      let reIndexStart = 1;
      Object.keys(roomCount).forEach((rKey) => {
        allRooms
          .filter((r) => r.roomTypeId === rKey)
          .forEach((r) => {
            r.index = reIndexStart;
            reIndexStart += 1;
          });
      });

      return allRooms;
    });
  };

  const removeRoomFromRoomList = (roomTypeId, roomIndex) => {
    setItemRooms((prevItemRooms) => {
      const filteredRooms = prevItemRooms.filter(
        (room) => room.roomTypeId !== roomTypeId || room.index !== roomIndex,
      );

      const reindexedRooms = filteredRooms.map((room, i) => {
        if (room.roomTypeId === roomTypeId) {
          return { ...room, index: i + 1 };
        }
        return room;
      });

      return reindexedRooms;
    });
  };

  const handleDeleteRoom = (roomTypeId, roomIndex) => {
    removeRoomFromRoomList(roomTypeId, roomIndex);

    setRoomCount((prevRoomCount) => {
      const updatedRoomCount = { ...prevRoomCount };
      if (updatedRoomCount[roomTypeId] > 0) {
        updatedRoomCount[roomTypeId] -= 1;
      }
      return updatedRoomCount;
    });
  };

  const handleCounterChange = (roomTypeId, amount) => {
    const updatedRoomCount = { ...roomCount };
    updatedRoomCount[roomTypeId] = amount;

    // Add
    if (roomCount[roomTypeId] < amount) {
      addRoomToRoomList(updatedRoomCount, roomTypeId);
    }

    // Remove
    if (roomCount[roomTypeId] > amount) {
      const filteredRooms = itemRooms.filter(
        (room) => room.roomTypeId === roomTypeId,
      );
      const roomIndex = filteredRooms[filteredRooms.length - 1].index;
      removeRoomFromRoomList(roomTypeId, roomIndex);
    }

    setRoomCount(updatedRoomCount);
  };

  const roomHasError = (roomIndex: number) => {
    let hasErrors = false;

    if (itemRooms[roomIndex].guests) {
      for (
        let guestIndex = 0;
        guestIndex < itemRooms[roomIndex].guests.length;
        guestIndex++
      ) {
        if (errors[`rooms[${roomIndex}].guests[${guestIndex}].fullName`]) {
          hasErrors = true;
          break;
        }
      }
    }

    return hasErrors;
  };

  return (
    <>
      {roomInfo.map((room, i) => {
        return (
          <div
            className="fb-change-booking"
            key={`${room.roomType.roomTypeId}-${i}`}
          >
            <div
              key={`room-type-${room.roomType.roomTypeId}`}
              className="room-type-container"
            >
              <div className="room-type-booking-image-container ">
                <Image
                  image={room.roomType.roomIcon}
                  className="room-type-booking-image"
                />
              </div>
              <RoomFacilities
                facilitiesHtml={room.roomType.facilities}
                roomTypeId={room.roomType.roomTypeId}
                showAllFacilities={showAllFacilitiesItem.includes(
                  room.roomType.roomTypeId,
                )}
                toggleFacilities={(roomTypeId) => {
                  if (showAllFacilitiesItem.includes(roomTypeId)) {
                    removeShowFacilities(roomTypeId);
                  } else {
                    addShowFacilities(roomTypeId);
                  }
                }}
              />

              <div className="room-type-selection">
                <Badge
                  variant={AlertType.Special1}
                >{`${Math.round(room.roomType.price ?? 0)},- for ${getNrOfDays()} ${getNrOfDays() != 1 ? 'netter' : 'natt'}`}</Badge>
                <Counter
                  name={room.roomType.roomTypeId}
                  label={'Antall rom'}
                  formHolder={roomCount}
                  updateForm={(i, v) => handleCounterChange(i, v)}
                  errors={errors}
                  max={fetchRoomAvailability(room.roomType.roomTypeId)}
                  min={0}
                />
                <div>{`(${fetchRoomAvailability(room.roomType.roomTypeId)} ledig)`}</div>
              </div>
            </div>

            {itemRooms.map((rwg, rwgIndex) => {
              const guestPrice = room.priceList.find(
                (x) => x.persons === roomCount[room.roomType.roomTypeId],
              );
              if (rwg.roomTypeId === room.roomType.roomTypeId) {
                return (
                  <ExpandSection
                    key={rwg.roomTypeId + rwg.index}
                    header={`Rom ${rwg.index}`}
                    description={`(${room.roomType.heading})`}
                    errorMessage={rwg.guests?.length === 0 ? 'Tomt rom' : ''}
                    openOnDefault={roomHasError(rwgIndex)}
                    content={
                      <React.Fragment>
                        <GuestInfoForm
                          guests={rwg.guests ?? []}
                          roomIndex={rwgIndex}
                          setItemRooms={setItemRooms}
                          errors={errors}
                        />
                        {/* Add guest list button group here */}
                        <div className="guest-list-button-group">
                          <Button
                            icon={I.DeleteIcon}
                            className="tertiary"
                            onClick={() =>
                              handleDeleteRoom(rwg.roomTypeId, rwg.index)
                            }
                          >
                            Slett rom
                          </Button>
                          {(rwg.guests?.length ?? 0) <
                            room.roomType.standardCapacity +
                              room.roomType.extraCapacity && (
                            <Button
                              icon={I.PlusIcon}
                              className="tertiary"
                              onClick={() =>
                                handleAddGuest(rwg.roomTypeId, rwg.index)
                              }
                            >
                              Legg til gjest (
                              {(guestPrice?.priceValue ?? 0) -
                                (room.roomType.price ?? 0)}
                              ,-)
                            </Button>
                          )}
                        </div>
                      </React.Fragment>
                    }
                  />
                );
              }
            })}
          </div>
        );
      })}
    </>
  );
};

export default RoomForm;
