import './WidgetServiceOrder.scss';

import { fetchStaticFilterOptions } from 'actions/optionsActions';
import Select from 'components/form/Select/Select';
import List from 'components/List/List';
import ListItem from 'components/ListItem/ListItem';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DeliberateAny } from 'types/DelibrateAny';

import {
  establishmentsURL,
  proffSearchURL,
} from '../../../../api/basenproff/basenproff_api';
import { axiosFetch } from '../../../../hooks/useAxios';

const WidgetServiceOrder = ({
  marketingArea,
  establishmentNumber,
  regions,
  orderStates,
  saveModule,
  deleteModule,
  id,
  fetchStaticFilterOptions,
}) => {
  const [serviceOrder, setServiceOrder] = useState<DeliberateAny>();
  const [widgetSettings, setWidgetSettings] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(marketingArea);
  const [establishmentList, setEstablishmentList] = useState<DeliberateAny[]>();
  const [selectedEstablishment, setSelectedEstablishment] =
    useState(establishmentNumber);
  const [serviceOrderExpanded, setServiceOrderExpanded] =
    useState<DeliberateAny>();

  useEffect(() => {
    if (!regions || !orderStates) {
      fetchStaticFilterOptions();
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (selectedRegion) {
      if (selectedRegion !== marketingArea) {
        setSelectedEstablishment(undefined);
        setEstablishmentList(undefined);
      }
      axiosFetch({
        url: establishmentsURL,
        data: {
          MarketingArea: selectedRegion,
        },
      }).then((response) => {
        if (isSubscribed) {
          setEstablishmentList(
            response.map((est) => ({ key: est.Key, value: est.Value }))
          );
        }
      });
    }
     
    return () => {
      isSubscribed = false;
    };
  }, [selectedRegion]);

  useEffect(() => {
    let isSubscribed = true;
    if (selectedRegion && selectedEstablishment) {
      axiosFetch({
        url: proffSearchURL,
        data: {
          MarketingArea: selectedRegion,
          Establishment: selectedEstablishment,
          takeCount: 5,
        },
      }).then((response) => {
        if (isSubscribed) {
          if (response.length) {
            setServiceOrder(response);
          } else {
            setServiceOrder([]);
          }
        }
      });
    } else {
      setWidgetSettings(true);
    }
     
    return () => {
      isSubscribed = false;
    };
  }, [selectedEstablishment]);

  const serviceOrderColumns: DeliberateAny[] = [
    {
      label: 'Henvendelse',
      name: 'serviceOrder',
      className: 'serviceOrder',
      renderFunction: (item) => (
        <div className="column-title columnTitle">
          <span>{item.Title}</span>
          <br />
          <span style={{ fontSize: '13px' }} className="status-text">
            <strong>Status:</strong>{' '}
            <span className="red">
              {orderStates && orderStates.length > 0
                ? orderStates.find(
                    (state) => state.key === item.ServiceOrderState
                  ).value
                : ''}
            </span>
          </span>
        </div>
      ),
    },
    { label: 'Bygg', name: 'Building', className: 'building' },
    {
      label: 'Inventarnummer',
      name: 'BuildingNumber',
      className: 'buildingNumber',
    },
    {
      label: 'Forventet levering',
      name: 'DeliveryDate',
      className: 'deliveryDate',
      renderFunction: (item) =>
        item.DeliveryDate ? (
          <div>{moment(item.DeliveryDate).format('DD.MM.YY')}</div>
        ) : (
          <div />
        ),
    },
  ];

  const renderItem = (item) => (
    <ListItem
      key={item.ServiceOrderNumber}
      item={item}
      columns={serviceOrderColumns}
    />
  );

  const renderSmallList = () => (
    <ul className="widgetList">
      <li>
        <div
          className="columnTitle"
          style={{ fontWeight: '700', padding: '5.5px' }}
        >
          Henvendelse
        </div>
      </li>
      {serviceOrder.map((item) => (
        <li key={item.ServiceOrderNumber}>
          <div
            className="columnTitle"
            style={{ flexGrow: '3', width: '25%', padding: '5.5px' }}
          >
            <span>{item.Title}</span>
            <br />
            <span className="status-text" style={{ fontSize: '13px' }}>
              <strong>Status:</strong>
              <span className="red">
                {orderStates && orderStates.length > 0
                  ? orderStates.find(
                      (state) => state.Key === item.ServiceOrderState
                    )?.Value
                  : ''}
              </span>
            </span>
          </div>
          <div className="expandDiv">
            <button
              className="btn list-expand"
              onClick={() => setServiceOrderExpanded(item)}
            />
          </div>
        </li>
      ))}
    </ul>
  );

  const renderExpandedWidgetWrapper = () => {
    if (serviceOrderExpanded) {
      return (
        <div className="expanded-widget-wrapper">
          <button
            className="box-close top-right"
            onClick={() => setServiceOrderExpanded(false)}
          />
          <div className="expanded-widget-content">
            <h3>{serviceOrderExpanded.Title}</h3>
            <p>
              <strong>Status:</strong>{' '}
              <span className="red">
                {orderStates && orderStates.length > 0
                  ? orderStates.find(
                      (state) =>
                        state.Key === serviceOrderExpanded.ServiceOrderState
                    )?.Value
                  : ''}
              </span>
            </p>
            <p>
              <strong>Bygg: </strong>
              {serviceOrderExpanded.Building}
            </p>
            <p>
              <strong>Inventarnr: </strong>
              {serviceOrderExpanded.BuildingNumber}
            </p>
            <p>
              <strong>Forventet levering: </strong>
              {moment(serviceOrderExpanded.DeliveryDate).format('DD.MM.YY')}
            </p>
          </div>
        </div>
      );
    }
    return '';
  };

  const handleSave = () => {
    setWidgetSettings(false);
    saveModule(id, {
      MarketingArea: selectedRegion,
      EstablishmentNumber: selectedEstablishment,
    });
  };

  return (
    <div
      className={`widget serviceOrder wide${
        widgetSettings ? ' show-settings' : ''
      }`}
    >
      <div className="widget-wrapper">
        <div className="widget-inner-wrapper">
          <div className="header-wrapper">
            <header>
              <button
                className="btn-widget-settings"
                onClick={() => setWidgetSettings(!widgetSettings)}
              />
              <h3>
                {establishmentList && selectedEstablishment
                  ? `Siste Henvendelser på ${
                      establishmentList
                        .find(
                          (el) => el.key === selectedEstablishment.toString()
                        )
                        ?.value.split(' ')[1]
                    }`
                  : 'Henvendelser'}
              </h3>
            </header>
            <div className="widget-settings-wrapper">
              <div className="widget-settings">
                <div className="widget-specific-settings margin">
                  <span>Velg distrikt</span>
                  <Select
                    name="region"
                    options={
                      regions &&
                      regions.map((region) => ({
                        key: region.code,
                        value: region.caption,
                      }))
                    }
                    value={selectedRegion}
                    onChange={(name, value) => setSelectedRegion(value)}
                    defaultChoice="Velg distrikt"
                    loading={!regions}
                  />
                  <span>Velg etablissement</span>
                  <Select
                    name="establishment"
                    options={establishmentList}
                    onChange={(name, value) => setSelectedEstablishment(value)}
                    value={selectedEstablishment}
                    defaultChoice={
                      selectedRegion > 0
                        ? 'Velg etablissement'
                        : 'Velg distrikt først'
                    }
                    loading={!establishmentList && selectedRegion > 0}
                    dependentOn="distrikt først"
                  />
                </div>
                <button className="btn white" onClick={() => deleteModule(id)}>
                  Fjern Modul
                </button>
                <button
                  className="btn white align-right"
                  onClick={() => handleSave()}
                >
                  Lagre
                </button>
              </div>
            </div>
            <div
              className={`widget-content-container${
                !serviceOrder ? ' widget-loading' : ''
              }${serviceOrderExpanded ? ' expanded' : ''}`}
            >
              <div className="widget-loader" />
              <div className="widget-content">
                {serviceOrder && serviceOrder.length ? (
                  <div>
                    <div className={'list'}>
                      <List
                        columns={serviceOrderColumns}
                        items={serviceOrder}
                        renderItem={renderItem}
                        extraListStyle="no-border-list"
                        extraHeaderStyle="white-background-color"
                      />
                    </div>
                    <div className={'smallList'}>{renderSmallList()}</div>
                  </div>
                ) : (
                  <p className="widget-no-data">
                    Fant ingen data for de valgte innstillingene
                  </p>
                )}
                {renderExpandedWidgetWrapper()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state: DeliberateAny) => ({
    regions: state.options.staticFilterOptions.marketingAreas,
    orderStates: state.options.staticFilterOptions.serviceOrderStates,
  }),
  { fetchStaticFilterOptions }
)(WidgetServiceOrder);
