// @flow

import _ from "lodash/fp";
import { mapObjectKeysToCamelCase } from "../helpers/commonReducerHelpers";
import type { AllocationRoundType } from "../types/housingTypes";

export const LIVING_CONDITION_OWNS_RESIDENT = 0;
export const LIVING_CONDITION_RENTS_SIVIL = 1;
export const LIVING_CONDITION_RENTS_MILITARY = 2;

export const MARITAL_STATUS_SINGLE = 0;
export const MARITAL_STATUS_MARRIED_COHABITANT_PARTNER = 1;
export const MARITAL_STATUS_SINGLE_CHILD_CARE = 3;
export const MARITAL_STATUS_PARTNER_MILITARY = 4;
export const MARITAL_STATUS_MARRIED_UNKNOWN = 5;

export const APPLICANT_SPECIAL_CATEGORY = 5;

export const STATE_ALLOCATION_ROUND_OPEN = 0;

export const getSharedQuarterStr = (accepted: boolean) =>
  accepted
    ? "Dele kvarter med en partner som også er befal, grenader eller matros"
    : "Eget kvarter";

export const allocationRoundIsApplicable = (
  allocationRound: AllocationRoundType
) =>
  !_.isNil(allocationRound) &&
  allocationRound.State === STATE_ALLOCATION_ROUND_OPEN;

export const mapApplicationToCamelCase = (application: object) => ({
  ...mapObjectKeysToCamelCase(application),
  coApplicants: !_.isNil(application.CoApplicants)
    ? application.CoApplicants.map((applicant) =>
        mapObjectKeysToCamelCase(applicant)
      )
    : null,
  children: !_.isNil(application.Children)
    ? application.Children.map((applicant) =>
        mapObjectKeysToCamelCase(applicant)
      )
    : null,
});

export const IS_MAIN_APPLICANT_LABEL =
  "Er du hovedsøker? NB! Du kan kun legge inn en søknad hvor du enten er hovedsøker eller medsøker.";
