import type { ListColumnType } from 'types/listTypes';
import type { ServiceOrderType } from 'types/serviceOrderTypes';
import { proffBaseUrl } from 'api/basenproff/basenproff_api';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import { useModal, useUser } from 'contexts/index';
import { axiosFetch } from 'hooks/useAxios';
import { DeliberateAny } from 'types/DelibrateAny';

import AdditionalServicesServiceOrderListItem from './AdditionalServicesServiceOrderListItem';
import { ModalTypes } from 'constants/modalTypes';

const serviceOrderDetails = [
  { label: 'SO-nummer', name: 'ServiceOrderNumber' },
  { label: 'Type', name: 'ServiceOrderType' },
  { label: 'Tittel', name: 'Title' },
  { label: 'Status', name: 'ServiceOrderStatePrintable' },
  { label: 'Opprettet', name: 'CreatedDate' },
  { label: 'Leveringsdato', name: 'DeliveryDate' },
  { label: 'Ansvarlig', name: 'ResponsiblePerson' },
  { label: 'Telefon', name: 'ResponsiblePhoneNumber' },
  { label: 'Etablissement', name: 'Establishment' },
  { label: 'Bygning', name: 'Building' },
  { label: 'Inventarnr', name: 'BuildingNumber' },
  { label: 'Innmelder', name: 'Reporter' },
  { label: 'Forsvarets IOnr', name: 'IONumber' },
  { label: 'Beskrivelse', name: 'Description' },
];

const itemDescriptionsWithValue = (item, details) => {
  // returnerer kolonner som det finnes verdier for i items.
  if (!item) return [];
  return details.filter((el) => item[el.name]);
};

const mapComplaints = (complaints) => {
  const mappedComplaints: DeliberateAny[] = [];
  complaints.forEach((complaint) => {
    const newComplaint = {
      data: [
        { label: 'SO-nummer', value: complaint.ServiceOrderNumber },
        { label: 'SO-TYPE', value: complaint.ServiceOrderType },
        { label: 'Opprettet', value: complaint.CreatedDate },
        { label: 'Navn', value: complaint.CreatedBy },
        { label: 'Beskrivelse', value: complaint.Description },
      ],
      title: complaint.Title,
    };
    mappedComplaints.push(newComplaint);
  });
  return mappedComplaints;
};

const mapDunnings = (dunnings) => {
  const mappedDunnings: DeliberateAny[] = [];
  dunnings.forEach((dunning) => {
    const newDunning = {
      title: 'Purring',
      data: [
        { label: 'Opprettet', value: dunning.CreatedDate },
        { label: 'Navn', value: dunning.CreatedBy },
        { label: 'Brukernavn', value: dunning.CreatedByUsername },
        { label: 'Tlf.', value: dunning.CreatedByPhone },
      ],
    };
    mappedDunnings.push(newDunning);
  });
  return mappedDunnings;
};

const mapAdditionalInfo = (additionalInfo) => {
  const mappedAdditionalInfo: DeliberateAny[] = [];
  additionalInfo.forEach((addInfo) => {
    const newAddInfo = {
      title: 'Tilleggsinformasjon',
      data: [
        { label: 'Opprettet', value: addInfo.CreatedDate },
        { label: 'Navn', value: addInfo.CreatedBy },
        { label: 'Brukernavn', value: addInfo.CreatedByUsername },
        { label: 'Tlf.', value: addInfo.CreatedByPhone },
        { label: 'Beskrivelse', value: addInfo.Description },
      ],
    };
    mappedAdditionalInfo.push(newAddInfo);
  });
  return mappedAdditionalInfo;
};

const getRelatedItems = (item) => {
  if (!item.Complaints && !item.Dunnings && !item.AdditionalInfo) {
    return [];
  }
  let relatedItems: DeliberateAny[] = [];

  if (item.AdditionalInfo.length > 0) {
    const additonalInfo = mapAdditionalInfo(item.AdditionalInfo);
    relatedItems = relatedItems.concat(additonalInfo);
  }

  if (item.Complaints.length > 0) {
    const complaints = mapComplaints(item.Complaints);
    relatedItems = relatedItems.concat(complaints);
  }
  if (item.Dunnings.length > 0) {
    const dunnings = mapDunnings(item.Dunnings);
    relatedItems = relatedItems.concat(dunnings);
  }

  return relatedItems;
};

const getDocuments = (item) => {
  if (!item.Documents || item.Documents.length < 1) {
    return [];
  }
  const documentList: DeliberateAny[] = [];
  item.Documents.forEach((doc) => {
    documentList.push({
      name: doc.Name,
      path: proffBaseUrl + doc.Path,
      code: doc.Code,
    });
  });
  return documentList;
};

const AdditionalServicesServiceOrderItem = ({
  columns,
  item,
  urlRoot,
}: {
  columns: Array<ListColumnType>;
  item: ServiceOrderType;
  urlRoot: string;
}) => {
  const { showModal } = useModal();
  const itemDescriptions = itemDescriptionsWithValue(item, serviceOrderDetails);
  const relatedItems = getRelatedItems(item);
  const documentList = getDocuments(item);
  const { user } = useUser();

  const handleOpenModal = () => {
    showModal({
      modalType: ModalTypes.GetModal,
      title: 'Tilleggsinformasjon',
      text: '',
      yesText: 'Bekreft',
      noText: 'Avbryt',
      data: {
        SO: item.ServiceOrderNumber,
        reporter: user.Name,
        serviceUrl: `${urlRoot}/RegisterAdditionalInfo`,
      },
      isBasenProff: urlRoot === '/basenproff',
      onYes: (success) => {
        showModal({
          title: success ? 'Sendt' : 'Oops',
          text: success
            ? 'Opplysningene har blitt sendt inn.'
            : 'Noe gikk galt. Prøv igjen senere.',
        });
      },
    });
  };

  return (
    <AdditionalServicesServiceOrderListItem
      columns={columns}
      item={item}
      minimalDetails
      details={itemDescriptions}
      related={relatedItems}
      xpandDescription={item.XpandDescription}
      documentList={documentList}
      extendedDetailsContainer={
        <ButtonRow>
          <ButtonRow.Left onClick={() => handleOpenModal()}>
            Legg til opplysninger
          </ButtonRow.Left>
          <ButtonRow.Left
            onClick={() =>
              showModal({
                modalType: 'yes-no',
                title: 'Send purring',
                text: 'Vennligst bekreft at du vil sende en purring på denne henvendelsen.',
                onYes: () =>
                  axiosFetch({
                    url: `${urlRoot}/RegisterDunning`,
                    data: { ServiceOrderNumber: item.ServiceOrderNumber },
                    method: 'POST',
                  }).then((res) =>
                    showModal({
                      modalType: 'default',
                      title:
                        res === 'Purring opprettet i Xpand'
                          ? 'Takk'
                          : 'Beklager',
                      text:
                        res === 'Purring opprettet i Xpand'
                          ? 'Din purring har blitt registrert.'
                          : 'Det skjedde en feil',
                    }),
                  ),
              })
            }
          >
            Purring
          </ButtonRow.Left>
          <ButtonRow.Left
            onClick={() => {
              if (item.Complaints.length >= 3) {
                showModal({
                  title: 'Kan ikke registrere klage',
                  html:
                    'Det kan dessverre ikke registreres mer enn tre klager på en henvendelse. ' +
                    'Dersom du fortsatt ikke er fornøyd med behandlingen av denne saken, ' +
                    'vennligst kontakt ' +
                    "<a data-action='dismiss' href='#/contact'>Forsvarsbygg servicesenter</a>.<br><br>" +
                    'Vi beklager at vår behandling av saken ikke har vært i tråd med dine forventninger.',
                });
                return;
              }

              showModal({
                modalType: 'basenproff-complaint',
                serviceOrderNumber: item.ServiceOrderNumber,
                serviceOrderTitle: item.Title,
                isBasenProff: urlRoot === '/basenproff',
                user,
              });
            }}
          >
            Klage/avvik
          </ButtonRow.Left>
        </ButtonRow>
      }
    />
  );
};

export default AdditionalServicesServiceOrderItem;
