import {
  arenaAvailabilityUrl,
  arenaAvailableRoomsUrl,
  arenaByIdUrl,
  arenaUrl,
  bookingByIdUrl,
  bookingOptionsUrl,
  reservationByIdUrl,
} from 'api/booking/booking_api_v2';
import { calculateSplitValues } from 'helpers/splitValues';
import useAxios from 'hooks/useAxios';
import moment from 'moment';
import {
  createContext,
  FC,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useParams } from 'react-router-dom';
import { SimpleArenaInfo } from 'types/BookingTypes/arenaTypes';
import {
  AvailableRoomType,
  RoomFormItemType,
} from 'types/BookingTypes/roomTypes';
import { DeliberateAny, SetState } from 'types/DelibrateAny';

import { IWrapper } from '../interfaces/IWrapper';
import { useUser } from './';

// TODO: NewBookingContextProvider rename after Booking complete

type PriceResponseType = {
  name: string;
  persons: number;
  priceId: number;
  priceValue: number;
  validFrom: Date;
  validTo: Date;
};

interface AvailablePaymentOption {
  heading: string;
  identity: string;
}

interface IoDepartment {
  name: string;
  number: number;
}

interface OrderForOthers {
  heading: string;
  text: string;
}

interface ShieldedUnit {
  heading: string;
  text: string;
}

export interface Arena {
  locationId: string;
  name: string;
  openForBooking: boolean;
  coordinates: string;
}

export interface BookingOptions {
  purposeOptions: string[];
  bookingPaymentRegistrationTimeout: number;
  availablePaymentOptions: AvailablePaymentOption[];
  termsAndConditions: string;
  ioDepartments: IoDepartment[];
  orderForOthers: OrderForOthers;
  shieldedUnit: ShieldedUnit;
}

export interface IBookingSplitValue {
  nights: number;
  rooms: number;
  price: number;
}

interface IBookingSearch {
  orderType?: string;
  guestCount: number;
  location?: string;
  arriveDeparture?: DateObject[];
}

interface IGuest {
  fullName: string;
  mobile: string;
  additionalInfo: string;
  isUnnamed: boolean;
}

interface IRoom {
  roomTypeId: string;
  index: number;
  heading: string;
  guests: IGuest[];
}

export interface IPaymentInfo {
  purposeOption: string;
  purposeDescription: string;
  paymentOption: string;
  smsOption: string;
}

export interface IGuestList {
  guestListPostponed: boolean;
  unnamedGuests: boolean;
  civilGuests: boolean;
  rooms: IRoom[];
}

export interface BookingContextState {
  bookingid?: string;
  bookingOptions?: BookingOptions;
  arenas?: Arena[];
  bookingLoading: string[];
  bookingError: DeliberateAny;
  splitValues?: IBookingSplitValue;
  bookingSearch: IBookingSearch;
  setBookingSearch: SetState<IBookingSearch>;
  errors: DeliberateAny;
  setErrors: SetState<DeliberateAny>;
  guestList?: IGuestList;
  setGuestList: SetState<IGuestList | undefined>;
  location?: SimpleArenaInfo;
  roomInfo?: AvailableRoomType[];
  quoteId?: string;
  setQuoteId: SetState<string>;
  reservationId?: string;
  setReservationId: SetState<string>;
  roomCounts: { [key: string]: number };
  setRoomCounts: SetState<{ [key: string]: number }>;
  rooms?: RoomFormItemType[];
  setRooms: SetState<RoomFormItemType[] | undefined>;
  paymentInfo: IPaymentInfo;
  setPaymentInfo: SetState<IPaymentInfo>;
  availableLoading: boolean;
  deleteReservation: (rId: string) => Promise<void>;
  unavailableDates: Date[];
}

export const BookingContext = createContext({} as BookingContextState);

export const getPrice = (
  priceList: PriceResponseType[],
  standardRoomCapacity: number,
  dateCheckFrom: Date,
  dateCheckTo: Date,
) => {
  const priceItems = priceList.filter((priceItem) => {
    const dateFrom = new Date(priceItem.validFrom);
    const dateTo = new Date(priceItem.validTo);

    if (dateCheckFrom && dateCheckTo) {
      if (dateCheckFrom > dateFrom && dateCheckTo < dateTo) {
        return priceItem.priceValue;
      }
    }
  });
  return priceItems.find((room) => room.persons === standardRoomCapacity)
    ?.priceValue;
};

export const NewBookingContextProvider: FC<IWrapper> = ({ children }) => {
  const { user } = useUser();
  const { bookingid } = useParams();
  const { sendRequest } = useAxios();
  const { sendRequest: sendLocationRequest, requestLoading: locationLoading } =
    useAxios();
  const { sendRequest: sendBoRequest, requestLoading: boLoading } = useAxios();
  const { sendRequest: sendArenasRequest, requestLoading: arenasLoading } =
    useAxios();
  const { sendRequest: sendAvailableRooms, requestLoading: availableLoading } =
    useAxios();

  const [bookingOptions, setBookingOptions] = useState<BookingOptions>();
  const [arenas, setArenas] = useState<Arena[]>();
  const [bookingLoading, setBookingLoading] = useState<string[]>([]);
  const [bookingError, setBookingError] = useState<DeliberateAny>({});
  const [splitValues, setSplitValues] = useState<IBookingSplitValue>();
  const [bookingSearch, setBookingSearch] = useState<IBookingSearch>({
    orderType: '0',
    guestCount: 0,
  });
  const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo>({
    purposeOption: '',
    purposeDescription: '',
    paymentOption: '',
    smsOption: '',
  });
  const [guestList, setGuestList] = useState<IGuestList>();
  const [location, setLocation] = useState<SimpleArenaInfo>({
    locationId: '',
    heading: '',
    description: '',
  });
  const [roomInfo, setRoomInfo] = useState<AvailableRoomType[]>([]);
  const [roomCounts, setRoomCounts] = useState<{ [key: string]: number }>({});
  const [rooms, setRooms] = useState<RoomFormItemType[]>();

  const [quoteId, setQuoteId] = useState('');
  const [reservationId, setReservationId] = useState('');
  const [errors, setErrors] = useState<DeliberateAny>({});
  const [unavailableDates, setUnavailableDates] = useState<Date[]>([]);

  const fetchAvailableDates = async (locationId: string) => {
    await sendRequest(
      {
        method: 'GET',
        url: arenaAvailabilityUrl(locationId),
      },
      (r) =>
        setUnavailableDates([
          ...r.unavailableDates.map((date) => new Date(date)),
        ]),
    );
  };

  useEffect(() => {
    if (location) {
      if (location.locationId && location.locationId !== '') {
        fetchAvailableDates(location.locationId);
      }
    }
  }, [location]);

  const fetchBookingOptions = async () => {
    await sendBoRequest(
      {
        method: 'GET',
        url: bookingOptionsUrl,
      },
      (x) => setBookingOptions(x),
      user,
      ({ response }) => {
        setBookingError((x) => {
          if (!response?.data?.detail) return x;
          return {
            ...x,
            bo: `${response.data.detail} (${response.data.errorCode})`,
          };
        });
      },
    );
  };

  const fetchArenas = async () => {
    await sendArenasRequest(
      {
        method: 'GET',
        url: arenaUrl,
      },
      (r) => setArenas(r.arenas),
      user,
      ({ response }) => {
        setBookingError((x) => {
          if (!response?.data?.detail) return x;
          return {
            ...x,
            arenas: `${response.data.detail} (${response.data.errorCode})`,
          };
        });
      },
    );
  };

  const fetchArenaInfo = async () => {
    const locationId = bookingSearch.location;
    if (locationId) {
      await sendLocationRequest(
        { method: 'GET', url: arenaByIdUrl(locationId) },
        (response) => {
          const mappedResponse = {
            locationId: locationId,
            heading: response.heading,
            description: response.description,
            eLockActive: response.eLockActive,
            checkInToTime: response.checkInToTime,
          } as SimpleArenaInfo;
          setLocation(mappedResponse);
        },
      );
    }
  };

  const fetchAvailableRoomTypes = async () => {
    if (bookingSearch.location) {
      const arrDate = bookingSearch.arriveDeparture?.[0].toDate();
      const depDate = bookingSearch.arriveDeparture?.[1].toDate();

      await sendAvailableRooms(
        {
          method: 'GET',
          url: arenaAvailableRoomsUrl(bookingSearch.location),
          params: {
            arrivalDate: arrDate ? moment(arrDate).format('YYYY/MM/DD') : '',
            departureDate: depDate ? moment(depDate).format('YYYY/MM/DD') : '',
            numberOfGuests: bookingSearch.guestCount,
          },
        },
        (response) => {
          const mappedResponse: AvailableRoomType[] =
            response.availableRooms.map((room) => {
              const roomPrice = getPrice(
                room.priceList,
                room.standardCapacity,
                bookingSearch.arriveDeparture?.[0].toDate() ?? new Date(),
                bookingSearch.arriveDeparture?.[1].toDate() ?? new Date(),
              );
              const newObject = {
                roomType: room.roomType,
                availableCount: room.availableCount,
                defaultSelectedCount: room.defaultSelectedCount,
                priceList: room.priceList,
              } as AvailableRoomType;

              newObject.roomType.price = roomPrice
                ? roomPrice
                : room.priceList[0]?.priceValue;
              return newObject;
            });
          setRoomInfo(
            mappedResponse.sort(
              (item1, item2) =>
                item1.roomType.standardCapacity -
                item2.roomType.standardCapacity,
            ),
          );
          setQuoteId(response.quoteId);
        },
      );
    }
  };

  const deleteReservation = async (rId: string) => {
    if (!rId) return;

    await sendRequest(
      {
        method: 'DELETE',
        url: reservationByIdUrl(rId),
      },
      () => setReservationId(''),
    );
  };

  useEffect(() => {
    const loading: string[] = [];
    if (boLoading) loading.push('boLoading');
    if (arenasLoading) loading.push('arenasLoading');
    setBookingLoading(loading);
  }, [boLoading, arenasLoading]);

  useEffect(() => {
    fetchBookingOptions();
    fetchArenas();
  }, []);

  useEffect(() => {
    fetchArenaInfo();
  }, [bookingSearch.location]);

  useEffect(() => {
    if (
      bookingSearch?.location &&
      bookingSearch.guestCount > 0 &&
      bookingSearch.arriveDeparture?.length === 2
    )
      fetchAvailableRoomTypes();
  }, [bookingSearch]);

  // SplitValues
  useEffect(() => {
    const updatedValues = calculateSplitValues(
      bookingSearch.arriveDeparture,
      roomCounts,
      roomInfo,
    );
    setSplitValues(updatedValues);
  }, [bookingSearch, roomInfo, roomCounts]);

  const values = useMemo(
    () => ({
      bookingOptions,
      arenas,
      bookingLoading,
      bookingError,
      splitValues,
      errors,
      setErrors,
      bookingSearch,
      setBookingSearch,
      guestList,
      setGuestList,
      location,
      roomInfo,
      paymentInfo,
      setPaymentInfo,
      quoteId,
      setQuoteId,
      reservationId,
      setReservationId,
      roomCounts,
      setRoomCounts,
      rooms,
      setRooms,
      bookingid,
      availableLoading,
      deleteReservation,
      unavailableDates,
    }),
    [
      bookingOptions,
      arenas,
      bookingLoading,
      bookingError,
      splitValues,
      errors,
      bookingSearch,
      guestList,
      location,
      roomInfo,
      paymentInfo,
      quoteId,
      roomCounts,
      rooms,
      bookingid,
      availableLoading,
      reservationId,
      unavailableDates,
    ],
  );

  return (
    <BookingContext.Provider value={values}>
      <Fragment>{children}</Fragment>
    </BookingContext.Provider>
  );
};
