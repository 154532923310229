import { getArrivalListLocationURL, getArrivalListURL } from 'api/lock/lock_api';
import Loader from 'components/Loader/Loader';
import Spinner from 'components/Spinner/Spinner';
import useAxios from 'hooks/useAxios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { KeyValueType } from 'types/commonTypes';
import { DeliberateAny } from 'types/DelibrateAny';

import doubleRoomIcon from '../../assets/img/dobbeltrom.svg';
import singleRoomIcon from '../../assets/img/enkeltrom.svg';
import ArrivalList from './ArrivalList/ArrivalList';
import ArrivalListHeader from './ArrivalList/ArrivalListHeader';

export type ArrivalType = {
  guestName: string;
  guestPhone: string;
  checkinStatus: string;
  endDate: string;
  buildingId: string;
  buildingIdRoomName: string;
  accommodationType: React.ReactElement; //used to indicate icon
};

export type FilterSet = {
  selectedCheckInStatus: KeyValueType[];
  selectedBuildings: KeyValueType[];
};

const TabArrivalListPage = () => {
  const { sendRequest } = useAxios();
  const [location, setLocation] = useState('');
  const [arrivals, setArrivals] = useState<ArrivalType[]>([]);
  const [filteredArrivals, setFilteredArrivals] = useState<ArrivalType[]>([]);

  const [selectedDate, setSelectedDate] = useState<Date>(moment().toDate());
  const [selectedFilters, setSelectedFilters] = useState<FilterSet>({
    selectedBuildings: [],
    selectedCheckInStatus: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(moment().toDate());

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    setFilteredArrivals(arrivals);
  }, [arrivals]);

  useEffect(() => {
    updateArrivalList();
  }, [selectedFilters]);

  useEffect(() => {
    fetchArrivals();
  }, [selectedDate]);

  const getIcon = (itemValue: number) => {
    const housingIcon = {
      src: '/globalassets/ikoner/AM_bolig-black.svg',
      alt: 'Langtidsovernatting',
    };

    const bookingIcon = {
      src: '/globalassets/ikoner/AM_overnatting-black.svg',
      alt: 'Korttidsovernatting',
    };

    switch (itemValue) {
      case 0:
        return (
          <img
            src={bookingIcon.src}
            alt={bookingIcon.alt}
            style={{ width: '25px', display: 'inline-block' }}
          />
        );
      case 1:
        return (
          <img
            src={housingIcon.src}
            alt={housingIcon.alt}
            style={{ width: '25px', display: 'inline-block' }}
          />
        );
      default:
        return <span>Noe gikk galt</span>;
    }
  };

  const fetchArrivals = async () => {
    setIsLoading(true);
    const shortDate = moment(selectedDate).format('YYYY-MM-DD');
    await sendRequest(
      {
        method: 'GET',
        url: getArrivalListURL,
        params: { arrivalDate: shortDate },
      },
      (response) => {
        const formattedResponse = response.map((item) => {
          let accommodationIcon = '';
          switch (item.AccommodationType) {
            case 0:
              accommodationIcon = singleRoomIcon;
              break;
            case 1:
              accommodationIcon = doubleRoomIcon;
              break;
          }
          return {
            guestName: item.GuestName,
            guestPhone: item.GuestPhone,
            checkinStatus: item.CheckInStatus,
            endDate: item.EndDate,
            buildingId: item.BuildingId,
            buildingIdRoomName: `${item.BuildingId && item.RoomName ? `${item.BuildingId}_${item.RoomName}` : ''}`,
            accommodationType: getIcon(item.AccommodationType),
          } as ArrivalType;
        });
        setArrivals(formattedResponse);
      },
    );
    setLastUpdated(moment().toDate());
    setIsLoading(false);
  };

  const fetchLocation = async () => {
    await sendRequest(
      { method: 'GET', url: getArrivalListLocationURL },
      (response) => {
        setLocation(response.ArenaName);
      },
    );
  };

  const updateArrivalList = () => {
    const filteredList = arrivals
      .filter((arrival) => {
        if (
          selectedFilters.selectedCheckInStatus &&
          selectedFilters.selectedCheckInStatus.length != 0
        ) {
          const keyList = selectedFilters.selectedCheckInStatus.map(
            (item) => item.key,
          );
          const valueList = selectedFilters.selectedCheckInStatus.map(
            (item) => item.value,
          );
          return (
            keyList.includes('alle') ||
            valueList.includes(arrival.checkinStatus)
          );
        }
        return true;
      })
      .filter((arrival) => {
        if (
          selectedFilters.selectedBuildings &&
          selectedFilters.selectedBuildings.length != 0
        ) {
          const keyList = selectedFilters.selectedBuildings.map(
            (item) => item.key,
          );
          const valueList = selectedFilters.selectedBuildings.map(
            (item) => item.value,
          );

          if (arrival.buildingId == '') {
            return keyList.includes('alle') || keyList.includes('unset');
          }
          return (
            keyList.includes('alle') || valueList.includes(arrival.buildingId)
          );
        }
        return true;
      });
    setFilteredArrivals(filteredList);
  };

  const formatDateTime = (date) => {
    return `${new Date(date).toLocaleDateString('no', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })}`;
  };

  const handleSetSelectedFilters = (filters) => {
    setSelectedFilters(filters);
  };

  const handleSetSelectedDate = (date: Date) => {
    setSelectedDate(date);
  };

  const triggerListUpdate = () => {
    fetchArrivals();
    updateArrivalList();
  };

  // Print arrivalslist
  function printElement(e) {
    const filterList = document.createElement('div');
    document.body.appendChild(filterList);
    filterList.innerHTML = printableFilterValues();
    filterList.classList.add('printable');

    const cloned = e.cloneNode(true);
    document.body.appendChild(cloned);
    cloned.classList.add('printable');

    window.print();
    document.body.removeChild(cloned);
    document.body.removeChild(filterList);
  }

  const printableFilterValues = () => {
    const selectedCheckInStatus = selectedFilters.selectedCheckInStatus?.map(
      (item) => {
        return `<span>${item.value}</span>`;
      },
    );
    const selectedBuildings = selectedFilters.selectedBuildings?.map((item) => {
      return `<span>${item.value}</span>`;
    });

    const formattedTime = new Date(lastUpdated).toLocaleTimeString('no', {
      hour: '2-digit',
      minute: '2-digit',
    });
    const isFiltered = filteredArrivals.length != arrivals.length;
    return `
      <div>
        <h2>Ankomstliste ${location}</h2>
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <div>
                <div><b>Dato:</b> ${formatDateTime(selectedDate)}</div>
                <div><b>Sist oppdatert:</b> ${formatDateTime(lastUpdated)} - ${formattedTime}</div>
                <div><b>Totalt antall gjester:</b> ${arrivals.length}</div>
                ${isFiltered ? `<div><b>Filtrert antall gjester:</b> ${filteredArrivals.length}</div>` : ''}
                  
            </div>
            <div style="display:flex; flex-direction: column; justify-content: space-between;">
                <div>
                ${isFiltered ? `<h2 style='color: red; text-align: end;'>FILTRERT</h2>` : ''}
                </div>
                <div style="width: 15em;">
                  <div style="display: flex; justify-content: space-between;"><b>Bygg:</b> ${selectedBuildings}</div>
                </div>
            </div>
        </div>
      </div>
      `;
  };

  const triggerPrint = () => {
    const container = document.getElementById('arrival-list-container');
    printElement(container);
  };

  const returnItem = () => (
    <div className="relative relative-print">
      <h2>
        {`Ankomstliste`} {location ?? <Spinner />}
      </h2>
      {selectedFilters.selectedBuildings?.length !== 0 &&
      !selectedFilters.selectedBuildings?.find((x) => x.key === 'alle') ? (
        <div
          className="absolute absolute-print"
          style={{
            fontSize: 24,
            fontWeight: 600,
            color: '#c1002b',
            right: 0,
            top: 0,
          }}
        >
          FILTRERT
        </div>
      ) : (
        ''
      )}
      <p>
        Her ser du en liste over alle gjester som skal eller har ankommet
        lokasjonen på angitt dato
      </p>
      <ArrivalListHeader
        setSelectedFilters={handleSetSelectedFilters}
        selectedDate={selectedDate}
        setSelectedDate={handleSetSelectedDate}
        triggerListUpdate={triggerListUpdate}
        lastUpdated={lastUpdated}
        arrivals={{
          totalArrivals: arrivals,
          filteredArrivals: filteredArrivals,
        }}
        printArrivalsList={triggerPrint}
      />
      <Loader isLoading={isLoading}>
        <ArrivalList arrivals={filteredArrivals} />
      </Loader>
    </div>
  );
  return returnItem();
};

export default TabArrivalListPage;
