import {
  arenaUrl,
  bookingHistoryUrl,
  bookingUpcomingUrl,
} from 'api/booking/booking_api_v2';
import Badge from 'components/Badge/Badge';
import Loader from 'components/Loader/Loader';
import Table from 'components/Table/Table';
import { Arena } from 'contexts/BookingContextNew';
import { useApplication, useUser } from 'contexts/index';
import { formatDateRange } from 'helpers/date';
import useAxios from 'hooks/useAxios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TabContainer from 'src/layout/TabContainer/TabContainer';
import {
  BookingInfo,
  GetHistoricBookingsResponse,
  GetUpcomingBookingsResponse,
} from 'types/bookingRequests';

const itemsPerPage = 10;

const MyBookings = () => {
  const { user } = useUser();
  const { sendRequest, requestLoading } = useAxios();
  const { setHeading } = useApplication();
  const navigate = useNavigate();
  const [arenas, setArenas] = useState<Arena[]>([]);
  const [itemsUpcoming, setItemsUpcoming] = useState<BookingInfo[]>([]);
  const [itemsHistory, setItemsHistory] = useState<BookingInfo[]>([]);
  const [totalUpcoming, setTotalUpcoming] = useState<number>(0);
  const [totalHistory, setTotalHistory] = useState<number>(0);
  const [loadingUpcoming, setLoadingUpcoming] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [pageUpcoming, setPageUpcoming] = useState<number>(1);
  const [pageHistory, setPageHistory] = useState<number>(1);
  const [activeTab, setActiveTab] = useState<string>('next');

  const fetchArenas = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: arenaUrl,
      },
      (response) => setArenas(response.arenas),
      user,
    );
  };

  const fetchUpcoming = async (page: number): Promise<void> => {
    setLoadingUpcoming(true);
    await sendRequest(
      {
        method: 'GET',
        url: bookingUpcomingUrl(page, itemsPerPage),
      },
      (response: GetUpcomingBookingsResponse) => {
        setItemsUpcoming(response.bookings || []);
        setTotalUpcoming(response.totalCount || 0);
        setLoadingUpcoming(false);
      },
      user,
    );
  };

  const fetchHistory = async (page: number): Promise<void> => {
    setLoadingHistory(true);
    await sendRequest(
      {
        method: 'GET',
        url: bookingHistoryUrl(page, itemsPerPage),
      },
      (response: GetHistoricBookingsResponse) => {
        setItemsHistory(response.bookings || []);
        setTotalHistory(response.totalCount || 0);
        setLoadingHistory(false);
      },
      user,
    );
  };
  useEffect(() => {
    fetchArenas();
    if (activeTab === 'next') fetchUpcoming(pageUpcoming);
    if (activeTab === 'previous') fetchHistory(pageHistory);
  }, []);

  useEffect(() => {
    if (activeTab === 'next') fetchUpcoming(pageUpcoming);
  }, [pageUpcoming, activeTab]);

  useEffect(() => {
    if (activeTab === 'previous') fetchHistory(pageHistory);
  }, [pageHistory, activeTab]);

  useEffect(() => {
    setHeading('Mine overnattinger');
  }, []);

  const columns = useMemo(
    () => [
      {
        key: 'location',
        label: 'Sted',
        conditionalRendering: (item: BookingInfo) =>
          arenas.find((location) => location.locationId === item.locationId)
            ?.name ?? 'Ukjent sted',
      },
      {
        key: 'date',
        label: 'Dato',
        conditionalRendering: (item: BookingInfo) => (
          <>
            {`${formatDateRange(
              new Date(item.arrivalDate),
              new Date(item.departureDate),
            )}`}
          </>
        ),
      },
      {
        key: 'room',
        label: 'Rom',
        conditionalRendering: (item: BookingInfo) => {
          return <>{`${item.numberOfRooms} rom`}</>;
        },
      },
      {
        key: 'purpose',
        label: 'Formål',
      },
      {
        key: 'price',
        label: 'Pris',
        conditionalRendering: (item: BookingInfo) => (
          <>{`kr ${Math.round(item.price)},-`}</>
        ),
      },
      {
        key: 'status',
        label: 'Status',
        conditionalRendering: (item: BookingInfo) => (
          <Badge variant={item.status.status}>{item.status.text ?? ''}</Badge>
        ),
      },
    ],
    [arenas],
  );

  const handleItemClick = useCallback(
    (item: BookingInfo) => {
      navigate(`${item?.id}`);
    },
    [navigate],
  );

  return (
    <>
      <TabContainer
        tabs={[
          { id: 'next', label: 'Kommende' },
          { id: 'previous', label: 'Tidligere' },
        ]}
        content={[
          {
            id: 'next',
            content: <></>,
          },
          {
            id: 'previous',
            content: <></>,
          },
        ]}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />
      <div className="fb-tab-container">
        {activeTab === 'next' ? (
          <>
            <Loader isLoading={loadingUpcoming} noBackground />
            <Table
              columns={columns}
              items={itemsUpcoming}
              pagination={{
                type: 'server',
                itemsPerPage,
                fetchItems: fetchUpcoming,
              }}
              totalItems={totalUpcoming}
              currentPage={pageUpcoming}
              onPageChange={setPageUpcoming}
              onItemClick={handleItemClick}
            />
          </>
        ) : (
          <>
            <Loader isLoading={loadingHistory} noBackground />
            <Table
              columns={columns}
              items={itemsHistory}
              pagination={{
                type: 'server',
                itemsPerPage,
                fetchItems: fetchHistory,
              }}
              totalItems={totalHistory}
              currentPage={pageHistory}
              onPageChange={setPageHistory}
              onItemClick={handleItemClick}
            />
          </>
        )}
      </div>
    </>
  );
};

export default MyBookings;
