import {
  getArenaInfoWithReferenceCodeURL,
  patchReleaseRoomURL,
} from 'api/booking/booking_api';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import './PrintUnnamedGuestList.scss';
import Button from 'components/buttons/Button/Button';
import Loader from 'components/Loader/Loader';
import { useModal } from 'contexts/index';
import { ModalTypes } from 'constants/modalTypes';
import Spinner from 'components/Spinner/Spinner';

type RoomInfo = {
  Id: string;
  RoomtypeDescription: string;
  RoomName: string;
  CheckedInAt?: Date;
  CanBeReleased: boolean;
};

const PrintUnnamedGuestList = ({ checkInReferenceCode }) => {
  const getRoomInfoRequest = useAxios();
  const releaseRoomRequest = useAxios();
  const { showModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [roomInfoList, setRoomInfoList] = useState<RoomInfo[]>([]);

  useEffect(() => {
    fetchRoomInfo();
  }, [checkInReferenceCode]);

  const fetchRoomInfo = async () => {
    setIsLoading(true);

    await getRoomInfoRequest.sendRequest(
      {
        method: 'GET',
        url: getArenaInfoWithReferenceCodeURL,
        params: { checkInReferenceCode: checkInReferenceCode },
      },
      (response) => {
        setRoomInfoList(
          response.map((item) => {
            return {
              Id: item.id,
              RoomtypeDescription: item.roomTypeDescription,
              RoomName: item.roomName,
              CheckedInAt: item.checkedInAt,
              CanBeReleased: item.canBeReleased,
            } as RoomInfo;
          }),
        );
      },
    );

    setIsLoading(false);
  };

  const releaseRoom = async (roomId, roomName) => {
    await releaseRoomRequest.sendRequest(
      {
        method: 'PATCH',
        url: patchReleaseRoomURL,
        params: {
          checkInReferenceCode: checkInReferenceCode,
          roomId: roomId,
        },
      },
      (response) => {
        showModal({
          title: 'Rom frigitt',
          infoText: `Rom ${roomName} er nå frigitt og tilgjengelig i innsjekksløsningen`,
          onClose: () => {
            handleRoomReleased();
          },
        });
      },
      null,
      (error) => {
        if (error?.response?.data) {
          showModal({
            title: error.response.data.title,
            infoText: error.response.data.detail,
          });
        }
      },
    );
  };

  const handleRoomReleased = async () => {
    fetchRoomInfo();
  };

  const releaseRoomModal = (room: RoomInfo) => {
    showModal({
      modalType: ModalTypes.CustomForm,
      title: 'Frigi rom',
      infoText: `Ønsker du å frigi rom ${room.RoomName} - ${room.RoomtypeDescription}?`,
      submitAction: () => {
        releaseRoom(room.Id, room.RoomName);
      },
      submitText: 'Ja',
      cancelText: 'Nei',
    });
  };

  const formatDateTime = (date: Date) => {
    return `${new Date(date).toLocaleDateString('no', {
      month: 'long',
      day: '2-digit',
    })} kl. ${new Date(date).toLocaleTimeString('no', {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  };

  const printGuestList = () => (
    <Loader isLoading={isLoading}>
      <div className="print-guest-list">
        <ul>
          {roomInfoList.map((room) => (
            <li key={uuidv4()} className="guest-list-room">
              <p className="room-index red no-margin">{room.RoomName}</p>
              <h5 className="half-margin bold">{room.RoomtypeDescription}</h5>
              <div className="flex-row roomInfo">
                <div>
                  {room.CheckedInAt
                    ? `Innsjekk: ${formatDateTime(room.CheckedInAt)}`
                    : `Ledig`}
                </div>
                {room.CheckedInAt && (
                  <div className="release-button">
                    {room.CanBeReleased && (
                      <div>
                        <Button
                          onClick={() => releaseRoomModal(room)}
                          style={{
                            display: 'flex',
                            height: 44,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {releaseRoomRequest.requestLoading ? (
                            <Spinner white />
                          ) : (
                            `Frigi rom`
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Loader>
  );

  return <div>{printGuestList()}</div>;
};

export default PrintUnnamedGuestList;
