export function mimeTypesToDict(mimeString: string): {
  [key: string]: string[];
} {
  // Split the input string by commas to get individual MIME types
  const mimeTypes = mimeString?.split(",") ?? [];

  // Create a dictionary to hold the MIME types and their extensions
  const mimeDict: { [key: string]: string[] } = {};

  // Define a dictionary that maps MIME types to their common extensions
  const extensionMap: { [key: string]: string[] } = {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/gif": [".gif"],
    "image/png": [".png"],
    "image/bmp": [".bmp"],
    "text/html": [".html", ".htm"],
    "text/plain": [".txt"],
    "text/css": [".css"],
    "text/javascript": [".js"],
    "application/json": [".json"],
    "application/xml": [".xml"],
    "application/pdf": [".pdf"],
    "application/zip": [".zip"],
    "application/x-www-form-urlencoded": [".urlencoded"],
    "application/vnd.ms-excel": [".xls", ".xlsx"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
    "audio/mpeg": [".mp3"],
    "audio/wav": [".wav"],
    "video/mp4": [".mp4"],
    "video/x-msvideo": [".avi"],
    "video/mpeg": [".mpeg"],
    "video/quicktime": [".mov"],
    "image/svg+xml": [".svg"],
    "application/x-tar": [".tar"],
    "application/gzip": [".gz"],
    "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
    "application/msword": [".doc", ".docx"],
  };

  // Populate the mimeDict based on the extensionMap
  for (const mimeType of mimeTypes) {
    if (extensionMap[mimeType]) {
      mimeDict[mimeType] = extensionMap[mimeType];
    }
  }

  return mimeDict;
}
