import { FC } from "react";

import { DeliberateAny } from "../../types/DelibrateAny";
import Select from "../form/Select/Select";

interface IRentalsContractsSelect {
  contracts: DeliberateAny[];
  rentalId: number;
  setRentalId: (arg: number) => void;
  loading: boolean;
  errors?: DeliberateAny;
  showOther?: boolean;
}

// Nedtrekksliste med leieforhold
const RentalsContractsSelect: FC<IRentalsContractsSelect> = ({
  contracts,
  rentalId,
  setRentalId,
  loading,
  showOther,
  errors,
}: DeliberateAny) => {
  const list = contracts.map((item: DeliberateAny) => ({
    key: item.RentalId,
    value: item.BuildingName,
  }));

  if (list.length <= 1 && !loading && rentalId === -1) return null;

  return (
    <div className="row margin" id="error_rentalId">
      <Select
        onChange={(_formname, value) => setRentalId(value)}
        options={list}
        loading={loading && !showOther}
        disabled={contracts.isLoading}
        errors={errors}
        value={rentalId}
        name="rentalId"
        label="Leieforhold"
        defaultChoice="Velg leieforhold"
      />
    </div>
  );
};

export default RentalsContractsSelect;
