import './BasenIcon.scss';

import React, { Dispatch, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Tooltip } from 'react-tooltip';

interface BasenIconProps {
  icon: string;
  hoverIcon?: string;
  alt?: string;
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | number;
  tooltipId?: string;
  tooltip?: ReactNode;
  isHover?: boolean;
  notification?: boolean;
  className?: string;
  setMouseOver?: Dispatch<boolean>;
  onClick?: () => void;
  disabled?: boolean;
}

function getSizeInPx(
  size: 'xs' | 's' | 'm' | 'l' | 'xl' | number | undefined,
): string {
  switch (size) {
    case 'xs':
      return '18px';
    case 's':
      return '24px';
    case 'm':
      return '32px';
    case 'l':
      return '48px';
    case 'xl':
      return '56px';
    default:
      return size ? `${size}px` : '32px';
  }
}

const BasenIcon: React.FC<BasenIconProps> = ({
  icon,
  hoverIcon,
  alt,
  size,
  tooltipId,
  tooltip,
  isHover = false,
  notification = false,
  className = '',
  setMouseOver,
  onClick,
  disabled = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const iconSrc = useMemo(
    () => ((isHovered || isHover) && hoverIcon ? hoverIcon : icon),
    [isHovered, isHover, hoverIcon, icon],
  );

  const iconClasses = useMemo(
    () =>
      `fb-icon ${className} ${notification ? 'notification' : ''} ${disabled ? 'disabled' : ''}`,
    [className, notification, disabled],
  );

  const handleMouseEnter = useCallback(() => {
    if (!disabled) {
      if (hoverIcon) setIsHovered(true);
      if (setMouseOver) setMouseOver(true);
    }
  }, [disabled, hoverIcon, setMouseOver]);

  const handleMouseLeave = useCallback(() => {
    if (!disabled) {
      setIsHovered(false);
      if (setMouseOver) setMouseOver(false);
    }
  }, [disabled, setMouseOver]);

  const handleClick = useCallback(() => {
    if (!disabled && onClick) onClick();
  }, [disabled, onClick]);

  const renderIcon = (
    <ReactSVG
      className={iconClasses}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      src={iconSrc}
      onClick={handleClick}
      beforeInjection={(svg) => {
        const sizeInPx = getSizeInPx(size);
        svg.setAttribute('width', sizeInPx);
        svg.setAttribute('height', sizeInPx);
        if (alt) svg.setAttribute('aria-label', alt);
      }}
    />
  );

  if (!tooltip && !tooltipId) {
    return (
      <>
        {renderIcon}
        {hoverIcon && (
          <ReactSVG src={hoverIcon} className="hidden-icon-preload" />
        )}
      </>
    );
  }

  return (
    <div
      data-tooltip-id={tooltipId}
      data-tooltip-place="right-end"
      className={disabled ? 'disabled' : ''}
    >
      {renderIcon}
      {tooltip && tooltipId && <Tooltip id={tooltipId}>{tooltip}</Tooltip>}
      {hoverIcon && (
        <ReactSVG src={hoverIcon} className="hidden-icon-preload" />
      )}
    </div>
  );
};

export default BasenIcon;
