// @flow

import './OverviewContainer.scss';

import Button from 'components/buttons/Button/Button';
import LabelValueList from 'components/LabelValueList/LabelValueList';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import DetailsButton from '../DetailsButton/DetailsButton';

import type {
  ActionButtonType,
  DetailsButtonType,
  ListColumnWithValueType,
} from "types/listTypes";

export type OverviewContainerPropsType = {
  item: DeliberateAny;
  columns: Array<ListColumnWithValueType>;
  detailsButton?: DetailsButtonType;
  actionButton?: ActionButtonType;
  small?: boolean;
  counter?: DeliberateAny;
  highlight?: boolean;
};

// class OverviewContainer extends Component {
const OverviewContainer = (props: OverviewContainerPropsType) => {
  const [countDown, setCountDown] = useState("");

  useEffect(() => {
    const updateCountdown = () => {
      const expiration = moment(props.item.ChangedDate).add(
        props.item.CardTimeOut,
        "minutes"
      );
      const remaining = moment.duration(expiration.diff(moment()));

      const m = remaining.minutes();
      const s =
        remaining.seconds() > 9
          ? remaining.seconds()
          : `0${remaining.seconds()}`;
      const remainingString = `${m}:${s}`;

      setCountDown(remainingString);
      if (m <= 0 && +s <= 0) {
        props.counter?.expiredFunction(props.item.Id);
      }
    };
    if (props.counter) {
      const intervalRef = setInterval(updateCountdown, 1000);
      return () => clearInterval(intervalRef);
    }
    return undefined;
  }, [countDown]);

  const renderValue = (col: ListColumnWithValueType, item: DeliberateAny) =>
    col.renderFunction ? (
      col.renderFunction(item, countDown)
    ) : (
      <div>
        {col.value} {col.suffix}
      </div>
    );

  const renderDesktopColumns = () =>
    props.columns.map((col, i) => {
      if (i === 0) {
        return (
          <div
            key={`${col.name}_${i}`}
            className={col.className}
            onClick={props.detailsButton && props.detailsButton.onClick}
          >
            <h5 className={`${!props.detailsButton ? "non-clickable" : ""}`}>
              <>{renderValue(col, props.item)}</>
            </h5>
          </div>
        );
      }

      return (
        <div key={`${col.name}_${i}`} className={col.className}>
          <>{renderValue(col, props.item)}</>
        </div>
      );
    });

  const renderSmallColumns = () => {
    const firstColumn = props.columns[0];
    const firstColumnValue = firstColumn.renderFunction
      ? firstColumn.renderFunction(props.item, countDown, true)
      : firstColumn.value;

    return (
      <div>
        {props.detailsButton ? (
          <div onClick={props.detailsButton.onClick}>
            <h5>
              <>{firstColumnValue}</>
            </h5>
          </div>
        ) : (
          <h5 className="non-clickable">
            <>{firstColumnValue}</>
          </h5>
        )}
        <LabelValueList
          items={props.columns.slice(1).map((col) => ({
            label: col.label,
            value: renderValue(col, props.item),
          }))}
        />
      </div>
    );
  };

  // render() {
  return (
    <div
      className={`${props.small ? "small-" : ""}overview-container ${
        props.highlight ? "list-highlight" : ""
      }`}
    >
      {props.small ? renderSmallColumns() : renderDesktopColumns()}
      {props.actionButton && (
        <div className="action-button">
          <Button
            className={props.actionButton.className}
            onClick={props.actionButton.onClick}
            color={`${props.actionButton.color}`}
          >
            {props.actionButton.title}
          </Button>
        </div>
      )}

      {props.detailsButton && <DetailsButton {...props.detailsButton} />}
    </div>
  );
  // }
};

export default OverviewContainer;
