import ListItem from "components/ListItem/ListItem";
import moment from "moment";
import { useSelector } from "react-redux";
import { DeliberateAny } from "types/DelibrateAny";

const HousingPropertiesItem = ({ columns, item }) => {
  const parkingOptions = useSelector(
    (state: DeliberateAny) =>
      state.housingAdmin.housingArea.housingAdminDataOptions.ParkingOptions
  );

  const renderDetails = () => (
    <div className="details" style={{ padding: "0" }}>
      <div className="details-wrapper">
        <ul className="label-value-list">
          <li>
            <span className="label">Postadresse:</span>
            <span className="value">{item.PostalAddress}</span>
          </li>
          <li>
            <span className="label">Postnummer:</span>
            <span className="value">{item.ZipCode}</span>
          </li>
          <br />
          <li>
            <span className="label">Byggeår:</span>
            <span className="value">{item.BuiltYear}</span>
          </li>
          <li>
            <span className="label">Parkering:</span>
            <span className="value">
              {parkingOptions.find((po) => po.Key === item.Parking).Value}
            </span>
          </li>
          <li>
            <span className="label">Leiepris: </span>
            <span className="value">{item.Price} kr</span>
          </li>
          <li>
            <span className="label">Kompensasjon: </span>
            <span className="value">{item.Compensation} kr</span>
          </li>
          <br />
          <li>
            <span className="label">Ledig fra: </span>
            <span className="value">
              {moment(item.AvailableFrom).format("DD.MM.YYYY")}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <ListItem
      columns={columns}
      item={item}
      renderDetailsFunction={() => renderDetails()}
    />
  );
};

export default HousingPropertiesItem;
