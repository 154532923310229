import "./HousingAllocationsColumns.scss";

import React from "react";
import { ListColumnType } from "types/listTypes";

const HousingAllocationsColumns: ListColumnType[] = [
  {
    label: "Leietager",
    name: "Name",
    className: "name",
    renderFunction: (item) => (
      <div className="column-tenant description">
        <h5>{item.FullName}</h5>
        <span className={"spanStyle"}>Ansattnummer: {item.EmployeeNumber}</span>
      </div>
    ),
  },

  {
    label: "Adresse",
    name: "Address",
    className: "address",
    renderFunction: (item) => (
      <div className="column-address">
        <strong>{item.HousingName}</strong>
        <br />
        Leie-ID: {item.HousingRentalId}
      </div>
    ),
  },
  { label: "Status", name: "ApplicationState", className: "status" },
];

export default HousingAllocationsColumns;
