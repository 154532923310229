import './Notifications.scss';
import 'moment/locale/nb'; // Import Norwegian locale

import { uiRecentUserNotificationsURL } from 'api/core/core_api';
import { Notification0Icon, Notification1Icon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import useAxios from 'hooks/useAxios';
import { forEach } from 'lodash';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';
import { useSignalR } from 'contexts/index';

const NotificationsSignalR = () => {
  moment.locale('nb');

  const navigate = useNavigate();
  const { sendRequest } = useAxios();
  const { notifications, setNotifications } = useSignalR();

  const [showNotifications, setShowNotifications] = useState(false);

  const hoverRef = useRef<string>();

  const acknowledgeAll = () => {
    const all = notifications.filter((x) => !x.Acknowledged);

    forEach(all, (n) => handleAcknowledge(n.Id));
  };

  const handleAcknowledge = async (id: string) => {
    await sendRequest(
      {
        method: 'PATCH',
        url: `${uiRecentUserNotificationsURL}/${id}`,
        withCredentials: true,
      },
      () => {
        const updatedNotifications = notifications.map((n: DeliberateAny) => {
          if (n.Id === id) {
            return { ...n, Acknowledged: true };
          }
          return n;
        });
        setNotifications(updatedNotifications);
      },
    );
  };

  const handleMouseEnter = (id: string) => {
    hoverRef.current = id;

    if (!notifications.find((n) => n.Id === id)?.Acknowledged)
      setTimeout(() => {
        if (hoverRef.current === id) {
          handleAcknowledge(id);
        }
      }, 3000);
  };

  const handleMouseLeave = (id: string) => {
    if (hoverRef.current === id) hoverRef.current = undefined;
  };

  return (
    <>
      <BasenIcon
        notification={notifications.filter((n) => !n.Acknowledged).length > 0}
        size={'l'}
        icon={showNotifications ? Notification1Icon : Notification0Icon}
        hoverIcon={!showNotifications ? Notification1Icon : Notification0Icon}
        onClick={() => setShowNotifications((x) => !x)}
      />

      <div className={`fb-notifications ${showNotifications ? 'open' : ''}`}>
        <div className="fb-notify-top">
          <span className="fb-notify-title">Varslinger</span>
          <span onClick={() => acknowledgeAll()} className="fb-notify-link">
            Marker alle som lest
          </span>
        </div>
        <div className="fb-notify-body">
          {notifications && notifications.length > 0 ? (
            notifications.slice(0, 5).map((n) => (
              <div
                key={n.Id}
                className={`fb-notify-item ${n.Acknowledged ? '' : 'notification'}`}
                onMouseEnter={() => handleMouseEnter(n.Id)}
                onMouseLeave={() => handleMouseLeave(n.Id)}
                onClick={() =>
                  navigate(`/my-page/tab-my-notifications?note=${n.Id}`)
                }
              >
                <div className="fb-notify-item-msg">{n.Message}</div>
                <div className="fb-notify-item-date">
                  {moment(n.Created).format('DD.MM.YYYY HH:mm')}
                </div>
              </div>
            ))
          ) : (
            <div>Ingen varsler</div>
          )}
        </div>
        <div className="fb-notify-bottom">
          <span
            onClick={() => {
              navigate(`/my-page/tab-my-notifications`);
            }}
            className="fb-notify-link"
          >
            Vis alle varsler
          </span>
        </div>
      </div>
    </>
  );
};

export default NotificationsSignalR;
