import env from 'env';

const { QUARTER: quarterBase } = env;

const quarterApplication = 'QuarterApplication';

export const applyQuarterURL = `${quarterBase}/${quarterApplication}/Apply`;
export const getQuarterCoApplicantInfoURL = `${quarterBase}/${quarterApplication}/GetCoApplicantInfo`;
export const deleteQuarterApplicationURL = `${quarterBase}/${quarterApplication}/delete`;
export const updateQuarterAssignmentURL = `${quarterBase}/${quarterApplication}/UpdateAssignment`;
export const updateApprovedQuarterURL = `${quarterBase}/${quarterApplication}/UpdateApproved`;
export const unprocessedQuarterApplicationsURL = `${quarterBase}/${quarterApplication}/UnprocessedApplications`;
export const processedQuarterApplicationsURL = `${quarterBase}/${quarterApplication}/ProcessedApplications`;
export const rejectQuarterApplicationURL = `${quarterBase}/${quarterApplication}/reject`;
export const getQuarterApplicationURL = `${quarterBase}/${quarterApplication}/GetApplication`;
export const sendQuarterApplicationToAllocationURL = `${quarterBase}/${quarterApplication}/SendToAllocation`;
export const addMessageQuarterURL = `${quarterBase}/${quarterApplication}/addmessage`;
export const commentForHousingCoordinatorQuarterURL = `${quarterBase}/${quarterApplication}/CommentForHousingCoordinator`;
export const availableQuarterForAreaURL = `${quarterBase}/${quarterApplication}/AvailableQuarterForArea`;

export default quarterBase;
