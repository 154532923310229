import { getMainNavigationData } from 'api/cms/cms_api';
import Loader from 'components/Loader/Loader';
import useAxios from 'hooks/useAxios';
import logo from 'images/logo.svg';
import { useEffect, useState } from 'react';
import NotificationSignalR from 'src/Notification/NotificationSignalR';
import { isDev } from 'utils/helpers';

import { useLogin, useUser } from '../contexts';
import { NavigationItem } from './Menu';
import { MenuFooter } from './MenuFooter';

const Navigation = () => {
  const { setShow, logout } = useLogin();
  const { user, hasUser } = useUser();
  const { requestLoading, sendRequest } = useAxios();
  const [navData, setNavData] = useState<NavigationItem[]>([]);

  const fetchNavData = async () => {
    const axiosParams = {
      method: 'GET',
      url: getMainNavigationData,
      withCredentials: true,
    };

    const applyData = (fetchedData: NavigationItem[]) => {
      setNavData(fetchedData);
    };
    sendRequest(axiosParams, applyData, user);
  };

  useEffect(() => {
    if (user) fetchNavData();
  }, [user]);

  return (
    <nav className="main-navigation scrollable js-main-navigation">
      <div className="nav-container">
        <div
          className="logo-container"
          style={
            navData || requestLoading ? undefined : { backgroundColor: 'gray' }
          }
        >
          <a className="hide-for-small hide-for-medium" href="#/">
            <img src={logo} alt="Basen logo" />
          </a>
        </div>
        <div
          className="current-user"
          style={{
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {user ? (
            <p>{hasUser ? user.Name : 'Ikke pålogget'}</p>
          ) : (
            !navData && !requestLoading && <p>OFFLINE</p>
          )}

          <div id="notification">{user && <NotificationSignalR />}</div>
        </div>

        <div className="menu-root">
          <nav className="menu-main">
            <ul role="toolbar">
              <Loader
                isLoading={requestLoading}
                noBackground
                onLoaderClick={fetchNavData}
              >
                {navData &&
                  navData.map((item, i) => (
                    <li
                      key={i}
                      className={`${item.inactive ? 'fade' : ''} ${
                        item.Restricted ? 'restricted' : ''
                      }`}
                      role="button"
                      aria-controls="content"
                    >
                      <a href={item.Url}>
                        <div className="menu-main-button">
                          <div className="btn-title">
                            <strong>{item.Title}</strong>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))}
              </Loader>
              {isDev() && (
                <li className="" role="button" aria-controls="content">
                  <a href="/#/sandbox">
                    <div className="menu-main-button">
                      <div className="btn-title">
                        <strong>Sandbox</strong>
                      </div>
                    </div>
                  </a>
                </li>
              )}
            </ul>

            {!user || user.EmployeeNumber === '0000' ? (
              <p className="center landing-login">
                <button
                  className="btn red login js-login"
                  tabIndex={-1}
                  onClick={() => setShow(true)}
                >
                  Logg inn
                </button>
              </p>
            ) : (
              <p className="center landing-login">
                <button onClick={logout} className="btn grey logout js-logout">
                  Logg ut
                </button>
              </p>
            )}
          </nav>
        </div>
        <MenuFooter />
      </div>
    </nav>
  );
};

export default Navigation;
