import './TabServiceOrders.scss';

import { fetchStaticFilterOptions } from 'actions/optionsActions';
import {
  proffSearchURL,
  staticFilterOptionsURL,
} from 'api/basenproff/basenproff_api';
import Loader from 'components/Loader/Loader';
import Modal from 'components/modals/Modal/Modal';
import Pagination from 'components/Pagination/Pagination';
import ExportList from 'components/serviceorders/ServiceOrderExportList';
import ServiceOrderList from 'components/serviceorders/ServiceOrderList';
import useAxios from 'hooks/useAxios';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';

import ServiceOrderFilter from './ServiceOrderFilter';

const serviceOrderSearchBase = proffSearchURL;

const ServiceOrders = ({ staticFilterOptionsLoaded }) => {
  const { sendRequest: sendSoRequest, requestLoading: soRequestLoading } =
    useAxios();
  const { sendRequest } = useAxios();
  const [requestErr, setRequestErr] = useState<DeliberateAny>({
    open: false,
  });

  const setRequestError = ({ open, title, description }) => {
    if (!requestErr.open) {
      setRequestErr({ ...requestErr, open, title, description });
    }
  };

  const [items, setItems] = useState<DeliberateAny[]>([]);
  const [searchQueryParam, setSearchQueryParam] = useState({
    SkipCount: 0,
    TakeCount: 10,
  });
  const [pageNumber, setPageNumber] = useState(1);

  // Two objects for holding drop-down data elements
  const [optionsStatus, setOptionsStatus] = useState<DeliberateAny[]>([]);
  const [optionsOrderTypes, setOptionsOrderTypes] = useState<DeliberateAny[]>(
    [],
  );
  const [optionsBras, setOptionsBras] = useState<DeliberateAny[]>([]);
  const [optionsDifs, setOptionsDifs] = useState<DeliberateAny[]>([]);
  const [optionsMarketingAreas, setOptionsMarketingAreas] = useState<
    DeliberateAny[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const mountedRef = useRef(false);

  const asyncEffect = async (url) => {
    await sendSoRequest(
      {
        method: 'GET',
        url: url,
      },
      (response) => {
        if (!mountedRef.current) return;
        if (
          response &&
          response.length > 0 &&
          optionsStatus &&
          optionsStatus.length > 0
        ) {
          const mappedResponse = response.map((SOItem) => {
            const SOItemWithStatus = {
              ...SOItem,
              ServiceOrderStatePrintable: optionsStatus.find(
                (option) => option.Key === SOItem.ServiceOrderState,
              )?.Value,
            };
            return SOItemWithStatus;
          });
          setItems(mappedResponse);
        } else {
          setItems(response);
        }
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  // kaller setFilter fra ServiceOrderFilter. setFilter kjører ajax og oppdaterer items

  const buildQuery = (query) =>
    Object.keys(query)
      .map((key) => key + '=' + query[key])
      .join('&');

  useEffect(() => {
    if (!staticFilterOptionsLoaded) {
      fetchStaticFilterOptions();
    }
  }, [loadError]);

  useEffect(() => {
    if (optionsStatus && optionsStatus.length > 0) {
      const query = buildQuery(searchQueryParam);

      asyncEffect(serviceOrderSearchBase + '?' + query).catch((error) => {
        setLoadError(true);
        setRequestError({
          open: true,
          title: 'Modalmelding',
          description: 'Det skjedde en feil ved søk',
        });
      });
    }
  }, [optionsStatus]);

  const fetchFilterOptions = async () => {
    await sendRequest(
      { url: staticFilterOptionsURL, withCredentials: true },
      (response) => {
        setOptionsStatus(response.ServiceOrderStates);
        setOptionsOrderTypes(response.ServiceOrderTypes);
        setOptionsBras(response.Bras);
        setOptionsDifs(response.Difs);
        setOptionsMarketingAreas(
          response.MarketingAreas.map((marketingArea) => ({
            key: marketingArea.Code,
            value: marketingArea.Caption,
          })),
        );
      },
    );
  };

  useEffect(() => {
    fetchFilterOptions();
  }, [items.length, staticFilterOptionsLoaded]);

  const setFilterAndSearch = async (filter) => {
    setLoading(true);
    setPageNumber(1);

    const searchCriteria = { ...searchQueryParam, ...filter, SkipCount: 0 };
    const query = buildQuery(searchCriteria);
    setSearchQueryParam(searchCriteria);

    asyncEffect(serviceOrderSearchBase + '?' + query).catch((error) =>
      setRequestError({
        open: true,
        title: 'Feil ved filtrering',
        description: `Søk kunne ikke gjennomføres. Statuskode fra server: ${error.status}`,
      }),
    );
  };

  const navigateToPage = (pagenum) => {
    setLoading(true);
    const page = { SkipCount: (pagenum - 1) * searchQueryParam.TakeCount };
    const query = buildQuery({ ...searchQueryParam, ...page });
    asyncEffect(serviceOrderSearchBase + '?' + query).catch((error) =>
      setRequestError({
        open: true,
        title: 'Feil ved søk',
        description:
          'Feil ved respons fra server etter navigering til ny resultatside',
      }),
    );
  };

  const serverPaging = {
    pageNumber,
    pageSize: 10,
    numItems: items.length,
    onChangePageNumber: (number) => {
      setPageNumber(number);
      navigateToPage(number);
    },
  };

  const setPaginationCount = async (count) => {
    setLoading(true);
    const queryItem = {
      ...searchQueryParam,
      SkipCount: 0,
      TakeCount: count,
    };
    const query = buildQuery(queryItem);
    setSearchQueryParam(queryItem);
    asyncEffect(serviceOrderSearchBase + '?' + query).catch((error) =>
      setRequestError({
        open: true,
        title: 'Feil ved søk',
        description: 'Søk feilet etter satt pagineringsnummer',
      }),
    );
  };

  return (
    <>
      <div
        className="max-width tab-service-orders"
        style={{ display: 'block' }}
      >
        <div className="row">
          <p>
            <strong>
              Her kan du administrere og holde oversikt over dine henvendelser
              til Forsvarsbygg.
            </strong>
          </p>
          <p>
            Ved spørsmål, eller brukerstøtte,{' '}
            <Link to="/contact">kontakt Forsvarsbygg servicesenter</Link>
          </p>
        </div>
        <p className="row call-to-action-wrapper">
          <Link className="call-to-action no-underline" to="/reporting">
            <span>Ny henvendelse</span>
          </Link>
        </p>
        <div className="service-orders">
          <div className="row">
            <h2>Registrerte henvendelser</h2>
          </div>
          <ServiceOrderFilter
            setFilterAndSearch={setFilterAndSearch}
            loading={loading}
            opstat={optionsStatus}
            optype={optionsOrderTypes}
            opbras={optionsBras}
            opdifs={optionsDifs}
            opmarketingareas={optionsMarketingAreas}
          />

          <Loader isLoading={soRequestLoading}>
            <div className="button-row half-margin">
              <Pagination
                setPaginationCount={setPaginationCount}
                paginationCount={searchQueryParam.TakeCount}
              />
              <p className="no-margin">
                <ExportList items={items} />
              </p>
            </div>
            <ServiceOrderList
              items={items ? items : []}
              serverPaging={serverPaging}
              urlRoot="/basenproff"
            />
          </Loader>
        </div>
      </div>
      <Modal
        onClose={() => setRequestErr({ ...requestErr, open: false })}
        isOpen={requestErr.open}
        title={requestErr.title}
        description={requestErr.description}
        size="small"
        ariaLabel="En feil oppsto"
      />
    </>
  );
};

export default connect(
  (state: DeliberateAny) => ({
    staticFilterOptions: state.options.staticFilterOptions,
    staticFilterOptionsLoaded: state.options.staticFilterOptionsLoaded,
  }),
  { fetchStaticFilterOptions },
)(ServiceOrders);
