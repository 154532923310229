import { createContext, FC, Fragment, useMemo, useState } from 'react';

import { IWrapper } from '../interfaces/IWrapper';
import { DeliberateAny } from '../types/DelibrateAny';

export const ModalContext = createContext({});

export const ModalContextProvider: FC<IWrapper> = ({ children }) => {
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState(null);

  const showModal = (modalOptions: DeliberateAny, showNow = true) => {
    setModal({
      ...modalOptions,
      infoText: modalOptions.infoText
        ? modalOptions.infoText
        : modalOptions.text,
    });
    setShow(showNow);
  };
  const close = (keepState?: boolean) => {
    setShow(false);
    setModal((x: DeliberateAny) =>
      keepState ? { ...x, isOpen: false } : { isOpen: false },
    );
  };

  const values = useMemo(
    () => ({
      show,
      modal,
      setShow,
      setModal,
      showModal,
      close,
    }),
    [show, modal],
  );

  return (
    <ModalContext.Provider value={values}>
      <Fragment>{children}</Fragment>
    </ModalContext.Provider>
  );
};
