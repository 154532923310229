import React from "react";

const DownloadIcon = (props) => (
  <svg
    className={props.animate ? "move" : ""}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="-51 51 113 113"
    style={{ enableBackground: "new -51 51 113 113" }}
    xmlSpace="preserve"
  >
    <g transform="translate(0,-952.36218)">
      <path
        id="icon-dl-1"
        className="st0"
        d="M-32.9,1037.8l-0.1,53.6c0,1,0.9,1.8,1.8,1.8l72.6,0.2c1,0,1.8-0.9,1.8-1.8l0.1-53.6c0-1-0.9-1.8-1.8-1.8H28.8
        c-1,0-1.8,0.9-1.8,1.8c0,1,0.9,1.8,1.8,1.8h10.9l-0.1,49.9l-69-0.2l0.1-49.9h10.9c1,0,1.8-0.9,1.8-1.8c0-1-0.9-1.8-1.8-1.8H-31
        C-32,1036-32.9,1036.9-32.9,1037.8z"
        style={{
          fill: "#FFFFFF",
          stroke: "#FFFFFF",
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
      <path
        id="icon-dl-2"
        className="st0"
        d="M5.2,1080.6c-0.4,0-1-0.3-1.2-0.5l-15.4-14.6c-0.7-0.7-0.8-1.8-0.1-2.6c0.6-0.7,1.9-0.7,2.6-0.1l12.3,11.7
        l0.1-48.5c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8L7,1074.5l12.4-11.6c0.7-0.7,1.9-0.6,2.6,0.1s0.6,1.9-0.1,2.6L6.4,1080
        C6,1080.5,5.6,1080.5,5.2,1080.6z"
        style={{
          fill: "#FFFFFF",
          stroke: "#FFFFFF",
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
    </g>
  </svg>
);

export default DownloadIcon;
