import List from 'components/List/List';
import ServiceOrderColumns from 'components/serviceorders/ServiceOrderColumns';
import { v4 as uuidv4 } from 'uuid';

import AdditionalServicesServiceOrderItem from './AdditionalServicesServiceOrderItem';

import type { ServiceOrderType } from "types/serviceOrderTypes";
import type { ServerPagingType } from "types/listTypes";
type PropsItemType = {
  item: ServiceOrderType;
  urlRoot: string;
};

type PropsServiceOrderListType = {
  items: Array<ServiceOrderType>;
  serverPaging: ServerPagingType;
  urlRoot: string;
};

const AdditionalServicesServiceOrderList = ({
  items,
  serverPaging,
  urlRoot,
}: PropsServiceOrderListType) => {
  const renderItem = (item: ServiceOrderType) => (
    <AdditionalServicesServiceOrderItem
      key={uuidv4()}
      item={item}
      columns={ServiceOrderColumns}
      urlRoot={urlRoot}
    />
  );

  return (
    <List
      columns={[
        ...ServiceOrderColumns,
        { label: "", value: "", className: "space30" },
      ]}
      renderItem={renderItem}
      items={items}
      noItemsMessage={<p>Ingen saker</p>}
      serverPaging={serverPaging}
    />
  );
};

export default AdditionalServicesServiceOrderList;
