/* eslint react/prop-types: 0 */

import './Location.scss';

import { basenUrl } from 'api/cms/cms_api';
import Button from 'components/buttons/Button/Button';
import DateInput from 'components/DateInput/DateInput';
import Checkbox from 'components/form/Checkbox/Checkbox';
import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import MapPickerModal from 'components/modals/MapPickerModal/MapPickerModal';
import NumberInput from 'components/NumberInput/NumberInput';
import { ModalTypes } from 'constants/modalTypes';
import { useModal, useSlides, useUser } from 'contexts/index';
import {
  blockBookingDeadlineExpired,
  blockBookingNoRooms,
  formatDate,
  getMinDate,
  processHit,
  searchInObject,
  setErrorMsgBooking,
} from 'helpers/bookingHelpers';
import useAxios, { axiosFetch } from 'hooks/useAxios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { DeliberateAny } from 'types/DelibrateAny';

import {
  blokkbestillingURL,
  bookingOptionsURL,
  helpTextsURL,
} from '../../api/booking/booking_api';

const Location = ({
  useReferenceCode,
  setUseReferenceCode,
  referenceCode,
  setReferenceCode,
  parentBooking,
  setParentBooking,
  numberOfGuests,
  setNumberOfGuests,
  isBlockBooking,
  setIsBlockBooking,
  selectedLocation,
  setSelectedLocation,
  arrivalDate,
  setArrivalDate,
  departureDate,
  setDepartureDate,
  setRoomInfo,
  setNumberOfDaysToFocePayment,
  clearBookingState,
  maxBookingLength,
  setMaxBookingLength,
  maxBookingLengthBlockBooking,
  setMaxBookingLengthBlockBooking,
  setMaxBookingLengthToUse,
}) => {
  const { user } = useUser();
  const { goToSlide } = useSlides();
  const [blockBookingUnavailable, setBlockBookingUnavailable] = useState(false);
  const [locationIsValid, setLocationIsValid] = useState<boolean>();
  const [bookingOptions, setBookingOptions] = useState<DeliberateAny[]>([]);
  const [searchOptions, setSearchOptions] = useState<DeliberateAny[]>([]);
  const [helpText, setHelpText] = useState<string>();
  const [locationInput, setLocationInput] = useState(
    selectedLocation && !useReferenceCode
      ? { name: selectedLocation.Name, id: selectedLocation.LocationId }
      : { name: '', id: '' },
  );
  const { sendRequest, requestLoading } = useAxios();
  const { showModal } = useModal();
  const [showMap, setShowMap] = useState(false);

  const fetchBookingOptions = async () => {
    const processBookingOptions = (res) => {
      setBookingOptions(res.Locations);
      setMaxBookingLength(res.MaxBookingLength);
      setMaxBookingLengthBlockBooking(res.MaxBookingLengthBlockBooking);
      if (res.DeadlineForRegisteringPayment) {
        setNumberOfDaysToFocePayment(res.DeadlineForRegisteringPayment);
        setErrorMsgBooking(res.ValidationErrorMessage);
      }
    };

    await sendRequest(
      {
        method: 'GET',
        url: bookingOptionsURL,
      },
      processBookingOptions,
      user,
    );
  };

  const fetchHelpText = async () => {
    const processHelpText = (res) => {
      const doc = new DOMParser().parseFromString(res, 'text/html');

      const bookingInfoHTML =
        doc.getElementsByClassName('booking_info')[0].innerHTML;

      setHelpText(bookingInfoHTML);
    };

    await sendRequest(
      {
        method: 'GET',
        url: helpTextsURL,
      },
      processHelpText,
    );
  };

  useEffect(() => {
    clearBookingState();
    setLocationInput({ name: '', id: '' });
    fetchBookingOptions();
    fetchHelpText();
  }, []);

  const options = [
    { label: 'Bestill uten referansekode', value: false },
    { label: 'Bestill med referansekode', value: true },
  ];

  const handleOpenInfoModal = (e) => {
    e.preventDefault();
    showModal({
      title: 'Info om overnattning',
      html: helpText,
      size: 'medium',
    });
  };

  const handleSetReferenceCode = (value) => {
    setParentBooking(false);
    setReferenceCode(value);
  };

  const gotBlockBooking = (booking) => {
    if (
      blockBookingNoRooms(booking) === 0 ||
      blockBookingDeadlineExpired(booking)
    ) {
      setBlockBookingUnavailable(true);
    } else {
      setBlockBookingUnavailable(false);
    }
    setParentBooking(booking);
    setArrivalDate(moment(booking.ArrivalDate));
    setDepartureDate(moment(booking.DepartureDate));
  };

  const handleSearchReferenceCode = () => {
    axiosFetch(
      {
        url: blokkbestillingURL,
        data: { referenceKey: referenceCode },
      },
      null,
      false,
    ).then((res) => {
      if (res.data.Success === false) {
        setParentBooking(false);
        if (!res.data.Data.Title && !res.data.Data.Text) {
          showModal({ html: res.data.Data });
        } else {
          showModal({
            title:
              res.data.Data.Title ??
              'Finner ingen bestilling med referansekoden',
            infoText: res.data.Data.Text,
          });
        }
      } else {
        gotBlockBooking(res.data.Data);
      }
    });
  };

  const handleShowTermsAndConditions = (event) => {
    event.preventDefault();

    showModal({
      url: basenUrl + '/vilkar?urlSegment=vilkarintegrert',
      selector: '.editor-content',
      size: 'medium',
      modalType: ModalTypes.GetModal,
    });
  };

  const locationLookup = (str) => {
    setLocationInput({ name: str, id: '' });
    if (!str || str.length < 2) {
      setSearchOptions([]);
      return;
    }
    const hits: DeliberateAny[] = [];
    bookingOptions.forEach((bookingOption) => {
      const hit = searchInObject(str, bookingOption);
      if (hit) {
        const message = processHit(hit);
        hits.push({
          id: hit.LocationId,
          name: hit.Name,
          message,
          selected: hits.length === 0 ? 'selected' : '',
        });
      }
    });
    setSearchOptions(hits);
  };

  const handleSelectLocation = (name, id) => {
    setSearchOptions([]);
    setLocationInput({
      name,
      id,
    });
  };

  const navigateLocations = (event) => {
    if (searchOptions.length > 0) {
      const selectedIndex = searchOptions
        .map((e) => e.selected)
        .indexOf('selected');
      switch (event.keyCode) {
        case 40: // down
          event.preventDefault();
          if (selectedIndex < searchOptions.length - 1) {
            const newArray = [...searchOptions];
            newArray[selectedIndex].selected = '';
            newArray[selectedIndex + 1].selected = 'selected';
            setSearchOptions(newArray);
          }
          break;
        case 38: // up
          event.preventDefault();
          if (selectedIndex > 0) {
            const newArray = [...searchOptions];
            newArray[selectedIndex].selected = '';
            newArray[selectedIndex - 1].selected = 'selected';
            setSearchOptions(newArray);
          }
          break;
        case 13: // enter
          event.preventDefault();
          handleSelectLocation(
            searchOptions[selectedIndex].name,
            searchOptions[selectedIndex].id,
          );
          break;
        default:
          break;
      }
    }
  };

  const nextSlide = () => {
    if (locationInput.id) {
      const obj = bookingOptions.find(
        (option) => option.LocationId === locationInput.id,
      );
      setSelectedLocation(obj);
      setRoomInfo([]);
      setMaxBookingLengthToUse(
        isBlockBooking ? maxBookingLengthBlockBooking : maxBookingLength,
      );
      goToSlide('guest-info');
    } else if (parentBooking) {
      const obj = bookingOptions.find(
        (option) => option.LocationId === parentBooking.LocationId,
      );
      setSelectedLocation(obj);
      setIsBlockBooking(false);
      setMaxBookingLengthToUse(maxBookingLengthBlockBooking);
      setRoomInfo([]);
      goToSlide('guest-info');
    } else {
      setLocationIsValid(!locationIsValid);
    }
  };

  const renderOrderWithReferenceCode = () => (
    <div className="content-group grey padding half-margin">
      <div className="book-wrapper">
        <div className="reference-input">
          <label>
            <span>Oppgi referansekode</span>
            <div className="input-with-button">
              <input
                placeholder="Eks. EF23456"
                type="text"
                value={referenceCode}
                name="referenceCode"
                onChange={(e) => handleSetReferenceCode(e.target.value)}
              />
              <Button color="red" onClick={() => handleSearchReferenceCode()}>
                Søk
              </Button>
            </div>
          </label>
          {parentBooking && (
            <div className="result-wrapper" style={{ height: 'auto' }}>
              <div className="result-container">
                <div className="reservation-info-wrapper margin vertical-padding">
                  <p className="half-margin">
                    <strong>Navn: </strong>
                    <span>{parentBooking.GroupInfo.EventName}</span>
                  </p>
                  <p className="half-margin">
                    <strong>Sted: </strong>
                    <span>{parentBooking.Location}</span>
                  </p>
                  <p className="no-margin">
                    <strong>Dato: </strong>
                    <span>{formatDate(parentBooking)}</span>
                  </p>
                  {blockBookingUnavailable && (
                    <p className="no-margin red">
                      <span>
                        Det er dessverre ikke mulig å booke flere rom på
                        bestillingen knyttet til denne referansekoden.
                        <br /> Ta kontakt med arrangør eller servicesenteret for
                        å for å finne en løsning, eller gjennomfør en bestilling
                        uten referansekode for samme tidsperiode.
                      </span>
                    </p>
                  )}
                </div>
                <div className="sub-booking-guests">
                  <NumberInput
                    name="NumberOfGuests"
                    title="Antall gjester"
                    value={numberOfGuests}
                    minValue={1}
                    small
                    onChange={(name, value) => setNumberOfGuests(value)}
                  />
                </div>
                <Button
                  className="btn red align-with-inputs float-right"
                  color="red"
                  onClick={() => nextSlide()}
                  disabled={blockBookingUnavailable}
                >
                  Neste
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderOrderWithoutReferenceCode = () => {
    if (parentBooking) {
      setParentBooking(false);
    }
    return (
      <div className="content-group grey padding half-margin">
        <div className="book-wrapper">
          <div className="settings-container">
            <div className="row margin">
              <Checkbox
                name="isBlockBooking"
                checked={isBlockBooking}
                label="Jeg er arrangør, og ønsker referansekode så deltakerne selv kan booke og reservere fra min reservasjon."
                onChange={() => setIsBlockBooking(!isBlockBooking)}
              />
            </div>
            <div className="book-city location-input">
              <label className={locationIsValid === false ? 'invalid' : ''}>
                <span>By eller sted</span>
                <div className="input-with-button">
                  <input
                    autoComplete="off"
                    onChange={(e) => locationLookup(e.target.value)}
                    onKeyUp={(e) => navigateLocations(e)}
                    onKeyDown={(e) =>
                      e.key === 'Tab' &&
                      handleSelectLocation(
                        searchOptions.find(
                          (option) => option.selected === 'selected',
                        ).name,
                        searchOptions.find(
                          (option) => option.selected === 'selected',
                        ).id,
                      )
                    }
                    value={locationInput.name}
                    className="input-with-button"
                    type="text"
                    name="location-input"
                    placeholder="Eks. Kolsås leir"
                  />

                  <button
                    className="btn white"
                    onClick={() => setShowMap(true)}
                  />
                </div>
              </label>
              <div className="autocomplete-results">
                <ul>
                  {searchOptions.map((option) => (
                    <li
                      key={option.id}
                      className={`${
                        option.selected === 'selected' ? 'selected' : ''
                      } ${
                        option.message &&
                        option.message.length > 0 &&
                        'unavailable'
                      }`}
                      role="article"
                    >
                      <div
                        role="presentation"
                        onClick={() =>
                          handleSelectLocation(option.name, option.id)
                        }
                      >
                        {option.name}
                        {option.message && option.message.length > 0 && (
                          <span className="unavailable-message">
                            {option.message}
                          </span>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="book-arrival picker-placement-right">
              <DateInput
                title="Ankomst"
                name="arrival"
                minDate={getMinDate()}
                selectedDate={arrivalDate}
                text={
                  <span>
                    <strong>NB!</strong> For å bestille overnatting med mindre
                    enn to dager til ankomst, vennligst{' '}
                    <a href="/#/contact">kontakt Forsvarsbygg Servicesenter</a>.
                  </span>
                }
                onSelectDate={setArrivalDate}
              />
            </div>
            <div className="book-departure picker-placement-left">
              <DateInput
                title="Avreise"
                name="departure"
                startDate={arrivalDate}
                selectedDate={departureDate}
                onSelectDate={setDepartureDate}
                maxDurationInDays={
                  isBlockBooking
                    ? maxBookingLengthBlockBooking
                    : maxBookingLength
                }
                durationValidationMessage={`Korttidsovernattinger kan ikke vare lengre enn ${
                  isBlockBooking
                    ? maxBookingLengthBlockBooking
                    : maxBookingLength
                } dager`}
              />
            </div>
            <div className="book-guests">
              <NumberInput
                name="NumberOfGuests"
                title="Antall gjester"
                value={numberOfGuests}
                minValue={1}
                onChange={(name, value) => setNumberOfGuests(value)}
              />
            </div>
            <div className="book-submit">
              <label>&nbsp;</label>
              <Button
                type="submit"
                color="red"
                className="js-next"
                onClick={() => nextSlide()}
              >
                Søk
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="form book form">
        <div className="radio-container margin">
          <h2>Bestill overnatting</h2>
          <div className="half-margin">
            <RadioButtonGroup
              options={options}
              onChange={() => setUseReferenceCode(!useReferenceCode)}
              name="useReferenceCode"
              checkedOption={useReferenceCode}
            />
          </div>
          <p>
            {!requestLoading && (
              <Link
                to="Hva er referansekode?"
                onClick={(e) => handleOpenInfoModal(e)}
              >
                Hva er referansekode?
              </Link>
            )}
          </p>
        </div>

        {useReferenceCode
          ? renderOrderWithReferenceCode()
          : renderOrderWithoutReferenceCode()}
        <p>
          <Link
            className="float-right line-height-normal"
            to="Vilkår for overnatting"
            onClick={(e) => handleShowTermsAndConditions(e)}
          >
            Vilkår for overnatting
          </Link>
        </p>
      </div>
      {
        <div style={{ display: showMap ? 'block' : 'none' }}>
          <MapPickerModal
            modalData={{
              onClose: () => {
                setShowMap(false);
              },
              isOpen: true,
              modalType: 'map-picker',
              arenas: bookingOptions,
              size: 'large',
              scrollable: true,
              extraClass: 'no-padding',
              onYes: (arena) => {
                handleSelectLocation(arena.Name, arena.LocationId);
              },
            }}
          />
        </div>
      }
    </>
  );
};

export default Location;
