import Modal from 'components/modals/Modal/Modal';
import { Component, ErrorInfo, ReactNode } from 'react';
import { appInsights } from 'src/appInsight';

import { IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';

interface Props {
  children: ReactNode | ReactNode[];
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo);

    // Create a sanitized error object
    const sanitizedError = {
      name: error.name,
      message: error.message,
      stack: error.stack ? error.stack.substring(0, 1000) : undefined, // Limit stack size
    };

    appInsights.trackException({
      exception: sanitizedError as Error,
      severityLevel: SeverityLevel.Error,
      properties: {
        componentStack: errorInfo.componentStack
          ? errorInfo.componentStack.substring(0, 1000)
          : undefined,
      },
    });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="app-root documents">
          <Modal
            isOpen={this.state.hasError}
            title="Ooops, det oppsto en feil!"
            cancel={{
              text: 'Prøv igjen',
              onClick: () => location.reload(),
            }}
            submit={{
              text: 'Lukk',
              onClick: () => (location.href = '/'),
            }}
          >
            <p>Beklager ulempen!</p>
            <p>
              En teknisk feil har forhindret fullføringen av din forespørsel. Vi
              arbeider med å rette feilen. Vennligst prøv igjen senere eller
              kontakt kundestøtte for umiddelbar assistanse. Takk for din
              forståelse.
            </p>
          </Modal>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
