import './Dropzone.scss';

import { fetchDropzoneOptions } from 'actions/optionsActions';
import { getDropzoneOptionsURL } from 'api/cms/cms_api';
import Modal from 'components/modals/Modal/Modal';
import useAxios from 'hooks/useAxios';
import _ from 'lodash/fp';
import { FC, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';

import { BasenFileType } from '../../types/commonTypes';
import { DeliberateAny } from '../../types/DelibrateAny';
import Button from '../buttons/Button/Button';
import Input from '../form/Input/Input';
import ConfirmationModal from '../modals/ConfirmationModal/ConfirmationModal';
import { mimeTypesToDict } from './helpers';

type PropsType = {
  formKey?: string;
  title?: string;
  maxFiles?: number;
  dontShowDescription?: boolean;
  strAcceptedFileTypes?: string;
  files: Array<BasenFileType>;
  deleteButtonStyle?: string;
  addFile: (file: DeliberateAny) => void;
  removeFile: (file: DeliberateAny, index: string) => void;
  updateFileDescription: (
    fileName: string,
    description: string,
    index: string,
  ) => void;
  showSizeInKB?: boolean;
  acceptedFileTypes?: string;
  fetchDropzoneOptions: () => void;
};

const maxFilesize = 512;

const Dropzone: FC<PropsType> = ({
  formKey,
  title,
  maxFiles,
  dontShowDescription,
  strAcceptedFileTypes,
  files,
  deleteButtonStyle,
  acceptedFileTypes,
  addFile,
  removeFile,
  updateFileDescription,
  showSizeInKB,
}) => {
  const { sendRequest } = useAxios();
  const defaultName = 'files';
  const inputId = _.uniqueId('id');

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [percentComplete, setPercentComplete] = useState(0);
  const [dropzoneOptions, setDropzoneOptions] = useState<DeliberateAny>();
  const [showTooBig, setShowTooBig] = useState(false);

  const allowMoreFiles = () => {
    if (maxFiles) {
      return files.length < maxFiles;
    }
    return true;
  };

  const handleOnDrop = (
    acceptedFiles: DeliberateAny[],
    rejectedFiles: FileRejection[],
  ) => {
    const filesMatchingTypes = acceptedFiles.filter(
      file =>
        file.type &&
        (dropzoneOptions?.Filetypes || strAcceptedFileTypes).includes(
          file.type,
        ),
    );

    if (filesMatchingTypes && filesMatchingTypes.length > 0) {
      const remainingAllowedFiles = maxFiles
        ? maxFiles - files.length
        : acceptedFiles.length;

      filesMatchingTypes.slice(0, remainingAllowedFiles).forEach(file => {
        if (
          (dropzoneOptions.FileSize || maxFilesize) >
          file.size / (1024 * 1024)
        ) {
          file.preview = URL.createObjectURL(file);
          addFile(file);
        } else {
          setShowTooBig(true);
        }
      });
    }

    if (rejectedFiles && rejectedFiles.length > 0) {
      setShowErrorModal(true);
    }
  };

  const handleDeleteFile = (file: DeliberateAny, index: string) => {
    removeFile(file, index);
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
    setShowTooBig(false);
  };

  const handleUpdateFileDescription = (
    fileName: string,
    title: string,
    index: string,
  ) => {
    updateFileDescription(fileName, title, index);
  };

   
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleOnDrop,
    accept:
      mimeTypesToDict(dropzoneOptions?.Filetypes) ||
      mimeTypesToDict(acceptedFileTypes ?? ''),
    multiple: maxFiles !== 1,
  });

  const fetchDropzoneOptions = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: getDropzoneOptionsURL,
        withCredentials: true,
      },
      (response: DeliberateAny) => {
        setDropzoneOptions({
          Filetypes: response?.types,
          FileSize: response?.maxFileSizeInMb,
        });
      },
    );
  };

  useEffect(() => {
    fetchDropzoneOptions();
  }, []);

  return (
    <div>
      {title && <label htmlFor={inputId}>{title}</label>}

      <div
        {...getRootProps()}
        onClick={e => {
          const rootProps = getRootProps();
          if (
            rootProps.onClick &&
            e &&
            files.length === 0 &&
            allowMoreFiles()
          ) {
            rootProps.onClick(e);
          }
        }}
        className={`dropzone ${
          files.length === 0 ? 'has-icon' : 'dz-started'
        } ${isDragActive ? 'drag' : ''}`}
      >
        <input name={formKey ?? defaultName} {...getInputProps()} />
        {!files.length && (
          <div className="dz-message">
            <p>
              Dra og slipp {maxFiles === 1 ? 'filen' : 'filer'} her, eller klikk
              for å velge.
            </p>
          </div>
        )}

        <div>
          {files.map((item, i) => {
            return (
              <div key={item.file.title + i.toString()} className="file-row">
                <div className="thumb">
                  <span>
                    <div
                      className="thumbnail-preview"
                      style={{ backgroundImage: `url(${item.file.preview})` }}
                    />
                  </span>
                </div>
                <div className="info">
                  <p className="name">{item.file.name}</p>
                  <p>
                    <span>
                      {showSizeInKB ? (
                        <b>{(item.file.size / 1000).toFixed(1)} KB</b>
                      ) : (
                        <b>{(item.file.size / 1000000).toFixed(1)} MB</b>
                      )}
                    </span>
                    <Button
                      color={deleteButtonStyle ? deleteButtonStyle : 'red'}
                      onClick={() => handleDeleteFile(item, i.toString())}
                      stopPropagation
                    >
                      Slett
                    </Button>
                  </p>
                  {dontShowDescription ? (
                    ''
                  ) : (
                    <Input
                      placeholder="Beskrivelse"
                      type="text"
                      value={files[i].title}
                      name={files[i].file.name}
                      // onClick={(event: ) => event.stopPropagation()}
                      onChange={(fileName: string, title: string) =>
                        handleUpdateFileDescription(
                          fileName,
                          title,
                          i.toString(),
                        )
                      }
                    />
                  )}
                  <div
                    style={{ opacity: percentComplete > 0 ? 1 : 0 }}
                    className="progress-wrapper"
                  >
                    <div
                      role="progressbar"
                      aria-valuemin={0}
                      aria-valuemax={100}
                      aria-valuenow={0}
                    >
                      <div
                        className="progress-bar progress-bar-success"
                        style={{ width: `${percentComplete}%` }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {files.length > 0 && allowMoreFiles() && (
        <Button
          style={{ marginTop: 10 }}
          color="grey"
          className="add-attachment dz-clickable"
          onClick={e => {
            const rootProps = getRootProps();
            if (rootProps.onClick && e) {
              rootProps.onClick(e);
            }
          }}
        >
          Legg til flere filer
        </Button>
      )}

      <ConfirmationModal
        title="Ooops, det oppsto en feil!"
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        infoText={`Du har valgt en ugyldig filtype.
                    ${
                      strAcceptedFileTypes || dropzoneOptions?.Filetypes
                        ? `Vennligst velg en fil av typen
                    ${strAcceptedFileTypes || dropzoneOptions?.Filetypes}.`
                        : ''
                    }`}
      />
      <Modal
        title="Ooops, det oppsto en feil!"
        isOpen={showTooBig}
        onClose={closeErrorModal}
        >
          {`Filen er for stor. Maks størrelse ${
          dropzoneOptions?.FileSize || maxFilesize
        } MB`}</Modal>
    </div>
  );
};

export default connect(
  (state: DeliberateAny) => ({
    dropzoneOptions: state.options.dropzoneOptions,
    dropzoneOptionsLoaded: state.options.dropzoneOptionsLoaded,
  }),
  { fetchDropzoneOptions },
)(Dropzone);
