/*
This page wil be replaced by InformationPage in the future. InformationPage is currently not active i production (15/05-24
*/
import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import Loader from "components/Loader/Loader";
import { getHousingHandbook } from "api/cms/cms_api";
import useFetch from "hooks/useFetch";

const OldInformationPage = () => {

    const { isLoading, data: oldInformation } = useFetch<OldInformationType>(
        getHousingHandbook
    );

    const [body, setBody] = useState("");

    useEffect(() => {
        if (oldInformation) {
            const doc = new DOMParser().parseFromString(DOMPurify.sanitize(oldInformation.MainBody), 'text/html');
            if (doc.getElementsByClassName('issuuembed')[0]) {

                doc.getElementsByClassName('issuuembed')[0].innerHTML = `<iframe
                src="https://e.issuu.com/embed.html?identifier=ak0y1mrvol7y&amp;embedType=script#11430530/13003840"
                title="issuu.com"
                style="border:none;width:100%;height:100%;"
                allowfullscreen=""
                webkitallowfullscreen=""
                mozallowfullscreen=""
                msallowfullscree=""
                ></iframe>`;
            }
            setBody(doc.documentElement.outerHTML);
        }
    }, [oldInformation]);

    return (
        <Loader isLoading={isLoading}>
            {oldInformation && body && (
                <div className="slides">
                    <div className="slide active-slide">
                        <div className="sticky-middle scrollable">
                            <div className="max-width editor-content">
                                <h1 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(oldInformation.Heading) }} />
                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(oldInformation.MainIntro) }} />
                                <div dangerouslySetInnerHTML={{ __html: body }} />
                            </div>
                        </div>
                    </div>
                </div>)}
        </Loader>
    );
};
export default OldInformationPage;

type OldInformationType = {
    Heading: string;
    MainIntro: string;
    MainBody: string;
};