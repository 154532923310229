import { createContext, Dispatch, FC, Fragment, useMemo, useState } from 'react';

import { IWrapper } from '../interfaces/IWrapper'; // Assuming you have this interface

export interface ApplicationContextState {
  heading: string;
  setHeading: Dispatch<string>;
  subHeading: string;
  setSubHeading: Dispatch<string>;
  clear: () => void;
}

export const ApplicationContext = createContext({} as ApplicationContextState);

export const ApplicationContextProvider: FC<IWrapper> = ({ children }) => {
  const [heading, setHeading] = useState<string>('');
  const [subHeading, setSubHeading] = useState<string>('');

  const clear = () => {
    setHeading('');
    setSubHeading('');
  };

  const values = useMemo(
    () => ({
      heading,
      setHeading,
      subHeading,
      setSubHeading,
      clear,
    }),
    [heading, subHeading],
  );

  return (
    <ApplicationContext.Provider value={values}>
      <Fragment>{children}</Fragment>
    </ApplicationContext.Provider>
  );
};
