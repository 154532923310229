import './FireListModal.scss';

import BoxClose from 'components/buttons/BoxClose/BoxClose';
import Button from 'components/buttons/Button/Button';
import Modal from 'components/modals/Modal/Modal';
import { useEffect, useState } from 'react';

const FireListModal = ({
  showModal,
  text,
  listDate,
  triggerListUpdate,
  triggerPrint,
  hideUpdateButton = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(showModal);
  }, [showModal]);

  const onClose = () => {
    triggerListUpdate();
  };

  const onSubmit = () => {
    triggerPrint();
  };

  const renderItem = () => {
    return (
      <Modal
        size="medium"
        ariaLabel={text}
        isOpen={isOpen}
        overrideButtonRow={true}
      >
        <div className="fire-list-modal">{text}</div>
        {!hideUpdateButton ? (
          <div className="fire-list-modal-button-row">
            <Button onClick={onClose} color="red">
              Hent oppdatert brannliste
            </Button>

            <Button onClick={onSubmit}>
              Skriv ut brannliste for {listDate}
            </Button>
          </div>
        ) : (
          <div className="fire-list-modal-button-row center">
            <Button onClick={onSubmit}>
              Skriv ut brannliste for {listDate}
            </Button>
          </div>
        )}
        <BoxClose onClick={onClose} title="Lukk popup" topRight />
      </Modal>
    );
  };

  return renderItem();
};

export default FireListModal;
