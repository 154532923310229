import './HousingApplicationsOverview.scss';

import {
  addAllocationRound,
  fetchHousingAdministrationDataOptions,
  fetchHousingRoundDataOptions,
  handleCloseHousingRound,
  setAllocationRoundId,
  updateHousingApplicationsPage,
} from 'actions/housingAdminActions';
import {
  allocationEndRoundURL,
  applicationsAsPdfURL,
  housingAllocationExcelDocumentURL,
} from 'api/housing/housing_api';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import DownloadButton from 'components/buttons/DownloadButton/DownloadButton';
import DateInput from 'components/DateInput/DateInput';
import DetailsCards from 'components/DetailsCards/DetailsCards';
import Loader from 'components/Loader/Loader';
import Modal from 'components/modals/Modal/Modal';
import { useSlides } from 'contexts/index';
import FBHelpers from 'helpers/_helpers';
import {
  getRoundTitle,
  isOpenAllocationRound,
  isRegularAllocationRound,
  openRoundIsAllocatable,
  prettyPrintApplicationDeadline,
  regularAllocationRoundExists,
  roundIsAllocated,
  roundIsClosed,
  roundIsFinished,
  roundIsOpen,
} from 'helpers/housingAdminHelpers';
import { axiosFetch } from 'hooks/useAxios';
import activeIcon from '/assets/img/FB_bolig_aktivrunde.svg';
import inactiveIcon from '/assets/img/FB_bolig_inaktivrunde.svg';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DeliberateAny } from 'types/DelibrateAny';

const NewRoundModal = ({ data, isOpen, setModalIsOpen }) => {
  const [selectedDay, setSelectedDay] = useState(moment());

  const dispatch = useDispatch();

  return (
    <Modal
      title={`Opprett fordelingsrunde for ${data.Name}`}
      isOpen={isOpen}
      size="medium"
      onClose={() => setModalIsOpen(false)}
      submit={{
        onClick: () =>
          dispatch(
            addAllocationRound(
              data.Id,
              selectedDay
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .toISOString()
            )
          ),
        text: 'Opprett',
      }}
      ariaLabel={`Opprett fordelingsrunde for ${data.Name}`}
      cancel={{ hide: true }}
    >
      <p>
        Datoen som du velger bør være den samme som du deler i kunngjøringen til
        søkerne.
      </p>
      <DateInput
        title={<strong style={{ color: 'black' }}>Søknadsfrist</strong>}
        name="deadline"
        minDate={moment()}
        selectedDate={selectedDay}
        onSelectDate={setSelectedDay}
        full
      />
      <p style={{ paddingTop: '11px' }}>
        NB: Det er ikke mulig å endre fristen, kun utsette den etter fristen har
        gått ut.
      </p>
    </Modal>
  );
};

const CloseRoundModal = ({ isOpen, setModalIsOpen, onSubmit }) => (
  <Modal
    title="Steng søknadsmulighet"
    isOpen={isOpen}
    size="medium"
    onClose={() => setModalIsOpen(false)}
    submit={{
      onClick: () => onSubmit(),
      text: 'Steng',
    }}
    cancel={{ text: 'Avbryt', onClick: () => setModalIsOpen(false) }}
    ariaLabel="Steng søknadsmulighet"
  >
    <p>
      Ved å stenge søknadsmuligheten vil det ikke være mulig å sende inn flere
      søknader tilknyttet denne fordelingsrunden.
    </p>
  </Modal>
);

const RoundEndModal = ({ isOpen, setModalIsOpen, onSubmit }) => (
  <Modal
    title="Lukk fordelingsrunde"
    isOpen={isOpen}
    size="medium"
    onClose={() => setModalIsOpen(false)}
    submit={{
      onClick: () => onSubmit(),
      text: 'Lukk runde',
    }}
    cancel={{ text: 'Avbryt', onClick: () => setModalIsOpen(false) }}
  >
    <p>Er du sikker på at du vil lukke fordelingsrunden?</p>
  </Modal>
);

const InfoModal = ({ isOpen, setModalIsOpen, title, text }) => (
  <Modal
    title={title}
    isOpen={isOpen}
    size="medium"
    onClose={() => setModalIsOpen(false)}
    submit={{ hide: true }}
    cancel={{ text: 'Lukk', color: 'red' }}
  >
    <p>{text}</p>
  </Modal>
);

const HousingApplicationsOverview = (props) => {
  const { goToSlide } = useSlides();
  const [showNewRoundModal, setShowNewRoundModal] = useState(false);
  const [showCloseRoundModal, setShowCloseRoundModal] = useState(false);
  const [showDeadlineValidInfoModal, setShowDeadlineValidInfoModal] =
    useState(false);
  const [showOngoingRoundsInfoModal, setShowOngoingRoundsInfoModal] =
    useState(false);
  const [showRoundEndModal, setShowRoundEndModal] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHousingRoundDataOptions());
    dispatch(fetchHousingAdministrationDataOptions());
  }, []);

  const housingArea = useSelector(
    (state: DeliberateAny) => state.housingAdmin.housingArea,
    shallowEqual
  );
  const loadingHousingAreas = useSelector(
    (state: DeliberateAny) => state.housingAdmin.loadingHousingAreas
  );

  const validateAllocationRound = () => {
    if (
      regularAllocationRoundExists(housingArea.housingCurrentAllocationRounds)
    ) {
      setShowOngoingRoundsInfoModal(true);
    } else {
      setShowNewRoundModal(true);
    }
  };

  const housingRoundOverview = () => {
    const getRoundStatus = (round, dataOptions) => {
      let status: DeliberateAny = '';
      if (roundIsOpen(round, dataOptions)) {
        status = <span>Åpen for boligsøknader</span>;
      }

      if (roundIsClosed(round, dataOptions)) {
        status = <span>Under behandling</span>;
      }

      if (roundIsAllocated(round, dataOptions)) {
        const acceptanceDeadline = round.Round.AllocationAcceptanceDeadline;
        const nbText = (
          <span>
            <strong>Obs:</strong> det kan finnes tildelte boliger utover
            svarfrist hovedrunde hvis det er gjort flere tildelinger
          </span>
        );

        status = moment().isAfter(moment(acceptanceDeadline)) ? (
          <span>
            Boliger tildelt. Svarfristen på hovedrunden har gått ut.
            <br />
            {nbText}
          </span>
        ) : (
          <span>
            Boliger tildelt. Søkerne i hovedrunden har
            <strong className="red">
              {FBHelpers.getStrDaysOrHoursLeftUntilDeadline(
                moment(acceptanceDeadline)
              )}
            </strong>{' '}
            igjen før svarfristen går ut. {nbText}
          </span>
        );
      }

      if (roundIsFinished(round, dataOptions)) {
        status = (
          <span>
            Avsluttet{' '}
            <strong className="red">
              {moment(round.Round.RoundEndedDate).format('DD.MM.YYYY')}
            </strong>
          </span>
        );
      }

      return (
        <span>
          <strong>Status: </strong>
          {status}
        </span>
      );
    };

    const renderRenewalDetailsCards = (applicationsCount) => (
      <DetailsCards
        cards={[
          {
            header: 'Forlengelser',
            iconSrc: inactiveIcon,
            details: (
              <div>
                <div className="margin" style={{ marginTop: '11px' }}>
                  <p>
                    <strong>Søkefrist:</strong> Fortløpende tildeling
                  </p>
                  <p>
                    <strong>Antall søknader:</strong> {applicationsCount}
                  </p>
                </div>
                <ButtonRow>
                  <ButtonRow.Left
                    onClick={() =>
                      goToSlide(
                        `/boligsekretær/boligforlengelser/${props.housingArea.Id}`
                      )
                    }
                    className="btn red"
                  >
                    {applicationsCount > 0
                      ? 'Behandle søknader'
                      : 'Gå til oversikt'}
                  </ButtonRow.Left>
                </ButtonRow>
              </div>
            ),
          },
        ]}
      />
    );

    const setAllocationRound = (round, key) => {
      dispatch(setAllocationRoundId(round.Round.Id));
      switch (key) {
        case 1:
          goToSlide(`/boligsekretær/behandle/${props.housingArea.Id}`);
          break;
        case 2:
          goToSlide(`/boligsekretær/tildelingsliste/${props.housingArea.Id}`);
          break;
        case 3:
          goToSlide(`/boligsekretær/boligliste/${props.housingArea.Id}`);
          break;
        case 4: {
          const isBeforeApplicationDeadline = moment().isBefore(
            round.Round.ApplicationDeadline
          );
          if (isBeforeApplicationDeadline) {
            setShowDeadlineValidInfoModal(true);
          } else {
            setShowCloseRoundModal(true);
          }
          break;
        }
        case 5:
          setShowRoundEndModal(true);
          break;
        default:
          break;
      }
    };

    const closeRound = () => {
      const url = allocationEndRoundURL;
      const data = {
        allocationRoundId: housingArea.selectedAllocationRound.Round.Id,
      };
      axiosFetch({ url, data, method: 'POST' }).then(() => {
        dispatch(
          updateHousingApplicationsPage(props.match.params.housingAreaId)
        );
      });
    };

    const renderCurrentRoundDetailsCards = (
      currentAllocationRounds,
      housingRoundDataOptions
    ) => {
      let cards = [];

      if (
        !housingArea.loadingCurrentAllocationRounds &&
        !housingArea.loadingRoundDataOptions
      ) {
        cards = currentAllocationRounds.map((round) => ({
          header: getRoundTitle(round, housingRoundDataOptions),
          description: getRoundStatus(round, housingRoundDataOptions),
          iconSrc: isRegularAllocationRound(round) ? activeIcon : inactiveIcon,
          details: (
            <div>
              <div
                className="margin border-bottom"
                style={{ marginTop: '11px' }}
              >
                <p>
                  <strong>Søkefrist: </strong>
                  {prettyPrintApplicationDeadline(round)}
                </p>
                <p>
                  <strong>Antall søknader:</strong> {round.ApplicationCount}
                </p>
                {roundIsAllocated(round, housingRoundDataOptions) ? (
                  <div>
                    <div className="margin border-bottom" />
                    <p>
                      <strong>Antall boliger tildelt:</strong>{' '}
                      {round.AllocationCount}
                    </p>
                    <p>
                      <strong>Tildelingsdato hovedrunde: </strong>
                      {moment(round.Round.FinallyAllocatedDate).format(
                        'DD.MM.YYYY'
                      )}
                    </p>
                    <p>
                      <strong>Svarfrist på tildeling i hovedrunde:</strong>{' '}
                      {moment(round.Round.AllocationAcceptanceDeadline).format(
                        'DD.MM.YYYY'
                      )}
                    </p>
                    <p>
                      <strong>Svar på tildelingen:</strong>
                      <span>
                        {round.AllocationAcceptedCount} Godtatt bolig,{' '}
                        {round.AllocationNoAnswerCount} Ikke svart,{' '}
                        {round.AllocationRejectedCount} Takket nei,{' '}
                        {round.AllocationAutomaticallyRejectedCount} Avslått
                        grunnet manglende svar
                      </span>
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <ButtonRow>
                {round.Round.Closeable && !isOpenAllocationRound(round) && (
                  <ButtonRow.Left
                    onClick={() => setAllocationRound(round, 4)}
                    className="btn red"
                  >
                    Steng søknadsmulighet
                  </ButtonRow.Left>
                )}
                {(roundIsClosed(round, housingRoundDataOptions) ||
                  openRoundIsAllocatable(round, housingRoundDataOptions)) && (
                  <ButtonRow.Left
                    onClick={() => setAllocationRound(round, 1)}
                    className="btn red"
                  >
                    Behandle søknader
                  </ButtonRow.Left>
                )}

                {roundIsAllocated(round, housingRoundDataOptions) && (
                  <ButtonRow.Left
                    onClick={() => setAllocationRound(round, 2)}
                    className="btn grey"
                  >
                    Se tildeling
                  </ButtonRow.Left>
                )}
                <ButtonRow.Left
                  onClick={() => setAllocationRound(round, 3)}
                  className="btn grey btn-wrap"
                >
                  Se ledige boliger
                </ButtonRow.Left>

                {roundIsAllocated(round, housingRoundDataOptions) &&
                  isRegularAllocationRound(round) &&
                  moment().isAfter(
                    moment(round.Round.AllocationAcceptanceDeadline)
                  ) && (
                    <ButtonRow.Left
                      onClick={() => setAllocationRound(round, 5)}
                      className="btn red"
                    >
                      Lukk fordelingsrunde
                    </ButtonRow.Left>
                  )}
              </ButtonRow>

              {isRegularAllocationRound(round) &&
                roundIsOpen(round, housingRoundDataOptions) && (
                  <div className="LinkButtons">
                    <div>
                      <DownloadButton
                        href={`${applicationsAsPdfURL}?roundId=${round.Round.Id}`}
                      >
                        Last ned (PDF)
                      </DownloadButton>
                      <DownloadButton
                        href={`${housingAllocationExcelDocumentURL}?allocationRoundId=${round.Round.Id}`}
                      >
                        Last ned behandlingsark
                      </DownloadButton>
                    </div>
                  </div>
                )}
            </div>
          ),
        }));
      }
      if (cards.length) {
        return (
          <div>
            <DetailsCards cards={cards} />
            {showCloseRoundModal && (
              <CloseRoundModal
                isOpen={showCloseRoundModal}
                setModalIsOpen={setShowCloseRoundModal}
                onSubmit={() =>
                  dispatch(
                    handleCloseHousingRound(
                      housingArea.selectedAllocationRound.Round.Id,
                      housingArea.Id
                    )
                  )
                }
              />
            )}
            {showDeadlineValidInfoModal && (
              <InfoModal
                isOpen={showDeadlineValidInfoModal}
                setModalIsOpen={setShowDeadlineValidInfoModal}
                title="Søkefristen er fortsatt gyldig"
                text="Fordelingsrunden kan ikke stenges før søkefristen er gått ut."
              />
            )}
            {showRoundEndModal && (
              <RoundEndModal
                isOpen={showRoundEndModal}
                setModalIsOpen={setShowRoundEndModal}
                onSubmit={closeRound}
              />
            )}
          </div>
        );
      }
      return (
        <div className="row">
          <div className="housing-rounds">
            <div className="details-card info-container padding">
              Fant ingen pågående fordelingsrunder eller åpne søknader for dette
              området
            </div>
          </div>
        </div>
      );
    };

    const renderPreviousRoundDetailsCards = (
      previousAllocationRounds,
      housingRoundDataOptions
    ) => {
      let cards = [];

      if (
        !housingArea.loadingPreviousAllocationRounds &&
        !housingArea.loadingRoundDataOptions
      ) {
        cards = previousAllocationRounds.map((round) => ({
          header: getRoundTitle(round, housingRoundDataOptions),
          description: getRoundStatus(round, housingRoundDataOptions),
          iconSrc: '/assets/img/FB_bolig_inaktivrunde.svg',
          details: (
            <div>
              <div
                className="margin border-bottom"
                style={{ marginTop: '11px' }}
              >
                <p>
                  <strong>Antall tildelte boliger:</strong>{' '}
                  {round.AllocationCount}
                </p>
              </div>
              <ButtonRow>
                <ButtonRow.Left onClick={() => setAllocationRound(round, 2)}>
                  Se tildeling
                </ButtonRow.Left>
              </ButtonRow>
            </div>
          ),
        }));
      }
      if (cards.length) {
        return <DetailsCards cards={cards} />;
      }
      return (
        <div className="row">
          <div className="housing-rounds">
            <div className="details-card info-container padding">
              Fant ingen ferdige fordelingsrunder for dette området
            </div>
          </div>
        </div>
      );
    };

    return (
      <div>
        <Loader
          isLoading={
            housingArea.loadingUnprocessedApplicationsCount ||
            loadingHousingAreas
          }
        >
          <div>
            <h2>Forlengelser</h2>
            {renderRenewalDetailsCards(
              housingArea.unprocessedApplicationsCount
            )}
          </div>
        </Loader>
        <Loader
          isLoading={
            housingArea.loadingCurrentAllocationRounds ||
            housingArea.loadingRoundDataOptions ||
            loadingHousingAreas
          }
        >
          <div>
            <h2>Pågående fordelingsrunder</h2>
            {renderCurrentRoundDetailsCards(
              housingArea.housingCurrentAllocationRounds,
              housingArea.housingRoundDataOptions
            )}
          </div>
        </Loader>
        <Loader
          isLoading={
            housingArea.loadingPreviousAllocationRounds ||
            housingArea.loadingRoundDataOptions ||
            loadingHousingAreas
          }
        >
          <div>
            <h2>Ferdige fordelingsrunder</h2>
            {renderPreviousRoundDetailsCards(
              housingArea.housingPreviousAllocationRounds,
              housingArea.housingRoundDataOptions
            )}
          </div>
        </Loader>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Loader
          isLoading={
            housingArea.loadingCurrentAllocationRounds || loadingHousingAreas
          }
        >
          <h1 className="hide-for-ms">{props.housingArea.Name}</h1>
          <p>
            Her kan du opprette en ny fordelingsrunde, følge med på innkomne
            søknader og behandle disse. <br />
            <br /> Åpne søknader kan behandles fortløpende. Ved opprettelse av
            en ny fordelingsrunde vil åpne søknader som ikke er behandlet
            automatisk flyttes til den nye runden.
          </p>
          <div style={{ marginTop: '22px' }}>
            <ButtonRow>
              <ButtonRow.Left
                className="btn grey margin"
                onClick={() => validateAllocationRound()}
              >
                Opprett ny fordelingsrunde
              </ButtonRow.Left>
            </ButtonRow>
          </div>
          {showNewRoundModal && (
            <NewRoundModal
              isOpen={showNewRoundModal}
              setModalIsOpen={setShowNewRoundModal}
              data={props.housingArea}
            />
          )}
          {showOngoingRoundsInfoModal && (
            <InfoModal
              isOpen={showOngoingRoundsInfoModal}
              setModalIsOpen={setShowOngoingRoundsInfoModal}
              title="Kan ikke opprette fordelingsrunde"
              text="Det finnes allerede en pågående fordelingsrunde."
            />
          )}
        </Loader>
      </div>
      {housingRoundOverview()}
    </div>
  );
};

export default HousingApplicationsOverview;
