import Modal from "../Modal/Modal";

type FieldType = {
  name: string;
  type: string;
  label: string;
  options?: string[];
  initialValue?: string;
  disabled?: boolean;
};

type PropsType = {
  title: string;
  fields: FieldType[];
  isOpen: boolean;
  submitText?: string;
  cancelText?: string;
  onClose: () => void;
  submitAction: (subject: string, email: string) => void;
  cancelAction: () => void;
};

const ExportListModal = ({
  title,
  fields = [],
  isOpen,
  submitText = "Send",
  cancelText = "Avbryt",
  onClose,
  submitAction,
  cancelAction,
}: PropsType) => {
  let emailTo;
  let subjectText;
  let fieldItems;

  if (fields.length > 0) {
    fieldItems = fields.map((field) => {
      if (field.type === "select" && field.options) {
        emailTo = field.options[0];
        const fieldOptions = field.options.map((option) => {
          return (
            <option
              value={option}
              key={`${field.label}_${field.options?.indexOf(option)}`}
              onSelect={(e) => (emailTo = e.target)}
            >
              {option}
            </option>
          );
        });
        return (
          <label className="row margin" key={field.label}>
            <span>{field.label}</span>
            <div className="custom-select">
              <select
                id="exportListTo"
                onChange={(e) => (emailTo = e.target.value)}
              >
                {fieldOptions}
              </select>
            </div>
          </label>
        );
      } else if (field.type === "textfield") {
        subjectText = field.initialValue;
        return (
          <label className="row margin" key={field.label}>
            <span>{field.label}</span>
            <input type="text" value={field.initialValue} disabled></input>
          </label>
        );
      }
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      ariaLabel={title}
      submit={{
        text: submitText,
        color: "red",
        onClick: () => {
          submitAction(subjectText, emailTo);
        },
      }}
      cancel={{
        text: cancelText,
        onClick: cancelAction,
      }}
    >
      <header>
        <h2>{title}</h2>
      </header>

      <div>{fieldItems}</div>
    </Modal>
  );
};

export default ExportListModal;
