import _ from "lodash/fp";
import { mapApplicationToCamelCase } from "helpers/housingHelpers";
import {
  HOUSING_REFERENCE_TYPE,
  QUARTER_REFERENCE_TYPE,
  isFavoriteAreaProperty,
} from "helpers/housingAdminHelpers";

export const initialState = {
  referenceType: QUARTER_REFERENCE_TYPE,
  referenceTypes: [
    {
      value: QUARTER_REFERENCE_TYPE,
      label: "Kvarter",
    },
    {
      value: HOUSING_REFERENCE_TYPE,
      label: "Bolig",
    },
  ],
  region: "",
  regions: [],
  housingAreas: [],
  housingArea: {
    Id: "",
    Name: "",
    unprocessedApplicationsCount: 0,
    housingCurrentAllocationRounds: [],
    housingPreviousAllocationRounds: [],
    housingRoundDataOptions: {},
    housingAdminDataOptions: {},
    housingRound: {
      HousingListItems: [],
    },
    loadingRoundDataOptions: true,
    loadingCurrentAllocationRounds: true,
    loadingUnprocessedApplicationsCount: true,
    loadingHousingTexts: true,
    loadingHousingAllocations: true,
    loadingHousingRound: true,
  },
  quarterArea: {
    Id: "",
    Name: "",
    unprocessedApplications: [],
    processedApplications: [],
    availableQuarters: [],
  },
  housingRenewalArea: {
    Id: "",
    Name: "",
    unprocessedApplications: [],
    processedApplications: [],
  },
  loadingHousingAreas: true,
};

export default (state = initialState, action) => {
  const getQuarterApplicationIndex = (applicationId) =>
    state.quarterArea.unprocessedApplications.findIndex(
      (application) => application.Id === applicationId
    );

  const getHousingRenewalApplicationIndex = (applicationId) =>
    state.housingRenewalArea.unprocessedApplications.findIndex(
      (application) => application.Id === applicationId
    );

  const getHousingAreaIndex = (housingAreaId) =>
    state.housingAreas.findIndex((area) => area.Id === housingAreaId);

  switch (action.type) {
    case "SET_HOUSING_ADMIN_REGION": {
      return {
        ...state,
        region: action.region,
      };
    }

    case "TOGGLE_FAVORITE_HOUSING_ADMIN_AREA": {
      const i = getHousingAreaIndex(action.areaId);
      return _.set(`housingAreas[${i}].loadingToggle]`, true, state);
    }

    case "RECEIVE_TOGGLE_FAVORITE_HOUSING_ADMIN_AREA": {
      const i = getHousingAreaIndex(action.areaId);
      const favoriteProp = isFavoriteAreaProperty(action.referenceType);
      return _.flow(
        _.set(`housingAreas[${i}].loadingToggle]`, false),
        _.set(`housingAreas[${i}][${favoriteProp}]`, action.isFavorite)
      )(state);
    }

    case "FETCH_HOUSING_ADMIN_SERVICE_AREAS": {
      return {
        ...state,
        loadingHousingAreas: true,
      };
    }

    case "RECEIVE_HOUSING_ADMIN_SERVICE_AREAS": {
      return {
        ...state,
        loadingHousingAreas: false,
        region: action.data.Regions.length > 0 ? action.data.Regions[0] : "",
        regions: action.data.Regions,
        housingAreas: action.data.HousingAreas,
      };
    }

    case "SET_HOUSING_ADMIN_REFERENCE_TYPE": {
      return {
        ...state,
        referenceType: action.referenceType,
      };
    }

    case "SET_QUARTER_HOUSING_AREA": {
      const housingAreaId = action.housingAreaId;
      const housingArea = state.housingAreas.find(
        (area) => area.Id === housingAreaId
      );

      return {
        ...state,
        quarterArea: {
          ...state.quarterArea,
          ...housingArea,
          unprocessedApplications: [],
        },
      };
    }

    case "SET_HOUSING_RENEWAL_HOUSING_AREA": {
      const housingAreaId = action.housingAreaId;
      const housingArea = state.housingAreas.find(
        (area) => area.Id === housingAreaId
      );

      return {
        ...state,
        housingRenewalArea: {
          ...state.housingRenewalArea,
          ...housingArea,
          unprocessedApplications: [],
        },
      };
    }

    case "FETCH_QUARTER_UNPROCESSED_APPLICATIONS": {
      return {
        ...state,
        quarterArea: {
          ...state.quarterArea,
          loadingUnprocessedApplications: true,
        },
      };
    }

    case "FETCH_HOUSING_UNPROCESSED_APPLICATIONS_COUNT": {
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          loadingUnprocessedApplicationsCount: true,
        },
      };
    }

    case "FETCH_CURRENT_ALLOCATION_ROUNDS": {
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          loadingCurrentAllocationRounds: true,
        },
      };
    }

    case "FETCH_PREVIOUS_ALLOCATION_ROUNDS": {
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          loadingPreviousAllocationRounds: true,
        },
      };
    }

    case "FETCH_HOUSING_RENEWAL_UNPROCESSED_APPLICATIONS": {
      return {
        ...state,
        housingRenewalArea: {
          ...state.housingRenewalArea,
          loadingUnprocessedApplications: true,
        },
      };
    }

    case "FETCH_AVAILABLE_QUARTERS": {
      return {
        ...state,
        quarterArea: {
          ...state.quarterArea,
          loadingAvailableQuarters: true,
        },
      };
    }

    case "RECEIVE_QUARTER_UNPROCESSED_APPLICATIONS":
      return {
        ...state,
        quarterArea: {
          ...state.quarterArea,
          loadingUnprocessedApplications: false,
          unprocessedApplications: action.unprocessedApplications,
        },
      };

    case "RECEIVE_HOUSING_UNPROCESSED_APPLICATIONS_COUNT":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          loadingUnprocessedApplicationsCount: false,
          unprocessedApplicationsCount: action.unprocessedApplicationsCount,
        },
      };

    case "RECEIVE_HOUSING_CURRENT_ALLOCATION_ROUNDS":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          loadingCurrentAllocationRounds: false,
          housingCurrentAllocationRounds: action.currentAllocationRounds,
        },
      };

    case "RECEIVE_HOUSING_PREVIOUS_ALLOCATION_ROUNDS":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          loadingPreviousAllocationRounds: false,
          housingPreviousAllocationRounds: action.previousAllocationRounds,
        },
      };

    case "FETCH_HOUSING_ROUND_DATA_OPTIONS":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          loadingRoundDataOptions: true,
        },
      };

    case "RECEIVE_HOUSING_ROUND_DATA_OPTIONS":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          housingRoundDataOptions: action.housingRoundDataOptions,
          loadingRoundDataOptions: false,
        },
      };

    case "FETCH_HOUSING_ADMIN_DATA_OPTIONS":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          loadingAdminDataOptions: true,
        },
      };

    case "RECEIVE_HOUSING_TEXTS":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          housingTexts: action.housingTexts,
          loadingHousingTexts: false,
        },
      };

    case "RECEIVE_HOUSING_ALLOCATIONS":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          housingAllocations: action.housingAllocations,
          loadingHousingAllocations: false,
        },
      };

    case "FETCH_HOUSING_ROUND":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          loadingHousingRound: true,
        },
      };

    case "RECEIVE_HOUSING_ROUND":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          housingRound: action.housingRound,
          loadingHousingRound: false,
        },
      };

    case "REGISTER_SINGLE_HOUSING_ALLOCATION":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
        },
      };

    case "RECEIVE_REGISTER_HOUSING_ALLOCATIONS":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          registeredHousingAllocations: action.registerAllocations,
        },
      };

    case "SORT_HOUSING_ALLOCATIONS":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          housingAllocations: [...action.sortedItems],
        },
      };

    case "RECEIVE_HOUSING_ADMIN_DATA_OPTIONS":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          housingAdminDataOptions: action.housingAdminDataOptions,
          loadingAdminDataOptions: true,
        },
      };

    case "SET_SELECTED_ALLOCATION_ROUND": {
      let selectedRound =
        state.housingArea.housingCurrentAllocationRounds.filter(
          (round) => round.Round.Id === action.allocationRoundId
        );
      if (!selectedRound.length) {
        selectedRound =
          state.housingArea.housingPreviousAllocationRounds.filter(
            (round) => round.Round.Id === action.allocationRoundId
          );
      }
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          selectedAllocationRound: selectedRound[0],
        },
      };
    }

    case "RECEIVE_HOUSING_RENEWAL_UNPROCESSED_APPLICATIONS":
      return {
        ...state,
        housingRenewalArea: {
          ...state.housingRenewalArea,
          loadingUnprocessedApplications: false,
          unprocessedApplications: action.unprocessedApplications,
        },
      };

    case "RECEIVE_AVAILABLE_QUARTERS":
      return {
        ...state,
        quarterArea: {
          ...state.quarterArea,
          loadingAvailableQuarters: false,
          // $FlowFixMe
          availableQuarters: action.availableQuarters.HousingListItems,
        },
      };

    case "RECEIVE_VACANT_FROM_OFFSET_DAYS_QUARTER":
      return {
        ...state,
        quarterArea: {
          ...state.quarterArea,
          vacantFromStandardOffsetDays: action.offsetDays,
        },
      };

    case "RECEIVE_VACANT_FROM_OFFSET_DAYS_HOUSING":
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          vacantFromStandardOffsetDays: action.offsetDays,
        },
      };

    case "FETCH_QUARTER_PROCESSED_APPLICATIONS": {
      return {
        ...state,
        quarterArea: {
          ...state.quarterArea,
          loadingProcessedApplications: true,
        },
      };
    }

    case "FETCH_HOUSING_RENEWAL_PROCESSED_APPLICATIONS": {
      return {
        ...state,
        housingRenewalArea: {
          ...state.housingRenewalArea,
          loadingProcessedApplications: true,
        },
      };
    }

    case "RECEIVE_QUARTER_PROCESSED_APPLICATIONS": {
      return {
        ...state,
        quarterArea: {
          ...state.quarterArea,
          loadingProcessedApplications: false,
          processedApplications: action.processedApplications,
        },
      };
    }

    case "RECEIVE_HOUSING_RENEWAL_PROCESSED_APPLICATIONS": {
      return {
        ...state,
        housingRenewalArea: {
          ...state.housingRenewalArea,
          loadingProcessedApplications: false,
          processedApplications: action.processedApplications,
        },
      };
    }

    case "SET_HOUSING_AREA": {
      const housingAreaId = action.housingAreaId;
      return {
        ...state,
        housingArea: {
          ...state.housingArea,
          ...state.housingAreas.find((area) => area.Id === housingAreaId),
          registeredHousingAllocations: false,
        },
      };
    }

    case "FETCH_QUARTER_APPLICATION_SUMMARY": {
      const i = getQuarterApplicationIndex(action.applicationId);
      return _.set(
        `quarterArea.unprocessedApplications[${i}].loadingSummary`,
        true,
        state
      );
    }

    case "RECEIVE_QUARTER_APPLICATION_SUMMARY": {
      const i = getQuarterApplicationIndex(action.summary.Id);
      return _.flow(
        _.set(
          `quarterArea.unprocessedApplications[${i}].loadingSummary`,
          false
        ),
        _.set(`quarterArea.unprocessedApplications[${i}].summary`, {
          ...mapApplicationToCamelCase(action.summary),
          type: "quarter",
        })
      )(state);
    }

    case "RECEIVE_HOUSING_RENEWAL_APPLICATION_SUMMARY": {
      const i = getHousingRenewalApplicationIndex(action.summary.Id);
      return _.flow(
        _.set(
          `housingRenewalArea.unprocessedApplications[${i}].loadingSummary`,
          false
        ),
        _.set(`housingRenewalArea.unprocessedApplications[${i}].summary`, {
          ...mapApplicationToCamelCase(action.summary),
          type: "housingRenewalArea",
        })
      )(state);
    }

    case "FETCH_HOUSING_ADMIN_APPLICATIONS_COUNT": {
      return {
        ...state,
        loadingApplicationsCount: true,
      };
    }

    case "RECEIVE_HOUSING_ADMIN_APPLICATION_COUNTS": {
      const favoriteAreas = action.favoriteAreas;
      const housingAreas = state.housingAreas.map((area) => {
        const favoriteArea = favoriteAreas.find(
          (favArea) => favArea.HousingAreaId === area.Id
        );
        if (favoriteArea) {
          return {
            ...area,
            CurrentHousingApplicationsCount:
              favoriteArea.CurrentHousingApplicationsCount +
              (favoriteArea.UnprocessedHousingRenewalApplicationsCount || 0),
            UnprocessedQuarterApplicationsCount:
              favoriteArea.UnprocessedQuarterApplicationsCount,
          };
        }
        return area;
      });

      return {
        ...state,
        loadingApplicationsCount: false,
        housingAreas,
      };
    }

    case "UPDATE_QUARTER_APPLICATION_MESSAGE": {
      const i = getQuarterApplicationIndex(action.applicationId);
      return _.set(
        `quarterArea.unprocessedApplications[${i}].${action.messageKey}`,
        action.message,
        state
      );
    }

    case "UPDATE_HOUSINGRENEWAL_APPLICATION_MESSAGE": {
      const i = getHousingRenewalApplicationIndex(action.applicationId);
      return _.set(
        `housingRenewalArea.unprocessedApplications[${i}].${action.messageKey}`,
        action.message,
        state
      );
    }

    case "SEND_MESSAGE_TO_QUARTER_APPLICANT": {
      const i = getQuarterApplicationIndex(action.applicationId);
      return _.set(
        `quarterArea.unprocessedApplications[${i}].sendingApplicantMessage`,
        true,
        state
      );
    }

    case "RECEIVE_SEND_MESSAGE_TO_QUARTER_APPLICANT": {
      const i = getQuarterApplicationIndex(action.applicationId);
      return _.flow(
        _.set(
          `quarterArea.unprocessedApplications[${i}].UserMessages`,
          action.userMessages
        ),
        _.set(`quarterArea.unprocessedApplications[${i}].applicantMessage`, ""),
        _.set(
          `quarterArea.unprocessedApplications[${i}].sendingApplicantMessage`,
          false
        )
      )(state);
    }

    case "SEND_COMMENT_TO_HOUSING_COORDINATOR": {
      const i = getQuarterApplicationIndex(action.applicationId);
      return _.set(
        `quarterArea.unprocessedApplications[${i}].savingHousingCoordinatorComment`,
        true,
        state
      );
    }

    case "RECEIVE_SEND_COMMENT_TO_HOUSING_COORDINATOR": {
      const i = getQuarterApplicationIndex(action.applicationId);
      return _.set(
        `quarterArea.unprocessedApplications[${i}].savingHousingCoordinatorComment`,
        false,
        state
      );
    }

    case "TOGGLE_QUARTER_APPLICATION_ASSIGNMENT": {
      const i = getQuarterApplicationIndex(action.applicationId);
      return _.set(
        `quarterArea.unprocessedApplications[${i}].loadingAssignmentToggle`,
        true,
        state
      );
    }

    case "RECEIVE_TOGGLE_QUARTER_APPLICATION_ASSIGNMENT": {
      const i = getQuarterApplicationIndex(action.applicationId);
      return _.flow(
        _.set(
          `quarterArea.unprocessedApplications[${i}].loadingAssignmentToggle`,
          false
        ),
        _.set(
          `quarterArea.unprocessedApplications[${i}].IsAssignmentDocumentationReceived`,
          action.assignmentIsOK
        )
      )(state);
    }

    case "TOGGLE_QUARTER_APPLICATION_APPROVED": {
      const i = getQuarterApplicationIndex(action.applicationId);
      return _.set(
        `quarterArea.unprocessedApplications[${i}].loadingApprovedToggle`,
        true,
        state
      );
    }

    case "RECEIVE_TOGGLE_QUARTER_APPLICATION_APPROVED": {
      const i = getQuarterApplicationIndex(action.applicationId);
      return _.flow(
        _.set(
          `quarterArea.unprocessedApplications[${i}].loadingApprovedToggle`,
          false
        ),
        _.set(
          `quarterArea.unprocessedApplications[${i}].IsApproved`,
          action.isApproved
        )
      )(state);
    }

    case "SORT_UNPROCESSED_QUARTER_APPLICATIONS":
      return {
        ...state,
        quarterArea: {
          ...state.quarterArea,
          unprocessedApplications: action.sorted,
        },
      };

    case "SORT_PROCESSED_QUARTER_APPLICATIONS":
      return {
        ...state,
        quarterArea: {
          ...state.quarterArea,
          processedApplications: action.sorted,
        },
      };

    default:
      return state;
  }
};
