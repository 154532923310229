import Loader from "components/Loader/Loader";
import Slide from "components/Slides/Slide";
import { useBooking, useUser } from "contexts/index";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import GuestInfo from "../GuestInfo";

const GuestInfoSlide = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const {
    setItem,
    parentBooking,
    numberOfGuests,
    setNumberOfGuests,
    isBlockBooking,
    arrivalDate,
    setArrivalDate,
    departureDate,
    setDepartureDate,
    selectedLocation,
    setSelectedLocation,
    maxBookingLengthToUse,
    roomInfo,
    setRoomInfo,
    roomInfoPerRoomType,
    setRoomInfoPerRoomType,
  } = useBooking();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    timeout = setTimeout(() => {
      if (!selectedLocation) {
        navigate("/booking");
      }
    }, 10000);

    return () => clearTimeout(timeout);
  }, [selectedLocation]);

  if (!selectedLocation) {
    return <Loader isLoading />;
  }

  return (
    <Slide
      path="guest-info"
      title={`Overnatting på ${selectedLocation.Name}`}
      slideSize="none"
    >
      <GuestInfo
        numberOfGuests={numberOfGuests}
        setNumberOfGuests={setNumberOfGuests}
        arrivalDate={arrivalDate}
        setArrivalDate={setArrivalDate}
        departureDate={departureDate}
        setDepartureDate={setDepartureDate}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        roomInfo={roomInfo}
        setRoomInfo={setRoomInfo}
        isBlockBooking={isBlockBooking}
        parentBooking={parentBooking}
        setItem={setItem}
        roomInfoPerRoomType={roomInfoPerRoomType}
        setRoomInfoPerRoomType={setRoomInfoPerRoomType}
        userData={user}
        maxBookingLength={maxBookingLengthToUse}
      />
    </Slide>
  );
};

export default GuestInfoSlide;
