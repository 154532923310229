import { getFireListValidityInMinutesURL } from 'api/cms/cms_api';
import { getFireListLocationURL, getFireListURL } from 'api/lock/lock_api';
import Loader from 'components/Loader/Loader';
import Spinner from 'components/Spinner/Spinner';
import useAxios from 'hooks/useAxios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { KeyValueType } from 'types/commonTypes';

import doubleRoomIcon from '../../assets/img/dobbeltrom.svg';
import singleRoomIcon from '../../assets/img/enkeltrom.svg';
import FireList from './FireList/FireList';
import FireListHeader from './FireList/FireListHeader';
import FireListModal from './FireList/FireListModal';

export type FireType = {
  guestName: string;
  guestPhone: string;
  checkinStatus: string;
  buildingId: string;
  buildingIdRoomName: string;
  accommodationType: React.ReactElement;
};

export type FilterSet = {
  selectedCheckInStatus: KeyValueType[];
  selectedBuildings: KeyValueType[];
};

const TabFireListPage = () => {
  const { sendRequest } = useAxios();
  const [location, setLocation] = useState('');
  const [fireList, setFireList] = useState<FireType[]>([]);
  const [filteredFireList, setFilteredFireList] = useState<FireType[]>([]);

  const [finalList, setFinalList] = useState<FireType[]>([]);

  const [selectedDate, setSelectedDate] = useState<Date>(moment().toDate());
  const [selectedFilters, setSelectedFilters] = useState<FilterSet>({
    selectedBuildings: [],
    selectedCheckInStatus: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(moment().toDate());
  const [fireListValidityInMinutes, setFireListValidityInMinutes] =
    useState<number>(0);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');

  useEffect(() => {
    fetchLocation();
    fetchFireListValidityTime();
  }, []);

  const sortByBuildingAndRoom = (list) => {
    return list.sort((a, b) => {
      const buildingComparison = a.buildingId.localeCompare(b.buildingId);
      if (buildingComparison === 0) {
        return a.buildingIdRoomName.localeCompare(b.buildingIdRoomName);
      }
      return buildingComparison;
    });
  };

  useEffect(() => {
    setFinalList(sortByBuildingAndRoom(filteredFireList));
  }, [filteredFireList]);

  useEffect(() => {
    setFilteredFireList(fireList);
  }, [fireList]);

  useEffect(() => {
    filterFireList();
  }, [selectedFilters]);

  useEffect(() => {
    fetchFireList();
  }, [selectedDate]);

  const getIcon = (itemValue: number) => {
    const housingIcon = {
      src: '/globalassets/ikoner/AM_bolig-black.svg',
      alt: 'Langtidsovernatting',
    };

    const bookingIcon = {
      src: '/globalassets/ikoner/AM_overnatting-black.svg',
      alt: 'Korttidsovernatting',
    };

    switch (itemValue) {
      case 0:
        return (
          <img
            src={bookingIcon.src}
            alt={bookingIcon.alt}
            style={{ width: '25px', display: 'inline-block' }}
          />
        );
      case 1:
        return (
          <img
            src={housingIcon.src}
            alt={housingIcon.alt}
            style={{ width: '25px', display: 'inline-block' }}
          />
        );
      default:
        return <span>Noe gikk galt</span>;
    }
  };

  const fetchFireList = async () => {
    setIsLoading(true);
    const shortDate = moment(selectedDate).format('YYYY-MM-DD');
    await sendRequest(
      {
        method: 'GET',
        url: getFireListURL,
        params: { currentDate: shortDate },
      },
      (response) => {
        const formattedResponse = response.map((item) => {
          return {
            guestName: item.GuestName,
            guestPhone: item.GuestPhone,
            checkinStatus: item.CheckInStatus,
            buildingId: item.BuildingId,
            buildingIdRoomName: `${item.BuildingId && item.RoomName ? `${item.BuildingId}_${item.RoomName}` : ''}`,
            accommodationType: getIcon(item.AccommodationType),
          } as FireType;
        });
        setFireList(formattedResponse);
      },
    );
    setLastUpdated(moment().toDate());
    setIsLoading(false);
  };

  const fetchLocation = async () => {
    await sendRequest(
      { method: 'GET', url: getFireListLocationURL },
      (response) => {
        setLocation(response.ArenaName);
      },
    );
  };

  const fetchFireListValidityTime = async () => {
    await sendRequest(
      { method: 'GET', url: getFireListValidityInMinutesURL },
      (response) => {
        setFireListValidityInMinutes(response ?? 5);
      },
    );
  };

  const filterFireList = () => {
    const filteredList = fireList
      .filter((fire) => {
        if (
          selectedFilters.selectedCheckInStatus &&
          selectedFilters.selectedCheckInStatus.length != 0
        ) {
          const keyList = selectedFilters.selectedCheckInStatus.map(
            (item) => item.key,
          );
          const valueList = selectedFilters.selectedCheckInStatus.map(
            (item) => item.value,
          );
          return (
            keyList.includes('alle') || valueList.includes(fire.checkinStatus)
          );
        }
        return true;
      })
      .filter((fire) => {
        if (
          selectedFilters.selectedBuildings &&
          selectedFilters.selectedBuildings.length != 0
        ) {
          const keyList = selectedFilters.selectedBuildings.map(
            (item) => item.key,
          );
          const valueList = selectedFilters.selectedBuildings.map(
            (item) => item.value,
          );

          if (fire.buildingId == '') {
            return keyList.includes('alle') || keyList.includes('unset');
          }
          return (
            keyList.includes('alle') || valueList.includes(fire.buildingId)
          );
        }
        return true;
      });
    setFilteredFireList(filteredList);
  };

  const formatDateTime = (date) => {
    return `${new Date(date).toLocaleDateString('no', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })}`;
  };

  const handleSetSelectedFilters = (filters) => {
    setSelectedFilters(filters);
  };
  const handleSetSelectedDate = (date: Date) => {
    setSelectedDate(date);
  };

  const triggerListUpdate = () => {
    setShowModal(false);
    fetchFireList();
    filterFireList();
  };

  const printFireList = async () => {
    setShowModal(false);
    await delay(1000);
    window.print();
  };

  const delay = async (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handlePrint = () => {
    const timeLimitInMinutes = fireListValidityInMinutes;
    const timeDiff = moment().diff(lastUpdated);
    const diffDuration = moment.duration(timeDiff);
    if (!moment().isSame(selectedDate, 'day')) {
      setModalText(`OBS! Brannlisten gjelder ikke for dagens dato.`);
      setShowModal(true);
    } else if (diffDuration.minutes() >= timeLimitInMinutes) {
      setModalText(
        `Brannlisten er eldre enn ${timeLimitInMinutes} minutter. Bekreft ditt valg:`,
      );
      setShowModal(true);
    } else {
      printFireList();
    }
  };

  const returnItem = () => (
    <div className="relative relative-print">
      <h2>
        {`Brannliste`} {location ?? <Spinner />}
      </h2>

      {selectedFilters.selectedBuildings?.length !== 0 &&
      !selectedFilters.selectedBuildings?.find((x) => x.key === 'alle') ? (
        <div
          className="absolute absolute-print"
          style={{
            fontSize: 24,
            fontWeight: 600,
            color: '#c1002b',
            right: 0,
            top: 0,
          }}
        >
          FILTRERT
        </div>
      ) : (
        ''
      )}
      <p>
        Her ser du en liste over alle beboere som oppholder seg på
        befalsforlegning på angitt dato.
      </p>
      <FireListHeader
        setSelectedFilters={handleSetSelectedFilters}
        selectedDate={selectedDate}
        setSelectedDate={handleSetSelectedDate}
        triggerListUpdate={triggerListUpdate}
        lastUpdated={lastUpdated}
        fireList={{
          totalFireList: fireList,
          filteredFireList: filteredFireList,
        }}
        printFireList={handlePrint}
        isLoading={isLoading}
      />
      <Loader isLoading={isLoading}>
        <FireList fireList={finalList} />
      </Loader>
      <FireListModal
        showModal={showModal}
        text={modalText}
        listDate={formatDateTime(selectedDate)}
        triggerListUpdate={triggerListUpdate}
        triggerPrint={printFireList}
        hideUpdateButton={!moment(selectedDate).isSame(moment(), 'day')}
      />
    </div>
  );
  return returnItem();
};

export default TabFireListPage;
