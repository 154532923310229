import {
  registerComplaintForCurrentUserURL,
  registerComplaintURL,
} from "api/basenproff/basenproff_api";
import Input from "components/form/Input/Input";
import TextArea from "components/form/TextArea/TextArea";
import Modal from "components/modals/Modal/Modal";
import { useModal } from "contexts/index";
import useAxios from "hooks/useAxios";
import { createRef, useEffect, useState } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import UnclassifiedConfirmation from "../../form/UnclassifiedConfirmation/UnclassifiedConfirmation";

const ComplaintServiceOrderModal = ({
  openModal,
  setOpenModal,
  SO,
  reporter,
  itemTitle,
  basenProffAccess,
  update = () => {},
}) => {
  const [errors, setErrors] = useState<DeliberateAny>([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [originalInquiry, setOriginalInquiry] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [buttonText, setButtonText] = useState("Bekreft");
  const { requestError, sendRequest } = useAxios();
  const { showModal } = useModal();

  const modalContentRef = createRef<DeliberateAny>();

  useEffect(() => {
    if (SO) {
      setTitle(`Klage: ${SO}`);
      setOriginalInquiry(`${SO}: ${itemTitle}`);
    }
  }, [SO]);

  useEffect(() => {
    if (requestError) {
      showModal({
        title: "Oops",
        infoText: "Noe gikk galt. Prøv igjen senere.",
      });
    }
  }, [requestError]);

  const submit = async () => {
    // check for errors before POST
    const formErrors: DeliberateAny = validateForm();

    if (Object.keys(formErrors).length !== 0) {
      setErrors(formErrors);
      modalContentRef.current.contentElement.scrollTop = 0;
    } else {
      setButtonText("Sender...");

      const complaintInfo = {
        Title: title,
        Description: description,
        "classified-confirmation": checkBox && "classified-confirmation",
        Reporter: reporter,
        ServiceOrderNumber: SO,
      };

      await sendRequest(
        {
          method: "POST",
          url: basenProffAccess
            ? registerComplaintURL
            : registerComplaintForCurrentUserURL,
          data: complaintInfo,
          withCredentials: true,
        },
        () => {
          showModal({
            title: "Takk",
            infoText: "Din henvendelse har blitt registrert.",
          });
          setOpenModal(false);
          if (update) update();
        }
      );
    }
  };

  const validateForm = () => {
    const errorRules = {
      Title: { rule: "required" },
      Description: { rule: "required" },
      IsUnclassified: { rule: "required" },
    };

    const temp: DeliberateAny = {};
    if (!title) temp.title = errorRules.Title;
    if (!description) temp.description = errorRules.Description;
    if (!checkBox) temp.isUnclassified = errorRules.IsUnclassified;
    return temp;
  };

  return (
    <Modal
      submit={{
        text: buttonText,
        onClick: submit,
      }}
      cancel={{ onClick: () => setOpenModal(false) }}
      isOpen={openModal}
      size="large"
      ref={modalContentRef}
      title="Send klage/meld avvik"
      unclassifiedStamp
    >
      <div className="flex-row margin">
        <div>
          <Input
            title="Tittel"
            name="title"
            value={title}
            onChange={(name, value) => setTitle(value)}
            errors={errors}
            className="row trailer"
          />
          <TextArea
            title="Beskrivelse"
            className="textarea--big margin"
            name="description"
            value={description}
            onChange={(name, value) => setDescription(value)}
            errors={errors}
          />
          <Input
            title="Opprinnelig henvendelse"
            name="originalInquiry"
            disabled
            value={originalInquiry}
            className="row trailer"
          />
          <UnclassifiedConfirmation
            checked={checkBox}
            onChange={() => setCheckBox(!checkBox)}
            errors={errors}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ComplaintServiceOrderModal;
