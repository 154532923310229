import {
  resetGuestsFilter,
  setFromDate,
  setToDate,
  updateCheckInStatus,
  updateGuestsFilter,
} from "actions/guardPageActions";
import Button from "components/buttons/Button/Button";
import ButtonRow from "components/buttons/ButtonRow/ButtonRow";
import DateInput from "components/DateInput/DateInput";
import Input from "components/form/Input/Input";
import Select from "components/form/Select/Select";
import connect from "helpers/connectWithRouter";
import { mediaQueries, MediaQuery } from "helpers/mediaQuery";
import moment, { Moment } from "moment";
import { Component } from "react";
import { UnmountClosed } from "react-collapse";
import { DeliberateAny } from "types/DelibrateAny";

import { getGuardPageExcelFileURL } from "../../../api/lock/lock_api";

import type { GuestsFilterType } from "types/guardPageTypes";
type PropsType = {
  guestsFilter: GuestsFilterType;
  updateGuestsFilter: (key: string, value: number | string) => void;
  BookingStatus: DeliberateAny[];
  updateCheckInStatus: (bookingId?: string) => void;
  applySearch: () => void;
  resetGuestsFilter: () => void;
  fromDate: Moment;
  toDate: Moment;
  setFromDate: (moment: Moment) => void;
  setToDate: (moment: Moment) => void;
  small?: boolean;
};

class UnconnectedGuestsFilter extends Component {
  declare props: PropsType;
  state: {
    filterExpanded: boolean;
  };

  constructor(props: PropsType) {
    super(props);

    this.state = {
      filterExpanded: false,
    };
  }

  getFilterString = () => {
    const gf: DeliberateAny = this.props.guestsFilter;
    const dateFormat = "YYYY-MM-DD HH:mm";
    let queryArgs = "?";

    if (gf.name) queryArgs = queryArgs.concat(`Name=${gf.name}&`);
    if (gf.BookingStatus) {
      queryArgs = queryArgs.concat(`BookingStatus=${gf.BookingStatus}&`);
    }
    if (gf.fromDate) {
      queryArgs = queryArgs.concat(
        `FromDate=${moment(gf.fromDate).format(dateFormat)}&`
      );
    }
    if (gf.toDate) {
      queryArgs = queryArgs.concat(
        `ToDate=${moment(gf.toDate).format(dateFormat)}&`
      );
    }
    queryArgs = queryArgs.slice(0, -1);
    return queryArgs;
  };

  handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.applySearch();
    }
  };

  toggleExpandFilter = () => {
    this.setState({
      filterExpanded: !this.state.filterExpanded,
    });
  };

  renderPrimaryInputs = () => (
    <div>
      <div className="flex-row margin">
        <label>
          <span>Navn</span>
          <Input
            onChange={this.props.updateGuestsFilter}
            value={this.props.guestsFilter?.name || ""}
            type="text"
            name="name"
          />
        </label>
        <DateInput
          title="Dato fra"
          name="arrival"
          selectedDate={this.props.fromDate}
          selectionSpan={365}
          small={this.props.small}
          onSelectDate={this.props.setFromDate}
        />
        <DateInput
          title="Dato til"
          name="departure"
          startDate={this.props.fromDate}
          selectedDate={this.props.toDate}
          small={this.props.small}
          maxDurationInDays={60}
          onSelectDate={this.props.setToDate}
        />
        <label>
          <span>Status</span>
          <Select
            onChange={this.props.updateCheckInStatus}
            options={this.props.BookingStatus.map((status) => ({
              key: status.Key,
              value: status.Value,
            }))}
            value={this.props.guestsFilter.BookingStatus}
            name="BookingStatus"
          />
        </label>
      </div>
    </div>
  );

  renderButtons = () => (
    <ButtonRow>
      <ButtonRow.Right onClick={this.props.applySearch} color="red">
        Søk
      </ButtonRow.Right>
      <ButtonRow.Right onClick={this.props.resetGuestsFilter} color="grey">
        Tøm filter
      </ButtonRow.Right>
      <ButtonRow.Right
        className="btn grey"
        href={`${getGuardPageExcelFileURL}${this.getFilterString()}`}
        download
      >
        Last ned
      </ButtonRow.Right>
    </ButtonRow>
  );

  render() {
    return (
      <div onKeyPress={this.handleOnKeyPress}>
        <MediaQuery query={mediaQueries.mediumUp}>
          <div className="content-group padded grey">
            {this.renderPrimaryInputs()}
            {this.renderButtons()}
          </div>
        </MediaQuery>

        <MediaQuery query={mediaQueries.small}>
          <header className={`${this.state.filterExpanded ? "margin" : ""}`}>
            <Button color="light-grey" onClick={this.toggleExpandFilter}>
              Filtrer
            </Button>
          </header>
          <UnmountClosed isOpened={this.state.filterExpanded}>
            <div className="content-group padded grey">
              {this.renderPrimaryInputs()}
              {this.renderButtons()}
            </div>
          </UnmountClosed>
        </MediaQuery>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    guestsFilter: state.guardPage.search.filter,
    fromDate: state.guardPage.search.filter.fromDate,
    toDate: state.guardPage.search.filter.toDate,
    BookingStatus: state.guardPage.search.checkInStatusFilter,
    isLoading: state.guardPage.search.isLoading,
  }),
  {
    updateGuestsFilter,
    updateCheckInStatus,
    resetGuestsFilter,
    setFromDate,
    setToDate,
  }
)(UnconnectedGuestsFilter);
