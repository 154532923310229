import Loader from 'components/Loader/Loader';
import { useUser } from 'contexts/index';

import NoAuthReporting from './NoAuthReporting';
import ReportingSection from './ReportingSection';

const ReportingSlides = () => {
  const { isLoading, hasUser } = useUser();

  if (!hasUser)
    return (
      <Loader isLoading={isLoading}>
        <NoAuthReporting />
      </Loader>
    );

  return <ReportingSection />;
};

export default ReportingSlides;
