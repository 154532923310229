import './TabLocationQR.scss';

import { getArenaCheckInKeyURL } from 'api/booking/booking_api';
import env from 'env';
import useAxios from 'hooks/useAxios';
import { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Link } from 'react-router-dom';

import LocationSelector from './LocationSelector';

const TabLocationQRCodes = () => {
  const { sendRequest } = useAxios();
  const [locationInput, setLocationInput] = useState({ name: '', id: '' });
  const [arenaCheckInKey, setArenaCheckInKey] = useState('');

  const handleOnSelect = (id: string) => {
    fetchArenaCheckInKey(id);
  };

  const fetchArenaCheckInKey = async (locationId: string) => {
    await sendRequest(
      {
        method: 'GET',
        url: getArenaCheckInKeyURL + locationId,
      },
      (response) => {
        setArenaCheckInKey(response.key);
      },
    );
  };

  const locationQRCode = () => {
    const url = `${env.BASE_URL}/#/innsjekk/${arenaCheckInKey}`;
    const localurl = `/innsjekk/${arenaCheckInKey}`;
    return (
      <div className="qr-container">
        <h1 className="print-only">Innsjekk {locationInput.name}</h1>
        <QRCode value={url} size={512}></QRCode>
        <Link className="no-print" to={localurl}>
          Innsjekk: {locationInput.name}
        </Link>
      </div>
    );
  };

  const render = () => {
    return (
      <div className="tab-qr">
        <LocationSelector
          className="no-print"
          setLocationInput={setLocationInput}
          handleOnSelect={handleOnSelect}
        />
        {locationInput.id && <div>{locationQRCode()}</div>}
      </div>
    );
  };

  return render();
};

export default TabLocationQRCodes;
