import "./PlanDetails.scss";

import ImageCarousel from "components/ImageCarousel/ImageCarousel";
import ProgressTracker from "components/ProgressTracker/ProgressTracker";
import moment from "moment";
// @flow
import React, { Component } from "react";

import type { PlanItemType } from "types/planTypes";
type PropsType = {
  plan: PlanItemType;
  togglePlanFavorite: (item: PlanItemType) => void;
};

class PlanDetails extends Component {
  declare props: PropsType;

  toggleFavorite = () => {
    this.props.togglePlanFavorite(this.props.plan);
  };

  render() {
    return (
      <div className="scrollable">
        <div className="content">
          <div className="plan-details">
            <div className="vertical-padding">
              <h1> {this.props.plan.ShortHeading} </h1>
            </div>

            <div className="margin">
              <ImageCarousel
                fixedImageHeight
                images={this.props.plan.PlanImages}
              />
            </div>

            <div className="margin">
              <ProgressTracker
                currentStatus={parseInt(this.props.plan.StateKey, 10)}
                currentProgress={parseInt(this.props.plan.ProgressKey, 10)}
                previousStatus={parseInt(this.props.plan.PreviousStateKey, 10)}
              />
            </div>

            {this.props.plan.Comment && (
              <div className="plan-details__comment">
                <p>
                  <strong>Kommentar:</strong>
                </p>
                {this.props.plan.Comment}
              </div>
            )}

            <div className="plan-details-wrapper vertical-padding">
              <div className="main-info">
                <p>
                  <strong> {this.props.plan.MainIntro} </strong>
                </p>
                <div>
                  <p>{this.props.plan.MainBody}</p>
                  <span className="last-updated">
                    Sist oppdatert kl{" "}
                    {moment(this.props.plan.Changed).format("DD.MM.YY")} av{" "}
                    {this.props.plan.ChangedBy}
                  </span>
                </div>
              </div>

              <div className="sidebar">
                <div
                  onClick={this.toggleFavorite}
                  className={` \
                    follow-plan follow-plan--border \
                    ${
                      this.props.plan.UserExtra.IsFavorite
                        ? "follow-plan--is-favorite"
                        : ""
                    } \
                  `}
                >
                  <span className="follow-plan__star" />
                  <span>
                    {this.props.plan.UserExtra.IsFavorite
                      ? "Følger prosjekt"
                      : "Følg prosjekt"}
                  </span>
                </div>
                <div className="key-info-wrapper">
                  <h3>Nøkkelinformasjon</h3>
                  <div className="key-info">
                    <div>
                      <span>
                        <strong> Hvor: </strong>
                      </span>
                      <span>{this.props.plan.EstablishmentName}</span>
                    </div>
                    <div>
                      <span>
                        <strong> Bygg: </strong>
                      </span>
                      <span>{this.props.plan.Building} </span>
                    </div>
                    <div>
                      <span>
                        <strong> Inventarnummer: </strong>
                      </span>
                      <span>{this.props.plan.InventoryNumber} </span>
                    </div>
                    <div>
                      <span>
                        <strong> Planlagt oppstart: </strong>
                      </span>
                      <span>
                        {moment(this.props.plan.ScheduledStart).format(
                          "DD.MM.YY"
                        )}
                      </span>
                    </div>
                    <div>
                      <span>
                        <strong> Planlagt sluttdato: </strong>
                      </span>
                      <span>
                        {moment(this.props.plan.ScheduledCompletion).format(
                          "DD.MM.YY"
                        )}
                      </span>
                    </div>

                    {this.props.plan.Budget && (
                      <div>
                        <span>
                          <strong> Kostnadsramme: </strong>
                        </span>
                        <span>
                          {/* TODO : FIX THIS */}
                          {/* {FB.Helpers.formatCurrency(this.props.plan.Budget)} kr */}
                        </span>
                      </div>
                    )}

                    <div>
                      <span>
                        <strong> Kontaktperson: </strong>
                      </span>
                      <span className="capitalize">
                        {this.props.plan.Contact.toLowerCase()}
                      </span>
                    </div>
                    <div>
                      <span>
                        <strong> Servicesenteret: </strong>
                      </span>
                      <span>468 70 400 </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlanDetails;
