import logo from 'images/logo.svg';
import { useLocation, useUpdateEffect } from 'react-use';

const Header = () => {
  const location = useLocation();

  const toggle = (value?: boolean) => {
    if (value) document.body.classList.add('menu-active');
    else if (value === false) document.body.classList.remove('menu-active');
    else {
      const currentValue =
        document.body.classList.value.includes('menu-active');

      if (currentValue) document.body.classList.remove('menu-active');
      else document.body.classList.add('menu-active');
    }
  };

  useUpdateEffect(() => {
    toggle(false);
  }, [location]);

  return (
    <header className="main-header js-main-header">
      <div
        onClick={() => toggle()}
        tabIndex={0}
        aria-label="Vis/skjul meny"
        role="button"
        aria-pressed="false"
        className="menu-toggle js-menu-toggle"
      >
        <div className="menu-toggle-wrapper">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <a title="Til startside" href="#">
        <img className="logo" src={logo} alt="Logo" />
      </a>
    </header>
  );
};

export default Header;
