import { DeliberateAny } from "../../../types/DelibrateAny";
import Checkbox from "../Checkbox/Checkbox";

type PropsType = {
  checked?: boolean;
  onChange?: (name: string, value: boolean) => void;
  errors?: DeliberateAny[];
};

const UnclassifiedConfirmation = (props: PropsType) => {
  const description = (
    <p>
      <br />
      Dersom du har gradert (B) tilleggsinformasjon til din bestilling må du
      sende dette på FISBasis til epostadresse{" "}
      <strong> FORSVARSBYGG SERVICESENTER </strong>. Husk å referere til
      serviceordrenummer. Saker som i sin helhet er gradert (B) skal kun sendes
      på FISBasis.
    </p>
  );

  return (
    <div className="content-group grey padded">
      <Checkbox
        {...props}
        type="checkbox"
        name="isUnclassified"
        label="Jeg bekrefter at henvendelsen ikke inneholder gradert informasjon."
        description={description}
      />
    </div>
  );
};
export default UnclassifiedConfirmation;
