import { DeliberateAny } from "../types/DelibrateAny";

export function updateResultsBySorting(value: string, label: string) {
  return { type: "UPDATE_RESULTS_BY_SORTING", value, label };
}

export function updateReservationFilter(key: string, value: DeliberateAny) {
  return { type: "UPDATE_RESERVATION_FILTER", key, value };
}

export function fetchReservationSearch(filter: DeliberateAny) {
  return { type: "FETCH_RESERVATION_FILTER", filter };
}

export function fetchLocationReservation() {
  return { type: "FETCH_RESERVATION_LOCATION" };
}

export function fetchbookeeProfileById(value: DeliberateAny) {
  return { type: "FETCH_RESERVATION_BOOKEEID", value };
}

export function fetchAuditEntries(value: DeliberateAny) {
  return { type: "FETCH_AUDIT_ENDTRIES", value };
}
