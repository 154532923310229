// @flow

import {
  fetchPortfolioFavorites,
  sortPortfolioFavorites,
  updatePortfolioFavoritesMetaData,
} from "actions/myPortfolioActions";
import ButtonRow from "components/buttons/ButtonRow/ButtonRow";
import Loader from "components/Loader/Loader";
import connect from "helpers/connectWithRouter";
import React, { Component } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import PortfolioList from './PortfolioList/PortfolioList';

import type {
  PortfolioItemType,
  PortfolioFavoritesType,
} from "types/myPortfolioTypes";
type PropsType = {
  favorites: PortfolioFavoritesType;
  fetchPortfolioFavorites: () => void;
  navigateToPortfolioSearch: () => void;
  navigateToViewBuilding: () => void;
  sortPortfolioFavorites: (sortedItems: Array<PortfolioItemType>) => void;
  updatePortfolioFavoritesMetaData: (
    key: string,
    value: number | boolean | string
  ) => void;
  path: string;
  location: Location;
};

export class UnconnectedMyPortfolio extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    // this.props.location.pathname = this.props.path;
    this.props.fetchPortfolioFavorites();
  }

  // componentDidUpdate(prevProps) {
  //   this.props.match.url = this.props.path;
  //   // if (this.props.favorites.items !== prevProps.favorites.items) {
  //     this.props.fetchPortfolioFavorites();
  //   // }
  // }

  onSortColumn = (columnName: string, sortOrderAscending: boolean) => {
    this.props.updatePortfolioFavoritesMetaData("sortByColumn", columnName);
    this.props.updatePortfolioFavoritesMetaData(
      "sortOrderAscending",
      sortOrderAscending
    );
  };

  setPageCount = (pageCount: number) => {
    this.props.updatePortfolioFavoritesMetaData("pageCount", pageCount);
  };

  changePageNumber = (pageNumber: number) => {
    this.props.updatePortfolioFavoritesMetaData("pageNumber", pageNumber);
  };

  renderMyBuildings = () => (
    <PortfolioList
      key="2"
      items={this.props.favorites.items}
      customDetailsButton={{
        onClick: this.props.navigateToViewBuilding,
        icon: "arrow-right",
        elementType: "a",
      }}
      uncontrolledSortOptions={{
        onSortList: this.props.sortPortfolioFavorites,
        sortColumnOnMount: "Name",
      }}
      clientPaging={{
        onChangePageNumber: this.changePageNumber,
        onSetPageCount: this.setPageCount,
        numItems: this.props.favorites.items.length,
        pageSize: this.props.favorites.pageSize,
        pageNumber: this.props.favorites.pageNumber,
        pageCount: this.props.favorites.pageCount,
      }}
      noItemsMessage={
        <div>
          <p>
            Du har ikke lagt til noen bygninger eller uteareal i listen din.
          </p>
          <button onClick={this.props.navigateToPortfolioSearch}>
            <a>Søk etter dine bygninger og utearealer her.</a>
          </button>
        </div>
      }
    />
  );

  render() {
    return (
      <div>
        <div className="row">
          <div className="margin">
            <div className="flex-row">
              <h2 className="no-margin">Min portefølje</h2>
              <ButtonRow>
                <ButtonRow.Right
                  color="red"
                  onClick={this.props.navigateToPortfolioSearch}
                >
                  Legg til bygning
                </ButtonRow.Right>
              </ButtonRow>
            </div>
          </div>
          <Loader isLoading={this.props.favorites.isLoading}>
            {this.renderMyBuildings()}
          </Loader>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    favorites: state.myPortfolio.favorites,
  }),
  {
    fetchPortfolioFavorites,
    sortPortfolioFavorites,
    updatePortfolioFavoritesMetaData,
  }
)(UnconnectedMyPortfolio);
