import FBHelpers from 'helpers/_helpers';
import {
  blockBookingIsFull,
  getBookingState,
  getConfirmedState,
  getDeadlineForPayment,
  getPaymentMessage,
  invoiceMessage,
  isBlockBooking,
  numberOfBookedRoomsInSubBookings,
  numberOfBookedSharedBedsInSubBookings,
  numberOfSharedRoomBedsInBooking,
  onlinePayment,
  payByInvoice,
  paymentFailed,
  salaryDeduction,
  successfulStay,
  totalRoomCountInBlockBooking,
  transactionStatus,
} from 'helpers/bookingHelpers';

import * as bookingConstants from '../../../constants/bookingEnums';

export const getActiveMessages = (bookingData) => {
  let bookingStatus = '';
  let paymentMessage = '';
  let cls = '';

  if (
    bookingData.RequestState === bookingConstants.BookingStates.ROOMRESERVATION
  ) {
    // If rooms have been reserved in VisBook, but credit card isn't registered.
    // This status is only active for 14min before booking is automatically cancelled.
    paymentMessage = 'Registrer kort';
    bookingStatus =
      'Din bestilling mangler registrert betalingskort. Registrer kortinfo innen ';

    cls = 'text-decoration-inline-warning';
  } else if (
    bookingData.RequestState === bookingConstants.BookingStates.CONFIRMED
  ) {
    bookingStatus = getConfirmedState(bookingData);
    paymentMessage = getPaymentMessage(bookingData);
  } else {
    bookingStatus = getBookingState(bookingData);
    paymentMessage = getPaymentMessage(bookingData);
  }

  return {
    bookingStatus,
    paymentMessage,
    cls,
  };
};

export const getHistoricalMessages = (bookingData) => {
  let bookingStatus = '';
  let paymentMessage = '';
  const defaultMessage = 'Ukjent betalingsmåte';
  const cls = '';

  if (payByInvoice(bookingData.PaymentInfo)) {
    paymentMessage = invoiceMessage(bookingData);
  } else if (successfulStay(bookingData)) {
    bookingStatus = 'Oppholdet er gjennomført. Takk for besøket!';
    if (onlinePayment(bookingData.PaymentInfo)) {
      paymentMessage = transactionStatus(
        bookingData.PaymentTransaction.TransactionEvent,
      );
    }
  } else if (
    bookingData.RequestState === bookingConstants.BookingStates.NOSHOW
  ) {
    bookingStatus = 'Du dukket ikke opp.';
    paymentMessage = transactionStatus(
      bookingData.PaymentTransaction.TransactionEvent,
    );
  } else if (
    bookingData.RequestState === bookingConstants.BookingStates.CANCELLED
  ) {
    bookingStatus = 'Kansellert.';
  } else if (
    bookingData.PaymentInfo.PaymentMethod ===
    bookingConstants.PaymentMethods.PAYATARRIVAL
  ) {
    paymentMessage = 'Ved ankomst.';
  } else if (
    paymentFailed(bookingData) &&
    bookingData.PaymentInfo.PaymentMethod ===
      bookingConstants.PaymentMethods.PAYBYCARD
  ) {
    bookingStatus = 'Du skylder penger.';
    if (
      bookingData.PaymentInfo.PaymentState ===
      bookingConstants.PaymentStates.NOTPAID
    ) {
      paymentMessage = 'Legg inn nytt kort.';
    }
  } else if (salaryDeduction(bookingData.PaymentInfo)) {
    paymentMessage = 'Beløpet vil belastes i form av lønnstrekk.';
  } else {
    paymentMessage = defaultMessage;
  }

  return {
    bookingStatus,
    paymentMessage,
    cls,
  };
};

export const getBlockBookingTextAndClass = (bookingData) => {
  const deadlineSoon = (span) => {
    const days = FBHelpers.Date.daysBetweenDates(
      new Date(),
      bookingData.ArrivalDate,
    );
    return days > 0 && Math.abs(days) <= (span || 14);
  };

  let bookingStatus = '';
  let cls = '';
  const paymentMessage = getPaymentMessage(bookingData);

  const numberOfRoomsAndBeds = totalRoomCountInBlockBooking(bookingData);
  const bedsInSharedRooms = numberOfSharedRoomBedsInBooking(bookingData);
  const bookedSharedBeds = numberOfBookedSharedBedsInSubBookings(
    bookingData.SubBookings,
  );
  const bookedRooms = numberOfBookedRoomsInSubBookings(bookingData.SubBookings);

  const numberOfRooms = numberOfRoomsAndBeds - bedsInSharedRooms;

  if (bedsInSharedRooms) {
    bookingStatus = `${bookedSharedBeds} av ${bedsInSharedRooms} senger i flermannsrom reservert <br/> ${bookedRooms} av ${numberOfRooms} rom reservert.`;
  } else {
    bookingStatus = `${bookedRooms} av ${numberOfRooms} rom reservert.`;
  }

  if (
    deadlineSoon(getDeadlineForPayment()) &&
    !bookingData.GroupInfo.KeepRooms &&
    !blockBookingIsFull(bookingData)
  ) {
    bookingStatus +=
      ' Legg inn fakturainfo før kl. 24:00 for å beholde alle rom.';
    cls = 'text-decoration-inline-warning';
  }

  if (bookingData.RequestState === bookingConstants.BookingStates.CANCELLED) {
    bookingStatus = 'Booking avbestilt';
    cls = 'text-decoration-inline-warning';
  }

  return {
    bookingStatus,
    paymentMessage,
    cls,
  };
};

export const getMessages = (bookingData) => {
  let obj = {
    bookingStatus: '',
    paymentMessage: '',
    cls: '',
  };
  if (isBlockBooking(bookingData)) {
    obj = getBlockBookingTextAndClass(bookingData);
  } else {
    const dateToday = new Date();
    const bookingDepartureDate = new Date(bookingData.DepartureDate);
    obj =
      dateToday > bookingDepartureDate
        ? getHistoricalMessages(bookingData)
        : getActiveMessages(bookingData);
  }
  return {
    bookingStatus: obj.bookingStatus,
    paymentMessage: obj.paymentMessage,
    cls: obj.cls,
  };
};
