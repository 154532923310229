/* eslint react/prop-types: 0 */

import {
  searchRentalURL,
  staticFilterOptionsURL,
} from 'api/basenproff/basenproff_api';
import Loader from 'components/Loader/Loader';
import ExportList from 'components/serviceorders/ServiceOrderExportList';
import ServiceOrderList from 'components/serviceorders/ServiceOrderList';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

const RelatedServiceOrdersList = ({ rentalId, typeFilter, stateFilter }) => {
  const { sendRequest: sendOptionsRequest } = useAxios();
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState<DeliberateAny>();
  const [skipCount, setSkipCount] = useState(0);

  const { requestLoading, sendRequest } = useAxios();

  const takeCount = 5;

  const fetchRelatedServiceOrders = async () => {
    await sendRequest(
      {
        method: 'POST',
        url: searchRentalURL,
        data: {
          typeFilter,
          stateFilter,
          rentalId,
          takeCount,
          skipCount,
        },
      },
      (res) => {
        const rItems = res.map((r) => {
          const soStatePrintable =
            options?.ServiceOrderStates.length > 0
              ? options?.ServiceOrderStates.find(
                  (o) => o.Key === r.ServiceOrderState,
                ).Value
              : '';
          return {
            ...r,
            ServiceOrderStatePrintable: soStatePrintable,
          };
        });
        setItems(rItems);
      },
    );
  };

  useEffect(() => {
    if (skipCount) void fetchRelatedServiceOrders();
  }, [skipCount]);

  useEffect(() => {
    if (options) void fetchRelatedServiceOrders();
  }, [rentalId, options]);

  const fetchOptions = async () => {
    await sendOptionsRequest(
      {
        method: 'GET',
        url: staticFilterOptionsURL,
        withCredentials: true,
      },
      setOptions,
    );
  };

  useEffect(() => {
    void fetchOptions();
  }, []);

  const navigateToPage = (pagenum) => {
    setSkipCount((pagenum - 1) * takeCount);
  };

  const serverPaging = {
    pageNumber: 0,
    pageSize: 0,
    onChangePageNumber: function (number: any): void {
      navigateToPage(number);
    },
    numItems: 0,
  };

  return (
    <Loader isLoading={requestLoading}>
      <div className="button-row half-margin">
        <p className="no-margin">
          <ExportList items={items} />
        </p>
      </div>
      <ServiceOrderList items={items} serverPaging={serverPaging} />
    </Loader>
  );
};
export default RelatedServiceOrdersList;
