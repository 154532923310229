import FeatureRoute, { FeatureEnum } from 'components/FeatureRoute/FeatureRoute';
import PaymentProviderCallback from 'components/ModalComponents/PaymentProviderCallback';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import Slide from 'components/Slides/Slide';
import { BookingContextProvider } from 'contexts/BookingContext';
import { NewBookingContextProvider } from 'contexts/BookingContextNew';
import { HousingQuarter } from 'contexts/HousingContext';
import { MyPageContextProvider } from 'contexts/MyPageContext';
import { SlidesContextProvider } from 'contexts/SlidesContext';
import { ToastContextProvider } from 'contexts/ToastContext';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { isDev } from 'utils/helpers';

import Sandbox from './components/Sandbox/Sandbox';
import { useUser } from './contexts';
import Login from './modules/Auth/Login';
import Booking from './modules/Booking';
import LimitedBookingReceipt from './modules/Booking/LimitedBookingReceipt';
import ArrivalInfo from './modules/Information/ArrivalInfo';
import BookingTerms from './modules/Information/BookingTerms';
import Information from './modules/Information/Information';
import LocationInfo from './modules/Information/LocationInfo';
import NewMyPage from './modules/MyPage';
import MyBooking from './modules/MyPage/MyBooking';
import MyBookings from './modules/MyPage/MyBookings';
import Payment from './modules/Payment/Payment';
import BasenProff from './pages/BasenProff/BasenProff';
import TabAdditionalServices from './pages/BasenProff/TabAdditionalServices/TabAdditionalServices';
import TabDashboard from './pages/BasenProff/TabDashboard/TabDashboard';
import TabMyPortfolio from './pages/BasenProff/TabMyPortfolio/TabMyPortfolio';
import TabServiceOrders from './pages/BasenProff/TabServiceOrders/TabServiceOrders';
import BookingSlides from './pages/Booking/BookingSlides';
import ConfirmationInfoSlide from './pages/Booking/Slides/ConfirmationInfoSlide';
import GuestInfoSlide from './pages/Booking/Slides/GuestInfoSlide';
import LocationInfoSlide from './pages/Booking/Slides/LocationInfoSlide';
import PaymentInfoSlide from './pages/Booking/Slides/PaymentInfoSlide';
import BookingReceipt from './pages/BookingReceipt';
import Contact from './pages/Contact/Contact';
import Documents from './pages/Documents/Documents';
import FireAndArrivalPage from './pages/Guardpage/FireAndArrivalPage';
import Guardpage from './pages/Guardpage/Guardpage';
import TabArrivalListPage from './pages/Guardpage/TabArrivalListPage';
import TabFireListPage from './pages/Guardpage/TabFireListPage';
import TabGuardPage from './pages/Guardpage/TabGuardPage';
import Housing from './pages/Housing/Housing';
import HousingSection, { housing, paths, quarter } from './pages/Housing/HousingSection';
import ApplicantCategorySlide from './pages/Housing/Slides/ApplicantCategorySlide';
import ApplicationSummarySlide from './pages/Housing/Slides/ApplicationSummarySlide';
import FamilySituationSlide from './pages/Housing/Slides/FamilySituationSlide';
import LivingConditionSlide from './pages/Housing/Slides/LivingConditionSlide';
import PersonalInfoSlide from './pages/Housing/Slides/PersonalInfoSlide';
import SelectLocationSlide from './pages/Housing/Slides/SelectLocationSlide';
import HousingAdmin from './pages/HousingAdmin/HousingAdmin';
import HousingAdminSection, { HousingSlides } from './pages/HousingAdmin/HousingAdminSection';
import InformationPage from './pages/Information/Information';
import OldInformationPage from './pages/Information/OldInformation';
import InspectionSection from './pages/Inspection/InspectionSection';
import L3 from './pages/L3/L3';
import Landing from './pages/Landing';
import Oops from './pages/Landing/Oops';
import MyPage from './pages/MyPage/MyPage';
import TabLocationQRCodes from './pages/MyPage/TabLocationQRCodes/TabLocationQRCodes';
import TabMyBookings from './pages/MyPage/TabMyBookings/TabMyBookings';
import TabMyHousingApplications from './pages/MyPage/TabMyHousingApplications/TabMyHousingApplications';
import MyNotifications from './pages/MyPage/TabMyNotifications/MyNotifications';
import TabMyProfile from './pages/MyPage/TabMyProfile/TabMyProfile';
import MyRentals from './pages/MyPage/TabMyRentals/MyRentals';
import TabMyServiceOrders from './pages/MyPage/TabMyServiceOrders/TabMyServiceOrders';
import NotFound from './pages/NotFound';
import Privacy from './pages/Privacy/Privacy';
import Confirmation from './pages/Reporting/Confirmation';
import ReportingSlides from './pages/Reporting/ReportingSlides';
import ExtraServicesListSlide from './pages/Reporting/ReportingSlides/ExtraServicesListSlide';
import FaultFormSlide from './pages/Reporting/ReportingSlides/FaultFormSlide';
import StartPageSlide from './pages/Reporting/ReportingSlides/StartPageSlide';
import Reservations from './pages/Reservations/Reservations';
import UnnamedBooking from './pages/UnnamedBooking/UnnamedBooking';

const AppContainer = () => {
  const { user } = useUser();

  // BasenProff states
  const [portfolioSearch, setPortfolioSearch] = useState(false);
  const [viewBuilding, setViewBuildning] = useState(false);

  return (
    <Routes>
      <Route
        path="/my-page"
        element={
          <ProtectedRoute>
            <MyPage />
          </ProtectedRoute>
        }
      >
        <Route path="/my-page" element={<TabMyProfile userData={user} />} />
        <Route
          path="/my-page/tab-my-profile"
          element={<TabMyProfile userData={user} />}
        />
        <Route
          path="/my-page/tab-my-service-orders"
          element={<TabMyServiceOrders />}
        />
        <Route path="/my-page/tab-my-bookings" element={<TabMyBookings />} />
        <Route
          path="/my-page/tab-my-housing-applications"
          element={<TabMyHousingApplications />}
        />
        <Route path="/my-page/tab-my-rentals/*" element={<MyRentals />} />
        <Route
          path="/my-page/tab-location-qr-codes"
          element={<TabLocationQRCodes />}
        />
        <Route
          path="/my-page/tab-my-notifications"
          element={<MyNotifications />}
        />
      </Route>
      <Route
        path="/limited/booking/:bookingId/receipt"
        element={<LimitedBookingReceipt />}
      />
      <Route
        path="/mypage"
        element={
          <FeatureRoute feature={FeatureEnum.BookingV2}>
            <ProtectedRoute>
              <ToastContextProvider>
                <MyPageContextProvider>
                  <NewMyPage />
                </MyPageContextProvider>
              </ToastContextProvider>
            </ProtectedRoute>
          </FeatureRoute>
        }
      >
        <Route path="/mypage/booking" element={<MyBookings />} />
        <Route path="/mypage/booking/:bookingid" element={<MyBooking />} />
        <Route
          path="/mypage/booking/:bookingid/receipt"
          element={<MyBooking />}
        />
      </Route>
      <Route
        path="/basen-proff"
        element={
          <ProtectedRoute>
            <BasenProff
              portfolioSearch={portfolioSearch}
              viewBuilding={viewBuilding}
              setPortfolioSearch={setPortfolioSearch}
              setViewBuildning={setViewBuildning}
            />
          </ProtectedRoute>
        }
      >
        <Route path="/basen-proff" element={<TabDashboard />} />
        <Route path="/basen-proff/tab-dashboard" element={<TabDashboard />} />
        <Route
          path="/basen-proff/tab-service-orders"
          element={<TabServiceOrders />}
        />
        <Route
          path="/basen-proff/tab-my-portfolio/*"
          element={
            <TabMyPortfolio
              setPortfolioSearch={setPortfolioSearch}
              setViewBuildning={setViewBuildning}
            />
          }
        />
        <Route
          path="/basen-proff/tab-additionalservices/*"
          element={<TabAdditionalServices />}
        />
      </Route>
      <Route
        path="/boligsekretær"
        element={
          <ProtectedRoute>
            <SlidesContextProvider baseUrl="boligsekretær">
              <HousingAdminSection />
            </SlidesContextProvider>
          </ProtectedRoute>
        }
      >
        <Route index element={<HousingAdmin />} />
        <Route
          path="start/:modal?"
          element={
            <Slide path="start">
              <HousingAdmin />
            </Slide>
          }
        />
        {Object.entries(HousingSlides).map(({ [0]: i, [1]: x }) => {
          const HouseSlide = x.Element;
          return (
            <Route
              key={i}
              path={x.Path}
              element={
                <Slide path={x.Path} title={x.ApplicationsTitle}>
                  <HouseSlide name={x.ApplicationsTitle} />
                </Slide>
              }
            />
          );
        })}
      </Route>
      <Route
        path="/forsvarsbolig"
        element={
          <ProtectedRoute>
            <SlidesContextProvider baseUrl="forsvarsbolig">
              <HousingSection />
            </SlidesContextProvider>
          </ProtectedRoute>
        }
      >
        <Route index element={<Housing />} />

        {/* HOUSING SLIDES */}
        <Route
          path={`${housing}${paths.applicantCategory}`}
          element={<ApplicantCategorySlide appType={HousingQuarter.Housing} />}
        />
        <Route
          path={`${housing}${paths.personalInfo}`}
          element={<PersonalInfoSlide appType={HousingQuarter.Housing} />}
        />
        <Route
          path={`${housing}${paths.familySituation}`}
          element={<FamilySituationSlide appType={HousingQuarter.Housing} />}
        />
        <Route
          path={`${housing}${paths.livingCondition}`}
          element={<LivingConditionSlide appType={HousingQuarter.Housing} />}
        />
        <Route
          path={`${housing}${paths.summary}`}
          element={<ApplicationSummarySlide appType={HousingQuarter.Housing} />}
        />
        <Route
          path={`${housing}${paths.serviceLocation}`}
          element={<SelectLocationSlide appType={HousingQuarter.Housing} />}
        />

        {/* QUARTER SLIDES */}
        <Route
          path={`${quarter}${paths.applicantCategory}`}
          element={<ApplicantCategorySlide appType={HousingQuarter.Quarter} />}
        />
        <Route
          path={`${quarter}${paths.personalInfo}`}
          element={<PersonalInfoSlide appType={HousingQuarter.Quarter} />}
        />
        <Route
          path={`${quarter}${paths.familySituation}`}
          element={<FamilySituationSlide appType={HousingQuarter.Quarter} />}
        />
        <Route
          path={`${quarter}${paths.livingCondition}`}
          element={<LivingConditionSlide appType={HousingQuarter.Quarter} />}
        />
        <Route
          path={`${quarter}${paths.summary}`}
          element={<ApplicationSummarySlide appType={HousingQuarter.Quarter} />}
        />
        <Route
          path={`${quarter}${paths.serviceLocation}`}
          element={<SelectLocationSlide appType={HousingQuarter.Quarter} />}
        />
      </Route>
      <Route
        path="/befaring/:rentalId/:inspectionId/*"
        element={
          <ProtectedRoute>
            <SlidesContextProvider baseUrl="befaring">
              <InspectionSection />
            </SlidesContextProvider>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/documents"
        element={
          <ProtectedRoute>
            <Documents />
          </ProtectedRoute>
        }
      />
      <Route
        path="/booking"
        element={
          <ProtectedRoute>
            <BookingContextProvider>
              <SlidesContextProvider baseUrl="booking">
                <BookingSlides />
              </SlidesContextProvider>
            </BookingContextProvider>
          </ProtectedRoute>
        }
      >
        <Route index element={<LocationInfoSlide />} />
        <Route path="guest-info" element={<GuestInfoSlide />} />
        <Route path="payment-info" element={<PaymentInfoSlide />} />
        <Route path="confirmation-info" element={<ConfirmationInfoSlide />} />
      </Route>
      <Route
        path="/booking-v2/:bookingid?"
        element={
          <FeatureRoute feature={FeatureEnum.BookingV2}>
            <ProtectedRoute>
              <NewBookingContextProvider>
                <Booking />
              </NewBookingContextProvider>
            </ProtectedRoute>
          </FeatureRoute>
        }
      />
      <Route path="/payment" element={<Payment />} />
      <Route
        path="/info"
        element={
          <ProtectedRoute>
            <Information />
          </ProtectedRoute>
        }
      >
        <Route path="arrival/:locationId" element={<ArrivalInfo />} />
        <Route path="booking-terms" element={<BookingTerms />} />
        <Route path="location/:locationId" element={<LocationInfo />} />
      </Route>
      <Route
        path="/l3"
        element={
          <ProtectedRoute>
            <L3 />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reservations"
        element={
          <ProtectedRoute>
            <Reservations />
          </ProtectedRoute>
        }
      />
      <Route
        path="/vaktside_old"
        element={
          <ProtectedRoute>
            <Guardpage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/vaktside"
        element={
          <ProtectedRoute>
            <FireAndArrivalPage />
          </ProtectedRoute>
        }
      >
        <Route path="/vaktside" element={<TabGuardPage />} />
        <Route path="/vaktside/tab-vaktside" element={<TabGuardPage />} />
        <Route
          path="/vaktside/tab-ankomstliste"
          element={<TabArrivalListPage />}
        />
        <Route path="/vaktside/tab-brannliste" element={<TabFireListPage />} />
      </Route>
      <Route path="/reporting/confirmation" element={<Confirmation />} />
      <Route
        path="/reporting"
        element={
          <SlidesContextProvider baseUrl="reporting">
            <ReportingSlides />
          </SlidesContextProvider>
        }
      >
        <Route index element={<StartPageSlide />} />
        <Route path="faultform" element={<FaultFormSlide />} />
        <Route path="extraservices" element={<ExtraServicesListSlide />} />
        <Route path="faultform/:type/:id?" element={<FaultFormSlide />} />
      </Route>
      <Route path="/contact" element={<Contact />} />
      <Route path="/informasjon" element={<InformationPage />} />
      <Route path="/information" element={<OldInformationPage />} />{' '}
      {/*This page wil be replaced by InformationPage in the future. InformationPage is currently not active i production (15/05-24*/}
      <Route path="/oops" element={<Oops />} />
      {isDev() && <Route path="/sandbox" element={<Sandbox />} />}
      <Route path="basen/Verify" element={<Landing />} />
      <Route path="basen/Confirm" element={<Landing />} />
      <Route path="basen/TilbakestillPassord" element={<Landing />} />
      <Route path="CreateUser" element={<Landing />} />
      <Route path="BookingCallback/Receipt" element={<BookingReceipt />} />
      <Route
        path="paymentprovidercallback/:payeeDomain/:orderReference"
        element={<PaymentProviderCallback />}
      />
      <Route path="/innsjekk/:arenaid" element={<UnnamedBooking />} />
      <Route path="/auth/login" element={<Login />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/" element={<Landing />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppContainer;
