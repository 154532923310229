export const parseCoordinates = (coordinates: string) => {
  if (!coordinates) return undefined;

  const dmsRegex = /(\d+)[°\s]+(\d+)?[\'\s]*(\d+(?:\.\d+)?)*[\"\s]*([NSEW])/gi;

  const convertDMSToDecimal = (
    degrees: number,
    minutes: number = 0,
    seconds: number = 0,
    direction: string,
  ) => {
    let decimal = degrees + minutes / 60 + seconds / 3600;
    if (direction === 'S' || direction === 'W') {
      decimal *= -1;
    }
    return parseFloat(decimal.toFixed(6)); // Round to 6 decimal places
  };

  const matches = Array.from(coordinates.matchAll(dmsRegex));

  if (matches.length === 2) {
    const latMatch = matches[0];
    const lngMatch = matches[1];

    if (!latMatch || !lngMatch) {
      throw new Error('Invalid DMS format');
    }

    const lat = convertDMSToDecimal(
      parseFloat(latMatch[1]),
      latMatch[2] ? parseFloat(latMatch[2]) : 0,
      latMatch[3] ? parseFloat(latMatch[3]) : 0,
      latMatch[4],
    );

    const lng = convertDMSToDecimal(
      parseFloat(lngMatch[1]),
      lngMatch[2] ? parseFloat(lngMatch[2]) : 0,
      lngMatch[3] ? parseFloat(lngMatch[3]) : 0,
      lngMatch[4],
    );

    return { lat, lng };
  } else if (coordinates.includes(',') && matches.length === 0) {
    const [lat, lng] = coordinates
      .split(',')
      .map((coord) => parseFloat(coord.trim()));
    if (isNaN(lat) || isNaN(lng)) {
      throw new Error('Invalid decimal format');
    }
    return { lat, lng };
  } else {
    throw new Error('Invalid DMS format');
  }
};
