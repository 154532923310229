import { basenUrl } from 'api/cms/cms_api';
import Accordion from 'components/Accordian/Accordion';
import { useApplication } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import Space from 'src/layout/Space';
import { DeliberateAny } from 'types/DelibrateAny';
import { v4 } from 'uuid';

type TermsListItem = {
  heading: string | null;
  paragraphs: (string | null)[];
};

const BookingTerms = () => {
  const { setHeading, setSubHeading } = useApplication();
  const { sendRequest } = useAxios();

  const [termsList, setTermsList] = useState<TermsListItem[]>();

  const extractTermsList = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    const termsList: TermsListItem[] = [];
    let currentHeading: TermsListItem = {
      heading: null,
      paragraphs: [],
    };

    doc.querySelectorAll('h3, p').forEach((tag) => {
      if (tag.tagName === 'H3') {
        // If there's already a heading, append its details before starting a new one
        if (currentHeading) {
          termsList.push(currentHeading);
        }
        // Start a new heading with an empty list of paragraphs
        currentHeading = {
          heading: tag.textContent,
          paragraphs: [],
        };
      } else if (tag.tagName === 'P' && currentHeading) {
        // Add the paragraph to the current heading's list
        currentHeading.paragraphs.push(tag.textContent);
      }
    });

    // Append the last heading
    if (currentHeading) {
      termsList.push(currentHeading);
    }
    return termsList;
  };

  const fetchTerms = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: basenUrl + '/vilkar?urlSegment=vilkarintegrert',
      },
      (res: DeliberateAny) => {
        setTermsList(extractTermsList(res));
      },
    );
  };

  useEffect(() => {
    fetchTerms();
    setHeading('Vilkår for overnatting');
  }, []);

  useEffect(() => {
    if (termsList) {
      const subheading = termsList.find((t) => t.heading === null)
        ?.paragraphs[0];
      if (subheading) setSubHeading(subheading);
    }
  }, [termsList]);

  return (
    <>
      <div className="html-content no-gap">
        {termsList &&
          termsList
            .filter((t) => t.heading !== null)
            .map((t) => {
              const key = v4();
              return (
                <Accordion
                  className={'bordered-item-in-list'}
                  title={t.heading ?? ''}
                  key={key}
                >
                  <div
                    style={{
                      display: 'flex',
                      gap: 32,
                      flexDirection: 'column',
                    }}
                  >
                    {t.paragraphs.map((p, i) => (
                      <p key={key + '_' + i}>{p}</p>
                    ))}
                  </div>
                </Accordion>
              );
            })}
      </div>
      <Space height={'150px'} />
    </>
  );
};

export default BookingTerms;
