// @flow

import { ADMIN_SERVICE_ORDER_TYPE } from "helpers/serviceOrderHelpers";

import SendServiceOrderModal from "../SendServiceOrderModal/SendServiceOrderModal";
import { registerBuildingAdministrativeServiceOrderURL } from "api/basenproff/basenproff_api";

type PropsType = {
  path: string;
  inventoryNumber: number;
  title: string;
  serviceOrderTitle: string;
};

const DocumentServiceOrderModal = (props: PropsType) => (
  <SendServiceOrderModal
    description="Forespørselen behandles manuelt og det kan være opptil 3 ukers ventetid."
    fields={{
      title: { value: props.serviceOrderTitle, disabled: true },
      building: { value: props.inventoryNumber, disabled: true },
      description: { label: "Kommentar", required: true },
      serviceOrderType: { value: ADMIN_SERVICE_ORDER_TYPE },
    }}
    {...props}
    url= {registerBuildingAdministrativeServiceOrderURL}
  />
);

export default DocumentServiceOrderModal;
