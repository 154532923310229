import ConfirmationModal from "components/modals/ConfirmationModal/ConfirmationModal";
import { UserContext } from "contexts/UserContext";
import connect from "helpers/connectWithRouter";
import { xpandUserIdExists } from "helpers/xpandUser";
import { Component } from "react";
import { Outlet } from "react-router-dom";
import { DeliberateAny } from "types/DelibrateAny";

type PropsType = {
  isRenewalQuarter: boolean;
  isRenewalHousing: boolean;
  location: Location;
};

class UnconnectedHousingSlides extends Component {
  declare props: PropsType;

  state: {
    userData: any;
    showModal: boolean;
  };

  constructor(props: PropsType) {
    super(props);
    this.state = { userData: "", showModal: false };
  }

  async componentDidMount() {
    const ctx: DeliberateAny = this.context;
    if (ctx?.user) {
      if (!xpandUserIdExists(ctx?.user)) {
        this.state.showModal = true;
      }
      this.state.userData = ctx?.user;
    }
  }

  shouldComponentUpdate(nextProp) {
    return (
      nextProp.location.pathname !== this.props.location.pathname ||
      this.state.showModal
    );
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <>
        <Outlet />
        <ConfirmationModal
          isOpen={this.state.showModal}
          onClose={this.closeModal}
          title="Mangler tilgang til å søke bolig"
          html={`
            <p>Be om tilgang til å søke bolig</p>
            <p>Denne funksjonen må aktiveres av
            <a data-action='dismiss' href='#/contact'> Forsvarsbygg servicesenter</a></p>
            <p>Ta kontakt for å få tilgang</p>`}
        />
      </>
    );
  }
}

UnconnectedHousingSlides.contextType = UserContext;

export default connect((state: DeliberateAny) => ({
  isRenewalQuarter: state.housing.quarterApplication.isRenewal,
  isRenewalHousing: state.housing.housingApplication.isRenewal,
}))(UnconnectedHousingSlides);
