import * as Yup from 'yup';

// Add a custom method to Yup for validating a date range
Yup.addMethod(Yup.array, 'dateRange', function (message) {
  return this.test('dateRange', message, function (value) {
    const { path, createError } = this;

    // Ensure the value is an array with exactly two elements
    if (!Array.isArray(value) || value.length !== 2) {
      return createError({
        path,
        message: message || '${path} må være to datoer',
      });
    }

    // Ensure both elements are valid numbers
    const [start, end] = value;
    if (typeof start !== 'number' || typeof end !== 'number') {
      return createError({
        path,
        message: message || '${path} må være to datoer',
      });
    }

    // If all checks pass, the validation is successful
    return true;
  });
});

export default Yup;
