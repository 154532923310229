import { sendApplication } from 'actions/housingActions';
import RenderApplicationSummary from 'components/HousingApplication/RenderApplicationSummary';
import LabelValueList from 'components/LabelValueList/LabelValueList';
import Modal from 'components/modals/Modal/Modal';
import Spinner from 'components/Spinner/Spinner';
import { useModal } from 'contexts/index';
import FBHelpers from 'helpers/_helpers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeliberateAny } from 'types/DelibrateAny';

import {
  acceptAllocationURL,
  deleteHousingApplicationURL,
  rejectAllocationURL,
} from '../../../api/housing/housing_api';
import { deleteQuarterApplicationURL } from '../../../api/housing/quarter_api';
import { axiosFetch } from '../../../hooks/useAxios';

export const ApplicationSummaryModal = ({
  item,
  isOpen,
  setApplicationSummaryModalIsOpen,
}: DeliberateAny) => (
  <Modal
    title="Oppsummering av søknad"
    ariaLabel="Oppsummering av søknad"
    size="medium"
    isOpen={isOpen}
    onClose={() => setApplicationSummaryModalIsOpen(false)}
  >
    <span>
      <strong>Søknadsfrist: </strong> {item.Deadline}
    </span>
    <RenderApplicationSummary
      application={{
        fullName: item.FullName,
        employeeNumber: item.EmployeeNumber,
        dateOfBirth: item.DateOfBirth,
        applicantId: item.ApplicantId,
        primaryEmail: item.PrimaryEmail,
        type: item.ApplicationType,
        applicationNumber: item.ApplicationNumber,
        applicationPoints: item.ApplicationPoints,
        dateApplied: item.DateApplied,
        applicantCategory: item.ApplicantCategory,
        newWorkTitle: item.NewWorkTitle,
        isDefenceEmployee: item.IsDefenceEmployee,
        assignmentFromDate: item.AssignmentFromDate,
        assignmentToDate: item.AssignmentToDate,
        assignmentDocumentation: item.AssignmentDocumentation,
        serviceAreaId: item.ServiceAreaId,
        housingAreaId: item.HousingAreaId,
        maritalStatus: item.MaritalStatus,
        isPregnant: item.IsPregnant,
        children: item.Children,
        petCount: item.PetCount,
        petInfo: item.PetInfo,
        otherInfo: item.OtherInfo,
        currentHousingSituation: item.CurrentHousingSituation,
        currentHousingCounty: item.CurrentHousingCounty,
        currentHousingZipCode: item.CurrentHousingZipCode,
        housingPreferences: item.HousingPreferences,
        sharedHousingAcceptable: item.SharedHousingAcceptable,
        isMainApplicant: item.IsMainApplicant,
        wantedFromDate: item.WantedFromDate,
      }}
    />
  </Modal>
);
export const WithdrawApplicationModal = ({
  id,
  applicationType,
  isOpen,
  setWithdrawApplicationModalIsOpen,
  setRunUseEffect,
}: DeliberateAny) => {
  const [checkbox, setCheckbox] = useState(false);
  const [checkboxClass, setCheckboxClass] = useState('onoff-radio');

  const handleClick = async () => {
    if (checkbox) {
      setWithdrawApplicationModalIsOpen(false);
      if (applicationType === 'quarter') {
        await axiosFetch({
          url: `${deleteQuarterApplicationURL}/${id}`,
          method: 'DELETE',
        });
      } else {
        await axiosFetch({
          url: `${deleteHousingApplicationURL}/${id}`,
          method: 'DELETE',
        });
      }
      setRunUseEffect(true);
    } else {
      setCheckboxClass('onoff-radio invalid');
    }
  };

  return (
    <Modal
      title="Trekk søknaden"
      ariaLabel="Trekk søknaden"
      isOpen={isOpen}
      submit={{
        text: 'Trekk søknad',
        onClick: () => handleClick(),
        ignoreOnClose: true,
      }}
      cancel={{
        text: 'Nei',
        onClick: () => setWithdrawApplicationModalIsOpen(false),
      }}
    >
      <div>
        <div>
          <p>
            Ved å trykke &apos;Trekk søknad&apos; fjernes din søknad fra Basen
            permanent.
            <br /> Er du sikker på at du ønsker å trekke søknaden din?
          </p>
        </div>
        <div className="half-margin">
          <input
            type="checkbox"
            id="withdrawApplicationCheckbox"
            onChange={() => setCheckbox(!checkbox)}
            className={checkboxClass}
          />
          <label
            className="onoff-checkbox-label"
            htmlFor="withdrawApplicationCheckbox"
          >
            Ja, jeg ønsker å trekke min søknad
          </label>
        </div>
      </div>
    </Modal>
  );
};

export const AnswerAllocationModal = ({
  isOpen,
  setAnswerAllocationModalIsOpen,
  itemId,
  setRunUseEffect,
}: DeliberateAny) => {
  const [optionSelected, setOptionSelected] = useState('');
  const [invalid, setInvalid] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);
  const { showModal } = useModal();
  const [showDelayMessage, setShowDelayMessage] = useState(false);

  useEffect(() => {
    let timer;
    if (hasClicked) {
      timer = setTimeout(() => {
        setShowDelayMessage(true);
      }, 5000);
    } else {
      setShowDelayMessage(false);
    }
    return () => clearTimeout(timer);
  }, [hasClicked]);

  const handleClick = () => {
    if (optionSelected && !hasClicked) {
      setHasClicked(true);
      const url =
        optionSelected === 'accept-allocated'
          ? acceptAllocationURL
          : rejectAllocationURL;
      axiosFetch({ url, data: { housingRequestId: itemId }, method: 'POST' })
        .catch((error) => {
          showModal({
            title: error.response.data.Text,
            html: error.response.data.Title,
            size: 'medium',
            show: true,
          });
        })
        .finally(() => {
          setHasClicked(false);
          setRunUseEffect(true);
        });
    } else {
      setInvalid(true);
    }
  };

  return (
    <Modal
      title="Svar på tildelingen"
      ariaLabel="Svar på tildelingen"
      isOpen={isOpen}
      submit={{
        text: 'Registrer svar',
        onClick: () => handleClick(),
        ignoreOnClose: true,
        color: 'red',
        disabled: hasClicked,
      }}
      cancel={{
        text: 'Avbryt',
        onClick: () => setAnswerAllocationModalIsOpen(false),
      }}
      isLoading={hasClicked}
    >
      <p>Ønsker du den tildelte boligen?</p>
      <div className="row margin">
        <div
          className={invalid ? 'radio-container invalid' : 'radio-container'}
        >
          <label className="radio">
            <input
              type="radio"
              className="radio"
              name="allocationFeedback"
              value="accept-allocated"
              onChange={(e) => setOptionSelected(e.target.value)}
            />
            <span className="radio-indicator" />
            <span className="radio-label">
              Ja, jeg ønsker den tildelte boligen
            </span>
          </label>
          <label className="radio">
            <input
              type="radio"
              className="radio"
              name="allocationFeedback"
              value="decline-allocated"
              onChange={(e) => setOptionSelected(e.target.value)}
            />
            <span className="radio-indicator" />
            <span className="radio-label">
              Nei, jeg ønsker ikke den tildelte boligen
            </span>
          </label>
        </div>
      </div>
      {hasClicked && (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Spinner />
          </div>

          <div
            style={{
              opacity: showDelayMessage ? 1 : 0,
              marginTop: '12px',
              fontStyle: 'italic',
              textAlign: 'center',
              transition: 'all 1s ease-in-out',
            }}
          >
            Dette tar lenger tid enn antatt. Vennligst vent...
          </div>
        </>
      )}
    </Modal>
  );
};

export const ResendAsMainApplicantModal = ({
  isOpen,
  setResendAsMainApplicantModalOpen,
  item,
  setRunUseEffect,
}: DeliberateAny) => {
  const [application, setApplication] = useState(item);
  const dispatch = useDispatch();
  const [hasClicked, setHasClicked] = useState(false);
  const [applicationSent, setApplicationSent] = useState(
    item.housing && item.housing.sentApplication,
  );

  useEffect(() => {
    const newItem = { ...item, type: item.ApplicationType };
    newItem.IsMainApplicant = true;
    newItem.SharedHousingAcceptable = false;
    setApplication(newItem);
  }, []);

  const handleClick = () => {
    if (!hasClicked) {
      setHasClicked(true);
      dispatch(sendApplication(application));
      setRunUseEffect(true);
    }
  };

  const handleConfirmationModalClick = () => {
    if (applicationSent) {
      setApplicationSent(false);
      setRunUseEffect(true);
    }
  };

  return (
    <div>
      <Modal
        title="Send ny søknad som hovedsøker"
        size="medium"
        ariaLabel="Send ny søknad som hovedsøker"
        isOpen={isOpen}
        submit={{
          text: 'Send søknad',
          onClick: () => handleClick(),
          ignoreOnClose: true,
          color: 'red',
          disabled: hasClicked,
        }}
        cancel={{
          text: 'Avbryt',
          onClick: () => setResendAsMainApplicantModalOpen(false),
        }}
      >
        <p>
          Hovedsøker har trukket søknaden. Ønsker du å sende din søknad på nytt
          som hovedsøker? Hvis du vil gjøre endringer på søknaden må du sende en
          ny søknad.
        </p>
        <RenderApplicationSummary
          application={{
            fullName: application.FullName,
            employeeNumber: application.EmployeeNumber,
            dateOfBirth: application.DateOfBirth,
            applicantId: application.ApplicantId,
            primaryEmail: application.PrimaryEmail,
            secondaryEmail: application.SecondaryEmail,
            type: application.ApplicationType,
            applicationNumber: application.ApplicationNumber,
            applicationPoints: application.ApplicationPoints,
            dateApplied: application.DateApplied,
            applicantCategory: application.ApplicantCategory,
            newWorkTitle: application.NewWorkTitle,
            isDefenceEmployee: application.IsDefenceEmployee,
            assignmentFromDate: application.AssignmentFromDate,
            assignmentToDate: application.AssignmentToDate,
            assignmentDocumentation: application.AssignmentDocumentation,
            serviceAreaId: application.ServiceAreaId,
            housingAreaId: application.HousingAreaId,
            maritalStatus: application.MaritalStatus,
            isPregnant: application.IsPregnant,
            children: application.Children,
            petCount: application.PetCount,
            petInfo: application.PetInfo,
            otherInfo: application.OtherInfo,
            currentHousingSituation: application.CurrentHousingSituation,
            currentHousingCounty: application.CurrentHousingCounty,
            currentHousingZipCode: application.CurrentHousingZipCode,
            housingPreferences: application.HousingPreferences,
            sharedHousingAcceptable: application.SharedHousingAcceptable,
            isMainApplicant: application.IsMainApplicant,
            wantedFromDate: application.WantedFromDate,
          }}
        />
      </Modal>
      <Modal
        isOpen={applicationSent}
        cancel={{ hide: true }}
        submit={{
          text: 'Ok',
          onClick: () => {
            handleConfirmationModalClick();
          },
        }}
        ariaLabel="Boligsøknad sendt inn"
      >
        <h2>Søknaden er registrert!</h2>
        <p>
          Du har søkt om {application.ApplicationType} på{' '}
          {application.ServiceAreaName}. En kopi av søknaden er sendt til{' '}
          {application.SecondaryEmail || application.PrimaryEmail}
        </p>
        {application.RequiredDocumentation.length > 0 && (
          <div className="margin">
            <h3>Husk vedlegg</h3>
            <p>
              Fristen for innsendelse av vedlegg er{' '}
              {moment(application.DocumentationDeadline).format('DD.MM.YYYY')}
              <br />
              <br />
              Har du alt klart? Nødvendig dokumentasjon ettersendes på FISBasis.
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export const InfoModal = ({
  isOpen,
  setInfoModalIsOpen,
  item,
}: DeliberateAny) => (
  <Modal
    title="Din tildelte bolig"
    ariaLabel="Din tildelte bolig"
    isOpen={isOpen}
    onClose={() => setInfoModalIsOpen(false)}
  >
    <LabelValueList
      items={[
        { label: 'Adresse', value: item.Housing.Name },
        { label: 'Leie-ID', value: item.Housing.RentalId },
        {
          label: 'Størrelse',
          value: (
            <span>
              {item.Housing.Size}
              <sup>2</sup>
            </span>
          ),
        },
        { label: 'Antall rom', value: item.Housing.Type },
        {
          label: 'Leiepris',
          value: (
            <span>
              {FBHelpers.formatCurrency(item.Housing.Price, ' ', true)}
            </span>
          ),
        },
      ]}
    />
  </Modal>
);
