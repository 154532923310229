import './HousingApplicationsColumns.scss';

const HousingApplicationsColumns = [
  {
    label: 'Boområde',
    name: 'HousingAreaName',
    className: 'housingAreaName',
  },

  {
    label: 'Tjenestested',
    name: 'ServiceAreaName',
    className: 'serviceAreaName',
  },
  { label: 'Søknadstype', name: 'ApplicationTypeName', className: 'applicationType' },
  { label: 'Søknadsfrist', name: 'Deadline', className: 'deadline' },
  { label: 'Status', name: 'ApplicationState', className: 'applicationState' },
];

export default HousingApplicationsColumns;
