import Slide from "components/Slides/Slide";
import { useBooking } from "contexts/index";

import Location from "../LocationInfo";

const LocationInfoSlide = () => {
  const {
    parentBooking,
    setParentBooking,
    useReferenceCode,
    setUseReferenceCode,
    referenceCode,
    setReferenceCode,
    numberOfGuests,
    setNumberOfGuests,
    isBlockBooking,
    setIsBlockBooking,
    arrivalDate,
    setArrivalDate,
    departureDate,
    setDepartureDate,
    selectedLocation,
    setSelectedLocation,
    setNumberOfDaysToFocePayment,
    maxBookingLength,
    setMaxBookingLength,
    maxBookingLengthBlockBooking,
    setMaxBookingLengthBlockBooking,
    setMaxBookingLengthToUse,
    setRoomInfo,
    clearBookingState,
  } = useBooking();
  return (
    <Slide path="" backgroundImage slideSize="none">
      <Location
        parentBooking={parentBooking}
        useReferenceCode={useReferenceCode}
        setUseReferenceCode={setUseReferenceCode}
        referenceCode={referenceCode}
        setReferenceCode={setReferenceCode}
        setParentBooking={setParentBooking}
        numberOfGuests={numberOfGuests}
        setNumberOfGuests={setNumberOfGuests}
        isBlockBooking={isBlockBooking}
        setIsBlockBooking={setIsBlockBooking}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        arrivalDate={arrivalDate}
        setArrivalDate={setArrivalDate}
        departureDate={departureDate}
        setDepartureDate={setDepartureDate}
        setRoomInfo={setRoomInfo}
        setNumberOfDaysToFocePayment={setNumberOfDaysToFocePayment}
        clearBookingState={clearBookingState}
        maxBookingLength={maxBookingLength}
        setMaxBookingLength={setMaxBookingLength}
        maxBookingLengthBlockBooking={maxBookingLengthBlockBooking}
        setMaxBookingLengthBlockBooking={setMaxBookingLengthBlockBooking}
        setMaxBookingLengthToUse={setMaxBookingLengthToUse}
      />
    </Slide>
  );
};

export default LocationInfoSlide;
