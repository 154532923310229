import Modal, { ModalSize } from '../Modal/Modal';

type PropsType = {
  title: string;
  infoText: string;
  html: string;
  isOpen: boolean;
  size?: ModalSize;
  submitText?: string;
  cancelText?: string;
  onClose: () => void;
  submitAction: () => void;
  cancelAction: () => void;
  flipActionOrder?: boolean;
};

const ActionModal = ({
  title,
  infoText,
  html,
  isOpen,
  size,
  submitText = 'Ok',
  cancelText = 'Avbryt',
  onClose,
  submitAction,
  cancelAction,
  flipActionOrder,
}: PropsType) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      ariaLabel={title}
      size={size}
      submit={{
        text: submitText,
        color: 'red',
        onClick: submitAction,
      }}
      cancel={{
        text: cancelText,
        onClick: cancelAction,
      }}
      flipActionOrder={flipActionOrder}
    >
      <header>
        <h2>{title}</h2>
      </header>
      {html !== undefined && html !== '' ? (
        <p dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        <p>{infoText}</p>
      )}
    </Modal>
  );
};

export default ActionModal;
