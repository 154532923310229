import { v4 as uuidv4 } from 'uuid';

const PrintGuestList = ({ item }) => (
  <div className="print-guest-list">
    <ul>
      {item.RoomInfo.sort((a, b) => a.Index - b.Index).map((room) => (
        <li key={room.Id} className="guest-list-room">
          <p className="room-index red no-margin">{`Rom ${room.Index + 1}`}</p>
          <h3 className="half-margin">{room.RoomTypeName}</h3>
          <div className="half-margin">
            <table className="names">
              <thead>
                <tr className="list-header">
                  <th className="firstname left-align">Fornavn</th>
                  <th className="left-align">Etternavn</th>
                  <th className="left-align">E-post</th>
                  <th className="left-align">Mobiltelefon</th>
                </tr>
              </thead>
              <tbody>
                {room.NameListInfo.NameListItems &&
                  room.NameListInfo.NameListItems.map((guest) => (
                    <tr key={uuidv4()}>
                      <td
                        data-th="Fornavn"
                        className={guest.FirstName ? '' : 'italic'}
                      >
                        {guest.FirstName ? guest.FirstName : 'navn mangler'}
                      </td>
                      <td data-th="Etternavn">
                        {guest.LastName ? guest.LastName : ''}
                      </td>
                      <td data-th="E-post">{guest.Email ? guest.Email : ''}</td>
                      <td data-th="Mobiltelefon">
                        {guest.Phone ? guest.Phone : ''}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {room.Requirements && room.Requirements.length > 0 && (
            <div className="requirements">
              <p className="no-margin">
                <strong>Tilpasninger</strong>
              </p>
              <p className="no-margin">{room.Requirements}</p>
            </div>
          )}
        </li>
      ))}
    </ul>
  </div>
);

export default PrintGuestList;
