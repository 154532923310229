import './ComboSelectBox.scss';

import { CheckIcon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import HelpText from 'components/HelpText';
import { sortByRelevance } from 'helpers/valueOptions';
import { FC, useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { IFormPropBase, ValueOption } from './FormPropBase';

interface IComboSelectBoxProps extends IFormPropBase {
  initial?: string;
  options: ValueOption[];
  requiredChars?: number;
  openOnDown?: boolean;
}

const ComboSelectBox: FC<IComboSelectBoxProps> = ({
  name,
  label,
  formHolder,
  updateForm,
  errors,
  helpInfo,
  options,
  disabled,
  initial,
  placeHolder,
  requiredChars,
  required,
  openOnDown,
}) => {
  const noMatch: ValueOption[] = [
    { label: 'Ingen treff', value: 'Ingen treff', disabled: true },
  ];

  // State variables
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLabel, setSelectedLabel] = useState<string | undefined>(
    initial,
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] =
    useState<ValueOption[]>(options);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // Track mouseover index

  // Refs for handling DOM interaction
  const searchInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]); // Array of refs for dropdown items

  // Filter options based on search term
  useEffect(() => {
    const filtered = sortByRelevance(options, searchTerm, noMatch);
    setFilteredOptions(filtered);
    setHighlightedIndex(0); // Reset highlighted index after filtering
  }, [options, searchTerm]);

  // Toggle dropdown based on required character input
  useUpdateEffect(() => {
    setIsDropdownOpen(
      !requiredChars || searchTerm.length >= requiredChars || !!openOnDown,
    );
  }, [searchTerm, requiredChars]);

  useEffect(() => {
    const selectedOption = options.find(
      (option) => option.value === formHolder[name],
    );
    setSelectedLabel(selectedOption?.label ?? '');
  }, [formHolder, name, options]);

  // Scroll the highlighted item into view when highlightedIndex changes
  useEffect(() => {
    if (highlightedIndex >= 0 && itemRefs.current[highlightedIndex]) {
      itemRefs.current[highlightedIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [highlightedIndex]);

  // Handle clicking outside the component to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Event Handlers
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleOptionClick = (option: ValueOption) => {
    if (!option.disabled) {
      updateForm(name, option.value);
      setIsDropdownOpen(false);
    }
  };

  const handleInputFocus = () => {
    setSearchTerm(
      options.find((option) => option.value === formHolder[name])?.label ?? '',
    );
    setSelectedLabel(undefined);
    searchInputRef.current?.focus();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isDropdownOpen) return;

    const selectedOption = filteredOptions[highlightedIndex];
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0,
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1,
        );
        break;
      case 'Enter':
        e.preventDefault();

        if (selectedOption && !selectedOption.disabled) {
          handleOptionClick(selectedOption);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setIsDropdownOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <div ref={containerRef} className="combo-select-box-container">
      <HelpText content={helpInfo}>
        <label className={`fb-form-label ${errors[name] ? 'error' : ''}`}>
          {errors[name] || label || name}
        </label>
      </HelpText>

      <div className="combo-input">
        {selectedLabel ? (
          <input
            type="text"
            value={selectedLabel}
            disabled={disabled}
            className={`combo-input-field ${disabled ? 'disabled' : ''}`}
            onChange={handleSearchChange}
            onFocus={handleInputFocus}
            onKeyDown={handleKeyDown}
            required={required}
          />
        ) : (
          <input
            ref={searchInputRef}
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            placeholder={placeHolder}
            disabled={disabled}
            className={`combo-input-field ${disabled ? 'disabled' : ''}`}
            onFocus={() =>
              setIsDropdownOpen(
                !requiredChars ||
                  searchTerm.length >= requiredChars ||
                  !!openOnDown,
              )
            }
            required={required}
          />
        )}

        {isDropdownOpen && (
          <ul className="dropdown-list">
            {filteredOptions.map((option, index) => (
              <li
                key={`${option.value}_index_${index}`}
                ref={(el) => (itemRefs.current[index] = el)} // Assign ref to each item
                className={`dropdown-item ${
                  option.disabled ? 'disabled' : ''
                } ${
                  highlightedIndex === index ||
                  (hoveredIndex === index && highlightedIndex === index)
                    ? 'hover'
                    : ''
                }`}
                onClick={() => handleOptionClick(option)}
                onMouseEnter={() => setHoveredIndex(index)} // Update hovered index on mouse enter
                onMouseLeave={() => setHoveredIndex(null)} // Reset on mouse leave
              >
                {option.label}
                {option.value === formHolder[name] && (
                  <BasenIcon icon={CheckIcon} size="s" />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ComboSelectBox;
