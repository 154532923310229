import './GuestInfo.scss';

import ArenaInfo from 'components/ArenaInfo/ArenaInfo';
import RoomInfoWrapper from 'components/booking/RoomInfoWrapper';
import {
  bedsVsGuests,
  calculateBeds,
  roomsVsGuests,
  validateRooms,
} from 'components/booking/ValidationFunctions';
import Button from 'components/buttons/Button/Button';
import DateInput from 'components/DateInput/DateInput';
import Checkbox from 'components/form/Checkbox/Checkbox';
import Loader from 'components/Loader/Loader';
import ModalWrapper from 'components/modals/ModalWrapper/ModalWrapper';
import NumberInput from 'components/NumberInput/NumberInput';
import { useModal, useSlides } from 'contexts/index';
import FBHelpers from 'helpers/_helpers';
import {
  availableRoomsInParent,
  getDaysToNameListDeadline,
  getGuestListDeadline,
  getMinDate,
  getTotalNumberOfRooms,
  getTotalPrice,
  isSharedRoom,
  numberOfAvailableBedsAndRooms,
} from 'helpers/bookingHelpers';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Space from 'src/layout/Space';
import { DeliberateAny } from 'types/DelibrateAny';
import { v4 as uuidv4 } from 'uuid';

import { ledigeRomMedPrisURL, priserURL } from '../../api/booking/booking_api';
import { axiosFetch } from '../../hooks/useAxios';
import BookingSummary from './BookingSummary';
import PasteInNamesModal from './PasteInNamesModal';

const GuestInfo = ({
  numberOfGuests,
  setNumberOfGuests,
  arrivalDate,
  setArrivalDate,
  departureDate,
  setDepartureDate,
  selectedLocation,
  setSelectedLocation,
  roomInfo,
  setRoomInfo,
  isBlockBooking,
  parentBooking,
  setItem,
  roomInfoPerRoomType,
  setRoomInfoPerRoomType,
  userData,
  maxBookingLength,
}) => {
  const navigate = useNavigate();
  const { showModal } = useModal();
  const { goToSlide } = useSlides();
  const [availableRoomsLoaded, setAvailableRoomsLoaded] = useState(false);
  const [pasteInNamesModalIsOpen, setPasteInNamesModalIsOpen] = useState(false);
  const [postponeGuestList, setPostponeGuestList] = useState(false);
  const [protectedUnit, setProtectedUnit] = useState(false);
  const [guestsNotDefenceEmployees, setGuestsNotDefenceEmployees] =
    useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [guestCountUnavailable, setGuestCountUnavailable] =
    useState<DeliberateAny>(false);

  const inputRef = useRef<DeliberateAny>();

  useEffect(() => {
    const arrival = `${arrivalDate.format('YYYY-MM-DDT00:00:00.000')}Z`;
    let departure;

    if (arrivalDate >= departureDate) {
      departure = `${arrivalDate
        .clone()
        .add(1, 'days')
        .format('YYYY-MM-DDT00:00:00.000')}Z`;
    } else {
      departure = `${departureDate.format('YYYY-MM-DDT00:00:00.000')}Z`;
    }

    if (selectedLocation && !parentBooking) {
      if (
        !selectedLocation.NotOpenForBooking &&
        selectedLocation.VBUseIntegration
      ) {
        axiosFetch({
          url: ledigeRomMedPrisURL,
          params: {
            locationId: selectedLocation.LocationId,
            arrivalDate: arrival,
            departureDate: departure,
          },
        }).then((res) => {
          const obj = {
            ...selectedLocation,
            Rooms: selectedLocation.Rooms.map((roomType) => ({
              ...roomType,
              AvailableCount: res?.find(
                (e) => e.RoomTypeId === roomType.RoomTypeId,
              )
                ? res?.find((e) => e.RoomTypeId === roomType.RoomTypeId)
                    .AvailableCount
                : 0,
              PriceList: res?.find((e) => e.RoomTypeId === roomType.RoomTypeId)
                ? res?.find((e) => e.RoomTypeId === roomType.RoomTypeId)
                    .PriceList
                : [],
            })),
          };
          setRoomInfoPerRoomType(
            obj.Rooms.map((roomType) => ({
              id: roomType.RoomTypeId,
              count:
                roomType.AvailableCount <
                roomInfo.filter(
                  (room) => room.RoomTypeId === roomType.RoomTypeId,
                ).length
                  ? roomType.AvailableCount
                  : roomInfo.filter(
                      (room) => room.RoomTypeId === roomType.RoomTypeId,
                    ).length,
              name: roomType.Name,
            })),
          );
          setSelectedLocation(obj);
          setGuestCountUnavailable(
            numberOfAvailableBedsAndRooms(obj, isBlockBooking),
          );
          setAvailableRoomsLoaded(true);
        });
      } else if (selectedLocation.NotOpenForBooking) {
        setShowWarningMessage(true);
      }
    } else if (selectedLocation && parentBooking) {
      const numberOfAvailableRooms = availableRoomsInParent(parentBooking);
      axiosFetch({
        url: priserURL,
        data: {
          locationId: selectedLocation.LocationId,
          arrivalDate: arrival,
          departureDate: departure,
        },
      }).then((res) => {
        const obj = {
          ...selectedLocation,
          Rooms: selectedLocation.Rooms.map((roomType) => ({
            ...roomType,
            AvailableCount: numberOfAvailableRooms[roomType.RoomTypeId] || 0,
            PriceList: res.find((e) => e.RoomTypeId === roomType.RoomTypeId)
              .PriceList,
          })),
        };
        setRoomInfoPerRoomType(
          obj.Rooms.map((roomType) => ({
            id: roomType.RoomTypeId,
            count: 0,
            name: roomType.Name,
            isSharedRoom: isSharedRoom(roomType),
          })),
        );
        setSelectedLocation(obj);
        setGuestCountUnavailable(
          numberOfAvailableBedsAndRooms(obj, isBlockBooking),
        );
        setAvailableRoomsLoaded(true);
      });
    } else {
      navigate('/booking');
    }
  }, [departureDate, arrivalDate]);

  const handleOpenPasteInNamesModal = (e) => {
    e.preventDefault();
    setPasteInNamesModalIsOpen(true);
  };

  const handlePostponeGuestList = () => {
    const DEADLINEHOUR = 10;
    const daysToDeadline = getDaysToNameListDeadline({
      ArrivalDate: arrivalDate,
    });
    if (
      !postponeGuestList &&
      (daysToDeadline > 0 ||
        (daysToDeadline === 0 && new Date().getHours() < DEADLINEHOUR))
    ) {
      showModal({
        title: 'Informasjon',
        html:
          "<p class='margin'>Fristen for innsending av gjesteliste er " +
          `<strong>${getGuestListDeadline} dager</strong> før ankomstdagen. Gjestelisten kan lastes opp på Min side`,
      });
      setPostponeGuestList(!postponeGuestList);
    } else if (daysToDeadline <= 0) {
      showModal({
        title: 'Informasjon',
        html:
          "<p class='margin'>Fristen for innsending av gjesteliste er utløpt. Fristen er " +
          `<strong>${getGuestListDeadline} dager</strong> før ankomstdagen. Gjestelisten kan <strong>ikke</strong> bli utsatt.`,
      });
    } else {
      setPostponeGuestList(!postponeGuestList);
    }
  };

  const handleGuestsNotDefenceEmployees = () => {
    if (!guestsNotDefenceEmployees) {
      showModal({
        title: 'Meld fra til Forsvaret',
        html:
          "<p class='margin'>Ved å bestille overnatting for gjester som ikke " +
          'jobber i forsvarssektoren må du huske å få dem godkjent ' +
          'av lokal sikkerhetsoffiser i Forsvaret.<br><br>' +
          'Har du ikke det, risikerer gjesten å bli avvist i vakta.</p>',
      });
    }
    setGuestsNotDefenceEmployees(!guestsNotDefenceEmployees);
  };

  const getbookingMode = () => {
    if (parentBooking) {
      return 'reference';
    } else if (isBlockBooking) {
      return 'block';
    }
    return 'standard';
  };

  const nextSlide = () => {
    const obj: DeliberateAny = {
      RoomInfo: roomInfo,
      GroupInfo: {
        NameListComplete: !postponeGuestList && !isBlockBooking,
        NameListRequired: !protectedUnit,
        GuestCount: numberOfGuests,
      },
      bookingMode: getbookingMode(),
      arrivalDate: `${arrivalDate.format('YYYY-MM-DDT00:00:00.000')}Z`,
      departureDate: `${departureDate.format('YYYY-MM-DDT00:00:00.000')}Z`,
      guestCount: numberOfGuests,
      duration: departureDate.diff(arrivalDate, 'days'),
      locationId: selectedLocation.LocationId,
      Bookee: userData.Name,
    };
    if (postponeGuestList) {
      obj.guestListLater = true;
    } else if (protectedUnit) {
      obj.isSecretUnit = true;
    }
    setItem(obj);
    goToSlide('payment-info');
  };

  const validateBedsAndRooms = () => {
    const roomCount = roomInfo.length;
    const bedCount = calculateBeds(roomInfo, isBlockBooking);
    const guestCount = numberOfGuests;
    let validated = false;
    if (protectedUnit) {
      validated =
        bedsVsGuests(bedCount, guestCount, isBlockBooking, showModal) &&
        roomsVsGuests(roomCount, guestCount, showModal);
    }
    validated = validateRooms(roomInfo, guestCount, isBlockBooking, showModal);
    if (validated) {
      // validate fields
      inputRef.current?.handleSubmit();
    }
  };

  const renderGuestCountWarning = () => {
    const seasonClosureInSelectedPeriod = () => {
      //  const _booking = booking || currentBooking;
      const booking = selectedLocation;
      if (selectedLocation.ClosurePeriods.length === 0) return false;
      const bookingArrivalDate = new Date(booking.arrivalDate).getTime();
      const bookingDepartureDate = new Date(booking.departureDate).getTime();

      for (let i = 0; i < selectedLocation.ClosurePeriods.length; i += 1) {
        const period = selectedLocation.ClosurePeriods[i];
        const closureStart = new Date(period.StartDate).getTime();
        const closureEnd = new Date(period.EndDate).getTime();

        if (
          !(
            bookingDepartureDate < closureStart ||
            bookingArrivalDate > closureEnd
          )
        ) {
          return true;
        }
      }
      return false;
    };

    const getClosures = () => {
      const periodList: DeliberateAny[] = [];
      for (let i = 0; i < selectedLocation.ClosurePeriods.length; i += 1) {
        const period = selectedLocation.ClosurePeriods[i];
        const seasonClosureStart = new Date(period.StartDate);
        const seasonClosureEnd = new Date(period.EndDate);
        const fromStr = `${seasonClosureStart.getDate()} . ${FBHelpers.Date.monthString(
          seasonClosureStart.getMonth(),
        )}`;
        const toStr = `${seasonClosureEnd.getDate()} . ${FBHelpers.Date.monthString(
          seasonClosureEnd.getMonth(),
        )}`;
        let periodStr = `${fromStr} til ${toStr}`;
        if (period.Description && period.Description.length > 0) {
          periodStr += ` - ${period.Description}`;
        }
        periodList.push(periodStr);
      }
      if (periodList.length > 0) {
        return (
          <div>
            {selectedLocation.Name} er utilgjengelig for overnatting i følgende
            perioder:
            {periodList.map((item) => (
              <div key={item}>{item}</div>
            ))}
          </div>
        );
      }
      return '';
    };

    const renderWarningMessage = () => {
      if (guestCountUnavailable.beds === 0) {
        return 'Det er dessverre ingen ledige rom i perioden du ønsker.';
      } else if (getbookingMode() === 'block') {
        return `Det er kun ledige rom for ${guestCountUnavailable.beds} gjester.`;
      }
      return `Det er kun ledige rom for ${guestCountUnavailable.beds} gjester, fordelt på ${guestCountUnavailable.rooms} rom.`;
    };
    return (
      <div className="guest-count-unavailable">
        <div className="warning-message">
          <div className="no-margin">
            {!seasonClosureInSelectedPeriod() && renderWarningMessage()}
            {seasonClosureInSelectedPeriod() && getClosures()}
            <br />
            <Link to="/contact">Kontakt Forsvarsbygg servicesenter</Link> for
            mer informasjon.
          </div>
        </div>
      </div>
    );
  };

  const getRoomText = () => {
    const sharedRoom = roomInfoPerRoomType.some(
      (roomType) => roomType.isSharedRoom && roomType.count > 0,
    );
    if (sharedRoom) {
      return `${
        getTotalNumberOfRooms(roomInfoPerRoomType) > 1 ? 'senger' : 'seng'
      }/rom`;
    }
    return 'rom';
  };

  const formatText = (_text) => {
    try {
      let text = '';
      text = _text.replace(/\n/g, '<br/>');

      if (text.toString().indexOf('<br/>') === -1) return text;
      const newText = text.split('<br/>').map((item) => (
        <span key={uuidv4()}>
          {item}
          <br />
        </span>
      ));
      return newText;
    } catch (error: DeliberateAny) {
      return error.toString();
    }
  };

  const handleArrivalDate = (date) => {
    if (getDaysToNameListDeadline({ ArrivalDate: date }) < 0) {
      setPostponeGuestList(false);
    }

    setArrivalDate(date);
  };

  return (
    <div>
      <div className="booking-meta-row hide-for-small margin">
        <div className="booking-settings">
          <div className="book-guests">
            <NumberInput
              name="NumberOfGuests"
              title="Antall gjester"
              value={numberOfGuests}
              minValue={1}
              small
              onChange={(name, value) => setNumberOfGuests(value)}
            />
          </div>
          <div className="book-arrival picker-placement-lower">
            <DateInput
              title="Ankomst"
              name="arrival"
              minDate={getMinDate()}
              selectedDate={arrivalDate}
              disabled={parentBooking && true}
              text={
                <span>
                  <strong>NB!</strong> For å bestille overnatting med mindre enn
                  to dager til ankomst, vennligst{' '}
                  <a href="/#/contact">kontakt Forsvarsbygg Servicesenter</a>.
                </span>
              }
              onSelectDate={(date) => handleArrivalDate(date)}
              small
            />
          </div>
          <div className="book-departure picker-placement-lower">
            <DateInput
              title="Avreise"
              name="departure"
              startDate={arrivalDate}
              selectedDate={departureDate}
              disabled={parentBooking && true}
              onSelectDate={setDepartureDate}
              small
              maxDurationInDays={maxBookingLength}
              durationValidationMessage={`Korttidsovernattinger kan ikke vare lengre enn ${maxBookingLength} dager`}
            />
          </div>
        </div>
        <div className="labels booking-summary">
          <label>&nbsp;</label>
          <div className="book-room-count">
            <strong>
              <span className="red booking-summary-room-count">
                {getTotalNumberOfRooms(roomInfoPerRoomType)}
              </span>
              <span> {getRoomText()}</span>
            </strong>
          </div>
          <div className="book-price">
            <strong>
              Kr{' '}
              <span className="red booking-summary-room-count">
                {getTotalPrice(
                  roomInfo,
                  selectedLocation.Rooms,
                  isBlockBooking,
                )}
              </span>
              ,-
            </strong>
          </div>
        </div>
      </div>
      {showWarningMessage && (
        <div>
          <div className="hide-for-small warning-message">
            Det er dessverre ikke mulig å booke overnatting på denne arenaen på
            dette tidspunktet. <br />
            Vennligst{' '}
            <Link to="/contact">kontakt Forsvarsbygg servicesenter</Link> for
            mer informasjon.
          </div>
          <div className="hide-for-large hide-for-medium warning-message">
            Ingen overnatting tilgengelig for øyeblikket.
          </div>
        </div>
      )}
      {guestCountUnavailable &&
        guestCountUnavailable.beds < numberOfGuests &&
        renderGuestCountWarning()}
      <div className="arena">
        <div className="flex-row margin">
          <div className="arena-main-info">
            <h1>{selectedLocation.Name}</h1>
            <p className="intro margin">
              {formatText(selectedLocation.MainIntro)}
            </p>
            <div className="arena-info">
              <ArenaInfo
                arenaId={selectedLocation.LocationId}
                arenaName={selectedLocation.Name}
              />
            </div>
          </div>
          {!isBlockBooking && (
            <div className="guest-list-info hide-for-block-booking">
              <div className="content-group grey padding">
                <h2>Gjesteliste</h2>
                <p>
                  Vi må vite navnet på alle gjester. Fyll dem inn i feltene
                  under eller{' '}
                  <Link
                    className="red"
                    to="import-names"
                    onClick={(e) => handleOpenPasteInNamesModal(e)}
                  >
                    last opp gjestelisten her
                  </Link>
                  .<br />
                  <br />
                  Om du ikke vet hvem gjestene er enda, velger du rommene de
                  trenger og utsetter innsending av listen til senere.
                </p>
                <div className="row margin">
                  <Checkbox
                    name="postponeGuestList"
                    checked={postponeGuestList}
                    label="Utsett innsending av gjesteliste"
                    onChange={() => handlePostponeGuestList()}
                  />
                </div>
                <div className="row margin">
                  <Checkbox
                    name="protectedUnit"
                    checked={protectedUnit}
                    label="Jeg bestiller for en skjermet enhet og vil derfor ikke levere gjesteliste i Basen."
                    onChange={() => setProtectedUnit(!protectedUnit)}
                  />
                  {protectedUnit && (
                    <p className="red secret-unit" style={{ marginBottom: 0 }}>
                      Husk å levere gjesteliste på FISBasis før ankomst. Vær obs
                      på at ditt navn som bestiller vil komme inn på bookingen.
                    </p>
                  )}
                </div>
                <div className="row">
                  <Checkbox
                    name="guestsNotDefenceEmployees"
                    checked={guestsNotDefenceEmployees}
                    label="Jeg har med gjester som ikke jobber i forsvarssektoren, eller bestiller for andre enn meg selv"
                    onChange={() => handleGuestsNotDefenceEmployees()}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <h2>Velg romtype</h2>
        <div className="rooms margin">
          {!showWarningMessage && (
            <Loader isLoading={!availableRoomsLoaded}>
              {availableRoomsLoaded && (
                <RoomInfoWrapper
                  showModal={showModal}
                  numberOfRooms={roomInfoPerRoomType}
                  setNumberOfRooms={setRoomInfoPerRoomType}
                  availableRooms={selectedLocation.Rooms}
                  roomInfo={roomInfo}
                  setRoomInfo={setRoomInfo}
                  isBlockBooking={isBlockBooking}
                  onSubmit={nextSlide}
                  ref={inputRef}
                  noGuestList={postponeGuestList || protectedUnit}
                  userData={userData}
                  givenPriceList={undefined}
                />
              )}
            </Loader>
          )}
        </div>
        <h2 className="border-bottom">Din bestilling</h2>
        <div className="booking-summary">
          <BookingSummary
            arrivalDate={arrivalDate}
            departureDate={departureDate}
            roomInfo={roomInfo}
            numberOfGuests={numberOfGuests}
            selectedLocation={selectedLocation}
            roomInfoPerRoomType={roomInfoPerRoomType}
            isBlockBooking={isBlockBooking}
          />
          {!selectedLocation.NotOpenForBooking && (
            <Button
              className="float-right js-next"
              onClick={() => validateBedsAndRooms()}
              disabled={!availableRoomsLoaded}
              color="red"
            >
              Gå videre
            </Button>
          )}
        </div>
        <Space height="100px" />
      </div>
      <ModalWrapper>
        <PasteInNamesModal
          isOpen={pasteInNamesModalIsOpen}
          setPasteInNamesModalIsOpen={setPasteInNamesModalIsOpen}
          roomInfo={roomInfo}
          setRoomInfo={setRoomInfo}
        />
      </ModalWrapper>
    </div>
  );
};

export default GuestInfo;
