import './BookingColumns.scss';

import DOMPurify from 'dompurify';
import { formatDate, isBlockBooking, isSubBooking } from 'helpers/bookingHelpers';
import { ListColumnType } from 'types/listTypes';

import * as bookingConstants from '../../../constants/bookingEnums';
import { getMessages } from './BookingMessages';

const renderFirstLine = (item) => {
  if (isBlockBooking(item)) {
    return (
      <div>
        Arrangør av{" "}
        <span style={{ fontWeight: "bold" }}>
          {item.GroupInfo.EventName} : {item.GroupInfo.ReferenceKey}
        </span>
      </div>
    );
  } else if (isSubBooking(item)) {
    return (
      <div>
        Deltaker på{" "}
        <span style={{ fontWeight: "bold" }}>{item.GroupInfo.EventName}</span>
      </div>
    );
  }
  return <span style={{ fontWeight: "bold" }}>{item.Location}</span>;
};

const BookingColumns: ListColumnType[] = [
  {
    label: "Overnatting",
    name: "Stay",
    className: "stay",

    renderFunction: (item, counter, small) => (
      <div>
        {small ? (
          item.Location
        ) : (
          <div className="mainDiv" style={{ color: "black" }}>
            <div>{renderFirstLine(item)}</div>
            <div>
              {formatDate(item)} |{" "}
              {isBlockBooking(item) || isSubBooking(item)
                ? item.Location
                : item.TypeOfTravel}
            </div>
            <div className={getMessages(item).cls}>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    counter?.indexOf("-") >= 0
                      ? "Betalingen er ikke registrert, din bestilling er kanselert"
                      : getMessages(item).bookingStatus
                  ),
                }}
              />
              {item.RequestState ===
                bookingConstants.BookingStates.ROOMRESERVATION && (
                <strong>{counter?.indexOf("-") >= 0 ? "" : counter}</strong>
              )}
            </div>
          </div>
        )}
      </div>
    ),
  },
  {
    label: "Dato",
    name: "Date",
    renderFunction: (item) => formatDate(item),
    className: "date",
  },
  {
    label: "Type",
    name: "TypeOfTravel",
    className: "type",
  },
  {
    label: "Rom",
    name: "RoomCount",
    className: "roomCount",
    renderFunction: (item) => (
      <div className="roomCountDiv">{item.RoomInfo.length}</div>
    ),
  },
  {
    label: "Betaling",
    name: "Payment",
    className: "payment",
    renderFunction: (item) => (
      <div className="paymentDiv">
        <span className="paymentSpan">{`kr ${item.PaymentInfo.Price},- `}</span>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(getMessages(item).paymentMessage),
          }}
        />
      </div>
    ),
  },
  {
    label: "Status",
    name: "State",
    className: "state",
    renderFunction: (item, counter) => (
      <div>
        {getMessages(item).bookingStatus}
        <strong>{counter?.indexOf("-") >= 0 ? "" : counter}</strong>
      </div>
    ),
  },
];

export default BookingColumns;
