import { getReceptionPageTabsURL } from 'api/lock/lock_api';
import Loader from 'components/Loader/Loader';
import Nav from 'components/Nav/Nav';
import { useUser } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

type FireAndArrivalTabs = {
  ShowGuardPageTab: boolean;
  ShowArrivalListPageTab: boolean;
  ShowFireListPageTab: boolean;
};

const FireAndArrivalPage = () => {
  const { user, fetchUser, requestLoading: isLoading } = useUser();
  const navigate = useNavigate();
  const { sendRequest } = useAxios();

  const [tabs, setTabs] = useState<FireAndArrivalTabs>({
    ShowArrivalListPageTab: false,
    ShowFireListPageTab: false,
    ShowGuardPageTab: false,
  } as FireAndArrivalTabs);

  useEffect(() => {
    if (!user && !isLoading) fetchUser();
  }, [user]);

  const fetchTabs = async () => {
    await sendRequest(
      { method: 'GET', url: getReceptionPageTabsURL },
      (response) => {
        setTabs({
          ShowGuardPageTab: response.ShowGuardPageTab,
          ShowArrivalListPageTab: response.ShowArrivalListPageTab,
          ShowFireListPageTab: response.ShowFireListPageTab,
        });

        if (response.ShowGuardPageTab) navigate('/vaktside/tab-vaktside');
        else if (response.ShowArrivalListPageTab)
          navigate('/vaktside/tab-ankomstliste');
        else if (response.ShowFireListPageTab)
          navigate('/vaktside/tab-brannliste');
      },
    );
  };

  useEffect(() => {
    fetchTabs();
  }, [user]);

  return (
    <div>
      <Loader isLoading={isLoading}>
        <div className="tabs-wrapper">
          <div className="tabs">
            <ul className="navlinkWrapper">
              {tabs.ShowGuardPageTab && (
                <Nav
                  to="/vaktside/tab-vaktside"
                  alsoActiveWith={['/vaktside']}
                  text="Vaktside"
                />
              )}
              {tabs.ShowArrivalListPageTab && (
                <Nav to="/vaktside/tab-ankomstliste" text="Ankomstliste" />
              )}
              {tabs.ShowFireListPageTab && (
                <Nav to="/vaktside/tab-brannliste" text="Brannliste" />
              )}
            </ul>
          </div>
          <Outlet />
        </div>
      </Loader>
    </div>
  );
};

export default FireAndArrivalPage;
