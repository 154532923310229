// @flow

import { Component } from "react";
import connect from "helpers/connectWithRouter";
import _ from "lodash/fp";
import type { InspectionType } from "types/inspectionTypes";
import { fetchInspectionTypes } from "actions/inspectionActions";
import InspectionPage from "./InspectionPage";
import InspectionErrorList from "./InspectionErrorList";
import { DeliberateAny } from "types/DelibrateAny";
import Loader from "components/Loader/Loader";
import ButtonRow from "components/buttons/ButtonRow/ButtonRow";
import Slide from "components/Slides/Slide";
import { PropSlideNavigation } from "types/PropNavigationBase";
import SlidesWithoutRoute from "components/Slides/SlidesWithoutRoute";

type PropsType = PropSlideNavigation & {
  inspectionType: InspectionType;
  inspectionTypesLoaded: boolean;
  fetchInspectionTypes: (rentalobjectid: string, inspectionid: string) => void;
  inspectionForm: DeliberateAny;
};

class UnconnectedInspectionSlides extends Component {
  declare props: PropsType;

  state: {
    rentalId: string;
    inspectionId: string;
  };

  constructor(props) {
    super(props);

    this.state = {
      rentalId: this.props.params.rentalId || "",
      inspectionId: this.props.params.inspectionId || "",
    };

    if (this.props.params.inspectionId && this.props.params.rentalId) {
      this.props.fetchInspectionTypes(
        this.props.params.rentalId,
        this.props.params.inspectionId
      );
    }
  }

  startInspection = (type: InspectionType) => {
    const firstSlidePath = `${encodeURIComponent(type.CategoryCaption + '-' + type.Groups[0].Caption)}`;
    this.props.goToSlide(firstSlidePath);
  };

  allQuestions = () => {
    if (this.props.inspectionForm.Groups) {
      const myResponses: DeliberateAny = [];
      this.props.inspectionType.Groups.forEach((room) => {
        room.Elements.forEach((element) => {
          myResponses.push(element.Response);
        });
      });
      return myResponses;
    }
    return [];
  };

  answeredQuestions = () => {
    const questions = this.allQuestions();
    const responses = _.filter((x) => !!x, questions);
    return responses.length || 0;
  };

  numberOfQuestions = () => {
    const questions = this.allQuestions();
    return questions.length || 0;
  };

  render() {
    const answeredQuestions = this.answeredQuestions();
    const numberOfQuestions = this.numberOfQuestions();

    return (
      <Loader isLoading={!this.props.inspectionTypesLoaded}>
        <SlidesWithoutRoute startSlide="start-befaring">
          {!_.isEmpty(this.props.inspectionType) &&
            [
              {
                Caption: "",
                Code: "",
                Elements: [],
                IsAlwaysIncluded: true,
                Sortorder: -1,
              },
              ...this.props.inspectionType.Groups,
            ].map((room, i) => {
              const basePath = `${this.props.inspectionType.CategoryCaption}-`;
              const slidePath = encodeURIComponent(basePath + room.Caption.replace(/\//g, ""));

              let prevSlidePath = "start-befaring";
              const prevSlideCaption = this.props.inspectionType.Groups[i-2]?.Caption.replace(/\//g, "");
              if(prevSlideCaption){
                prevSlidePath = encodeURIComponent(basePath + prevSlideCaption);
              }
              const nextCaption =
                i < this.props.inspectionType.Groups.length
                  ? this.props.inspectionType.Groups[i].Caption.replace(
                      /\//g,
                      ""
                    )
                  : "";

              const nextSlidePath =
                i < this.props.inspectionType.Groups.length &&
                `${basePath}${nextCaption}`;

              // Start slide
              if (room.Sortorder === -1) {
                return (
                  <Slide path="start-befaring" key="start-befaring">
                    <div className="row margin">
                      <h1>Klar for befaring</h1>
                      {answeredQuestions > 0 ? (
                        <p>
                          Du har allerede besvart {answeredQuestions} av{" "}
                          {numberOfQuestions} spørsmål . Disse kan du hoppe over
                          om du ikke ønsker å endre svaret ditt.
                        </p>
                      ) : (
                        <p>
                          Du skal nå starte din befaring. Avvik på renhold må
                          meldes direkte ved å sende e-post til flo.ren@mil.no.
                          PS! Det kan være punkter som ikke er relevant for ditt
                          tildelte kvarter. F.eks. kan inventarlisten i
                          befaringen avvike fra det som er standard for ditt
                          kvarter.
                        </p>
                      )}
                    </div>

                    <div className="margin">
                      <InspectionErrorList rentalId={this.state.rentalId} />
                    </div>

                    <strong>{this.props.inspectionType.CategoryCaption}</strong>
                    <ButtonRow>
                      <ButtonRow.Left
                        onClick={() =>
                          this.startInspection(this.props.inspectionType)
                        }
                        color="red"
                      >
                        Start befaring
                      </ButtonRow.Left>
                    </ButtonRow>
                    <InspectionPage room={room}
                    rentalId={this.state.rentalId}
                    inspectionId={this.state.inspectionId}
                    nextSlidePath={nextSlidePath}
                    goToSlide={this.props.goToSlide}
                    onlyModals
                    />
                  </Slide>
                );
              }

              return (
                <Slide
                  key={slidePath}
                  path={slidePath}
                  prevSlidePath={prevSlidePath}
                  title={`${this.props.inspectionType.CategoryCaption} - ${room.Caption}:`}
                  showCount
                >
                  <InspectionPage
                    room={room}
                    rentalId={this.state.rentalId}
                    inspectionId={this.state.inspectionId}
                    nextSlidePath={nextSlidePath}
                    goToSlide={this.props.goToSlide}
                  />
                </Slide>
              );
            })}
        </SlidesWithoutRoute>
      </Loader>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    inspectionForm: state.inspection.inspectionForm,
    inspectionType: state.inspection.inspectionTypes,
    inspectionTypesLoaded: state.inspection.inspectionTypesLoaded,
  }),
  {
    fetchInspectionTypes,
  }
)(UnconnectedInspectionSlides);
