// @flow

import {
  fetchPortfolioSearch,
  updatePortfolioFilter,
} from "actions/myPortfolioActions";
import Loader from "components/Loader/Loader";
import connect from "helpers/connectWithRouter";
import { buildingColumns } from "pages/BasenProff/TabMyPortfolio/PortfolioColumns";
import { Component } from "react";
import { Link } from "react-router-dom";
import { DeliberateAny } from "types/DelibrateAny";

import PortfolioFilter from '../PortfolioFilter/PortfolioFilter';
import PortfolioList from '../PortfolioList/PortfolioList';

import type {
  PortfolioFilterType,
  PortfolioFilterMetaDataType,
  PortfolioSearchType,
  PortfolioFavoritesType,
} from "types/myPortfolioTypes";
type PropsType = {
  search: PortfolioSearchType;
  favorites: PortfolioFavoritesType;
  fetchPortfolioSearch: (
    filter: PortfolioFilterType,
    metaData: PortfolioFilterMetaDataType
  ) => void;
  updatePortfolioFilter: (
    key: string,
    value: string | number | boolean
  ) => void;
  setPortfolioSearch: (val: boolean) => void;
};

export class UnconnectedPortfolioSearch extends Component {
  declare props: PropsType;

  onSortColumn = (columnName: string) => {
    this.props.updatePortfolioFilter("sortByColumn", columnName);
    this.props.fetchPortfolioSearch(
      { ...this.props.search.filter, sortByColumn: columnName },
      this.props.search.filterMetaData
    );
  };

  onChangePageNumber = (pageNumber: number) => {
    this.props.updatePortfolioFilter("pageNumber", pageNumber);
    this.props.fetchPortfolioSearch(
      { ...this.props.search.filter, pageNumber },
      this.props.search.filterMetaData
    );
  };

  applySearch = () => {
    const firstPage = 1;
    this.props.updatePortfolioFilter("pageNumber", firstPage);
    this.props.fetchPortfolioSearch(
      { ...this.props.search.filter, pageNumber: firstPage },
      this.props.search.filterMetaData
    );
  };

  previousSlide = (event) => {
    event.preventDefault();
    this.props.setPortfolioSearch(false);
  };

  render() {
    return (
      <div>
        <div className="slide-navigation sticky-top max-width-large">
          {/* <a href="Tilbake" className="prev js-prev">
            <span className="hide-for-small">Tilbake</span>
          </a> */}
          <Link
            to="Tillbake"
            onClick={(e) => this.previousSlide(e)}
            className="prev js-prev"
          >
            <span className="hide-for-small">Tilbake</span>
          </Link>
          <h2>Legg til bygning</h2>
        </div>

        <div className="sticky-middle scrollable max-width-large">
          <h1>Legg til bygning</h1>
          <div className="row double-margin">
            <PortfolioFilter applySearch={this.applySearch} />
          </div>

          <div className="row">
            <Loader
              isLoading={
                this.props.search.isLoading || this.props.favorites.isLoading
              }
            >
              <PortfolioList
                items={this.props.search.items}
                updatePortfolioFilter={this.props.updatePortfolioFilter}
                controlledSortOptions={{
                  onSortColumn: this.onSortColumn,
                  sortByColumn: this.props.search.filter.sortByColumn,
                }}
                columns={buildingColumns}
                serverPaging={{
                  onChangePageNumber: this.onChangePageNumber,
                  pageNumber: this.props.search.filter.pageNumber,
                  pageSize: this.props.search.filter.pageSize,
                  numItems: this.props.search.items.length,
                }}
                noItemsMessage={
                  this.props.search.firstSearch ? (
                    ""
                  ) : (
                    <p>Fant ingen bygninger eller uteareal med valgt filter.</p>
                  )
                }
              />
            </Loader>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    search: state.myPortfolio.search,
    favorites: state.myPortfolio.favorites,
  }),
  {
    updatePortfolioFilter,
    fetchPortfolioSearch,
  }
)(UnconnectedPortfolioSearch);
