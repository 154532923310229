import Slide from 'components/Slides/Slide';
import { useUser } from 'contexts/index';

import ReportingStart from '../ReportingStart';

const StartPageSlide = () => {
  const { hasProffAccess } = useUser();

  if (!hasProffAccess) {
    window.location.href = '/#/reporting/faultform';
  }

  return <Slide path="">{hasProffAccess ? <ReportingStart /> : null}</Slide>;
};

export default StartPageSlide;
