import './PortfolioRenterList.scss';

import List from "components/List/List";
import ListItem from "components/ListItem/ListItem";
import Loader from "components/Loader/Loader";
import { Component } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import type { PortfolioRenterItemType } from "types/myPortfolioTypes";

type PropsType = {
  items: Array<PortfolioRenterItemType>;
  isLoading: boolean;
  totalBTA: number;
};

class PortfolioRenterList extends Component {
  declare props: PropsType;

  setupColumns = () => {
    const columns = [
      {
        label: "Leietakere",
        name: "TenantName",
        className: "tenant-name",
      },
      { label: "BRA", name: "BRA", className: "bra" },
      { label: "Leie-ID", name: "RentalId", className: "rental-id" },
      {
        label: "Leid areal",
        name: "RentedArea",
        suffix: "m²",
        className: "rented-area",
      },
      {
        label: "Leid i %",
        name: "RentedPercentage",
        renderFunction: (renter: PortfolioRenterItemType) => (
          <div>
            {this.props.totalBTA
              ? `${Math.round(
                  (renter.RentedArea / this.props.totalBTA) * 100
                )} %`
              : "N/A"}
          </div>
        ),
        className: "rented-percentage",
      },
      { label: "Arealtype", name: "AreaType", className: "area-type" },
      {
        label: "Status på kontrakt",
        name: "ContractStatus",
        className: "contract-status",
      },
    ];
    return columns;
  };

  renderItem = (item: PortfolioRenterItemType) => (
    <ListItem
      key={item.RentalId}
      item={item}
      columns={this.setupColumns() as DeliberateAny[]}
    />
  );

  render() {
    return (
      <Loader isLoading={this.props.isLoading}>
        <div className="portfolio-renter-list">
          <List
            items={this.props.items}
            columns={this.setupColumns() as DeliberateAny[]}
            renderItem={this.renderItem}
            noItemsMessage={<p>Fant ingen leietakere</p>}
          />
        </div>
      </Loader>
    );
  }
}

export default PortfolioRenterList;
