import './Split.scss';

import { CloseIcon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import Button from 'components/buttons/Button/Button';
import { FC } from 'react';
import { IWrapper } from 'src/interfaces/IWrapper';

export enum SplitPosition {
  Top = 'top',
  Bottom = 'bottom',
}

interface ISplitProps extends IWrapper {
  position: SplitPosition;
  hide?: boolean;
  className?: string;
  onClose?: () => void;
}

const Split: FC<ISplitProps> = ({
  position,
  children,
  hide,
  onClose,
  className,
}) => {
  return (
    <div
      className={`split split-${position} ${hide ? 'hidden' : ''} ${className ?? ''}`}
    >
      {children}
      {onClose && (
        <BasenIcon
          className="close-btn"
          icon={CloseIcon}
          size={'s'}
          onClick={onClose}
        />
      )}
    </div>
  );
};

export default Split;
