import { kundenummerURL, ledigeRomMedPrisURL, resendReceiptURL } from 'api/booking/booking_api';
import GuestList from 'components/booking/GuestList';
import PasteInNames from 'components/booking/PasteInNames';
import RoomInfoWrapper from 'components/booking/RoomInfoWrapper';
import { validateFields, validateRooms } from 'components/booking/ValidationFunctions';
import DateInput from 'components/DateInput/DateInput';
import Input from 'components/form/Input/Input';
import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import Loader from 'components/Loader/Loader';
import Modal from 'components/modals/Modal/Modal';
import NumberInput from 'components/NumberInput/NumberInput';
import { useModal, useUser } from 'contexts/index';
import {
    getDeadlineForPayment, getMinDate, isBlockBooking, isSubBooking
} from 'helpers/bookingHelpers';
import _ from 'lodash/fp';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';
import { v4 as uuidv4 } from 'uuid';

import { orgnrBRlookup } from '../../../api/core/core_api';
import { requestNewKeyMyPageURL } from '../../../api/lock/lock_api';
import * as bookingConstants from '../../../constants/bookingEnums';
import useAxios, { axiosFetch } from '../../../hooks/useAxios';

export const ChangeBookingModal = ({
  item,
  availableRooms,
  handleSubmit,
  isOpen,
  setChangeBookingIsOpen,
  roomBookingOptions,
  numberOfRooms,
  setNumberOfRooms,
  setAvailableRooms,
  isLoading,
}: DeliberateAny) => {
  const { user } = useUser();
  const [showModal, setShowModal] = useState({
    title: '',
    text: '',
    show: false,
  });
  const { sendRequest } = useAxios();
  const [roomInfo, setRoomInfo] = useState(item.RoomInfo);
  const [numberOfGuests, setNumberOfGuests] = useState(
    item.GroupInfo.GuestCount,
  );
  const [arrivalDate, setArrivalDate] = useState(moment(item.ArrivalDate));
  const [departureDate, setDepartureDate] = useState(
    moment(item.DepartureDate),
  );
  const inputRef = useRef<any>();

  const [priceList, setCurrentPriceList] = useState(
    availableRooms.filter((availableRoom) =>
      item.RoomInfo.some(
        (itemRoom) => availableRoom.RoomTypeId === itemRoom.RoomTypeId,
      ),
    ),
  );

  const fetchAvailableRooms = async () => {
    const arrival = `${arrivalDate.format('YYYY-MM-DDT00:00:00.000')}Z`;
    let departure;

    if (arrivalDate >= departureDate) {
      departure = `${arrivalDate
        .clone()
        .add(1, 'days')
        .format('YYYY-MM-DDT00:00:00.000')}Z`;
    } else {
      departure = `${departureDate.format('YYYY-MM-DDT00:00:00.000')}Z`;
    }

    await sendRequest(
      {
        method: 'GET',
        url: ledigeRomMedPrisURL,
        params: {
          locationId: item.LocationId,
          arrivalDate: arrival,
          departureDate: departure,
        },
      },
      (response) => {
        response.reverse();
        setAvailableRooms(response);
      },
    );
  };

  useEffect(() => {
    fetchAvailableRooms();
    const priceListForRoom = availableRooms.filter((availableRoom) =>
      item.RoomInfo.some(
        (itemRoom) => availableRoom.RoomTypeId === itemRoom.RoomTypeId,
      ),
    );

    setCurrentPriceList(priceListForRoom);
  }, [arrivalDate, departureDate]);

  const updateItem = () => {
    const copyOfItem = _.cloneDeep(item);
    copyOfItem.RoomInfo = roomInfo;
    copyOfItem.GroupInfo.GuestCount = numberOfGuests;
    copyOfItem.ArrivalDate = arrivalDate.toISOString(true).substring(0, 19);
    copyOfItem.DepartureDate = departureDate.toISOString(true).substring(0, 19);

    handleSubmit(copyOfItem, 'endre');
  };

  const validateBedsAndRooms = () => {
    const guestCount = numberOfGuests;
    const validated = validateRooms(
      roomInfo,
      guestCount,
      isBlockBooking(item),
      setShowModal,
    );
    if (validated) {
      inputRef.current.handleSubmit();
    }
  };

  const handleCancelModal = () => {
    setRoomInfo(_.cloneDeep(item.RoomInfo));
    setNumberOfGuests(item.GroupInfo.GuestCount);
    setNumberOfRooms(
      roomBookingOptions.map((room) => ({
        id: room.RoomTypeId,
        name: room.Name,
        count: item.RoomInfo.filter(
          (bookedRoom) => bookedRoom.RoomTypeId === room.RoomTypeId,
        ).length,
      })),
    );

    setArrivalDate(moment(item.ArrivalDate));
    setDepartureDate(moment(item.DepartureDate));
    setChangeBookingIsOpen(false);
  };

  return (
    <Modal
      title="Endre overnatting"
      ariaLabel="Endre overnatting"
      isOpen={isOpen}
      isLoading={isLoading}
      submit={{
        text: 'Lagre',
        onClick: () => validateBedsAndRooms(),
        ignoreOnClose: true,
      }}
      cancel={{
        text: 'Avbryt',
        onClick: () => handleCancelModal(),
      }}
      size="large"
    >
      <Loader isLoading={isLoading}>
        <div className={isBlockBooking(item) ? 'is-block-booking' : undefined}>
          <div
            className={showModal.show ? 'no-scroll' : 'scrollable'}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div className="margin booking-settings" style={{ width: '100%' }}>
              <NumberInput
                name="NumberOfGuests"
                title="Antall gjester"
                value={numberOfGuests}
                minValue={1}
                small
                onChange={(name, value) => setNumberOfGuests(value)}
              />

              <DateInput
                title="Ankomst"
                name="arrival"
                minDate={getMinDate()}
                selectedDate={arrivalDate}
                text={
                  <span>
                    <strong>NB!</strong> For å bestille overnatting med mindre
                    enn to dager til ankomst, vennligst{' '}
                    <a href="/#/contact">kontakt Forsvarsbygg Servicesenter</a>.
                  </span>
                }
                selectionSpan={365}
                onSelectDate={setArrivalDate}
                disableWeekends={false}
                small
                disabled={(isBlockBooking(item) || isSubBooking(item)) && true}
                placement="lower"
              />
              <DateInput
                title="Avreise"
                name="departure"
                startDate={arrivalDate}
                selectedDate={departureDate}
                maxDurationInDays={60}
                durationValidationMessage={
                  <span>
                    <strong>NB!</strong> Kortidsovernatting kan ikke vare mer
                    enn to måneder.
                  </span>
                }
                onSelectDate={setDepartureDate}
                small
                disabled={(isBlockBooking(item) || isSubBooking(item)) && true}
                placement="lower"
              />
            </div>
            {!isBlockBooking(item) && (
              <PasteInNames
                setRoomInfo={setRoomInfo}
                roomInfo={roomInfo}
                bookingModal={false}
              />
            )}
            <RoomInfoWrapper
              showModal={setShowModal}
              numberOfRooms={numberOfRooms}
              setNumberOfRooms={setNumberOfRooms}
              availableRooms={availableRooms}
              roomInfo={roomInfo}
              setRoomInfo={setRoomInfo}
              isBlockBooking={isBlockBooking(item)}
              onSubmit={updateItem}
              ref={inputRef}
              noGuestList
              givenPriceList={priceList}
              userData={user}
            />
          </div>
        </div>
      </Loader>
      <Modal
        title={showModal.title}
        isOpen={showModal.show}
        onClose={() => setShowModal({ title: '', text: '', show: false })}
      >
        <p>{showModal.text}</p>
      </Modal>
    </Modal>
  );
};

export const ManageGuestsModal = ({
  item,
  handleSubmit,
  isOpen,
  setManageGuestListIsOpen,
  roomBookingOptions,
  numberOfRooms,
}: DeliberateAny) => {
  const [showModal, setShowModal] = useState({
    title: '',
    text: '',
    show: false,
  });
  const [roomInfo, setRoomInfo] = useState(_.cloneDeep(item.RoomInfo));
  const [validField, setValidField] = useState(
    roomInfo.map((room) => ({
      id: room.Id,
      guests:
        room.NameListInfo.NameListItems &&
        room.NameListInfo.NameListItems.map(() => ({
          firstName: true,
          lastName: true,
          phone: true,
          email: true,
        })),
    })),
  );
  const [isSwitching, setIsSwitching] = useState(false);
  const [switchFrom, setSwitchFrom] = useState({
    Id: '',
    Switch: [false, false],
  });

  const updateItem = () => {
    if (validateFields(roomInfo, setValidField, false, true, setShowModal)) {
      const copyOfItem = _.cloneDeep(item);
      copyOfItem.RoomInfo = roomInfo;
      setManageGuestListIsOpen(false);
      handleSubmit(copyOfItem, 'endre');
    }
  };

  const handleCancelModal = () => {
    setRoomInfo(_.cloneDeep(item.RoomInfo));
    setManageGuestListIsOpen(false);
  };

  return (
    <Modal
      title="Endre gjesteliste"
      ariaLabel="Endre gjesteliste"
      isOpen={isOpen}
      submit={{
        text: 'Lagre',
        onClick: () => updateItem(),
        ignoreOnClose: true,
      }}
      cancel={{
        text: 'Avbryt',
        onClick: () => handleCancelModal(),
      }}
      size="large"
    >
      <PasteInNames
        setRoomInfo={setRoomInfo}
        roomInfo={roomInfo}
        bookingModal={false}
      />
      <div
        className={
          isSwitching
            ? 'guest-list-container margin is-switching'
            : 'guest-list-container margin'
        }
      >
        {roomBookingOptions &&
          roomBookingOptions.map((roomType) => {
            if (
              numberOfRooms.find((room) => room.name === roomType.Name).count >
              0
            ) {
              return (
                <div
                  key={roomType.RoomTypeId}
                  className={
                    numberOfRooms.find((room) => room.name === roomType.Name)
                      .count > 0
                      ? 'room has-rooms'
                      : 'room'
                  }
                  style={{ width: '100%' }}
                >
                  <div className="room-wrapper double-margin">
                    <div
                      className="room-description wrapper-grey"
                      style={{ padding: '11px' }}
                    >
                      <h3 className="no-margin">{roomType.Name}</h3>
                    </div>
                    <GuestList
                      roomInfo={roomInfo}
                      roomArray={roomInfo.filter(
                        (room) => room.RoomTypeId === roomType.RoomTypeId,
                      )}
                      setRoomInfo={setRoomInfo}
                      isSwitching={isSwitching}
                      setIsSwitching={setIsSwitching}
                      switchFrom={switchFrom}
                      setSwitchFrom={setSwitchFrom}
                      simplified
                      validField={validField}
                      availableRoom={roomType}
                      numberOfRooms={undefined}
                      setNumberOfRooms={undefined}
                      setValidField={undefined}
                      priceList={undefined}
                    />
                  </div>
                </div>
              );
            }
            return false;
          })}
      </div>
      <Modal
        title={showModal.title}
        isOpen={showModal.show}
        onClose={() => setShowModal({ title: '', text: '', show: false })}
      >
        <p>{showModal.text}</p>
      </Modal>
    </Modal>
  );
};

export const PurposeModal = ({
  isOpen,
  setPurposeModalIsOpen,
  item,
  handleSubmit,
}: DeliberateAny) => {
  const [name, setName] = useState(item.GroupInfo.EventName);
  const [description, setDescription] = useState(item.GroupInfo.Reason);

  const handleClick = () => {
    const copyOfItem = _.cloneDeep(item);
    copyOfItem.GroupInfo.EventName = name;
    copyOfItem.GroupInfo.Reason = description;
    setPurposeModalIsOpen(false);
    handleSubmit(copyOfItem, 'endre');
  };

  return (
    <Modal
      title="Endre"
      ariaLabel="Endre"
      isOpen={isOpen}
      submit={{
        text: 'Lagre',
        onClick: () => handleClick(),
        ignoreOnClose: true,
      }}
      cancel={{
        text: 'Avbryt',
        onClick: () => setPurposeModalIsOpen(false),
      }}
      size="medium"
    >
      <div className="row margin">
        <label>
          <span>Navn</span>
          <input
            type="text"
            name="eventName"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
      </div>
      <div className="row margin">
        <label>
          <span>Beskrivelse</span>
          <textarea
            name="reason"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>
      </div>
    </Modal>
  );
};

export const KeepRoomsModal = ({
  isOpen,
  setKeepRoomsModalIsOpen,
  item,
  handleSubmit,
  userData,
}: DeliberateAny) => {
  const [whoPays, setWhoPays] = useState('me');
  const [IONumber, setIONumber] = useState('');
  const [customerNumbers, setCustomerNumbers] = useState<DeliberateAny>([]);
  const [selectedCustomerNumber, setSelectedCustomerNumber] = useState({
    key: -1,
    value: 'Velg avdeling',
  });
  const [organisationNumber, setOrganisationNumber] = useState('');
  const [businessName, setBusinessName] = useState({
    value: '',
    placeholder: 'Hentes automatisk fra Org. nr',
  });
  const [billingAddress, setBillingAddress] = useState('');
  const [billingZipCode, setBillingZipCode] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [invoiceManager, setInvoiceManager] = useState('');
  const [validation, setValidation] = useState({
    zipCode: true,
    city: true,
    IONumber: true,
    selectedCustomerNumber: true,
    organisationNumber: true,
    businessName: true,
    address: true,
    bookingName: true,
  });
  const [validationInvalidText, setValidationInvalidText] = useState({
    IONumber: true,
    organisationNumber: true,
  });

  const { sendRequest } = useAxios();

  const fetchUserNumbers = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: kundenummerURL,
      },
      (response) => {
        setCustomerNumbers(
          response.map((cusNo) => ({
            key: cusNo.Number,
            value: cusNo.Department,
          })),
        );
      },
    );
  };

  useEffect(() => {
    fetchUserNumbers();
  }, []);

  const handleClick = () => {
    const copyOfItem = _.cloneDeep(item);
    copyOfItem.GroupInfo.KeepRooms = true;
    if (whoPays === 'me') {
      copyOfItem.PaymentInfo.PaymentMethod =
        bookingConstants.PaymentMethods.PAYBYCARD;
    } else {
      copyOfItem.PaymentInfo.PaymentMethod =
        bookingConstants.PaymentMethods.PAYBYINVOICE;
    }

    if (whoPays === 'defense') {
      copyOfItem.GroupInfo.CustomerName = selectedCustomerNumber.value;
      copyOfItem.GroupInfo.CustomerNumber = selectedCustomerNumber.key;
      copyOfItem.GroupInfo.IONumber = IONumber;
    } else if (whoPays === 'others') {
      copyOfItem.GroupInfo.BillOwner = invoiceManager;
      copyOfItem.GroupInfo.BillingAddress = billingAddress;
      copyOfItem.GroupInfo.BillingLocation = billingCity;
      copyOfItem.GroupInfo.BillingZipCode = billingZipCode;
      copyOfItem.GroupInfo.ContactPerson = organisationNumber;
      copyOfItem.GroupInfo.CustomerName = businessName.value;
    }
    handleSubmit(copyOfItem, 'beholdrom');
    setKeepRoomsModalIsOpen(false);
  };

  const fetchOrganisationNumber = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: `${orgnrBRlookup}/${organisationNumber}`,
      },
      (response) => {
        setBusinessName({ ...businessName, value: response.Name });
      },
    ).catch(() => {
      setBusinessName({
        ...businessName,
        placeholder: 'Ingen match i Brønnøysundregisteret',
      });
    });
  };

  const validateInputFields = () => {
    // check invalid
    const validationObj = { ...validation };

    validationObj.IONumber = whoPays === 'defense' ? IONumber.length > 0 : true;
    validationObj.selectedCustomerNumber =
      whoPays === 'defense' ? selectedCustomerNumber.key > 0 : true;
    validationObj.organisationNumber =
      whoPays === 'others' ? organisationNumber.length > 0 : true;
    validationObj.businessName =
      whoPays === 'others' ? businessName.value.length > 0 : true;
    validationObj.address =
      whoPays === 'others' ? billingAddress.length > 0 : true;
    validationObj.zipCode =
      whoPays === 'others' ? billingZipCode.length > 0 : true;
    validationObj.city = whoPays === 'others' ? billingCity.length > 0 : true;

    setValidation(validationObj);

    // check invalid text
    const validationInvalidTextObj = { ...validationInvalidText };

    validationInvalidTextObj.IONumber =
      whoPays === 'defense' && IONumber.length > 0
        ? IONumber.length === 10 &&
          (IONumber.startsWith('45') || IONumber.startsWith('47'))
        : true;
    validationInvalidTextObj.organisationNumber =
      whoPays === 'others' && organisationNumber.length > 0
        ? organisationNumber.length === 9 && /^[0-9]+$/.test(organisationNumber)
        : true;

    setValidationInvalidText(validationInvalidTextObj);

    if (
      Object.keys(validationObj).every((k) => validationObj[k]) &&
      Object.keys(validationInvalidTextObj).every(
        (k) => validationInvalidTextObj[k],
      )
    ) {
      handleClick();
    }
  };

  return (
    <Modal
      title="Behold rom"
      ariaLabel="Behold rom"
      isOpen={isOpen}
      submit={{
        text: 'Lagre',
        onClick: () => validateInputFields(),
        ignoreOnClose: true,
      }}
      cancel={{
        text: 'Avbryt',
        onClick: () => setKeepRoomsModalIsOpen(false),
      }}
      size="medium"
    >
      <p className="margin">
        Du kan holde reservasjonen åpen til sene bestillere om du legger inn
        betalingsinformasjon. Du vil bli belastet for alle rom som deltakerne
        dine ikke benytter seg av.
      </p>

      <div className="row">
        <label className="margin">
          <span>Hvem betaler?</span>
          <div className="custom-select">
            <select
              value={whoPays}
              onChange={(e) => setWhoPays(e.target.value)}
            >
              <option value="me">{userData.Name}</option>
              <option value="defense">
                Faktura til Forsvaret med IO-nummer
              </option>
              <option value="others">
                Faktura til andre virksomheter, (ikke privatpersoner)
              </option>
            </select>
          </div>
        </label>
        {whoPays === 'me' && (
          <div data-triggered-by="me">
            <div className="content-group padded grey margin">
              <p className="half-margin">
                Betalingsmåte: <strong>Betal med kort på nett</strong>
              </p>
            </div>
          </div>
        )}
        {whoPays === 'defense' && customerNumbers.length > 0 && (
          <div>
            <p className="margin">
              Ansvarlig bestiller: {userData.Name} <br />
              Ansattnummer: {userData.EmployeeNumber}
            </p>
            <div className="content-group padded grey margin">
              <div className="margin">
                <label
                  className={`${validation.IONumber ? '' : 'invalid'}${
                    validationInvalidText.IONumber ? '' : 'invalid invalid-text'
                  }`}
                >
                  <span>IO - innkjøpsordrenummer</span>
                  <input
                    type="text"
                    value={IONumber}
                    name="IONumber"
                    max="10"
                    min="10"
                    onChange={(e) => setIONumber(e.target.value)}
                  />
                </label>
              </div>
              <label
                htmlFor="customerNumber"
                className={validation.selectedCustomerNumber ? '' : 'invalid'}
              >
                <span>Avdeling som skal faktureres</span>
                <div className="custom-select">
                  <select
                    value={selectedCustomerNumber.key}
                    name="customerNumber"
                    onChange={(e) =>
                      setSelectedCustomerNumber(
                        customerNumbers.find(
                          (cusNo) => cusNo.key === e.target.value.toString(),
                        ),
                      )
                    }
                  >
                    <option value="-1" disabled>
                      Velg avdeling
                    </option>
                    {customerNumbers.map((cusNo) => (
                      <option key={cusNo.key} value={cusNo.key}>
                        {cusNo.value}
                      </option>
                    ))}
                  </select>
                </div>
              </label>
            </div>
          </div>
        )}
        {whoPays === 'others' && (
          <div className="content-group padded grey margin">
            <div className="flex-row margin">
              <label
                className={`${validation.organisationNumber ? '' : 'invalid'}${
                  validationInvalidText.organisationNumber
                    ? ''
                    : 'invalid invalid-text'
                }`}
              >
                <span>Org.nr</span>
                <input
                  type="text"
                  placeholder="9 siffer - Eks. 975950662"
                  name="organisationNumber"
                  max="9"
                  min="9"
                  value={organisationNumber}
                  onChange={(e) => setOrganisationNumber(e.target.value)}
                  onBlur={() => fetchOrganisationNumber()}
                />
              </label>
              <label className={validation.businessName ? '' : 'invalid'}>
                <span>Virksomhet</span>
                <input
                  type="text"
                  placeholder={businessName.placeholder}
                  name="business"
                  value={businessName.value}
                  disabled
                />
              </label>
              <label className={validation.address ? '' : 'invalid'}>
                <span>Fakturaadresse</span>
                <input
                  type="text"
                  name="invoiceAddress"
                  value={billingAddress}
                  onChange={(e) => setBillingAddress(e.target.value)}
                />
              </label>
            </div>
            <div className="flex-row">
              <label className={validation.zipCode ? '' : 'invalid'}>
                <span>Postnummer</span>
                <input
                  type="text"
                  name="zipCode"
                  value={billingZipCode}
                  onChange={(e) => setBillingZipCode(e.target.value)}
                />
              </label>
              <label className={validation.city ? '' : 'invalid'}>
                <span>Sted</span>
                <input
                  type="text"
                  name="city"
                  value={billingCity}
                  onChange={(e) => setBillingCity(e.target.value)}
                />
              </label>
              <label className="">
                <span>Fakturaansvarlig</span>
                <input
                  type="text"
                  name="InvoiceManager"
                  value={invoiceManager}
                  onChange={(e) => setInvoiceManager(e.target.value)}
                />
              </label>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export const CancelBlockBookingAfterDeadline = ({
  isOpen,
  setCancelBlockBookingAfterDeadlineModalIsOpen,
  handleShowTermsAndConditions,
  item,
  handleSubmitCancellation,
}: DeliberateAny) => {
  const handleClick = () => {
    handleSubmitCancellation();
    setCancelBlockBookingAfterDeadlineModalIsOpen(false);
  };

  return (
    <Modal
      title="Avbestill booking"
      ariaLabel="Avbestill booking"
      isOpen={isOpen}
      submit={{
        text: 'Avbestill',
        onClick: () => handleClick(),
        ignoreOnClose: true,
      }}
      cancel={{
        text: 'Avbryt',
        onClick: () => setCancelBlockBookingAfterDeadlineModalIsOpen(false),
      }}
      size="medium"
    >
      <p className="margin">
        <span>
          Det er under {getDeadlineForPayment()} dager til ankomst, og en
          avbestilling nå vil føre til kostnader for deg som arrangør. Som
          arrangør vil en avbestilling etter fristen føre til at arrangør blir
          belastet for 100% av avtalt pris, inkludert for delbestillingene
          knyttet til reservasjonen. Deltakerne blir da frigitt sin bestilling.
          (Vilkår for overnatting{' '}
          <Link
            to="Vilkår for overnatting"
            onClick={(e) => handleShowTermsAndConditions(e)}
          >
            Vilkår for overnatting
          </Link>{' '}
          Pt 5: Avbestilling)
        </span>
      </p>
      {!item.GroupInfo.KeepRooms && (
        <p>Oppgi betalingsinformasjon for å fullføre avbestillingen</p>
      )}
    </Modal>
  );
};

export const CancelReservation = ({
  isOpen,
  setCancelReservationModalIsOpen,
  handleSubmitCancellation,
}: DeliberateAny) => {
  const handleClick = () => {
    handleSubmitCancellation();
    setCancelReservationModalIsOpen(false);
  };

  return (
    <Modal
      title="Avbestill booking"
      ariaLabel="Avbestill booking"
      isOpen={isOpen}
      submit={{
        text: 'Avbestill',
        onClick: () => handleClick(),
        ignoreOnClose: true,
      }}
      cancel={{
        text: 'Avbryt',
        onClick: () => setCancelReservationModalIsOpen(false),
      }}
      size="medium"
    >
      <p>Er du sikker på at du vil avbestille denne bookingen?</p>
    </Modal>
  );
};

export const GuestListModal = ({
  isOpen,
  setGuestListModalIsOpen,
  subBooking,
  position,
  guestListModalIsOpen,
  requirements,
}: DeliberateAny) => {
  const handleCloseModal = () => {
    const newArray = [...guestListModalIsOpen];
    newArray[position] = false;
    setGuestListModalIsOpen(newArray);
  };

  return (
    <Modal
      title="Gjesteliste"
      ariaLabel="Gjesteliste"
      isOpen={isOpen}
      onClose={() => handleCloseModal()}
      size="medium"
    >
      <div className="print-guest-list">
        <ul>
          {subBooking.RoomInfo.map((room, i) => (
            <li key={room.Id} className="guest-list-room">
              <p className="room-index red no-margin">{`Rom ${i + 1}`}</p>
              <h3>{room.RoomTypeName}</h3>
              <table className="names half-margin">
                <tbody>
                  <tr className="list-header">
                    <th className="firstname left-align">Fornavn</th>
                    <th className="left-align">Etternavn</th>
                    <th className="left-align">E-post</th>
                    <th className="left-align">Mobiltelefon</th>
                  </tr>
                  {room.NameListInfo.NameListItems.map((guest) => (
                    <tr key={uuidv4()}>
                      <td data-th="Fornavn" className="">
                        {` ${guest.FirstName}`}
                      </td>
                      <td data-th="Etternavn">{` ${guest.LastName}`}</td>
                      <td data-th="E-post">{` ${guest.Email}`}</td>
                      <td data-th="Telefon">{` ${guest.Phone}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {requirements ? (
                <div className="requirements">
                  <p className="no-margin">
                    <strong>Tilpasninger</strong>
                  </p>
                  <p className="no-margin">{room.Requirements}</p>
                </div>
              ) : (
                ''
              )}
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
};

export const SendNewKeyModal = ({
  isOpen,
  setSendNewKeyModalIsOpen,
  booking,
}: DeliberateAny) => {
  const { showModal } = useModal();
  const requestUrl = requestNewKeyMyPageURL;
  const [chosenGuest, setChosenGuest] = useState({
    phone: '+47',
    userReference: '',
  });
  const isValidPhonenumber = () =>
    /^(?:\+47|0047|)\d{8}$/.test(chosenGuest.phone);
  const guestLookup = booking.BookingGuests
    ? booking.BookingGuests.reduce((map, obj) => {
        const newMap = { ...map };
        newMap[obj.Reference] = obj;
        return newMap;
      }, {})
    : [];

  const handleCloseModal = () => {
    setSendNewKeyModalIsOpen(!isOpen);
  };

  const handleRequestNewKey = async () => {
    const allValid = isValidPhonenumber();
    if (allValid) {
      const query = {
        Reference: booking.VisbookBookingOrderReference,
        GuestReference: chosenGuest.userReference
          ? chosenGuest.userReference
          : '',
        PhoneNumber: chosenGuest.phone ? chosenGuest.phone : '',
        Origin: 0, // 0 is Visbook as Origin
      };
      await axiosFetch({ url: requestUrl, data: query, method: 'POST' })
        .then((res) => {
          if (res) {
            showModal({
              modalType: 'default',
              title: res.Title ? res.Title : 'Forespørsel sendt',
              infoText: res.Text ? res.Text : res,
            });
            setChosenGuest({ phone: '', userReference: '' });
          }
        })
        .catch((reason) => {
          const res = reason.response.data;
          showModal({
            modalType: 'default',
            title: res.Title ? res.Title : 'Feil ved forespørsel om nøkkel',
            infoText: res.Text ? res.Text : res,
          });
        });
    }
  };

  /*

  Dersom det er flere rom/gjester i samme bestilling, vises en dialog
  der brukeren kan velge hvilken gjest som skal motta ny nøkkel.
  Det er mulig å overstyre telefonnummeret som nøkkelen sendes til,
  gjennom et eget valg i dialogen.

  */

  const generatePhoneNumberList = (bok) =>
    bok.BookingGuests.map((guest) => ({
      label: `${guest.Name}: ${guest.Phone}`,
      value: guest.Reference,
    }));

  return (
    <Modal
      title="Send nøkkel"
      ariaLabel="Send nøkkel"
      isOpen={isOpen}
      onClose={() => handleCloseModal()}
      size="medium"
      submit={{
        text: 'Send nøkkel',
        onClick: () => handleRequestNewKey(),
        ignoreOnClose: false,
      }}
    >
      <p>
        Velg hvilket telefonnummer den nye nøkkelen skal sendes til. Dersom du
        ikke mottar nøkkel innen 5 minutter, ta kontakt med servicesenteret.
      </p>
      <div className="margin">
        <div className="margin">
          <RadioButtonGroup
            options={generatePhoneNumberList(booking)}
            name="keyreceivers"
            onChange={(r, value) => {
              const selectedGuest = guestLookup[value.toString()];
              return setChosenGuest({
                phone: selectedGuest.Phone,
                userReference: selectedGuest.Reference,
              });
            }}
            checkedOption={chosenGuest.userReference}
          />
        </div>
      </div>

      <div className="margin">
        <div className="margin">
          <div className="case-filter form">
            <div className="small-expandable">
              <div className="content-group grey padded expand-container">
                <div className="input-container">
                  <div className="flex-row margin">
                    <label className={isValidPhonenumber() ? '' : 'invalid'}>
                      <span>Mobilnummer</span>
                      <Input
                        onChange={(name, val) =>
                          setChosenGuest({
                            ...chosenGuest,
                            phone: val,
                          })
                        }
                        value={chosenGuest.phone}
                        type="text"
                        name="name"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const ResendReceiptModal = ({
  isOpen,
  setResendReceiptModalIsOpen,
  booking,
  userData,
}: DeliberateAny) => {
  const { showModal } = useModal();
  const requestUrl = resendReceiptURL;
  const [resendReceiptEmail, setResendReceiptEmail] = useState(userData.Email);

  const handleCloseModal = () => {
    setResendReceiptModalIsOpen(!isOpen);
  };

  const handleResendReceipt = () => {
    const query = {
      bookingid: booking.Id,
      email: resendReceiptEmail,
    };
    axiosFetch({ url: requestUrl, data: query, method: 'GET' }).then((res) => {
      if (res) {
        showModal({
          modalType: 'default',
          title: res.Title ? res.Title : 'Kvittering sendt på e-post',
          text: res.Text ? res.Text : res,
        });
        setResendReceiptEmail('');
      } else {
        showModal({
          modalType: 'default',
          title: res.Title ? res.Title : 'Feil ved sending av kvittering',
          text: res.Text ? res.Text : res,
        });
      }
    });
  };

  return (
    <Modal
      title="Send kvittering"
      ariaLabel="Send kvittering"
      isOpen={isOpen}
      onClose={() => handleCloseModal()}
      size="medium"
      submit={{
        text: 'Send kvittering',
        onClick: () => handleResendReceipt(),
        ignoreOnClose: false,
      }}
    >
      <p>Velg hvilken epost kvitteringen skal sendes til.</p>
      <div className="margin">
        <div className="margin">
          <div className="case-filter form">
            <div className="small-expandable">
              <div className="content-group grey padded expand-container">
                <div className="input-container">
                  <div className="flex-row margin">
                    <label>
                      <span>Epost adresse</span>
                      <Input
                        onChange={(val) => setResendReceiptEmail(val)}
                        value={resendReceiptEmail}
                        type="text"
                        name="name"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
