import { forEach } from 'lodash';
import moment from 'moment';
import { AvailableRoomType } from 'types/BookingTypes/roomTypes';
import { DeliberateAny } from 'types/DelibrateAny';

function isGuid(value: string): boolean {
  const guidPattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return guidPattern.test(value);
}

function extractGuidProperties(obj: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {};

  for (const key in obj) {
    if (isGuid(key)) {
      result[key] = obj[key];
    }
  }

  return result;
}

// Helper function to extract room costs with the room count factored in
export const roomCost = (formHolder: DeliberateAny): Record<string, number> => {
  const guidProps = extractGuidProperties(formHolder); // This contains the room count for each GUID

  // Calculate the number of nights between arrival and departure
  const nights = moment(formHolder.arriveDeparture?.[1]?.toDate()).diff(
    moment(formHolder.arriveDeparture?.[0]?.toDate()),
    'days',
  );

  if (!formHolder?.roomInfoHolder) return {} as Record<string, number>;

  // Reduce through the GUID properties and calculate room costs
  const result = Object.keys(guidProps).reduce(
    (acc, guid) => {
      const matchingRoom = formHolder.roomInfoHolder.reduce(
        (foundRoom: any, room: any) => {
          // Check if the roomType.roomTypeId matches the GUID
          if (room.roomType && room.roomType.roomTypeId === guid) {
            return room;
          }
          return foundRoom;
        },
        null,
      );

      if (
        matchingRoom &&
        matchingRoom.roomType &&
        matchingRoom.roomType.price
      ) {
        // Multiply price by the number of nights and room count (from guidProps)
        const roomCount = guidProps[guid]; // This is the room count
        acc[guid] = matchingRoom.roomType.price * nights * roomCount;
      }

      return acc;
    },
    {} as Record<string, number>,
  );

  return result; // Return the room costs
};

// Function to calculate total room cost
export const totalRoomCost = (formHolder: DeliberateAny): number => {
  const rooms = roomCost(formHolder);

  // Calculate the total sum of all room costs
  const totalPrice = Object.values(rooms).reduce(
    (sum, price) => sum + price,
    0,
  );

  return totalPrice; // Return the total cost
};

// Function to return both nightCount and roomCount
export const getNightAndRoomCount = (
  formHolder: DeliberateAny,
): { nights: number; rooms: number; price: number } => {
  // Calculate the number of nights between arrival and departure
  const nightCount = moment(formHolder.arriveDeparture?.[1]?.toDate()).diff(
    moment(formHolder.arriveDeparture?.[0]?.toDate()),
    'days',
  );

  // Extract room count from guid properties
  const guidProps = extractGuidProperties(formHolder); // Room count for each GUID
  const roomCount = Object.values(guidProps).reduce(
    (total, count) => total + count,
    0,
  ); // Sum up all room counts

  return {
    nights: nightCount,
    rooms: roomCount,
    price: totalRoomCost(formHolder),
  };
};

export const formatToNorwegianPrice = (amount: number): string => {
  const formattedAmount = new Intl.NumberFormat('no-NO', {
    style: 'currency',
    currency: 'NOK',
    minimumFractionDigits: 2, // Ensures 2 decimal places
  }).format(amount);

  // Check if the amount has .00 decimals, and replace them with '-'
  if (amount % 1 === 0) {
    return formattedAmount.replace(',00', ',-');
  }

  return formattedAmount;
};

export const roomCapacity = (
  roomInfo: AvailableRoomType[],
  roomCounts: { [key: string]: number },
): { minGuests: number; maxGuests: number } => {
  let minGuests = 0;
  let maxGuests = 0;

  roomInfo.forEach((room) => {
    const selectedRoomCount = roomCounts[room.roomType.roomTypeId] || 0;

    const minCapacityForRoomType = selectedRoomCount * 1;
    const maxCapacityForRoomType =
      selectedRoomCount *
      (room.roomType.standardCapacity + room.roomType.extraCapacity);

    minGuests += minCapacityForRoomType;
    maxGuests += maxCapacityForRoomType;
  });

  return {
    minGuests,
    maxGuests,
  };
};
