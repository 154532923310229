import moment from "moment";

const getBookingState = function bookState(Status) {
  let bookingStatus = "";
  switch (Status) {
    case "null":
      bookingStatus = "Bookingstatus ikke satt";
      break;
    case "ny":
      bookingStatus = "Venter på behandling";
      break;
    case "avslått":
      bookingStatus = "Avslått";
      break;
    case "kanselleres":
      bookingStatus = "Kansellering forespurt";
      break;
    case "kansellert":
      bookingStatus = "Booking kansellert";
      break;
    case "utsatt": {
      bookingStatus = "Utsatt";
      break;
    }
    case "lukket":
      bookingStatus = "Lukket";
      break;
    case "kom ikke":
      bookingStatus = "Kom ikke";
      break;
    case "slettet":
      bookingStatus = "Booking slettet";
      break;
    case "godkjent":
      bookingStatus = "Godkjent";
      break;
    case "rom reservert":
      bookingStatus = "Rom reservert";
      break;
    default:
      bookingStatus = "Ukjent bookingstatus.";
  }
  return bookingStatus;
};

const getGuestString = function guestString(item) {
  if (item.IsGroupBooking) {
    return `${item.GroupInfo.RoomCount}, '/', ${item.GroupInfo.ExtraBedCount}`;
  }
  let guests = 0;
  for (let i = 0; i < item.RoomInfo.length; i += 1) {
    const room = item.RoomInfo[i];
    if (!room.NameOfGuest) continue;
    guests += room.NameOfGuest.length;
  }

  if (item.GroupInfo && item.GroupInfo.GuestCount) {
    guests = item.GroupInfo.GuestCount;
  }
  return `${guests} / ${item.RoomInfo.length}`;
};

const getPaymentStatus = function (transactionEvent) {
  const REGISTERED = 0;
  const AUTHORIZED = 1;
  const CAPTURE = 3;
  const PAYATARRIVAL = 4;
  let message = "med kort.";
  switch (transactionEvent) {
    case REGISTERED:
      message += "Transaksjon opprettet.";
      break;
    case AUTHORIZED:
      message += "Belastes ved avreise.";
      break;
    case CAPTURE:
      message += "Beløp trukket.";
      break;
    case PAYATARRIVAL:
      message += "Ved ankomst.";
      break;
    default:
      message = "";
  }
  return message;
};

const invoiceMessage = function (booking) {
  const DUEDEBIT = 3;
  let ret = "";
  if (booking.PaymentInfo.PaymentState === DUEDEBIT) {
    ret = "beløp faktureres";
  } else {
    ret = "med faktura";
  }

  return ret;
};

const paymentMessage = function (booking) {
  const NOTSELECTED = 0;
  const PAYBYCARD = 1;
  const PAYBYINVOICE = 2;
  const PAYBYSALARYDEDUCTION = 3;
  const PAYATARRIVAL = 4;
  const PREPAID = 5;
  const BookingStateNew = 0;
  const defaultMessage = "ukjent betalingsmåte";
  let message = "";

  switch (booking.PaymentInfo.PaymentMethod) {
    case NOTSELECTED:
      message = defaultMessage;
      break;
    case PAYBYCARD:
      if (booking.PaymentTransaction) {
        message = getPaymentStatus(booking.PaymentTransaction.TransactionEvent);
      } else {
        message = "med kort.";
        if (booking.RequestState !== BookingStateNew) {
          message += " Kortinfo mangler.";
        }
      }
      break;
    case PAYBYINVOICE:
      message = invoiceMessage(booking);
      break;
    case PAYBYSALARYDEDUCTION:
      message = "trekkes fra lønn.";
      break;
    case PAYATARRIVAL:
      message = "ved ankomst.";
      break;
    case PREPAID:
      message = "Forhåndsbetalt.";
      break;
    default:
      message = defaultMessage;
  }
  return message;
};

const getGuestPayment = function guestPay(item) {
  const payment = `${item.PaymentInfo.Price}`;
  paymentMessage(item);

  const message = paymentMessage(item);
  return `${payment} ${message ? message : ""}`;
};

const initialState = {
  filter: {
    arrivalPeriodEnd: moment().format("DD.MM.YYYY"),
    bookingStateFilter: null,
    locationFilter: "",
    paymentStateFilter: "",
    searchText: "",
    TakeCount: 100,
  },
  isLoading: true,
  locationFilter: "Alle",
  SearchText: "",
  profile: {},
  bookingStateFilter: [
    { key: "0", value: "Alle" },
    { key: "1", value: "Ny" },
    { key: "2", value: "Godkjent" },
    { key: "3", value: "Avslått" },
    { key: "4", value: "Kansellert" },
    { key: "5", value: "Lukket" },
    { key: "6", value: "Utsatt" },
    { key: "7", value: "Slettet" },
    { key: "8", value: "Rom reservert" },
    { key: "9", value: "Ikke møtt" },
  ],
  paymentStateFilter: [
    { key: "", value: "Alle" },
    { key: "1", value: "Betalt" },
    { key: "2", value: "Penger til gode" },
    { key: "3", value: "Skylder penger" },
    { key: "4", value: "Send faktura" },
    { key: "5", value: "Ikke betalt" },
  ],
  period: [
    { key: "", value: "Alle" },
    { key: moment().subtract(7, "d").format("DD.MM.YYYY"), value: "Week" },
    {
      key: moment().subtract(1, "months").format("DD.MM.YYYY"),
      value: "Siste måned",
    },
    {
      key: moment().subtract(3, "months").format("DD.MM.YYYY"),
      value: "Siste 3 måneder",
    },
    {
      key: moment().subtract(6, "months").format("DD.MM.YYYY"),
      value: "Siste 6 måneder",
    },
    {
      key: moment().subtract(1, "year").format("DD.MM.YYYY"),
      value: "Siste året",
    },
  ],
  ubehandlet: [],
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_RESULTS_BY_SORTING":
      return {
        ...state,
        filter: {
          ...state.filter,
          sortingOption: action.label,
        },
      };
    case "UPDATE_RESERVATION_FILTER":
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.key]: action.value,
        },
      };
    case "RECEIVE_RESERVATON_ITEMS": {
      const items = action.data.map((item) => {
        const ArrivalDate = item.ArrivalDate;
        const DepartureDate = item.DepartureDate;
        const BookingDate = item.BookingDate;

        return {
          ...item,
          DepartureDate: moment(DepartureDate).format("DD.MM.YY"),
          ArrivalDate: moment(ArrivalDate).format("DD.MM.YY"),
          BookingDate: moment(BookingDate).format("DD.MM.YY"),
          BookingState: getBookingState(item.State),
          GuestString: getGuestString(item),
          GuestPayment: getGuestPayment(item),
        };
      });
      return {
        ...state,
        items,
      };
    }
    case "RECEIVE_RESERVATON_LOCATIONS":
      return {
        ...state,
        locations: action.data,
        isLoading: false,
      };
    case "RECEIVE_RESERVATON_PROFILE_ID_DATA":
      return {
        ...state,
        profile: action.data,
      };

    case "RECEIVE_RESERVATON_AUDIT_ENTRIES":
      return {
        ...state,
        history: action.data,
      };
    default:
      return state;
  }
};
