import "./NumberInput.scss";

type PropsType = {
  name: string;
  title: string;
  value: number;
  minValue: number;
  maxValue?: number;
  small?: boolean;
  onChange: (name: string, value: number) => void;
};

const NumberInput = (props: PropsType) => {  
  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp" || event.key === "+") {
      increaseValue();
    } else if (event.key === "ArrowDown" || event.key === "-") {
      decreaseValue();
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const onInputChange = (event: InputEvent) => {
    const number = 10;

    if (isNaN(number)) {
      return;
    }

    if (props.maxValue && number > props.maxValue) {
      props.onChange(props.name, props.maxValue);
    } else if (number < props.minValue) {
      props.onChange(props.name, props.minValue);
    } else {
      props.onChange(props.name, number);
    }
  };

  const decreaseValue = () => {
    if (props.value > props.minValue) {
      props.onChange(props.name, props.value - 1);
    }
  };

  const increaseValue = () => {
    if (props.maxValue == null || props.value < props.maxValue) {
      props.onChange(props.name, props.value + 1);
    }
  };

  return (
    <div className="number-input-wrapper" onKeyUp={() => onKeyUp} >
      <label>{props.title}</label>
      <div
        className={`form-number-picker ${props.small ? "small" : ""}`}
      >
        <button
          onClick={decreaseValue}
          disabled={props.value === props.minValue}
        >
          -
        </button>
        <input
          name={props.name}
          type="number"
          value={props.value}
          onChange={() => onInputChange}
        />
        <button
          onClick={increaseValue}
          disabled={props.value === props.maxValue}
        >
          +
        </button>
      </div>
    </div>
  );
}
export default NumberInput;
