import './TabContainer.scss';

import TabButtons, { Tab } from 'components/TabButtons/TabButtons';
import { FC, ReactNode, useMemo } from 'react';

type TabContent = {
  id: string;
  content: ReactNode;
};

interface ITabContainerProps {
  tabs?: Tab[];
  content: TabContent[];
  activeTab: string; // Controlled prop for active tab
  onTabChange: (tabId: string) => void; // Controlled handler for changing active tab
}

const TabContainer: FC<ITabContainerProps> = ({
  tabs,
  content,
  activeTab,
  onTabChange,
}) => {
  // Memoize the content rendering to avoid unnecessary re-renders
  const activeContent = useMemo(
    () => content.find((c) => c.id === activeTab)?.content,
    [activeTab, content],
  );

  return (
    <div className="fb-tab-container">
      {/* Render TabButtons separately to prevent flashing */}
      <TabButtons
        tabs={
          tabs ??
          content.map(
            (c) =>
              ({
                id: c.id,
                label: c.id,
              }) as Tab,
          )
        }
        active={activeTab}
        onChange={onTabChange}
      />
      {/* Render only the active content */}
      <div key={activeTab}>{activeContent}</div>
    </div>
  );
};

export default TabContainer;
