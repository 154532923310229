// @flow

import './List.scss';

import { removeWhiteSpaces } from 'helpers/valueHelpers';
import { Component } from 'react';
import { ListColumnType, UncontrolledSortOptionsType } from 'types/listTypes';

type PropsType = {
  items: Array<object>;
  columns: Array<ListColumnType>;
  uncontrolledSortOptions?: UncontrolledSortOptionsType;
  className?: string;
};

class UncontrolledColumnHeaders extends Component {
  declare props: PropsType;

  state: {
    sortByColumn: string;
    sortOrderAscending: boolean;
  };

  constructor(props) {
    super(props);

    const sortAsc =
      (this.props.uncontrolledSortOptions &&
        this.props.uncontrolledSortOptions.sortDescending) ||
      false;

    this.state = {
      sortByColumn:
        this.props.columns.length > 0 ? this.props.columns[0].name : '',
      sortOrderAscending: sortAsc,
    };
  }

  componentDidMount() {
    if (
      this.props.uncontrolledSortOptions &&
      this.props.uncontrolledSortOptions.sortColumnOnMount
    ) {
      this.sortColumn(this.props.uncontrolledSortOptions.sortColumnOnMount);
    }
  }

  sortableHeaderStyles = (column) => ` \
    sortable \
    ${this.state.sortByColumn === column ? 'sorting-header' : ''}  \
    ${this.state.sortOrderAscending ? 'ascending' : ''}  \
  `;

  sortItems = (sortByColumn) =>
    this.props.items.sort((a, b) => {
      let valA = a[sortByColumn];
      let valB = b[sortByColumn];

      if (typeof valA === 'string' && typeof valB === 'string') {
        valA = removeWhiteSpaces(valA).toLowerCase();
        valB = removeWhiteSpaces(valB).toLowerCase();

        return this.state.sortOrderAscending
          ? valB.localeCompare(valA, 'nb')
          : valA.localeCompare(valB, 'nb');
      } else if (typeof valA === 'string' && !valB) {
        return -1;
      } else if (typeof valB === 'string' && !valA) {
        return 1;
      }

      return this.state.sortOrderAscending ? valB - valA : valA - valB;
    });

  sortColumn = (sortByColumn) => {
    const sortedItems = this.sortItems(sortByColumn);
    if (
      this.props.uncontrolledSortOptions &&
      this.props.uncontrolledSortOptions.onSortList &&
      sortedItems
    ) {
      this.props.uncontrolledSortOptions.onSortList(sortedItems);
      this.setState({
        sortByColumn,
        sortOrderAscending: !this.state.sortOrderAscending,
      });
    }
  };

  render() {
    return (
      <div className={'overview-container ' + (this.props.className ?? '')}>
        {this.props.columns.map((column) =>
          this.props.uncontrolledSortOptions ? (
            <div
              key={column.label}
              className={
                this.sortableHeaderStyles(column.name) + ' ' + column.className
              }
              onClick={() => this.sortColumn(column.name)}
            >
              {column.label}
            </div>
          ) : (
            <div key={column.label} className={column.className}>
              {column.label}
            </div>
          ),
        )}
      </div>
    );
  }
}

export default UncontrolledColumnHeaders;
