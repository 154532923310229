import "./HousingPropertiesColumns.scss";

const HousingPropertiesColumns = [
  {
    label: "Adresse",
    name: "Address",
    className: "address",
    renderFunction: (item) => (
      <div className="column-address">
        <h5>{item.Name}</h5>
        <span className={"spanStyle"}>Leie-ID: {item.RentalId}</span>
      </div>
    ),
  },

  {
    label: "Rom",
    name: "Room",
    className: "room",
    renderFunction: (item) => (
      <div className="column-room-count">{item.Type} rom</div>
    ),
  },
  {
    label: "BRA",
    name: "Size",
    className: "size",
    renderFunction: (item) => (
      <div className="column-gross-area">{item.Size} m2</div>
    ),
  },
];

export default HousingPropertiesColumns;
