import { ReactElement, ReactNode, useState } from 'react';

type PropsType = {
  children?: ReactNode | ReactNode[];
  helpText: string;
};

const HelpText = ({ children, helpText }: PropsType) => {
  const [helpTextVisible, setHelpTextVisible] = useState(false);

  const onHelpTextClick = () => {
    setHelpTextVisible(!helpTextVisible);
  };

  return (
    <span
      className={`inline-helper-wrapper ${helpTextVisible ? 'toggled' : ''}`}
    >
      {children}
      <span className="inline-helper">
        <i
          className="inline-helper-trigger"
          aria-haspopup="true"
          onClick={onHelpTextClick}
        >
          ?
        </i>
        <span className="inline-helper-content">{helpText}</span>
      </span>
    </span>
  );
};

export default HelpText;
