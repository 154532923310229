// @flow

import { fetchRejectionReasons } from 'actions/myRentalsActions';
import {
    fetchSignableDocuments, fetchSignedDocumentFileName, uploadSignedRentalProtocol
} from 'actions/signableDocumentsActions';
import DownloadFileArea from 'components/DownloadFileArea/DownloadFileArea';
import Loader from 'components/Loader/Loader';
import ConfirmationModal from 'components/modals/ConfirmationModal/ConfirmationModal';
import Modal from 'components/modals/Modal/Modal';
import {
  SIGNATURE_STATE_ERROR,
  SIGNATURE_STATE_REJECTION,
  SIGNATURE_STATE_SUCCESS,
} from "constants/signatureConstants";
import connect from "helpers/connectWithRouter";
import {
  getParamFromURL,
  getStatusQueryToken,
} from "helpers/difiRedirectUrlParser";
import React, { Component } from "react";
import { ClientLinkType } from "types/commonTypes";
import { DeliberateAny } from "types/DelibrateAny";

import SignableDocumentsList from './SignableDocumentsList/SignableDocumentsList';

import type { SignableDocumentType } from "types/signableDocumentsTypes";
type PropsType = {
  documents: Array<SignableDocumentType>;
  fetchSignableDocuments: () => void;
  fetchSignedDocumentFileName: (statusQueryToken: string) => void;
  fetchRejectionReasons: () => void;
  documentsLoaded: boolean;
  uploadSignedRentalProtocol: (
    inventoryType: number,
    inventoryNumber: string,
    documentId: string,
    rentalId: string,
    statusQueryToken: string
  ) => void;
  signedDocument?: ClientLinkType;
  signedDocumentLoaded: boolean;
};

export class UnconnectedSignableDocuments extends Component {
  declare props: PropsType;

  state: {
    showSigningSuccessModal: boolean;
    showSigningRejectionModal: boolean;
    showSigningErrorModal: boolean;
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      showSigningSuccessModal: false,
      showSigningRejectionModal: false,
      showSigningErrorModal: false,
    };

    this.props.fetchRejectionReasons();

    const statusQueryToken = getStatusQueryToken();
    if (statusQueryToken) {
      this.handleRedirectFromSigningService(statusQueryToken);
    } else {
      this.props.fetchSignableDocuments();
    }
  }

  handleRedirectFromSigningService = (statusQueryToken: string) => {
    const signingState = getParamFromURL("signingState");
    const documentId = getParamFromURL("documentId");
    const inventoryNumber = getParamFromURL("inventoryNumber");
    const inventoryType = getParamFromURL("inventoryType");
    const rentalId = getParamFromURL("rentalId");

    switch (signingState) {
      case SIGNATURE_STATE_SUCCESS:
        this.state.showSigningSuccessModal = true;
        this.props.fetchSignedDocumentFileName(statusQueryToken);
        this.props.uploadSignedRentalProtocol(
          inventoryType ? parseInt(inventoryType, 10) : 0,
          inventoryNumber ? inventoryNumber : "",
          documentId ? documentId : "",
          rentalId ? rentalId : "",
          statusQueryToken
        );
        break;

      case SIGNATURE_STATE_REJECTION:
        this.state.showSigningRejectionModal = true;
        this.props.fetchSignableDocuments();
        break;

      case SIGNATURE_STATE_ERROR:
        this.state.showSigningErrorModal = true;
        this.props.fetchSignableDocuments();
        break;

      default:
        this.props.fetchSignableDocuments();
        break;
    }
  };

  SigningSuccessModal = () => (
    <Modal
      size="medium"
      title="Dokumentet er signert"
      ariaLabel="Dokumentet er signert"
      description="Leieprotokollen er signert og sendt til Forsvarsbygg."
      isOpen={this.state.showSigningSuccessModal}
      onClose={() => this.setState({ showSigningSuccessModal: false })}
      cancel={{
        hide: true,
      }}
      submit={{
        text: "Lukk",
        color: "grey",
      }}
    >
      <p>Du kan laste ned en kopi her:</p>
      <Loader isLoading={!this.props.signedDocumentLoaded}>
        {this.props.signedDocument && (
          <DownloadFileArea {...this.props.signedDocument} />
        )}
      </Loader>
    </Modal>
  );

  render() {
    return (
      <div className="double-margin">
        <h2>Leie- og endringsprotokoll klar for signering</h2>
        <Loader isLoading={!this.props.documentsLoaded}>
          <SignableDocumentsList items={this.props.documents} />
        </Loader>
        <this.SigningSuccessModal />
        <ConfirmationModal
          isOpen={this.state.showSigningRejectionModal}
          onClose={() => this.setState({ showSigningRejectionModal: false })}
          title="Signering avbrutt"
          infoText="Du har avbrutt signering og dokumentet er ikke blitt signert. Du kan starte signering på nytt."
        />
        <ConfirmationModal
          isOpen={this.state.showSigningErrorModal}
          onClose={() => this.setState({ showSigningErrorModal: false })}
          title="Signering feilet"
          infoText="Det skjedde en feil ved signering og dokumentet er ikke blitt signert. Du kan starte signering på nytt."
        />
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    signedDocument: state.signableDocuments.signedDocument,
    signedDocumentLoaded: state.signableDocuments.signedDocumentLoaded,
    documents: state.signableDocuments.documents,
    documentsLoaded: state.signableDocuments.documentsLoaded,
  }),
  {
    fetchSignableDocuments,
    uploadSignedRentalProtocol,
    fetchSignedDocumentFileName,
    fetchRejectionReasons,
  }
)(UnconnectedSignableDocuments);
