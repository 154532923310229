import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import Image from 'components/ImageComponents/Image';
import ImageCarousel from 'components/ImageComponents/ImageCarousel';
import { ImageResponse } from 'types/imageTypes';

const ImageExample = () => {
  const images: ImageResponse[] = [
    {
      altText: '',
      description: '',
      url: '/src/assets/img/Akershus.jpg',
    },
    {
      altText: '',
      description: '',
      url: '/src/assets/img/frontpage.jpg',
    },
    {
      altText: '',
      description: '',
      url: '/src/assets/img/hakonsvern.jpg',
    },
    {
      altText: '',
      description: '',
      url: '/src/assets/img/_kontakt.jpg',
    },
    {
      altText: '',
      description: '',
      url: '/src/assets/img/Akershus.jpg',
    },
    {
      altText: '',
      description: '',
      url: '/src/assets/img/Akershus.jpg',
    },
  ];

  return (
    <div className="flex-column gap">
      <div>
        <h3>Default image component</h3>
        <Image image={images[0]} />
      </div>
      <div>
        <h3>Customized with &apos;className&apos; and/or &apos;style&apos;</h3>
        <Image
          className="fancyImage"
          style={{ width: '200px' }}
          image={images[0]}
        />
      </div>
      <div>
        <h3>Image from url source</h3>
        <div
          style={{
            resize: 'horizontal',
            overflow: 'auto',
            padding: '1em',
            border: '1px solid grey',
          }}
        >
          <Image
            image={{
              altText: '',
              description: '',
              url: 'https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg',
            }}
          />
        </div>
      </div>
      <AlertMessage variant={AlertType.Info}>
        The image component is responsive based on it's parent container.
        Meaning you can decide the size of the container to decide the size of
        the image. The last image has a resizable container (with padding) to
        showcase this
      </AlertMessage>
      <div>
        <h3>Image Carousel</h3>
        <div>
          <ImageCarousel images={images}></ImageCarousel>
        </div>
      </div>
    </div>
  );
};

export default ImageExample;
