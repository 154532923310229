import './ExpandSection.scss';

import * as I from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import { FC, ReactNode, useState } from 'react';

export interface IGuestListProps {
  header: string;
  description: string;
  errorMessage?: string;
  content: ReactNode | ReactNode[];
  openOnDefault?: boolean;
}

const ExpandSection: FC<IGuestListProps> = ({
  header,
  description,
  content,
  errorMessage,
  openOnDefault,
}) => {
  return (
    <div className="expand-section-container">
      <details open={openOnDefault}>
        <summary>
          <span>
            <span className="es-header">{header}</span>
            <span className="es-description">{description}</span>
            <span className="es-error">{errorMessage}</span>
          </span>
          <BasenIcon className="es-icon" icon={I.DownIcon} />
        </summary>
        <div className={`es-content`}>{content}</div>
      </details>
    </div>
  );
};

export default ExpandSection;
