/* eslint react/prop-types: 0 */

import { registerSingleHousingAllocation } from 'actions/housingAdminActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Checkbox from 'components/form/Checkbox/Checkbox';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import Input from 'components/form/Input/Input';
import TextArea from 'components/form/TextArea/TextArea';
import ListItem from 'components/ListItem/ListItem';
import Modal from 'components/modals/Modal/Modal';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

const AssignHousingModal = ({ item, isOpen, setModalIsOpen }) => {
  const [housingRentalId, setHousingRentalId] = useState(
    item.HousingRentalId || '',
  );
  const [allocationDate, setAllocationDate] = useState('');
  const [allocationDateISO, setAllocationDateISO] = useState('');
  const [hasCompensation, setHasCompensation] = useState(false);
  const [comments, setComments] = useState('');
  const [commentsForApplicant, setCommentsForApplicant] = useState(
    item.CommentForApplicant,
  );
  const [formErrors, setFormErrors] = useState({});

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      registerSingleHousingAllocation({
        AllocationRoundId: item.AllocationRoundId,
        ApplicationId: item.ApplicationId,
        AllocationDate: allocationDateISO,
        Comments: comments,
        CommentsForApplicant: commentsForApplicant,
        Compensation: hasCompensation ? 'Ja' : 'Nei',
        HousingRentalId: housingRentalId,
      }),
    );
    setModalIsOpen(false);
  };

  useEffect(() => {
    const isValidDate = moment(allocationDate, 'DD.MM.YYYY', true).isValid();
    if (isValidDate) {
      const momentObj = moment(allocationDate, 'DD.MM.YYYY');
      setAllocationDateISO(momentObj.toISOString(true));
    } else {
      setAllocationDateISO('');
    }
  }, [allocationDate]);

  const validatorConfig = yup
    .object()
    .shape({
      housingRentalId: yup
        .string()
        .required('required')
        .test({
          test: (value) => value == null || value.length >= 5,
          message: { rule: 'length.min', min: 5 },
        }),
      allocationDate: yup
        .string()
        .matches(/^([0-2][0-9]|(3)[0-1]).(0[1-9]|1[0-2]).\d{4}$/, {
          rule: 'date.format',
          format: 'DD.MM.YYYY',
        }),
    })
    .defined();

  const formProps = {
    errors: formErrors,
    form: {
      housingRentalId,
    },
    onChange: setHousingRentalId,
    updateAllValidationErrors: setFormErrors,
    updateOneValidationError: (name, errors) => setFormErrors(errors),
    onSubmit: handleClick,
    schema: validatorConfig,
    validatorConfig: {
      housingRentalId: { required: true, length: { min: 5 } },
      allocationDate: {
        required: false,
        date: {
          format: 'DD.MM.YYYY',
        },
      },
    },
  };

  return (
    <Modal
      title="Tildel bolig"
      isOpen={isOpen}
      size="medium"
      onClose={() => setModalIsOpen(false)}
      submit={{ hide: true }}
      cancel={{ hide: true }}
    >
      <FormWrapper {...formProps}>
        <div className="row margin">
          <Input
            onChange={(name, value) => setHousingRentalId(value)}
            value={housingRentalId}
            name="housingRentalId"
            title="Leie-id"
          />
        </div>
        <div className="row margin">
          <Input
            onChange={(name, value) => setAllocationDate(value)}
            value={allocationDate}
            name="allocationDate"
            title="Tildelingsdato"
          />
        </div>
        <div className="row margin">
          <Checkbox
            onChange={(name, value) => setHasCompensation(value)}
            name="hasCompensation"
            checked={hasCompensation}
            label="Kompensasjon"
          />
        </div>
        <div className="half-margin">
          <TextArea
            onChange={(name, value) => setComments(value)}
            value={comments}
            name="comments"
            title="Kommentar til Forsvarsbygg"
            placeholder="Her kan du skrive en kommentar til Forsvarsbygg"
          />
        </div>
        <div className="half-margin">
          <TextArea
            onChange={(name, value) => setCommentsForApplicant(value)}
            value={commentsForApplicant}
            name="commentsForApplicant"
            title="Kommentar til søker"
            placeholder="Skriv en kommentar til søker"
          />
        </div>
        <ButtonRow>
          <ButtonRow.Left
            type="submit"
            color="red"
            className="btn"
            key="1"
            onClick={handleClick}
          >
            Tildel
          </ButtonRow.Left>
          <ButtonRow.Left
            type="button"
            color="white"
            className="btn"
            key="2"
            onClick={() => setModalIsOpen(false)}
          >
            Avbryt
          </ButtonRow.Left>
        </ButtonRow>
      </FormWrapper>
    </Modal>
  );
};

const HousingAllocationsItem = ({ columns, item }) => {
  const [showAssignHousingModal, setShowAssignHousingModal] = useState(false);

  const renderDetails = () => (
    <div className="details" style={{ padding: '0' }}>
      <div className="details-wrapper">
        <h3>Personinformasjon</h3>
        <ul className="label-value-list">
          <li key="item_phone">
            <span className="label">Telefon:</span>
            <span className="value">{item.Phone}</span>
          </li>
          <li key="item_email">
            <span className="label">E-post:</span>
            <span className="value">{item.Email}</span>
          </li>
          <li key="item_orderDate">
            <span className="label">Beordringsdato:</span>
            <span className="value">
              {moment(item.AssignmentFromDate).format('DD.MM.YYYY')}
            </span>
          </li>
          <li key="item_moveInDate">
            <span className="label">Ønsket innflytting:</span>
            <span className="value">
              {moment(item.WantedFromDate).format('DD.MM.YYYY')}
            </span>
          </li>
          <li key="item_comments">
            <span className="label">Kommentar til søker: </span>
            <span className="value">
              <textarea
                disabled
                value={item.CommentForApplicant || item.CommentsForApplicant}
              />
            </span>
          </li>
        </ul>
        {item.ApplicationState === 'Ikke tildelt bolig' && (
          <ButtonRow>
            <ButtonRow.Left
              onClick={() => setShowAssignHousingModal(true)}
              className="btn red"
            >
              Tildel bolig
            </ButtonRow.Left>
          </ButtonRow>
        )}
        {showAssignHousingModal && (
          <AssignHousingModal
            isOpen={showAssignHousingModal}
            setModalIsOpen={setShowAssignHousingModal}
            item={item}
            // onSubmit={() => dispatch(handleSendReporting())}
          />
        )}
      </div>
    </div>
  );
  return (
    <ListItem
      columns={columns}
      item={item}
      renderDetailsFunction={() => renderDetails()}
    />
  );
};

export default HousingAllocationsItem;
