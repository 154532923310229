import './HousingRenewalApplicationItem.scss';

import {
  approveHousingRenewalApplication,
  fetchHousingRenewalApplicationSummary,
  rejectHousingRenewalApplication,
  sendCommentHousingRenewal,
  updateHousingRenewalApplicationMessage,
} from 'actions/housingAdminActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import RenderApplicationSummary from 'components/HousingApplication/RenderApplicationSummary';
import ListItem from 'components/ListItem/ListItem';
import Modal from 'components/modals/Modal/Modal';
import { getLabelFromValue } from 'helpers/housingAdminHelpers';
import moment, { Moment } from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { DeliberateAny } from 'types/DelibrateAny';

import HousingRenewalMessageModal from './HousingRenewalModals/HousingRenewalMessageModal';

import type { ListColumnType } from 'types/listTypes';
import type { HousingDataOptionsType } from 'types/optionsTypes';
import type {
  HousingRenewalApplicationType,
  HousingRenewalAreaType,
} from 'types/housingAdminTypes';
type PropsType = {
  application: HousingRenewalApplicationType;
  columns: Array<ListColumnType>;
  housingDataOptions: HousingDataOptionsType;
  approveHousingRenewalApplication: (
    applicationId: string,
    reason: string,
    areaId: string,
    approvedTo: string,
  ) => void;
  rejectHousingRenewalApplication: (
    applicationId: string,
    reason: string,
    areaId: string,
  ) => void;
  sendCommentHousingRenewal: (
    applicationId: string,
    comment: string,
    areaId: string,
  ) => void;
  updateHousingRenewalApplicationMessage: (
    applicationId: string,
    messageKey: string,
    message: string,
  ) => void;
  hideDetails?: boolean;
  housingRenewalArea: HousingRenewalAreaType;
  renewalCommentSent: boolean;
  fetchHousingRenewalApplicationSummary: (applicationId: string) => void;
};

export class UnconnectedHousingRenewalApplicationItem extends Component<PropsType> {
  declare props: PropsType;
  state: {
    showRenewalApprovalModal: boolean;
    showRenewalRejectionModal: boolean;
    showRenewalCommentModal: boolean;
    showCommentSentModal: boolean;
    showApplicationSummary: boolean;
    approvedToDate: Moment;
    changeApprovalDate: boolean;
  };

  constructor(props: PropsType) {
    super(props);

    this.state = {
      showRenewalApprovalModal: false,
      showRenewalRejectionModal: false,
      showRenewalCommentModal: false,
      showCommentSentModal: false,
      showApplicationSummary: false,
      approvedToDate: moment(this.props.application.AssignmentToDate),
      changeApprovalDate: true,
    };
    this.props.fetchHousingRenewalApplicationSummary(this.props.application.Id);
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    if (
      nextProps.renewalCommentSent !== this.state.showCommentSentModal &&
      this.props.renewalCommentSent !== nextProps.renewalCommentSent
    ) {
      this.setState({ showCommentSentModal: nextProps.renewalCommentSent });
    }
  }

  onMessageInputChange = (messageKey: string, message: string) => {
    this.props.updateHousingRenewalApplicationMessage(
      this.props.application.Id,
      messageKey,
      message,
    );
  };

  toggleState = (stateProperty: string) => {
    this.setState({ [stateProperty]: !this.state[stateProperty] });
  };

  changeApprovedToDate = (approvedTo: Moment) => {
    this.setState({ approvedToDate: approvedTo });
  };

  addDateToComment = () => `${
    this.props.application.approvalMessage || ''
  } NB! Godkjent periode er til og med 
    ${moment(this.state.approvedToDate).format('DD.MM.YYYY')}`;

  housingRenewalSummaryModal = () => (
    <Modal
      isOpen={this.state.showApplicationSummary}
      onClose={() => this.toggleState('showApplicationSummary')}
      ariaLabel="Vis hele søknaden"
      size="large"
    >
      <h2>Oppsummering</h2>
      {this.props.application.summary && (
        <RenderApplicationSummary
          application={this.props.application.summary}
        />
      )}
    </Modal>
  );

  rentalCommentSent = () => (
    <Modal
      isOpen={this.state.showCommentSentModal}
      onClose={() => this.toggleState('showCommentSentModal')}
      ariaLabel="Sending av melding vellykket"
      size="medium"
    >
      <p>Din kommentar til BOK-forvalter ble sent.</p>
    </Modal>
  );

  renderCommentModal = () => (
    <HousingRenewalMessageModal
      isOpen={this.state.showRenewalCommentModal}
      onClose={() => this.toggleState('showRenewalCommentModal')}
      ariaLabel={'Kommentar til BOK-forvalter'}
      submit={{
        disabled: !(
          this.props.application.bokComment &&
          this.props.application.bokComment.length > 0
        ),
        onClick: () =>
          this.props.sendCommentHousingRenewal(
            this.props.application.Id,
            this.props.application.bokComment ?? '',
            this.props.housingRenewalArea.Id,
          ),
      }}
      modalHeader={'Send kommentar til BOK-forvalter'}
      modalText="Skreddersy melding til forvalteren"
      textArea={{
        onChange: this.onMessageInputChange,
        name: 'bokComment',
        value: this.props.application.bokComment || '',
      }}
    />
  );

  renderApprovalModal = () => (
    <HousingRenewalMessageModal
      isOpen={this.state.showRenewalApprovalModal}
      onClose={() => this.toggleState('showRenewalApprovalModal')}
      ariaLabel={'Godkjenn forlengelse'}
      submit={{
        disabled: !(
          this.props.application.approvalMessage &&
          this.props.application.approvalMessage.length > 0
        ),
        onClick: () =>
          this.props.approveHousingRenewalApplication(
            this.props.application.Id,
            this.addDateToComment(),
            this.props.housingRenewalArea.Id,
            moment(this.state.approvedToDate).format('DD.MM.YYYY'),
          ),
      }}
      modalHeader={'Godkjenn søknad om forlengelse'}
      modalText="Skreddersy melding til søkeren"
      textArea={{
        onChange: this.onMessageInputChange,
        name: 'approvalMessage',
        value: this.props.application.approvalMessage || '',
      }}
      datePicker={{
        title: 'Godkjent til dato',
        selectedDate: moment(this.state.approvedToDate),
        mindDate: moment(this.props.application.AssignmentFromDate),
        onChange: this.changeApprovedToDate,
        disabled: {
          yesNo: this.state.changeApprovalDate,
          toggleDisable: () => this.toggleState('changeApprovalDate'),
        },
      }}
    />
  );

  renderRejectionModal = () => (
    <HousingRenewalMessageModal
      isOpen={this.state.showRenewalRejectionModal}
      onClose={() => this.toggleState('showRenewalRejectionModal')}
      ariaLabel={'Avslå forlengelse'}
      submit={{
        disabled: !(
          this.props.application.rejectionMessage &&
          this.props.application.rejectionMessage.length > 0
        ),
        onClick: () =>
          this.props.rejectHousingRenewalApplication(
            this.props.application.Id,
            this.props.application.rejectionMessage ?? '',
            this.props.housingRenewalArea.Id,
          ),
      }}
      modalHeader={'Avslå søknad om forlengelse'}
      modalText="Skreddersy melding til søkeren"
      textArea={{
        onChange: this.onMessageInputChange,
        name: 'rejectionMessage',
        value: this.props.application.rejectionMessage || '',
      }}
    />
  );

  renderDetailsFunction = () => (
    <div className="applications-item">
      <div className="details-divider">
        <div>
          <label className="margin">
            <span className="bold">Søkers særskilte behov:</span>
            <p className="italic">
              {this.props.application.HousingPreferences
                ? this.props.application.HousingPreferences
                : 'Ingen'}
            </p>
          </label>

          <label className="margin">
            <span className="bold">Beordring:</span>
            <p>
              {getLabelFromValue(
                this.props.housingDataOptions.assignmentDocumentationOptions,
                this.props.application.AssignmentDocumentation,
              )}
            </p>
          </label>
        </div>
      </div>

      <a
        className="renewal-modal-link"
        onClick={() => this.toggleState('showApplicationSummary')}
      >
        Vis hele søknaden
      </a>

      <ButtonRow>
        <ButtonRow.Right
          color="grey"
          onClick={() => this.toggleState('showRenewalCommentModal')}
        >
          Kommentar til BOK-forvalter
        </ButtonRow.Right>
        <ButtonRow.Right
          color="grey"
          onClick={() => this.toggleState('showRenewalRejectionModal')}
        >
          Send avslag
        </ButtonRow.Right>
        <ButtonRow.Right
          color="grey"
          onClick={() => this.toggleState('showRenewalApprovalModal')}
        >
          Godkjenn forlengelse
        </ButtonRow.Right>
      </ButtonRow>

      {this.state.showRenewalRejectionModal && this.renderRejectionModal()}
      {this.state.showRenewalApprovalModal && this.renderApprovalModal()}
      {this.state.showRenewalCommentModal && this.renderCommentModal()}
      {this.state.showCommentSentModal && this.rentalCommentSent()}
      {this.state.showApplicationSummary && this.housingRenewalSummaryModal()}
    </div>
  );

  render() {
    return (
      <ListItem
        item={this.props.application}
        columns={this.props.columns}
        renderDetailsFunction={
          this.props.hideDetails
            ? undefined
            : () => this.renderDetailsFunction()
        }
      />
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    housingDataOptions: state.options.housingDataOptions,
    housingRenewalArea: state.housingAdmin.housingRenewalArea,
    renewalCommentSent: state.myRentals.rentalCommentBOKSent,
  }),
  {
    approveHousingRenewalApplication,
    rejectHousingRenewalApplication,
    sendCommentHousingRenewal,
    updateHousingRenewalApplicationMessage,
    fetchHousingRenewalApplicationSummary,
  },
)(UnconnectedHousingRenewalApplicationItem);
