import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import Guardpage from './Guardpage';

const TabGuardPage = () => {
  return (
    <ProtectedRoute>
      <Guardpage />
    </ProtectedRoute>
  );
};

export default TabGuardPage;
