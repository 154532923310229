const Pagination = ({ setPaginationCount, paginationCount }) => (
  <div className={'itemsPerPage'}>
    <span className={'label'}>Henvendelser per side:</span>
    <div>
      <a
        onClick={() => setPaginationCount(10)}
        className={paginationCount === 10 ? 'active' : ''}
      >
        10
      </a>
      <a
        onClick={() => setPaginationCount(25)}
        className={paginationCount === 25 ? 'active' : ''}
      >
        25
      </a>
      <a
        onClick={() => setPaginationCount(50)}
        className={paginationCount === 50 ? 'active' : ''}
      >
        50
      </a>
      <a
        onClick={() => setPaginationCount(100)}
        className={paginationCount === 100 ? 'active' : ''}
      >
        100
      </a>
    </div>
  </div>
);

export default Pagination;
