import { logoutURL } from 'api/cms/cms_api';
import Modal from 'components/modals/Modal/Modal';
import useAxios from 'hooks/useAxios';
import useToken from 'hooks/useToken';
import { createContext, FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-use';

import { IWrapper } from '../interfaces/IWrapper';

export const LoginContext = createContext({});

export enum LoginPath {
  login = 'login',
  register = 'register',
  forgot = 'forgot',
  verify = 'verify',
  confirm = 'confirm',
  update = 'update',
  code = 'code',
  requestSent = 'requestSent',
  setup = 'setup',
  registerSuccess = 'registerSuccess',
  newPassword = 'newPassword',
  newPasswordSet = 'newPasswordSet',
}

function isForgotPasswordPath(hashFragment?: string): boolean {
  const expectedPathname: string = '#/basen/TilbakestillPassord';
  const pathname: string | undefined = hashFragment?.split('?')[0];
  return pathname === expectedPathname;
}

function isCreateUserPath(hashFragment?: string): boolean {
  const expectedPathname: string = '#/CreateUser';
  const pathname: string | undefined = hashFragment?.split('?')[0];
  return pathname === expectedPathname;
}

function isVerifyUserPath(hashFragment?: string): boolean {
  const expectedPathname: string = '#/basen/Verify';
  const pathname: string | undefined = hashFragment?.split('?')[0];
  return pathname === expectedPathname;
}

function isConfirmPath(hashFragment?: string): boolean {
  const expectedPathname: string = '#/basen/Confirm/';
  const pathname: string | undefined = hashFragment?.split('?')[0];
  return pathname === expectedPathname;
}

function extractActionKey(hashFragment?: string): string | undefined {
  const parts: string[] | undefined = hashFragment?.split('?');
  if (!parts) return parts;
  const queryString: string | undefined = parts[1];

  if (queryString) {
    const queries: string[] = queryString.split('&');
    for (const query of queries) {
      const [key, value] = query.split('=');
      if (key === 'actionKey') {
        return value;
      }
    }
  }

  return undefined;
}

export const LoginContextProvider: FC<IWrapper> = ({ children }) => {
  const { sendRequest } = useAxios();
  const { remove } = useToken();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [path, setPath] = useState<LoginPath>();
  const [loginUrl, setLoginUrl] = useState<string>();
  const [actionKey, setActionKey] = useState<string>();

  const [msg, setMsg] = useState<string>();

  const updateBasedOnLocation = () => {
    const isVerifyUser = isVerifyUserPath(location.hash);
    const isConfirm = isConfirmPath(location.hash);
    const isCreateUser = isCreateUserPath(location.hash);
    const isForgotPassword = isForgotPasswordPath(location.hash);

    setActionKey(extractActionKey(location.hash));
    if (isForgotPassword) {
      setPath(LoginPath.newPasswordSet);
      setShow(true);
    }
    if (isVerifyUser) {
      setPath(LoginPath.verify);
      setShow(true);
    }
    if (isConfirm) {
      setPath(LoginPath.confirm);
      setShow(true);
    }
    if (isCreateUser) {
      setPath(LoginPath.setup);
      setShow(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      updateBasedOnLocation(); // Waiting for HashRouter to render
    }, 500);
  }, [location]);

  const logout = async (reload = true) => {
    await sendRequest(
      {
        method: 'POST',
        url: logoutURL,
        withCredentials: true,
      },
      () => {
        remove();
        if (reload) {
          window.location.href = '/#';
          window.location.reload();
        }
      },
    );
  };

  const closeLogin = () => {
    setShow(false);
    setPath(LoginPath.login);
  };

  const values = useMemo(
    () => ({
      show,
      setShow,
      path,
      setPath,
      logout,
      loginUrl,
      setLoginUrl,
      actionKey,
      setMsg,
      closeLogin,
    }),
    [show, path, loginUrl, actionKey],
  );

  return (
    <LoginContext.Provider value={values}>
      <Fragment>{children}</Fragment>
      <Modal
        isOpen={!!msg}
        onClose={() => {
          setMsg('');
        }}
      >
        {msg}
      </Modal>
    </LoginContext.Provider>
  );
};
