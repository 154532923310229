import './QuarterApplications.scss';

import {
  fetchHousingAdminServiceAreas,
  sendQuarterApplicationsToAllocation,
  sortProcessedQuarterApplications,
  sortUnprocessedQuarterApplications,
  updateQuarterApplicationsPage,
} from 'actions/housingAdminActions';
import { fetchHousingDataOptions } from 'actions/optionsActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import DownloadButton from 'components/buttons/DownloadButton/DownloadButton';
import DateInput from 'components/DateInput/DateInput';
import DetailsCards from 'components/DetailsCards/DetailsCards';
import Loader from 'components/Loader/Loader';
import RouterModal from 'components/modals/RouterModal/RouterModal';
import { connectSlides } from 'helpers/connectWithRouter';
import { shouldUpdateAreaId } from 'helpers/housingAdminHelpers';
import _ from 'lodash/fp';
import moment from 'moment';
import { Component } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropSlideNavigation } from 'types/PropNavigationBase';

import {
  historicalQuarterAppsURL,
  unprocessedQuarterAppsURL,
} from '../../../api/housing/housing_api';
import QuarterApplicationsList from './QuarterApplicationsList/QuarterApplicationsList';

import type {
  QuarterAreaType,
  QuarterApplicationType,
  AvailableQuarterType,
} from 'types/housingAdminTypes';
type PropsType = PropSlideNavigation & {
  quarterArea: QuarterAreaType;
  updateQuarterApplicationsPage: (housingAreaId: string) => void;
  sendQuarterApplicationsToAllocation: (
    applicationIds: Array<string>,
    areaId: string,
  ) => void;
  sortUnprocessedQuarterApplications: (
    sortedApplications: Array<QuarterApplicationType>,
  ) => void;
  sortProcessedQuarterApplications: (
    sortedApplications: Array<QuarterApplicationType>,
  ) => void;
  loadingHousingAreas: boolean;
  housingDataOptionsLoaded: boolean;
  fetchHousingAdminServiceAreas: () => void;
  fetchHousingDataOptions: () => void;
};

export class UnconnectedQuarterApplications extends Component {
  declare props: PropsType;
  declare state: DeliberateAny;

  constructor(props: PropsType) {
    super(props);
    this.props.fetchHousingAdminServiceAreas();
    this.props.fetchHousingDataOptions();
    this.state = { date: undefined };

    if (!this.props.loadingHousingAreas && this.props.params.housingAreaId) {
      this.changeAreaId(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    if (shouldUpdateAreaId(this.props, nextProps)) {
      this.changeAreaId(nextProps);
    }
    if (
      !isNaN(nextProps.quarterArea.vacantFromStandardOffsetDays) &&
      this.state.date === undefined
    ) {
      this.setState({
        date: moment().add(
          nextProps.quarterArea.vacantFromStandardOffsetDays,
          'd',
        ),
      });
    }
  }

  unprocessedApplicationsDownloadLink = () =>
    `${unprocessedQuarterAppsURL}?areaId=${this.props.quarterArea.Id}`;

  historicalAllocationsDownloadLink = () =>
    `${historicalQuarterAppsURL}?areaId=${this.props.quarterArea.Id}`;

  approveAllocationsPath = () =>
    `${this.props.location.pathname}/send-søknader-til-fordeling`;

  formatMomentToString = (date) => date.format('YYYY-MM-DD');

  changeAreaId = (props: PropsType) => {
    const housingAreaId = props.params.housingAreaId || '';
    const vacantFromDate = this.state.date
      ? this.formatMomentToString(this.state.date)
      : null;
    this.props.updateQuarterApplicationsPage(housingAreaId);
  };

  ApproveAllocationsModal = (
    pendingApprovedApplications: Array<QuarterApplicationType>,
  ) => (
    <RouterModal
      path={'send-søknader-til-fordeling'}
      size="large"
      title="Send godkjente søknader til fordeling"
      ariaLabel="Send godkjente søknader til fordeling"
      submit={{
        text: 'Godkjenn',
        onClick: () =>
          this.props.sendQuarterApplicationsToAllocation(
            pendingApprovedApplications.map((application) => application.Id),
            this.props.quarterArea.Id,
          ),
      }}
    >
      <p>
        Du vil ikke ha mulighet til å gjøre om denne endringen senere. Når du
        godkjenner fordelingen begynner Bolig-og kvarterforvalter i Forsvarsbygg
        å tildele kvarter
      </p>
      <p>
        Antall godkjente søknader:{' '}
        <span className="red">{pendingApprovedApplications.length}</span>
      </p>
      <div className="margin">
        <QuarterApplicationsList
          items={pendingApprovedApplications}
          listType="approve-allocations"
        />
      </div>
    </RouterModal>
  );

  search = (date) => {
    const housingAreaId = this.props.params.housingAreaId;
    const vacantFromDate = this.formatMomentToString(date);
    // this.props.updateQuarterApplicationsPage(housingAreaId, vacantFromDate);
    if (housingAreaId) this.props.updateQuarterApplicationsPage(housingAreaId);
  };

  renderAvailableQuartersList = (
    availableQuarters: Array<AvailableQuarterType>,
  ) => {
    const establishments = _.groupBy((x) => x.Establishment, availableQuarters);
    const cards: DeliberateAny = [];

    Object.keys(establishments).forEach((key) => {
      const quartertypes = _.groupBy((x) => x.Type, establishments[key]);

      cards.push({
        header: establishments[key][0].Establishment,
        description: `Antall: ${establishments[key].length}`,
        details: (
          <div>
            {Object.keys(quartertypes).map((establishment) => (
              <p key={establishment}>
                <span className="bold">
                  {quartertypes[establishment][0].TypeName}:
                </span>{' '}
                {quartertypes[establishment].length} ledige
              </p>
            ))}
          </div>
        ),
        extraStyling: 'less-margin',
      });
    });

    return <DetailsCards expandable cards={cards} />;
  };

  render() {
    const pendingApprovedApplications =
      this.props.quarterArea.unprocessedApplications.filter(
        (application) => application.IsApproved,
      );
    const processedApplications = this.props.quarterArea.processedApplications;

    return (
      <div className="scrollable sticky-middle max-width-large">
        <Loader
          isLoading={
            this.props.quarterArea.loadingUnprocessedApplications ||
            this.props.quarterArea.loadingProcessedApplications ||
            this.props.loadingHousingAreas ||
            !this.props.housingDataOptionsLoaded ||
            this.props.quarterArea.sendingApplicationsToAllocation ||
            this.props.quarterArea.loadingAvailableQuarters
          }
        >
          <div className="double-margin">
            <h2>{this.props.quarterArea.Name}</h2>
            <div className="date-search-container">
              <h3>
                Ledige kvarter:{' '}
                <span className="red">
                  {this.props.quarterArea.availableQuarters.length}
                </span>
              </h3>
              <div className="date-search-div">
                <h3>Velg dato for kvartersøk</h3>
                <DateInput
                  placement="lower"
                  small
                  name="quarter_search_date"
                  minDate={moment()}
                  selectedDate={this.state.date}
                  onSelectDate={(d) => this.setState({ date: d })}
                  onChange={(name, date) => this.search(date)}
                />
              </div>
            </div>
            <div className="margin">
              {this.renderAvailableQuartersList(
                this.props.quarterArea.availableQuarters,
              )}
            </div>
            <div className="button-row">
              <div>
                <h3 className="flex-end">
                  Ubehandlede søknader:{' '}
                  <span className="red">
                    {this.props.quarterArea.unprocessedApplications.length}
                  </span>
                </h3>
              </div>
              <div>
                <p>
                  <DownloadButton
                    disabled={
                      this.props.quarterArea.unprocessedApplications.length ===
                      0
                    }
                    href={this.unprocessedApplicationsDownloadLink()}
                  >
                    Last ned ubehandlede søknader
                  </DownloadButton>
                </p>
              </div>
            </div>

            <div className="margin">
              <QuarterApplicationsList
                items={this.props.quarterArea.unprocessedApplications}
                listType="unprocessed"
                uncontrolledSortOptions={{
                  onSortList: this.props.sortUnprocessedQuarterApplications,
                  sortColumnOnMount: 'DateApplied',
                  sortDescending: true,
                }}
                noItemsMessage={<p>Ingen ubehandlede søknader</p>}
              />
            </div>
            <ButtonRow>
              <ButtonRow.Right
                disabled={pendingApprovedApplications.length <= 0}
                color="red"
                onClick={
                  // () => this.renderApprovedAllocationsModal
                  () => this.props.navigate(this.approveAllocationsPath())
                }
              >
                Send godkjente søknader til fordeling
              </ButtonRow.Right>
            </ButtonRow>
            {this.ApproveAllocationsModal(pendingApprovedApplications)}
          </div>

          <div>
            <div className="button-row">
              <div>
                <h3 className="flex-end">
                  Historiske søknader:{' '}
                  <span className="red">
                    {this.props.quarterArea.processedApplications.length}
                  </span>
                </h3>
              </div>
              <div>
                <p>
                  <DownloadButton
                    disabled={processedApplications.length === 0}
                    href={this.historicalAllocationsDownloadLink()}
                  >
                    Last ned historiske søknader
                  </DownloadButton>
                </p>
              </div>
            </div>
            <div className="margin">
              <p>
                Under Historiske søknader vises kvartersøknader som er godkjent
                eller avvist i løpet av de siste 30 dager.
              </p>
            </div>
            <QuarterApplicationsList
              items={this.props.quarterArea.processedApplications}
              listType="processed"
              uncontrolledSortOptions={{
                onSortList: this.props.sortProcessedQuarterApplications,
                sortColumnOnMount: 'DateApplied',
                sortDescending: true,
              }}
              noItemsMessage={<p>Ingen historiske søknader</p>}
            />
          </div>
        </Loader>
      </div>
    );
  }
}

export default connectSlides(
  (state: DeliberateAny) => ({
    loadingHousingAreas: state.housingAdmin.loadingHousingAreas,
    quarterArea: state.housingAdmin.quarterArea,
    housingDataOptionsLoaded: state.options.housingDataOptionsLoaded,
  }),
  {
    updateQuarterApplicationsPage,
    sendQuarterApplicationsToAllocation,
    sortUnprocessedQuarterApplications,
    sortProcessedQuarterApplications,
    fetchHousingAdminServiceAreas,
    fetchHousingDataOptions,
  },
)(UnconnectedQuarterApplications);
