import { useUser } from 'contexts/index';
import { FC, useEffect } from 'react';

import { DeliberateAny } from '../../types/DelibrateAny';
import Input from '../form/Input/Input';

interface IContactProps {
  formHolder: DeliberateAny;
  updateFormHolder: (name: string, value: DeliberateAny) => void;
  setFormHolder: (fh: DeliberateAny) => void;
  errors: DeliberateAny;
  alternativeNames?: DeliberateAny;
}

const ContactDetails: FC<IContactProps> = ({
  formHolder,
  updateFormHolder,
  setFormHolder,
  errors,
  alternativeNames,
}: DeliberateAny) => {
  const { user, hasUser } = useUser();
  const getName = (name: string) => {
    return alternativeNames ? alternativeNames[name] ?? name : name;
  };

  useEffect(() => {
    if (user) {
      let isChanged = false;
      Object.keys(alternativeNames).forEach((key) => {
        if (formHolder[alternativeNames[key]]) isChanged = true;
      });
      if (!isChanged) {
        const updateObject = {};
        updateObject[getName("name")] = user.Name;
        updateObject[getName("mobile")] = user.Phone;
        updateObject[getName("employeeNumber")] = hasUser ? user.EmployeeNumber : '';
        updateObject[getName("email")] = user.Email;

        setFormHolder((x) => {
          return { ...x, ...updateObject };
        });
      }
    }
  }, [user]);

  return (
    <div className="row margin">
      <div className="content-group grey padded">
        <div className="row margin">
          <h2>Kontaktopplysninger</h2>
        </div>
        <div className="row margin" id="error_name">
          <Input
            disabled={hasUser}
            type="text"
            maxLength={60}
            placeholder="Maks. 60 tegn"
            name={getName("name")}
            value={formHolder[getName("name")]}
            onChange={(name, val) => updateFormHolder(name, val)}
            title="Navn"
            errors={errors}
          />
        </div>
        <div className="row margin" id="error_mobile">
          <Input
            disabled={hasUser}
            type="text"
            placeholder="Ditt mobilnummer"
            name={getName("mobile")}
            value={formHolder[getName("mobile")]}
            onChange={(name, val) => updateFormHolder(name, val)}
            title="Mobilnummer"
            errors={errors}
          />
        </div>
        <div className="row margin" id="error_empoyee_no">
          <Input
            disabled={hasUser}
            type="text"
            placeholder="Ditt ansattnummer"
            name={getName("employeeNumber")}
            value={formHolder[getName("employeeNumber")]}
            onChange={(name, val) => updateFormHolder(name, val)}
            title="Ditt ansattnummer (ikke påkrevd)"
            errors={errors}
          />
        </div>
        <div className="row margin" id="error_email">
          <Input
            disabled={hasUser}
            type="email"
            placeholder="Din epostadresse"
            name={getName("email")}
            value={formHolder[getName("email")]}
            onChange={(name, val) => updateFormHolder(name, val)}
            title="Din epostadresse"
            errors={errors}
          />
        </div>
        <div className="row margin" id="error_copy">
          <Input
            type="email"
            placeholder="Kopimottakers epostadresse"
            name={getName("copy")}
            value={formHolder[getName("copy")]}
            onChange={(name, val) => updateFormHolder(name, val)}
            title="Send kopi av denne innmeldingen (ikke påkrevd)"
            errors={errors}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
