import {
  fetchHousingAdminServiceAreas,
  updateHousingApplicationsPage,
} from 'actions/housingAdminActions';
import { fetchHousingDataOptions } from 'actions/optionsActions';
import connect from 'helpers/connectWithRouter';
import { shouldUpdateAreaId } from 'helpers/housingAdminHelpers';
import { Component } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropSlideNavigation } from 'types/PropNavigationBase';

import HousingApplicationsOverview from './HousingApplicationsSlides/HousingApplicationsOverview';

import type { HousingAreaType } from 'types/housingAdminTypes';
type PropsType = PropSlideNavigation & {
  housingArea: HousingAreaType;
  loadingHousingAreas: boolean;
  fetchHousingAdminServiceAreas: () => void;
  fetchHousingDataOptions: () => void;
  updateHousingApplicationsPage: (housingAreaId: string) => void;
};

export class UnconnectedHousingApplications extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    this.props.fetchHousingAdminServiceAreas();
    this.props.fetchHousingDataOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    if (shouldUpdateAreaId(this.props, nextProps)) {
      this.changeAreaId(nextProps);
    }
  }

  changeAreaId = (props: PropsType) => {
    const housingAreaId = props.params.housingAreaId || '';
    this.props.updateHousingApplicationsPage(housingAreaId);
  };

  render() {
    return <HousingApplicationsOverview {...this.props} />;
  }
}

export default connect(
  (state: DeliberateAny) => ({
    loadingHousingAreas: state.housingAdmin.loadingHousingAreas,
    housingArea: state.housingAdmin.housingArea,
  }),
  {
    updateHousingApplicationsPage,
    fetchHousingAdminServiceAreas,
    fetchHousingDataOptions,
  },
)(UnconnectedHousingApplications);
