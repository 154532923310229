import './RoomDetails.scss';

import { DownIcon, UpIcon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import { FC, Fragment, useState } from 'react';
import { RoomWithGuest } from 'types/bookingRequests';

interface IRoomDetails {
  rooms?: RoomWithGuest[];
}

const RoomDetails: FC<IRoomDetails> = ({ rooms }) => {
  const [openRooms, setOpenRooms] = useState<string[]>([]);

  return (
    <div className="fb-summary">
      <h2 className="subtitle-m">Kontaktopplysninger</h2>
      {rooms?.map((room: RoomWithGuest, index: number) => {
        const roomKey = `${room.index}-${index}`;
        const isOpen = openRooms.includes(roomKey);
        return (
          <div key={roomKey} className="fb-room">
            <div
              className="pointer fb-room-heading"
              onClick={() =>
                setOpenRooms((x) =>
                  isOpen ? x.filter((i) => i !== roomKey) : [...x, roomKey],
                )
              }
            >
              <span>
                <strong>{`Rom ${room.index}`}</strong> {`(${room.heading})`}
              </span>

              <BasenIcon icon={isOpen ? UpIcon : DownIcon} />
            </div>
            {room?.guests && (
              <div className={`fb-room-info ${isOpen ? 'open' : 'closed'}`}>
                {room?.guests?.map((guest, i) => (
                  <Fragment key={`${i}-${index}-${guest.fullName}`}>
                    <div className="fb-summary-value w-1_1">
                      <div className="fb-summary-value-label">Fullt navn</div>
                      <div className="fb-summary-value-value">
                        {guest.fullName}
                      </div>
                    </div>

                    <div className="fb-summary-value w-1_1">
                      <div className="fb-summary-value-label">Mobilnummer</div>
                      <div className="fb-summary-value-value">
                        {guest.mobile}
                      </div>
                    </div>

                    <div className="fb-summary-value w-1_1">
                      <div className="fb-summary-value-label">
                        Spesielle behov/ønsker{' '}
                        <span className="thin">(valgfri)</span>
                      </div>
                      <div className="fb-summary-value-value">
                        {guest.additionalInfo || 'Ingen spesielle behov/ønsker'}
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RoomDetails;
