// @flow

import moment from 'moment';
import _ from 'lodash/fp';

import { updateSingleError } from 'helpers/commonReducerHelpers';
import { allocationRoundIsApplicable } from 'helpers/housingHelpers';

export const initialState = {
  quarterApplication: {
    type: 'quarter',
    applicantId: '',
    applicantCategory: '',
    petCount: 0,
    petInfo: '',
    primaryEmail: '',
    otherInfo: '',
    dateOfBirth: '',
    newWorkTitle: '',
    assignmentDocumentation: false,
    currentHousingSituation: -1,
    currentHousingZipCode: '',
    currentHousingCity: '',
    currentHousingCounty: '',
    currentHousingAddress: '',
    currentHousingUnitNumber: '',
    housingPreferences: '',
    requiredDocumentation: '',
    documentationDeadline: '',
    coApplicants: [],
    receiveSMSNotifications: false,
    terms: false,
    saveInfo: false,
    isRenewal: false,
    housingAreaId: '',
  },
  housingApplication: {
    type: 'housing',
    applicantId: '',
    applicantCategory: '',
    petCount: 0,
    petInfo: '',
    primaryEmail: '',
    otherInfo: '',
    maritalStatus: -1,
    children: [],
    dateOfBirth: '',
    newWorkTitle: '',
    assignmentDocumentation: false,
    currentHousingSituation: -1,
    currentHousingZipCode: '',
    currentHousingCity: '',
    currentHousingCounty: '',
    currentHousingAddress: '',
    currentHousingUnitNumber: '',
    housingPreferences: '',
    requiredDocumentation: '',
    documentationDeadline: '',
    coApplicants: [],
    receiveSMSNotifications: false,
    terms: false,
    saveInfo: false,
    isRenewal: false,
    housingAreaId: '',
  },
  housingRenewalApplication: {
    type: 'housingrenewal',
    applicantId: '',
    applicantCategory: '',
    petCount: 0,
    petInfo: '',
    primaryEmail: '',
    otherInfo: '',
    maritalStatus: -1,
    children: [],
    dateOfBirth: '',
    newWorkTitle: '',
    assignmentFromDate: moment(),
    assignmentToDate: moment().add(2, 'y'),
    assignmentDocumentation: false,
    currentHousingSituation: -1,
    currentHousingZipCode: '',
    currentHousingCity: '',
    currentHousingCounty: '',
    currentHousingAddress: '',
    currentHousingUnitNumber: '',
    housingPreferences: '',
    requiredDocumentation: '',
    documentationDeadline: '',
    coApplicants: [],
    receiveSMSNotifications: false,
    terms: false,
    saveInfo: false,
  },
  housingInfoText: {},
  housingInfoTextLoaded: false,
  serviceAreaInHousingArea: false,
  housingAreas: [],
  serviceAreas: [],
  serviceAreasLoaded: false,
  sendingApplication: false,
  sentApplication: false,
};

const serviceAreaInHousingArea = (
  selectedServiceArea,
  serviceAreas,
  selectedHousingArea,
) => {
  const serviceArea = serviceAreas.find(
    (area) => area.key === selectedServiceArea,
  );
  if (serviceArea) {
    return serviceArea.housingAreaId === selectedHousingArea;
  }

  return undefined;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_APPLICATION':
      return {
        ...state,
        errors: initialState.errors,
        quarterApplication: initialState.quarterApplication,
        housingApplication: initialState.housingApplication,
        housingRenewalApplication: initialState.housingRenewalApplication,
        serviceAreaInHousingArea: initialState.serviceAreaInHousingArea,
        housingArea: initialState.housingArea,
        serviceAreaName: initialState.serviceAreaName,
        sendingApplication: false,
        sentApplication: false,
        roundInfo: undefined,
      };

    case 'UPDATE_APPLICATION':
      return _.set(
        `${action.applicationType}Application.${action.key}`,
        action.value,
        state,
      );

    case 'UPDATE_ALL_APPLICATION_ERRORS': {
      // console.log('UPDATE_ALL_APPLICATION_ERRORS ');
      return _.set('errors', action.errors, state);
    }

    case 'UPDATE_SINGLE_APPLICATION_ERROR': {
      // console.log('UPDATE_ALL_APPLICATION_ERRORS ');
      return {
        ...state,
        errors: updateSingleError({ ...state.errors }, action),
      };
    }

    case 'RECEIVE_HOUSING_INFO_TEXT':
      return {
        ...state,
        housingInfoTextLoaded: true,
        housingInfoText: {
          ...action.data,
        },
      };

    case 'FETCHING_APPLICATIONS':
      return {
        ...state,
        isLoading: true,
      };

    case 'RECEIVE_APPLICATIONS_IN_AREA': {
      const areaId = action.areaId;
      const applicationType = action.applicationType;
      const housingApplications = action.applications.MyHousingApplications;
      const quarterApplications = action.applications.MyQuarterApplications;

      const hasActiveApplications = () => {
        if (applicationType === 'housing') {
          return (
            housingApplications.length > 0 &&
            housingApplications.some(
              (application) =>
                areaId === application.HousingAreaId &&
                application.ApplicationState !== 'Avslått bolig' &&
                application.ApplicationState !== 'Overført til åpen runde' &&
                application.ApplicationState !== 'Ikke tildelt bolig' &&
                application.ApplicationState !==
                  'Avslått grunnet manglende svar',
            )
          );
        } else if (applicationType === 'quarter') {
          return (
            quarterApplications.length > 0 &&
            quarterApplications.some(
              (application) =>
                areaId === application.Details.HousingAreaId &&
                application.State !== 'Godkjent',
            )
          );
        }

        return false;
      };

      const hasApplicationSentToAllocation = () => {
        if (applicationType === 'quarter') {
          return (
            quarterApplications.length > 0 &&
            quarterApplications.some(
              (application) =>
                areaId === application.Details.HousingAreaId &&
                application.State === 'Godkjent',
            )
          );
        }
        return false;
      };

      return {
        ...state,
        isLoading: false,
        hasApplicationInArea: hasActiveApplications(),
        hasApplicationSentToAllocationInArea: hasApplicationSentToAllocation(),
      };
    }

    case 'RECEIVE_HOUSING_AND_SERVICE_AREAS':
      return {
        ...state,
        housingAreas: action.data.HousingAreas.map((areas) => ({
          value: areas.Name,
          key: areas.Id.toString(),
          info: areas.Info,
          name: areas.Name,
          region: areas.Region,
          housingManagerEmail: areas.HousingManagerEmail,
          housingSecretaryEmail: areas.HousingSecretaryEmail,
        })),
        serviceAreas: action.data.ServiceAreas.map((area) => ({
          value: area.Name,
          key: area.Id.toString(),
          housingAreaId: area.HousingAreaId,
          arenaId: area.ArenaId,
          arenaName: area.ArenaName,
        })),
        serviceAreasLoaded: true,
      };

    case 'SET_SERVICE_AREA_IN_APPLICATION': {
      const serviceArea = action.serviceArea;
      return {
        ...state,
        serviceAreaInHousingArea: true,
        [`${action.applicationType}Application`]: {
          ...state[`${action.applicationType}Application`],
          serviceAreaId: serviceArea.key,
        },
        serviceAreaName: serviceArea.value,
      };
    }

    case 'SET_HOUSING_AREA_IN_APPLICATION':
      return {
        ...state,
        serviceAreaInHousingArea: serviceAreaInHousingArea(
          state[`${action.applicationType}Application`].serviceAreaId,
          state.serviceAreas,
          action.housingAreaId,
        ),
        [`${action.applicationType}Application`]: {
          ...state[`${action.applicationType}Application`],
          housingAreaId: action.housingAreaId,
        },
        housingArea: state.housingAreas.find(
          (area) =>
            // $FlowFixMe
            area.key === action.housingAreaId,
        ),
      };

    case 'FETCH_ALLOCATION_ROUND_INFO':
      return {
        ...state,
        roundInfoLoading: true,
      };

    case 'RECEIVE_ALLOCATION_ROUND_INFO':
      return {
        ...state,
        roundInfo: action.roundInfo,
        roundInfoLoading: false,
        housingApplication: {
          ...state.housingApplication,
          roundId: allocationRoundIsApplicable(action.roundInfo)
            ? action.roundInfo.Id
            : null,
          documentationDeadline: allocationRoundIsApplicable(action.roundInfo)
            ? action.roundInfo.ApplicationDeadline
            : state.housingApplication.documentationDeadline,
        },
      };

    case 'UPDATE_MARITAL_STATUS':
      return _.set(
        `${action.applicationType}Application.${action.key}`,
        action.status,
        state,
      );

    case 'ADD_CHILD':
      return _.set(
        `housingApplication.children[${state.housingApplication.children.length}]`,
        {},
        state,
      );

    case 'REMOVE_CHILD':
      return {
        ...state,
        housingApplication: {
          ...state.housingApplication,
          children: [
            // $FlowFixMe: action properties doesn't work inside functions
            ...state.housingApplication.children.filter(
              (child, i) => i !== action.index,
            ),
          ],
        },
      };

    case 'REMOVE_CHILDREN':
      return _.set('housingApplication.children', [], state);

    case 'CLEAR_PET_INFO':
      return {
        ...state,
        [`${action.applicationType}Application`]: {
          ...state[`${action.applicationType}Application`],
          petInfo: '',
          petCount: 0,
          otherInfo: '',
        },
      };

    case 'SET_POSTAL_NUMBER':
      return _.set(
        `${action.applicationType}Application.${action.key}`,
        action.postalCode,
        state,
      );

    case 'ADD_CO_APPLICANT': {
      const key = `${action.applicationType}Application.coApplicants`;
      const coApplicants = _.get(key, state);
      return _.set(key, coApplicants.concat({}), state);
    }

    case 'REMOVE_CO_APPLICANT': {
      const key = `${action.applicationType}Application.coApplicants`;
      const index = action.index;
      const coApplicants = _.get(key, state).filter((e, i) => i !== index);
      return _.set(key, coApplicants, state);
    }

    case 'REMOVE_CO_APPLICANT_ALL_BUT_FIRST': {
      const key = `${action.applicationType}Application.coApplicants`;
      const coApplicants = _.get(key, state);
      return _.set(key, coApplicants.slice(0, 1), state);
    }

    case 'SENDING_APPLICATION':
      return _.set('sendingApplication', true, state);

    case 'RECEIVE_SEND_APPLICATION': {
      return _.flow(
        _.set('sendingApplication', false),
        _.set('sentApplication', true),
      )(state);
    }

    case 'ERROR_SEND_APPLICATION':
      return _.set('sendingApplication', false, state);

    case 'RECEIVE_HOUSING_CO_APPLICANT': {
      const key = `${action.applicationType}Application`;
      return {
        ...state,
        [`${key}`]: {
          ...state[`${key}`],
          coApplicantInAreasStatus: {
            isCoApplicant: action.data.IsCoApplicant,
            isMainApplicant: action.data.IsMainApplicant,
            coApplicants: action.data.CoApplicantIds,
          },
        },
      };
    }

    default:
      return state;
  }
};
