// @flow

import "./GuestColumns.scss";

import List from "components/List/List";
import connect from "helpers/connectWithRouter";
import React, { Component } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import guestColumns from "./GuestColumns";
import GuestItem from "./GuestItem/GuestItem";

import type { GuestItemType } from "types/guardPageTypes";
import type { ClientPagingType } from "types/listTypes";
type PropsType = {
  items: Array<GuestItemType>;
  clientPaging?: ClientPagingType;
};

export class UnconnectedGuestList extends Component {
  declare props: PropsType;

  renderItem = (item: GuestItemType) => {
    return (
      <GuestItem
        key={`${item.Reference}${item.GuestName}${item.Phone}`}
        item={item}
        columns={guestColumns as DeliberateAny}
        mountAsExpanded={this.props.items.length === 1}
      />
    );
  };

  render() {
    return (
      <div className="guest-columns">
        <List
          columns={guestColumns as DeliberateAny}
          renderItem={this.renderItem}
          noItemsMessage={<p>Ingen gjester funnet.</p>}
          alternateRows
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(null, {})(UnconnectedGuestList);
