import FBHelpers from 'helpers/_helpers';
import _ from 'lodash';
import React, { Component, ReactNode } from 'react';
import Space from 'src/layout/Space';
import { InputField, TextAreaField } from 'src/pages/Reporting/ReportingFormComponents';
import * as yup from 'yup';

import ChildInput from '../../../pages/Housing/ChildInput';
import { DeliberateAny } from '../../../types/DelibrateAny';
import ButtonRow from '../../buttons/ButtonRow/ButtonRow';
import DateInput from '../../DateInput/DateInput';
import ConfirmationModal from '../../modals/ConfirmationModal/ConfirmationModal';
import Checkbox from '../Checkbox/Checkbox';
import HasPetConfirmation from '../HasPetConfirmation/HasPetConfirmation';
import Input from '../Input/Input';
import RadioButtonGroup from '../RadioButtonGroup/RadioButtonGroup';
import RegionSelectErrorWrapper from '../RegionSelect/RegionSelectErrorWrapper';
import Select from '../Select/Select';
import SubcontractorConfirmation from '../SubcontractorConfirmation/SubcontractorConfirmation';
import TextArea from '../TextArea/TextArea';
import UnclassifiedConfirmation from '../UnclassifiedConfirmation/UnclassifiedConfirmation';
import UnclassifiedStamp from '../UnclassifiedStamp/UnclassifiedStamp';

type PropsType = {
  schema?: yup.ObjectSchema<DeliberateAny>;
  schemaContext?: DeliberateAny;
  updateAllValidationErrors: (errors: DeliberateAny) => void;
  updateOneValidationError: (name: string, errors: DeliberateAny) => void;
  onChange: (key: string, value: string) => void;
  onSubmit: () => void;
  onSubmitWithResult?: () => boolean;
  errors: DeliberateAny;
  form: DeliberateAny;
  children?: ReactNode | Array<ReactNode>;
  unclassifiedStamp?: boolean;
};

class FormWrapper extends Component {
  declare props: PropsType;
  validate: DeliberateAny;

  state: {
    showErrorModal: boolean;
  };

  constructor(props: PropsType) {
    super(props);

    this.state = {
      showErrorModal: false,
    };
  }

  formatErrors = (err) => {
    if (err instanceof yup.ValidationError) {
      const validationErrors: any = {};

      err.inner.forEach((error) => {
        if (error.path) {
          validationErrors[error.path] = (error as DeliberateAny).message?.rule
            ? error.message
            : { rule: error.message };
        }
      });
      return validationErrors;
    }
    return [];
  };

  onSubmit = async () => {
    try {
      await this.props.schema?.validate(this.props.form, {
        abortEarly: false,
        context: this.props.schemaContext,
      });

      this.props.onSubmit();
      this.props.updateAllValidationErrors({});
    } catch (err) {
      this.props.updateAllValidationErrors(this.formatErrors(err));
      if (FBHelpers.isMobile()) {
        this.setState({ showErrorModal: true });
      }
    }
  };

  onSubmitWithResult = async () => {
    try {
      await this.props.schema?.validate(this.props.form, {
        abortEarly: false,
        context: this.props.schemaContext,
      });

      this.props.updateAllValidationErrors({});

      if (this.props.onSubmitWithResult) {
        return this.props.onSubmitWithResult();
      }
      return false;
    } catch (err) {
      this.props.updateAllValidationErrors(this.formatErrors(err));
      if (FBHelpers.isMobile()) {
        this.setState({ showErrorModal: true });
      }
    }
  };

  renderWrappedChildren = (children: ReactNode): DeliberateAny =>
    React.Children.map(children, (child: DeliberateAny) => {
      // This is support for non-node elements (eg. pure text), they have no props
      if (_.isNil(child) || !child.props) {
        return child;
      }

      const onChange = child.props.onChange || this.props.onChange;
      let extraProps = {};

      if (
        [
          Input,
          DateInput,
          TextArea,
          Checkbox,
          RadioButtonGroup,
          RegionSelectErrorWrapper,
          Select,
          UnclassifiedConfirmation,
          SubcontractorConfirmation,
          HasPetConfirmation,
          ChildInput,
          InputField,
          TextAreaField,
        ].includes(child.type)
      ) {
        extraProps = {
          onChange,
          errors: this.props.errors,
        };
      }

      if (
        (child.type === ButtonRow.Left || child.type === ButtonRow.Right) &&
        child.props.type === 'submit'
      ) {
        extraProps = {
          onClick: () => {
            this.onSubmit();
          },
          errors: this.props.errors,
        };
      }

      if (child.props.children) {
        return React.cloneElement(child, {
          children: this.renderWrappedChildren(child.props.children),
          ...extraProps,
        });
      }

      return React.cloneElement(child, extraProps);
    });

  render() {
    const childrenWithProps = this.renderWrappedChildren(this.props.children);
    return (
      <div>
        {this.props.unclassifiedStamp && <UnclassifiedStamp />}
        {childrenWithProps}
        <ConfirmationModal
          title="Obligatoriske felter mangler"
          infoText="Vennligst gå over skjemaet og sjekk at alle feltene er fylt ut riktig!"
          isOpen={this.state.showErrorModal}
          onClose={() => this.setState({ showErrorModal: false })}
        />
        <Space height="2rem" />
      </div>
    );
  }
}

export default FormWrapper;
