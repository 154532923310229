import { useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import Input from '../../components/form/Input/Input';
import Select from '../../components/form/Select/Select';

const L3Filter = ({
  subject,
  setSubject,
  tag,
  setTag,
  q,
  setQ,
  items,
}: DeliberateAny) => {
  const [subjects, setSubjects] = useState<DeliberateAny[]>([]);
  const [tags, setTags] = useState<DeliberateAny[]>([]);

  const extractDistinct = (array, arrowFunction) => {
    const extractedValues = array.map(arrowFunction);
    const distinct = [...new Set(extractedValues)];

    const sortedDistinct = distinct.sort(
      (a: DeliberateAny, b: DeliberateAny) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      },
    );

    return sortedDistinct.map((x) => {
      return { key: x, value: x };
    });
  };

  useEffect(() => {
    setSubjects([
      ...extractDistinct(items, (x) => x.Subject),
      { key: 'favs', value: 'Favoritter' },
    ]);

    const tagOptions = items
      .filter((i) => i.L3Extra?.Tags && i.L3Extra.Tags.length > 0)
      .map((i) => i.L3Extra?.Tags)
      .flat()
      .filter((t) => t.trim() !== '');

    setTags(extractDistinct(tagOptions, (x) => x));
  }, []);

  return (
    <div className="form">
      <div className="flex-row margin">
        <Select
          label="Tema"
          disabled={subjects.length < 1}
          value={subject}
          options={subjects}
          showAllOption
          onChange={(name, value) => setSubject(value)}
          name={''}
        />
        <Select
          label="Tag"
          disabled={tags.length < 1}
          value={tag}
          options={tags}
          showAllOption
          onChange={(name, value) => setTag(value)}
          name={''}
        />
        <Input
          title="Fritekstsøk"
          placeholder="Søk"
          value={q}
          onChange={(name, value) => setQ(value)}
          name={''}
        />
      </div>
    </div>
  );
};

export default L3Filter;
