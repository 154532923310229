/* eslint react/prop-types: 0 */

import { useModal } from "contexts/index";
import DOMPurify from "dompurify";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DeliberateAny } from "types/DelibrateAny";

import { arenainfoURL } from "../../api/booking/booking_api";
import { axiosFetch } from "../../hooks/useAxios";

const ConfirmationInfo = ({ markup }) => {
  const { showModal } = useModal();
  const navigate = useNavigate();

  const test = (e) => {
    e.preventDefault();
    axiosFetch({
      url: arenainfoURL,
      data: { locationId: e.target.getAttribute("data-location-id") },
    }).then((html) => {
      showModal({
        html,
        size: "large",
        extraClass: "no-padding",
        carousel: {
          container: ".carousel",
        },
      });
    });
  };

  useEffect(() => {
    if (!markup) {
      navigate("/booking");
    } else {
      const element: DeliberateAny = document.querySelector(
        '[data-action="arena-info"]'
      );
      if (element) {
        element.setAttribute("href", `#/booking/${element.text}`);
        element.addEventListener("click", (event) => test(event));
      }
    }
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(unescape(markup)),
      }}
    />
  );
};

export default ConfirmationInfo;
