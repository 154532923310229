import {
  registerAdditionalInfoForCurrentUserURL,
  registerAdditionalInfoURL,
} from "api/basenproff/basenproff_api";
import BasenDropzone from "components/Dropzone/Dropzone";
import Input from "components/form/Input/Input";
import TextArea from "components/form/TextArea/TextArea";
import Modal from "components/modals/Modal/Modal";
import { useModal } from "contexts/index";
import { createRef, useEffect, useState } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import useAxios from '../../../hooks/useAxios';
import UnclassifiedConfirmation from '../../form/UnclassifiedConfirmation/UnclassifiedConfirmation';

const AdditionalInformationServiceOrderModal = ({
  openModal,
  setOpenModal,
  SO,
  reporter,
  basenProffAccess,
}) => {
  const [errors, setErrors] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [files, setFiles] = useState<DeliberateAny[]>([]);
  const [buttonText, setButtonText] = useState("Bekreft");
  const { requestError, sendRequest } = useAxios();
  const { showModal } = useModal();
  const modalContentRef = createRef<DeliberateAny>();

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (requestError) {
      showModal({
        title: "Oops",
        infoText: "Noe gikk galt. Prøv igjen senere.",
      });
    }
  }, [requestError]);

  const submit = async () => {
    // check for errors before POST
    const formErrors = validateForm();

    if (Object.keys(formErrors).length !== 0) {
      setErrors(formErrors);
      modalContentRef.current.contentElement.scrollTop = 0;
    } else {
      setButtonText("Sender...");
      const filesExist = files.length > 0;
      const formData = new FormData();
      const additionalInfo = {
        Title: title,
        Description: description,
        "classified-confirmation": checkBox && "classified-confirmation",
        Documents: files.map((file) => ({
          title: file.title,
          name: file.file.name,
          size: file.file.size,
          lastModifiedDate: file.file.lastModifiedDate,
          type: file.file.type,
        })),
        Reporter: reporter,
        ServiceOrderNumber: SO,
      };
      formData.append("additionalInfo", JSON.stringify(additionalInfo));
      files.map((file, index) => formData.append(`file[${index}]`, file.file));

      await sendRequest(
        {
          method: "POST",
          url: basenProffAccess
            ? registerAdditionalInfoURL
            : registerAdditionalInfoForCurrentUserURL,
          data: filesExist ? formData : additionalInfo,
          headers: filesExist ? { "Content-Type": "multipart/form-data" } : {},
        },
        () => {
          showModal({
            title: "Sendt",
            infoText: "Opplysningene har blitt sendt inn.",
          });
          setOpenModal(false);
        }
      );
    }
  };

  const validateForm: DeliberateAny = () => {
    const errorRules = {
      Title: { rule: "required" },
      Description: { rule: "required" },
      IsUnclassified: { rule: "required" },
    };

    const temp: DeliberateAny = {};
    if (!title) temp.title = errorRules.Title;
    if (!description) temp.description = errorRules.Description;
    if (!checkBox) temp.isUnclassified = errorRules.IsUnclassified;
    return temp;
  };

  return (
    <Modal
      submit={{
        text: buttonText,
        onClick: submit,
      }}
      cancel={{ onClick: () => setOpenModal(false) }}
      isOpen={openModal}
      size="large"
      ref={modalContentRef}
      title="Tilleggsinformasjon"
      ariaLabel="Tilleggsinformasjon"
      unclassifiedStamp
    >
      <div className="flex-row margin">
        <div>
          <Input
            title="Tittel"
            name="title"
            value={title}
            onChange={(name, value) => setTitle(value)}
            errors={errors}
            className="row trailer"
          />
          <TextArea
            title="Beskrivelse"
            className="textarea--big margin"
            name="description"
            value={description}
            onChange={(name, value) => setDescription(value)}
            errors={errors}
          />
          <div className="row margin">
            <div className="content-group grey padded">
              <div className="row margin">
                Vedlegg
                <BasenDropzone
                  acceptedFileTypes="image/pjp, image/jpg, image/pjpeg, image/jpeg, image/jfif, image/gif"
                  strAcceptedFileTypes=".pjp, .jpg, .pjpeg, .jpeg, .jfif eller .gif"
                  showSizeInKB
                  updateFileDescription={(fileName, fileTitle) =>
                    setFiles((state) => {
                      return state.map((item) =>
                        item.file.name === fileName
                          ? { ...item, title: fileTitle }
                          : item
                      );
                    })
                  }
                  addFile={(file) => {
                    setFiles((state) => [
                      ...state,
                      {
                        title: file.name,
                        file,
                      },
                    ]);
                  }}
                  files={files}
                  removeFile={(x) => {
                    setFiles((prevState) =>
                      prevState.filter((e) => e.file.preview !== x.file.preview)
                    );
                  }}
                  maxFiles={9}
                />
              </div>
            </div>
          </div>
          <UnclassifiedConfirmation
            checked={checkBox}
            onChange={() => setCheckBox(!checkBox)}
            errors={errors}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AdditionalInformationServiceOrderModal;
