import './NewDesign.scss';

import React from 'react';
import { IWrapper } from 'src/interfaces/IWrapper';
import Top from 'src/layout/Top';

const NewDesign: React.FC<IWrapper> = ({ children }) => {
  return (
    <div className="fb-content">
      <Top />
      {children}
    </div>
  );
};

export default NewDesign;
