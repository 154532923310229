import 'react-tooltip/dist/react-tooltip.css';
import 'react-multi-date-picker/styles/layouts/mobile.css';

import { googleMapsSettingURL } from 'api/cms/cms_api';
import Loader from 'components/Loader/Loader';
import { ApplicationContextProvider } from 'contexts/ApplicationContext';
import { InactivityContextProvider } from 'contexts/InactivityContext';
import { LoginContextProvider } from 'contexts/LoginContext';
import { SignalRContextProvider } from 'contexts/SignalRContext';
import { ToastContextProvider } from 'contexts/ToastContext';
import { configureYupLocale } from 'helpers/yupLocales';
import useAxios from 'hooks/useAxios';
import moment from 'moment';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import ConditionalLayoutWrapper from 'styles/ConditionalLayoutWrapper';

import { LoadScript } from '@react-google-maps/api';

import { ModalContextProvider } from './contexts/ModalContext';
import { UserContextProvider } from './contexts/UserContext';
import Content from './layout/Content';
import LoginForm from './layout/login/LoginForm';
import store from './store';

// Set the Moment locale globally for Norwegian Bokmål
moment.locale('nb');

const App = () => {
  configureYupLocale('no');

  const { sendRequest } = useAxios();
  const [apiKey, setApiKey] = useSessionStorage('gm', '');

  useEffect(() => {
    const getApiKey = async () => {
      await sendRequest(
        { method: 'GET', url: googleMapsSettingURL },
        (result) => {
          setApiKey(result?.GoogleMapsApiKey);
        },
      );
    };

    getApiKey();
  }, []);

  return (
    <HashRouter>
      <Provider store={store}>
        <ApplicationContextProvider>
          <LoginContextProvider>
            <UserContextProvider>
              <ModalContextProvider>
                <InactivityContextProvider>
                  <SignalRContextProvider>
                    <ToastContextProvider>
                      <div className="tab-shortcut">
                        <a
                          data-action="to-content"
                          href="#"
                          className="to-content"
                        >
                          Hopp til innhold
                        </a>
                      </div>
                      <div className="site-animator js-site-animator">
                        <LoadScript
                          googleMapsApiKey={apiKey}
                          loadingElement={
                            <Loader isLoading={true} noBackground />
                          }
                        >
                          <ConditionalLayoutWrapper>
                            <Content />
                          </ConditionalLayoutWrapper>
                        </LoadScript>
                      </div>
                      <LoginForm />
                    </ToastContextProvider>
                  </SignalRContextProvider>
                </InactivityContextProvider>
              </ModalContextProvider>
            </UserContextProvider>
          </LoginContextProvider>
        </ApplicationContextProvider>
      </Provider>
    </HashRouter>
  );
};

export default App;
