import HelpText from 'components/HelpText';
import { FC, useEffect } from 'react';

import { IFormPropBase, ValueOption } from './FormPropBase';

interface IRadioButtonGroupProps extends IFormPropBase {
  options: ValueOption[];
  initial?: ValueOption;
}

const RadioButtonGroup: FC<IRadioButtonGroupProps> = ({
  name,
  label,
  formHolder,
  updateForm,
  errors,
  helpInfo,
  options,
  initial,
  disabled,
}) => {
  /*
  useEffect(() => {
    if (initial) {
      updateForm(name, initial.value);
    }
  }, [initial]);
  */

  const handleChange = (e) => {
    updateForm(name, e.target.value);
  };

  return (
    <div className="fb-form-field">
      <HelpText content={helpInfo}>
        <label className={`fb-form-label ${errors[name] ? 'invalid' : ''}`}>
          {errors[name] ? errors[name] : label ? label : name}
        </label>
      </HelpText>

      <div>
        {options?.map((opt, i) => {
          return (
            <label className="radio" key={`${name}_${i}`}>
              <input
                disabled={disabled}
                type="radio"
                checked={formHolder[name] == opt.value}
                name={name}
                value={opt.value}
                onChange={handleChange}
              />
              <span className="form-option">{opt.label}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default RadioButtonGroup;
