import '../../modules/Booking/AboutLocation.scss';

import { arenaByIdUrl } from 'api/booking/booking_api_v2';
import Loader from 'components/Loader/Loader';
import { useApplication } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DetailedArenaResponse } from 'types/bookingRequests';

import AboutArena from '../Booking/AboutLocation';

const LocationInfo = () => {
  const { sendRequest, requestLoading } = useAxios();
  const { locationId } = useParams();
  const { setHeading, clear } = useApplication();
  const [location, setLocation] = useState<DetailedArenaResponse>();

  const fetchLocation = async () => {
    if (locationId)
      await sendRequest(
        {
          method: 'GET',
          url: arenaByIdUrl(locationId),
        },
        (res) => {
          setHeading(`Om ${res.heading}`);
          setLocation(res);
        },
      );
  };

  useEffect(() => {
    clear();
    fetchLocation();
  }, [locationId]);

  return (
    <Loader isLoading={requestLoading}>
      {locationId && <AboutArena locationId={locationId} />}
    </Loader>
  );
};

export default LocationInfo;
