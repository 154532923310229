import { serviceOrderCategoriesURL } from 'api/basenproff/basenproff_api';
import Select from 'components/form/Select/Select';
import Spinner from 'components/Spinner/Spinner';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InputField } from 'src/pages/Reporting/ReportingFormComponents';
import { DeliberateAny } from 'types/DelibrateAny';

const CategoryFaultTypeSelect = ({ formHolder, updateFormHolder, errors }) => {
  const { requestLoading, sendRequest } = useAxios();
  const { id } = useParams();
  const [serviceOrderCategories, setServiceOrderCategories] =
    useState<DeliberateAny[]>();
  const [caseTypeOptions, setCaseTypeOptions] = useState<DeliberateAny[]>();

  const fetchCategories = async () => {
    await sendRequest(
      {
        method: "GET",
        url: serviceOrderCategoriesURL,
        withCredentials: true,
      },
      (res) => {
        setServiceOrderCategories(res);
        if (id) {
          const main = res.find((mc) => mc.Id === id);

          if (!main) return;
          updateFormHolder("CategoryId", id);
          updateFormHolder(
            "CaseTypeId",
            main.ErrorTypes.map((type: DeliberateAny) => ({
              key: type.Id,
              value: type.Label,
            }))[0].key
          );
        }
      }
    );
  };

  useEffect(() => {
    if (serviceOrderCategories) {
      const main = serviceOrderCategories.find(
        (mc) => mc.Id === formHolder.CategoryId
      );
      if (main) {
        updateFormHolder("_category", main);
        const options = main?.ErrorTypes.map((type: DeliberateAny) => ({
            key: type.Id,
            value: type.Label,
          }));
        setCaseTypeOptions(options);
        updateFormHolder("CaseTypeId", options[0].key);
      }
    }
  }, [serviceOrderCategories, formHolder.CategoryId]);

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      {requestLoading && !serviceOrderCategories ? (
        <Spinner />
      ) : (
        <>
          <div className="row margin" id="error_CategoryId">
            <Select
              onChange={updateFormHolder}
              options={
                serviceOrderCategories &&
                serviceOrderCategories.map((type: DeliberateAny) => ({
                  key: type.Id,
                  value: type.Name,
                }))
              }
              value={formHolder.CategoryId}
              loading={requestLoading}
              defaultChoice="Velg kategori"
              name="CategoryId"
              label="Kategori"
              errors={errors}
            />
          </div>
          {serviceOrderCategories && 
            serviceOrderCategories?.find((x) => x.Id === formHolder.CategoryId)
            ?.Settings.ShowCaseType && (
          <div className="row margin" id="error_serviceOrderCaseType">
            <Select
              onChange={updateFormHolder}
              options={caseTypeOptions ?? []}
              value={formHolder.CaseTypeId}
              loading={requestLoading}
              name="CaseTypeId"
              dependentOn="Kategori"
              defaultChoice={
                serviceOrderCategories
                  ? `Velg ${
                      serviceOrderCategories
                        ?.find((x) => x.Id === formHolder.CategoryId)
                        ?.HeaderCaseType?.toLowerCase() ?? "sakstype"
                    }`
                  : ""
              }
              label={
                serviceOrderCategories
                  ? serviceOrderCategories?.find(
                      (x) => x.Id === formHolder.CategoryId
                    )?.HeaderCaseType ?? "Sakstype"
                  : "Sakstype"
              }
              errors={errors}
            />
          </div>)
          }
          {serviceOrderCategories &&
            serviceOrderCategories?.find((x) => x.Id === formHolder.CategoryId)
              ?.Settings.RequiresIONumber && (
              <InputField
                label="Innkjøpsordrenummer"
                id="ioNumber"
                value={formHolder.ioNumber}
                updateField={updateFormHolder}
                errors={errors}
              />
            )}
        </>
      )}
    </>
  );
};

export default CategoryFaultTypeSelect;
