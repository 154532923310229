import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import './documents.scss';
import useAxios from '../../hooks/useAxios';
import { documentsURL } from '../../api/basenproff/basenproff_api';
import { DeliberateAny } from 'types/DelibrateAny';
import Loader from 'components/Loader/Loader';

const Documents = () => {
  const {sendRequest} = useAxios();
  const [documentsData, setDocumentsData] = useState<DeliberateAny>();

  const fetchDocuments = async () => {
    await sendRequest ({
      method: "GET",
      url: documentsURL,
    }, (response) => {
      setDocumentsData(response.SubLevel);
    }).catch((error) => {
      setDocumentsData(error);
    })
  }

  useEffect(() => {
   fetchDocuments();
  }, []);

  const buildList = data => {
    let html = '<ul>';
    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      html += '<li>';
      html += `<strong>${item.Name} </strong> – `;
      html += item.Text;
      if (item.SubLevel) {
        html += buildList(item.SubLevel);
      }
      html += '</li>';
    }
    html += '</ul>';
    return html;
  };

  return (
    <div className="app-root documents">
      <h1>Avtaler og vedlegg</h1>
      <p>På denne siden kan du laste ned dokumenter relatert til bygg, eiendom og forvaltning</p>

      <Loader isLoading={!documentsData}>
        {documentsData &&
          <span
            className="content-group grey padded documents"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(buildList(documentsData)) }}
          />
        }
      </Loader>


    </div>

  );
};

export default Documents;
