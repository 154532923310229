import { Component } from 'react';
import connect from 'helpers/connectWithRouter';
import DOMPurify from 'dompurify';
import { INSPECTION_STATUSES, RESERVED_STATUSES, RENTALSTATUSIMAGE } from 'constants/rentalsConstants';
import { RETURN_CONTRACT_TYPE_STRING, MOVING_INTO_QUARTER } from 'helpers/inspectionHelpers';
import type { RentalItemType, RentalContractTextsType } from 'types/myRentalsTypes';
import LabelValueList from 'components/LabelValueList/LabelValueList';
import { formatDate } from 'helpers/valueHelpers';
import './RentalStatusbarBox.scss';
import { DeliberateAny } from 'types/DelibrateAny';
import ProgressTracker from 'components/ProgressTracker/ProgressTracker';
import DwellingInspectionInfo from './DwellingInspectionInfo';

type PropsType = {
  item: RentalItemType,
  rentalContractTexts: RentalContractTextsType,
};

export class UnconnectedRentalStatusbarBox extends Component {
  declare props: PropsType;

  getActiveInspection = () => this.props.item.Inspections.find(inspection =>
    inspection.InspectionStatus === INSPECTION_STATUSES.REGISTERED
    && inspection.ReservedStatus >= RESERVED_STATUSES.OUR_PROPOSAL);

  getStatusInfoText = (activeStatus: number, contractType: string) => (
    this.props.rentalContractTexts[`Status${activeStatus}InfoText${contractType}`]
  );

  pointStatuses = {
    STARTED: 0,
    NOTSTARTED: -1,
    PLANNING: 6,
    DONE: 5,
    STOPPED: 6,
    CANCELLED: 6,
    POSTPONED: 6,
  };

  render() {
    const activeInspection = this.getActiveInspection();
    const imageRentalStatus = RENTALSTATUSIMAGE(this.props.item.CurrentRentalStatusNameIndex - 1);
    const contractsTypeString = RETURN_CONTRACT_TYPE_STRING(this.props.item.ContractType);
    const movingIntoQuarter = activeInspection ? MOVING_INTO_QUARTER(
      this.props.item.ContractType,
      activeInspection.IsMovingIn,
    ) : false;

    return (
      <div className="rightbox">
        <img style={{ width: '17vw !important' }} src={imageRentalStatus.src} alt={imageRentalStatus.alt} />
        <ProgressTracker
          currentStatus={this.props.item.CurrentRentalStatusNameIndex - 1}
          currentProgress={this.props.item.CurrentRentalStatusNameIndex - 1}
          previousStatus={this.props.item.CurrentRentalStatusNameIndex - 1}
          styleAllSmall
          alternateProgressPoints={
            this.props.item.RentalStatusNames.slice(1, -1)
              .map(item => ({ text: item }))}
          pointStatuses={this.pointStatuses}
        />
        <div className="divider-top">
          <h3
            className="no-margin"
            dangerouslySetInnerHTML={
              { __html: DOMPurify.sanitize(this.props.item.StatusInfoHeading) }
            }
          />
          <div
            className={!this.props.item.IsTerminated && activeInspection ? 'details-divider' : ''}
            dangerouslySetInnerHTML={
              {
                __html: DOMPurify.sanitize(this.getStatusInfoText(
                  this.props.item.CurrentRentalStatusNameIndex, contractsTypeString)),
              }
            }
          />
          {this.props.item.IsTerminated &&
          <div className={activeInspection ? 'details-divider' : ''}>
            <LabelValueList
              items={[
                {
                  label: 'Oppsagt dato',
                  value: formatDate(this.props.item.TerminatedDate),
                },
                {
                  label: 'Ønsket utflyttingsdato',
                  value: formatDate(this.props.item.DesiredMoveDate),
                },
              ]}
            />
          </div>
          }
          {activeInspection &&
          <DwellingInspectionInfo
            rentalItem={this.props.item}
            activeInspection={activeInspection}
            befaringInfoTekst={
              this.props.rentalContractTexts[`BefaringInfoTekst${contractsTypeString}${movingIntoQuarter ?
                'MovingIn' : ''}`]}
          />
          }
        </div>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    rentalContractTexts: state.myRentals.rentalContractTexts,
  }),
  {},
)(UnconnectedRentalStatusbarBox);