import { getMinDate } from "helpers/bookingHelpers";
import moment from "moment";
import { createContext, FC, Fragment, useMemo, useState } from "react";

import { IWrapper } from "../interfaces/IWrapper";
import { DeliberateAny } from "../types/DelibrateAny";

export const BookingContext = createContext({});

export const BookingContextProvider: FC<IWrapper> = ({ children }) => {
  // item
  const [item, setItem] = useState({});

  const [parentBooking, setParentBooking] = useState(false);
  const [useReferenceCode, setUseReferenceCode] = useState(false);
  const [referenceCode, setReferenceCode] = useState("");
  const [numberOfGuests, setNumberOfGuests] = useState(1);
  const [isBlockBooking, setIsBlockBooking] = useState(false);
  const [arrivalDate, setArrivalDate] = useState(getMinDate());
  const [departureDate, setDepartureDate] = useState(
    moment(arrivalDate).add(1, "days")
  );
  const [selectedLocation, setSelectedLocation] = useState<DeliberateAny>();
  const [numberOfDaysToForcePayment, setNumberOfDaysToForcePayment] =
    useState(7);
  const [maxBookingLength, setMaxBookingLength] = useState();
  const [maxBookingLengthBlockBooking, setMaxBookingLengthBlockBooking] =
    useState();
  const [maxBookingLengthToUse, setMaxBookingLengthToUse] = useState();
  const [roomInfo, setRoomInfo] = useState([]);
  const [roomInfoPerRoomType, setRoomInfoPerRoomType] = useState([
    { id: "", count: 0, price: 0, name: "" },
  ]);

  const [markup, setMarkup] = useState(false);

  const clearBookingState = () => {
    setItem({});
    setUseReferenceCode(false);
    setReferenceCode("");
    setParentBooking(false);
    setNumberOfGuests(1);
    setIsBlockBooking(false);
    setArrivalDate(getMinDate());
    setDepartureDate(getMinDate().add(1, "days"));
    setSelectedLocation(undefined);
    setNumberOfDaysToForcePayment(7);
    setRoomInfo([]);
    setRoomInfoPerRoomType([{ id: "", count: 0, price: 0, name: "" }]);
  };

  const values = useMemo(
    () => ({
      item,
      setItem,
      parentBooking,
      setParentBooking,
      useReferenceCode,
      setUseReferenceCode,
      referenceCode,
      setReferenceCode,
      numberOfGuests,
      setNumberOfGuests,
      isBlockBooking,
      setIsBlockBooking,
      arrivalDate,
      setArrivalDate,
      departureDate,
      setDepartureDate,
      selectedLocation,
      setSelectedLocation,
      numberOfDaysToFocePayment: numberOfDaysToForcePayment,
      setNumberOfDaysToFocePayment: setNumberOfDaysToForcePayment,
      maxBookingLength,
      setMaxBookingLength,
      maxBookingLengthBlockBooking,
      setMaxBookingLengthBlockBooking,
      maxBookingLengthToUse,
      setMaxBookingLengthToUse,
      roomInfo,
      setRoomInfo,
      roomInfoPerRoomType,
      setRoomInfoPerRoomType,
      markup,
      setMarkup,
      clearBookingState,
    }),
    [
      item,
      parentBooking,
      useReferenceCode,
      referenceCode,
      numberOfGuests,
      isBlockBooking,
      arrivalDate,
      departureDate,
      selectedLocation,
      numberOfDaysToForcePayment,
      maxBookingLength,
      maxBookingLengthBlockBooking,
      maxBookingLengthToUse,
      roomInfo,
      roomInfoPerRoomType,
      markup,
    ]
  );

  return (
    <BookingContext.Provider value={values}>
      <Fragment>{children}</Fragment>
    </BookingContext.Provider>
  );
};
