/* eslint react/prop-types: 0 */

import ArenaInfo from 'components/ArenaInfo/ArenaInfo';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import ListItem from 'components/ListItem/ListItem';
import ModalWrapper from 'components/modals/ModalWrapper/ModalWrapper';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  AnswerAllocationModal,
  ApplicationSummaryModal,
  InfoModal,
  ResendAsMainApplicantModal,
  WithdrawApplicationModal,
} from './HousingApplicationModals';

const HousingApplicationsItem = ({ columns, item, setRunUseEffect }) => {
  const [withdrawApplicationModalIsOpen, setWithdrawApplicationModalIsOpen] =
    useState(false);
  const [applicationSummaryModalIsOpen, setApplicationSummaryModalIsOpen] =
    useState(false);
  const [answerAllocationModalIsOpen, setAnswerAllocationModalIsOpen] =
    useState(false);
  const [
    resendAsMainApplicantModalIsOpen,
    setResendAsMainApplicantModalIsOpen,
  ] = useState(false);
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);

  const handleInfoModal = (event) => {
    event.preventDefault();
    setInfoModalIsOpen(true);
  };

  const renderDetails = () => (
    <div className="details-wrapper">
      <div className="details-divider">
        <strong>Status på tildelingen</strong>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              unescape(item.StateDescriptionHtml).replace(/'/g, '"'),
            ),
          }}
        />
      </div>

      <div className="details-divider">
        <div>
          <strong>Søknad registrert: </strong>
          <span>{new Date(item.DateApplied).toLocaleDateString('ru')}</span>
        </div>
        <div style={{ marginBottom: '22px' }}>
          <strong>Kontakt boligkontoret: </strong>
          <a
            href={`mailto:${item.HousingSecretaryEmail}?subject=Søknadsnummer: ${item.ApplicationNumber}, Boligrunde: ${item.HousingAreaName} med søknadsfrist ${item.DateApplied}`}
          >
            {item.HousingSecretaryEmail}
          </a>
        </div>
        {item.RequiredDocumentation ? (
          <div>
            <div>
              <strong>Dokumentasjonskrav: </strong>
              <ul style={{ margin: '0 0 22px 22px' }}>
                {item.RequiredDocumentation.replace('-,', '***,***')
                  .split(', ')
                  .map((e) => e.replace('***,***', ',-'))
                  .map((doc) => (
                    <li
                      key={uuidv4()}
                      style={{ listStyleType: 'circle', display: 'list-item' }}
                    >
                      <span>{doc}</span>
                    </li>
                  ))}
              </ul>
            </div>
            <div>
              <span>
                <strong className="red">MERK!</strong>{' '}
                {item.ApplicationTypeName === 'Bolig'
                  ? ' Du mister viktige poeng i vurderingen '
                  : ' Søknaden kan ikke godkjennes '}
                om nødvendige vedlegg ikke er ettersendt innen søknadsfristens
                utløp.
              </span>
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: '22px' }}>
            <strong>Dokumentasjonskrav: </strong>
            <span>Du trenger ikke levere noen vedlegg</span>
          </div>
        )}
      </div>
      {item.CommentsForApplicant && item.CommentsForApplicant.length > 0 && (
        <div className="details-divider">
          <p>
            <strong>Kommentar fra boligsekretær</strong>
            <br />
            {item.CommentsForApplicant}
          </p>
        </div>
      )}
      {item.RejectionReason && item.RejectionReason.length > 0 && (
        <div className="details-divider">
          <p>
            <strong>Årsak til avslag</strong>
            <br />
            {item.RejectionReason}
          </p>
        </div>
      )}
      {item.ArenaId && (
        <div className="details-divider">
          {item.Housing && !item.IsRenewal && (
            <p>
              <Link
                to="Informasjon om din tildelte bolig"
                onClick={(e) => handleInfoModal(e)}
              >
                Informasjon om din tildelte bolig
              </Link>
            </p>
          )}
          <ArenaInfo arenaId={item.ArenaId} arenaName={item.ServiceAreaName} />
        </div>
      )}
      <ModalWrapper>
        {(item.ApplicationState === 'Registrert' ||
          item.ApplicationState === 'Godkjent') && (
          <WithdrawApplicationModal
            id={item.Id}
            applicationType={item.ApplicationType}
            isOpen={withdrawApplicationModalIsOpen}
            setWithdrawApplicationModalIsOpen={
              setWithdrawApplicationModalIsOpen
            }
            setRunUseEffect={setRunUseEffect}
          />
        )}
        {(item.ApplicationState === 'Tildelt bolig' || true) && (
          <AnswerAllocationModal
            isOpen={answerAllocationModalIsOpen}
            setAnswerAllocationModalIsOpen={setAnswerAllocationModalIsOpen}
            itemId={item.Id}
            setRunUseEffect={setRunUseEffect}
          />
        )}
        {item.ApplicationState === 'Trukket av hovedsøker' && (
          <ResendAsMainApplicantModal
            isOpen={resendAsMainApplicantModalIsOpen}
            setResendAsMainApplicantModalOpen={
              setResendAsMainApplicantModalIsOpen
            }
            item={item}
            setRunUseEffect={setRunUseEffect}
          />
        )}
        <ApplicationSummaryModal
          item={item}
          isOpen={applicationSummaryModalIsOpen}
          setApplicationSummaryModalIsOpen={setApplicationSummaryModalIsOpen}
        />
        {item.Housing && !item.IsRenewal && (
          <InfoModal
            isOpen={infoModalIsOpen}
            setInfoModalIsOpen={setInfoModalIsOpen}
            item={item}
          />
        )}
      </ModalWrapper>
      <div style={{ paddingTop: '11px' }}>
        <ButtonRow>
          {(item.ApplicationState === 'Registrert' ||
            item.ApplicationState === 'Godkjent') && (
            <ButtonRow.Right
              key="1"
              onClick={() => setWithdrawApplicationModalIsOpen(true)}
            >
              Trekk søknaden
            </ButtonRow.Right>
          )}

          {item.ApplicationState === 'Tildelt bolig' && (
            <ButtonRow.Right
              key="2"
              color="red"
              onClick={() => setAnswerAllocationModalIsOpen(true)}
            >
              Svar på tildelingen
            </ButtonRow.Right>
          )}

          {item.ApplicationState === 'Trukket av hovedsøker' && (
            <ButtonRow.Right
              key="4"
              color="red"
              onClick={() => setResendAsMainApplicantModalIsOpen(true)}
            >
              Send søknad som hovedsøker
            </ButtonRow.Right>
          )}

          <ButtonRow.Right
            key="3"
            onClick={() => setApplicationSummaryModalIsOpen(true)}
            className="btn grey decoration-popup"
          >
            Se søknad
          </ButtonRow.Right>
        </ButtonRow>
      </div>
    </div>
  );

  return (
    <ListItem
      columns={columns}
      item={item}
      renderDetailsFunction={() => renderDetails()}
      passStylingToList={{ styleInHalf: true }}
    />
  );
};

export default HousingApplicationsItem;
