/* eslint react/prop-types: 0 */

import './TabDashboard.scss';

import { saveWidgetConfigURL } from 'api/cms/cms_api';
import { useModal, useUser } from 'contexts/index';
import FBHelpers from 'helpers/_helpers';
import _ from 'lodash/fp';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';

import { axiosFetch } from '../../../hooks/useAxios';
import WidgetDeadlines from './Widgets/WidgetDeadlines';
import WidgetKvartalet from './Widgets/WidgetKvartalet';
import WidgetServiceOrder from './Widgets/WidgetServiceOrder';
import WidgetWasteRecycling from './Widgets/WidgetWasteRecycling';

const Dashboard = () => {
  const navigate = useNavigate();
  const { showModal } = useModal();
  const { user } = useUser();
  const [userWidgets, setUserwidgets] = useState();

  const getWidgetConfigs = () => {
    if (userWidgets === null || userWidgets === undefined) {
      setUserwidgets(JSON.parse(user.WidgetConfigs));
    }
    const widgetConfigsArray = JSON.parse(user.WidgetConfigs);
    return widgetConfigsArray.map((t) => JSON.parse(t.AsJson));
  };

  const [showWidgetLibrary, setShowWidgetLibrary] = useState(false);
  const [widgets, setWidgets] = useState(
    getWidgetConfigs().map((widget, index) => ({ ...widget, Id: index }))
  );

  const sortWidgets = (widgetList) =>
    widgetList.sort((a, b) => a.SortOrder > b.SortOrder);

  const setSortOrder = (list) =>
    sortWidgets(list.map((item, index) => ({ ...item, SortOrder: index })));

  const saveWidgetConfigs = async (widgetConfigs) => {
    setUserwidgets(widgetConfigs);

    const obj = widgetConfigs.map((item) => {
      const clonedConfig = _.cloneDeep(item);
      return {
        WidgetType: clonedConfig.WidgetType,
        AsJson: JSON.stringify({ ...clonedConfig }),
      };
    });

    await axiosFetch({
      url: saveWidgetConfigURL,
      method: "POST",
      data: obj,
    });
  };

  const userHasAccessToBasenProff = () =>
    user !== null && user.ClientSquareAccess === "True";
  const [userNotified, setUserNotified] = useState(false);
  const notifyUserAboutBasenProffAccess = () => {
    if (userNotified === false) {
      setUserNotified(true);
      return FBHelpers.notifyUserOfBasenProffAccess(showModal);
    }
    return null;
  };

  const addWidgetFromLibrary = (type) => {
    setShowWidgetLibrary(false);
    const widgetConfig = { WidgetType: type, Id: widgets.length };
    const list = [...widgets];
    list.unshift(widgetConfig);
    const sortedList = setSortOrder(list);
    setWidgets(sortedList);
    saveWidgetConfigs(sortedList);
  };

  const deleteModule = (id) => {
    const newList = widgets.filter((item) => item.Id !== id);
    const sortedList = setSortOrder(newList);
    setWidgets(sortedList);
    saveWidgetConfigs(sortedList);
  };

  const saveModule = (id, data) => {
    const newWidgetList = widgets.map((item) => {
      if (data && item.Id === id) {
        const updatedItem = {
          ...item,
          ...data,
        };
        return updatedItem;
      }
      return item;
    });
    const sortedList = setSortOrder(newWidgetList);
    setWidgets(sortedList);
    saveWidgetConfigs(sortedList);
  };

  const renderWidget = (widget) => {
    if (widget.WidgetType === "ServiceOrder") {
      return (
        <WidgetServiceOrder
          marketingArea={widget.Region || widget.MarketingArea}
          establishmentNumber={widget.EstablishmentNumber}
          key={widget.Id}
          id={widget.Id}
          saveModule={saveModule}
          deleteModule={deleteModule}
        />
      );
    } else if (widget.WidgetType === "Deadlines") {
      return (
        <WidgetDeadlines
          key={widget.Id}
          saveModule={saveModule}
          deleteModule={deleteModule}
          id={widget.Id}
        />
      );
    } else if (widget.WidgetType === "WasteRecycling") {
      return (
        <WidgetWasteRecycling
          marketingArea={widget.Region || widget.MarketingArea}
          establishmentNumber={widget.EstablishmentNumber}
          key={widget.Id}
          saveModule={saveModule}
          deleteModule={deleteModule}
          id={widget.Id}
        />
      );
    }
    return (
      <WidgetKvartalet
        key={widget.Id}
        saveModule={saveModule}
        deleteModule={deleteModule}
        id={widget.Id}
      />
    );
  };

  const renderWidgetLibrary = () => (
    <div
      className={
        "widget-library " + (showWidgetLibrary ? "showWidgetLibrary" : "")
      }
    >
      <div className="library-widgets">
        <div
          className="widget"
          onClick={() => addWidgetFromLibrary("ServiceOrder")}
        >
          <div className="widget-wrapper">
            <div className="widget-inner-wrapper">
              <div className="header-wrapper">
                <header>
                  <h3>Henvendelse</h3>
                </header>
              </div>
            </div>
          </div>
        </div>
        <div
          className="widget"
          onClick={() => addWidgetFromLibrary("Deadlines")}
        >
          <div className="widget-wrapper">
            <div className="widget-inner-wrapper">
              <div className="header-wrapper">
                <header>
                  <h3>Frister</h3>
                </header>
              </div>
            </div>
          </div>
        </div>
        <div
          className="widget"
          onClick={() => addWidgetFromLibrary("WasteRecycling")}
        >
          <div className="widget-wrapper">
            <div className="widget-inner-wrapper">
              <div className="header-wrapper">
                <header>
                  <h3>Sorteringsgrad avfall</h3>
                </header>
              </div>
            </div>
          </div>
        </div>
        <div
          className="widget"
          onClick={() => addWidgetFromLibrary("Kvartalet")}
        >
          <div className="widget-wrapper">
            <div className="widget-inner-wrapper">
              <div className="header-wrapper">
                <header>
                  <h3>Kvartalet</h3>
                </header>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (!userHasAccessToBasenProff()) {
    notifyUserAboutBasenProffAccess();
    navigate("/");
  }

  return (
    <div
      className="tab-panel max-width-large tab-dashboard"
      style={{ display: "block" }}
    >
      <div className="widget-button-wrapper buttonWrapper">
        <button
          className={`red btn plus${showWidgetLibrary ? " expanded" : ""}`}
          onClick={() => setShowWidgetLibrary(!showWidgetLibrary)}
        >
          <span />
        </button>
      </div>
      <div className="widgets-wrapper">
        {renderWidgetLibrary()}
        <ReactSortable
          list={widgets}
          setList={setWidgets}
          className={`widgets widgetsDiv ${
            showWidgetLibrary ? "showLibrary" : "noShowLibrary"
          }`}
        >
          {widgets.map((widget) => renderWidget(widget))}
        </ReactSortable>
      </div>
    </div>
  );
};

export default Dashboard;
