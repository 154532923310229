import './ServiceOrderColumns.scss';

import { DeliberateAny } from 'types/DelibrateAny';

const ServiceOrderColumns: DeliberateAny[] = [
  {
    label: 'Henvendelse',
    name: 'Title',
    className: 'title',
    renderFunction: (item) => (
      <div style={{ display: 'flex' }}>
        {item.Complaints && item.Complaints.length > 0 && (
          <div
            className="extra-info-indicator"
            style={{
              border: '1px solid #e6e6e6',
              textAlign: 'center',
              marginRight: '25px',
              marginLeft: '-10px',
              backgroundColor: '#ededed',
              width: '44px',
              lineHeight: '44px',
              color: '#c1002b',
            }}
          >
            {item.Complaints.length}
          </div>
        )}
        <div>
          {item.Title}{' '}
          <div className={'subTitle'} style={{ color: 'black' }}>
            {new Date(item.CreatedDate).toLocaleDateString('ru', {
              day: 'numeric',
              month: 'numeric',
              year: '2-digit',
            })}{' '}
            | {item.Reporter}
          </div>
        </div>
      </div>
    ),
  },

  {
    label: 'SO-nummer',
    name: 'ServiceOrderNumber',
    className: 'serviceOrderNumber',
  },
  {
    label: 'Status',
    name: 'ServiceOrderStatePrintable',
    className: 'serviceOrderState',
  },
];

export default ServiceOrderColumns;
