export const ModalTypes = {
  CustomForm: 'custom-form',
  SearchableList: 'searchable-list',
  Share: 'share-para',
  MapPicker: 'map-picker',
  ExportList: 'export-list',
  GetModal: 'get',
};

export default { ModalTypes };
