import { DeliberateAny } from "../../../types/DelibrateAny";
import Checkbox from "../Checkbox/Checkbox";

type PropsType = {
  checked: boolean;
  onChange?: (name: string, value: boolean) => void;
  errors?: DeliberateAny;
};

const SubcontractorConfirmation = (props: PropsType) => {
  const description = (
    <p>
      <br />
      Dersom saken gjelder boligen eller kvarteret du bor i er det fint om du
      samtykker i at Forsvarsbygg eller en av våre underleverandører kan låse
      seg inn dersom du ikke er hjemme på det aktuelle tidspunktet. Har du hund
      må dette varsles til drift.
    </p>
  );

  return (
    <div className="content-group grey padded">
      <Checkbox
        {...props}
        type="checkbox"
        name="consentSubcontractor"
        label="Jeg bekrefter at Forsvarsbygg eller deres underleverandører kan låse seg inn dersom jeg ikke er hjemme."
        description={description}
      />
    </div>
  );
};
export default SubcontractorConfirmation;
