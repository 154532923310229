import "./DetailsCard.scss";

import DetailsButton from "components/ListItem/DetailsButton/DetailsButton";
import React, { Component } from "react";
import { UnmountClosed } from "react-collapse";
import { DeliberateAny } from "types/DelibrateAny";

export type DetailsCardType = {
  header: string;
  description?: string;
  iconSrc?: string;
  iconAlt?: string;
  expandable?: boolean;
  details: React.ReactNode | Array<React.ReactNode>;
  extraStyling?: string;
  svg?: DeliberateAny;
};

class DetailsCard extends Component {
  declare props: DetailsCardType;

  state: {
    showDetails: boolean;
  };

  constructor(props: DetailsCardType) {
    super(props);
    this.state = {
      showDetails: !props.expandable,
    };
  }

  toggleDetails = () => {
    this.setState({
      showDetails: !this.state.showDetails,
    });
  };

  render() {
    return (
      <div className={`details-card ${this.props.extraStyling || ""}`}>
        <div className="wrapper">
          {this.props.iconSrc && (
            <div className="icon details-icon">
              {this.props.svg ? (
                this.props.svg
              ) : (
                <img src={this.props.iconSrc} alt={this.props.iconAlt} />
              )}
            </div>
          )}
          <div className="text">
            {this.props.extraStyling &&
            this.props.extraStyling === "less-margin" ? (
              <h4 className="margin">{this.props.header}</h4>
            ) : (
              <h3 className="margin">{this.props.header}</h3>
            )}
            <p>{this.props.description}</p>
          </div>

          {this.props.expandable && (
            <DetailsButton
              margin
              onClick={this.toggleDetails}
              expanded={this.state.showDetails}
            />
          )}
        </div>

        {this.props.expandable && (
          <UnmountClosed isOpened={this.state.showDetails}>
            <div className="info-container">{this.props.details}</div>
          </UnmountClosed>
        )}

        {!this.props.expandable && this.state.showDetails && (
          <div className="info-container">{this.props.details}</div>
        )}
      </div>
    );
  }
}

export default DetailsCard;
