// @flow

import { call, put } from "redux-saga/effects";
import { uiAddFavoriteURL, uiRemoveFavoriteURL } from "../api/core/core_api";
import { axiosFetch } from "../hooks/useAxios";

function* handleTogglePlanFavorite(action) {
  const query = {
    ItemId: action.item.Id,
    IsFavorite: !action.item.UserExtra.IsFavorite,
  };
  const url = query.IsFavorite ? uiAddFavoriteURL : uiRemoveFavoriteURL;

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({ type: "RECEIVE_TOGGLE_PLAN_FAVORITE", item: action.item });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

export default handleTogglePlanFavorite;
