import "./Portfolio.scss";

import {
  addPortfolioFavorite,
  removePortfolioFavorite,
} from "actions/myPortfolioActions";
import ListItem from "components/ListItem/ListItem";
import connect from "helpers/connectWithRouter";
import { useState } from "react";
import { IoIosHourglass } from "react-icons/io";
import { useLocation, useSearchParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";

import type { DetailsButtonType, ListColumnType } from "types/listTypes";
import type { PortfolioItemType } from "types/myPortfolioTypes";
type PropsType = {
  item: PortfolioItemType;
  addPortfolioFavorite: (item: PortfolioItemType) => void;
  removePortfolioFavorite: (item: PortfolioItemType) => void;
  customDetailsButton?: DetailsButtonType;
  columns: Array<ListColumnType>;
};

const buildingDetails = [
  { label: "Distrikt", name: "RegionName" },
  { label: "KNGR/GNR/BNR/SNR", name: "KnrGnrBnrSnr" },
  { label: "Byggeår", name: "BuiltYear" },
];

const UnconnectedPortfolioItem = (props: PropsType) => {
  const [loading, setLoading] = useState(false);
  const removePortfolioFavorite = () => {
    setLoading(true);
    props.removePortfolioFavorite(props.item);
  };

  const addPortfolioFavorite = () => {
    setLoading(true);
    props.addPortfolioFavorite(props.item);
  };

  useUpdateEffect(() => {
    setLoading(false);
  }, [props.item.IsFavorite]);
  return (
    <ListItem
      item={props.item}
      columns={props.columns}
      details={buildingDetails}
      actionButton={{
        className: "portfolio-btn",
        onClick: props.item.IsFavorite
          ? removePortfolioFavorite
          : addPortfolioFavorite,
        title: loading ? (
          <IoIosHourglass style={{ marginTop: 10 }} />
        ) : props.item.IsFavorite ? (
          "Fjern"
        ) : (
          "Legg til"
        ),
        color: props.item.IsFavorite ? "grey" : "red",
      }}
      customDetailsButton={props.customDetailsButton}
    />
  );
};

export default connect(null, {
  addPortfolioFavorite,
  removePortfolioFavorite,
})(UnconnectedPortfolioItem);
