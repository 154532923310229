import Button from 'components/buttons/Button/Button';
import ChangeSearchModal from 'components/ModalComponents/ChangeSearchModal';
import { useNewBooking } from 'contexts/index';
import moment from 'moment';
import { FC, useState } from 'react';
import { shortMonths } from 'utils/date';

interface IBookingSearchProps {
  nav?: (step: number) => void;
}

const BookingSearch: FC<IBookingSearchProps> = ({ nav }) => {
  const { bookingSearch, location, splitValues } = useNewBooking();
  const [showChangeSearchModal, setShowChangeSearchModal] =
    useState<boolean>(false);

  return (
    <div className="fb-form fb-booking-search">
      <div className="fb-search-left">
        <div className="fb-booking-search-location">{location?.heading}</div>
        {bookingSearch.arriveDeparture &&
          bookingSearch.arriveDeparture.length === 2 && (
            <div className="fb-booking-search-dates">
              {`${bookingSearch.arriveDeparture[0].format('DD')} ${shortMonths[bookingSearch.arriveDeparture[0].month.index]} - ${bookingSearch.arriveDeparture[1].format('DD')} ${shortMonths[bookingSearch.arriveDeparture[1].month.index]} (${moment(
                bookingSearch.arriveDeparture?.[1]?.toDate(),
              ).diff(
                moment(bookingSearch.arriveDeparture?.[0]?.toDate()),
                'days',
              )} netter)`}

              <span style={{ width: 24, display: 'inline-block' }} />

              {`${bookingSearch.guestCount} gjest${(bookingSearch.guestCount ?? 0) > 1 ? 'er' : ''}, ${splitValues?.rooms ?? 0} rom`}
            </div>
          )}
      </div>
      <div className="fb-search-right">
        <Button
          className="tertiary"
          onClick={() => setShowChangeSearchModal(true)}
        >
          Endre søk
        </Button>
      </div>
      {showChangeSearchModal && (
        <ChangeSearchModal
          onClose={() => setShowChangeSearchModal(false)}
          onSubmit={() => {
            nav?.(1);
            setShowChangeSearchModal(false);
          }}
        />
      )}
    </div>
  );
};

export default BookingSearch;
