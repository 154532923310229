// @flow

import './DocumentForSigningItem.scss';

import { createSignatureJobForXpandDocument } from 'actions/signatureActions';
import { proffBaseUrl } from 'api/basenproff/basenproff_api';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import DownloadLink from 'components/DownloadLink/DownloadLink';
import RejectSigningModal from 'components/modals/RejectSigningModal/RejectSigningModal';
import SigningModal from 'components/modals/SigningModal/SigningModal';
import {
  SIGNATURE_STATE_ERROR,
  SIGNATURE_STATE_REJECTION,
  SIGNATURE_STATE_SUCCESS,
} from 'constants/signatureConstants';
import connect from 'helpers/connectWithRouter';
import queryString from 'query-string';
import React, { Component } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropNavigation } from 'types/PropNavigationBase';
import { SignatureJobXpandRequestType } from 'types/signatureTypes';

import type { RentalDocumentType } from 'types/myRentalsTypes';
const RENTAL_CONTRACT_SIGNATURE_CHANNEL = 'my-page:sign-rentalcontract';

type PropsType = PropNavigation & {
  document: RentalDocumentType;
  rentalId: string;
  createSignatureJobForXpandDocument: (
    jobRequest: SignatureJobXpandRequestType,
  ) => string;
  match: DeliberateAny;
  history: DeliberateAny;
};

export class UnconnectedDocumentForSigningItem extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    // OBS: FB.mediator
    // FB.mediator.subscribe(this.getChannelName(), this.createSignatureJob);
  }

  getChannelName = () =>
    `${RENTAL_CONTRACT_SIGNATURE_CHANNEL}${this.props.document.DocumentId}`;

  createSignatureJob = ssn => {
    const createUrl = signingState =>
      `#/my-page/tab-my-rentals?${queryString.stringify({
        rentalId: this.props.rentalId,
        documentId: this.props.document.DocumentId,
        signingState,
      })}`;
    const completionUrl = createUrl(SIGNATURE_STATE_SUCCESS);
    const errorUrl = createUrl(SIGNATURE_STATE_ERROR);
    const rejectionUrl = createUrl(SIGNATURE_STATE_REJECTION);

    this.props.createSignatureJobForXpandDocument({
      identityNumber: ssn,
      completionUrl,
      rejectionUrl,
      errorUrl,
      rentalId: this.props.rentalId,
      documentId: this.props.document.DocumentId,
      fileName: this.props.document.FileName,
      revision: this.props.document.Revision,
    });
  };

  acceptSigning = ssn => {
    this.createSignatureJob(ssn);
  };

  acceptRentalContractModalPath = () => {
    return `${this.props.location.pathname}/${this.props.document.DocumentId}/signer`;
  };

  rejectRentalContractModalPath = () => {
    return `${this.props.location.pathname}/${this.props.document.DocumentId}/avslå-tilbudet`;
  };

  AcceptRentalContractModal = () => (
    <SigningModal
      title="Start elektronisk signering"
      ariaLabel="Start elektronisk signering"
      path={this.acceptRentalContractModalPath()}
      documentId={this.props.document.DocumentId}
      acceptSigning={this.acceptSigning}
      description={''}
    />
  );

  RejectRentalContractModal = () => (
    <RejectSigningModal
      title="Avslå tilbudet"
      ariaLabel="Avslå tilbudet"
      description="Du vil nå avslå signering."
      path={this.rejectRentalContractModalPath()}
      documentId={this.props.document.DocumentId}
    />
  );
  handleOpenAcceptRentalContractModal = () => {
    this.props.navigate(this.acceptRentalContractModalPath());
  };
  handleOpenRejectRentalContractModal = () => {
    this.props.navigate(this.rejectRentalContractModalPath());
  };

  render() {
    return (
      <div className="document-for-signing-item">
        <div className="pdf-text">
          <p>
            <strong>Dokument til signering</strong>
          </p>
          <p>
            <DownloadLink href={`${proffBaseUrl}/${this.props.document.Path}`}>
              {this.props.document.DisplayName}
            </DownloadLink>
          </p>
        </div>
        <ButtonRow>
          <ButtonRow.Left
            color="red"
            onClick={this.handleOpenAcceptRentalContractModal}
          >
            Start signering
          </ButtonRow.Left>
          <ButtonRow.Left onClick={this.handleOpenRejectRentalContractModal}>
            Avslå tilbudet
          </ButtonRow.Left>
        </ButtonRow>
        <this.RejectRentalContractModal />
        <this.AcceptRentalContractModal />
      </div>
    );
  }
}

export default connect(null, {
  createSignatureJobForXpandDocument,
})(UnconnectedDocumentForSigningItem);
