// @flow

import React, { Component } from 'react';
import moment from 'moment';
import connect from 'helpers/connectWithRouter';
import { sendServiceOrder } from 'actions/serviceOrdersActions';
import { PROPERTY_SERVICE_ORDER_TYPE } from 'helpers/serviceOrderHelpers';
import type { SendServiceOrderType } from 'types/serviceOrderTypes';
import { fetchHousingInfoText } from 'actions/housingActions';
import type { InfoTextType } from 'types/housingTypes';
import type { RentalItemType } from 'types/myRentalsTypes';
import { upperCaseFirstLetter } from 'helpers/valueHelpers';
import SendServiceOrderModal from '../SendServiceOrderModal/SendServiceOrderModal';
import { DeliberateAny } from 'types/DelibrateAny';
import { registerRentalCancellationServiceOrder } from 'api/basenproff/basenproff_api';

type PropsType = {
  path: string,
  item: RentalItemType,
  serviceOrder: SendServiceOrderType,
  sendServiceOrder: (serviceOrder: SendServiceOrderType) => void,
  fetchHousingInfoText: () => void,
  housingInfoTextLoaded: boolean,
  housingInfoText: InfoTextType,
  contractType: string,
};

const fromLabel = 'Ønsket utflyttingsdato';
const descriptionLabel = 'Kommentar';
const selectorLabel = 'oppsigelsesårsak';

const addDescription = (serviceOrder: SendServiceOrderType) => serviceOrder.selector === 'Annet';

const editDescription = (serviceOrder?: SendServiceOrderType) => {
  if (serviceOrder) {
    const date = serviceOrder.resignationDate ?
      serviceOrder.resignationDate.format('DD.MM.YYYY') : '';

    const comment = addDescription(serviceOrder) ?
      `${descriptionLabel}: ${serviceOrder.description ? serviceOrder.description : ''}` : '';

    const reasonLabel = upperCaseFirstLetter(selectorLabel);
    const reason = serviceOrder.selector ? `${reasonLabel}: ${serviceOrder.selector}` : '';

    return `Oppsigelse meldt fra basen\n ${fromLabel} ${date}\n ${reason}\n ${comment}`;
  }

  return '';
};

const preSendServiceOrder = (props: PropsType) => {
  if(props?.serviceOrder)
    props.serviceOrder.description = editDescription(props.serviceOrder);
}

export class UnconnectedRentalTerminationModal extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    this.props.fetchHousingInfoText();
  }

  render() {
    return (
      <SendServiceOrderModal
        title="Si opp kontrakt"
        description={this.props.housingInfoText[`${this.props.contractType}ContractTerminationHtml`]}
        ariaLabel="Oppsigelse av kontrakt"
        fields={{
          title: { value: `Oppsigelse av ${this.props.item.RentalId}`, disabled: true },
          rentalId: { value: this.props.item.RentalId, disabled: true },
          housingArea: {value: this.props.item.HousingArea},
          description: addDescription(this.props.serviceOrder) ?
            { label: descriptionLabel, required: false } : false,
          serviceOrderType: { value: PROPERTY_SERVICE_ORDER_TYPE },
          resignationDate: { value: moment(), disableWeekends: true, label: fromLabel },
          selector: {
            label: `Velg ${selectorLabel}`,
            options: this.props.housingInfoText.ContractTerminationReasons ?
              this.props.housingInfoText.ContractTerminationReasons.map(reason => ({
                key: reason,
                value: reason,
              })) : [],
          },
          keyCmObj: { value: this.props.item.KeyCmObj },
          regionNumber: { value: this.props.item.RegionNumber },
          isCancellation: { value: true },
        }}
        preSendServiceOrder={() => preSendServiceOrder(this.props)}
        url={registerRentalCancellationServiceOrder}
        loading={!this.props.housingInfoTextLoaded}
        {...this.props}
      />
    );
  }
}


export default connect(
  (state: DeliberateAny) => ({
    serviceOrder: state.serviceOrders.serviceOrder,
    housingInfoTextLoaded: state.housing.housingInfoTextLoaded,
    housingInfoText: state.housing.housingInfoText,
  }),
  { sendServiceOrder, fetchHousingInfoText },
)(UnconnectedRentalTerminationModal);
