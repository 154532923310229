import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { errorTypesURL } from "../../api/basenproff/basenproff_api";
import useAxios from "../../hooks/useAxios";
import { DeliberateAny } from "../../types/DelibrateAny";
import Select from "../form/Select/Select";

const FaultTypeSelect = ({
  formHolder,
  updateFormHolder,
  setErrorMessageTypes: _setErrorMessageTypes,
}: DeliberateAny) => {
  const { sendRequest, requestLoading } = useAxios();
  const [errorMessageTypes, setErrorMessageTypes] = useState<DeliberateAny[]>(
    []
  );

  const fetchErrorMessageTypes = async () => {
    await sendRequest(
      {
        method: "GET",
        url: errorTypesURL,
      },
      (r: DeliberateAny) => {
        setErrorMessageTypes(r);
        _setErrorMessageTypes(r);
        if (r[0]) {
          updateFormHolder("serviceOrderType", r[0].ServiceOrderType);
          updateFormHolder("serviceOrderErrorTypeId", r[0].Id);
        }
      }
    );
  };

  // Updates form holder with values for selected error type.
  const setSelectedElement = (id: string | number) => {
    const selectedType: DeliberateAny = errorMessageTypes.find(
      (el: DeliberateAny) => el.Id === id
    );
    if (selectedType) {
      updateFormHolder("serviceOrderType", selectedType.ServiceOrderType);
      updateFormHolder("serviceOrderErrorTypeId", selectedType.Id);
    }
  };

  useEffect(() => {
    fetchErrorMessageTypes();
  }, []);

  useEffect(() => {
    if (!requestLoading) {
      setSelectedElement(formHolder.serviceOrderErrorTypeId); // Initially select item specified by formHolder.serviceOrderErrorTypeId when request finishes.
    }
  }, [requestLoading]);

  if (!requestLoading && (!errorMessageTypes || errorMessageTypes.length === 0))
    return null;

  return (
    <div className="row margin" id="error_serviceOrderErrorTypeId">
      <Select
        onChange={(_formname, value) => {
          setSelectedElement(value);
        }}
        options={errorMessageTypes.map((type: DeliberateAny) => ({
          key: type.Id,
          value: type.Label,
        }))}
        value={formHolder.serviceOrderErrorTypeId}
        loading={requestLoading}
        name="serviceOrderErrorTypeId"
        label="Feilmeldingstype"
      />
    </div>
  );
};

FaultTypeSelect.propTypes = {
  formHolder: PropTypes.object,
  updateFormHolder: PropTypes.func,
  setErrorMessageTypes: PropTypes.func,
};

export default FaultTypeSelect;
