import * as I from 'components/BasenIcon';
import Button from 'components/buttons/Button/Button';
import { CountAction } from 'components/Counter/Counter';
import DetailsDivider from 'components/DetailsDivider/DetailsDivider';
import ExpandSection from 'components/ExpandSection/ExpandSection';
import PhoneInput from 'components/FormComponents/PhoneInput';
import TextInput from 'components/FormComponents/TextInput';
import { useNewBooking } from 'contexts/index';
import React, { FC, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { RoomFormItemType } from 'types/BookingTypes/roomTypes';

export interface IGuestListItemProps {
  room: RoomFormItemType;
}

const RoomListItem: FC<IGuestListItemProps> = ({ room }) => {
  const {
    rooms,
    setRooms,
    errors,
    roomCounts,
    setRoomCounts,
    roomInfo,
    setBookingSearch,
  } = useNewBooking();

  const [localGuestData, setLocalGuestData] = useState(room.guests);
  const [roomIndex, setRoomIndex] = useState<number>(0);

  useEffect(() => {
    const index = rooms?.findIndex((roomItem) => roomItem === room);
    if (index) setRoomIndex(index);
  }, [room]);

  useEffect(() => {
    setLocalGuestData(room.guests);
  }, [room.guests]);

  const updateGuestCount = (action: CountAction, count: number = 1) => {
    setBookingSearch((prev) => ({
      ...prev,
      guestCount:
        action === CountAction.INCREMENT
          ? prev.guestCount + count
          : Math.max(prev.guestCount - count, 0), // Ensure guestCount does not go below 0
    }));
  };

  const updateRoomCount = (
    action: CountAction,
    roomTypeId: string,
    count: number = 1,
  ) => {
    setRoomCounts((prev) => ({
      ...prev,
      [roomTypeId]:
        action === CountAction.INCREMENT
          ? prev[roomTypeId] + count
          : Math.max(prev[roomTypeId] - count, 0), // Ensure guestCount does not go below 0
    }));
  };

  const handleRemoveGuest = (guestIndex: number) => {
    const newGuestList = localGuestData.filter((_, i) => i !== guestIndex);
    setLocalGuestData(newGuestList);
    updateGuestCount(CountAction.DECREMENT);
  };

  const handleAddGuest = () => {
    const newGuest = { fullName: '', mobile: '', additionalInfo: '' };
    const newGuestList = [...localGuestData, newGuest];
    setLocalGuestData(newGuestList);
    updateGuestCount(CountAction.INCREMENT);
  };

  const handleDeleteRoom = () => {
    if (rooms) {
      const newRoomsList = rooms.filter((roomItem) => roomItem !== room);
      setRooms(newRoomsList);
      updateRoomCount(CountAction.DECREMENT, room.roomTypeId);
      updateGuestCount(CountAction.DECREMENT, room.guests.length ?? 0);
    }
  };

  const updateGuestInfo = (
    field: string,
    value: string,
    guestIndex: number,
  ) => {
    const updatedGuests = localGuestData.map((guest, index) =>
      index === guestIndex ? { ...guest, [field]: value } : guest,
    );
    setLocalGuestData(updatedGuests);
  };

  useDebounce(
    () => {
      setRooms((prevRooms) => {
        const updatedRooms = [...(prevRooms ?? [])];
        updatedRooms[roomIndex] = {
          ...updatedRooms[roomIndex],
          guests: localGuestData,
        };
        return updatedRooms;
      });
    },
    500,
    [localGuestData],
  );

  return (
    <ExpandSection
      header={`Rom ${roomIndex + 1}`}
      description={`(${room.heading})`}
      errorMessage={room.guests.length === 0 ? 'Tomt rom' : ''}
      openOnDefault={true}
      content={
        <React.Fragment>
          {localGuestData.map((guest, guestIndex) => {
            return (
              <React.Fragment key={`room_${roomIndex + 1}_guest_${guestIndex}`}>
                <div className="guest-info">
                  {guestIndex > 0 && (
                    <div>
                      <DetailsDivider />
                      <div className="guest-list-button-group">
                        <div className="guest-header">Gjest</div>
                        <Button
                          icon={I.MinusIcon}
                          className="tertiary"
                          onClick={() => handleRemoveGuest(guestIndex)}
                        >
                          Fjern gjest
                        </Button>
                      </div>
                    </div>
                  )}
                  <div className="guest-input-group">
                    <TextInput
                      name={'fullName'}
                      label="Fullt navn"
                      initial={guest.fullName}
                      formHolder={{ fullName: guest.fullName }}
                      updateForm={(id, value) => {
                        updateGuestInfo(id, value, guestIndex);
                      }}
                      errors={{
                        fullName:
                          errors[
                            `rooms[${roomIndex}].guests[${guestIndex}].fullName`
                          ],
                      }}
                      required
                    />
                    <PhoneInput
                      name={`mobile`}
                      label="Mobilnummer"
                      initial={guest.mobile ?? guest.mobile}
                      updateForm={(id, value) => {
                        updateGuestInfo(id, value, guestIndex);
                      }}
                      errors={{
                        mobile:
                          errors[
                            `rooms[${roomIndex}].guests[${guestIndex}].mobile`
                          ],
                      }}
                      formHolder={{ mobile: guest.mobile }}
                      required
                    />
                  </div>
                  <TextInput
                    name={'additionalInfo'}
                    label="Spesielle behov/ønsker (valgfritt)"
                    initial={guest.additionalInfo}
                    formHolder={{ additionalInfo: guest.additionalInfo }}
                    updateForm={(id, value) => {
                      updateGuestInfo(id, value, guestIndex);
                    }}
                    errors={{
                      additionalInfo:
                        errors[
                          `rooms[${roomIndex}].guests[${guestIndex}].additionalInfo`
                        ],
                    }}
                  />
                </div>
              </React.Fragment>
            );
          })}
          <div className="guest-list-button-group">
            <Button
              icon={I.DeleteIcon}
              className="tertiary"
              onClick={handleDeleteRoom}
            >
              Slett rom
            </Button>
            {room.guests.length < room.maxCapacity && (
              <Button
                icon={I.PlusIcon}
                className="tertiary"
                onClick={handleAddGuest}
              >
                Legg til gjest ({room.price},-)
              </Button>
            )}
          </div>
        </React.Fragment>
      }
    />
  );
};

export default RoomListItem;
