import { FC } from 'react';
import ComboSelectBox from 'components/FormComponents/ComboSelectBox';
import { SetState } from 'types/DelibrateAny';
import TextInput from 'components/FormComponents/TextInput';
import { ValueOption } from 'components/FormComponents/FormPropBase';
import { BookingOptions } from 'contexts/BookingContextNew';

export interface IIoDepartment {
  department: string;
  ioNumber: string;
}

interface IConfirmWithIoFormProps {
  bookingOptions: BookingOptions;
  ioDepartment: IIoDepartment;
  setIoDepartment: SetState<IIoDepartment>;
  bookingError: any;
}

const ConfirmWithIoForm: FC<IConfirmWithIoFormProps> = ({
  bookingOptions,
  ioDepartment,
  setIoDepartment,
  bookingError,
}) => {
  const updatePaymentInfo = (id: string, value: string) => {
    setIoDepartment((prevForm) => ({
      ...prevForm,
      [id]: value,
    }));
  };

  const mapToIoOptions = () => {
    return (
      bookingOptions.ioDepartments?.map((io) => {
        return { label: io.name, value: io.number.toString() } as ValueOption;
      }) ?? []
    );
  };

  return (
    <div className="fb-form">
      <ComboSelectBox
        name={'department'}
        label={'Velg avdeling'}
        formHolder={ioDepartment}
        updateForm={(id, value) => updatePaymentInfo(id, value)}
        errors={bookingError}
        options={mapToIoOptions()}
        required
      />
      <TextInput
        name={'ioNumber'}
        label={'IO Nummer'}
        formHolder={ioDepartment}
        updateForm={(id, value) => updatePaymentInfo(id, value)}
        errors={bookingError}
        maxLength={10}
        required
      />
    </div>
  );
};

export default ConfirmWithIoForm;
