import './HousingAdmin.scss';

import { fetchHousingAdminServiceAreas } from 'actions/housingAdminActions';
import { fetchHousingDataOptions } from 'actions/optionsActions';
import CogButton from 'components/buttons/CogButton/CogButton';
import DetailsCards from 'components/DetailsCards/DetailsCards';
import Link from 'components/Link/Link';
import Loader from 'components/Loader/Loader';
import SettingsModal from 'components/modals/SettingsModal/SettingsModal';
import { useSlides } from 'contexts/index';
import connect from 'helpers/connectWithRouter';
import {
    filterFavoriteAreasByType, HOUSING_REFERENCE_TYPE, QUARTER_REFERENCE_TYPE
} from 'helpers/housingAdminHelpers';
import housingIcon from 'images/FB_bolig.svg';
import quarterIcon from 'images/FB_kvarter.svg';
// @flow
import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropSlideNavigation } from 'types/PropNavigationBase';
import { getSiblingPath } from 'utils/helpers';

import type { HousingAreaType } from "types/housingAdminTypes";
type PropsType = PropSlideNavigation & {
  housingAreas: Array<HousingAreaType>;
  loadingHousingAreas: boolean;
  loadingApplicationsCount: boolean;
  fetchHousingAdminServiceAreas: () => void;
  fetchHousingDataOptions: () => void;
};

type HousingAreaDetailsPropsType = {
  areas: Array<HousingAreaType>;
  processApplicationsPath: string;
  applicationsCountField: string;
  applicationsLabel: string;
  goToSlide: (path: string, restart?: boolean) => void;
};

function insertZerowidthSpaceAfterSlash(areaName) {
  return (
    <strong
      dangerouslySetInnerHTML={{
        __html: areaName.replace(/\//giu, "/&#8203;"),
      }}
    />
  );
}

const HousingAreaDetails = (props: HousingAreaDetailsPropsType) => {
  const { goToSlide } = useSlides();

  return (
    <div className="margin">
      {props.areas.length === 0 ? (
        <div className="no-area">Du har ikke valgt noen boområder enda.</div>
      ) : (
        <div>
          <div className="housing-area-item">
            <div className="area-name">Boområde</div>
            <div>{props.applicationsLabel}</div>
            <div>Oppfølging</div>
          </div>
          {props.areas.map((area) => (
            <div className="housing-area-item" key={area.Id}>
              <div className="area-name">
                {insertZerowidthSpaceAfterSlash(area.Name)}
              </div>
              <div>
                <strong className="red">
                  {area[props.applicationsCountField]}
                </strong>
              </div>
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    goToSlide(`${props.processApplicationsPath}/${area.Id}`);
                  }}
                >
                  Behandle »
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const UnconnectedHousingAdmin: FC<PropsType> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes("start")) {
      navigate("start");
    }
  }, [location, navigate]);

  useEffect(() => {
    props.fetchHousingAdminServiceAreas();
    props.fetchHousingDataOptions();
  }, []);

  const getSettingsPath = () => `innstillinger`;
  const getProcessHousingApplicationsPath = () =>
    getSiblingPath(props.location.pathname, "boligsøknader");
  const getProcessQuarterApplicationsPath = () =>
    getSiblingPath(props.location.pathname, "kvartersøknader");

  const settingsLinkText = () => (
    <p>
      Gå inn på <Link to={getSettingsPath()}>innstillinger</Link> for å velge
      hvilke boomårder du har ansvaret for.
    </p>
  );

  const handleOpenSettingsModal = () => {
    navigate(getSettingsPath());
  };

  const quarterAreas = filterFavoriteAreasByType(
    props.housingAreas,
    QUARTER_REFERENCE_TYPE
  );
  const housingAreas = filterFavoriteAreasByType(
    props.housingAreas,
    HOUSING_REFERENCE_TYPE
  );
  const numberOfFavoriteAreas = [...quarterAreas, ...housingAreas].length;

  return (
    <div className="scrollable sticky-middle max-width-large">
      <div className="divider">
        <SettingsModal path={getSettingsPath()} />
        <h1>
          Boligsekretær
          <CogButton
            size="large"
            placement="right"
            onClick={handleOpenSettingsModal}
          />
        </h1>
      </div>

      <Loader
        isLoading={props.loadingHousingAreas || props.loadingApplicationsCount}
      >
        {numberOfFavoriteAreas === 0 && (
          <div>
            <h2>Velkommen til dine nye arbeidsverktøy</h2>
            <div>
              Her finner du verktøy for å behandle søknader om bolig og kvarter.
              <br />
              {settingsLinkText()}
            </div>
          </div>
        )}

        <DetailsCards
          rowAlignment
          cards={[
            {
              header: "Kvartersøknader",
              description:
                "Verktøy for å behandle og godkjenne kvartersøknader.",
              iconSrc: quarterIcon,
              details: (
                <HousingAreaDetails
                  areas={quarterAreas}
                  processApplicationsPath={getProcessQuarterApplicationsPath()}
                  applicationsCountField="UnprocessedQuarterApplicationsCount"
                  applicationsLabel="Nye søknader"
                  goToSlide={props.goToSlide}
                />
              ),
            },
            {
              header: "Boligsøknader",
              description: "Verktøy for å behandle og fordele boligsøknader.",
              iconSrc: housingIcon,
              details: (
                <HousingAreaDetails
                  areas={housingAreas}
                  processApplicationsPath={getProcessHousingApplicationsPath()}
                  applicationsCountField="CurrentHousingApplicationsCount"
                  applicationsLabel="Søknader"
                  goToSlide={props.goToSlide}
                />
              ),
            },
          ]}
        />

        {numberOfFavoriteAreas > 0 && (
          <div>
            Du har {numberOfFavoriteAreas} boområde
            {numberOfFavoriteAreas > 1 ? "r" : ""} markert som favoritter.{" "}
            <br />
            {settingsLinkText()}
          </div>
        )}
      </Loader>
    </div>
  );
};

export default connect(
  (state: DeliberateAny) => ({
    loadingHousingAreas: state.housingAdmin.loadingHousingAreas,
    loadingApplicationsCount: state.housingAdmin.loadingApplicationsCount,
    housingAreas: state.housingAdmin.housingAreas,
  }),
  {
    fetchHousingAdminServiceAreas,
    fetchHousingDataOptions,
  }
)(UnconnectedHousingAdmin);
