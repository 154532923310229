import "./PortfolioColumns.scss";

import { formatDate } from "helpers/valueHelpers";
import { SignableDocumentType } from "types/signableDocumentsTypes";

export const commomColumns = [
  {
    label: "Inventarnr",
    name: "InventoryNumber",
    className: "inventory-number",
  },
  {
    label: "Etablissement",
    name: "EstablishmentName",
    className: "establishment",
  },
  {
    label: "Bygningstype",
    name: "BuildingTypeName",
    className: "building-type",
  },
  {
    label: "Totalt BTA",
    name: "TotalBTA",
    suffix: "m²",
    className: "gross-area",
  },
];

export const buildingColumns = [
  { label: "Inventarnavn", name: "Name", className: "inventory-name" },
  ...commomColumns,
];

export const signableDocumentsColumns = [
  {
    label: "Inventarnavn",
    name: "InventoryName",
    className: "inventory-name",
  },
  ...commomColumns,
  {
    label: "Signeringsfrist",
    name: "SigningDeadline",
    renderFunction: (document: SignableDocumentType) => (
      <div>{formatDate(document.SigningDeadline)}</div>
    ),
    className: "deadline",
  },
];
