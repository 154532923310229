import './Pagination.scss';

import { LeftIcon, RightIcon } from 'components/BasenIcon';
import Button from 'components/buttons/Button/Button';
import { FC, useEffect, useState } from 'react';

interface IServerPaginationProps {
  fetchItems: (page: number, itemsPerPage: number) => void;
  itemsPerPage?: number;
  totalItems: number;
  currentPage: number; // New prop to get the current page from the parent
  onPageChange: (page: number) => void; // New prop to notify parent of page changes
}

const ServerPagination: FC<IServerPaginationProps> = ({
  fetchItems,
  itemsPerPage = 10,
  totalItems,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    fetchItems(currentPage, itemsPerPage);
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    onPageChange(page); // Notify the parent of the page change
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const paginationRange = () => {
    const siblingCount = 1;
    const maxElementCount = siblingCount + 6; // Max 7 elements

    const getRange = (start: number, end: number) => {
      const length = end - start + 1;
      return Array.from({ length }, (_, index) => index + start);
    };

    if (maxElementCount >= totalPages) {
      return getRange(1, totalPages);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = getRange(1, leftItemCount);

      return [...leftRange, '...', totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = getRange(totalPages - rightItemCount + 1, totalPages);
      return [firstPageIndex, '...', ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = getRange(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
    }

    return [];
  };

  const renderPageController = () => {
    return (
      <>
        {paginationRange().map((page, index) => {
          if (page === '...') {
            return (
              <li key={`paginator_ellipsis_${index}`} className="ellipsis">
                ...
              </li>
            );
          }
          return (
            <li key={`paginator_page_${page}`}>
              <Button
                className={`tertiary ${page === currentPage ? 'active' : ''}`}
                onClick={() =>
                  typeof page === 'number' && handlePageChange(page)
                }
              >
                {page}
              </Button>
            </li>
          );
        })}
      </>
    );
  };

  return (
    <>
      {totalPages > 1 && (
        <div className="paginator">
          <Button
            className="tertiary"
            icon={LeftIcon}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          ></Button>
          <div className="page-numbers">{renderPageController()}</div>
          <Button
            className="tertiary"
            icon={RightIcon}
            onClick={handleNext}
            disabled={currentPage === totalPages}
          ></Button>
        </div>
      )}
    </>
  );
};

export default ServerPagination;
