import Slide from "components/Slides/Slide";
import { useBooking } from "contexts/index";

import ConfirmationInfo from "../ConfirmationInfo";

const ConfirmationInfoSlide = () => {
  const { markup } = useBooking();
  return (
    <Slide path="confirmation-info" slideSize="none">
      <ConfirmationInfo markup={markup} />
    </Slide>
  );
};

export default ConfirmationInfoSlide;
