// @flow

export const getStatusQueryToken = () => {
  const statusQueryToken = new URLSearchParams(window.location.search).get(
    'status_query_token',
  );

  if (statusQueryToken && statusQueryToken.length >= 1) {
    const urlWithoutStatusQueryToken = window.location.href.replace(
      `?status_query_token=${statusQueryToken}`,
      '',
    );
    window.history.replaceState({}, document.title, urlWithoutStatusQueryToken);
    return statusQueryToken;
  }
  return undefined;
};

export const getParamFromURL = (paramName: string) => {
  let url = window.location.href;
  if (url.includes('#')) {
    url = url.replace('#/', '');
  }
  const newURL = new URL(url);
  return newURL.searchParams.get(paramName);
};
