import './BookingItem.scss';

import {
  avbestillURL,
  beholdromURL,
  betalendringURL,
  betalURL,
  blokkbestillingURL,
  endreURL,
  getUnnamedBookingURL,
  ledigeRomMedPrisURL,
  priserURL,
} from 'api/booking/booking_api';
import { basenUrl } from 'api/cms/cms_api';
import ArenaInfo from 'components/ArenaInfo/ArenaInfo';
import PrintGuestList from 'components/booking/PrintGuestList';
import PrintUnnamedGuestList from 'components/booking/PrintUnnamedGuestList';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import ListItem from 'components/ListItem/ListItem';
import ModalWrapper from 'components/modals/ModalWrapper/ModalWrapper';
import * as bookingConstants from 'constants/bookingEnums';
import { ModalTypes } from 'constants/modalTypes';
import { useModal } from 'contexts/index';
import FBHelpers from 'helpers/_helpers';
import {
  afterDeadline,
  availableRoomsInParent,
  blockBookingIsFull,
  bookingRoomTypesWithCount,
  getGuestListDeadline,
  hasSharedRooms,
  isBlockBooking,
  isDeadlineHourNotPassed,
  isSharedRoom,
  isSubBooking,
  remainingPriceForParentBooking,
  stayHasStarted,
  subBookingBookedByOrganizer,
} from 'helpers/bookingHelpers';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';
import { v4 as uuidv4 } from 'uuid';

import useAxios, { axiosFetch } from '../../../hooks/useAxios';
import {
  CancelBlockBookingAfterDeadline,
  CancelReservation,
  ChangeBookingModal,
  GuestListModal,
  KeepRoomsModal,
  ManageGuestsModal,
  PurposeModal,
  ResendReceiptModal,
  SendNewKeyModal,
} from './BookingModals';

const BookingItem = ({
  columns,
  item,
  isActive,
  setActiveBookings,
  items,
  bookingOption,
  userData,
}: DeliberateAny) => {
  const [isLoading, setIsLoading] = useState(false);
  const [availableRooms, setAvailableRooms] = useState<DeliberateAny>();
  const [manageGuestListIsOpen, setManageGuestListIsOpen] = useState(false);
  const [changeBookingIsOpen, setChangeBookingIsOpen] = useState(false);
  const [purposeModalIsOpen, setPurposeModalIsOpen] = useState(false);
  const [keepRoomsModalIsOpen, setKeepRoomsModalIsOpen] = useState(false);
  const [sendNewKeyModalIsOpen, setSendNewKeyModalIsOpen] = useState(false);
  const [resendReceiptModalIsOpen, setResendReceiptModalIsOpen] =
    useState(false);
  const [
    cancelBlockBookingAfterDeadlineModalIsOpen,
    setCancelBlockBookingAfterDeadlineModalIsOpen,
  ] = useState(false);
  const [cancelReservationModalIsOpen, setCancelReservationModalIsOpen] =
    useState(false);
  const [guestListModalIsOpen, setGuestListModalIsOpen] = useState(
    item.SubBookings.map(() => false),
  );
  const [numberOfRooms, setNumberOfRooms] = useState(
    isActive && bookingOption?.Rooms
      ? bookingOption.Rooms.map((room) => ({
          id: room.RoomTypeId,
          name: room.Name,
          count: item.RoomInfo.filter(
            (bookedRoom) => bookedRoom.RoomTypeId === room.RoomTypeId,
          ).length,
        }))
      : [],
  );
  const { sendRequest } = useAxios();
  const { showModal } = useModal();

  const [referenceCode, setReferenceCode] = useState('');

  const fetchReferenceCode = async () => {
    const url = getUnnamedBookingURL + item.Id;
    await sendRequest({ method: 'GET', url: url }, (response) => {
      if (response) setReferenceCode(response.code);
    });
  };

  useEffect(() => {
    if (isActive) {
      fetchReferenceCode();
    }
  }, [item.LocationId]);

  const fetchAvailableRoomsWithPrice = async () => {
    const arrivalDate = `${moment(item.ArrivalDate).format('YYYY-MM-DDT00:00:00.000')}Z`;
    const departureDate = `${moment(item.DepartureDate).format('YYYY-MM-DDT00:00:00.000')}Z`;

    await sendRequest(
      {
        method: 'GET',
        url: ledigeRomMedPrisURL,
        params: {
          locationId: item.LocationId,
          arrivalDate,
          departureDate,
        },
      },
      (response) => {
        response.reverse();
        setAvailableRooms(response);
      },
    );
  };

  const asyncFunctionIsSubBooking = async () => {
    let numberOfAvailableRooms;
    const fetchBlockOrder = async () => {
      await sendRequest(
        {
          method: 'GET',
          url: blokkbestillingURL,
          data: { referenceKey: item.GroupInfo.ReferenceKey },
        },
        (response) => {
          if (response) {
            numberOfAvailableRooms = availableRoomsInParent(response);
          }
        },
      );
    };

    const fetchPricesWithData = async () => {
      const arrivalDate = `${moment(item.ArrivalDate).format('YYYY-MM-DDT00:00:00.000')}Z`;
      const departureDate = `${moment(item.DepartureDate).format('YYYY-MM-DDT00:00:00.000')}Z`;
      await sendRequest(
        {
          method: 'GET',
          url: priserURL,
          params: {
            locationId: item.LocationId,
            arrivalDate,
            departureDate,
          },
        },
        (response) => {
          response.map((roomType) => ({
            ...roomType,
            AvailableCount: numberOfAvailableRooms[roomType.RoomTypeId] || 0,
          }));
          setAvailableRooms(response);
        },
      );
    };
    await fetchBlockOrder();
    await fetchPricesWithData();
  };

  useEffect(() => {
    if (
      isActive &&
      item.ArrivalDate > new Date().toISOString().substring(0, 19)
    ) {
      if (isSubBooking(item)) {
        asyncFunctionIsSubBooking();
      } else {
        fetchAvailableRoomsWithPrice();
      }
    }
  }, [item.Id]);

  const handleSubmitCancellation = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: avbestillURL,
        params: {
          bookingId: item.Id,
        },
      },
      (response) => {
        if (!isBlockBooking(item)) {
          showModal({
            title: 'Kansellert',
            text: 'Bookingen har blitt kansellert.',
          });
          setActiveBookings(items.filter((el) => el.Id !== item.Id));
        } else if (response.PayUrl) {
          showModal({
            modalType: 'yes-no-links',
            text: response.PayText,
            title: 'Registrer betalingsopplysninger',
            yes: {
              text: 'Registrer betalingsopplysninger',
              url: response.PayUrl,
            },
            no: false,
            unclosable: true,
          });
        } else {
          if (response.Data && response.Data.Title && response.Data.Text) {
            showModal({
              title: response.Data.Title,
              text: response.Data.Text,
            });
          } else {
            showModal({
              html: response,
            });
          }
          setActiveBookings(items.filter((el) => el.Id !== item.Id));
        }
      },
    );
  };

  const handleCancelBookingModals = () => {
    if (isBlockBooking(item) && afterDeadline(item)) {
      setCancelBlockBookingAfterDeadlineModalIsOpen(true);
    } else {
      setCancelReservationModalIsOpen(true);
    }
  };

  const handleOpenKeepRoomModal = (event) => {
    event.preventDefault();
    setKeepRoomsModalIsOpen(true);
  };

  const handleResponse = (response) => {
    if (response.data.DataType === 'redirect') {
      window.location.href = response.data.Data;
    } else {
      showModal({
        html: response.data.Data,
      });
    }
  };

  const handleSubmit = (copyOfItem, type) => {
    setIsLoading(true);
    setActiveBookings(
      [...items].map((newItem) => {
        return newItem.Id === copyOfItem.Id ? copyOfItem : newItem;
      }),
    );
    if (type === 'endre') {
      const url = endreURL;

      axiosFetch({ url, data: copyOfItem, method: 'POST' }, null, false).then(
        (r) => {
          const response = r.data.Data;
          if (
            copyOfItem.PaymentInfo.PaymentMethod ===
              bookingConstants.PaymentMethods.PAYBYCARD &&
            response.ConfirmUrl
          ) {
            showModal({
              modalType: ModalTypes.CustomForm,
              title: 'Registrer betalingsopplysninger',
              infoText: `Din endring må bekreftes ved å registrere betalingsopplysninger på nytt innen
            ${copyOfItem.CardTimeOut} minutter. Nytt beløp er
            ${FBHelpers.formatCurrency(response.Amount, ' ', true)}`,
              submitText: 'Bekreft endringer',
              submitAction: () =>
                axiosFetch(
                  {
                    url: response.ConfirmUrl,
                  },
                  null,
                  false,
                ).then((res) => {
                  if (res) {
                    handleResponse(res);
                    setIsLoading(false);
                    setChangeBookingIsOpen(false);
                  }
                }),
              cancelText: 'Annuller endringer',
              cancelAction: () =>
                axiosFetch(
                  {
                    url: response.DismissUrl,
                  },
                  null,
                  false,
                ).then((res) => {
                  if (res) {
                    handleResponse(res);
                    setIsLoading(false);
                  }
                }),
            });
          } else if (
            // Vi har hatt en endring i opphold som betales med kort men hvor lengden
            // på oppholdet er redusert og derfor ikke krever en ny kort-transaksjon.
            // Dvs at Balance før > Balance nå
            // Vi har en condition check senere i kode hvor det ikke sjekkes om bet.måte
            // er kortbetaling. Der har vi en litt annen ordlyd i melding til kunde
            response.PaymentInfo &&
            response.PaymentInfo.PaymentMethod ===
              bookingConstants.PaymentMethods.PAYBYCARD &&
            copyOfItem.PaymentInfo.Balance > response.PaymentInfo.Balance
          ) {
            showModal({
              modalType: ModalTypes.CustomForm,
              title: 'Endret booking',
              infoText: `Din endring reduserte prisen for oppholdet. Nytt beløp er
               ${FBHelpers.formatCurrency(
                 response.PaymentInfo.Balance,
                 ' ',
                 true,
               )}`,
            });
            setActiveBookings(
              [...items].map((newItem) =>
                newItem.Id === response.Id ? response : newItem,
              ),
            );
            setIsLoading(false);
            setChangeBookingIsOpen(false);
          } else if (
            copyOfItem.PaymentInfo.PaymentMethod ===
              bookingConstants.PaymentMethods.PAYBYINVOICE &&
            response.ConfirmUrl
          ) {
            showModal({
              modalType: ModalTypes.CustomForm,
              title: 'Bekreft',
              infoText: `Din endring fører til at fakturaen får et nytt beløp. Nytt beløp er
            ${FBHelpers.formatCurrency(response.Amount, ' ', true)}`,
              submitText: 'Bekreft endringer',
              submitAction: () =>
                axiosFetch(
                  {
                    url: response.ConfirmUrl,
                  },
                  null,
                  false,
                ).then((res) => {
                  if (res) {
                    handleResponse(res);
                    setIsLoading(false);
                    setChangeBookingIsOpen(false);
                  }
                }),
              cancelText: 'Annuller endringer',
              cancelAction: () =>
                axiosFetch(
                  {
                    url: response.DismissUrl,
                  },
                  null,
                  false,
                ).then((res) => {
                  if (res) {
                    handleResponse(res);
                    setIsLoading(false);
                  }
                }),
            });
          } else if (response === 'Ingen endringer') {
            setIsLoading(false);
            showModal({
              title: 'Ingen endringer å lagre',
              text: 'Du har ikke gjort noen endringer på bestillingen.',
            });
          } else if (
            !isBlockBooking(response) &&
            copyOfItem.GroupInfo &&
            response.RoomInfo &&
            JSON.stringify(
              copyOfItem.RoomInfo.sort((a, b) => (a.Id > b.Id ? 1 : -1)),
            ) !==
              JSON.stringify(
                response.RoomInfo.sort((a, b) => (a.Id > b.Id ? 1 : -1)),
              )
          ) {
            const infoText = response.GroupInfo.NameListComplete
              ? 'Gjestelisten er oppdatert og har status som fullstendig. ' +
                `Du kan fortsatt gjøre endringer fram til fristen (${getGuestListDeadline} dager før ankomstdagen).`
              : 'Gjestelisten er oppdatert, men ufullstendig. ' +
                `Husk å fullføre gjestelisten innen fristen (${getGuestListDeadline} dager før ankomstdagen).`;
            showModal({
              title: 'Endringer er lagret',
              infoText,
            });

            setActiveBookings(
              [...items].map((newItem) =>
                newItem.Id === copyOfItem.Id ? response : newItem,
              ),
            );
            setIsLoading(false);
            setChangeBookingIsOpen(false);
          } else if (
            copyOfItem.PaymentInfo.PaymentMethod ===
              bookingConstants.PaymentMethods.NOTSELECTED &&
            response.ConfirmUrl
          ) {
            showModal({
              modalType: ModalTypes.CustomForm,
              title: 'Endring av booking',
              infoText: `Din endring fører til at oppholdet får en ny
            pris og du må bekrefte dette innen
            ${copyOfItem.CardTimeOut} minutter. Nytt beløp er
            ${FBHelpers.formatCurrency(response.Amount, ' ', true)}`,
              submitText: 'Bekreft endringer',
              submitAction: () =>
                axiosFetch(
                  {
                    url: response.ConfirmUrl,
                  },
                  null,
                  false,
                ).then((res) => {
                  if (res) {
                    handleResponse(res);
                    setIsLoading(false);
                    setChangeBookingIsOpen(false);
                  }
                }),
              cancelText: 'Annuller endringer',
              cancelAction: () =>
                axiosFetch(
                  {
                    url: response.DismissUrl,
                  },
                  null,
                  false,
                ).then((res) => {
                  if (res) {
                    handleResponse(res);
                    setIsLoading(false);
                  }
                }),
            });
          } else if (!r.data.Success) {
            setIsLoading(false);
            showModal({
              title: 'Noe gikk galt',
              html: response,
            });
          } else {
            setIsLoading(false);
            setChangeBookingIsOpen(false);
            showModal({
              title: 'Bestillingen er oppdatert',
              text: 'Din bestilling har blitt oppdatert.',
            });
          }
        },
      );
    } else if (type === 'beholdrom') {
      const url = beholdromURL;
      axiosFetch({ url, data: copyOfItem, method: 'POST' }).then((response) => {
        if (Object.prototype.hasOwnProperty.call(response, 'DismissUrl')) {
          showModal({
            modalType: ModalTypes.CustomForm,
            title: 'Registrer betalingsopplysninger',
            infoText:
              'Du må nå registrere betalingsopplysninger hos Verifone. ',
            submitText: 'Registrer betalingsopplysninger',
            submitAction: () =>
              axiosFetch(
                {
                  url: response.ConfirmUrl,
                },
                null,
                false,
              ).then((res) => {
                if (res) {
                  handleResponse(res);
                  setIsLoading(false);
                }
              }),
            cancelText: 'Avbryt',
            cancelAction: () =>
              axiosFetch(
                {
                  url: response.DismissUrl,
                },
                null,
                false,
              ).then((res) => {
                if (res) {
                  handleResponse(res);
                  setIsLoading(false);
                }
              }),
          });
        } else {
          showModal({
            modalType: 'default',
            html: response,
            title: 'Fakturainformasjon lagt til.',
          });
          setIsLoading(false);
        }
      });
    }
  };

  const handleViewGuestListModal = (event, i) => {
    event.preventDefault();
    const newArray = [...guestListModalIsOpen];
    newArray[i] = true;
    setGuestListModalIsOpen(newArray);
  };

  const handleShowTermsAndConditions = (event) => {
    event.preventDefault();

    showModal({
      url: basenUrl + '/vilkar?urlSegment=vilkarintegrert',
      selector: '.editor-content',
      size: 'medium',
      modalType: ModalTypes.GetModal,
    });
  };

  const sendNewKey = () => {
    setSendNewKeyModalIsOpen(true);
  };

  const resendReceipt = () => {
    setResendReceiptModalIsOpen(true);
  };

  const isGuestListNotOverdue = (booking) => !isDeadlineHourNotPassed(booking);

  const showRegisterCardBtn = () => {
    if (
      item.RequestState === bookingConstants.BookingStates.ROOMRESERVATION ||
      item.PaymentInfo.CaptureFailedDate !== '0001-01-01T00:00:00+00:00'
    ) {
      return (
        <a
          className="btn red"
          style={{ color: '#fff' }}
          onClick={() =>
            axiosFetch(
              {
                url: `${
                  item.ChangedFromVB ? betalendringURL : betalURL
                }?bookingid=${item.Id}`,
              },
              null,
              false,
            ).then((res) => {
              if (res) {
                handleResponse(res);
              }
            })
          }
        >
          Registrer betalingsopplysninger
        </a>
      );
    }

    return <Fragment />;
  };

  const renderActiveModalsAndButtons = () => (
    <div>
      <ModalWrapper>
        {availableRooms && (
          <ChangeBookingModal
            item={item}
            availableRooms={availableRooms}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            isOpen={changeBookingIsOpen}
            setChangeBookingIsOpen={setChangeBookingIsOpen}
            roomBookingOptions={bookingOption?.Rooms}
            numberOfRooms={numberOfRooms}
            setNumberOfRooms={setNumberOfRooms}
            setAvailableRooms={setAvailableRooms}
          />
        )}
        {isBlockBooking(item) ? (
          <PurposeModal
            isOpen={purposeModalIsOpen}
            setPurposeModalIsOpen={setPurposeModalIsOpen}
            item={item}
            handleSubmit={handleSubmit}
          />
        ) : (
          <ManageGuestsModal
            item={item}
            handleSubmit={handleSubmit}
            isOpen={manageGuestListIsOpen}
            setManageGuestListIsOpen={setManageGuestListIsOpen}
            roomBookingOptions={bookingOption?.Rooms}
            numberOfRooms={numberOfRooms}
          />
        )}
        {!item.GroupInfo.KeepRooms && !blockBookingIsFull(item) && (
          <KeepRoomsModal
            isOpen={keepRoomsModalIsOpen}
            setKeepRoomsModalIsOpen={setKeepRoomsModalIsOpen}
            item={item}
            handleSubmit={handleSubmit}
            userData={userData}
          />
        )}
        {isBlockBooking(item) && afterDeadline(item) ? (
          <CancelBlockBookingAfterDeadline
            isOpen={cancelBlockBookingAfterDeadlineModalIsOpen}
            setCancelBlockBookingAfterDeadlineModalIsOpen={
              setCancelBlockBookingAfterDeadlineModalIsOpen
            }
            handleShowTermsAndConditions={handleShowTermsAndConditions}
            item={item}
            handleSubmitCancellation={handleSubmitCancellation}
          />
        ) : (
          <CancelReservation
            isOpen={cancelReservationModalIsOpen}
            setCancelReservationModalIsOpen={setCancelReservationModalIsOpen}
            handleSubmitCancellation={handleSubmitCancellation}
          />
        )}
        {item.SubBookings &&
          item.SubBookings.length > 0 &&
          item.SubBookings.map((subBooking, i) => (
            <GuestListModal
              key={subBooking.Id}
              isOpen={guestListModalIsOpen[i]}
              setGuestListModalIsOpen={setGuestListModalIsOpen}
              subBooking={subBooking}
              guestListModalIsOpen={guestListModalIsOpen}
              position={i}
              requirements={undefined}
            />
          ))}
        {item.BookingGuests && (
          <SendNewKeyModal
            isOpen={sendNewKeyModalIsOpen}
            setSendNewKeyModalIsOpen={setSendNewKeyModalIsOpen}
            booking={item}
          />
        )}
      </ModalWrapper>
      <div style={{ paddingTop: '11px' }}>
        {stayHasStarted(item.ArrivalDate) ? (
          <div>
            {showRegisterCardBtn()}
            <ButtonRow>
              <ButtonRow.Right
                className="btn grey decoration-popup"
                key="1"
                onClick={() => sendNewKey()}
              >
                Send nøkkel
              </ButtonRow.Right>
              <ButtonRow.Right
                className="btn grey decoration-popup"
                key="2"
                onClick={() => setManageGuestListIsOpen(true)}
              >
                Gjester
              </ButtonRow.Right>
            </ButtonRow>
          </div>
        ) : (
          <div className="button-row">
            <div>
              {isGuestListNotOverdue(item) && (
                <ButtonRow.Left
                  className="btn grey decoration-popup"
                  onClick={() => setChangeBookingIsOpen(true)}
                >
                  Endre
                </ButtonRow.Left>
              )}
              {isBlockBooking(item) && (
                <ButtonRow.Left
                  className="btn grey decoration-popup"
                  onClick={() => setPurposeModalIsOpen(true)}
                >
                  Formål/beskrivelse
                </ButtonRow.Left>
              )}

              {isGuestListNotOverdue(item) && !isBlockBooking(item) && (
                <ButtonRow.Left
                  className="btn grey decoration-popup"
                  onClick={() => setManageGuestListIsOpen(true)}
                >
                  Gjester
                </ButtonRow.Left>
              )}
              {showRegisterCardBtn()}
            </div>
            <div>
              <ButtonRow.Right
                className="btn grey decoration-popup"
                key="4"
                onClick={() => handleCancelBookingModals()}
              >
                Avbestill
              </ButtonRow.Right>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const renderModalsAndButtonsForHistoricalBookings = () => (
    <div>
      <ModalWrapper>
        <ResendReceiptModal
          isOpen={resendReceiptModalIsOpen}
          setResendReceiptModalIsOpen={setResendReceiptModalIsOpen}
          booking={item}
          userData={userData}
        />
      </ModalWrapper>
      <ButtonRow>
        <ButtonRow.Right
          className="btn grey decoration-popup"
          onClick={() => resendReceipt()}
        >
          Send kvittering på e-post
        </ButtonRow.Right>
      </ButtonRow>
    </div>
  );

  const renderGroupComment = () => {
    if (!item.GroupInfo.KeepRooms && !blockBookingIsFull(item)) {
      return (
        <p>
          Du har holdt av flere rom enn hva dine deltakere har reservert. Ønsker
          du å beholde de ubenyttede rommene må du{' '}
          <Link
            to="legge inn betalingsinformasjon"
            onClick={(e) => handleOpenKeepRoomModal(e)}
          >
            legge inn betalingsinformasjon
          </Link>{' '}
          før avbestillingsfristen. Om du ikke gjør dette vil de automatisk
          frigjøres sånn at de kan reserveres av andre.
        </p>
      );
    } else if (item.GroupInfo.KeepRooms && !blockBookingIsFull(item)) {
      if (
        item.PaymentInfo.PaymentState === bookingConstants.PaymentStates.PAID
      ) {
        return <p>Du har betalt for ubenyttede rom.</p>;
      } else if (
        item.PaymentInfo.PaymentState ===
        bookingConstants.PaymentStates.DOINVOICE
      ) {
        return <p>Du har blitt fakturert for ubenyttede rom.</p>;
      }
      return (
        <p>
          <strong>NB! </strong>Du vil bli{' '}
          {item.PaymentInfo.PaymentMethod ===
          bookingConstants.PaymentMethods.PAYBYCARD
            ? 'belastet '
            : 'fakturert '}
          for alle rom som ikke reserveres.
        </p>
      );
    }
    return null;
  };

  const printGuestListBlock = () => {
    if (item.SubBookings && item.SubBookings.length > 0) {
      return (
        <ul>
          {item.SubBookings.map((subBooking, i) => {
            const roomListSubBooking = bookingRoomTypesWithCount(subBooking);
            return (
              <li key={uuidv4()} className="guest-list-room">
                <p className="half-margin">
                  Bestiller {i + 1} : {subBooking.Bookee}
                </p>
                <p className="no-margin">
                  Pris:{' '}
                  <span className="red">
                    {FBHelpers.formatCurrency(subBooking.PaymentInfo.Price)},-
                  </span>
                </p>
                {!subBookingBookedByOrganizer(item, subBooking) && (
                  <p className="no-margin">
                    {subBooking.GroupInfo.GuestCount} gjest(er)
                  </p>
                )}
                {Object.keys(roomListSubBooking).map((key) => (
                  <div key={uuidv4()} className="no-margin">
                    {roomListSubBooking[key].count}{' '}
                    {roomListSubBooking[key].VBProductId === 7 &&
                      roomListSubBooking[key].count > 1 && (
                        <span>senger i</span>
                      )}
                    {roomListSubBooking[key].VBProductId === 7 &&
                      roomListSubBooking[key].count < 1 && <span>seng i</span>}
                    {roomListSubBooking[key].name}
                  </div>
                ))}
                <p>
                  <Link
                    to="Se gjesteliste"
                    onClick={(event) => handleViewGuestListModal(event, i)}
                  >
                    Se gjesteliste
                  </Link>
                  {!subBooking.GroupInfo.NameListComplete && 'ufullstendig'}
                </p>
              </li>
            );
          })}
        </ul>
      );
    } else if (item.RequestState === bookingConstants.BookingStates.NEW) {
      return (
        <p>
          Bestillingen er ikke godkjent enda. <br />
          Når bestillingen er godkjent vil du få en referansekode som du kan
          dele med deltakerene dine.
        </p>
      );
    }
    return (
      <p>
        Ingen har benyttet seg av referansekoden din enda. <br /> Har du delt
        den med deltakerene dine?
      </p>
    );
  };

  // const printGuestList = () => (
  //   <div className="print-guest-list">
  //     <ul>
  //       {item.RoomInfo.sort((a, b) => a.Index - b.Index).map((room) => (
  //         <li key={room.Id} className="guest-list-room">
  //           <p className="room-index red no-margin">
  //             {`Rom ${room.Index + 1}`}
  //           </p>
  //           <h3 className="half-margin">{room.RoomTypeName}</h3>
  //           <div className="half-margin">
  //             <table className="names">
  //               <thead>
  //                 <tr className="list-header">
  //                   <th className="firstname left-align">Fornavn</th>
  //                   <th className="left-align">Etternavn</th>
  //                   <th className="left-align">E-post</th>
  //                   <th className="left-align">Mobiltelefon</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 {room.NameListInfo.NameListItems &&
  //                   room.NameListInfo.NameListItems.map((guest) => (
  //                     <tr key={uuidv4()}>
  //                       <td
  //                         data-th="Fornavn"
  //                         className={guest.FirstName ? '' : 'italic'}
  //                       >
  //                         {guest.FirstName ? guest.FirstName : 'navn mangler'}
  //                       </td>
  //                       <td data-th="Etternavn">
  //                         {guest.LastName ? guest.LastName : ''}
  //                       </td>
  //                       <td data-th="E-post">
  //                         {guest.Email ? guest.Email : ''}
  //                       </td>
  //                       <td data-th="Mobiltelefon">
  //                         {guest.Phone ? guest.Phone : ''}
  //                       </td>
  //                     </tr>
  //                   ))}
  //               </tbody>
  //             </table>
  //           </div>
  //           {room.Requirements && room.Requirements.length > 0 && (
  //             <div className="requirements">
  //               <p className="no-margin">
  //                 <strong>Tilpasninger</strong>
  //               </p>
  //               <p className="no-margin">{room.Requirements}</p>
  //             </div>
  //           )}
  //         </li>
  //       ))}
  //     </ul>
  //   </div>
  // );

  const renderDetails = () => {
    const roomList = bookingRoomTypesWithCount(item);
    const roomsRemaining = availableRoomsInParent(item);

    return (
      // wraps everything
      <div className="details-wrapper" style={{ fontSize: 14 }}>
        <div className="flex-row details-container detailsContainer">
          {/* wrapps left */}
          <div className="booking-details bookingDetails">
            <div className="booking-details-container">
              {isBlockBooking(item) ? (
                <div>
                  <dl className="margin">
                    <dt>
                      <strong>Bestillingsdato</strong>
                    </dt>
                    <dd>
                      {`${new Date(item.BookingDate).toLocaleDateString('no', {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                      })} - ${new Date(item.BookingDate).toLocaleTimeString(
                        'no',
                        {
                          hour: '2-digit',
                          minute: '2-digit',
                        },
                      )}`}
                    </dd>
                    {item.VBOrderId && (
                      <div className="listDiv">
                        <dt>
                          <strong>BookingID</strong>
                        </dt>
                        <dd>{item.VBOrderId}</dd>
                      </div>
                    )}
                    {referenceCode && (
                      <div className="listDiv">
                        <dt>
                          <strong>Referansekode skjermet enhet</strong>
                        </dt>
                        <dd>{referenceCode}</dd>
                      </div>
                    )}

                    <dt>
                      <strong>Gjenstående beløp</strong>
                    </dt>
                    <dd className="red" data-field="remaining-price">
                      {item.PaymentInfo.PaymentState ===
                      bookingConstants.PaymentStates.PAID
                        ? FBHelpers.formatCurrency(
                            item.PaymentInfo.Balance,
                            undefined,
                            true,
                          )
                        : FBHelpers.formatCurrency(
                            remainingPriceForParentBooking(item).toString(),
                            undefined,
                            true,
                          )}
                    </dd>

                    <dt>
                      <strong>Formål</strong>
                    </dt>
                    <dd>{item.GroupInfo.EventName}</dd>
                    {item.RequestState !==
                      bookingConstants.BookingStates.NEW && (
                      <div className="listDiv">
                        <dt>
                          <strong>Referansekode</strong>
                        </dt>
                        <dd>{item.GroupInfo.ReferenceKey}</dd>
                      </div>
                    )}
                  </dl>

                  <p className="no-margin">
                    <strong>Beskrivelse</strong>
                  </p>
                  <p className="half-margin">{item.GroupInfo.Reason}</p>

                  {item.GroupInfo.UserComment &&
                    item.GroupInfo.UserComment.length > 0 && (
                      <div className="listDiv">
                        <p className="no-margin">
                          <strong>Ønsker</strong>
                        </p>
                        <p className="half-margin">
                          {item.GroupInfo.UserComment}
                        </p>
                      </div>
                    )}

                  {item.Message && item.Message.length > 0 && (
                    <div className="listDiv">
                      <p className="no-margin">
                        <strong>Kommentar ved godkjenning</strong>
                      </p>
                      <p className="half-margin">{item.Message}</p>
                    </div>
                  )}

                  <p className="no-margin">
                    <strong>Reserverte rom/senger</strong>
                  </p>
                  {Object.keys(roomList).map((key) => (
                    <p key={key} className="half-margin">
                      {`${roomList[key].count} ${
                        isSharedRoom(roomList[key]) ? 'Senger i ' : ''
                      }
                      ${roomList[key].name} (${
                        roomsRemaining[key] || 0
                      } gjenstående)`}
                    </p>
                  ))}
                  {renderGroupComment()}
                </div>
              ) : (
                <div>
                  <dl className="margin">
                    <dt>
                      <strong>Bestillingsdato</strong>
                    </dt>
                    <dd>
                      {`${new Date(item.BookingDate).toLocaleDateString('no', {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                      })} - ${new Date(item.BookingDate).toLocaleTimeString(
                        'no',
                        {
                          hour: '2-digit',
                          minute: '2-digit',
                        },
                      )}`}
                    </dd>
                    {item.VBOrderId ? (
                      <div className="listDiv">
                        <dt>
                          <strong>BookingID</strong>
                        </dt>
                        <dd>{item.VBOrderId}</dd>
                      </div>
                    ) : (
                      ''
                    )}
                    {referenceCode ? (
                      <div className="listDiv">
                        <dt>
                          <strong>Referansekode skjermet enhet</strong>
                        </dt>
                        <dd>{referenceCode}</dd>
                      </div>
                    ) : (
                      ''
                    )}
                    {isSubBooking(item) && (
                      <div className="listDiv">
                        <dt>
                          <strong>Referansekode</strong>
                        </dt>
                        <dd>{item.GroupInfo.ReferenceKey}</dd>
                      </div>
                    )}
                  </dl>

                  {item.GroupInfo &&
                    item.GroupInfo.Reason &&
                    item.GroupInfo.Reason.length > 0 && (
                      <div className="listDiv">
                        <p className="no-margin">
                          <strong>Beskrivelse</strong>
                        </p>
                        <p className="half-margin">{item.GroupInfo.Reason}</p>
                      </div>
                    )}

                  {item.Message && item.Message.length && (
                    <div className="listDiv">
                      <p className="no-margin">
                        <strong>Kommentar ved godkjenning</strong>
                      </p>
                      <p className="half-margin">{item.Message}</p>
                    </div>
                  )}
                </div>
              )}
              {/* wraps left END*/}
            </div>
            <div className="links" style={{ marginTop: '22px' }}>
              <div>
                <ArenaInfo
                  arenaId={item.LocationId}
                  arenaName={item.Location}
                />
              </div>
              <div>
                <p>
                  <Link
                    to="Vilkår for overnatting"
                    onClick={(e) => handleShowTermsAndConditions(e)}
                  >
                    Vilkår for overnatting
                  </Link>
                </p>
              </div>
            </div>
          </div>

          {/* wraps right */}
          <div className="booking-item nameList">
            {isBlockBooking(item) ? (
              <div className="print-guest-list block-booking printGuestList">
                <h3>Bestilte {hasSharedRooms(item) ? 'rom/senger' : 'rom'}</h3>
                <div style={{ fontSize: '16px' }}>{printGuestListBlock()}</div>
              </div>
            ) : (
              <div>
                {referenceCode && referenceCode != '' ? (
                  <PrintUnnamedGuestList checkInReferenceCode={referenceCode} />
                ) : (
                  <PrintGuestList item={item} />
                )}
              </div>
            )}
          </div>
          {/* wraps right END */}
          {/* wraps everything END */}
        </div>
        {isActive &&
          item.RequestState !== bookingConstants.BookingStates.CANCELLED &&
          renderActiveModalsAndButtons()}
        {!isActive &&
          item.RequestState !== bookingConstants.BookingStates.CANCELLED &&
          item.PaymentInfo.PaymentMethod ===
            bookingConstants.PaymentMethods.PAYBYCARD &&
          item.PaymentInfo.PaymentState ===
            bookingConstants.PaymentStates.PAID &&
          renderModalsAndButtonsForHistoricalBookings()}
      </div>
    );
  };

  const handleExpiredCounter = (id) => {
    setActiveBookings(items.filter((el) => el.Id !== id));
  };

  return (
    <ListItem
      columns={columns}
      item={item}
      renderDetailsFunction={() => renderDetails()}
      counter={
        item.RequestState === bookingConstants.BookingStates.ROOMRESERVATION &&
        isActive && {
          expiredFunction: (id) => handleExpiredCounter(id),
        }
      }
    />
  );
};

export default BookingItem;
