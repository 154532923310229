import {
  proffBaseUrl,
  registerDunningForCurrentUserURL,
  registerDunningURL,
} from 'api/basenproff/basenproff_api';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import TextArea from 'components/form/TextArea/TextArea';
import ListItem from 'components/ListItem/ListItem';
import ConfirmationModal from 'components/modals/ConfirmationModal/ConfirmationModal';
import { ModalTypes } from 'constants/modalTypes';
import { useModal, useUser } from 'contexts/index';
import { Fragment, useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import useAxios from '../../hooks/useAxios';
import AdditionalInformationServiceOrderModal from './AdditionalInformationServiceOrderModal/AdditionalInformationServiceOrderModal';
import ComplaintServiceOrderModal from './CompaintServiceOrderModal/CompaintServiceOrderModal';

import type { ListColumnType } from 'types/listTypes';
import type { ServiceOrderType } from 'types/serviceOrderTypes';
const serviceOrderDetails = [
  { label: 'SO-nummer', name: 'ServiceOrderNumber' },
  { label: 'Type', name: 'ServiceOrderType' },
  { label: 'Tittel', name: 'Title' },
  { label: 'Status', name: 'ServiceOrderStatePrintable' },
  { label: 'Opprettet', name: 'CreatedDate' },
  { label: 'Leveringsdato', name: 'DeliveryDate' },
  { label: 'Ansvarlig', name: 'ResponsiblePerson' },
  { label: 'Telefon', name: 'ResponsiblePhoneNumber' },
  { label: 'Etablissement', name: 'Establishment' },
  { label: 'Bygning', name: 'Building' },
  { label: 'Inventarnr', name: 'BuildingNumber' },
  { label: 'Innmelder', name: 'Reporter' },
  { label: 'Forsvarets IOnr', name: 'IONumber' },
  { label: 'Beskrivelse', name: 'Description' },
];

const itemDescriptionsWithValue = (item, details) => {
  // returnerer kolonner som det finnes verdier for i items.
  if (!item) return [];
  return details.filter(el => item[el.name]);
};

const mapComplaints = complaints => {
  const mappedComplaints: DeliberateAny[] = [];
  complaints.forEach(complaint => {
    const newComplaint: DeliberateAny = {
      data: [
        { label: 'SO-nummer', value: complaint.ServiceOrderNumber },
        { label: 'SO-TYPE', value: complaint.ServiceOrderType },
        { label: 'Opprettet', value: complaint.CreatedDate },
        { label: 'Navn', value: complaint.CreatedBy },
        { label: 'Beskrivelse', value: complaint.Description },
      ],
      title: complaint.Title,
    };
    mappedComplaints.push(newComplaint);
  });
  return mappedComplaints;
};

const mapDunnings = dunnings => {
  const mappedDunnings: DeliberateAny[] = [];
  dunnings.forEach(dunning => {
    const newDunning = {
      title: 'Purring',
      data: [
        { label: 'Opprettet', value: dunning.CreatedDate },
        { label: 'Navn', value: dunning.CreatedBy },
        { label: 'Brukernavn', value: dunning.CreatedByUsername },
        { label: 'Tlf.', value: dunning.CreatedByPhone },
      ],
    };
    mappedDunnings.push(newDunning);
  });
  return mappedDunnings;
};

const mapAdditionalInfo = additionalInfo => {
  const mappedAdditionalInfo: DeliberateAny[] = [];
  additionalInfo.forEach(addInfo => {
    const newAddInfo = {
      title: 'Tilleggsinformasjon',
      data: [
        { label: 'Opprettet', value: addInfo.CreatedDate },
        { label: 'Navn', value: addInfo.CreatedBy },
        { label: 'Brukernavn', value: addInfo.CreatedByUsername },
        { label: 'Tlf.', value: addInfo.CreatedByPhone },
        { label: 'Beskrivelse', value: addInfo.Description },
      ],
    };
    mappedAdditionalInfo.push(newAddInfo);
  });
  return mappedAdditionalInfo;
};

const getRelatedItems = item => {
  if (!item?.Complaints && !item?.Dunnings && !item?.AdditionalInfo) {
    return [];
  }
  let relatedItems: DeliberateAny[] = [];

  if (item.AdditionalInfo.length > 0) {
    const additonalInfo = mapAdditionalInfo(item.AdditionalInfo);
    relatedItems = relatedItems.concat(additonalInfo);
  }

  if (item.Complaints.length > 0) {
    const complaints = mapComplaints(item.Complaints);
    relatedItems = relatedItems.concat(complaints);
  }
  if (item.Dunnings.length > 0) {
    const dunnings = mapDunnings(item.Dunnings);
    relatedItems = relatedItems.concat(dunnings);
  }
  return relatedItems;
};

const getDocuments = item => {
  if (!item?.Documents || item.Documents?.length < 1) {
    return [];
  }
  const documentList: DeliberateAny[] = [];
  item.Documents.forEach(doc => {
    documentList.push({
      name: doc.Name,
      path: proffBaseUrl + doc.Path,
      code: doc.Code,
    });
  });
  return documentList;
};

const ServiceOrderItem = ({
  columns,
  item,
  update,
}: {
  columns: Array<ListColumnType>;
  item: ServiceOrderType;
  update?: () => void;
}) => {
  const [showComplainModal, setShowComplainModal] = useState(false);
  const [showAdditionalInfoModal, setShowAdditionalInfoModal] = useState(false);
  const [showXpandDescription, setShowXpandDescription] = useState(false);
  const itemDescriptions = itemDescriptionsWithValue(item, serviceOrderDetails);
  const relatedItems = getRelatedItems(item);
  const documentList = getDocuments(item);
  const { showModal } = useModal();
  const { requestError, sendRequest } = useAxios();
  const { user } = useUser();

  useEffect(() => {
    if (requestError) {
      showModal({
        title: 'Beklager',
        infoText: 'Det skjedde en feil',
      });
    }
  }, [requestError]);

  const remainderAction = async () => {
    await sendRequest(
      {
        method: 'POST',
        url:
          user.ClientSquareAccess === 'True'
            ? registerDunningURL
            : registerDunningForCurrentUserURL,
        data: { ServiceOrderNumber: item.ServiceOrderNumber },
      },
      () => {
        showModal({
          title: 'Takk',
          infoText: 'Din purring har blitt registrert.',
        });
      },
    );
  };

  return (
    <Fragment>
      <ListItem
        columns={columns}
        item={item}
        minimalDetails
        details={itemDescriptions}
        related={relatedItems}
        xpandDescription={item?.XpandDescription}
        documentList={documentList}
        extendedDetailsContainer={
          <ButtonRow>
            <ButtonRow.Left onClick={() => setShowAdditionalInfoModal(true)}>
              Legg til opplysninger
            </ButtonRow.Left>
            <ButtonRow.Left
              onClick={() =>
                showModal({
                  modalType: ModalTypes.CustomForm,
                  title: 'Send purring',
                  infoText:
                    'Vennligst bekreft at du vil sende en purring på denne henvendelsen.',
                  submitAction: remainderAction,
                })
              }
            >
              Purring
            </ButtonRow.Left>
            <ButtonRow.Left
              onClick={() => {
                if (item?.Complaints?.length >= 3) {
                  showModal({
                    title: 'Kan ikke registrere klage',
                    html:
                      'Det kan dessverre ikke registreres mer enn tre klager på en henvendelse. ' +
                      'Dersom du fortsatt ikke er fornøyd med behandlingen av denne saken, ' +
                      'vennligst kontakt ' +
                      "<a data-action='dismiss' href='#/contact'>Forsvarsbygg servicesenter</a>.<br><br>" +
                      'Vi beklager at vår behandling av saken ikke har vært i tråd med dine forventninger.',
                  });
                } else {
                  setShowComplainModal(true);
                }
              }}
            >
              Klage/avvik
            </ButtonRow.Left>
            {item?.XpandDescription && (
              <ButtonRow.Left onClick={() => setShowXpandDescription(true)}>
                Utfyllende beskrivelse
              </ButtonRow.Left>
            )}
          </ButtonRow>
        }
      />
      <AdditionalInformationServiceOrderModal
        openModal={showAdditionalInfoModal}
        setOpenModal={setShowAdditionalInfoModal}
        SO={item?.ServiceOrderNumber}
        reporter={user.Name}
        basenProffAccess={user.ClientSquareAccess === 'True'}
      />
      <ComplaintServiceOrderModal
        openModal={showComplainModal}
        setOpenModal={setShowComplainModal}
        SO={item?.ServiceOrderNumber}
        reporter={user.Name}
        itemTitle={item?.Title}
        basenProffAccess={user.ClientSquareAccess === 'True'}
        update={update}
      />
      <ConfirmationModal
        size="medium"
        title="Utfyllende beskrivelse"
        isOpen={showXpandDescription}
        onClose={() => setShowXpandDescription(false)}
        infoText={
          <TextArea
            rows={12}
            className="margin"
            name={'Utfyllende beskrivelse'}
            value={
              item?.XpandDescription
                ? item.XpandDescription.replace(/<br ?\/?>/gi, '\n')
                : ''
            }
          />
        }
      />
    </Fragment>
  );
};

export default ServiceOrderItem;
