import moment from 'moment';

import * as bookingConstants from '../constants/bookingEnums';
import FBHelpers from './_helpers';

let errorMessageBooking = '';
export const getGuestListDeadline = 3;
let deadlineForPayment = null;

export const availableRoomsInParent = (parent) => {
  const rooms = {};

  /* count number of rooms in parent booking
         This creates an object like so: {roomId: count, ...}
         i.e:
         {
         "80db95bd-6739-49d5-b86c-670af1a917ad": 10,
         "fcd2b3d5-1798-4f58-92cf-fa6a39855023": 5
         }
         */

  parent.RoomInfo.forEach((room) => {
    if (!rooms[room.RoomTypeId]) {
      rooms[room.RoomTypeId] = 1;
    } else {
      rooms[room.RoomTypeId] += 1;
    }
  });
  /*
         Subtract all rooms that are already reserved in other sub-bookings.
         */
  parent.SubBookings.forEach((subBooking) => {
    subBooking.RoomInfo.forEach((subBookingRoom) => {
      if (rooms[subBookingRoom.RoomTypeId]) {
        rooms[subBookingRoom.RoomTypeId] -= 1;
      }
    });
  });
  return rooms;
};

export const blockBookingNoRooms = (booking) => {
  const remaining = availableRoomsInParent(booking);
  let count = 0;
  Object.keys(remaining).forEach((key) => {
    count += remaining[key];
  });
  return count;
};

export const numberOfAvailableBedsAndRooms = (selectedLocation, isBlock) => {
  let availableBeds = 0;
  let availableRooms = 0;
  selectedLocation.Rooms.forEach((roomType) => {
    availableBeds += roomType.AvailableCount * roomType.StandardCapacity;
    availableRooms += roomType.AvailableCount;
    if (!isBlock) {
      availableBeds += roomType.AvailableCount * roomType.ExtraCapacity;
    }
  });

  return { beds: availableBeds, rooms: availableRooms };
};

export const blockBookingDeadlineExpired = (booking) =>
  FBHelpers.Date.daysBetweenDates(new Date(), booking.ArrivalDate) < 2;

export const formatDate = (item) => {
  const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'mai',
    'jun',
    'jul',
    'aug',
    'sep',
    'okt',
    'nov',
    'des',
  ];
  const arrDate = new Date(item.ArrivalDate).getDate();
  const depDate = new Date(item.DepartureDate).getDate();
  const arrMonth = new Date(item.ArrivalDate).getMonth();
  const depMonth = new Date(item.DepartureDate).getMonth();

  if (arrMonth === depMonth) {
    return `${arrDate} - ${depDate} ${months[depMonth]}`;
  }
  return `${arrDate} ${months[arrMonth]} - ${depDate} ${months[depMonth]}`;
};

export const getTotalPrice = (
  bookedRoomInfo,
  selectedLocationRoomInfo,
  isBlock,
) => {
  const price = isBlock
    ? bookedRoomInfo
        .map(
          (room) =>
            selectedLocationRoomInfo
              .find((obj) => obj.RoomTypeId === room.RoomTypeId)
              .PriceList.find((guests) => guests.Persons === 1).PriceValue,
        )
        .reduce((total, num) => total + num, 0)
    : bookedRoomInfo
        .map(
          (room) =>
            selectedLocationRoomInfo
              .find((obj) => obj.RoomTypeId === room.RoomTypeId)
              .PriceList.find(
                (guests) =>
                  guests.Persons === room.NameListInfo.NameListItems.length,
              ).PriceValue,
        )
        .reduce((total, num) => total + num, 0);

  return price;
};

const getFirstClosurePeriod = (hit) => {
  const periods = hit.ClosurePeriods.sort((a, b) => {
    return new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime();
  });

  return periods[0];
};

export const searchInObject = (str, obj) => {
  if (obj.Name.toLowerCase().indexOf(str.toLowerCase()) === 0) return obj;
  const hitInTags = (tag) => tag.toLowerCase().indexOf(str.toLowerCase()) === 0;
  if (obj.Tags.some(hitInTags)) return obj;
  return null;
};

export const processHit = (hit) => {
  if (hit.ClosurePeriods.length === 0 && !hit.NotOpenForBooking) {
    return '';
  } else if (hit.NotOpenForBooking) {
    return 'Ikke tilgjengelig for booking';
  }
  const upcomingPeriod =
    hit.ClosurePeriods.length === 1
      ? hit.ClosurePeriods[0]
      : getFirstClosurePeriod(hit);

  const seasonClosureStart = new Date(upcomingPeriod.StartDate);
  const seasonClosureEnd = new Date(upcomingPeriod.EndDate);

  const startStr = `${seasonClosureStart.getDate()} ${FBHelpers.Date.monthString(
    seasonClosureStart.getMonth(),
    true,
  )}.`;
  const endStr = `${seasonClosureEnd.getDate()} ${FBHelpers.Date.monthString(
    seasonClosureEnd.getMonth(),
    true,
  )}.`;
  const closedStr = `Utilgjengelig fra ${startStr} til ${endStr}`;

  return closedStr;
};

export const getTotalNumberOfRooms = (roomInfoPerRoomType) => {
  const totalRooms = roomInfoPerRoomType
    .map((roomType) => roomType.count)
    .reduce((total, num) => total + num, 0);
  return totalRooms;
};

export const isSharedRoom = (roomData) =>
  roomData.VBRoomTypeId === 7 || roomData.VBProductId === 7;

export const hasSharedRooms = (booking) => {
  const shared = booking.RoomInfo.some((room) =>
    // VBProductId 7 is shared rooms
    isSharedRoom(room),
  );
  return shared;
};

export const isSubBooking = (booking) =>
  booking.GroupInfo && booking.GroupInfo.EventName && booking.ParentBookingId;

export const isBlockBooking = (booking) =>
  booking.bookingMode === 'block' ||
  (booking.GroupInfo &&
    booking.GroupInfo.EventName &&
    !booking.ParentBookingId);

export const afterDeadline = (booking) => {
  const isGroupBooking = booking.GroupInfo.GuestCount >= 5;
  const isBlock = isBlockBooking(booking);
  const deadlineDaySpan = isGroupBooking || isBlock ? 7 : 1;
  let deadlinePassed = false;

  const daysRemaining = FBHelpers.Date.daysBetweenDates(
    new Date(),
    booking.ArrivalDate,
  );

  // deadline is at 10:00 AM
  if (daysRemaining === deadlineDaySpan && new Date().getHours() > 9) {
    deadlinePassed = true;
  } else if (daysRemaining < deadlineDaySpan) {
    deadlinePassed = true;
  }

  return deadlinePassed;
};

export const blockBookingIsFull = (booking) =>
  blockBookingNoRooms(booking) === 0;

export const bookingRoomTypesWithCount = (booking) => {
  const rooms = {};
  booking.RoomInfo.forEach((room) => {
    if (!rooms[room.RoomTypeId]) {
      rooms[room.RoomTypeId] = {
        count: 1,
        name: room.RoomTypeName,
        VBProductId: room.VBProductId,
      };
    } else {
      rooms[room.RoomTypeId].count += 1;
    }
  });
  return rooms;
};

export const subBookingBookedByOrganizer = (booking, subBooking) =>
  booking.BookeeId === subBooking.BookeeId;

export const remainingPriceForParentBooking = (booking) => {
  let price = booking.PaymentInfo.Price;
  booking.SubBookings.forEach((sub) => {
    price -= sub.PaymentInfo.Price;
  });

  return Math.max(0, price);
};

export const getDaysToNameListDeadline = (bookingData) =>
  FBHelpers.Date.daysBetweenDates(
    new Date(),
    moment(bookingData.ArrivalDate)
      .subtract(getGuestListDeadline, 'days')
      .toDate(),
  );

export const isDeadlineHourNotPassed = (bookingData) => {
  const deadlineHour = 10;
  return moment().isAfter(
    moment(bookingData.ArrivalDate)
      .subtract(getGuestListDeadline, 'days')
      .hours(deadlineHour),
  );
};

const paymentTransactionMissing = (booking) =>
  booking.PaymentInfo.PaymentMethod ===
    bookingConstants.PaymentMethods.PAYBYCARD && !booking.PaymentTransaction;

const cardWithdrawalFailed = (booking) =>
  !FBHelpers.Date.isDateTimeMinValue(booking.PaymentInfo.CaptureFailedDate) &&
  booking.PaymentInfo.PaymentMethod ===
    bookingConstants.PaymentMethods.PAYBYCARD &&
  booking.PaymentInfo.PaymentState === bookingConstants.PaymentStates.NOTPAID;

export const getConfirmedState = (bookingData) => {
  let txt = '';
  if (
    bookingData.GroupInfo.NameListRequired &&
    !bookingData.GroupInfo.NameListComplete
  ) {
    const daysToDeadline = getDaysToNameListDeadline(bookingData);
    txt += 'Gjesteliste mangler. ';
    if (daysToDeadline >= 1) {
      txt += `Det er ${getDaysToNameListDeadline(
        bookingData,
      )} dager igjen til fristen`;
    } else if (daysToDeadline === 0) {
      txt += 'Fristen utløper i dag kl 10.00. Lever inn gjesteliste.';
    } else {
      txt =
        'Fristen for innsending av gjesteliste er utløpt. Kontakt Forsvarsbygg servicesenter.';
    }
  } else if (
    paymentTransactionMissing(bookingData) ||
    cardWithdrawalFailed(bookingData)
  ) {
    txt = 'Registrer kortinfo.';
  } else {
    txt = 'Alt i orden med bestillingen. Velkommen!';
  }

  return txt;
};

const getPaymentStatus = (transactionEvent) => {
  let message = 'med kort.';
  switch (transactionEvent) {
    case bookingConstants.TransactionEvents.REGISTERED:
      message += '<br/>Transaksjon opprettet.';
      break;
    case bookingConstants.TransactionEvents.AUTHORIZED:
      message += '<br/>Belastes ved avreise.';
      break;
    case bookingConstants.TransactionEvents.CAPTURE:
      message += '<br/>Beløp trukket.';
      break;
    case bookingConstants.PaymentMethods.PAYATARRIVAL:
      message += '<br/>Ved ankomst.';
      break;
    default:
      message = '';
  }
  return message;
};

export const invoiceMessage = (booking) => {
  let ret = '';
  if (
    booking.PaymentInfo.PaymentState === bookingConstants.PaymentStates.DUEDEBIT
  ) {
    ret = 'beløp faktureres';
  } else {
    ret = 'med faktura';
  }

  return ret;
};

export const getPaymentMessage = (booking) => {
  const defaultMessage = 'ukjent betalingsmåte';
  let message = '';
  if (isBlockBooking(booking)) {
    if (booking.RequestState !== bookingConstants.BookingStates.CANCELLED) {
      message = 'gjestene betaler selv.';
    } else {
      message = '';
    }
  } else {
    switch (booking.PaymentInfo.PaymentMethod) {
      case bookingConstants.PaymentMethods.NOTSELECTED:
        message = defaultMessage;
        break;
      case bookingConstants.PaymentMethods.PAYBYCARD:
        if (booking.PaymentTransaction) {
          message = getPaymentStatus(
            booking.PaymentTransaction.TransactionEvent,
          );
        } else {
          message = 'med kort.';
          if (booking.RequestState !== bookingConstants.BookingStates.NEW) {
            message += ' Kortinfo mangler.';
          }
        }
        break;
      case bookingConstants.PaymentMethods.PAYBYINVOICE:
        message = invoiceMessage(booking);
        break;
      case bookingConstants.PaymentMethods.PAYBYSALARYDEDUCTION:
        message = 'trekkes fra lønn.';
        break;
      case bookingConstants.PaymentMethods.PAYATARRIVAL:
        message = 'ved ankomst.';
        break;
      case bookingConstants.PaymentMethods.PREPAID:
        message = 'Forhåndsbetalt.';
        break;
      default:
        message = defaultMessage;
    }
  }
  return message;
};

export const getBookingState = (booking) => {
  let bookingStatus = '';

  const postPonedTo = new Date(booking.PostPonedTillDate);
  const date = moment(postPonedTo).format('DD.MM');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  switch (booking.RequestState) {
    case bookingConstants.BookingStates.NULL:
      bookingStatus = 'Bookingstatus ikke satt.';
      break;
    case bookingConstants.BookingStates.NEW:
      bookingStatus = 'Venter på behandling.';
      break;
    case bookingConstants.BookingStates.REJECTED:
      bookingStatus = 'Avslått.';
      break;
    case bookingConstants.BookingStates.CANCELLATIONREQUESTED:
      bookingStatus = 'Kansellering forespurt.';
      break;
    case bookingConstants.BookingStates.CANCELLED:
      bookingStatus = 'Booking kansellert.';
      break;
    case bookingConstants.BookingStates.POSTPONED:
      bookingStatus = `Utsatt til ${date}.`;
      break;
    case bookingConstants.BookingStates.CLOSED:
      bookingStatus = 'Lukket.';
      break;
    case bookingConstants.BookingStates.NOSHOW:
      bookingStatus = 'Kansellert etter avbestillingsfrist.';
      break;
    default:
      bookingStatus = 'Ukjent bookingstatus.';
  }
  return bookingStatus;
};

export const payByInvoice = (paymentInfo) =>
  paymentInfo.PaymentMethod === bookingConstants.PaymentMethods.PAYBYINVOICE;

// There is no state for saying 'Successful stay', so to be able to show the desired message to
// the user we have to deduct it based on if the stay has been paid for and the guest hasn't
// been registered as a no-show we can assume the stay was successful.
export const successfulStay = (bookingData) =>
  bookingData.PaymentInfo.PaymentState ===
    bookingConstants.PaymentStates.PAID &&
  bookingData.RequestState !== bookingConstants.BookingStates.NOSHOW;

export const onlinePayment = (paymentInfo) =>
  paymentInfo.PaymentMethod === bookingConstants.PaymentMethods.PAYBYCARD;

export const transactionStatus = (transactionEvent) => {
  let transactionText = '';
  switch (transactionEvent) {
    case bookingConstants.TransactionEvents.REGISTERED:
      transactionText = 'Transaksjon opprettet';
      break;
    case bookingConstants.TransactionEvents.AUTHORIZED:
      transactionText = 'Kortdata registrert';
      break;
    case bookingConstants.TransactionEvents.CAPTURE:
      transactionText = 'Kort belastet';
      break;
    case bookingConstants.TransactionEvents.CREDIT:
      transactionText = 'Beløp kreditert';
      break;
    case bookingConstants.TransactionEvents.ANNUL:
      transactionText = 'Transaksjon annullert';
      break;
    default:
      transactionText = 'Ukjent transaksjonsstatus';
  }

  return transactionText;
};

export const paymentFailed = (bookingData) =>
  bookingData.PaymentInfo &&
  bookingData.PaymentInfo.CaptureFailedDate &&
  new Date(bookingData.PaymentInfo.CaptureFailedDate).getFullYear() > 2000;

export const salaryDeduction = (paymentInfo) =>
  paymentInfo.PaymentMethod ===
  bookingConstants.PaymentMethods.PAYBYSALARYDEDUCTION;

export const numberOfSharedRoomBedsInBooking = (booking) => {
  let counter = 0;

  const roomTypesWithCount = bookingRoomTypesWithCount(booking);
  Object.keys(roomTypesWithCount).forEach((room) => {
    if (roomTypesWithCount[room].VBProductId === 7) {
      counter = roomTypesWithCount[room].count;
    }
  });

  return counter;
};

export const totalRoomCountInBlockBooking = (booking) => {
  const roomList = bookingRoomTypesWithCount(booking);
  let count = 0;
  Object.keys(roomList).forEach((key) => {
    count += roomList[key].count;
  });
  return count;
};

export const numberOfBookedSharedBedsInSubBookings = (subBookings) => {
  let counter = 0;

  subBookings.forEach((booking) => {
    const roomTypesWithCount = bookingRoomTypesWithCount(booking);
    Object.keys(roomTypesWithCount).forEach((room) => {
      if (roomTypesWithCount[room].VBProductId === 7) {
        counter += roomTypesWithCount[room].count;
      }
    });
  });

  return counter;
};

export const numberOfBookedRoomsInSubBookings = (subBookings) => {
  let counter = 0;

  subBookings.forEach((booking) => {
    const roomTypesWithCount = bookingRoomTypesWithCount(booking);
    Object.keys(roomTypesWithCount).forEach((room) => {
      if (roomTypesWithCount[room].VBProductId !== 7) {
        counter += roomTypesWithCount[room].count;
      }
    });
  });

  return counter;
};

export const stayHasStarted = (arrivalDate) => {
  const daysRemaining = FBHelpers.Date.daysBetweenDates(
    new Date(),
    arrivalDate,
  );
  // checking in begins at 10, so stay has not officially started before 10:00
  return daysRemaining === 0 ? new Date().getHours() > 9 : daysRemaining < 0;
};

export const getMinDate = () => {
  const today = moment().startOf('day');
  const businessDaysToAdd = 2;
  return AddBusinessDays(today, businessDaysToAdd);
};

export function AddBusinessDays(date: moment.Moment, daysToAdd: number) {
  const Sunday = 0;
  const Saturday = 6;
  let minumumDaysAhead =
    daysToAdd + (date.day() !== Sunday && date.day() !== Saturday ? 0 : 1);
  while (minumumDaysAhead > 0) {
    date.add(1, 'days');
    if (date.day() !== Sunday && date.day() !== Saturday) {
      minumumDaysAhead--;
    }
  }
  return date;
}

export function setErrorMsgBooking(msg) {
  errorMessageBooking = msg || '';
}

export function getErrorMsgBooking() {
  return errorMessageBooking;
}

export function setDeadlineForPayment(days) {
  deadlineForPayment = days;
}

export function getDeadlineForPayment() {
  return deadlineForPayment;
}
