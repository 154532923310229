import { useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import { v4 } from 'uuid';

import { uiAddFavoriteURL, uiRemoveFavoriteURL } from '../../api/core/core_api';
import useAxios from '../../hooks/useAxios';
import L3Comment from './L3Comment';
import L3Tags from './L3Tags';

const L3Items = ({ items }: DeliberateAny) => {
  const { sendRequest } = useAxios();
  const [grouped, setGrouped] = useState<DeliberateAny[]>([]);
  const [expanded, setExpanded] = useState<DeliberateAny[]>([]);

  const [addFav, setAddFav] = useState<DeliberateAny[]>([]);
  const [delFav, setDelFav] = useState<DeliberateAny[]>([]);

  const handleFav = async (id, fav) => {
    try {
      await sendRequest(
        {
          method: 'POST',
          url: !fav ? uiAddFavoriteURL : uiRemoveFavoriteURL,
          data: {
            ItemId: id,
          },
        },
        () => {
          if (!fav) {
            setAddFav([...addFav, id]);
            setDelFav(delFav.filter((f) => f !== id));
          } else {
            setAddFav(addFav.filter((f) => f !== id));
            setDelFav([...delFav, id]);
          }
        },
      );
    } finally {
      //
    }
  };

  function prioritizeItemsWithL3Extra(items) {
    return items.sort(
      (a: DeliberateAny, b: DeliberateAny) =>
        (b.L3Extra !== undefined ? 1 : 0) - (a.L3Extra !== undefined ? 1 : 0),
    );
  }

  useEffect(() => {
    const groupedBySubject = prioritizeItemsWithL3Extra(items).reduce(
      (acc, item) => {
        const key = item.Subject;
        // Ensure the accumulator for the key exists
        if (!acc[key]) {
          acc[key] = [];
        }
        // Using a Set to track unique Ids for each subject
        if (!acc[key].ids) {
          acc[key].ids = new Set();
        }
        // Check if the item's Id is already added to the set
        if (!acc[key].ids.has(item.Id)) {
          acc[key].ids.add(item.Id);
          acc[key].push(item);
        }
        return acc;
      },
      {},
    );

    Object.values(groupedBySubject).forEach((group: DeliberateAny) => {
      delete group.ids;
    });

    setGrouped(groupedBySubject);
    // You can use 'groupedBySubject' here for further processing or state updates
  }, [items]);

  return (
    <div className="l3-lists">
      {grouped &&
        Object.keys(grouped).map((grp: DeliberateAny) => {
          return (
            <div className="l3-list-container" key={grp}>
              <h3>{grp}</h3>
              <ul className="l3-items item-list">
                <li className="list-header l3-item hide-for-small">
                  <div className="overview">
                    <div className="favorite sort" data-sort="IsFavorite">
                      Fav.
                    </div>
                    <div className="l3-ref sort" data-sort="Reference">
                      Ref.
                    </div>
                    <div className="l3-description sort" data-sort="Name">
                      Beskrivelse
                    </div>
                    <div
                      className="l3-responsible sort"
                      data-sort="Responsible"
                    >
                      Kostnad og ansvar
                    </div>
                    <div className="button-container" />
                  </div>
                </li>
                {grouped[grp] &&
                  grouped[grp].map((i) => (
                    <li
                      key={v4()}
                      className={`l3-item list-item ${
                        expanded.includes(i.Id) ? ' expanded' : ''
                      }`}
                      role="treeitem"
                      aria-expanded="false"
                    >
                      <div className="overview hide-for-small">
                        <div
                          className={`favorite${
                            (i.L3Extra?.IsFavorite || addFav.includes(i.Id)) &&
                            !delFav.includes(i.Id)
                              ? ' is-favorite'
                              : ''
                          }`}
                        >
                          <button
                            onClick={() =>
                              handleFav(
                                i.Id,
                                (i.L3Extra.IsFavorite ||
                                  addFav.includes(i.Id)) &&
                                  !delFav.includes(i.Id),
                              )
                            }
                            className="btn-favorite"
                          />
                        </div>
                        <h5 className="l3-ref" data-action="list-expand">
                          {i.Reference}
                        </h5>
                        <div className="l3-description">{i.Name}</div>
                        <div className="l3-responsible">{i.Responsible}</div>
                        <div className="button-container">
                          <a
                            href="#"
                            className="list-expand"
                            onClick={(e) => {
                              e.preventDefault();
                              setExpanded((x) => {
                                if (x.includes(i.Id))
                                  return x.filter((xx) => xx !== i.Id);
                                return [...x, i.Id];
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="details"
                        style={{
                          height: expanded.includes(i.Id) ? 'unset' : 0,
                        }}
                      >
                        <div className="details-wrapper">
                          <div className="flex-row margin">
                            <div className="l3-details">
                              <p>
                                <strong>Utdyping</strong>
                              </p>
                              <p
                                dangerouslySetInnerHTML={{ __html: i.Details }}
                              />
                            </div>
                            <div className="l3-example">
                              <p>
                                <strong>Eksempel</strong>
                              </p>
                              <p
                                dangerouslySetInnerHTML={{ __html: i.Example }}
                              />
                            </div>
                          </div>
                          <div className="flex-row">
                            <L3Tags item={i} />
                            <L3Comment item={i} />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          );
        })}
    </div>
  );
};

export default L3Items;
