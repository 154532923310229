import { applyMiddleware, legacy_createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { composeWithDevToolsLogOnlyInProduction } from '@redux-devtools/extension';

import sagas from "./sagas";
import reducers from "./reducers";
import { DeliberateAny } from "./types/DelibrateAny";

const composeEnhancers = composeWithDevToolsLogOnlyInProduction({});
const sagaMiddleware: DeliberateAny = createSagaMiddleware();

const store = legacy_createStore(
  reducers as DeliberateAny,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(sagas);

export default store;
