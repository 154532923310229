// @flow

import { useState, useEffect } from 'react';
import Link from 'components/Link/Link';
import { arenainfoURL } from '../../api/booking/booking_api';
import useAxios from '../../hooks/useAxios';
import { useModal } from 'contexts/index';

type PropsType = {
  arenaId?: string;
  arenaName?: string;
};

const ArenaInfo = ({ arenaId, arenaName }: PropsType) => {
  const { requestLoading, requestError, sendRequest } = useAxios();
  const [html, setHtml] = useState();
  const { showModal } = useModal();

  useEffect(() => {
    if (arenaId) {
      fetchArenaInfo();
    }
  }, []);

  const fetchArenaInfo = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: arenainfoURL,
        params: { locationId: arenaId },
      },
      setHtml,
    );
  };

  const getLinkText = () => `Les mer om ${arenaName}`;

  const openArena = (event) => {
    event.preventDefault();
    showModal({
      size: 'large',
      html,
    });
  };

  return (
    <div>
      {arenaId && arenaName && (
        <div>
          <p>
            <Link
              to={getLinkText()}
              // disabled={requestLoading || requestError}
              onClick={openArena}
            >
              {getLinkText()}
            </Link>
          </p>
        </div>
      )}
    </div>
  );
};

export default ArenaInfo;
