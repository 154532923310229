import Loader from 'components/Loader/Loader';
import SafeHtmlRenderer from 'components/SafeHtmlRenderer';
import { useModal } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import { ReactNode, useEffect, useState } from 'react';

import Modal, { ModalSize } from '../Modal/Modal';

type PropsType = {
  url: string;
  isOpen: boolean;
  size?: ModalSize;
  selector?: string;
};

export const getContentBySelector = (
  htmlString: string,
  cssSelector: string,
) => {
  const parser = new DOMParser();

  const doc = parser.parseFromString(htmlString, 'text/html');

  const element = doc.querySelector(cssSelector);

  if (element) {
    return element.innerHTML;
  } else {
    return null;
  }
};

const GetModal = (props: PropsType) => {
  const { close } = useModal();
  const { sendRequest, requestLoading } = useAxios();
  const [html, setHtml] = useState<string>();

  const fetchTheUrl = async () => {
    await sendRequest(
      {
        url: props.url,
      },
      (result) => {
        setHtml(
          props.selector
            ? getContentBySelector(result, props.selector)
            : result,
        );
      },
    );
  };

  useEffect(() => {
    fetchTheUrl();
  }, []);

  return (
    <Modal isOpen={props.isOpen} onClose={close} size={props.size}>
      <div style={{ minHeight: html ? 500 : 200, paddingRight: 10 }}>
        <Loader isLoading={requestLoading}>
          <div className="dddd">
            <SafeHtmlRenderer response={html} />{' '}
          </div>
        </Loader>
      </div>
    </Modal>
  );
};

export default GetModal;
