import './AlertMessage.scss';

import {
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import { FC, ReactNode } from 'react';

export enum AlertType {
  Info,
  Success,
  Warning,
  Error,
  Special1,
}

interface IAlertMessageProps {
  children: ReactNode | ReactNode[];
  variant?: AlertType;
  className?: string;
  list?: boolean;
}

const AlertMessage: FC<IAlertMessageProps> = ({
  children,
  variant,
  className,
  list,
}) => {
  const icon =
    variant === AlertType.Success
      ? SuccessIcon
      : variant === AlertType.Warning
        ? WarningIcon
        : variant === AlertType.Error
          ? ErrorIcon
          : InfoIcon;
  return (
    <div
      className={`fb-alert-message alert-${variant !== undefined ? AlertType[variant] : 'Info'} ${className ?? ''}`}
    >
      <div className="alert-icon">
        <BasenIcon icon={icon} />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default AlertMessage;
