import {
  arenaByIdUrl,
  bookingByIdUrl,
  bookingOptionsUrl,
} from 'api/booking/booking_api_v2';
import useAxios from 'hooks/useAxios';
import {
  createContext,
  FC,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IWrapper } from 'src/interfaces/IWrapper';
import { GetBookingResponse } from 'types/bookingRequests';
import { GetArenaInformationResponse } from 'types/BookingTypes/arenaTypes';
import { BookingOptions } from './BookingContextNew';
import { AxiosError } from 'axios';
import { useToast } from '.';
import { ToastType } from './ToastContext';

export interface MyPageContextState {
  bookingOptions?: BookingOptions;
  bookingItem?: GetBookingResponse;
  arenaInfo?: GetArenaInformationResponse;
}

export const MyPageContext = createContext({} as MyPageContextState);

export const MyPageContextProvider: FC<IWrapper> = ({ children }) => {
  const { sendRequest } = useAxios();
  const { bookingid } = useParams();
  const navigate = useNavigate();
  const { createToastMessage } = useToast();

  const [bookingOptions, setBookingOptions] = useState<BookingOptions>();

  const [bookingItem, setBookingItem] = useState<GetBookingResponse>();
  const [arenaInfo, setArenaInfo] = useState<GetArenaInformationResponse>();
  const [currentLocation, setCurrentLocation] = useState<string>('');

  const fetchBookingOptions = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: bookingOptionsUrl,
      },
      (response) => setBookingOptions(response),
    );
  };

  const fetchBooking = async () => {
    if (bookingid)
      await sendRequest(
        {
          method: 'GET',
          url: bookingByIdUrl(bookingid),
        },
        setBookingItem,
        null,
        (error: AxiosError) => {
          if (error.status === 404) {
            createToastMessage('Fant ikke overnattingen', ToastType.Error);
            navigate(`/mypage/booking`);
          }
        },
      );
  };

  const fetchArenaInformation = async () => {
    if (bookingItem) {
      if (bookingItem.locationId !== currentLocation) {
        setCurrentLocation(bookingItem.locationId);
        await sendRequest(
          { method: 'GET', url: arenaByIdUrl(bookingItem.locationId) },
          (response: GetArenaInformationResponse) => {
            setArenaInfo(response);
          },
        );
      }
    }
  };

  useEffect(() => {
    fetchArenaInformation();
  }, [bookingItem]);

  useEffect(() => {
    fetchBooking();
  }, [bookingid]);

  useEffect(() => {
    fetchBookingOptions();
  }, []);

  const values = useMemo(
    () => ({
      bookingOptions,
      bookingItem,
      arenaInfo,
    }),
    [bookingOptions, bookingItem, arenaInfo],
  );

  return (
    <MyPageContext.Provider value={values}>
      <Fragment>{children}</Fragment>
    </MyPageContext.Provider>
  );
};
