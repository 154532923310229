import { alertMessageURL } from 'api/cms/cms_api';
import SafeHtmlRenderer from 'components/SafeHtmlRenderer';
import useAxios from 'hooks/useAxios';
import bolig_white from 'images//am_bolig-white.svg';
import meld_white from 'images//am_meldinnsak-white.svg';
import overnatt_white from 'images//am_overnatting-white.svg';
import { useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

const Landing = () => {
  const { sendRequest } = useAxios();
  const [alertMessage, setAlertMessage] = useState<DeliberateAny>(null);

  useEffect(() => {
    if (
      !(
        window.location.pathname !== '/' &&
        !window.location.pathname.includes('#')
      )
    ) {
      sendRequest(
        {
          method: 'GET',
          url: alertMessageURL,
          withCredentials: false,
        },
        (res) => {
          if (res && res.Heading) {
            setAlertMessage(res);
          } else {
            setAlertMessage(null);
          }
        },
      );

      document.querySelector('body')?.classList.add('is-landing');
    }
    return () => document.querySelector('body')?.classList.remove('is-landing');
  }, []);

  if (
    window.location.pathname !== '/' &&
    !window.location.pathname.includes('#')
  ) {
    window.location.replace(
      '/#' + window.location.pathname + window.location.search,
    );
    return null;
  }

  return (
    <div className="landingpage scrollable" data-role="content">
      {alertMessage && (
        <div className="operational-message">
          <div className="warning-message">
            <h2>{alertMessage.Heading}</h2>
            <SafeHtmlRenderer
              className="no-margin"
              response={alertMessage.MainBody}
            />
          </div>
        </div>
      )}
      <div className="wrapper no-border">
        <h1 className="hide-for-medium hide-for-small">
          Velkommen til Basen&#160;
        </h1>
        <h2 className="hide-for-medium hide-for-small">
          Tjenesteportalen for leietakere, beboere og gjester
        </h2>
        <ul role="toolbar" className="link-blocks">
          <li
            className=""
            data-load-content="forsvarsbolig"
            data-action="/#/forsvarsbolig"
            role="button"
          >
            <a href="/#/forsvarsbolig">
              <div className="icon">
                <img src={bolig_white} alt="" />
              </div>
              <div className="text hide-for-small">
                <div className="h3-wrapper">
                  <h3 className="hide-for-small">S&#248;k om forsvarsbolig</h3>
                </div>
                <p className="hide-for-small">
                  Som ansatt i Forsvaret kan du søke om å få tildelt bolig eller
                  kvarter dersom du blir beordret til et nytt tjenestested.
                </p>
              </div>
              <div className="text hide-for-medium hide-for-large">
                <h3 className="hide-for-medium hide-for-large">
                  Forsvarsbolig
                </h3>
                <p className="hide-for-medium hide-for-large">
                  Søk om å få tildelt bolig eller kvarter.
                </p>
              </div>
            </a>
          </li>

          <li
            className=""
            data-load-content="booking"
            data-action="/#/booking"
            role="button"
          >
            <a href="/#/booking">
              <div className="icon">
                <img src={overnatt_white} alt="" />
              </div>
              <div className="text hide-for-small">
                <div className="h3-wrapper">
                  <h3 className="hide-for-small">Trenger du overnatting?</h3>
                </div>
                <p className="hide-for-small">
                  Bestill og betal for kortidsovernatting enten du skal på
                  tjenestereise, kurs eller øvelse. Bestiller du gruppebooking
                  vil du eller din avdeling få tilsendt faktura.
                </p>
              </div>
              <div className="text hide-for-medium hide-for-large">
                <h3 className="hide-for-medium hide-for-large">Overnatting?</h3>
                <p className="hide-for-medium hide-for-large">
                  Bestill og betal for kortidsovernatting.
                </p>
              </div>
            </a>
          </li>

          <li
            className=""
            data-load-content="basen-proff"
            data-action="/#/basen-proff"
            role="button"
          >
            <a href="/#/basen-proff">
              <div className="icon">
                <img src={meld_white} alt="" />
              </div>
              <div className="text hide-for-small">
                <div className="h3-wrapper">
                  <h3 className="hide-for-small">Basen PROFF</h3>
                </div>
                <p className="hide-for-small">
                  Tjenester for deg som følger opp eiendom, bygg og anlegg på
                  vegne av din avdeling. (For eksempel EBA-koordinator)
                </p>
              </div>
              <div className="text hide-for-medium hide-for-large">
                <h3 className="hide-for-medium hide-for-large">Basen PROFF</h3>
                <p className="hide-for-medium hide-for-large">
                  For deg som følger opp eiendom, bygg og anlegg.
                </p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Landing;
