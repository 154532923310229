import TextArea from 'components/form/TextArea/TextArea';

import { ModalButtonType } from '../Modal/Modal';
import RouterModal from '../RouterModal/RouterModal';

type MessageType = {
  title: string;
  name: string;
  value?: string;
  onChange: (key: string, value: string) => void;
};

type PropsType = {
  path: string;
  title: string;
  submit: ModalButtonType;
  message: MessageType;
};

const MessageModal = (props: PropsType) => (
  <RouterModal
    size="medium"
    path={props.path}
    ariaLabel={props.title}
    title={props.title}
    submit={props.submit}
  >
    <div className="margin">
      <label>
        <span>{props.message.title}</span>
        <TextArea
          rows={6}
          className="margin"
          onChange={props.message.onChange}
          name={props.message.name}
          value={props.message.value || ""}
        />
      </label>
    </div>
  </RouterModal>
);

export default MessageModal;

// export default withRouter(MessageModal);
