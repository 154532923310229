import { forgotURL } from 'api/cms/cms_api';
import { validateEmail } from 'helpers/validate';
import useAxios from 'hooks/useAxios';
import DOMPurify from 'dompurify';
import { isHtml } from 'helpers/html';
import { useState } from 'react';

import BackButton from './BackButton';

const ForgotPassword = () => {
  const { sendRequest, requestError: requestError } = useAxios();
  const [email, setEmail] = useState<string>('');
  const [sent, setSent] = useState<boolean>(false);

  const handleForgotPassword = async (username?: string) => {
    await sendRequest(
      {
        url: forgotURL,
        method: 'POST',
        data: { username, html: false },
      },
      () => {
        setEmail('');
        setSent(true);
      },
    );
  };

  return (
    <>
      {!sent && (
        <div
          data-flip-id="reset-password"
          className="flip-card-front scrollable"
        >
          <BackButton />
          <h2>Glemt passord</h2>
          <div className="form">
            <div className="row">
              <label htmlFor="oldemail">Brukernavn</label>
            </div>
            <div className="row margin">
              <input
                type="text"
                placeholder="Ditt brukernavn"
                id="oldemail"
                name="oldemail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="invalid">
              {isHtml(requestError) ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(requestError),
                  }}
                />
              ) : (
                <p>{requestError}</p>
              )}
            </div>
            <p>
              Oppgi ditt brukernavn, og du vil motta en e-post med instruksjoner
              for hvordan du endrer passordet ditt.
            </p>
            <p>
              Brukernavnet er det du bruker når du logger på Basen (f.eks
              FOR-XXXXXX).
            </p>
            <button
              disabled={
                !email || (email?.includes('@') && !validateEmail(email))
              }
              className={
                !email || (email?.includes('@') && !validateEmail(email))
                  ? 'gray btn'
                  : 'btn red'
              }
              onClick={() => handleForgotPassword(email)}
            >
              Send
            </button>
          </div>
        </div>
      )}

      {sent && (
        <div className="flip-card-front scrollable">
          <BackButton />
          <div className="confirm">
            <h2>Epost sendt</h2>
            <div className="row">
              <p>Instruksjoner er sendt til deg på e-post.</p>
              <p>Velkommen til Basen!</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
