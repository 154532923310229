import './Tooltip.scss';

import PropTypes from 'prop-types';
import { useState } from 'react';

const Tooltip = ({ children, message, disabled, position, help }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  if (disabled) {
    return children;
  }

  return (
    <div
      className={`tooltip-container`}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {isVisible &&
        (position === 'top' ? (
          <div className={`tooltip top ${help ? 'help' : ''}`}>{message}</div>
        ) : position === 'bottom' ? (
          <div className={`tooltip bottom ${help ? 'help' : ''}`}>
            {message}
          </div>
        ) : position === 'left' ? (
          <div className={`tooltip left ${help ? 'help' : ''}`}>{message}</div>
        ) : position === 'right' ? (
          <div className={`tooltip right ${help ? 'help' : ''}`}>{message}</div>
        ) : (
          <div className={`tooltip center ${help ? 'help' : ''}`}>
            {message}
          </div>
        ))}
    </div>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'center']),
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  message: PropTypes.string.isRequired,
  help: PropTypes.bool,
};
