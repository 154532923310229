// @ flow

export const MAX_SERVICE_ORDERS_TITLE_LENGTH = 60;
export const ADMIN_SERVICE_ORDER_TYPE = 'Administrativ serviceordre';
export const FAULT_SERVICE_ORDER_TYPE = 'Feilmelding';
export const PROPERTY_SERVICE_ORDER_TYPE = 'Eiendomsinformasjon';

export const trimTitle = (title) => {
  if (title.length > MAX_SERVICE_ORDERS_TITLE_LENGTH) {
    const suffix = '...';
    return `${title.substring(0, MAX_SERVICE_ORDERS_TITLE_LENGTH - suffix.length)}${suffix}`;
  }
  return title;
};
