import './Badge.scss';

import { AlertType } from 'components/AlertMessage/AlertMessage';
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import { FC } from 'react';

interface IBadgeProps {
  variant?: AlertType;
  showIcon?: boolean;
  children: string;
  className?: string;
}

const Badge: FC<IBadgeProps> = ({ children, variant, showIcon, className }) => {
  const icon =
    variant === AlertType.Success
      ? SuccessIcon
      : variant === AlertType.Warning
        ? WarningIcon
        : variant === AlertType.Error
          ? ErrorIcon
          : InfoIcon;

  return (
    <div
      className={`fb-badge badge-${variant ? AlertType[variant] : 'Info'} ${className ?? ''}`}
    >
      {showIcon && <BasenIcon size={20} icon={icon} />} {children}
    </div>
  );
};

export default Badge;
