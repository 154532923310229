import { parseCoordinates } from 'helpers/maps';

import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 61.054191,
  lng: 9.964194,
};

type MainImage = {
  Description: string;
  AltText: string;
  Url: string;
};

type Room = {
  RoomTypeId: string;
  Name: string;
  Heading: string;
  RoomDescription: string;
  RoomFacilities: string;
  RoomIcon: MainImage;
  RoomImages: MainImage[];
  StandardCapacity: number;
  ExtraCapacity: number;
  MaxRentalPeriod: number;
  VBRoomTypeId: number;
};

export type MapLocation = {
  LocationId: string;
  Name: string;
  MainImage: MainImage;
  MainIntro: string;
  Region: string;
  LocationFacilities: any[];
  ClosurePeriods: any[];
  Rooms: Room[];
  Tags: string[];
  Coordinates: string;
  NotOpenForBooking: boolean;
  SubLocations: any[];
  PayAtArrivalEnabled: boolean;
  VBUseIntegration: boolean;
  VBCompanyId: number;
};

type IMapLocationSelectorProps = {
  selectedLocation: MapLocation | null;
  setSelectedLocation: React.Dispatch<React.SetStateAction<MapLocation | null>>;
  locations: MapLocation[];
};

const MapLocationSelector: React.FC<IMapLocationSelectorProps> = ({
  selectedLocation,
  setSelectedLocation,
  locations,
}) => {
  const handleMarkerClick = (location: MapLocation) => {
    setSelectedLocation(location);
  };

  return (
    <div>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={6}>
        {locations.map((location) => {
          const lanlon = parseCoordinates(location.Coordinates);
          if (!lanlon) return null;
          return (
            <Marker
              key={location.LocationId}
              position={lanlon}
              onClick={() => handleMarkerClick(location)}
            />
          );
        })}
        {selectedLocation && parseCoordinates(selectedLocation.Coordinates) && (
          <InfoWindow
            position={parseCoordinates(selectedLocation.Coordinates)}
            onCloseClick={() => setSelectedLocation(null)}
          >
            <div>
              <h3>{selectedLocation.Name}</h3>
              <p>{selectedLocation.MainIntro}</p>
              {selectedLocation.MainImage && (
                <img
                  src={selectedLocation.MainImage.Url}
                  alt={selectedLocation.MainImage.AltText}
                  style={{ width: '100px', height: 'auto' }}
                />
              )}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
};

export default MapLocationSelector;
