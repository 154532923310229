import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';

import {
    dataOptionsURL, getHousingTextsURL, housingServiceAreasURL
} from '../api/housing/housing_api';
import useAxios from '../hooks/useAxios';
import { ContextTexts } from '../static';
import { useUser } from './';

export const HousingContext = createContext({});

export enum HousingQuarter {
  Housing = 'housing',
  Quarter = 'quarter',
}

export const HousingContextProvider = ({ children }) => {
  const { user } = useUser();
  const location = useLocation();
  const { requestLoading: loading, sendRequests } = useAxios();
  const [dataOptions, setDataOptions] = useState();
  const [housingTexts, setHousingTexts] = useState();
  const [housingServiceArea, setHousingServiceArea] = useState();
  const [applicationType, setApplicationType] = useState<HousingQuarter>(
    HousingQuarter.Housing,
  );
  const [applicaton, setApplication] = useState<DeliberateAny>({});

  const fetchAll = async () => {
    const requests = [
      {
        axiosParams: {
          method: 'GET',
          url: housingServiceAreasURL,
          withCredentials: true,
          params: {
            applicationType: 'housing',
          },
        },
        applyData: (x) => {
          setHousingServiceArea(x);
        },
      },
      {
        axiosParams: {
          method: 'GET',
          url: getHousingTextsURL,
        },
        applyData: (x) => {
          setHousingTexts(x);
        },
      },
      {
        axiosParams: {
          method: 'GET',
          url: dataOptionsURL,
        },
        applyData: (x) => {
          setDataOptions(x);
        },
      },
    ];
    sendRequests(requests, user);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('kvarter-'))
      setApplicationType(HousingQuarter.Quarter);
    else setApplicationType(HousingQuarter.Housing);
  }, [location]);

  const values = useMemo(
    () => ({
      loading,
      dataOptions,
      housingTexts,
      housingServiceArea,
      applicaton,
      applicationType,
      setApplication,
    }),
    [
      loading,
      dataOptions,
      housingTexts,
      housingServiceArea,
      applicaton,
      applicationType,
    ],
  );

  return (
    <HousingContext.Provider value={values}>{children}</HousingContext.Provider>
  );
};

export const useHousingContext = () => {
  const context = useContext(HousingContext);
  if (context === undefined) {
    throw new Error(ContextTexts.OutOfContextText('HousingContext'));
  }
  return context;
};
