// @flow

import '../../PortfolioColumns.scss';

import List from "components/List/List";
import { Component } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import { signableDocumentsColumns } from '../../PortfolioColumns';
import SignableDocumentItem from './SignableDocumentItem';

import type { SignableDocumentType } from "types/signableDocumentsTypes";
type PropsType = {
  items: Array<SignableDocumentType>;
};

class SignableDocumentsList extends Component {
  declare props: PropsType;

  renderItem = (item: DeliberateAny) => (
    <SignableDocumentItem
      key={item.InventoryNumber}
      item={item}
      columns={signableDocumentsColumns}
    />
  );

  render() {
    return (
      <div className="portfolio-columns">
        <List
          columns={signableDocumentsColumns as DeliberateAny}
          renderItem={this.renderItem}
          noItemsMessage={<p>Ingen dokumenter til signering</p>}
          {...this.props}
        />
      </div>
    );
  }
}

export default SignableDocumentsList;
