import './UnnamedRoomSelect.scss';

import { Dispatch, FC, ReactNode, useEffect } from 'react';
import dobbeltrom from 'src/assets/img/dobbeltrom.svg';
import enkeltrom from 'src/assets/img/enkeltrom.svg';
import { Availability } from 'src/pages/UnnamedBooking/UnnamedBooking';

interface IUnnamedRoomSelect {
  rooms?: Availability[];
  selected?: number;
  setSelected: Dispatch<React.SetStateAction<number>>;
}

const UnnamedRoomSelect: FC<IUnnamedRoomSelect> = ({
  rooms,
  selected,
  setSelected,
}) => {
  const imageSrc = (roomType: string): ReactNode => {
    const imgSrc = roomType === 'Enkeltrom' ? enkeltrom : dobbeltrom;
    return <img className="room-img" src={imgSrc} />;
  };

  useEffect(() => {
    if (rooms && rooms.length === 1) {
      setSelected(rooms[0].roomtypeId);
    }
  }, [rooms]);

  return (
    <div className="room-select">
      <strong>Velg romtype</strong>
      {rooms?.map((room) => (
        <div
          key={room.roomtypeId}
          className={`room-btn ${selected === room.roomtypeId ? 'room-selected' : ''}`}
          onClick={() =>
            setSelected((x: number) => {
              return x === room.roomtypeId ? -1 : room.roomtypeId;
            })
          }
        >
          {imageSrc(room.roomtypeDescription)}
          {room.roomtypeDescription}{' '}
          <span className="room-count">{`(${room.availabilityCount} ledig${room.availabilityCount > 1 ? 'e' : ''})`}</span>
        </div>
      ))}
    </div>
  );
};

export default UnnamedRoomSelect;
