import './MyBooking.scss';

import {
  bookingChangeConfirmUrl,
  ConfirmType,
  payByInvoiceUrl,
  payByIoUrl,
  payByProviderUrl,
} from 'api/booking/booking_api_v2';
import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import PaymentInfo from 'components/BookingComponents/PaymentInfo';
import CountDown from 'components/CountDown/CountDown';
import Loader from 'components/Loader/Loader';
import { ChangeRequestResponse } from 'components/ModalComponents/ChangeBooking/ChangeBooking';
import Modal, { ModalSize } from 'components/ModalComponents/Modal';
import { IPaymentInfo } from 'contexts/BookingContextNew';
import { useMyPage } from 'contexts/index';
import { addIFrameToBody } from 'helpers/paymentHelpers';
import useAxios from 'hooks/useAxios';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeliberateAny, SetState } from 'types/DelibrateAny';

import ConfirmWithInvoiceForm, {
  IInvoiceClaim,
} from './ConfirmWithInvoiceForm';
import ConfirmWithIoForm, { IIoDepartment } from './ConfirmWithIoForm';

enum PaymentMethod {
  NotSelected = 1,
  PayByProvider = 2,
  Invoice = 3,
  PurchaseOrder = 4,
  SalaryDeduction = 5,
}

const mapPaymentMethodIdToPaymentMethodValue = (
  paymentMethodId: PaymentMethod,
) => {
  if (paymentMethodId == PaymentMethod.NotSelected) {
    return 'Later';
  } else if (paymentMethodId == PaymentMethod.PayByProvider) {
    return 'Provider';
  } else if (paymentMethodId == PaymentMethod.Invoice) {
    return 'Invoice';
  } else if (paymentMethodId == PaymentMethod.PurchaseOrder) {
    return 'IO';
  } else {
    return '';
  }
};

const MyBookingChangePaymentModal = ({
  showModal,
  setShowModal,
  changeRequestResponse,
  purpose,
  description,
  title,
  onClose,
}: {
  showModal: boolean;
  setShowModal: SetState<boolean>;
  changeRequestResponse?: ChangeRequestResponse;
  purpose?: string;
  description?: string;
  onClose?: () => void;
  title?: string;
}) => {
  const { bookingItem, bookingOptions } = useMyPage();
  const { sendRequest, requestLoading } = useAxios();
  const { bookingid } = useParams();

  const [paymentLoading, setPaymentIsLoading] = useState<boolean>(true);

  const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo>({
    purposeOption: purpose ?? '',
    purposeDescription: description ?? '',
    paymentOption: '',
    smsOption: '',
  });

  const [filteredBookingOptions, setFilteredBookingOptions] =
    useState<DeliberateAny>();

  useEffect(() => {
    if (bookingOptions) {
      const newBookingOptions = JSON.parse(JSON.stringify(bookingOptions));
      newBookingOptions.availablePaymentOptions =
        newBookingOptions.availablePaymentOptions.filter(
          (option) =>
            option.identity !==
            bookingOptions.availablePaymentOptions[1].identity,
        );
      setFilteredBookingOptions(newBookingOptions);
    }
  }, [bookingOptions]);

  const submitChange = async () => {
    await changeBookingProvider();
    setShowModal(false);
  };

  const [ioDepartment, setIoDepartment] = useState<IIoDepartment>({
    department: '',
    ioNumber: '',
  });
  const [invoiceOptions, setInvoiceOptions] = useState<IInvoiceClaim>({
    orgNumber: '',
    orgName: '',
    invoiceAddress: '',
    postalCode: '',
    postalCity: '',
    invoiceResponsible: '',
  } as IInvoiceClaim);

  useEffect(() => {
    if (bookingItem?.paymentInfo.purchaseOrderInfo) {
      setIoDepartment(bookingItem.paymentInfo.purchaseOrderInfo);
    } else if (bookingItem?.paymentInfo.invoiceInfo) {
      setInvoiceOptions({
        orgNumber: bookingItem.paymentInfo.invoiceInfo.orgNumber,
        orgName: '',
        invoiceAddress: bookingItem.paymentInfo.invoiceInfo.invoiceAddress,
        postalCode: bookingItem.paymentInfo.invoiceInfo.postalCode,
        postalCity: '',
        invoiceResponsible:
          bookingItem.paymentInfo.invoiceInfo.invoiceResponsible,
      });
    }
  }, [bookingItem]);

  useEffect(() => {
    if (!paymentLoading) {
      location.reload();
    }
  }, [paymentLoading]);

  const changeBookingProvider = async () => {
    switch (paymentInfo.paymentOption) {
      case bookingOptions?.availablePaymentOptions[0].identity:
        // Provider
        await changeToProvider();
        break;
      case bookingOptions?.availablePaymentOptions[2].identity:
        // IO
        await changeToIo(
          ioDepartment.ioNumber,
          ioDepartment.department.toString(),
        );
        break;
      case bookingOptions?.availablePaymentOptions[3].identity:
        // Invoice
        await changeToInvoice(
          invoiceOptions.orgNumber,
          invoiceOptions.orgName,
          invoiceOptions.invoiceAddress,
          invoiceOptions.postalCode,
          invoiceOptions.postalCity,
          invoiceOptions.invoiceResponsible,
        );
        break;
      default:
        break;
    }
  };

  const changeToProvider = async () => {
    if (changeRequestResponse) {
      await sendRequest(
        {
          method: 'POST',
          url: bookingChangeConfirmUrl(
            changeRequestResponse.changeRequestId,
            ConfirmType.Provider,
          ),
          data: {
            purpose: paymentInfo.purposeOption,
            description: paymentInfo.purposeDescription,
          },
        },
        (res) => {
          addIFrameToBody(res.paymentComponentSource, setPaymentIsLoading);
        },
      );
    } else {
      await sendRequest(
        {
          method: 'POST',
          url: payByProviderUrl(bookingid!),
        },
        (res) => {
          addIFrameToBody(res.paymentComponentSource, setPaymentIsLoading);
        },
      );
    }
  };

  const changeToIo = async (
    purchaseOrderNumber: string,
    department: string,
  ) => {
    if (changeRequestResponse) {
      await sendRequest(
        {
          method: 'POST',
          url: bookingChangeConfirmUrl(
            changeRequestResponse.changeRequestId,
            ConfirmType.Io,
          ),
          data: {
            purpose: paymentInfo.purposeOption,
            description: paymentInfo.purposeDescription,
            ioNumber: purchaseOrderNumber,
            department: department,
          },
        },
        (res) => {
          location.reload();
        },
      );
    } else {
      await sendRequest(
        {
          method: 'POST',
          url: payByIoUrl(bookingid!),
          data: {
            purchaseOrderNumber: purchaseOrderNumber,
            department: department,
          },
        },
        (res) => {
          location.reload();
        },
      );
    }
  };

  const changeToInvoice = async (
    orgNumber: string,
    orgName: string,
    invoiceAddress: string,
    postalCode: string,
    postalCity: string,
    invoiceReponsible: string,
  ) => {
    if (changeRequestResponse) {
      await sendRequest(
        {
          method: 'POST',
          url: bookingChangeConfirmUrl(
            changeRequestResponse.changeRequestId,
            ConfirmType.Invoice,
          ),
          data: {
            purpose: paymentInfo.purposeOption,
            description: paymentInfo.purposeDescription,
            orgNumber: orgNumber,
            orgName: orgName,
            invoiceAddress: invoiceAddress,
            postalCode: postalCode,
            postalCity: postalCity,
            invoiceResponsible: invoiceReponsible,
          },
        },
        (res) => {
          location.reload();
        },
      );
    } else {
      await sendRequest(
        {
          method: 'POST',
          url: payByInvoiceUrl(bookingid!),
          data: {
            orgNumber: orgNumber,
            orgName: orgName,
            invoiceAddress: invoiceAddress,
            postalCode: postalCode,
            postalCity: postalCity,
            invoiceResponsible: invoiceReponsible,
          },
        },
        (res) => {
          location.reload();
        },
      );
    }
  };

  const renderAdditionalForm = () => {
    // IO
    if (
      paymentInfo.paymentOption ===
      bookingOptions?.availablePaymentOptions[2].identity
    ) {
      return (
        <ConfirmWithIoForm
          bookingError={{}}
          bookingOptions={bookingOptions}
          ioDepartment={ioDepartment}
          setIoDepartment={setIoDepartment}
        />
      );
    }
    // Invoice
    if (
      paymentInfo.paymentOption ===
      bookingOptions?.availablePaymentOptions[3].identity
    ) {
      return (
        <ConfirmWithInvoiceForm
          bookingError={{}}
          bookingOptions={bookingOptions}
          invoiceOptions={invoiceOptions}
          setInvoiceOptions={setInvoiceOptions}
        />
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (showModal && bookingItem) {
      setPaymentInfo((prevState) => ({
        ...prevState,
        purposeOption: bookingItem.purpose ?? '',
        purposeDescription: bookingItem.description ?? '',
        paymentOption: mapPaymentMethodIdToPaymentMethodValue(
          bookingItem.paymentInfo.paymentMethodId,
        ),
      }));
    }
  }, [showModal, bookingItem]);

  return (
    <Fragment>
      {showModal && (
        <Modal
          loadingSubmit={requestLoading}
          header={title ?? 'Velg betalingsmåte'}
          className="fit-content-modal"
          content={
            <Loader isLoading={requestLoading}>
              {changeRequestResponse && (
                <AlertMessage variant={AlertType.Info}>
                  {
                    <span>
                      <CountDown
                        targetDate={changeRequestResponse.confirmationDeadline}
                      />
                      {' for å bekrefte betalingsmåte.'}
                    </span>
                  }
                </AlertMessage>
              )}
              <div
                className="fb-booking-summary-modal"
                style={{ marginTop: changeRequestResponse ? 10 : 0 }}
              >
                <PaymentInfo
                  bookingOptions={filteredBookingOptions}
                  bookingError={{}}
                  paymentInfo={paymentInfo}
                  setPaymentInfo={setPaymentInfo}
                />
                {renderAdditionalForm()}
              </div>
            </Loader>
          }
          onClose={() => setShowModal(false)}
          showCancelButton={true}
          onSubmit={() => submitChange()}
          overrideSubmitButtonText="Velg betalingsmåte"
        />
      )}
    </Fragment>
  );
};

export default MyBookingChangePaymentModal;
