// @flow

import { call, CallEffect, put, PutEffect } from "redux-saga/effects";
import { DeliberateAny } from "types/DelibrateAny";

import {
  auditEntriesURL,
  bookeeProfileByIdURL,
  locationsURL,
  searchURL,
} from "../api/booking/booking_api";
import { axiosFetch } from "../hooks/useAxios";

function* fetchLocationReservation(): Generator<
  CallEffect | PutEffect,
  void,
  void
> {
  const url = locationsURL;
  try {
    const data = yield call(axiosFetch, { url });
    yield put({ type: "RECEIVE_RESERVATON_LOCATIONS", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* fetchReservationSearch(
  action: DeliberateAny
): Generator<DeliberateAny, void, void> {
  const url = searchURL;
  const query = action.filter;
  try {
    const data = yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({ type: "RECEIVE_RESERVATON_ITEMS", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* fetchBookerProfileId(
  action: DeliberateAny
): Generator<DeliberateAny, void, void> {
  const url = bookeeProfileByIdURL;
  const query = {
    BookeeID: action.value,
  };
  try {
    const data = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_RESERVATON_PROFILE_ID_DATA", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* fetchAuditEntries(
  action: DeliberateAny
): Generator<DeliberateAny, void, void> {
  const url = auditEntriesURL;
  const query = {
    requestId: action.value,
  };
  try {
    const data = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_RESERVATON_AUDIT_ENTRIES", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

export {
  fetchReservationSearch,
  fetchLocationReservation,
  fetchBookerProfileId,
  fetchAuditEntries,
};
