import {
    fetchHousingAndServiceAreas, fetchHousingInfoText, updateAllApplicationErrors,
    updateApplication, updateSingleApplicationError
} from 'actions/housingActions';
import { fetchHousingDataOptions } from 'actions/optionsActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import UnclassifiedStamp from 'components/form/UnclassifiedStamp/UnclassifiedStamp';
import HelpText from 'components/HelpText/HelpText';
import Loader from 'components/Loader/Loader';
import Modal from 'components/modals/Modal/Modal';
import DOMPurify from 'dompurify';
import connect from 'helpers/connectWithRouter';
import _ from 'lodash/fp';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropSlideNavigation } from 'types/PropNavigationBase';
import { getSiblingPath } from 'utils/helpers';
import * as yup from 'yup';

import Checkbox from '../../components/form/Checkbox/Checkbox';
import { tryParseJson } from '../../helpers/json';

import type {
  HousingApplicationType,
  InfoTextType,
  QuarterApplicationType,
  CategoryType,
} from 'types/housingTypes';

type PropsType = PropSlideNavigation & {
  fetchHousingDataOptions: () => void;
  housingDataOptionsLoaded: boolean;
  applicationType: string;
  applicantCategories: CategoryType;
  application: HousingApplicationType | QuarterApplicationType;
  infoText: InfoTextType;
  housingInfoTextLoaded: boolean;
  isLoading: boolean;
  updateApplication: (name: string, value: boolean | number | string) => void;
  validatorConfig: DeliberateAny;
  updateAllApplicationErrors: (errors: object) => void;
  updateSingleApplicationError: (name: string, errors: object) => void;
  fetchHousingInfoText: () => void;
  fetchHousingAndServiceAreas: (applicationType?: string) => void;
  errors: DeliberateAny;
  FAISSWarningHtml: string;
  sumibWarning: string;
  nextSlidePath: string;
};

class UnconnectedApplicantCategory extends Component {
  declare props: PropsType;

  state: {
    warningModalOpen: boolean;
    warningModalLabel: string;
    selectedWarning: number | null;
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      warningModalOpen: false,
      warningModalLabel: 'Ingen label',
      selectedWarning: null,
    };
    this.props.fetchHousingDataOptions();
    this.props.fetchHousingInfoText();
    this.props.fetchHousingAndServiceAreas();
  }

  onSubmit = () => {
    this.props.goToSlide(
      getSiblingPath(this.props.location.pathname, this.props.nextSlidePath),
    );
  };

  onChange = (name, value) => {
    this.props.updateApplication(name, value);
    // 5 is the value for 'Forsvarsansatt i spesiell situasjon' and should fire a modal if selected
    if (value === 5) {
      this.setState({
        warningModalLabel: 'Forsvarsansatt i spesiell situasjon',
        warningModalOpen: !this.state.warningModalOpen,
        selectedWarning: 5,
      });
      // 9 is the value for 'Søkere utenfor målgruppen ihht. boligbestemmelsene'
    } else if (value === 9) {
      this.setState({
        warningModalLabel: 'Søkere utenfor målgruppen ihht. boligbestemmelsene',
        warningModalOpen: !this.state.warningModalOpen,
        selectedWarning: 9,
      });
    }
  };

  onCheck = (name, value) => {
    this.props.updateApplication(name, !value ? null : value);
  };

  getCategories = () => {
    const output = this.props.applicantCategories.map(
      ({ label, value, info }) => {
        const jsonValue = tryParseJson(label.toString());
        return {
          label: jsonValue ? jsonValue.text : label,
          value,
          info: this.props.infoText[jsonValue ? jsonValue.info : info],
        };
      },
    );

    return output;
  };

  closeModal = () => {
    this.setState({
      warningModalOpen: !this.state.warningModalOpen,
    });
  };

  validationSchema = yup
    .object({
      applicantCategory: yup.string().required('required'),
      confirm: yup.boolean().when('$this.props.infoText', {
        is: () => !!this.props.infoText?.ResponsibleForInformationText,
        then: (schema) => schema.oneOf([true], 'required').required('required'),
      }),
    })
    .defined();

  render() {
    const formProps = {
      errors: this.props.errors,
      form: {
        ...this.props.application,
      },
      onSubmit: this.onSubmit,
      onChange: this.props.updateApplication,
      onCheck: this.props.updateApplication,
      updateAllValidationErrors: this.props.updateAllApplicationErrors,
      updateOneValidationError: this.props.updateSingleApplicationError,

      schema: this.validationSchema,
      schemaContext: {
        ResponsibleForInformationText: !_.isEmpty(
          this.props.infoText.ResponsibleForInformationText,
        ),
      },
    };

    const applicationText =
      this.props.applicationType === 'housing' ? ' bolig.' : ' kvarter.';
    const warningText = [5, 6].includes(this.state.selectedWarning ?? 0)
      ? this.props.FAISSWarningHtml
      : this.props.sumibWarning;
    const isLoading =
      this.props.isLoading ||
      !this.props.housingDataOptionsLoaded ||
      !this.props.housingInfoTextLoaded;

    return (
      <Loader isLoading={isLoading}>
        <UnclassifiedStamp />
        <h1>Målgruppe</h1>

        <HelpText helpText={this.props.infoText.PrerequisitesForAllocationInfo}>
          <p>
            Kun personell i følgende kategorier kan søke {`${applicationText}`}
          </p>
        </HelpText>

        <FormWrapper {...formProps}>
          {!_.isEmpty(this.props.applicantCategories) && (
            <RadioButtonGroup
              legend="Velg den som passer"
              options={this.getCategories()}
              onChange={this.onChange}
              name="applicantCategory"
              checkedOption={this.props.application.applicantCategory ?? '-1'}
              errors={this.props.errors}
            />
          )}
          <div className="margin">
            <ButtonRow>
              <ButtonRow.Right color="red" type="submit">
                Neste
              </ButtonRow.Right>
            </ButtonRow>
          </div>
          {!_.isEmpty(this.props.applicantCategories) &&
            !_.isEmpty(this.props.infoText.ResponsibleForInformationText) && (
              <Checkbox
                onChange={this.onCheck}
                name={'confirm'}
                label={this.props.infoText.ResponsibleForInformationText}
                data-testid="input-application-confirm-responsible"
                errors={this.props.errors}
                checked={this.props.application?.confirm ?? false}
              />
            )}
        </FormWrapper>
        <Modal
          size="medium"
          ariaLabel={this.state.warningModalLabel}
          isOpen={this.state.warningModalOpen}
          onClose={this.closeModal}
          cancel={{
            hide: true,
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(warningText),
            }}
          />
        </Modal>
      </Loader>
    );
  }
}

export default connect(
  (state: DeliberateAny, props: PropsType) => ({
    isLoading: state.housing.isLoading,
    errors: state.housing.errors,
    application: state.housing[`${props.applicationType}Application`],
    infoText: state.housing.housingInfoText,
    housingInfoTextLoaded: state.housing.housingInfoTextLoaded,
    applicantCategories:
      state.options.housingDataOptions[`${props.applicationType}Categories`],
    housingDataOptionsLoaded: state.options.housingDataOptionsLoaded,
    FAISSWarningHtml: state.options.housingDataOptions.FAISSWarningHtml,
    sumibWarning: state.options.housingDataOptions.SUMIBWarningHtml,
  }),
  (dispatch, props) =>
    bindActionCreators(
      {
        updateApplication: (...args: [string, string | boolean]) =>
          updateApplication(props.applicationType, ...args),
        updateAllApplicationErrors: (...args: [DeliberateAny]) =>
          updateAllApplicationErrors(props.applicationType, ...args),
        updateSingleApplicationError: (...args: [string, DeliberateAny]) =>
          updateSingleApplicationError(props.applicationType, ...args),
        fetchHousingDataOptions,
        fetchHousingInfoText,
        fetchHousingAndServiceAreas: () =>
          fetchHousingAndServiceAreas(
            window.location.hash.includes('kvarter-') ? 'quarter' : 'housing',
          ),
      },
      dispatch,
    ),
)(UnconnectedApplicantCategory);
