import { Moment } from "moment";
import { GuestItemType, GuestsFilterType } from "types/guardPageTypes";

import { DeliberateAny } from "../types/DelibrateAny";

function fetchLocationGuests(bookingId?: string) {
  return { type: "FETCH_LOCATION_GUESTS", bookingId };
}

function updateGuestsFilter(key: string, value: string | number | boolean) {
  let filterValue: string | number | boolean | undefined = value;
  if (value === -1) {
    filterValue = undefined;
  }
  return { type: "UPDATE_GUESTS_FILTER", key, value: filterValue };
}

export function updateGuestFilterMetaData(key: string, value: string) {
  return { type: "UPDATE_GUESTS_FILTER_METADATA", key, value };
}

function resetGuestsFilter() {
  return { type: "RESET_GUESTS_FILTER" };
}

function fetchGuestsSearch(filter: GuestsFilterType) {
  return { type: "FETCH_GUESTS_SEARCH", filter };
}

function setFromDate(date: Moment) {
  return { type: "SET_FROM_DATE", date };
}

function setToDate(date: Moment) {
  return { type: "SET_TO_DATE", date };
}

function fetchCheckInStatus() {
  return { type: "FETCH_CHECKIN_STATUS" };
}

function updateCheckInStatus(bookingId: string, value: string) {
  return { type: "UPDATE_CHECKIN_STATUS", value };
}

function fetchGuestsCheckinRules() {
  return { type: "FETCH_GUEST_CHECKIN_RULES" };
}

function fetchGuestsNewCheckinStatus(
  item: GuestsFilterType,
  filter: DeliberateAny
) {
  return { type: "FETCH_GUESTS_NEW_CHECKIN_STATUS", item, filter };
}

function fetchGuestsNewKey(item: GuestsFilterType, newKey: DeliberateAny) {
  return { type: "FETCH_GUESTS_NEW_KEY", item, newKey };
}

function updateGuestPhone(key: string, value: string | number | boolean) {
  let filterValue: string | number | boolean | undefined = value;
  if (value === -1) {
    filterValue = undefined;
  }
  return { type: "UPDATE_GUEST_PHONE", key, value: filterValue };
}

function sortGuestList(sorted: Array<GuestItemType>) {
  return { type: "SORT_GUEST_LIST", sorted };
}

function toggleGuestModal() {
  return { type: "MODAL_GUEST_CHECKED_INN" };
}

export {
  updateGuestPhone,
  updateCheckInStatus,
  fetchCheckInStatus,
  fetchLocationGuests,
  updateGuestsFilter,
  resetGuestsFilter,
  fetchGuestsSearch,
  setFromDate,
  setToDate,
  fetchGuestsCheckinRules,
  fetchGuestsNewCheckinStatus,
  fetchGuestsNewKey,
  sortGuestList,
  toggleGuestModal,
};
