import './LimitedBookingReceipt.scss';

import { limitedBookingUrl } from 'api/booking/booking_api_v2';
import Badge from 'components/Badge/Badge';
import Banner from 'components/Banner/Banner';
import Button from 'components/buttons/Button/Button';
import { formatFromToDate } from 'components/Summary/BookingSummary';
import Summary from 'components/Summary/Summary';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetLimitedBookingInfoResponse } from 'types/bookingRequests';

const LimitedBookingReceipt = () => {
  const nav = useNavigate();
  const { bookingId } = useParams();
  const { sendRequest, requestLoading } = useAxios();
  const [limited, setLimited] = useState<GetLimitedBookingInfoResponse>();

  const fetchLimitedItem = async () => {
    if (bookingId)
      await sendRequest(
        {
          method: 'GET',
          url: limitedBookingUrl(bookingId),
        },
        setLimited,
        null,
        () => {
          nav('/');
        },
      );
  };

  useEffect(() => {
    if (bookingId) fetchLimitedItem();
  }, [bookingId]);

  return (
    <>
      <Banner>
        <h1 className={'fade-in'}>{limited?.locationName ?? 'Laster...'}</h1>
      </Banner>

      <div className="fb-article">
        {limited && (
          <Summary
            sections={[
              {
                header: 'Sted',
                content: limited?.locationName,
                columnSpan: 1,
              },
              {
                header: '',
                content: (
                  <Badge
                    className="badge-notification"
                    variant={limited?.paymentInfo.statusInfo.status}
                  >
                    {limited?.paymentInfo.statusInfo.text ??
                      'Mangler beskrivelse'}
                  </Badge>
                ),
                columnSpan: 1,
                className: 'bookingStatus',
              },
              {
                header: 'Dato',
                content: formatFromToDate(
                  new Date(limited?.arrivalDate),
                  new Date(limited?.departureDate),
                ),
                columnSpan: 1,
              },
              {
                header: '',
                content: (
                  <Button
                    onClick={() => nav(`/mypage/booking/${bookingId}`)}
                    className="tertiary"
                  >
                    Logg inn for å se mer
                  </Button>
                ),
              },
            ]}
          />
        )}
      </div>
    </>
  );
};

export default LimitedBookingReceipt;
