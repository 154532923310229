import moment from 'moment';

import type { UserMessageType } from "types/housingAdminTypes";

type PropsType = {
  userMessages: Array<UserMessageType>;
};

const UserMessageList = (props: PropsType) => (
  <label>
    {props.userMessages.map((message) => (
      <div key={message.Created} className="half-margin">
        <span>{moment(message.Created).format("DD.MM.YYYY HH:mm")}:</span>
        <p className="italic">{message.Message}</p>
      </div>
    ))}
  </label>
);

export default UserMessageList;
