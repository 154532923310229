import { aktiveURL, bookingOptionsURL, historiskeURL } from 'api/booking/booking_api';
import Loader from 'components/Loader/Loader';
import { useUser } from 'contexts/index';
import { setDeadlineForPayment, setErrorMsgBooking } from 'helpers/bookingHelpers';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import BookingList from './BookingList';

const activeBookingsURL = aktiveURL;
const historicalBookingsURL = historiskeURL;

const TabMyBookings = () => {
  const { user } = useUser();
  const { sendRequest } = useAxios();
  const [bookingOptions, setBookingOptions] = useState<DeliberateAny>();
  const [activeBookings, setActiveBookings] = useState<DeliberateAny>();
  const [historicalBookings, setHistoricalBookings] = useState<DeliberateAny>();

  const [bookingLocationOptions, setBookingLocationOptions] =
    useState<DeliberateAny>([]);

  const fetchBookingOptions = async () => {
    await sendRequest(
      {
        method: "GET",
        url: bookingOptionsURL,
        withCredentials: true,
      }, setBookingOptions
    );
  };

  const fetchActiveBookings = async () => {
    await sendRequest(
      {
        method: "GET",
        url: activeBookingsURL,
      },
      (response) => {
        const hasActiveBookings = response.length > 0;
        setActiveBookings(
          hasActiveBookings
            ? response.sort((a, b) => {
                new Date(b.BookingDate - a.BookingDate);
              })
            : []
        );
      }
    );
  };

  const fetchHistoricalBookings = async () => {
    await sendRequest(
      {
        method: "GET",
        url: historicalBookingsURL,
      },
      (response) => {
        setHistoricalBookings(
          response.sort((a, b) => new Date(b.BookingDate - a.BookingDate))
        );
      }
    );
  };

  useEffect(() => {
    fetchBookingOptions();
    fetchActiveBookings();
    fetchHistoricalBookings();
  }, [])

  useEffect(() => {
    if (bookingOptions) {
      setBookingLocationOptions(bookingOptions.Locations);
      setErrorMsgBooking(bookingOptions.ValidationErrorMessage);
      setDeadlineForPayment(bookingOptions.DeadlineForRegisteringPayment);
    }
  }, [bookingOptions]);

  return (
    <div>
      <div className="max-width-large">
        <div style={{ padding: "22px 0 22px 0" }}>
          <h2>Fremtidige overnattinger</h2>
          <Loader isLoading={!activeBookings}>
            {activeBookings && bookingLocationOptions && (
              <BookingList
                bookings={activeBookings}
                isActive
                setActiveBookings={setActiveBookings}
                bookingOptions={bookingLocationOptions}
                userData={user}
                noItemsMessage={<p>Ingen kommende overnattinger</p>}
              />
            )}
          </Loader>
        </div>
        <div style={{ padding: "22px 0 22px 0" }}>
          <h2>Historikk</h2>
          <p>
            Under Mine overnattinger vises overnattingshistorikk kun for siste
            tre månedene. Ta vare på kvitteringene på e-post for dokumentasjon
            til reiseregninger. Skulle du ha behov for informasjon om dine
            overnattinger lenger tilbake i tid, kan du ta
            <a href="#/contact"> kontakt med Forsvarsbygg servicesenter</a>.
            Forsvarsbygg lagrer overnattingshistorikk i et år etter
            regnskapsårets slutt jmfr. Lov om bokføring § 13. Elektronisk
            tilgjengelighet.
          </p>
          <Loader isLoading={!historicalBookings}>
            {historicalBookings && (
              <BookingList
                bookings={historicalBookings}
                isActive={false}
                userData={user}
                setActiveBookings={undefined}
                bookingOptions={undefined}
                noItemsMessage={<p>Ingen tidligere overnattinger</p>}
              />
            )}
          </Loader>
        </div>
      </div>
    </div>
  );
};

export default TabMyBookings;
