import Loader from 'components/Loader/Loader';
import DOMPurify from 'dompurify';
import env from 'env';
import useFetch from 'hooks/useFetch';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const NotFound = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoading, data: lookup } = useFetch<string>(
    env.CMS + location.pathname + '?excludeLayout=true',
  );

  useEffect(() => {
    if (!lookup && location.pathname) {
      navigate('/not-found', { replace: true });
    }
  }, [navigate, lookup]);

  return (
    <Loader isLoading={isLoading}>
      {lookup && (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(lookup),
          }}
        />
      )}
      {!lookup && (
        <div>
          <h1>404 - Fant ikke side</h1>
          <p>Beklager, siden du ser etter finnes ikke.</p>
        </div>
      )}
    </Loader>
  );
};

export default NotFound;
