// @flow

import { call, put, select } from "redux-saga/effects";

import {
  uploadSignedRentalContractDocumentURL,
  myRentalContractsURL,
  getRentalContractTextsURL,
  rejectSigningURL,
  rejectionReasonsURL,
  documentLinksURL,
  respontToInspectionTimeURL,
} from "../api/basenproff/basenproff_api";
import { axiosFetch } from "../hooks/useAxios";

function* handleMyRentals(action) {
  const url = myRentalContractsURL;

  try {
    const data = yield call(axiosFetch, { url });
    yield put({ type: "RECEIVE_MY_RENTALS", data, rentalId: action.rentalId });
  } catch (e) {
    yield put({ type: "SAGA_ERROR_MY_RENTALS", e, url });
  }
}

function* handleUploadSignedRentalContractDocument(action) {
  const url = uploadSignedRentalContractDocumentURL;
  const query = {
    rentalId: action.rentalId,
    documentId: action.documentId,
    statusQueryToken: action.statusQueryToken,
  };

  try {
    yield call(axiosFetch, {
      url,
      data: query,
      method: "POST",
      withCredentials: true,
    });
    // Fetch my rentals when signed document has been uploaded to Xpand
    const data = yield call(axiosFetch, { url: myRentalContractsURL });
    yield put({ type: "RECEIVE_MY_RENTALS", data, rentalId: action.rentalId });
  } catch (e) {
    yield put({
      type: "SAGA_ERROR_UPLOAD_SIGNED_RENTAL_CONTRACT_DOCUMENT",
      e,
      url,
      query,
    });
  }
}

function* handleRentalContractTexts() {
  const url = getRentalContractTextsURL;

  try {
    const isLoaded = yield select(
      (state) => state.myRentals.rentalContractTextsLoaded
    );
    if (!isLoaded) {
      const rentalContractTexts = yield call(axiosFetch, { url });
      yield put({ type: "RECEIVE_RENTAL_CONTRACT_TEXTS", rentalContractTexts });
    }
  } catch (e) {
    yield put({ type: "SAGA_ERROR_RENTAL_CONTRACT_TEXTS", e, url });
  }
}

function* handleRejectSigning(action) {
  const url = rejectSigningURL;
  const query = {
    documentId: action.documentId,
    rejectionReason: action.rejectionReason,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "RECEIVE_REJECT_SIGNING",
      documentId: action.documentId,
      rejectionReason: action.rejectionReason,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR_REJECT_SIGNING", e, url, query });
  }
}

function* handleRejectionReasons() {
  const url = rejectionReasonsURL;

  try {
    const isLoaded = yield select(
      (state) => state.myRentals.rejectionReasonsLoaded
    );
    if (!isLoaded) {
      const data = yield call(axiosFetch, { url });
      yield put({ type: "RECEIVE_REJECTION_REASONS", data });
    }
  } catch (e) {
    yield put({ type: "SAGA_ERROR_REJECTION_REASONS", e, url });
  }
}

function* handleDocumentLinks() {
  const url = documentLinksURL;

  try {
    const isLoaded = yield select(
      (state) => state.myRentals.documentLinksLoaded
    );
    if (!isLoaded) {
      const documentLinks = yield call(axiosFetch, { url });
      yield put({ type: "RECEIVE_RENTAL_DOCUMENT_LINKS", documentLinks });
    }
  } catch (e) {
    yield put({ type: "SAGA_ERROR_RENTAL_DOCUMENT_LINKS", e, url });
  }
}

function* handleRespondToProposedInspectionTime(action) {
  const url = respontToInspectionTimeURL;
  const query = {
    rentalObjectId: action.rentalObjectId,
    inspectionId: action.inspectionId,
    accept: action.accept,
    comment: action.comment,
    inspectionTime: action.inspectionTime,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    const rentalId = action.rentalObjectId;
    yield put({ type: "FETCH_MY_RENTALS", rentalId });
    yield put({ type: "RECEIVE_RECEIPT_INSPECTION", rentalId });
  } catch (e) {
    yield put({
      type: "SAGA_ERROR_RESPOND_TO_PROPOSED_INSPECTION_TIME",
      e,
      url,
    });
  }
}

export {
  handleMyRentals,
  handleUploadSignedRentalContractDocument,
  handleRentalContractTexts,
  handleRejectSigning,
  handleRejectionReasons,
  handleDocumentLinks,
  handleRespondToProposedInspectionTime,
};
