import { useLogin } from "contexts/index";

const CloseButton = () => {
  const { setPath, setShow } = useLogin();
  return (
    <a
      href="#"
      title="Lukk popup"
      className="box-close top-right"
      data-action="close"
      onClick={() => {
        setPath(undefined);
        setShow(false);
      }}
    ></a>
  );
};

export default CloseButton;
