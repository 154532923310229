import ListItem from 'components/ListItem/ListItem';

import { DeliberateAny } from '../../types/DelibrateAny';

const ExtraServicesItem = ({ item, columns, goToForm }: DeliberateAny) => {
  const handleClick = (id) => {
    goToForm(id);
  };
  const renderDetails = () => (
    <div className="details-wrapper">
      <ul className="extra-services-list">
        {item.SubCategories.map((el: DeliberateAny) => {
          return (
            <li key={el.Heading}>
              <div>
                <strong>{el.Heading}</strong>
              </div>
              <div className="description">
                <div>{el.Description}</div>
                <div>
                  <button
                    className="btn red"
                    onClick={() => handleClick(el.id)}
                  >
                    Velg
                  </button>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <ListItem
      columns={columns}
      item={item}
      minimalDetails
      renderDetailsFunction={() => renderDetails()}
    />
  );
};

export default ExtraServicesItem;
