import { arenaByIdUrl } from 'api/booking/booking_api_v2';
import Summary, { SummarySection } from 'components/Summary/Summary';
import { useApplication } from 'contexts/index';
import DOMPurify from 'dompurify';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetArenaInformationResponse } from 'types/BookingTypes/arenaTypes';
import RenderHtmlTextField from '../MyPage/RenderHtmlTextField';

const ArrivalInfo = () => {
  const { setHeading, setSubHeading } = useApplication();
  const { sendRequest } = useAxios();
  const { locationId } = useParams();

  const [arenaInfo, setArenaInfo] = useState<GetArenaInformationResponse>();

  const fetchArenaInformation = async () => {
    if (locationId) {
      await sendRequest(
        { method: 'GET', url: arenaByIdUrl(locationId) },
        (response: GetArenaInformationResponse) => {
          setArenaInfo(response);
        },
      );
    }
  };

  useEffect(() => {
    fetchArenaInformation();
  }, [locationId]);

  useEffect(() => {
    setHeading('Adkomst til Kolsås leir');
    setSubHeading('');
  }, []);

  return (
    <div className="html-content">
      <div className="readable-content">
        {arenaInfo && (
          <Summary
            sections={[
              {
                header: '',
                content: (
                  <RenderHtmlTextField textArea={arenaInfo.arrivalInfo} />
                ),
                columnSpan: 2,
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default ArrivalInfo;
