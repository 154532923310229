// @flow

import {
  fetchBuildingTypes,
  resetPortfolioFilter,
  setPortfolioBrasOptions,
  updatePortfolioFilter,
  updatePortfolioFilterMetaData,
} from "actions/myPortfolioActions";
import Button from "components/buttons/Button/Button";
import ButtonRow from "components/buttons/ButtonRow/ButtonRow";
import BuildingTypeSelect from "components/form/BuildingTypeSelect/BuildingTypeSelect";
import Input from "components/form/Input/Input";
import MyDifsSelect from "components/form/MyDifsSelect/MyDifsSelect";
import RadioButtonGroup from "components/form/RadioButtonGroup/RadioButtonGroup";
import RegionSelect from "components/form/RegionSelect/RegionSelect";
import Select from "components/form/Select/Select";
import connect from "helpers/connectWithRouter";
import { mediaQueries, MediaQuery } from "helpers/mediaQuery";
import { stringIsEmpty } from "helpers/valueHelpers";
import React, { Component } from "react";
import { UnmountClosed } from "react-collapse";
import { DeliberateAny } from "types/DelibrateAny";

import type { MyDifType } from "types/optionsTypes";
import type {
  PortfolioFilterType,
  PortfolioFilterMetaDataType,
} from "types/myPortfolioTypes";
import type { KeyValueType } from "types/commonTypes";
type PropsType = {
  portfolioFilter: PortfolioFilterType;
  portfolioFilterMetaData: PortfolioFilterMetaDataType;
  myDifsLoaded: boolean;
  myDifs: Array<MyDifType>;
  updatePortfolioFilter: (key: string, value: number | string) => void;
  updatePortfolioFilterMetaData: (
    key: string,
    value: boolean | number | string
  ) => void;
  setPortfolioBrasOptions: (bras: Array<KeyValueType>) => void;
  applySearch: () => void;
  resetPortfolioFilter: () => void;
  fetchBuildingTypes: () => void;
  buildingTypesLoaded: boolean;
  buildingTypes?: Array<KeyValueType>;
};

class UnconnectedPortfolioFilter extends Component {
  declare props: PropsType;
  state: {
    filterExpanded: boolean;
  };

  constructor(props: PropsType) {
    super(props);

    this.state = {
      filterExpanded: false,
    };
  }

  onChangeDif = (selectName: string, difKey: string) => {
    const selectedDif = this.props.myDifs.find(
      (dif) => dif.key === difKey.toString()
    );

    if (selectedDif && selectedDif.bras) {
      this.props.setPortfolioBrasOptions(selectedDif.bras);
    }

    this.props.updatePortfolioFilter(selectName, difKey);
    this.props.fetchBuildingTypes();
  };

  onChangeSearchType = (key: string, value: string | number | boolean) => {
    this.props.updatePortfolioFilterMetaData(key, value);
    this.props.fetchBuildingTypes();
  };

  handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.applySearch();
    }
  };

  toggleExpandFilter = () => {
    this.setState({
      filterExpanded: !this.state.filterExpanded,
    });
  };

  renderSecondaryInputs = () => (
    <div>
      <div className="flex-row margin">
        <label>
          <span>Søk inventarnavn</span>
          <Input
            onChange={this.props.updatePortfolioFilter}
            value={this.props.portfolioFilter.textSearch || ""}
            type="text"
            name="textSearch"
          />
        </label>
        <label>
          <span>Fra m²</span>
          <Input
            onChange={this.props.updatePortfolioFilter}
            value={this.props.portfolioFilter.minSize || ""}
            type="number"
            name="minSize"
          />
        </label>
        <label>
          <span>Til m²</span>
          <Input
            onChange={this.props.updatePortfolioFilter}
            value={this.props.portfolioFilter.maxSize || ""}
            type="number"
            name="maxSize"
          />
        </label>
        <label>
          <span>Bygningstype</span>
          <BuildingTypeSelect
            onChange={this.props.updatePortfolioFilter}
            value={this.props.portfolioFilter.inventoryTypeCode}
            name="inventoryTypeCode"
            buildingTypes={this.props.buildingTypes}
            buildingTypesLoaded={this.props.buildingTypesLoaded}
            disabled={false}
          />
        </label>
      </div>
    </div>
  );

  renderPrimaryInputs = () => (
    <div>
      <div className="flex-row margin">
        <RadioButtonGroup
          options={[
            { value: "buildingSearch", label: "Søk etter bygninger" },
            { value: "surfaceOfLandSearch", label: "Søk etter utearealer" },
          ]}
          checkedOption={this.props.portfolioFilterMetaData.searchType}
          name="searchType"
          onChange={this.onChangeSearchType}
        />
      </div>
      <div className="flex-row margin">
        <label>
          <span>Distrikt</span>
          <RegionSelect
            onChange={this.props.updatePortfolioFilter}
            value={this.props.portfolioFilter.region}
            name="region"
            includeShowAll={false}
            autoSelectFirstRegion
          />
        </label>
        <label>
          <span>Etablissement</span>
          <Select
            onChange={this.props.updatePortfolioFilter}
            options={this.props.portfolioFilterMetaData.establishments}
            loading={this.props.portfolioFilterMetaData.loadingEstablishments}
            value={this.props.portfolioFilter.establishment}
            dependentOn="region"
            name="establishment"
            showAllOption
          />
        </label>
        <label>
          <span>DIF</span>
          <MyDifsSelect
            onChange={this.onChangeDif}
            value={this.props.portfolioFilter.dif}
            name="dif"
          />
        </label>
        <label>
          <span>BRA</span>
          <Select
            onChange={this.props.updatePortfolioFilter}
            options={this.props.portfolioFilterMetaData.bras}
            value={this.props.portfolioFilter.bra}
            loading={!this.props.myDifsLoaded}
            dependentOn="dif"
            name="bra"
            showAllOption
          />
        </label>
      </div>
    </div>
  );

  renderButtons = () => (
    <ButtonRow>
      <ButtonRow.Left
        onClick={this.props.applySearch}
        color="red"
        disabled={
          stringIsEmpty(this.props.portfolioFilter.region) ||
          stringIsEmpty(this.props.portfolioFilter.dif)
        }
      >
        Søk
      </ButtonRow.Left>
      <ButtonRow.Left onClick={this.props.resetPortfolioFilter} color="grey">
        Tøm filter
      </ButtonRow.Left>

      <ButtonRow.Right color="grey" onClick={this.toggleExpandFilter}>
        <MediaQuery query={mediaQueries.mediumUp}>
          {this.state.filterExpanded ? "Færre filter" : "Flere filter"}
        </MediaQuery>

        <MediaQuery query={mediaQueries.small}>Lukk</MediaQuery>
      </ButtonRow.Right>
    </ButtonRow>
  );

  render() {
    return (
      <div onKeyPress={this.handleOnKeyPress}>
        <MediaQuery query={mediaQueries.mediumUp}>
          <div className="content-group padded grey">
            {this.renderPrimaryInputs()}
            <UnmountClosed isOpened={this.state.filterExpanded}>
              {this.renderSecondaryInputs()}
            </UnmountClosed>
            {this.renderButtons()}
          </div>
        </MediaQuery>

        <MediaQuery query={mediaQueries.small}>
          <header className={`${this.state.filterExpanded ? "margin" : ""}`}>
            <Button color="light-grey" onClick={this.toggleExpandFilter}>
              Filtrer
            </Button>
          </header>
          <UnmountClosed isOpened={this.state.filterExpanded}>
            <div className="content-group padded grey">
              {this.renderPrimaryInputs()}
              {this.renderSecondaryInputs()}
              {this.renderButtons()}
            </div>
          </UnmountClosed>
        </MediaQuery>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    portfolioFilter: state.myPortfolio.search.filter,
    portfolioFilterMetaData: state.myPortfolio.search.filterMetaData,
    myDifsLoaded: state.options.myDifsLoaded,
    myDifs: state.options.myDifs,
    buildingTypes: state.myPortfolio.buildingTypes,
    buildingTypesLoaded: state.myPortfolio.buildingTypesLoaded,
  }),
  {
    updatePortfolioFilter,
    updatePortfolioFilterMetaData,
    resetPortfolioFilter,
    setPortfolioBrasOptions,
    fetchBuildingTypes,
  }
)(UnconnectedPortfolioFilter);
