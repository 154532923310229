import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { catalogURL } from '../../api/basenproff/basenproff_api';
import Loader from '../../components/Loader/Loader';
import { axiosFetch } from '../../hooks/useAxios';
import { DeliberateAny } from '../../types/DelibrateAny';
import styles from './ExtraServicesColumns.module.scss';
import ExtraServicesItem from './ExtraServicesItem';

const ExtraServicesColumns = [
  {
    label: 'Namn',
    name: 'Name',
    className: styles.name,
    renderFunction: (item: DeliberateAny) => item.Name,
  },
];

const ExtraServicesList = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<DeliberateAny[]>();

  useEffect(() => {
    void axiosFetch({ url: catalogURL, withCredentials: true }).then(
      (response) => setCategories(response.MainCategories),
    );
  }, []);

  const goToForm = (id: string) => {
    navigate(`/reporting/faultform/Tilleggstjenester/${id}`);
  };

  return (
    <div className="scrollable sticky-middle max-width clientsquare-report">
      <h2 className="unclassified-stamp">Ugradert</h2>
      <h2 className="row margin">Servicekatalog</h2>
      <Loader isLoading={!categories}>
        <div className="row">
          <ul className="item-list catalog-list">
            {categories &&
              categories.map((el) => (
                <ExtraServicesItem
                  key={uuidv4()}
                  item={el}
                  columns={ExtraServicesColumns}
                  goToForm={goToForm}
                />
              ))}
          </ul>
        </div>
      </Loader>
    </div>
  );
};

export default ExtraServicesList;
