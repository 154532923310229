// @flow

import { DeliberateAny } from "../types/DelibrateAny";
import type { QuarterApplicationType } from "../types/housingAdminTypes";

export function setHousingAdminReferenceType(referenceType: string) {
  return { type: "SET_HOUSING_ADMIN_REFERENCE_TYPE", referenceType };
}

export function setHousingAdminRegion(region: string) {
  return { type: "SET_HOUSING_ADMIN_REGION", region };
}

export function toggleFavoriteHousingAdminArea(
  areaId: string,
  referenceType: string,
  isFavorite: boolean
) {
  return {
    type: "TOGGLE_FAVORITE_HOUSING_ADMIN_AREA",
    areaId,
    referenceType,
    isFavorite,
  };
}

export function fetchHousingAdminServiceAreas() {
  return { type: "FETCH_HOUSING_ADMIN_SERVICE_AREAS" };
}

export function fetchHousingRoundDataOptions() {
  return { type: "FETCH_HOUSING_ROUND_DATA_OPTIONS" };
}

export function fetchHousingAdministrationDataOptions() {
  return { type: "FETCH_HOUSING_ADMIN_DATA_OPTIONS" };
}

export function fetchAvailableQuarters() {
  return { type: "FETCH_AVAILABLE_QUARTERS" };
}

export function fetchVacantFromOffsetDaysQuarter() {
  return { type: "FETCH_VACANT_FROM_OFFSET_DAYS_QUARTER" };
}

export function fetchVacantFromOffsetDaysHousing() {
  return { type: "FETCH_VACANT_FROM_OFFSET_DAYS_HOUSING" };
}

export function updateQuarterApplicationsPage(
  housingAreaId: string,
  vacantFromDate: string
) {
  return {
    type: "UPDATE_QUARTER_APPLICATIONS_PAGE",
    housingAreaId,
    vacantFromDate,
  };
}
export function updateHousingApplicationsPage(housingAreaId: string) {
  return { type: "UPDATE_HOUSING_APPLICATIONS_PAGE", housingAreaId };
}

export function updateHousingRenewalApplicationsPage(housingAreaId: string) {
  return { type: "UPDATE_HOUSING_RENEWAL_APPLICATIONS_PAGE", housingAreaId };
}

export function setHousingArea(housingAreaId: string) {
  return { type: "SET_HOUSING_AREA", housingAreaId };
}

export function fetchQuarterApplicationSummary(applicationId: string) {
  return { type: "FETCH_QUARTER_APPLICATION_SUMMARY", applicationId };
}

export function fetchHousingRenewalApplicationSummary(applicationId: string) {
  return { type: "FETCH_HOUSING_RENEWAL_APPLICATION_SUMMARY", applicationId };
}

export function updateQuarterApplicationMessage(
  applicationId: string,
  messageKey: string,
  message: string
) {
  return {
    type: "UPDATE_QUARTER_APPLICATION_MESSAGE",
    applicationId,
    messageKey,
    message,
  };
}

export function updateHousingRenewalApplicationMessage(
  applicationId: string,
  messageKey: string,
  message: string
) {
  return {
    type: "UPDATE_HOUSINGRENEWAL_APPLICATION_MESSAGE",
    applicationId,
    messageKey,
    message,
  };
}

export function rejectQuarterApplication(
  applicationId: string,
  reason: string,
  areaId: string
) {
  return { type: "REJECT_QUARTER_APPLICATION", applicationId, reason, areaId };
}

export function rejectHousingRenewalApplication(
  applicationId: string,
  reason: string,
  areaId: string
) {
  return {
    type: "REJECT_HOUSINGRENEWAL_APPLICATION",
    applicationId,
    reason,
    areaId,
  };
}

export function sendCommentHousingRenewal(
  applicationId: string,
  comment: string,
  areaId: string
) {
  return {
    type: "COMMENT_HOUSINGRENEWAL_APPLICATION",
    applicationId,
    comment,
    areaId,
  };
}

export function approveHousingRenewalApplication(
  applicationId: string,
  reason: string,
  areaId: string,
  approvedTo: string
) {
  return {
    type: "APPROVE_HOUSINGRENEWAL_APPLICATION",
    applicationId,
    reason,
    areaId,
    approvedTo,
  };
}

export function sendMessageToQuarterApplicant(
  applicationId: string,
  message: string
) {
  return { type: "SEND_MESSAGE_TO_QUARTER_APPLICANT", applicationId, message };
}

export function fetchHousingAdminApplicationCounts(areaIds: Array<string>) {
  return { type: "FETCH_HOUSING_ADMIN_APPLICATION_COUNTS", areaIds };
}

export function sendCommentToHousingCoordinator(
  applicationId: string,
  comment: string
) {
  return {
    type: "SEND_COMMENT_TO_HOUSING_COORDINATOR",
    applicationId,
    comment,
  };
}

export function toggleQuarterApplicationAssignment(
  applicationId: string,
  assignmentIsOK: boolean
) {
  return {
    type: "TOGGLE_QUARTER_APPLICATION_ASSIGNMENT",
    applicationId,
    assignmentIsOK,
  };
}

export function toggleQuarterApplicationApproved(
  applicationId: string,
  isApproved: boolean
) {
  return {
    type: "TOGGLE_QUARTER_APPLICATION_APPROVED",
    applicationId,
    isApproved,
  };
}

export function sendQuarterApplicationsToAllocation(
  applicationIds: Array<string>,
  areaId: string
) {
  return {
    type: "SEND_QUARTER_APPLICATIONS_TO_ALLOCATION",
    applicationIds,
    areaId,
  };
}

export function sortUnprocessedQuarterApplications(
  sorted: Array<QuarterApplicationType>
) {
  return { type: "SORT_UNPROCESSED_QUARTER_APPLICATIONS", sorted };
}

export function sortProcessedQuarterApplications(
  sorted: Array<QuarterApplicationType>
) {
  return { type: "SORT_PROCESSED_QUARTER_APPLICATIONS", sorted };
}

export function addAllocationRound(
  housingAreaId: string,
  applicationDeadline: string
) {
  return { type: "ADD_ALLOCATION_ROUND", housingAreaId, applicationDeadline };
}

export function fetchHousingTexts() {
  return { type: "FETCH_HOUSING_TEXTS" };
}

export function fetchHousingAllocations(allocationRoundId: string) {
  return { type: "FETCH_ALLOCATION_ROUND", allocationRoundId };
}

export function setAllocationRoundId(allocationRoundId: string) {
  return { type: "SET_SELECTED_ALLOCATION_ROUND", allocationRoundId };
}

export function sortHousingAllocations(sortedItems: Array<DeliberateAny>) {
  return { type: "SORT_HOUSING_ALLOCATIONS", sortedItems };
}

export function registerSingleHousingAllocation(
  singleAllocationItem: DeliberateAny
) {
  return { type: "REGISTER_SINGLE_HOUSING_ALLOCATION", singleAllocationItem };
}

export function fetchHousingRound(
  allocationRoundId: string,
  vacantFromDate: string
) {
  return { type: "FETCH_HOUSING_ROUND", allocationRoundId, vacantFromDate };
}

export function registerHousingAllocations(
  housingAllocationListItems: Array<DeliberateAny>,
  allocationRoundId: string
) {
  return {
    type: "REGISTER_HOUSING_ALLOCATIONS",
    housingAllocationListItems,
    allocationRoundId,
  };
}

export function handleCloseHousingRound(
  roundId: string,
  housingAreaId: string
) {
  return { type: "CLOSE_HOUSING_ALLOCATION_ROUND", roundId, housingAreaId };
}
