import Accordion from 'components/Accordian/Accordion';
import Button from 'components/buttons/Button/Button';
import ComboSelectBox from 'components/FormComponents/ComboSelectBox';
import Modal, { ModalSize } from 'components/ModalComponents/Modal';
import Summary from 'components/Summary/Summary';
import React, { useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

const ModalExample = () => {
  const [showFacilityModal, setShowFacilityModal] = useState<boolean>(false);
  const [showModalWithSubmit, setShowModalWithSubmit] =
    useState<boolean>(false);
  const [showModalWithCancel, setShowModalWithCancel] =
    useState<boolean>(false);

  const [formHolder, setFormHolder] = useState<DeliberateAny>({});
  const [formErrors, setFormErrors] = useState<DeliberateAny>({});
  const handleChange = (name: string, value: DeliberateAny) => {
    const updated: DeliberateAny = {};
    updated[name] = value;
    setFormHolder({ ...formHolder, ...updated });
  };

  const facilityModalContent = () => {
    return (
      <div>
        <Accordion title={'Kantine'}>
          <Summary
            sections={[
              {
                header: '',
                content: `ISS Arena og Event har ansvaret for 
                driften av kantina, og der kan du kjøpe varm 
                og kald mat. Betalingsmåte: Kort.`,
                columnSpan: 2,
              },
              {
                header: 'Åpningstider i ukedager',
                content: (
                  <div>
                    <div>Frokost: 06:30-08:30</div>
                    <div>Lunsj: 11:00-13:00</div>
                    <div>Middag: 15:00-18:00</div>
                  </div>
                ),
              },
              {
                header: 'Helg og helligdager',
                content: (
                  <div>
                    <div>Brunsj: 09:30-11:30</div>
                    <div>Lunsj: 11:00-13:00</div>
                    <div>Middag: 14:00-16:00</div>
                  </div>
                ),
              },
            ]}
          ></Summary>
        </Accordion>
        <Accordion title="Fritidsmesse">For en fritidsmesse</Accordion>
        <Accordion title="Velferdstilbud">Så mye tilbud</Accordion>
      </div>
    );
  };

  return (
    <React.Fragment>
      <h2>Modal</h2>
      <div>
        All modals can be canceled by clicking the dark overlay of the backdrop.
      </div>
      <div className="flex gap">
        <Button
          className="secondary"
          onClick={() => setShowFacilityModal(true)}
        >
          Les mer om fasilitetene
        </Button>
        <Button
          className="secondary"
          onClick={() => setShowModalWithSubmit(true)}
        >
          Modal with submit and cancel button
        </Button>
        <Button
          className="secondary"
          onClick={() => setShowModalWithCancel(true)}
        >
          Modal without submit, but cancel button
        </Button>
      </div>

      {showFacilityModal && (
        <Modal
          header={'Mer om fasilitetene'}
          content={facilityModalContent()}
          onClose={() => setShowFacilityModal(false)}
          showCancelIcon={true}
        />
      )}
      {showModalWithSubmit && (
        <Modal
          header={'Modal with submit button (size small)'}
          content={
            <div>
              <div>
                Imagine this to be a form of sorts. Then the submit button could
                be for that form
              </div>
              <div>
                <ComboSelectBox
                  initial="Velg noe"
                  name="comboSelect"
                  placeHolder="Skriv for å filtrere..."
                  label={'ComboSelectBox Label'}
                  formHolder={formHolder}
                  updateForm={handleChange}
                  errors={formErrors}
                  helpInfo={'Kan endres i Sandbox'}
                  requiredChars={2}
                  options={[
                    { label: 'Ikke valgt', value: '0' },
                    { label: 'Valgt 1', value: '1' },
                    { label: 'Ikke valgt', value: '2' },
                    { label: 'Disabled 3', value: '3', disabled: true },
                  ]}
                />
              </div>
            </div>
          }
          onClose={() => setShowModalWithSubmit(false)}
          onSubmit={() => setShowModalWithSubmit(false)}
          showCancelButton={true}
          size={ModalSize.Small}
        />
      )}
      {showModalWithCancel && (
        <Modal
          header={'Modal with extra cancel button (size large)'}
          content={
            <div className="flex-column gap">
              <b>
                This could just be a very large modal, maybe it requires you to
                scroll downwards, where you might want a cancel-button in the
                bottom.
              </b>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                lectus urna, fringilla nec ultrices id, vehicula sit amet nisi.
                Nam eu sodales leo. Maecenas a sem mi. Maecenas at tincidunt
                enim. Vivamus viverra nibh eu iaculis scelerisque. Morbi
                facilisis ex elit, a tristique libero porttitor ac. Proin sed mi
                non quam lacinia malesuada ut in ligula. Integer elementum vitae
                velit non faucibus. In id consequat orci. Vivamus nulla nisi,
                sollicitudin ut purus sed, vulputate ullamcorper ex. Mauris eget
                euismod libero. Vivamus vel libero nec leo placerat tempor vel
                sit amet eros. Integer convallis libero id urna faucibus
                pellentesque. Integer elementum dignissim ultricies. Morbi eu
                velit maximus, facilisis lacus quis, molestie lacus. Interdum et
                malesuada fames ac ante ipsum primis in faucibus. Praesent
                pharetra eu libero et finibus. Donec at leo vel elit sodales
                auctor. Etiam ut ex sed nunc tincidunt mattis in eget arcu. In
                imperdiet ante sed volutpat maximus. Pellentesque elementum arcu
                ut dolor vestibulum, eget vehicula magna mattis. Vivamus sed
                lectus bibendum, rhoncus lorem a, gravida erat. Ut dictum
                accumsan bibendum. Etiam ac ante auctor erat egestas fringilla.
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                posuere cubilia curae; Aenean eget enim nunc. Aliquam erat
                volutpat. Etiam imperdiet luctus lobortis. Ut interdum lectus ac
                purus condimentum, ac vehicula nisl rhoncus. Class aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos. Ut in leo vel ipsum euismod lobortis. Vestibulum
                volutpat aliquam posuere. Duis semper lacinia blandit. Quisque
                interdum elementum diam elementum ornare. Phasellus vestibulum
                ex enim, eu sodales metus pellentesque nec. Mauris maximus justo
                viverra finibus suscipit. Fusce vestibulum tortor ut mi euismod,
                at elementum eros tristique. Cras tincidunt convallis orci. In
                scelerisque sem nec sodales vehicula. Pellentesque pellentesque
                tincidunt turpis in viverra. Suspendisse tellus lectus, semper
                suscipit eros sed, mollis rutrum felis. Maecenas consectetur
                tempor pellentesque. Quisque quis sodales lorem. Suspendisse
                eget purus orci. Ut nec posuere risus. Quisque sollicitudin
                iaculis purus sit amet pellentesque. Morbi aliquam ipsum quis
                augue molestie, sit amet volutpat risus facilisis. Vestibulum
                ante ipsum primis in faucibus orci luctus et ultrices posuere
                cubilia curae; Maecenas sed sem et justo tempor rutrum. Donec
                tincidunt malesuada nibh id venenatis. Vivamus pellentesque
                dignissim diam. Etiam interdum dui vel dignissim elementum.
              </div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                lectus urna, fringilla nec ultrices id, vehicula sit amet nisi.
                Nam eu sodales leo. Maecenas a sem mi. Maecenas at tincidunt
                enim. Vivamus viverra nibh eu iaculis scelerisque. Morbi
                facilisis ex elit, a tristique libero porttitor ac. Proin sed mi
                non quam lacinia malesuada ut in ligula. Integer elementum vitae
                velit non faucibus. In id consequat orci. Vivamus nulla nisi,
                sollicitudin ut purus sed, vulputate ullamcorper ex. Mauris eget
                euismod libero. Vivamus vel libero nec leo placerat tempor vel
                sit amet eros. Integer convallis libero id urna faucibus
                pellentesque. Integer elementum dignissim ultricies. Morbi eu
                velit maximus, facilisis lacus quis, molestie lacus. Interdum et
                malesuada fames ac ante ipsum primis in faucibus. Praesent
                pharetra eu libero et finibus. Donec at leo vel elit sodales
                auctor. Etiam ut ex sed nunc tincidunt mattis in eget arcu. In
                imperdiet ante sed volutpat maximus. Pellentesque elementum arcu
                ut dolor vestibulum, eget vehicula magna mattis. Vivamus sed
                lectus bibendum, rhoncus lorem a, gravida erat. Ut dictum
                accumsan bibendum. Etiam ac ante auctor erat egestas fringilla.
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                posuere cubilia curae; Aenean eget enim nunc. Aliquam erat
                volutpat. Etiam imperdiet luctus lobortis. Ut interdum lectus ac
                purus condimentum, ac vehicula nisl rhoncus. Class aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos. Ut in leo vel ipsum euismod lobortis. Vestibulum
                volutpat aliquam posuere. Duis semper lacinia blandit. Quisque
                interdum elementum diam elementum ornare. Phasellus vestibulum
                ex enim, eu sodales metus pellentesque nec. Mauris maximus justo
                viverra finibus suscipit. Fusce vestibulum tortor ut mi euismod,
                at elementum eros tristique. Cras tincidunt convallis orci. In
                scelerisque sem nec sodales vehicula. Pellentesque pellentesque
                tincidunt turpis in viverra. Suspendisse tellus lectus, semper
                suscipit eros sed, mollis rutrum felis. Maecenas consectetur
                tempor pellentesque. Quisque quis sodales lorem. Suspendisse
                eget purus orci. Ut nec posuere risus. Quisque sollicitudin
                iaculis purus sit amet pellentesque. Morbi aliquam ipsum quis
                augue molestie, sit amet volutpat risus facilisis. Vestibulum
                ante ipsum primis in faucibus orci luctus et ultrices posuere
                cubilia curae; Maecenas sed sem et justo tempor rutrum. Donec
                tincidunt malesuada nibh id venenatis. Vivamus pellentesque
                dignissim diam. Etiam interdum dui vel dignissim elementum.
              </div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                lectus urna, fringilla nec ultrices id, vehicula sit amet nisi.
                Nam eu sodales leo. Maecenas a sem mi. Maecenas at tincidunt
                enim. Vivamus viverra nibh eu iaculis scelerisque. Morbi
                facilisis ex elit, a tristique libero porttitor ac. Proin sed mi
                non quam lacinia malesuada ut in ligula. Integer elementum vitae
                velit non faucibus. In id consequat orci. Vivamus nulla nisi,
                sollicitudin ut purus sed, vulputate ullamcorper ex. Mauris eget
                euismod libero. Vivamus vel libero nec leo placerat tempor vel
                sit amet eros. Integer convallis libero id urna faucibus
                pellentesque. Integer elementum dignissim ultricies. Morbi eu
                velit maximus, facilisis lacus quis, molestie lacus. Interdum et
                malesuada fames ac ante ipsum primis in faucibus. Praesent
                pharetra eu libero et finibus. Donec at leo vel elit sodales
                auctor. Etiam ut ex sed nunc tincidunt mattis in eget arcu. In
                imperdiet ante sed volutpat maximus. Pellentesque elementum arcu
                ut dolor vestibulum, eget vehicula magna mattis. Vivamus sed
                lectus bibendum, rhoncus lorem a, gravida erat. Ut dictum
                accumsan bibendum. Etiam ac ante auctor erat egestas fringilla.
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                posuere cubilia curae; Aenean eget enim nunc. Aliquam erat
                volutpat. Etiam imperdiet luctus lobortis. Ut interdum lectus ac
                purus condimentum, ac vehicula nisl rhoncus. Class aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos. Ut in leo vel ipsum euismod lobortis. Vestibulum
                volutpat aliquam posuere. Duis semper lacinia blandit. Quisque
                interdum elementum diam elementum ornare. Phasellus vestibulum
                ex enim, eu sodales metus pellentesque nec. Mauris maximus justo
                viverra finibus suscipit. Fusce vestibulum tortor ut mi euismod,
                at elementum eros tristique. Cras tincidunt convallis orci. In
                scelerisque sem nec sodales vehicula. Pellentesque pellentesque
                tincidunt turpis in viverra. Suspendisse tellus lectus, semper
                suscipit eros sed, mollis rutrum felis. Maecenas consectetur
                tempor pellentesque. Quisque quis sodales lorem. Suspendisse
                eget purus orci. Ut nec posuere risus. Quisque sollicitudin
                iaculis purus sit amet pellentesque. Morbi aliquam ipsum quis
                augue molestie, sit amet volutpat risus facilisis. Vestibulum
                ante ipsum primis in faucibus orci luctus et ultrices posuere
                cubilia curae; Maecenas sed sem et justo tempor rutrum. Donec
                tincidunt malesuada nibh id venenatis. Vivamus pellentesque
                dignissim diam. Etiam interdum dui vel dignissim elementum.
              </div>
            </div>
          }
          onClose={() => setShowModalWithCancel(false)}
          size={ModalSize.Large}
          showCancelButton={true}
          overrideCancelButtonText="Avslutt"
        />
      )}
    </React.Fragment>
  );
};

export default ModalExample;
