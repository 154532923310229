import './MyBooking.scss';

import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal, { ModalSize } from 'components/ModalComponents/Modal';
import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import { useMyPage } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import {
  bookingCancelUrl,
  bookingPreviewCancellationUrl,
} from 'api/booking/booking_api_v2';
import { SetState } from 'types/DelibrateAny';
import { PreviewCancellationResponse } from 'types/bookingRequests';
import { humanReadableDate } from 'helpers/date';
import Loader from 'components/Loader/Loader';

const MyBookingCancelModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: SetState<boolean>;
}) => {
  const { bookingid } = useParams();
  const { bookingItem } = useMyPage();
  const { sendRequest } = useAxios();
  const {
    sendRequest: sendCancelRequest,
    requestLoading: cancelRequestLoading,
  } = useAxios();
  const navigate = useNavigate();

  const [previewCancellation, setPreviewCancellation] =
    useState<PreviewCancellationResponse>();

  const handleCancelBooking = async () => {
    if (bookingid) {
      await sendCancelRequest(
        {
          method: 'PUT',
          url: bookingCancelUrl(bookingid),
        },
        (response) => {
          navigate('/mypage/booking');
        },
      );
    }
  };

  const fetchPreviewCancellation = async () => {
    if (bookingid)
      await sendRequest(
        {
          method: 'GET',
          url: bookingPreviewCancellationUrl(bookingid),
        },
        setPreviewCancellation,
      );
  };

  useEffect(() => {
    if (showModal) {
      fetchPreviewCancellation();
    }
  }, [showModal]);

  return (
    <Fragment>
      {showModal && (
        <Modal
          header="Avbestille overnatting"
          size={ModalSize.Medium}
          content={
            <Loader isLoading={cancelRequestLoading}>
              <div className="fb-booking-summary-modal">
                <p>
                  Ved ordinær bestilling kan bestillinger fritt avbestilles
                  inntil{' '}
                  <span>
                    {humanReadableDate(
                      bookingItem?.cancellationDeadline,
                      true,
                    ).toLocaleLowerCase()}
                  </span>
                  . Avbestillinger senere enn fristen, eller uteblivelse på
                  selve dagen (no show), vil medføre belastning av hele eller
                  deler av beløpet.
                </p>
                {previewCancellation?.status.status !== AlertType.Success && (
                  <AlertMessage
                    variant={previewCancellation?.status.status}
                    className="fb-full-width"
                  >
                    {previewCancellation?.message}
                  </AlertMessage>
                )}
              </div>
            </Loader>
          }
          onClose={() => setShowModal(false)}
          onSubmit={() => handleCancelBooking()}
          disableSubmit={cancelRequestLoading}
          showCancelButton={true}
        />
      )}
    </Fragment>
  );
};

export default MyBookingCancelModal;
