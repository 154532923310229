import './MyBooking.scss';

import RoomDetails from 'components/Details/RoomDetails';
import NavigationLink from 'components/Link/NavigationLink';
import Loader from 'components/Loader/Loader';
import { useApplication, useMyPage } from 'contexts/index';
import { useEffect, useState } from 'react';
import MyBookingAlertMessage from './MyBookingAlertMessage';
import MyBookingActions from './MyBookingActions';
import BookingSummary from 'components/Summary/BookingSummary';
import RenderHtmlTextField from './RenderHtmlTextField';

const MyBooking = () => {
  const { setHeading } = useApplication();
  const { bookingItem, arenaInfo } = useMyPage();

  useEffect(() => {
    if (bookingItem) {
      setHeading(bookingItem?.locationName ?? 'Min overnatting');
    } else {
      setHeading('Laster...');
    }
  }, [setHeading, bookingItem]);

  return (
    <div className="fb-main">
      <Loader isLoading={!bookingItem}>
        <div className="readable-content fb-my-booking">
          {bookingItem?.cancellationDeadline && <MyBookingAlertMessage />}

          {bookingItem && <BookingSummary booking={bookingItem} />}

          {bookingItem && <RoomDetails rooms={bookingItem.rooms} />}

          {arenaInfo && (
            <RenderHtmlTextField
              textArea={arenaInfo.openingInfo}
              smallText={false}
            />
          )}

          <div className="nav-links-container">
            <NavigationLink
              title="Adkomst"
              href={`/info/arrival/${bookingItem?.locationId}`}
            />
            <NavigationLink
              title="Les mer om leiren"
              href={`/info/location/${bookingItem?.locationId}`}
            />
            <NavigationLink
              title="Vilkår for overnatting"
              href={'/info/booking-terms'}
            />
          </div>

          <MyBookingActions />
        </div>
      </Loader>
    </div>
  );
};

export default MyBooking;
