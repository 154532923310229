import '../../PortfolioColumns.scss';

import List from 'components/List/List';
// @flow
import React, { Component } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import { buildingColumns } from '../../PortfolioColumns';
import PortfolioItem from './PortfolioItem';

import type { PortfolioItemType } from "types/myPortfolioTypes";
import type { DetailsButtonType } from "types/listTypes";
// type PropsType = {
//   items: Array<PortfolioItemType>;
//   customDetailsButton?: DetailsButtonType;
// };

class PortfolioList extends Component {
  declare props: DeliberateAny;

  renderItem = (item: PortfolioItemType) => (
    <PortfolioItem
      key={item.InventoryNumber}
      item={item}
      columns={buildingColumns}
      customDetailsButton={this.props.customDetailsButton}
    />
  );

  render() {
    return (
      <div className="portfolio-columns">
        <List
          columns={buildingColumns}
          renderItem={this.renderItem}
          {...this.props}
        />
      </div>
    );
  }
}

export default PortfolioList;
