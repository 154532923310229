export const sortByRelevance = (options, searchTerm, noMatch) => {
  const search = searchTerm.toLowerCase();

  return options
    .filter((option) => option.label?.toLowerCase().includes(search))
    .sort((a, b) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();

      // Check if either label is an exact match
      const isExactA = labelA === search;
      const isExactB = labelB === search;
      if (isExactA && !isExactB) return -1;
      if (isExactB && !isExactA) return 1;

      // Find the first index of the match within each label
      const indexA = labelA.indexOf(search);
      const indexB = labelB.indexOf(search);

      // Prioritize matches that start at the beginning of a word
      const startsAtWordA = labelA
        .split(' ')
        .some((word) => word.startsWith(search));
      const startsAtWordB = labelB
        .split(' ')
        .some((word) => word.startsWith(search));
      if (startsAtWordA && !startsAtWordB) return -1;
      if (startsAtWordB && !startsAtWordA) return 1;

      // Sort by index of the match if neither starts at the beginning of a word
      if (indexA !== indexB) return indexA - indexB;

      // Check position within a word for phrase-based matching
      const wordIndexA = labelA
        .split(' ')
        .findIndex((word) => word.includes(search));
      const wordIndexB = labelB
        .split(' ')
        .findIndex((word) => word.includes(search));
      return wordIndexA - wordIndexB;
    })
    .concat(noMatch && options.length === 0 ? noMatch : []);
};
