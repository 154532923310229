import "./ViewBuilding.scss";

import {
  closePortfolioPlanModal,
  fetchPortfolioRenters,
  togglePlanFavorite,
} from "actions/myPortfolioActions";
import ButtonCards from "components/ButtonCards/ButtonCards";
import Button from "components/buttons/Button/Button";
import ButtonRow from "components/buttons/ButtonRow/ButtonRow";
import LabelValueList from "components/LabelValueList/LabelValueList";
import Modal from "components/modals/Modal/Modal";
import PlanDetails from "components/Plans/PlanDetails/PlanDetails";
import DecreasedActivityServiceOrderModal from "components/serviceorders/DecreasedActivityServiceOrderModal/DecreasedActivityServiceOrderModal";
import DocumentServiceOrderModal from "components/serviceorders/DocumentServiceOrderModal/DocumentServiceOrderModal";
import ResignationServiceOrderModal from "components/serviceorders/ResignationServiceOrderModal/ResignationServiceOrderModal";
import connect from "helpers/connectWithRouter";
import am_bolig_white from "images/am_bolig-white.svg";
import am_meldinnsak_white from "images/am_meldinnsak-white.svg";
import queryString from "query-string";
// @flow
import React, { Component } from "react";
import { Link, Location, NavigateFunction } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import iconMap from "src/assets/css/gfx/location.svg";
import { DeliberateAny } from "types/DelibrateAny";

import PortfolioCaseResultsList from "./PortfolioCaseResultsList/PortfolioCaseResultsList";
import PortfolioPlansList from "./PortfolioPlansList/PortfolioPlansList";
import PortfolioRenterList from "./PortfolioRenterList/PortfolioRenterList";

import type {
  PortfolioItemType,
  PortfolioRentersType,
  PortfolioPlansType,
} from "types/myPortfolioTypes";
import type { PlanItemType } from "types/planTypes";
type PropsType = {
  location: Location;
  navigate: NavigateFunction;
  item: PortfolioItemType;
  plans: PortfolioPlansType;
  renters: PortfolioRentersType;
  fetchPortfolioRenters: (inventoryNumber: string, searchType: string) => void;
  togglePlanFavorite: (item: PlanItemType) => void;
  closePortfolioPlanModal: () => void;
  setViewBuildning: (val: boolean) => void;
};

export class UnconnectedViewBuilding extends Component {
  declare props: PropsType;

  componentDidUpdate(prevProps) {
    if (
      this.props.item.InventoryNumber &&
      prevProps.item.InventoryNumber !== this.props.item.InventoryNumber
    ) {
      const searchType =
        this.props.item.ReferenceType === 2 ? "Building" : "SurfaceOfLand";
      this.props.fetchPortfolioRenters(
        this.props.item.InventoryNumber,
        searchType
      );
    }
  }

  faultModalPath = `/reporting/faultform`;
  getModalUrl = (path: string) => `${this.props.location.pathname}/${path}`;
  decreasedActivityModalPath = () => "meld-nedsatt-aktivitet";
  resignationModalPath = () => "meld-oppsigelse";
  documentModalPath = () => "bestill-dokument";
  mapModalPath = () => "bestill-kart";
  illustrationModalPath = () => "bestill-tegning";

  handleOpenModal = (path: string) => this.props.navigate(path);

  handleReportIssue = () => {
    window.location.href = this.generateFaultFormUrl();
  };

  generateExtraServicesUrl = () =>
    `#/reporting/faultform/Tilleggstjenester?${queryString.stringify({
      district: `${this.props.item.RegionNumber}`,
      establishment: `${this.props.item.EstablishmentNumber}`,
      building: `${this.props.item.InventoryNumber}`,
      returnTab: "tab-my-portfolio",
    })}`;

    generateFaultFormUrl = () =>
    `#/reporting/faultform/Valgt plassering?${queryString.stringify({
      district: `${this.props.item.RegionNumber}`,
      establishment: `${this.props.item.EstablishmentNumber}`,
      building: `${this.props.item.InventoryNumber}`,
      returnTab: "tab-my-portfolio",
    })}`;

  previousSlide = (event) => {
    event.preventDefault();
    this.props.setViewBuildning(false);
  };

  render() {
    return (
      <div>
        <div className="slide-navigation sticky-top max-width-large">
          <Link
            to="Tillbake"
            onClick={(e) => this.previousSlide(e)}
            className="prev js-prev"
          >
            <span className="hide-for-small">Tilbake</span>
          </Link>
          <h2>Vis bygning</h2>
        </div>

        <div className="sticky-middle scrollable max-width-large">
          <h1>{this.props.item.Name}</h1>

          <div className="margin">
            <LabelValueList
              items={[
                { label: "Inventarnr", value: this.props.item.InventoryNumber },
                { label: "Distrikt", value: this.props.item.RegionName },
                {
                  label: "Etablissement",
                  value: this.props.item.EstablishmentName,
                },
                {
                  label: "KNGR/GNR/BNR/SNR",
                  value: this.props.item.KnrGnrBnrSnr,
                },
                {
                  label: "Bygningstype",
                  value: this.props.item.BuildingTypeName,
                },
                {
                  label: "Totalt BTA",
                  value: `${this.props.item.TotalBTA} m²`,
                },
                { label: "Byggeår", value: this.props.item.BuiltYear },
              ]}
              style={{
                color: "grey",
                border: true,
                padding: true,
              }}
            />
          </div>

          <div className="margin">
            <ButtonRow>
              <ButtonRow.Left
                color="red"
                onClick={() =>
                  this.handleOpenModal(this.getModalUrl(this.resignationModalPath()))
                }
              >
                Meld ønske om oppsigelse
              </ButtonRow.Left>
              <ButtonRow.Left
                color="red"
                onClick={() =>
                  this.handleOpenModal(this.getModalUrl(this.decreasedActivityModalPath()))
                }
              >
                Meld nedsatt aktivitet
              </ButtonRow.Left>
            </ButtonRow>
            <ResignationServiceOrderModal
              path={this.resignationModalPath()}
              inventoryNumber={Number(this.props.item.InventoryNumber)}
              inventoryName={this.props.item.Name}
              regionNumber={this.props.item.RegionNumber}
              keyCmObj={this.props.item.KeyCmObj}
            />
            <DecreasedActivityServiceOrderModal
              path={this.decreasedActivityModalPath()}
              inventoryNumber={Number(this.props.item.InventoryNumber)}
              inventoryName={this.props.item.Name}
            />
          </div>

          <Tabs className="tabs-wrapper">
            <TabList className="tabs">
              <Tab>Leietakere</Tab>
              <Tab>Feil og mangler</Tab>
              <Tab>Tilleggstjenester</Tab>
              <Tab>Bestill dokument/tegning/kart</Tab>
              <Tab>Prosjekter og tiltak</Tab>
            </TabList>

            <TabPanel>
              <PortfolioRenterList
                items={this.props.renters.items}
                isLoading={this.props.renters.isLoading}
                totalBTA={this.props.item.TotalBTA}
              />
            </TabPanel>

            <TabPanel>
              <div className="row margin">
                <Button color="red" onClick={this.handleReportIssue}>
                  Meld inn ny sak på dette inventaret
                </Button>
              </div>

              <PortfolioCaseResultsList
                building={this.props.item.InventoryNumber}
                typeFilter="Feilmelding"
              />
            </TabPanel>

            <TabPanel>
              <div className="row margin">
                <p>
                  <a href={this.generateExtraServicesUrl()}>
                    Bestill ny tilleggstjeneste på dette inventaret
                  </a>
                </p>
              </div>
              <PortfolioCaseResultsList
                building={this.props.item.InventoryNumber}
                typeFilter="Tilleggstjeneste"
              />
            </TabPanel>

            <TabPanel>
              <p>
                Her kan du bestille dokumenter,tegninger og kart knyttet til
                dette inventaret. Forspørselen behandles manuelt og det kan være
                opptil 3 ukers ventetid.
              </p>

              <ButtonCards
                cards={[
                  {
                    header: "Bestill dokument",
                    description:
                      "Dokumenter kan f.eks være branndokumentasjon.",
                    iconSrc: am_bolig_white,
                    onClick: () =>
                      this.handleOpenModal(this.getModalUrl(this.documentModalPath())),
                  },
                  {
                    header: "Bestill tegning",
                    description:
                      "Tegninger er byggets ulike arkitekttegninger" +
                      " og andre relevante tegninger. ",
                    iconSrc: am_meldinnsak_white,
                    onClick: () =>
                      this.handleOpenModal(this.getModalUrl(this.illustrationModalPath())),
                  },
                  {
                    header: "Bestill kart",
                    description:
                      "Kart viser byggets plassering på området og uteareal.",
                    iconSrc: iconMap,
                    onClick: () => this.handleOpenModal(this.getModalUrl(this.mapModalPath())),
                  },
                ]}
              />
              <DocumentServiceOrderModal
                title="Bestill dokument"
                path={this.documentModalPath()}
                inventoryNumber={Number(this.props.item.InventoryNumber)}
                serviceOrderTitle={`Bestilling av dokument for «${this.props.item.Name}»`}
              />
              <DocumentServiceOrderModal
                title="Bestill tegning"
                path={this.illustrationModalPath()}
                inventoryNumber={Number(this.props.item.InventoryNumber)}
                serviceOrderTitle={`Bestilling av tegning for «${this.props.item.Name}»`}
              />
              <DocumentServiceOrderModal
                title="Bestill kart"
                path={this.mapModalPath()}
                inventoryNumber={Number(this.props.item.InventoryNumber)}
                serviceOrderTitle={`Bestilling av kart for «${this.props.item.Name}»`}
              />
            </TabPanel>

            <TabPanel>
              <p>Aktuelle prosjekter og tiltak på dette inventaret.</p>
              <PortfolioPlansList
                items={this.props.plans.items}
                isLoading={this.props.plans.isLoading}
              />
              <Modal
                isOpen={this.props.plans.showModal}
                onClose={this.props.closePortfolioPlanModal}
                ariaLabel="Vis plan"
                size="large"
              >
                {this.props.plans.selectedPlan && (
                  <PlanDetails
                    plan={this.props.plans.selectedPlan}
                    togglePlanFavorite={this.props.togglePlanFavorite}
                  />
                )}
              </Modal>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    plans: state.myPortfolio.plans,
    renters: state.myPortfolio.renters,
  }),
  {
    fetchPortfolioRenters,
    closePortfolioPlanModal,
    togglePlanFavorite,
  }
)(UnconnectedViewBuilding);
