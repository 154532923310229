import AppContainer from '../AppContainer';
import ErrorBoundary from './ErrorBoundry';

const Content = () => {
  return (
    <ErrorBoundary>
      <AppContainer />
    </ErrorBoundary>
  );
};

export default Content;
