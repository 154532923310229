import {
  buildingsURL,
  establishmentsURL,
  registerLocationServiceOrderURL,
  staticFilterOptionsURL,
} from 'api/basenproff/basenproff_api';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Checkbox from 'components/form/Checkbox/Checkbox';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import Loader from 'components/Loader/Loader';
import ApplicationSummaryModal from 'components/ReportIssue/ApplicationSummaryModal';
import CategoryFaultTypeSelect from 'components/ReportIssue/CategoryFaultTypeSelect';
import ContactDetails from 'components/ReportIssue/ContactDetails';
import LocationInfoSelect from 'components/ReportIssue/LocationInfoSelect';
import { useUser } from 'contexts/index';
import generateFormData from 'helpers/fileHelpers';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Space from 'src/layout/Space';
import { DeliberateAny } from 'types/DelibrateAny';
import * as yup from 'yup';

import {
  AdditionalConfirmations,
  AttachmentSection,
  InputField,
  TextAreaField,
} from './ReportingFormComponents';

const FaultFormChoose = ({
  formHolder,
  setFormHolder,
  setLoading,
}: DeliberateAny) => {
  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();
  const initDistrict = searchParams.get('district');
  if (initDistrict) formHolder.regionNumber = initDistrict;

  const initEstablishment = searchParams.get('establishment');
  if (initEstablishment) formHolder.establishment = initEstablishment;

  const initBuilding = searchParams.get('building');
  if (initBuilding) formHolder.building = initBuilding;

  const { sendRequest, requestLoading } = useAxios();
  const { sendRequest: sendRequestOptions } = useAxios();
  const { hasUser } = useUser();
  const [formErrors, setFormErrors] = useState<DeliberateAny[]>([]);
  const [files, setFiles] = useState<DeliberateAny>([]);

  const [optionsLoading, setOptionsLoading] = useState({
    contracts: false,
    region: false,
    establishment: false,
    building: false,
  });

  const [regionOptions, setRegionOptions] = useState<DeliberateAny[]>([]);
  const [establishmentOptions, setEstablishmentOptions] = useState<
    DeliberateAny[]
  >([]);
  const [buildingOptions, setBuildingOptions] = useState<DeliberateAny[]>([]);

  const [establishmentsWait, setEstablishmentsWait] = useState(null);
  const [buildingsWait, setBuildingsWait] = useState(null);

  const [deliveryDateCheckbox, setDeliveryDateCheckbox] = useState(false);

  const [confirmData, setConfirmData] = useState<DeliberateAny>();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleResponse = (result: DeliberateAny) => {
    // return;
    if (result) {
      const returnTab = searchParams.get('returnTab');
      if (returnTab) navigate(`/reporting/confirmation?returnTab=${returnTab}`);
      else navigate('/reporting/confirmation');
    }
  };

  const cleanFormData = (formData: DeliberateAny) => {
    const propertiesToRemove = [
      'Reporter',
      'Phone',
      'EmployeeNumber',
      'Email',
      'RegionNumber',
      'Establishment',
      'Building',
      'HousingArea',
      'RentalId',
      'IsUnclassified',
      'ServiceOrderType',
      'ServiceOrderErrorTypeId',
    ].map((p) => p.toLowerCase());
    const cleanedData = { ...formData };
    Object.keys(cleanedData).forEach((key) => {
      if (propertiesToRemove.includes(key.toLowerCase())) {
        delete cleanedData[key];
      } else if (key.startsWith('_')) {
        delete cleanedData[key];
      }
    });
    return cleanedData;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formData = generateFormData(
      formHolder,
      files,
      'serviceOrder',
      'Documents',
      cleanFormData,
    );
    await sendRequest(
      {
        method: 'POST',
        url: registerLocationServiceOrderURL,
        data: formData,
        withCredentials: true,
      },
      handleResponse,
    ).finally(() => setLoading(false));
  };

  const updateFormHolder = (name: string, value: DeliberateAny) => {
    setFormHolder((fh: DeliberateAny) => {
      return { ...fh, [name]: value };
    });
  };

  const fetchRegionOptions = async () => {
    setOptionsLoading((x) => {
      return { ...x, region: true };
    });
    await sendRequestOptions(
      {
        method: 'GET',
        url: staticFilterOptionsURL,
        withCredentials: true,
      },
      (r) => {
        setOptionsLoading((x) => {
          return { ...x, region: false };
        });
        setRegionOptions(r.MarketingAreas);
      },
    );
  };

  const fetchEstablishmentOptions = async (marketingArea) => {
    setOptionsLoading((x) => {
      return { ...x, establishment: true };
    });
    await sendRequestOptions(
      {
        method: 'GET',
        url: `${establishmentsURL}`,
        params: { marketingArea },
      },
      (r) => {
        setOptionsLoading((x) => {
          return { ...x, establishment: false };
        });
        setEstablishmentOptions(
          r.map((e) => {
            return { key: e.Key, value: e.Value, name: e.Name };
          }),
        );
        setEstablishmentsWait(null);
      },
    );
  };

  const fetchBuildingOptions = async (establishment) => {
    setOptionsLoading((x) => {
      return { ...x, building: true };
    });
    await sendRequestOptions(
      {
        method: 'GET',
        url: `${buildingsURL}`,
        params: {
          establishment,
        },
      },
      (r) => {
        setOptionsLoading((x) => {
          return { ...x, building: false };
        });
        setBuildingOptions(
          r.map((e) => {
            return { key: e.Key, code: e.Code, value: e.Value };
          }),
        );
        setBuildingsWait(null);
      },
    );
  };

  const updateWhenRegionChange = (formname, value) => {
    const regionNumber = value;
    const establishment = undefined;
    const building = undefined;
    setFormHolder((x) => {
      return {
        ...x,
        regionNumber,
        establishment,
        building,
        housingArea: '',
        rentalId: '',
      };
    });
  };

  const updateWhenEstablishmentChange = (formname, value) => {
    const establishment = value;
    const building = undefined;

    setFormHolder((x) => {
      return {
        ...x,
        establishment,
        building,
        housingArea: '',
      };
    });
  };

  const updateWhenBuildingChange = (_, value) => {
    const bOption = buildingOptions.find((x) => x.code === value.toString());

    const newFormValues = {
      ...formHolder,
      ...{ building: value.toString(), keyCmObj: bOption?.key },
    };

    setFormHolder(newFormValues);
  };

  useEffect(() => {
    if (hasUser) void fetchRegionOptions();
  }, [hasUser]);

  useEffect(() => {
    setBuildingOptions([]);
    setEstablishmentOptions([]);
    if (hasUser && formHolder.regionNumber && formHolder.regionNumber > -1)
      void fetchEstablishmentOptions(formHolder.regionNumber);
  }, [hasUser, formHolder.regionNumber]);

  useEffect(() => {
    setBuildingOptions([]);
    if (hasUser && formHolder.establishment && formHolder.establishment > -1)
      void fetchBuildingOptions(formHolder.establishment);
  }, [hasUser, formHolder.establishment]);

  const updateAllErrors = (errors: DeliberateAny) => {
    setFormErrors(errors);

     
    const formElements = Object.keys(formProps.form);
    const firstInputValidationError = formElements.find((el) =>
      Object.keys(errors).includes(el),
    );
    const scrollElement = document.getElementById(
      `error_${firstInputValidationError}`,
    );
    if (scrollElement) scrollElement.scrollIntoView();
  };

  const updateSingleError = (name, error) => {
    const newerrors = { ...formErrors };
    newerrors[name] = error;
    setFormErrors(newerrors);
  };

  const handleShowConfirmModal = () => {
    const getModalData = () => {
      const getRegion = () => {
        const regionName =
          regionOptions.find((r: DeliberateAny) => {
            return r.Code === formHolder.regionNumber;
          })?.Caption ?? '';

        return {
          label: 'Distrikt',
          value: regionName,
        };
      };

      const getDeliveryDate = () => {
        return formHolder.requestedDeliveryTime
          ? {
              label: 'Ønsket leveringsdato',
              value: formHolder.requestedDeliveryTime,
            }
          : {};
      };
      const getBuilding = () => {
        const buildingName = buildingOptions.find((b: DeliberateAny) => {
          return b.code === formHolder.building;
        })?.value;

        if (buildingName)
          return {
            label: 'Bygning/bygningsnummer',
            value: buildingName,
          };
        return {};
      };

      const getEstablishment = () => {
        const label = 'Etablissement';

        const currentEstablishment = establishmentOptions.find(
          (e) => e.key === `${formHolder.establishment}`,
        );

        return {
          label,
          value: currentEstablishment
            ? currentEstablishment.value
            : formHolder.establishment,
        };
      };

      const getLocation = () =>
        formHolder.location && {
          label: 'Leir eller boligadresse',
          value: formHolder.location,
        };

      const getErrorMessageType = () => {
        const cat = formHolder['_category'];

        return {
          label: 'Kategori',
          value: cat.Name,
        };
      };

      return [
        { label: 'Overskrift', value: formHolder.title },
        getRegion(),
        getEstablishment(),
        getBuilding(),
        getLocation(),
        getErrorMessageType(),
        {
          label: 'Beskriv hva henvendelsen gjelder',
          value: formHolder.description,
        },
        getDeliveryDate(),
        {
          label:
            'Samtykke til at Forsvarsbygg eller underleverandør kan låse seg inn i boenhet',
          value: formHolder.ConsentSubcontractorAccess ? 'Ja' : 'Nei',
        },
        { label: 'Hund i bo-enheten', value: formHolder.hasPet ? 'Ja' : 'Nei' },
        { label: 'Antall vedlegg', value: files.length },
      ];
    };

    const data: DeliberateAny = getModalData();

    setConfirmData(data);
    setModalIsOpen(true);
  };

  const handleDeliveryDateCheckbox = () => {
    // if user sets checkbox from true to false, reset requestedDeliveryTime
    if (deliveryDateCheckbox) {
      updateFormHolder('requestedDeliveryTime', '');
    }
    setDeliveryDateCheckbox(!deliveryDateCheckbox);
  };

  const validator = yup
    .object()
    .shape({
      title: yup.string().required('required'),
      establishment: yup.string().required('required'),
      description: yup.string().required('required'),
      sendCopyToEmail: yup.string().email('invalid'),
      email: yup.string().email('invalid').required('required'),
      CategoryId: yup.string().required('required'),
      CaseTypeId: yup.string().required('required'),
      requestedDeliveryTime: yup.string().when([], {
        is: () => deliveryDateCheckbox,
        then: (s) => s.required('required'),
      }),
      isUnclassified: yup.boolean().when([], {
        is: () => true,
        then: (s) => s.required('required'),
        otherwise: (s) =>
          s.required('required').oneOf([true, false], 'invalid'),
      }),
      reporter: yup.string().required('required'),
      phone: yup.string().required('required'),
      ioNumber: yup.string().when([], {
        is: () => formHolder['_category']?.Settings?.RequiresIONumber,
        then: (s) => s.required('required'),
        otherwise: (s) => s,
      }),
    })
    .defined();

  const formProps = {
    errors: formErrors,
    form: formHolder,
    onChange: updateFormHolder,
    updateAllValidationErrors: updateAllErrors,
    updateOneValidationError: updateSingleError,
    onSubmit: () => handleShowConfirmModal(),
    schema: validator,
  };

  return (
    <Loader isLoading={requestLoading}>
      <FormWrapper {...formProps}>
        <div className="content-group grey padded">
          <LocationInfoSelect
            userLoggedIn={hasUser}
            otherPlacement={true}
            formHolder={formHolder}
            regionNumber={formHolder.regionNumber}
            establishment={formHolder.establishment}
            building={formHolder.building}
            location={formHolder.location}
            errors={formErrors}
            regionOptions={regionOptions}
            establishmentOptions={establishmentOptions}
            buildingOptions={buildingOptions}
            updateFormHolder={updateFormHolder}
            updateWhenRegionChange={updateWhenRegionChange}
            updateWhenEstablishmentChange={updateWhenEstablishmentChange}
            updateWhenBuildingChange={updateWhenBuildingChange}
            establishmentsWait={establishmentsWait}
            buildingsWait={buildingsWait}
            loading={optionsLoading}
            datatestIdPrefix={'serviceorder-input'}
          />
          <CategoryFaultTypeSelect
            formHolder={formHolder}
            updateFormHolder={updateFormHolder}
            errors={formErrors}
          />
        </div>
        <Space height="2rem" />
        <div className="content-group grey padded">
          <InputField
            id="title"
            label="Overskrift"
            placeholder="Maks. 60 tegn"
            value={formHolder.title}
            updateField={updateFormHolder}
            maxLength={60}
          />
          <TextAreaField
            id="description"
            label="Beskriv hva henvendelsen gjelder"
            rows={5}
            value={formHolder.description}
            updateField={updateFormHolder}
          />

          {formHolder['_category']?.Settings.ShowPriceRequest && (
            <Checkbox
              errors={formErrors}
              name="IsPriceEnquiry"
              label="Jeg ønsker prisforespørsel"
              onChange={updateFormHolder}
              value={formHolder.IsPriceEnquiry}
            />
          )}

          <Checkbox
            errors={formErrors}
            name="deliveryDateCheckbox"
            label="Ønsket leveringsdato (valgfritt)"
            onChange={handleDeliveryDateCheckbox}
            value={deliveryDateCheckbox}
          />
          {deliveryDateCheckbox && (
            <InputField
              id="requestedDeliveryTime"
              value={formHolder.requestedDeliveryTime}
              updateField={updateFormHolder}
            />
          )}
        </div>
        <Space height="2rem" />
        <ContactDetails
          errors={formErrors}
          formHolder={formHolder}
          updateFormHolder={updateFormHolder}
          setFormHolder={setFormHolder}
          alternativeNames={{
            name: 'reporter',
            mobile: 'phone',
            copy: 'copyEmailAddress',
          }}
        />
        <AttachmentSection
          files={files}
          addAttachment={(file) => {
            setFiles((prev) => [...prev, { title: file.name, file }]);
          }}
          removeAttachment={(file) => {
            setFiles((prev) =>
              prev.filter((e) => e.file.preview !== file.file.preview),
            );
          }}
          updateAttachmentDescription={(fileName, fileTitle) => {
            setFiles((prev) =>
              prev.map((item) =>
                item.file.name === fileName
                  ? { ...item, title: fileTitle }
                  : item,
              ),
            );
          }}
        />
        <AdditionalConfirmations
          formHolder={formHolder}
          updateFormHolder={updateFormHolder}
          errors={formErrors}
        />
        <ButtonRow>
          <ButtonRow.Right
            color="red"
            className="btn"
            onClick={handleShowConfirmModal}
            type="submit"
            data-testid="serviceorder-button-submit"
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              Send inn
            </div>
          </ButtonRow.Right>
        </ButtonRow>
      </FormWrapper>
      {modalIsOpen && (
        <ApplicationSummaryModal
          isOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          data={confirmData && confirmData.filter((el) => el)}
          onSubmit={handleSubmit}
        />
      )}
    </Loader>
  );
};

export default FaultFormChoose;
