export const norwegianLocale = {
  name: 'no', // Locale name
  months: [
    ['Januar'],
    ['Februar'],
    ['Mars'],
    ['April'],
    ['Mai'],
    ['Juni'],
    ['Juli'],
    ['August'],
    ['September'],
    ['Oktober'],
    ['November'],
    ['Desember'],
  ],
  weekDays: [
    ['Søndag', 'S'],
    ['Mandag', 'M'],
    ['Tirsdag', 'T'],
    ['Onsdag', 'O'],
    ['Torsdag', 'T'],
    ['Fredag', 'F'],
    ['Lørdag', 'L'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'AM'],
    ['PM', 'PM'],
  ],
  rtl: false, // Right to left or not
  startOfWeek: 1, // Monday as the start of the week
};
