// @flow

import './ButtonRow.scss';

import React, { ReactNode } from 'react';
import { IWrapper } from 'src/interfaces/IWrapper';

import { DeliberateAny } from '../../../types/DelibrateAny';
import Button from '../Button/Button';
import DownloadButton from '../DownloadButton/DownloadButton';

type PropsType = {
  children?: ReactNode | Array<ReactNode>;
};

type ButtonWrapperPropsType = {
  // The flow comments below and the disabling of eslint in this file
  // is because of an issue with eslint-plugin-react, see
  // https://github.com/yannickcr/eslint-plugin-react/issues/1178
  /* :: ...PropsType, */
  /* :: ...ButtonPropsType, */

  children?: ReactNode | Array<ReactNode>;
  type?: string;
  color?: string;
  className?: string;
  disabled?: boolean;
  onClick?: (e: Event) => void;
  href?: string;
  download?: boolean;
};

const ButtonRow = (props: PropsType) => {
  const children = React.Children.toArray(props.children);
  return (
    <div className="button-row">
      <div>
        {children.filter(
          (child: DeliberateAny) =>
            child.type === ButtonRow.Left || child.type === ButtonRow.LeftOther,
        )}
      </div>
      <div>
        {children.filter(
          (child: DeliberateAny) =>
            child.type === ButtonRow.Right ||
            child.type === ButtonRow.RightOther,
        )}
      </div>
    </div>
  );
};

const renderButtons = (props: DeliberateAny) => {
  const { children, download, ...otherProps } = props;
  return download ? (
    <DownloadButton {...otherProps}>{children}</DownloadButton>
  ) : (
    <Button {...otherProps}>{children}</Button>
  );
};

ButtonRow.Left = (props: ButtonWrapperPropsType) => {
  return renderButtons(props);
};

ButtonRow.Right = (props: ButtonWrapperPropsType) => {
  return renderButtons(props);
};

ButtonRow.LeftOther = function LeftOther({ children }: IWrapper) {
  return <>{children}</>;
};

ButtonRow.RightOther = function RightOther({ children }: IWrapper) {
  return <>{children}</>;
};

export default ButtonRow;
