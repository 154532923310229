import "./Reservations.scss";

import {
  fetchLocationReservation,
  updateReservationFilter,
  updateResultsBySorting,
} from "actions/reservationActions";
import Select from "components/form/Select/Select";
import Loader from "components/Loader/Loader";
import connect from "helpers/connectWithRouter";
import { Component } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import ReservationFilter from "./ReservationFilter/ReservationFilter";

type PropsType = {
  reservationFilter: DeliberateAny & DeliberateAny[];
  // updateResultsBySorting: () => void,
  reservation: DeliberateAny & DeliberateAny[];
  fetchLocationReservation: () => void;
  updateReservationFilter: (
    key: string,
    value: string | number | boolean
  ) => void;
  reservationLocation: [];
};

export class UnconnectedReservation extends Component {
  declare props: PropsType;
  declare state: DeliberateAny;

  constructor(props: PropsType) {
    super(props);
    this.state = {
      sortingOptions: [
        { key: 1, value: "Bestillingsdato - nyest først" },
        { key: 2, value: "Bestillingsdato - eldst først" },
        { key: 3, value: "Ankomstdato - tidligst først" },
        { key: 4, value: "Ankomstdato - senest først" },
      ],
      sorting: 1,
    };
    this.props.fetchLocationReservation();
  }

  onChangeSearchType = (key: string, value: string | number | boolean) => {
    this.props.updateReservationFilter(key, value);
  };

  render() {
    return (
      <div>
        <h1>Behandle forespørsler</h1>
        <Loader isLoading={this.props.reservation.isLoading}>
          {this.props.reservationLocation && (
            <div className="flex-row margin filterLine">
              <Select
                label="Arena"
                defaultChoice="Alle"
                options={this.props.reservationLocation.map(
                  (item: DeliberateAny) => ({
                    key: item.LocationId,
                    value: item.Name,
                  })
                )}
                onChange={this.onChangeSearchType}
                value={this.props.reservationFilter.locationFilter}
                name="locationFilter"
              />
              <Select
                label="Sortering"
                options={this.state.sortingOptions}
                name="Sorting"
                onChange={(name, value) => {
                  this.setState({ sorting: value });
                }}
                value={this.state.sorting}
                autoSelectFirstOption
              />
            </div>
          )}
          <ReservationFilter sorting={this.state.sorting} />
        </Loader>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    sortingOptions: state.reservation.sortingOption,
    reservationFilter: state.reservation.filter,
    reservationLocation: state.reservation.locations,
    reservation: state.reservation,
  }),
  { fetchLocationReservation, updateResultsBySorting, updateReservationFilter }
)(UnconnectedReservation);
