import '../Select/Select.scss';

import { staticFilterOptionsURL } from 'api/basenproff/basenproff_api';
import useAxios from 'hooks/useAxios';
import _ from 'lodash/fp';
import { useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import type { CodeCaptionType } from 'types/optionsTypes';
type PropsType = {
  disabled: boolean;
  onChange: (name: string, value: string) => void;
  value?: string;
  initvalue?: string;
  defaultChoice?: string;
  name: string;
  includeShowAll: boolean;
  autoSelectFirstRegion?: boolean;
  options?: DeliberateAny[];
  'data-testid'?: string;
};

const RegionSelect = ({
  disabled = false,
  onChange,
  value,
  initvalue = '',
  defaultChoice,
  name,
  includeShowAll = true,
  autoSelectFirstRegion,
  'data-testid': dataTestId,
  options: inputOptions,
}: PropsType) => {
  const { sendRequest } = useAxios();
  const [options, setOptions] = useState(inputOptions);

  const fetchOptions = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: staticFilterOptionsURL,
      },
      (r) => {
        setOptions(r.MarketingAreas);
      },
    );
  };

  useEffect(() => {
    if (!inputOptions) fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOptions(inputOptions);
  }, [inputOptions]);

  useEffect(() => {
    const regionsAreNotSet = autoSelectFirstRegion && !options;
    const rs: CodeCaptionType[] = options || [];
    if (regionsAreNotSet && rs && rs.length && rs.length > 0 && onChange) {
      onChange(name, rs[0].code);
    }
  }, [autoSelectFirstRegion, options, name, onChange]);

  const handleChange = (event: DeliberateAny) => {
    onChange(name, event.target.value);
  };

  let firstOption = includeShowAll ? <option key="all">Vis alle</option> : '';
  firstOption = initvalue ? (
    <option key="all">{initvalue}</option>
  ) : (
    firstOption
  );
  firstOption = defaultChoice ? (
    <option key="all" value="-1" disabled>
      {defaultChoice}
    </option>
  ) : (
    firstOption
  );

  useEffect(() => {
    if (autoSelectFirstRegion && options && options.length > 0) {
      onChange(name, options[0].Code);
    }
  }, [options, autoSelectFirstRegion]);

  return (
    <div className="custom-select">
      <select
        name={name}
        onChange={handleChange}
        value={_.isNil(value) ? -1 : value}
        disabled={disabled || !options}
        data-testid={dataTestId}
      >
        {options && options.length > 0 ? (
          [
            firstOption,
            options.map(({ Code, Caption }) => (
              <option key={Code} value={Code}>
                {Caption}
              </option>
            )),
          ]
        ) : (
          <option>Laster...</option>
        )}
      </select>
    </div>
  );
};

export default RegionSelect;
