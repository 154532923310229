 
import generateFormData from 'helpers/fileHelpers';
import { END, eventChannel } from 'redux-saga';
// @flow
import { call, put, select, take } from 'redux-saga/effects';
import { DeliberateAny } from 'types/DelibrateAny';

import {
  registerBuildingAdministrativeServiceOrderURL,
  searchRentalURL,
  uploadSignedSODocumentURL,
} from '../api/basenproff/basenproff_api';
import { axiosFetch } from '../hooks/useAxios';

function createUploadFileChannel(formData: DeliberateAny, url: string) {
  return eventChannel((emitter) => {
    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };

    axiosFetch({
      url,
      data: formData,
      method: 'POST',
      onUploadProgress: onProgress,
    })
      .then((data) => {
        emitter({ success: true, data });
        emitter(END);
      })
      .catch((data) => {
        emitter({ err: true, data });
        emitter(END);
      });

    return () => {};
  });
}

function* handleSendServiceOrder(
  action: DeliberateAny,
): Generator<DeliberateAny, void, void> {
  const query = action.serviceOrder
    ? action.serviceOrder
    : yield select((state) => state.serviceOrders.serviceOrder);
  const files: DeliberateAny = yield select(
    (state) => state.serviceOrders.files,
  );
  const url = registerBuildingAdministrativeServiceOrderURL;

  if (files && files.length > 0) {
    const formData = generateFormData(
      query,
      files,
      'serviceOrder',
      'Documents',
    );
    const channel: DeliberateAny = yield call(
      createUploadFileChannel,
      formData,
      url,
    );

    while (true) {
      const response: DeliberateAny = yield take(channel);

      if (response) {
        const { progress = 0, err, success } = response;
        if (err) {
          yield put({
            type: 'SAGA_ERROR_SEND_SERVICE_ORDER',
            e: response.data,
            url,
            query,
          });
          return;
        }
        if (success) {
          yield put({ type: 'SEND_SERVICE_ORDER_SUCCESS' });
          return;
        }
        yield put({ type: 'PROGRESS_CHANGED', percentComplete: progress });
      }
    }
  } else {
    try {
      yield call(axiosFetch, { url, data: query, method: 'POST' });
      yield put({ type: 'SEND_SERVICE_ORDER_SUCCESS' });
    } catch (e) {
      yield put({ type: 'SAGA_ERROR_SEND_SERVICE_ORDER', e, url, query });
    }
  }
}

function* handleSearchRentalServiceOrder(
  action: DeliberateAny,
): Generator<DeliberateAny, void, void> {
  const url = searchRentalURL;
  const query = {
    ServiceOrderNumber: action.serviceOrderNumber,
    Dif: action.dif,
    Bra: action.bra,
    MarketingArea: action.marketingArea,
    Establishment: action.establishment,
    Building: action.building,
    StateFilter: action.stateFilter,
    TypeFilter: action.typeFilter,
    Reporter: action.reporter,
    SkipCount: action.skipCount,
    TakeCount: action.takeCount,
    RentalId: action.rentalId,
  };

  try {
    const data = yield call(axiosFetch, { url, data: query, method: 'POST' });
    yield put({
      ...query,
      searchResult: data,
      type: 'RECEIVE_RESPOND_TO_SEARCH_RENTAL_SERVICE_ORDER',
    });
  } catch (e) {
    yield put({
      type: 'SAGA_ERROR_RESPOND_TO_SEARCH_RENTAL_SERVICE_ORDER',
      e,
      url,
    });
  }
}

function* handleUploadSignedAdditionalServicesDocument(
  action: DeliberateAny,
): Generator<DeliberateAny, void, void> {
  const url = uploadSignedSODocumentURL;
  const query = {
    documentId: action.documentId,
    revision: action.revision,
    statusQueryToken: action.statusQueryToken,
  };

  try {
    yield call(axiosFetch, {
      url,
      data: query,
      method: 'POST',
      withCredentials: true,
    });
  } catch (e) {
    yield put({
      type: 'SAGA_ERROR_UPLOAD_SIGNED_ADDITIONAL_SERVICES_DOCUMENT',
      e,
      url,
      query,
    });
  }
}

export {
  handleSendServiceOrder,
  handleSearchRentalServiceOrder,
  handleUploadSignedAdditionalServicesDocument,
};
