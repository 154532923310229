// import './HousingRenewalApplicationsList.scss';

import List from 'components/List/List';
import moment from 'moment';
import { Component, ReactNode } from 'react';
import { PropWrapper } from 'types/PropNavigationBase';

import HousingRenewalApplicationItem from './HousingRenewalApplicationItem/HousingRenewalApplicationItem';

import type { HousingRenewalApplicationType } from 'types/housingAdminTypes';
import type { UncontrolledSortOptionsType } from 'types/listTypes';
type PropsType = PropWrapper & {
  items: Array<HousingRenewalApplicationType>;
  listType: string;
  uncontrolledSortOptions: UncontrolledSortOptionsType;
  noItemsMessage?: ReactNode;
};

class HousingRenewalApplicationsList extends Component {
  declare props: PropsType;

  setupColumns = () => {
    if (this.props.listType === 'unprocessed') {
      return this.baseColumns();
    } else if (this.props.listType === 'processed') {
      return [
        ...this.baseColumns(),
        this.availableColumns.DateProcessed,
        this.availableColumns.Status,
      ];
    }
    return this.baseColumns();
  };

  baseColumns = () => [
    this.availableColumns.LastName,
    this.availableColumns.FirstName,
    this.availableColumns.DateApplied,
    this.availableColumns.ServiceAreaName,
    this.availableColumns.RentalId,
    this.availableColumns.AssignmentFromDate,
    this.availableColumns.AssignmentToDate,
  ];

  availableColumns = {
    LastName: {
      name: 'LastName',
      label: 'Etternavn',
      className: 'name',
    },
    FirstName: {
      name: 'FirstName',
      label: 'Fornavn',
      className: 'name',
    },
    DateApplied: {
      label: 'Dato søkt',
      name: 'DateApplied',
      renderFunction: (application: HousingRenewalApplicationType) => (
        <div>{moment(application.DateApplied).format('DD.MM.YYYY')}</div>
      ),
      className: 'date-field',
    },
    ServiceAreaName: {
      label: 'Tjenestested',
      name: 'ServiceAreaName',
      className: 'service-area',
    },
    RentalId: {
      name: 'RentalId',
      label: 'Leie-id',
      className: 'rentalId',
    },
    AssignmentFromDate: {
      label: 'Beordring fra',
      name: 'AssignmentFromDate',
      renderFunction: (application: HousingRenewalApplicationType) => (
        <div>{moment(application.AssignmentFromDate).format('DD.MM.YYYY')}</div>
      ),
      className: 'date-field',
    },
    AssignmentToDate: {
      label: 'Beordring til',
      name: 'AssignmentToDate',
      renderFunction: (application: HousingRenewalApplicationType) => (
        <div>{moment(application.AssignmentToDate).format('DD.MM.YYYY')}</div>
      ),
      className: 'date-field',
    },
    DateProcessed: {
      label: 'Dato behandlet',
      name: 'ProcessedDate',
      renderFunction: (application: HousingRenewalApplicationType) => (
        <div>{moment(application.ProcessedDate).format('DD.MM.YYYY')}</div>
      ),
      className: 'date-field',
    },
    Status: {
      label: 'Status',
      name: 'IsApproved',
      renderFunction: (application: HousingRenewalApplicationType) =>
        application.IsApproved ? (
          <strong className="green">Godkjent</strong>
        ) : (
          <strong className="red">Avslått</strong>
        ),
      className: 'status',
    },
  };

  renderItem = (item: HousingRenewalApplicationType) => (
    <HousingRenewalApplicationItem
      key={item.Id}
      application={item}
      columns={this.setupColumns()}
      hideDetails={
        this.props.listType === 'approve-allocations' ||
        this.props.listType === 'processed'
      }
    />
  );

  render() {
    return (
      <div className={`applications-list ${this.props.listType}`}>
        {this.props.items && this.props.items.length > 0 ? (
          <List
            columns={this.setupColumns()}
            renderItem={this.renderItem}
            {...this.props}
          />
        ) : (
          this.props.noItemsMessage
        )}
        {this.props.children}
      </div>
    );
  }
}

export default HousingRenewalApplicationsList;
