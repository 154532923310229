// @flow

export function fetchSignableDocuments() {
  return { type: 'FETCH_SIGNABLE_DOCUMENTS' };
}

export function uploadSignedRentalProtocol(
  inventoryType: number,
  inventoryNumber: string,
  documentId: string,
  rentalId: string,
  statusQueryToken: string,
) {
  return {
    type: 'UPLOAD_SIGNED_RENTAL_PROTOCOL',
    inventoryType,
    inventoryNumber,
    documentId,
    rentalId,
    statusQueryToken,
  };
}

export function fetchSignedDocumentFileName(statusQueryToken: string) {
  return { type: 'FETCH_SIGNED_DOCUMENT_FILE_NAME', statusQueryToken };
}
