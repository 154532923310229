// @flow

import { fetchMyDifs } from "actions/optionsActions";
import connect from "helpers/connectWithRouter";
import React, { Component } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import Select from '../Select/Select';

import type { MyDifType } from "types/optionsTypes";
type PropsType = {
  name: string;
  value: string;
  disabled: boolean;
  fetchMyDifs: () => void;
  myDifs?: Array<MyDifType>;
  myDifsLoaded: boolean;
  onChange: (name: string, value: number | string) => void;
};

export class UnconnectedMyDifsSelect extends Component {
  declare props: PropsType;

  static defaultProps = {
    disabled: false,
  };

  constructor(props: PropsType) {
    super(props);
    this.props.fetchMyDifs();
  }

  render() {
    return (
      <Select
        onChange={this.props.onChange}
        value={this.props.value}
        disabled={this.props.disabled || !this.props.myDifsLoaded}
        options={this.props.myDifs}
        loading={!this.props.myDifsLoaded}
        name={this.props.name}
        autoSelectFirstOption
      />
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    myDifs: state.options.myDifs,
    myDifsLoaded: state.options.myDifsLoaded,
  }),
  { fetchMyDifs }
)(UnconnectedMyDifsSelect);
