import "./Cohabitant.scss";

import Input from "components/form/Input/Input";

import RadioButtonGroup from "../../components/form/RadioButtonGroup/RadioButtonGroup";
import HelpText from "../../components/HelpText/HelpText";

type PropsType = {
  onChange: (name: string, value: string | number | boolean) => void;
  nameDateOfBirth: string;
  nameLongTerm: string;
  valueDateOfBirth: string;
  valueIsLongTerm: string;
  helpText: string;
  errors: object;
};

const CohabitantInput = ({
  valueDateOfBirth,
  valueIsLongTerm,
  onChange,
  nameDateOfBirth,
  nameLongTerm,
  errors,
  helpText,
}: PropsType) => {
  return (
    <div className="cohabitant-input">
      {helpText && (
        <div className="cohabitant-help">
          <HelpText helpText={helpText} />
        </div>
      )}
      <Input
        errors={errors}
        title="Samboers fødselsdato (DD.MM.YYYY)"
        type="text"
        name={nameDateOfBirth}
        value={valueDateOfBirth}
        onChange={onChange}
      />
      <RadioButtonGroup
        errors={errors}
        legend="Hvor lenge er det siden dere fikk felles adresse i Folkeregisteret?"
        name={nameLongTerm}
        options={[
          {
            label: "9 måneder eller lengre",
            value: true,
          },
          {
            label: "Under 9 måneder",
            value: false,
          },
        ]}
        checkedOption={valueIsLongTerm}
        onChange={onChange}
      />
    </div>
  );
};

export default CohabitantInput;
