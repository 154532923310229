import { updateSingleError } from "helpers/commonReducerHelpers";

const initialState = {
  isLoading: false,
  serviceOrderSent: false,
  serviceOrder: {
    serviceOrderType: "",
  },
  files: [],
  isUnclassified: false,
  consentSubcontractor: false,
  hasPet: false,
  errors: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SEND_SERVICE_ORDER":
      return {
        ...state,
        isLoading: true,
      };

    case "SEND_SERVICE_ORDER_SUCCESS":
      return {
        ...state,
        isLoading: false,
        serviceOrderSent: true,
      };

    case "SAGA_ERROR_SEND_SERVICE_ORDER":
      return {
        ...state,
        isLoading: false,
      };

    case "UPDATE_SERVICE_ORDER":
      return {
        ...state,
        serviceOrder: {
          ...state.serviceOrder,
          [action.key]: action.value,
        },
      };

    case "UPDATE_SERVICE_ORDER_ALL_ERRORS":
      return {
        ...state,
        errors: action.errors,
      };

    case "UPDATE_SERVICE_ORDER_ONE_ERROR":
      return {
        ...state,
        errors: updateSingleError({ ...state.errors }, action),
      };

    case "ADD_FILE_SERVICE_ORDER": {
      return {
        ...state,
        files: [
          ...state.files,
          {
            file: action.item,
            title: "",
          },
        ],
      };
    }

    case "REMOVE_FILE_SERVICE_ORDER": {
      const itemToRemove = action.item;
      const updatedFiles = state.files.filter(
        (item) => item.file.name !== itemToRemove.file.name
      );

      return {
        ...state,
        files: updatedFiles,
      };
    }

    case "UPDATE_FILE_DESCRIPTION_SERVICE_ORDER": {
      const fileName = action.fileName;
      const newTitle = action.title;

      const updatedFiles = state.files.map((item) => {
        if (item.file.name === fileName) {
          return {
            ...item,
            title: newTitle,
          };
        }
        return item;
      });

      return {
        ...state,
        files: updatedFiles,
      };
    }

    case "CLEAR_SERVICE_ORDER": {
      return {
        ...initialState,
        serviceOrderSent: state.serviceOrderSent,
      };
    }

    case "CLOSE_SERVICE_ORDER_SENT_CONFIRMATION": {
      return {
        ...initialState,
      };
    }

    case "TOGGLE_IS_UNCLASSIFIED_SERVICE_ORDER": {
      return {
        ...state,
        isUnclassified: !state.isUnclassified,
      };
    }

    case "TOGGLE_CONSENT_SUBCONTRACTOR_SERVICE_ORDER": {
      return {
        ...state,
        consentSubcontractor: !state.consentSubcontractor,
        serviceOrder: {
          ...state.serviceOrder,
          didConsentSubcontractor: !state.consentSubcontractor,
        },
      };
    }

    case "TOGGLE_HAS_PET_SERVICE_ORDER": {
      return {
        ...state,
        hasPet: !state.hasPet,
        serviceOrder: {
          ...state.serviceOrder,
          hasPet: !state.hasPet,
        },
      };
    }

    case "RECEIVE_RESPOND_TO_SEARCH_RENTAL_SERVICE_ORDER": {
      return {
        ...state,
        searchQuery: action,
      };
    }

    default:
      return state;
  }
};
