import env from 'env';

const { HOUSING: housingBase } = env;

// NB NB NB pr 11.07.2022 er ikke disse testet
// det mistenkes at application/renewal/allocation
// heller burde vært hardkodet inn i url-ene
// Hvis URL-ene ender opp med å være localhost:port/Housing/HousingRenewalApplication
// så må det endres under, siden de nå leses som localhost:port/HousingRenewalApplication osv
const boligrunde = 'AllocationRound';
const housingadmin = 'HousingAdministration';
const application = 'HousingApplication';
const boligomrade = 'HousingArea';
const renewal = 'HousingRenewalApplication';
const allocation = 'HousingAllocation';

// AllocationRound
export const boligrundeAddRoundURL = `${housingBase}/${boligrunde}/addRound`;
export const boligrundeCloseRoundURL = `${housingBase}/${boligrunde}/closeRound`;
export const allocationEndRoundURL = `${housingBase}/${boligrunde}/endRound`;
export const allocationRoundURL = `${housingBase}/${boligrunde}/allocationRound`;
export const currentAllocationRoundsURL = `${housingBase}/${boligrunde}/currentAllocationRounds`;
export const previousAllocationRoundsURL = `${housingBase}/${boligrunde}/previousAllocationRounds`;
export const applicationsAsPdfURL = `${housingBase}/${boligrunde}/applicationPDFs`; // GetApplicationsAsPdf
export const boligrundeDataOptionsURL = `${housingBase}/${boligrunde}/dataOptions`;

// HousingAdministration
export const housingAdminHousinginRoundURL = `${housingBase}/${housingadmin}/HousingInRound`;
export const housingListExcelDocumentURL = `${housingBase}/${housingadmin}/HousingListExcelDocument`;
export const housingAdminDataOptionsURL = `${housingBase}/${housingadmin}/DataOptions`;
export const vacantFromStandardOffsetDaysURL = `${housingBase}/${housingadmin}/VacantFromStandardOffsetDays`;

// HousingAllocation
export const housingAllocationExcelDocumentURL = `${housingBase}/${allocation}/HousingAllocationExcelDocument`;
export const allocatedHousingsExcelDocumentURL = `${housingBase}/${allocation}/AllocatedHousingsExcelDocument`;
export const previewHousingAllocationRegistrationURL = `${housingBase}/${allocation}/PreviewHousingAllocationRegistration`;
export const registerHousingAllocationURL = `${housingBase}/${allocation}/RegisterHousingAllocations`;
export const registerSingleHousingAllocationURL = `${housingBase}/${allocation}/RegisterSingleHousingAllocation`;
export const housingAllocationsURL = `${housingBase}/${allocation}/HousingAllocations`;
export const approveHousingAllocationsURL = `${housingBase}/${allocation}/ApproveHousingAllocations`;

// HousingApplication
export const getHousingCoApplicantInfoURL = `${housingBase}/${application}/GetCoApplicantInfo`;
export const myApplicationsURL = `${housingBase}/${application}/MyApplications`;
export const myCurrentApplicationsForAreaURL = `${housingBase}/${application}/MyCurrentApplicationsForArea`;
export const getHousingTextsURL = `${housingBase}/${application}/GetHousingTexts`;
export const dataOptionsURL = `${housingBase}/${application}/DataOptions`;
export const currentAllocationRoundURL = `${housingBase}/${application}/CurrentAllocationRound`;
export const addApplicationURL = `${housingBase}/${application}/AddApplication`;
export const addRenewalApplicationURL = `${housingBase}/${application}/AddRenewalApplication`;
export const acceptAllocationURL = `${housingBase}/${application}/AcceptAllocation`;
export const rejectAllocationURL = `${housingBase}/${application}/RejectAllocation`;
export const deleteHousingApplicationURL = `${housingBase}/${application}/Delete`;

// HousingArea
export const boligomradeApplicationCountsURL = `${housingBase}/${boligomrade}/ApplicationCounts`;
export const unprocessedQuarterAppsURL = `${housingBase}/${boligomrade}/GetUnprocessedQuarterApplicationsAsPdf`;
export const sentToAllocationQuarterAppsURL = `${housingBase}/${boligomrade}/GetSentToAllocationQuarterApplicationsAsPdf`;
export const historicalQuarterAppsURL = `${housingBase}/${boligomrade}/GetHistoricalQuarterApplicationsAsPdf`;
export const housingServiceAreasURL = `${housingBase}/${boligomrade}/HousingServiceAreas`;

// HousingRenewalApplication
export const unprocessedApplicationsURL = `${housingBase}/${renewal}/UnprocessedApplications`;
export const processedApplicationsURL = `${housingBase}/${renewal}/ProcessedApplications`;
export const rejectURL = `${housingBase}/${renewal}/reject`;
export const approveURL = `${housingBase}/${renewal}/approve`;
export const commentForHousingCoordinatorURL = `${housingBase}/${renewal}/CommentForHousingCoordinator`;
export const unprocessedApplicationsCountURL = `${housingBase}/${renewal}/UnprocessedApplicationsCount`;
export const housingRenewalApplicationDeleteURL = `${housingBase}/${renewal}/Delete`;
export const getApplicationURL = `${housingBase}/${renewal}/GetApplication`;
export const getUnprocessedRenewalAppsAsPDFURL = `${housingBase}/${renewal}/GetUnprocessedRenewalApplicationsAsPdf`;
export const getProcessedRenewalAppsAsPDFURL = `${housingBase}/${renewal}/getProcessedRenewalApplicationsAsPdf`;

export default housingBase;
