// @flow

import "./Guardpage.scss";

import {
  fetchCheckInStatus,
  fetchGuestsCheckinRules,
  fetchGuestsSearch,
  fetchLocationGuests,
  sortGuestList,
  updateGuestsFilter,
} from "actions/guardPageActions";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "components/modals/ConfirmationModal/ConfirmationModal";
import connect from "helpers/connectWithRouter";
import { Component } from "react";
import { DeliberateAny } from "types/DelibrateAny";
import {
  GuestRulesType,
  GuestsFilterType,
  GuestsSearchType,
} from "types/guardPageTypes";

import GuestFilter from "./GuestFilter/GuestFilter";
import guestsColumns from "./GuestList/GuestColumns";
import GuestList from "./GuestList/GuestList";

type PropsType = {
  fetchLocationGuests: (bookingId?: string) => void;
  location: Location;
  path: string;
  search: GuestsSearchType;
  guestsRules: GuestRulesType;
  fetchGuestsSearch: (filter: GuestsFilterType) => void;
  updateGuestsFilter: (key: string, value: string | number | boolean) => void;
  fetchCheckInStatus: () => void;
  fetchGuestsCheckinRules: () => void;
  sortGuestList: () => void;
};

type StateType = {
  showCheckInSuccessModal: boolean;
  showSigningSuccessModal: boolean;
};
export class UnconnectedGuardpage extends Component {
  declare props: PropsType;
  state: StateType;

  constructor(props: PropsType) {
    super(props);
    this.props.location.pathname = this.props.path;

    this.state = {
      showCheckInSuccessModal: false,
      showSigningSuccessModal: false,
    };
    this.props.fetchLocationGuests();
    this.props.fetchCheckInStatus();
    this.props.fetchGuestsCheckinRules();
  }

  onSortColumn = (columnName: string) => {
    this.props.updateGuestsFilter("sortByColumn", columnName);
    this.props.fetchGuestsSearch({
      ...this.props.search.filter,
      sortByColumn: columnName,
    });
  };

  setPageCount = (pageCount: number) => {
    this.props.updateGuestsFilter("pageCount", pageCount);
  };

  changePageNumber = (pageNumber: number) => {
    this.props.updateGuestsFilter("pageNumber", pageNumber);
  };

  applySearch = () => {
    const firstPage = 0;
    this.props.updateGuestsFilter("pageNumber", firstPage);
    this.props.fetchGuestsSearch({
      ...this.props.search.filter,
      pageNumber: firstPage,
    });
  };

  renderGuardpage = () => (
    <div>
      <div className="margin">
        <ConfirmationModal
          isOpen={this.state.showSigningSuccessModal}
          onClose={() => this.setState({ showSigningSuccessModal: false })}
          title="Innsjekk fullført"
          infoText="Gjesten er nå sjekket inn."
        />
      </div>
    </div>
  );

  render() {
    return (
      <div className="">
        <h1>{`Vaktside ${
          this.props.guestsRules ? this.props.guestsRules.ArenaName : ""
        }`}</h1>
        <div className="row">
          <GuestFilter applySearch={this.applySearch} />
        </div>
        <div className="row">
          <Loader isLoading={this.props.search.isLoading}>
            <GuestList
              key="89"
              items={this.props.search.items}
              updateGuestFilter={this.props.updateGuestsFilter}
              uncontrolledSortOptions={{
                onSortList: this.props.sortGuestList,
                sortColumnOnMount: "FromDate",
                sortDescending: true,
              }}
              columns={guestsColumns}
              noItemsMessage={
                this.props.search.firstSearch ? (
                  ""
                ) : (
                  <p>Fant ingen gjester med valgt filter.</p>
                )
              }
              clientPaging={{
                onChangePageNumber: this.changePageNumber,
                onSetPageCount: this.setPageCount,
                pageNumber: this.props.search.filter.pageNumber,
                pageSize: this.props.search.filter.pageSize,
                pageCount: this.props.search.filter.pageCount,
                numItems: this.props.search.items
                  ? this.props.search.items.length
                  : 0,
              }}
            />
          </Loader>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    search: state.guardPage.search,
    guestsRules: state.guardPage.search.guestsInfoText,
  }),
  {
    fetchCheckInStatus,
    fetchLocationGuests,
    updateGuestsFilter,
    fetchGuestsSearch,
    fetchGuestsCheckinRules,
    sortGuestList,
  }
)(UnconnectedGuardpage);
