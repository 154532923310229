import { getSignedDocumentPath } from "constants/signatureConstants";

const initialState = {
  documents: [],
  documentsLoaded: false,
  signedDocumentLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SIGNABLE_DOCUMENTS":
      return {
        ...state,
        documentsLoaded: false,
      };

    case "RECEIVE_SIGNABLE_DOCUMENTS":
      return {
        ...state,
        documentsLoaded: true,
        documents: action.data,
      };

    case "RECEIVE_REJECT_SIGNING": {
      const documentId = action.documentId;
      const filteredItems = state.documents.filter(
        (document) => document.DocumentId !== documentId
      );

      return {
        ...state,
        documents: filteredItems,
      };
    }

    case "FETCH_SIGNED_DOCUMENT_FILE_NAME": {
      return {
        ...state,
        signedDocumentLoaded: false,
      };
    }

    case "RECEIVE_SIGNED_DOCUMENT_FILE_NAME": {
      const signedDocument = {
        to: getSignedDocumentPath(action.statusQueryToken),
        name: action.fileName,
      };

      return {
        ...state,
        signedDocument,
        signedDocumentLoaded: true,
      };
    }

    default:
      return state;
  }
};
