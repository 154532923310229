import './Stepper.scss';

import React, { FC, Fragment } from 'react';

interface IStepper {
  activeStep: number;
  allowedSteps?: number[];
  setActiveStep: (step: number) => void;
  steps: string[];
  title?: string;
  showProgressBar?: boolean;
}

const Stepper: FC<IStepper> = ({
  activeStep,
  allowedSteps,
  setActiveStep,
  steps,
  title,
  showProgressBar = true,
}) => {
  return (
    <div className="fb-stepper-steps">
      <h1>{title ?? steps[activeStep]}</h1>

      <span className="fb-xs">{`${activeStep + 1} av ${steps.length} sider`}</span>

      {showProgressBar && (
        <div className="fb-no-xs progress-bar">
          {steps?.map((step, i) => (
            <Fragment key={step}>
              {i > 0 && (
                <div
                  className={i > activeStep ? 'step-line' : 'step-line filled'}
                ></div>
              )}
              <div
                className={`${!!allowedSteps && !allowedSteps?.includes(i) ? 'not-allowed' : ''} ${i <= activeStep ? 'step active' : 'step'}`}
                onClick={() => {
                  if (!(!!allowedSteps && !allowedSteps?.includes(i))) {
                    setActiveStep(i);
                  }
                }}
              >
                <div className="step-number">{i + 1}</div>
                <div className="step-label">
                  <span>{step}</span>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default Stepper;
