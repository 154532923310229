import MainModal from 'components/modals/MainModal';
import { useModal } from 'contexts/index';
import { FC } from 'react';
import { useLocation } from 'react-use';
import { IWrapper } from 'src/interfaces/IWrapper';
import GlobalError from 'src/layout/GlobalError';
import Header from 'src/layout/Header';
import Navigation from 'src/layout/Navigation';
import { isIOS } from 'utils/helpers';

import NewDesign from './NewDesign';

const newDesignRoutes = [
  '#/booking-v2',
  '#/booking-v2/*',
  '#/sandbox',
  '#/mypage',
  '#/mypage/booking',
  '#/mypage/booking/*',
  '#/info/*',
  '#/payment*',
  '#/limited/*',
];

const ConditionalLayoutWrapper: FC<IWrapper> = ({ children }) => {
  const { modal } = useModal();
  const location = useLocation();

  const isNewDesignRoute =
    !!location?.hash &&
    (newDesignRoutes.includes(location?.hash ?? '##MISSING##') ||
      newDesignRoutes
        .filter((route) => route.includes('*'))
        .map((route) => route.split('*'))
        .map(
          (routeSplit) =>
            routeSplit
              .map((route) => {
                return (
                  (location?.hash ?? '##MISSING##').startsWith(route) ||
                  (location?.hash ?? '##MISSING##').endsWith(route)
                );
              })
              .indexOf(false) === -1,
        )
        .includes(true));
  if (isNewDesignRoute) {
    return <NewDesign>{children}</NewDesign>;
  }

  return (
    <div className="site-animator js-site-animator">
      <Header />
      <Navigation />
      <article className="content-area js-main-content">
        <main
          id="content"
          className="content-loader js-content-loader"
          role="content"
          aria-live="polite"
        >
          <div className="scrollable">
            <div className={`${isIOS() ? 'ios-scroll-fix' : ''}`}>
              {children}
            </div>
            {modal && <MainModal modalData={modal} />}
            <GlobalError />
          </div>
        </main>
        <div className="menu-dimmer js-menu-toggle"></div>
      </article>
    </div>
  );
};

export default ConditionalLayoutWrapper;
