import './BlinkOn.scss';

// BlinkOn.tsx
import React, { useEffect, useState } from 'react';

interface BlinkOnProps {
  children: React.ReactNode;
  trigger: any; // Change this type if you want to limit what can trigger the animation
}

const BlinkOn: React.FC<BlinkOnProps> = ({ children, trigger }) => {
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    // Restart the blinking effect when the trigger changes
    setIsBlinking(true);
    const timer = setTimeout(() => setIsBlinking(false), 3000); // Stop blinking after 3 seconds

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, [trigger]); // Depend on `trigger` so the effect runs when it changes

  return (
    <div className={`blink-container ${isBlinking ? 'blink' : ''}`}>
      {children}
    </div>
  );
};

export default BlinkOn;
