// @flow

import {
  rejectQuarterApplication,
  sendCommentToHousingCoordinator,
  sendMessageToQuarterApplicant,
  updateQuarterApplicationMessage,
} from 'actions/housingAdminActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Link from 'components/Link/Link';
import ListItem from 'components/ListItem/ListItem';
import MessageModal from 'components/modals/MessageModal/MessageModal';
import ModalWrapper from 'components/modals/ModalWrapper/ModalWrapper';
import RouterModal from 'components/modals/RouterModal/RouterModal';
import UserMessageList from 'components/UserMessageList/UserMessageList';
import { connectSlides } from 'helpers/connectWithRouter';
import { getLabelFromValue } from 'helpers/housingAdminHelpers';
import { stringIsEmpty } from 'helpers/valueHelpers';
import React, { Component } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropSlideNavigation } from 'types/PropNavigationBase';

import QuarterApplicationSummaryModal from './QuarterApplicationSummaryModal';

import type { ListColumnType } from 'types/listTypes';
import type { HousingDataOptionsType } from 'types/optionsTypes';
import type {
  QuarterApplicationType,
  QuarterAreaType,
} from 'types/housingAdminTypes';
type PropsType = PropSlideNavigation & {
  application: QuarterApplicationType;
  columns: Array<ListColumnType>;
  housingDataOptions: HousingDataOptionsType;
  updateQuarterApplicationMessage: (
    applicationId: string,
    messageKey: string,
    message: string,
  ) => void;
  rejectQuarterApplication: (
    applicationId: string,
    reason: string | undefined,
    areaId: string,
  ) => void;
  sendMessageToQuarterApplicant: (
    applicationId: string,
    message?: string,
  ) => void;
  sendCommentToHousingCoordinator: (
    applicationId: string,
    comment?: string,
  ) => void;
  hideDetails?: boolean;
  quarterArea: QuarterAreaType;
};

export class UnconnectedQuarterApplicationItem extends Component {
  declare props: PropsType;

  onMessageInputChange = (messageKey: string, message: string) => {
    this.props.updateQuarterApplicationMessage(
      this.props.application.Id,
      messageKey,
      message,
    );
  };

  handleOpenModal = (path: string) => {
    this.props.navigate(path);
  };

  applicationPath = () =>
    `${this.props.location.pathname}/${this.props.application.Id}`;
  commentToHousingCoordinatorPath = () => `${this.applicationPath()}/kommentar`;
  applicantMessagePath = () => `${this.applicationPath()}/melding`;
  applicantMessagesPath = () => `${this.applicationPath()}/meldinger`;
  rejectionPath = () => `${this.applicationPath()}/avslag`;
  viewSummaryPath = () => `${this.applicationPath()}/søknad`;

  ApplicantMessageModal = () => (
    <MessageModal
      path={this.applicantMessagePath()}
      title="Melding til søker"
      submit={{
        text: 'Send',
        disabled:
          this.props.application.sendingApplicantMessage ||
          stringIsEmpty(this.props.application.applicantMessage),
        onClick: () =>
          this.props.sendMessageToQuarterApplicant(
            this.props.application.Id,
            this.props.application.applicantMessage,
          ),
      }}
      message={{
        title: 'Skreddersy melding til søkeren:',
        name: 'applicantMessage',
        value: this.props.application.applicantMessage ?? '',
        onChange: this.onMessageInputChange,
      }}
    />
  );

  ApplicantMessagesModal = () => (
    <RouterModal
      title="Meldinger til søker"
      ariaLabel="Meldinger til søker"
      path={this.applicantMessagesPath()}
    >
      <UserMessageList userMessages={this.props.application.UserMessages} />
    </RouterModal>
  );

  CommentToHousingCoordinatorModal = () => (
    <MessageModal
      path={this.commentToHousingCoordinatorPath()}
      title="Kommentar til BOK-forvalter"
      submit={{
        text: 'Lagre',
        disabled:
          this.props.application.savingHousingCoordinatorComment ||
          stringIsEmpty(this.props.application.CommentsForHousingCoordinator),
        onClick: () =>
          this.props.sendCommentToHousingCoordinator(
            this.props.application.Id,
            this.props.application.CommentsForHousingCoordinator,
          ),
      }}
      message={{
        title: 'Kommentar:',
        name: 'CommentsForHousingCoordinator',
        value: this.props.application.CommentsForHousingCoordinator ?? '',
        onChange: this.onMessageInputChange,
      }}
    />
  );

  RejectApplicationModal = () => (
    <MessageModal
      path={this.rejectionPath()}
      title="Avslå søknad"
      submit={{
        text: 'Send',
        onClick: () =>
          this.props.rejectQuarterApplication(
            this.props.application.Id,
            this.props.application.rejectionMessage,
            this.props.quarterArea.Id,
          ),
        disabled:
          !this.props.application.rejectionMessage &&
          stringIsEmpty(this.props.application.rejectionMessage),
      }}
      message={{
        title: 'Skreddersy melding til søkeren',
        name: 'rejectionMessage',
        value: this.props.application.rejectionMessage,
        onChange: this.onMessageInputChange,
      }}
    />
  );

  QuarterApplicationSummaryModal = () => (
    <QuarterApplicationSummaryModal
      path={this.viewSummaryPath()}
      application={this.props.application}
    />
  );

  renderDetailsFunction = () => (
    <React.Fragment>
      <div className="details-divider">
        <div>
          <label className="margin">
            <span className="bold">Søkers særskilte behov:</span>
            <p className="italic">
              {this.props.application.HousingPreferences
                ? this.props.application.HousingPreferences
                : 'Ingen'}
            </p>
          </label>

          <label className="margin">
            <span className="bold">Beordring:</span>
            <p>
              {getLabelFromValue(
                this.props.housingDataOptions.assignmentDocumentationOptions,
                this.props.application.AssignmentDocumentation,
              )}
            </p>
          </label>

          {this.props.application.IsApplyingOutsideHousingArea && (
            <label className="margin">
              <span className="red">
                MERK: Søker utenfor sitt eget boområde. Skal derfor ikke
                prioriteres.
              </span>
            </label>
          )}
        </div>
      </div>

      <div className="details-divider">
        <Link to={this.viewSummaryPath()}>Vis hele søknaden</Link>
      </div>

      {this.props.application.UserMessages && (
        <div className="details-divider">
          <Link to={this.applicantMessagesPath()}>Meldinger til søker</Link>
        </div>
      )}

      <ModalWrapper>
        {this.QuarterApplicationSummaryModal()}
        {this.ApplicantMessageModal()}
        {this.ApplicantMessagesModal()}
        {this.CommentToHousingCoordinatorModal()}
        {this.RejectApplicationModal()}
      </ModalWrapper>

      <ButtonRow>
        <ButtonRow.Right
          color="grey"
          onClick={() =>
            this.handleOpenModal(this.commentToHousingCoordinatorPath())
          }
        >
          Kommentar til BOK-forvalter
        </ButtonRow.Right>
        <ButtonRow.Right
          color="grey"
          onClick={() => this.handleOpenModal(this.applicantMessagePath())}
        >
          Send melding til søker
        </ButtonRow.Right>
        <ButtonRow.Right
          color="grey"
          onClick={() => this.handleOpenModal(this.rejectionPath())}
        >
          Send avslag
        </ButtonRow.Right>
      </ButtonRow>
    </React.Fragment>
  );

  render() {
    return (
      <ListItem
        item={this.props.application}
        columns={this.props.columns}
        renderDetailsFunction={
          this.props.hideDetails
            ? undefined
            : () => this.renderDetailsFunction()
        }
      />
    );
  }
}

export default connectSlides(
  (state: DeliberateAny) => ({
    housingDataOptions: state.options.housingDataOptions,
    quarterArea: state.housingAdmin.quarterArea,
  }),
  {
    updateQuarterApplicationMessage,
    rejectQuarterApplication,
    sendMessageToQuarterApplicant,
    sendCommentToHousingCoordinator,
  },
)(UnconnectedQuarterApplicationItem);
