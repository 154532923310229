// @flow

import React, { Component } from 'react';
import connect from 'helpers/connectWithRouter';
import moment from 'moment';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import ModalWrapper from 'components/modals/ModalWrapper/ModalWrapper';
import DOMPurify from 'dompurify';
import {
  RESERVED_STATUSES,
  INSPECTION_STATUSES,
} from 'constants/rentalsConstants';
import { NavigateFunction } from 'react-router-dom';
import type {
  DwellingInspectionSummaryType,
  RentalItemType,
  ReceiptInspectionType,
} from 'types/myRentalsTypes';
import {
  MOVING_INTO_QUARTER,
  RETURN_CONTRACT_TYPE_STRING,
} from 'helpers/inspectionHelpers';
import { DeliberateAny } from 'types/DelibrateAny';
import InspectionTimeProposalYesNoModal from './InspectionTimeProposalYesNoModal';

type DwellingInspectionInfoPropsType = {
  rentalItem: RentalItemType;
  receiptInspection: Array<ReceiptInspectionType>;
  activeInspection: DwellingInspectionSummaryType;
  befaringInfoTekst: string;
  navigate: NavigateFunction;
  location: Location;
};

export class UnconnectedDwellingInspectionInfo extends Component {
  props!: DwellingInspectionInfoPropsType;

  rentalItemPath = () =>
    `${this.props.location.pathname}/${this.props.rentalItem.RentalId}`;
  inspectionTimeProposalYesNoModalPath = () =>
    `${this.rentalItemPath()}/svar-paa-tidspunkt`;
  inspectionPath = () =>
    `/befaring/${this.props.rentalItem.RentalId}/${this.props.activeInspection.InspectionId}`;
  showInspectionTimeBtn = () =>
    this.props.activeInspection.ReservedStatus ===
      RESERVED_STATUSES.OUR_PROPOSAL &&
    moment()
      .startOf('day')
      .diff(
        moment(this.props.activeInspection.InspectionTime).startOf('day'),
        'days',
      ) <= 0 &&
    this.props.activeInspection.InspectionStatus ===
      INSPECTION_STATUSES.REGISTERED;
  showAnswerInspectionBtn = () =>
    MOVING_INTO_QUARTER(
      this.props.rentalItem.ContractType,
      this.props.activeInspection.IsMovingIn,
    ) &&
    this.props.activeInspection.InspectionStatus ===
      INSPECTION_STATUSES.REGISTERED &&
    moment()
      .startOf('day')
      .diff(this.props.activeInspection.InspectionTime, 'days') >= 0;
  answerTimeSent = () =>
    this.props.receiptInspection.find(
      (inspection) =>
        inspection.RentalId === this.props.rentalItem.RentalId &&
        inspection.InspectionAction === 'inspection_time_sent',
    );

  render() {
    const answerSent = this.answerTimeSent();
    const showTimeInList =
      RETURN_CONTRACT_TYPE_STRING(this.props.rentalItem.ContractType) !==
        'Quarter' || this.props.activeInspection.IsMovingIn === false;
    const showInspectionText =
      // MOVING_INTO_QUARTER &&
      this.props.activeInspection.ReservedStatus >=
        RESERVED_STATUSES.OUR_PROPOSAL ||
      this.props.activeInspection.ReservedStatus >=
        RESERVED_STATUSES.CUSTOMER_PROPOSAL;

    return (
      <div className="row half-margin">
        <p>
          <strong
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                this.props.rentalItem.InspectionInfoHeading,
              ),
            }}
          />
        </p>
        <ul className="margin">
          <li>
            <strong>Dato:</strong>{' '}
            {moment(this.props.activeInspection.InspectionTime).format(
              'DD.MM.YYYY',
            )}
          </li>
          {showTimeInList && (
            <li>
              <strong>Tid:</strong>{' '}
              {moment(this.props.activeInspection.InspectionTime).format(
                'HH:mm',
              )}
            </li>
          )}
        </ul>
        {showInspectionText && (
          <div
            className="margin"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(this.props.befaringInfoTekst),
            }}
          />
        )}

        {answerSent && answerSent.IsSuccess && (
          <div className="row half-margin">
            <p>
              <strong>Svar på tidspunkt vellykket.</strong>
            </p>
          </div>
        )}

        <ButtonRow>
          {this.showInspectionTimeBtn() && (
            <ButtonRow.Left
              color="red"
              onClick={() =>
                this.props.navigate(this.inspectionTimeProposalYesNoModalPath())
              }
            >
              Svar på tidspunkt
            </ButtonRow.Left>
          )}
          {this.showAnswerInspectionBtn() && (
            <ButtonRow.Left
              color="red"
              onClick={() => this.props.navigate(this.inspectionPath())}
            >
              Gjennomfør befaring
            </ButtonRow.Left>
          )}
        </ButtonRow>

        <ModalWrapper>
          <InspectionTimeProposalYesNoModal
            path={this.inspectionTimeProposalYesNoModalPath()}
            onClose={() => this.props.navigate(this.rentalItemPath())}
            inspectionId={this.props.activeInspection.InspectionId}
            rentalItem={this.props.rentalItem}
            activeInspection={this.props.activeInspection}
          />
        </ModalWrapper>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    receiptInspection: state.myRentals.rentals.receiptInspection,
  }),
  {},
)(UnconnectedDwellingInspectionInfo);
