// @flow

import { combineReducers } from 'redux';

import sandbox from './sandboxReducer';
import options from './optionsReducer';
import myPortfolio from './myPortfolioReducer';
import housingAdmin from './housingAdminReducer';
import housing from './housingReducer';
import signableDocuments from './signableDocumentsReducer';
import myRentals from './myRentalsReducer';
import errors from './errorsReducer';
import serviceOrders from './serviceOrdersReducer';
import inspection from './inspectionReducer';
import guardPage from './guardPageReducer';
import reporting from './reportingReducer';
import reservation from './reservationsReducer';

export default combineReducers({
  sandbox,
  options,
  myPortfolio,
  housingAdmin,
  housing,
  signableDocuments,
  myRentals,
  errors,
  serviceOrders,
  inspection,
  guardPage,
  reporting,
  reservation,
});
