// Housing

export const HousingTexts = {
  DetailsCards: {
    housingDescription: 'Forsvarets boliger varierer i størrelse og leies ut umøblert.' +
        ' Leieperioden beregnes fra botid med en maks tid på 3 år, men kan forlenges avhengig av hvilken sone boligen ligger i.' +
        ' Botiden løper fra den dato du ble disponert til boområdet og var i målgruppen for forsvarets botilbud, uavhengig av om du faktisk benytter botilbudet.' +
        ' Vær oppmerksom på at alder og standard på Forsvarets boliger er svært varierende.',
    quarterDescription: 'Kvarter er ett eller flere rom, eller en liten hybelleilighet, som ' +
        'normalt leies ut møblert. Rommene skal så langt som mulig utrustes ihht. standard ' +
        'for hotell i mellomklasse. Vær oppmerksom på at kjæledyr normalt ikke er tillatt.',
  },
};

// Context

export const ContextTexts = {
  OutOfContextText: (context) => `"use${context}" hook must be used within a "${context}Provider" component!`,
};

export default { HousingTexts, ContextTexts };
