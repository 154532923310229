export function validateNorwegianSSN(ssn) {
  if (!/^\d{11}$/.test(ssn)) return false;

   
  let [day, month, year] = splitDateParts(ssn);
  const individualNumbers = ssn.substring(6, 9);
  const controlDigits = ssn.substring(9);

  // Adjust day and month for D-numbers and H-numbers
  day = adjustDayForDNumber(day);
  month = adjustMonthForHNumber(month);

  if (!isValidDate(year, month, day)) return false;

  // Calculate expected control digits and compare them
  const expectedControlDigits = calculateControlDigits(ssn);
  return controlDigits === expectedControlDigits;
}

function splitDateParts(ssn) {
  const dayPart = parseInt(ssn.substring(0, 2), 10);
  const monthPart = parseInt(ssn.substring(2, 4), 10);
  const yearPart = parseInt(ssn.substring(4, 6), 10);
  return [dayPart, monthPart, yearPart];
}

function adjustDayForDNumber(day) {
  return day > 40 ? day - 40 : day;
}

function adjustMonthForHNumber(month) {
  return month > 80 ? month - 80 : month;
}

function isValidDate(year, month, day) {
  year += year > 39 ? 1900 : 2000;
  const date = new Date(year, month - 1, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

export function calculateControlDigits(ssn) {
  const weightsFirst = [3, 7, 6, 1, 8, 9, 4, 5, 2];
  const weightsSecond = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];

  const digits = ssn.split('').map(Number);
  const firstControlDigit = calculateControlDigit(digits, weightsFirst);
  if (firstControlDigit === null) return false;

  const secondControlDigit = calculateControlDigit(
    [...digits, firstControlDigit],
    weightsSecond,
  );
  if (secondControlDigit === null) return false;

  return `${firstControlDigit}${secondControlDigit}`;
}

export function calculateControlDigit(digits, weights) {
  const sum = digits
    .slice(0, weights.length)
    .reduce((acc, digit, index) => acc + digit * weights[index], 0);
  const remainder = sum % 11;
  return remainder === 1 ? null : remainder === 0 ? 0 : 11 - remainder;
}
