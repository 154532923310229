import env from 'env';

const { PROFF: proffBaseUrl } = env;

const buildinginfo = 'BuildingInformation';
const deadlines = 'Deadlines';
const depositData = 'DepositData';
const documents = 'Documents';
const quarters = 'Quarters';
const rentalContract = 'RentalContract';
const rentalDocuments = 'RentalDocuments';
const rentalProtocol = 'RentalProtocol';
const serviceOptions = 'ServiceOptions';
const serviceOrder = 'ServiceOrder';
const signature = 'Signature';

export { proffBaseUrl };

// BuildingInformation
export const myPortfolioURL = `${proffBaseUrl}/${buildinginfo}/MyPortfolio`;
export const myDifsURL = `${proffBaseUrl}/${buildinginfo}/MyDifs`;
export const buildingInformationURL = `${proffBaseUrl}/BuildingInformation/`;

// BuildingInformation: Endepunkter som ikke ser ut til å refereres direkte (adresser bygges dynamisk vha. buildingInformationURL)
// export const buildingSearchURL = `${baseBasenProff}/${buildinginfo}/BuildingSearch`;
// export const surfaceOfLandSearchURL = `${baseBasenProff}/${buildinginfo}/SurfaceOfLandSearch`;
// export const buildingTenantsURL = `${baseBasenProff}/${buildinginfo}/BuildingTenants`;
// export const surfaceOfLandTenantsURL = `${baseBasenProff}/${buildinginfo}/SurfaceOfLandTenants`;
// export const buildingTypesURL = `${baseBasenProff}/${buildinginfo}/BuildingTypes`;
// export const surfaceTypesURL = `${baseBasenProff}/${buildinginfo}/SurfaceTypes`;

// Deadlines
export const proffDeadlinesURL = `${proffBaseUrl}/${deadlines}/Deadlines`;

// DepositData
export const depositDataURL = `${proffBaseUrl}/${depositData}/DepositData`;
export const uploadDepositDataURL = `${proffBaseUrl}/${depositData}/UploadDepositData`;

// Documents
export const documentsURL = `${proffBaseUrl}/${documents}`;
export const l3URL = `${proffBaseUrl}/${documents}/L3`;
export const shareL3ItemURL = `${proffBaseUrl}/${documents}/ShareL3Item`;

// Quarters
export const proffQuartersURL = `${proffBaseUrl}/${quarters}/Quarters`;

// RentalContract
export const myRentalContractsURL = `${proffBaseUrl}/${rentalContract}/MyRentalContracts`;
export const showTabMyRentalsURL = `${proffBaseUrl}/${rentalContract}/ShowTabMyRentals`;
export const rejectionReasonsURL = `${proffBaseUrl}/${rentalContract}/RejectionReasons`;
export const getRentalContractTextsURL = `${proffBaseUrl}/${rentalContract}/GetRentalContractTexts`;
export const documentLinksURL = `${proffBaseUrl}/${rentalContract}/DocumentLinks`;
export const respontToInspectionTimeURL = `${proffBaseUrl}/${rentalContract}/RespondToProposedInspectionTime`;
export const getInspectionFormByCaptionURL = `${proffBaseUrl}/${rentalContract}/GetInspectionFormByCaption`;
export const getInspectionFormURL = `${proffBaseUrl}/${rentalContract}/GetInspectionForm`;
export const saveInspectionURL = `${proffBaseUrl}/${rentalContract}/SaveInspection`;

// RentalDocuments
export const documentFileURL = `${proffBaseUrl}/${rentalDocuments}/DocumentFile`;
export const rejectSigningURL = `${proffBaseUrl}/${rentalDocuments}/RejectSigning`;

// RentalProtocol
export const signableDocumentsURL = `${proffBaseUrl}/${rentalProtocol}/SignableDocuments`;

// ServiceOptions
export const catalogURL = `${proffBaseUrl}/${serviceOptions}/AdditionalServicesMainCategories`;
export const pilotWarningURL = `${proffBaseUrl}/${serviceOptions}/PilotWarning`;

// ServiceOrder
export const rapporterSakURL = `${proffBaseUrl}/${serviceOrder}/rapportersak`;
export const registerLocationServiceOrderURL = `${proffBaseUrl}/${serviceOrder}/RegisterLocationServiceOrder`;
export const registerRentalServiceOrderURL = `${proffBaseUrl}/${serviceOrder}/registerrentalserviceorder`;
export const dokumentURL = `${proffBaseUrl}/${serviceOrder}/Dokument`; // Brukes denne?
export const staticFilterOptionsURL = `${proffBaseUrl}/${serviceOrder}/StaticFilterOptions`;
export const reportingUsersURL = `${proffBaseUrl}/${serviceOrder}/ReportingUsers`;
export const serviceOrderTypesURL = `${proffBaseUrl}/${serviceOrder}/ServiceOrderTypes`;
export const serviceOrderStatesURL = `${proffBaseUrl}/${serviceOrder}/ServiceOrderStates`;
export const difsURL = `${proffBaseUrl}/${serviceOrder}/Difs`;
export const brasURL = `${proffBaseUrl}/${serviceOrder}/Bras`;
export const marketingAreasURL = `${proffBaseUrl}/${serviceOrder}/MarketingAreas`;
export const establishmentsURL = `${proffBaseUrl}/${serviceOrder}/establishments`;
export const buildingsURL = `${proffBaseUrl}/${serviceOrder}/buildings`;
export const registerShortRentalRenewalServiceOrder = `${proffBaseUrl}/${serviceOrder}/RegisterShortRentalRenewalServiceOrder`;
export const registerRentalCancellationServiceOrder = `${proffBaseUrl}/${serviceOrder}/RegisterRentalCancellationServiceOrder`;
export const registerBuildingRentalTerminationServiceOrderURL = `${proffBaseUrl}/${serviceOrder}/RegisterBuildingRentalTerminationServiceOrder`;
export const registerBuildingAdministrativeServiceOrderURL = `${proffBaseUrl}/${serviceOrder}/RegisterBuildingAdministrativeServiceOrder`;
export const registerQuestionURL = `${proffBaseUrl}/${serviceOrder}/RegisterQuestionServiceOrder`;
export const registerAdditionalInfoURL = `${proffBaseUrl}/${serviceOrder}/RegisterAdditionalInfo`; // Ting som bruker denne må nok skrives om litt...
export const registerComplaintURL = `${proffBaseUrl}/${serviceOrder}/RegisterComplaint`;
export const registerDunningURL = `${proffBaseUrl}/${serviceOrder}/RegisterDunning`; // Ting som bruker denne må nok skrives om litt...
export const searchRentalURL = `${proffBaseUrl}/${serviceOrder}/searchRental`;
export const proffSearchURL = `${proffBaseUrl}/${serviceOrder}/Search`;
export const serviceOrderCategoriesURL = `${proffBaseUrl}/${serviceOrder}/ServiceOrderCategories`;
export const showTabServiceOrderURL = `${proffBaseUrl}/${serviceOrder}/ShowTabServiceOrder`;
export const SOonEmailURL = `${proffBaseUrl}/${serviceOrder}/ServiceOrdersOnEmail`;
export const myAdditionalServiceOrdersURL = `${proffBaseUrl}/${serviceOrder}/MyAdditionalServiceOrders`;
export const additionalServicesWithDocumentsToSignByCurrentUserURL = `${proffBaseUrl}/${serviceOrder}/AdditionalServicesWithDocumentsToSignByCurrentUser`;

// ServiceOrder: Report issue
export const errorTypesURL = `${proffBaseUrl}/${serviceOrder}/errortypes`;

// ServiceOrder: Service Orders for user without BasenProff-access
export const searchSOforCurrentUserURL = `${proffBaseUrl}/${serviceOrder}/searchserviceordersforcurrentuser`;
export const registerAdditionalInfoForCurrentUserURL = `${proffBaseUrl}/${serviceOrder}/RegisterAdditionalInfoForCurrentUser`;
export const registerComplaintForCurrentUserURL = `${proffBaseUrl}/${serviceOrder}/RegisterComplaintForCurrentUser`;
export const registerDunningForCurrentUserURL = `${proffBaseUrl}/${serviceOrder}/RegisterDunningForCurrentUser`;

// Signature
export const createSignatureJobXpandDocURL = `${proffBaseUrl}/${signature}/CreateSignatureJobForXpandDocument`;
export const documentFileNameURL = `${proffBaseUrl}/${signature}/DocumentFileName`;
export const getSignedDocumentPathURL = `${proffBaseUrl}/${signature}/signedpdf/`;
export const uploadSignedSODocumentURL = `${proffBaseUrl}/${signature}/UploadSignedServiceOrderDocument`;
export const uploadSignedRentalProtocolURL = `${proffBaseUrl}/${signature}/UploadSignedRentalProtocol`;
export const uploadSignedRentalContractDocumentURL = `${proffBaseUrl}/${signature}/UploadSignedRentalContractDocument`;

export default proffBaseUrl;
