import './LivingCondition.scss';

import {
  addCoApplicant,
  fetchHousingCoApplicant,
  fetchHousingInfoText,
  removeCoApplicant,
  removeCoApplicantAllButFirst,
  setPostalCode,
  updateAllApplicationErrors,
  updateApplication,
  updateSingleApplicationError,
} from 'actions/housingActions';
import BoxClose from 'components/buttons/BoxClose/BoxClose';
import Button from 'components/buttons/Button/Button';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import DateInput from 'components/DateInput/DateInput';
import FormErrors from 'components/form/FormErrors';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import Input from 'components/form/Input/Input';
import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import TextArea from 'components/form/TextArea/TextArea';
import HelpText from 'components/HelpText/HelpText';
import Loader from 'components/Loader/Loader';
import DOMPurify from 'dompurify';
import connect from 'helpers/connectWithRouter';
import {
  getSharedQuarterStr,
  IS_MAIN_APPLICANT_LABEL,
  LIVING_CONDITION_OWNS_RESIDENT,
  LIVING_CONDITION_RENTS_MILITARY,
  LIVING_CONDITION_RENTS_SIVIL,
} from 'helpers/housingHelpers';
import { upperCaseFirstLetter } from 'helpers/valueHelpers';
import _ from 'lodash/fp';
import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropSlideNavigation } from 'types/PropNavigationBase';
import { getSiblingPath } from 'utils/helpers';
import * as yup from 'yup';

import type { KeyValueType } from 'types/commonTypes';
import type {
  InfoTextType,
  HousingApplicationType,
  QuarterApplicationType,
} from 'types/housingTypes';
import moment from 'moment';
const showPostalCode = (currentHousingSituation, isRenewal) =>
  _.includes(currentHousingSituation, [
    LIVING_CONDITION_OWNS_RESIDENT,
    LIVING_CONDITION_RENTS_SIVIL,
  ]) && !isRenewal;

const showCohabitantOption = (application) => {
  return application.type === 'housing' && !application.isRenewal;
};

type PropsType = PropSlideNavigation & {
  nextSlidePath: string;
  fetchHousingInfoText: () => void;
  fetchHousingCoApplicant: (string) => void;
  housingDataOptionsLoaded: boolean;
  isLoading: boolean;
  errors: DeliberateAny;
  application: HousingApplicationType | QuarterApplicationType;
  applicationType: string;
  infoText: InfoTextType;
  livingConditionOptions: Array<KeyValueType>;
  updateApplication: (string, mixed) => void;
  setPostalCode: (string) => void;
  addCoApplicant: () => void;
  removeCoApplicant: (number) => void;
  removeCoApplicantAllButFirst: (number?: number) => void;
  updateAllApplicationErrors: (errors: object) => void;
  updateSingleApplicationError: (name: string, errors: object) => void;
  defaultTo: string;
};

const UnconnectedLivingCondition = (props: PropsType) => {
  useEffect(() => {
    if (!props.housingDataOptionsLoaded) {
      props.goToSlide(
        getSiblingPath(props.location.pathname, props.defaultTo),
        true,
      );
    }
    if (!props.application.wantedFromDate) {
      props.updateApplication(
        'wantedFromDate',
        props.application.assignmentFromDate,
      );
    }
    if (!props.application.wantedToDate && props.application.isRenewal) {
      props.updateApplication(
        'wantedToDate',
        props.application.assignmentToDate,
      );
    }
    if (
      !props.application.wantedToDate &&
      props.application.applicantCategory === '9'
    ) {
      props.updateApplication(
        'wantedToDate',
        props.application.assignmentToDate.year() === 9999
          ? moment(props.application.assignmentFromDate).add(2, 'y')
          : props.application.assignmentToDate,
      );
    }
    props.fetchHousingCoApplicant(props.application.housingAreaId);
  }, []);

  useEffect(() => {
    setIsRentingMilitarySituation(props.application.currentHousingSituation_1);
  }, [props.application.currentHousingSituation_1]);

  useEffect(() => {
    if (props.application.coApplicantInAreasStatus) {
      setCoApplicantInAreasStatus(props.application.coApplicantInAreasStatus);
    }
  }, [props.application]);

  const [disableNextButton, setDisableNextButton] = useState(false);
  const [applicantHasChanged, setApplicantHasChanged] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState<string>('');
  const [isRentingMilitarySituation, setIsRentingMilitarySituation] =
    useState(false);
  const [coApplicantInAreasStatus, setCoApplicantInAreasStatus] =
    useState<DeliberateAny>([]);
  const [missingCoApplicantName, setMissingCoApplicantName] =
    useState<boolean>(false);
  const [
    missingCoApplicantEmployeeNumber,
    setMissingCoApplicantEmployeeNumber,
  ] = useState<boolean>(false);

  const myEmployeeNumber = props.application.employeeNumber;

  // True if current user exist in response from rest-api
  const existCoApplicant = (employeeNumber) =>
    coApplicantInAreasStatus.coApplicants.find((en) => en === employeeNumber);

  // Oppgir eget ansattnummer ved innlegging av med-/hovedsøkernummer
  const employeeIsSelf = props.application.coApplicants.some(
    (el) => el.employeeNumber === myEmployeeNumber,
  );

  const isCoApplicant = () =>
    !coApplicantInAreasStatus.isMainApplicant &&
    coApplicantInAreasStatus.isCoApplicant;
  const isMainApplicant = () => coApplicantInAreasStatus.isMainApplicant;

  const onSharedHousingAcceptableChange = (
    name: string,
    sharedHousingAcceptable: string | number | boolean,
  ) => {
    if (sharedHousingAcceptable) {
      props.addCoApplicant();
    } else {
      props.updateApplication('coApplicants', []);
      props.updateApplication('isMainApplicant', undefined);
      if (
        props.application.coApplicantInAreasStatus &&
        props.application.coApplicantInAreasStatus.coApplicants &&
        props.application.coApplicantInAreasStatus.coApplicants.length > 0
      ) {
        if (
          !props.application.coApplicantInAreasStatus.coApplicants.findIndex(
            (e) => props.application.employeeNumber === e,
          )
        ) {
          setNotifyMessage(`Du er oppgitt som medsøker på området.
           Aksepter kollektiv som boform og fyll ut ansattnummeret til hovedsøker.`);
        }
      }
    }
    props.updateApplication(name, sharedHousingAcceptable);
  };

  const handeOnSubmit = () => {
    if (!props.application.wantedFromDate) {
      props.updateApplication('wantedFromDate', moment());
    }
    if (props.application.isRenewal) {
      props.updateApplication('currentHousingSituation', 2);
    }
    props.goToSlide(
      getSiblingPath(props.location.pathname, props.nextSlidePath),
    );
  };

  const onMainApplicantChange = (name, newMainApplicantStatus) => {
    setApplicantHasChanged(true);
    props.updateApplication('isMainApplicant', newMainApplicantStatus);
    // Hvis ny status er Hovesøker og bruker allerde er Medsøker på boområde (areaid)
    // Eller
    // Hvis ny staus er Medsøker og bruker allerede er Hovedsøker på boområde
    if (isCoApplicant() && newMainApplicantStatus === true) {
      setDisableNextButton(true);
    } else if (newMainApplicantStatus !== true) {
      // Byttet fra hovesøker til medsøker. Fjerne alle coApplicants bortsett fra første
      props.removeCoApplicantAllButFirst();
      if (isCoApplicant()) {
        setDisableNextButton(true);
      }
    } else {
      setDisableNextButton(false);
    }
  };

  const generateValidatorConfig = () => {
    const validatorConfig: any = {
      currentHousingSituation: yup.string().when([], {
        is: () => !props.application.isRenewal,
        then: (stream) => stream.required('required'),
        otherwise: (schema) => schema,
      }),
      currentHousingZipCode: yup.string().when('currentHousingSituation', {
        is: (situation) =>
          showPostalCode(situation, props.application.isRenewal),
        then: (stream) =>
          stream
            .required('required')
            .matches(/^[0-9]{5}$/, 'Must be a valid postal code'),
        otherwise: (schema) => schema,
      }),
      currentHousingAddress: yup.string().when('currentHousingSituation', {
        is: (situation) =>
          situation === 'LIVING_CONDITION_RENTS_MILITARY' &&
          !props.application.isRenewal,
        then: (stream) => stream.required('required'),
        otherwise: (schema) => schema,
      }),
      currentHousingUnitNumber: yup.string().when('currentHousingSituation', {
        is: (situation) =>
          situation === 'LIVING_CONDITION_RENTS_MILITARY' &&
          !props.application.isRenewal,
        then: (stream) => stream.required('required'),
        otherwise: (schema) => schema,
      }),
      sharedHousingAcceptable: yup.boolean().when(['type', 'isRenewal'], {
        is: (type, isRenewal) => showCohabitantOption({ type, isRenewal }),
        then: (schema) =>
          schema.required('Shared Housing Acceptable is required'),
        otherwise: (schema) => schema,
      }),
      coApplicants: yup.array().of(
        yup.object().shape({
          name: yup.string().required('required'),
          employeeNumber: yup.string().when([], {
            is: () => true,
            then: (stream) =>
              stream.required('required').matches(/^[0-9]+$/, 'number'),
            otherwise: (schema) => schema,
          }),
        }),
      ),
      isMainApplicant: yup.boolean().when('sharedHousingAcceptable', {
        is: true,
        then: (stream) => stream.required('required'),
        otherwise: (schema) => schema,
      }),
    };

    props.application.coApplicants.forEach((e, i) => {
      if (Object.keys(e).length === 0) return;
      validatorConfig[`coApplicants[${i}].employeeNumber`] = yup
        .string()
        .matches(/^[0-9]+$/, 'required');
    });

    if (props.livingConditionOptions && !props.application.isRenewal) {
      props.livingConditionOptions.forEach((opt: any) => {
        validatorConfig[`currentHousingSituation_${opt.key}`] = yup
          .boolean()
          .required('required');

        if (opt.key === 1) {
          validatorConfig.isRentingMilitary = yup
            .number()
            .when('currentHousingSituation_1', {
              is: true,
              then: (stream) => stream.required('required'),
              otherwise: (schema) => schema,
            });
        }
      });
    }

    return yup.object().shape(validatorConfig).defined();
  };

  const getCollectiveTextHtml = () =>
    props.infoText[
      `${upperCaseFirstLetter(props.applicationType)}CollectiveInfoHtml`
    ];

  const formProps = {
    errors: props.errors,
    form: {
      ...props.application,
    },
    onSubmit: handeOnSubmit,
    onChange: props.updateApplication,
    updateAllValidationErrors: props.updateAllApplicationErrors,
    updateOneValidationError: props.updateSingleApplicationError,
    schema: generateValidatorConfig(),
  };

  const applicationTypeName =
    props.applicationType === 'housing' ? 'bolig' : 'kvarter';

  const handleCoApplicantNameChange = (event: DeliberateAny, index: number) => {
    const coApplicantsData = props.application.coApplicants;
    if (event.target.value === undefined || event.target.value == '') {
      setDisableNextButton(true);
      setMissingCoApplicantName(true);
    } else {
      setDisableNextButton(false);
      setMissingCoApplicantName(false);
      coApplicantsData[index].name = event.target.value;
      props.updateApplication('coApplicants', coApplicantsData);
    }
  };

  const handleCoApplicantEmployeeNumberChange = (
    event: DeliberateAny,
    index: number,
  ) => {
    const coApplicantsData = props.application.coApplicants;
    if (event.target.value === undefined || event.target.value == '') {
      setDisableNextButton(true);
      setMissingCoApplicantEmployeeNumber(true);
      if (props.application.isMainApplicant) {
        setNotifyMessage(
          props.infoText.HousingMissingEmployeenumerForCoApplicant,
        );
      } else {
        setNotifyMessage(
          props.infoText.HousingMissingEmployeenumerForMainApplicant,
        );
      }
    } else {
      setDisableNextButton(false);
      setMissingCoApplicantEmployeeNumber(false);
      coApplicantsData[index].employeeNumber = event.target.value;
      props.updateApplication('coApplicants', coApplicantsData);
    }
  };

  const getCoApplicantErrors = () => {
    const coApplicantMissmatch = (
      coApplicantsFormdata,
      coApplicantsRestresult,
    ) => {
      if (
        (coApplicantsFormdata?.length ?? 0) <
        (coApplicantsRestresult?.length ?? 0)
      ) {
        return true;
      }
      const existsIn = (searchid) =>
        coApplicantsFormdata
          .filter((el) => el.employeeNumber)
          .map((e) => e.employeeNumber)
          .includes(searchid);

      return !coApplicantsRestresult?.every(existsIn) || false;
    };

    const errorMessage = (key) => props.infoText[key];

    if (props.application.isMainApplicant) {
      // rets.push('Hovedsøker er valgt');
      if (applicantHasChanged && isCoApplicant()) {
        // Regel #1. Mitt empolyeenumber finnes i respons fra coApplicant listen.
        // Responsen må også ha satt verdi coApplicantInAreasStatus.isCoApplicant == true
        // Skriv ut melding og disable Next-button
        if (!disableNextButton) {
          setDisableNextButton(true);
        }
        // Melding: Du er oppgitt som medsøker og kan derfor ikke søke som hovedsøker
        return errorMessage('HousingCoApplicantCantSearch');
      } else if (
        coApplicantMissmatch(
          props.application.coApplicants,
          coApplicantInAreasStatus.coApplicants,
        )
      ) {
        // Hovedsøker har ikke fylt ut alle medsøkere.
        if (!disableNextButton) {
          setDisableNextButton(true);
        }
        // Du har ikke oppgitt riktig ansattnummer på medsøker(e)
        return errorMessage('HousingMissingEmployeenumerForCoApplicant');
      } else if (
        applicantHasChanged &&
        isMainApplicant() &&
        existCoApplicant(myEmployeeNumber)
      ) {
        // Hovesøker er tidligere satt som hovedsøker på område.
        // myemployee finnes i coApplicantInAreasSatus og
        // isMainApplicant er der satt til true
        if (!disableNextButton) {
          setDisableNextButton(true);
        }
        // Melding: Du er oppgitt som hovedsøker. Oppgi medsøkers ansattnummer
        return errorMessage('HousingMainApplicantCantSearch');
      } else if (employeeIsSelf) {
        if (!disableNextButton) {
          setDisableNextButton(true);
        }
        // Melding: 'Du kan ikke oppgi ditt eget ansattnummer som medsøkers ansattnummer';
        return errorMessage('HousingCoApplicantIsSelfMainApplicant');
      } else if (disableNextButton) {
        // Ingen feil. Fjern tidligere feilmeldinger
        setDisableNextButton(false);
      }
    } else if (props.application.isMainApplicant === false) {
      // Medsøker er valgt
      if (isMainApplicant()) {
        // Bruker er satt som Hovedsøker i boområde
        if (!disableNextButton) {
          setDisableNextButton(true);
        }
        // Melding: Du er oppgitt som hovedsøker, og kan derfor ikke søke som medsøker
        return errorMessage('HousingMainApplicantCantSearch');
      } else if (employeeIsSelf) {
        if (!disableNextButton) {
          setDisableNextButton(true);
        }
        // Melding: 'Du kan ikke oppgi ditt eget ansattnummer som hovedsøkers ansattnummer';
        return errorMessage('HousingCoApplicantIsSelfCoApplicant');
      } else if (
        !props.application.coApplicants[0].employeeNumber ||
        !props.application.coApplicants[0].name
      ) {
        if (!disableNextButton) {
          setDisableNextButton(true);
        }
        // melding: Du har ikke oppgitt en hovedsøker
        return errorMessage('HousingNoMainApplicantSpesified');
      } else if (disableNextButton) {
        // Ingen feil. Fjern tidligere feilmeldinger
        setDisableNextButton(false);
      }
    }
    return null;
  };

  return (
    <Loader isLoading={props.isLoading}>
      <FormWrapper {...formProps} unclassifiedStamp>
        <h1>Bosituasjon</h1>

        {!props.application.isRenewal && (
          <div className="margin">
            <HelpText helpText={props.infoText.HousingSituationInfo}>
              <h3>Nåværende boforhold</h3>
            </HelpText>

            <div className="margin">
              <div className="margin">
                {props.livingConditionOptions &&
                  props.livingConditionOptions.map((option) => {
                    return (
                      <div key={`currentHousingSituation_${option.key}`}>
                        <RadioButtonGroup
                          legend={option.value}
                          name={`currentHousingSituation_${option.key}`}
                          options={[
                            { value: true, label: 'Ja' },
                            { value: false, label: 'Nei' },
                          ]}
                          checkedOption={
                            props.application[
                              `currentHousingSituation_${option.key}`
                            ]
                          }
                          onChange={props.updateApplication}
                          errors={props.errors}
                        />
                        {isRentingMilitarySituation &&
                          option.key == '1' && ( // SPECIAL FOR OPTION 1
                            <div className="extra-wrapper">
                              <RadioButtonGroup
                                legend="Leieforhold"
                                key="isRentingMilitary"
                                name="isRentingMilitary"
                                options={[
                                  { value: 1, label: 'Leier du kvarter?' },
                                  { value: 2, label: 'Leier du bolig?' },
                                ]}
                                checkedOption={
                                  props.application.isRentingMilitary
                                }
                                onChange={props.updateApplication}
                                errors={props.errors}
                              />
                            </div>
                          )}
                      </div>
                    );
                  })}
              </div>
              <div className="margin">
                {showCohabitantOption(props.application) && (
                  <HelpText
                    helpText={props.infoText.MainApplicantAndCoApplicantInfo}
                  >
                    <RadioButtonGroup
                      legend="Er du villig til å bo i bofellesskap? Du får 50 ekstra søknadspoeng per
                medsøker, såfremt de er i målgruppen for boligtildeling."
                      options={[
                        { value: true, label: 'Ja' },
                        { value: false, label: 'Nei' },
                      ]}
                      name="sharedHousingAcceptable"
                      checkedOption={props.application.sharedHousingAcceptable}
                      onChange={onSharedHousingAcceptableChange}
                      errors={props.errors}
                    />
                  </HelpText>
                )}
              </div>

              {props.application.currentHousingSituation ===
                LIVING_CONDITION_RENTS_MILITARY && (
                <div className="flex-row">
                  <Input
                    name="currentHousingAddress"
                    value={props.application.currentHousingAddress}
                    title="Adresse/leir"
                    placeholder="Skriv inn adresse eller leir"
                  />
                  <Input
                    name="currentHousingUnitNumber"
                    value={props.application.currentHousingUnitNumber}
                    title="Leilighetsnummer/bygg"
                    placeholder="Skriv inn leilighetsnummer eller bygg"
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className="margin">
          {props.applicationType === 'quarter' && (
            <RadioButtonGroup
              legend="Ønsket bosituasjon"
              options={[
                { value: false, label: getSharedQuarterStr(false) },
                { value: true, label: getSharedQuarterStr(true) },
              ]}
              name="sharedHousingAcceptable"
              checkedOption={
                props.application.sharedHousingAcceptable ?? undefined
              }
              onChange={onSharedHousingAcceptableChange}
            />
          )}

          {!props.application.sharedHousingAcceptable && notifyMessage && (
            <div
              className="flex-row red"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(notifyMessage),
              }}
            />
          )}
          {props.application.sharedHousingAcceptable && (
            <div className="extra-wrapper">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(getCollectiveTextHtml()),
                }}
              />
              <RadioButtonGroup
                legend={IS_MAIN_APPLICANT_LABEL}
                options={[
                  { value: true, label: 'Ja' },
                  { value: false, label: 'Nei' },
                ]}
                name="isMainApplicant"
                checkedOption={props.application.isMainApplicant}
                onChange={onMainApplicantChange}
              />

              {getCoApplicantErrors() && (
                <div
                  className="flex-row red"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(getCoApplicantErrors()),
                  }}
                />
              )}

              {notifyMessage && (
                <div
                  className="flex-row red"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(notifyMessage),
                  }}
                />
              )}

              {props.application.coApplicants.map((coApplicant, i) => (
                <div key={`coApplicant_${i}`}>
                  <div className="flex-row">
                    <div>
                      <label>
                        {props.application.isMainApplicant ||
                        props.application.isMainApplicant === undefined
                          ? props.infoText.coApplicantNameLabel ??
                            'Navn på medsøker'
                          : props.infoText.mainApplicantNameLabel ??
                            'Navn på hovedsøker'}
                      </label>
                      <input
                        type="text"
                        name={`coApplicants[${i}].name`}
                        defaultValue={coApplicant.name}
                        onBlur={(e) => {
                          handleCoApplicantNameChange(e, i);
                        }}
                      />
                    </div>
                    <div>
                      <label>
                        <span>Ansattnummer </span>
                        <FormErrors
                          errortext={
                            props.infoText
                              .HousingMissingEmployeenumerForMainApplicant
                          }
                          rule={
                            coApplicant.employeeNumber == '' ||
                            coApplicant.employeeNumber === undefined
                          }
                        />
                      </label>
                      <input
                        type="text"
                        name={`coApplicants[${i}].employeeNumber`}
                        defaultValue={coApplicant.employeeNumber}
                        onBlur={(e) =>
                          handleCoApplicantEmployeeNumberChange(e, i)
                        }
                      />
                    </div>
                  </div>
                  {i > 0 && (
                    <BoxClose
                      title="Fjern kollega"
                      onClick={() => props.removeCoApplicant(i)}
                      topRight
                    />
                  )}
                </div>
              ))}
              {props.applicationType === 'housing' &&
                props.application.isMainApplicant === true && (
                  <Button
                    name="addCoApplicant"
                    color="red"
                    onClick={props.addCoApplicant}
                    disabled={!props.application.isMainApplicant}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(props.infoText.HousingAdd),
                      }}
                    />
                  </Button>
                )}
            </div>
          )}
        </div>

        {props.application.isRenewal && (
          <p>
            Velg ønsket sluttdato for forlengelsen. Endelig dato bekreftes etter
            behandling hos boligsekretær.
          </p>
        )}

        <div className="flex-row margin">
          {!props.application.isRenewal && (
            <DateInput
              className="wanted-from-date"
              name="wantedFromDate"
              title={`${_.capitalize(applicationTypeName)} ønskes fra`}
              selectedDate={props.application.wantedFromDate}
              showYear
              onChange={props.updateApplication}
            />
          )}
          {(props.application.applicantCategory?.toString() === '9' ||
            props.application.isRenewal) && (
            <DateInput
              className="wanted-to-date"
              name="wantedToDate"
              title={`${_.capitalize(applicationTypeName)} ${props.application.isRenewal ? 'forlenges til' : 'ønskes til'}`}
              selectedDate={props.application.wantedToDate}
              minDate={
                props.application.isRenewal
                  ? moment()
                  : props.application.wantedFromDate
              }
              onChange={props.updateApplication}
              showYear
            />
          )}
          <HelpText
            helpText={
              props.infoText.HousingRenewalWantedDateInfo &&
              props.application.isRenewal
                ? props.infoText.HousingRenewalWantedDateInfo
                : props.infoText.HousingWantedFromInfo
            }
          />
        </div>

        <h3>
          Ønsker om{' '}
          {props.applicationType === 'housing' ? 'ny bolig' : 'nytt kvarter'}
        </h3>
        <div className="housing-preferences">
          <TextArea
            className="margin"
            name="housingPreferences"
            value={props.application.housingPreferences}
            placeholder={
              'Skriv noe om hvilke ønsker du har for ny bolig i prioritert rekkefølge. ' +
              'Boligønsker kan f.eks. omhandle beliggenhet, antall soverom, ' +
              'kort avstand til offentlig kommunikasjon eller andre særskilte behov. ' +
              'OBS: ikke skriv gradert informasjon. ' +
              'Begrenset informasjon kan være stilling, tilhørighet, eller annen jobbrelatert informasjon'
            }
            onChange={props.updateApplication}
          />
        </div>

        {props.application.applicantCategory === '9' && (
          <div
            className="flex-row margin red"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.infoText.sumibInfo),
            }}
          />
        )}

        <ButtonRow>
          <ButtonRow.Right
            disabled={disableNextButton}
            color="red"
            type="submit"
            onClick={handeOnSubmit}
          >
            Neste
          </ButtonRow.Right>
        </ButtonRow>
      </FormWrapper>
    </Loader>
  );
};

// $FlowFixMe
export default connect(
  (state: DeliberateAny, props: PropsType) => ({
    isLoading:
      state.housing.isLoading || !state.options.housingDataOptionsLoaded,
    errors: state.housing.errors,
    application: state.housing[`${props.applicationType}Application`],
    infoText: state.housing.housingInfoText,
    livingConditionOptions:
      state.options.housingDataOptions.livingConditionOptions,
    housingDataOptionsLoaded: state.options.housingDataOptionsLoaded,
  }),
  (dispatch, props) =>
    bindActionCreators(
      {
        updateApplication: (...args: [string, string | boolean]) =>
          updateApplication(props.applicationType, ...args),
        setPostalCode: (...args: [string, string]) =>
          setPostalCode(props.applicationType, ...args),
        addCoApplicant: () => addCoApplicant(props.applicationType),
        removeCoApplicant: (...args: [number]) =>
          removeCoApplicant(props.applicationType, ...args),
        removeCoApplicantAllButFirst: () =>
          removeCoApplicantAllButFirst(props.applicationType),
        updateAllApplicationErrors: (...args: [DeliberateAny]) =>
          updateAllApplicationErrors(props.applicationType, ...args),
        updateSingleApplicationError: (...args: [string, DeliberateAny]) =>
          updateSingleApplicationError(props.applicationType, ...args),
        fetchHousingInfoText,
        fetchHousingCoApplicant: (...args: [string]) =>
          fetchHousingCoApplicant(props.applicationType, ...args),
      },
      dispatch,
    ),
)(UnconnectedLivingCondition);
