import moment, { Moment } from 'moment';

import { DeliberateAny } from '../types/DelibrateAny';

/* eslint-disable */
const FBHelpers = {
  isIOS: function () {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  },
  /**
   * converts namespaceString(i.e. FB.Apps.MyPage) to object on window
   */
  namespaceToObject(namespaceString: string): any {
    return namespaceString.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : undefined;
    }, window as any);
  },

  /* this is used in React */
  formatCurrency: function (
    val: string,
    separator?: string,
    addNotation = false,
  ) {
    var value = '' + val; // make sure it's a string
    var s = separator || ' '; // default separator = space
    var formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, s);
    if (addNotation) {
      formattedValue += ',-';
    }
    return formattedValue;
  },

  /**
   * Checks if the current user agent is a mobile device.
   * Mobile devices are identified based on common user agent substrings.
   * @return {boolean} True if the user agent is a mobile device, otherwise false.
   */
  isMobile() {
    // User agent strings to check for
    const mobileKeywords = [
      'Android',
      'webOS',
      'iPhone',
      'iPad',
      'iPod',
      'BlackBerry',
      'IEMobile',
      'Opera Mini',
    ];

    // Checking if any of the mobile keywords exist in the user agent string
    return mobileKeywords.some((keyword) =>
      navigator.userAgent.includes(keyword),
    );
  },

  Date: {
    // shamelessly stolen from http://stackoverflow.com/a/20948347/435720
    // I did give the poster +1 though. ;)
    addWorkingDays: function (_date: DeliberateAny, _days: number) {
      var date = moment(_date); // use a clone
      var days = _days;
      while (days > 0) {
        date = date.add(1, 'days');
        // decrease "days" only if it's a weekday.
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
          days -= 1;
        }
      }
      return date;
    },

    dayString: function (dayIndex: number, short: boolean) {
      switch (dayIndex) {
        case 0:
          return short ? 'søn' : 'søndag';
        case 1:
          return short ? 'man' : 'mandag';
        case 2:
          return short ? 'tirs' : 'tirsdag';
        case 3:
          return short ? 'ons' : 'onsdag';
        case 4:
          return short ? 'tors' : 'torsdag';
        case 5:
          return short ? 'fre' : 'Fredag';
        case 6:
          return short ? 'lør' : 'lørdag';
        default:
          return 'No match';
      }
    },

    monthString: function (month: number, short = false) {
      switch (month) {
        case 0:
          return short ? 'jan' : 'januar';
        case 1:
          return short ? 'feb' : 'februar';
        case 2:
          return short ? 'mar' : 'mars';
        case 3:
          return short ? 'apr' : 'april';
        case 4:
          return short ? 'mai' : 'mai';
        case 5:
          return short ? 'jun' : 'juni';
        case 6:
          return short ? 'jul' : 'juli';
        case 7:
          return short ? 'aug' : 'august';
        case 8:
          return short ? 'sep' : 'september';
        case 9:
          return short ? 'okt' : 'oktober';
        case 10:
          return short ? 'nov' : 'november';
        case 11:
          return short ? 'des' : 'desember';
        default:
          return 'No match';
      }
    },

    fromAjax(datestr: string, includeTime: boolean): string {
      const formatString = `DD.MM.YY${includeTime ? ' - HH:mm' : ''}`;
      const parsedDate = moment(datestr);

      if (!parsedDate.isValid()) {
        return datestr;
      }

      return parsedDate.format(formatString);
    },

    makeDate: function (date: DeliberateAny, asUTC?: any): Date | null {
      if (!date) return null;
      var d;
      if (date instanceof Date) {
        d = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12);
      } else if (date.year) {
        d = new Date(date.year, date.month, date.date, 12);
      } else {
        d = new Date(date);
      }
      return asUTC ? moment(d).utc().startOf('day').toDate() : d;
    },

    daysBetweenDates(_from: Date, _to: Date): number {
      const from = moment(this.makeDate(_from));
      const to = moment(this.makeDate(_to));
      if (!from.isValid() || !to.isValid()) return -1;

      const numberOfWholeDays = to.diff(from, 'days', false);
      if (numberOfWholeDays !== 0) return numberOfWholeDays;

      // If less than 24 hours, check if dates are on two different days.
      const from_day_of_year = from.dayOfYear();
      const to_day_of_year = to.dayOfYear();
      if (from_day_of_year === to_day_of_year) return 0;

      const preciseDiff = to.diff(from, 'days', true); // returns 0.5 or -0.5
      return preciseDiff > 0 ? 1 : -1;
    },

    prettyDuration: function (_from: Moment | Date, _to: Moment | Date) {
      var from = moment(_from);
      var to = moment(_to);

      var sameMonth = from.month() === to.month();

      var yearStr =
        to.year() !== new Date().getFullYear() ? ' ' + to.year() : '';

      var arrStr =
        '<i>' +
        from.date() +
        '</i>' +
        (!sameMonth ? ' ' + this.monthString(from.month(), true) : '');

      var depStr =
        '<i>' + to.date() + '</i> ' + this.monthString(to.month(), true);
      return arrStr + ' - ' + depStr + yearStr;
    },

    formatTime(time: string): string {
      // Split the time string by ':' and parse hours and minutes
      const [hourStr, minStr] = time.split(':');
      const hour = parseInt(hourStr, 10);
      const min = parseInt(minStr, 10);

      // Check if the parsed values are valid numbers
      if (isNaN(hour) || isNaN(min)) {
        return '14:00'; // Default value for invalid input
      }

      // Format the hour and minute values
      const formattedHour =
        hour <= 9 ? `0${hour}` : hour >= 23 ? '23' : hourStr;
      const formattedMin = min <= 9 ? `0${min}` : min >= 59 ? '59' : minStr;

      return `${formattedHour}:${formattedMin}`;
    },

    isDateTimeMinValue: function (value: string) {
      return value.startsWith('0001-01-01T00:00:00');
    },
  },

  /* is used only once in React */
  // viewModal: function (type: DeliberateAny, data: DeliberateAny) {
  //   FB.Modal.show(type, data);
  // },
  /* this is used twice */
  userHasAccessToLocation: function (location: string) {
    return true;
  },
  /* this is used once */
  notifyUserOfBasenProffAccess: function (showmodal) {
    showmodal({
      title: 'Basen PROFF',
      html:
        '<p>Er du f.eks EBA-koordinator, eller i en annen rolle der du følger opp ' +
        'eiendom, bygg og anlegg på vegne av din avdeling?' +
        '<br><br>' +
        'Da kan du be om tilgang til Basen PROFF (tidl. kundetorget)' +
        '<br><br>' +
        '<a href="#/contact">Kontakt Forsvarsbygg servicesenter</a> for tilgang.</p>',
    });
  },

  translateUrl: function (url: string) {
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_MOCK === 'true'
    ) {
      if (url.indexOf('?') !== -1) {
        return '/mock' + url.replace(/(.+)([*^?]+)(.*)/g, '$1.json?$3');
      }
      return '/mock' + url + '.json';
    }
    return url;
  },

  realType: function (obj: any) {
    return Object.prototype.toString
      .call(obj)
      .split(' ')[1]
      .slice(0, -1)
      .toLowerCase();
  },

  getStrDaysOrHoursLeftUntilDeadline: function (deadline: moment.MomentInput) {
    var daysLeft = moment(deadline).diff(moment(), 'days');
    var hoursLeft = moment(deadline).diff(moment(), 'hours');
    var minutesLeft = moment(deadline).diff(moment(), 'minutes');

    if (daysLeft > 1) {
      return daysLeft + ' dager';
    } else if (daysLeft === 1) {
      return daysLeft + ' dag';
    } else if (daysLeft === 0 && hoursLeft > 1) {
      return hoursLeft + ' timer';
    } else if (hoursLeft === 1) {
      return hoursLeft + ' time';
    } else if (hoursLeft === 0) {
      return minutesLeft + ' minutter';
    }
    return '';
  },
};

export default FBHelpers;
