import 'moment/locale/nb';
import './Notification.scss';

import moment from 'moment';
import { Fragment, useRef, useState } from 'react';

import { uiRecentUserNotificationsURL } from '../api/core/core_api';
import useAxios from '../hooks/useAxios';
import { useSignalR } from 'contexts/index';
import { UserNotificationDto } from 'contexts/SignalRContext';

const NotificationSignalR = () => {
  const { sendRequest } = useAxios();

  const { notifications, setNotifications } = useSignalR();
  const [showNotifications, setShowNotifications] = useState(false);
  const hoverRef = useRef<string>();

  const handleAcknowledge = async (id: string) => {
    await sendRequest(
      {
        method: 'PATCH',
        url: `${uiRecentUserNotificationsURL}/${id}`,
        withCredentials: true,
      },
      () => {
        const updatedNotifications = notifications.map(
          (n: UserNotificationDto) => {
            if (n.Id === id) {
              return { ...n, Acknowledged: true };
            }
            return n;
          },
        );
        setNotifications(updatedNotifications);
      },
    );
  };

  const handleMouseEnter = (id: string) => {
    hoverRef.current = id;

    if (!notifications.find((n) => n.Id === id)?.Acknowledged)
      setTimeout(() => {
        if (hoverRef.current === id) {
          handleAcknowledge(id);
        }
      }, 3000);
  };

  const handleMouseLeave = (id: string) => {
    if (hoverRef.current === id) hoverRef.current = undefined;
  };

  const onlyAcknowledgedNotifications = () => {
    return notifications && notifications.length > 0;
  };

  const numOfUnacknowledgedNotifications = () => {
    return notifications.filter((n) => !n.Acknowledged).length;
  };

  return (
    <Fragment>
      {onlyAcknowledgedNotifications() && (
        <div className="notification">
          <div onClick={() => setShowNotifications((x) => !x)}>
            <span className="icon-bell" role="img" aria-label="varslinger">
              🔔
            </span>
            {numOfUnacknowledgedNotifications() > 0 && (
              <span className="notification-badge">
                {numOfUnacknowledgedNotifications()}
              </span>
            )}
          </div>
          {showNotifications && (
            <div className={'notification-container fade-in-element'}>
              {notifications.map((n, idx) => (
                <li
                  key={idx}
                  onClick={() => {
                    window.location.href = `#/my-page/tab-my-notifications?note=${n.Id}`;
                    setShowNotifications(false);
                  }}
                  onMouseEnter={() => handleMouseEnter(n.Id)}
                  onMouseLeave={() => handleMouseLeave(n.Id)}
                >
                  {!n.Acknowledged && (
                    <span className="notify-dot" role="img" aria-label="ny">
                      🔴
                    </span>
                  )}
                  <span className="notify-date">
                    {moment(n.Created).format('DD.MM.YYYY HH:mm ')}
                  </span>
                  <hr />
                  {n.Message}
                </li>
              ))}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default NotificationSignalR;
