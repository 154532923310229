import React from "react";

import MyPortfolio from "./MyPortfolio/MyPortfolio";
import SignableDocuments from "./SignableDocuments/SignableDocuments";

const TabMyPortfolio = ({ setPortfolioSearch, setViewBuildning }) => (
  <div className="tab-panel max-width-large" style={{ display: "block" }}>
    <SignableDocuments />
    <MyPortfolio
      navigateToPortfolioSearch={() => setPortfolioSearch(true)}
      navigateToViewBuilding={(item) => setViewBuildning(item)}
    />
  </div>
);

export default TabMyPortfolio;
