import './GuestList.scss';

import { useNewBooking } from 'contexts/index';
import React, { FC } from 'react';

import RoomListItem from './RoomListItem';

const RoomList: FC = () => {
  const { rooms } = useNewBooking();

  const renderGuestListItems = () => {
    if (rooms) {
      const mappedItems = rooms.map((room, index) => {
        return (
          <React.Fragment key={`room_${index}`}>
            <RoomListItem room={room ?? {}} />
          </React.Fragment>
        );
      });
      return mappedItems;
    }
  };
  return <div className="guest-list">{renderGuestListItems()}</div>;
};

export default RoomList;
