import DateInput from 'components/DateInput/DateInput';
import Checkbox from 'components/form/Checkbox/Checkbox';
import TextArea from 'components/form/TextArea/TextArea';
import Modal from 'components/modals/Modal/Modal';
import { Moment } from 'moment';

type PropsType = {
  isOpen: boolean;
  onClose: () => void;
  ariaLabel: string;
  submit: {
    disabled: boolean;
    onClick: () => void;
  };
  modalHeader: string;
  textArea: {
    onChange: (name: string, value: string) => void;
    name: string;
    value?: string;
  };
  datePicker?: {
    title: string;
    selectedDate: Moment;
    mindDate: Moment;
    onChange: (moment: Moment) => void;
    disabled: {
      yesNo: boolean;
      toggleDisable: () => void;
    };
  };
  modalText: string;
};

const HousingRenewalMessageModal = (props: PropsType) => (
  <Modal
    isOpen={props.isOpen}
    onClose={props.onClose}
    ariaLabel={props.ariaLabel}
    size="medium"
    submit={{
      hide: false,
      text: 'Send',
      disabled: props.submit.disabled,
      onClick: props.submit.onClick,
    }}
  >
    <h2>{props.modalHeader}</h2>
    {props.datePicker && (
      <div className="margin">
        <DateInput
          showYear
          title={props.datePicker.title}
          name="approved to date"
          minDate={props.datePicker.mindDate}
          selectedDate={props.datePicker.selectedDate}
          small={false}
          onSelectDate={props.datePicker.onChange}
          disabled={props.datePicker.disabled.yesNo}
        />
        <Checkbox
          className="row margin"
          type="checkbox"
          name="changeApprovedDate"
          label={'Endre periode'}
          checked={!props.datePicker.disabled.yesNo}
          onChange={() => props.datePicker?.disabled.toggleDisable()}
        />
      </div>
    )}
    <label>
      <span>{props.modalText}</span>
      <TextArea
        rows={6}
        className="margin"
        onChange={props.textArea.onChange}
        name={props.textArea.name}
        value={props.textArea.value}
      />
    </label>
  </Modal>
);

export default HousingRenewalMessageModal;
