// @flow

import { DeliberateAny } from "../types/DelibrateAny";

export function addFileReporting(item: DeliberateAny) {
  return { type: "ADD_FILE_REPORTING", item };
}

export function removeFileReporting(item: DeliberateAny, index: string) {
  return { type: "REMOVE_FILE_REPORTING", item, index };
}

// export function updateFileDescriptionReporting(fileName: string, title: string) {
//   return { type: 'UPDATE_FILE_DESCRIPTION_REPORTING', fileName, title };
// }

export function updateFileDescriptionReporting(
  fileName: string,
  title: string,
  index: string
) {
  return { type: "UPDATE_FILE_DESCRIPTION_REPORTING", fileName, title, index };
}

export function handleUpdateReportingFilter(
  key: string,
  value: string | number | boolean | undefined
) {
  let filterValue = value;
  if (value === -1) {
    filterValue = undefined;
  }
  return { type: "UPDATE_REPORTING_FILTER", key, value: filterValue };
}

export function handleSendReporting(formHolder: DeliberateAny, url: string) {
  return { type: "SEND_REPORTING", formHolder, url };
}

export function updateReportingAllErrors(errors: DeliberateAny) {
  return { type: "UPDATE_REPORTING_ALL_ERRORS", errors };
}

export function updateReportingOneError(name: string, error: DeliberateAny) {
  return { type: "UPDATE_REPORTING_ONE_ERROR", name, error };
}

export function updateReporting(key: string, value: string) {
  return { type: "UPDATE_REPORTING", key, value };
}

export function handleCloseReportingConfirmationSent() {
  return { type: "CLOSE_REPORTING_SENT_CONFIRMATION" };
}
