import { fetchQuarterApplicationSummary } from 'actions/housingAdminActions';
import RenderApplicationSummary from 'components/HousingApplication/RenderApplicationSummary';
import Loader from 'components/Loader/Loader';
import RouterModal from 'components/modals/RouterModal/RouterModal';
import connect from 'helpers/connectWithRouter';
import { Component } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import type { QuarterApplicationType } from "types/housingAdminTypes";
type PropsType = {
  path: string;
  application: QuarterApplicationType;
  fetchQuarterApplicationSummary: (applicationId: string) => void;
};

export class UnconnectedQuarterApplicationSummaryModal extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    this.props.fetchQuarterApplicationSummary(this.props.application.Id);
  }

  render() {
    return (
      <RouterModal
        size="medium"
        path={this.props.path}
        ariaLabel="Oppsumering av søknad"
        title="Oppsumering"
      >
        <div className="margin">
          <Loader isLoading={this.props.application.loadingSummary}>
            {this.props.application.summary && (
              <RenderApplicationSummary
                application={{
                  ...this.props.application.summary,
                  isRenewal: this.props.application.IsRenewal,
                }}
              />
            )}
          </Loader>
        </div>
      </RouterModal>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    housingDataOptions: state.options.housingDataOptions,
  }),
  {
    fetchQuarterApplicationSummary,
  }
)(UnconnectedQuarterApplicationSummaryModal);
