 

import "./ReservationFilter.scss";

import {
  fetchbookeeProfileById,
  fetchReservationSearch,
  updateReservationFilter,
} from "actions/reservationActions";
import Button from "components/buttons/Button/Button";
import ButtonRow from "components/buttons/ButtonRow/ButtonRow";
import Input from "components/form/Input/Input";
import Select from "components/form/Select/Select";
import connect from "helpers/connectWithRouter";
import React, { Component } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import ReservationList from "../ReservationList/ReservationList";

type PropsType = {
  updateReservationFilter: (
    key: string,
    value: string | number | boolean | string[]
  ) => void;
  reservationFilter: DeliberateAny;
  reservationValues: DeliberateAny & DeliberateAny[];
  fetchReservationSearch: (filter: DeliberateAny) => void;
  onBlur?: (name: string) => void;
  fetchbookeeProfileById: () => void;
  responseItemsFromSearch: [];
  responseItemsFromUbehandlet: [];
  sorting?: DeliberateAny;
  bookingStateFilter: DeliberateAny;
  paymentStateFilter: DeliberateAny;
  period: DeliberateAny;
};

class ReservationFilter extends Component {
  declare props: PropsType;
  declare state: DeliberateAny;

  constructor(props: PropsType) {
    super(props);
    this.state = {
      toggle: true,
    };
  }

  onChangeSearchType = (key: string, value: string | number | boolean) => {
    if (key === "bookingStateFilter" || key === "paymentStateFilter") {
      this.props.updateReservationFilter(key, [value.toString()]);
    } else {
      this.props.updateReservationFilter(key, value);
    }
  };

  showFilter = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  applySearch = () => {
    this.props.fetchReservationSearch({ ...this.props.reservationFilter });
  };

  sortArrayFunction(array) {
    const sort = this.props.sorting;
    let newArray = [];
    switch (sort) {
      case 1: //'Bestillingsdato - nyest først':
        newArray = array.sort((a, b) =>
          b.BookingDate > a.BookingDate
            ? 1
            : a.BookingDate > b.BookingDate
            ? -1
            : 0
        );
        break;
      case 2: //'Bestillingsdato - eldst først':
        newArray = array.sort((a, b) =>
          a.BookingDate > b.BookingDate
            ? 1
            : b.BookingDate > a.BookingDate
            ? -1
            : 0
        );
        break;
      case 3: //'Ankomstdato - nyest først':
        newArray = array.sort((a, b) =>
          b.ArrivalDate > a.ArrivalDate
            ? 1
            : a.ArrivalDate > b.ArrivalDate
            ? -1
            : 0
        );
        break;
      case 4: //'Ankomstdato - eldst først':
        newArray = array.sort((a, b) =>
          a.ArrivalDate > b.ArrivalDate
            ? 1
            : b.ArrivalDate > a.ArrivalDate
            ? -1
            : 0
        );
        break;
      default:
        newArray = array;
    }
    return newArray;
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="flex-row margin"
          style={{ borderBottom: "1px solid #b3b3b3", paddingBottom: "22px" }}
        >
          <ButtonRow>
            <ButtonRow.Left
              color={this.state.toggle ? "red" : "light-grey"}
              onClick={this.showFilter}
              type="submit"
            >
              Ubehandlet
            </ButtonRow.Left>
            <ButtonRow.Left
              color={this.state.toggle ? "light-grey" : "red"}
              onClick={this.showFilter}
              type="submit"
            >
              Søk & filtrer
            </ButtonRow.Left>
          </ButtonRow>
        </div>
        <div
          className="container"
          style={{ display: this.state.toggle ? "block" : "none" }}
        >
          <div className="flex-row margin">
            <ReservationList items={this.props.responseItemsFromUbehandlet} />
          </div>
        </div>
        <div
          className="container"
          style={{ display: this.state.toggle ? "none" : "block" }}
        >
          <div className="flex-row margin">
            <Input
              title="Søk"
              type="text"
              onChange={this.onChangeSearchType}
              value={this.props.reservationFilter.searchText}
              onBlur={this.props.onBlur}
              name="searchText"
              placeholder="Søk på navn, kommentar etc."
            />
            <Select
              label="Filtrer på bookingstatus"
              onChange={this.onChangeSearchType}
              options={this.props.bookingStateFilter}
              value={this.props.reservationFilter.bookingStateFilter}
              name="bookingStateFilter"
            />
            <Select
              label="Filtrer på betalingstatus"
              onChange={this.onChangeSearchType}
              options={this.props.paymentStateFilter}
              value={this.props.reservationFilter.paymentStateFilter}
              name="paymentStateFilter"
            />
            <Select
              className={"arrivalPeriodStart"}
              label="Tidsrom"
              onChange={this.onChangeSearchType}
              options={this.props.period}
              value={this.props.reservationFilter.arrivalPeriodStart}
              name="arrivalPeriodStart"
            />
            <div style={{ paddingTop: "22px", textAlign: "right" }}>
              <Button color="red" onClick={this.applySearch}>
                Søk
              </Button>
            </div>
          </div>
          <div className="flex-row margin">
            <ReservationList
              items={this.sortArrayFunction(this.props.responseItemsFromSearch)}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    reservationFilter: state.reservation.filter,
    bookingStateFilter: state.reservation.bookingStateFilter,
    paymentStateFilter: state.reservation.paymentStateFilter,
    period: state.reservation.period,
    responseItemsFromSearch: state.reservation.items,
    responseItemsFromUbehandlet: state.reservation.ubehandlet,
  }),
  {
    updateReservationFilter,
    fetchReservationSearch,
    fetchbookeeProfileById,
  }
)(ReservationFilter);
