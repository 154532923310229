// @flow

function fetchMyRentals(rentalId?: string) {
  return { type: "FETCH_MY_RENTALS", rentalId };
}

function resetMyRentalsExpandMe() {
  return { type: "RESET_MY_RENTALS_EXPAND_ME" };
}

function fetchRentalContractTexts() {
  return { type: "FETCH_RENTAL_CONTRACT_TEXTS" };
}

function rejectSigning(documentId: string, rejectionReason: string) {
  return { type: "REJECT_SIGNING", documentId, rejectionReason };
}

function fetchRejectionReasons() {
  return { type: "FETCH_RENTAL_CONTRACT_REJECTION_REASONS" };
}

function fetchDocumentLinks() {
  return { type: "FETCH_RENTAL_DOCUMENT_LINKS" };
}

function respondToProposedInspectionTime(
  rentalObjectId: string,
  inspectionId: string,
  accept: boolean,
  comment?: string,
  inspectionTime?: string
) {
  return {
    type: "RESPOND_TO_PROPOSED_INSPECTION_TIME",
    rentalObjectId,
    inspectionId,
    accept,
    comment,
    inspectionTime,
  };
}

export {
  fetchMyRentals,
  resetMyRentalsExpandMe,
  fetchRentalContractTexts,
  rejectSigning,
  fetchRejectionReasons,
  fetchDocumentLinks,
  respondToProposedInspectionTime,
};
