// @flow

import moment from 'moment';
import { call, put, select, delay } from 'redux-saga/effects';

import { countyNameLookup } from '../api/core/core_api';
import {
  getHousingTextsURL,
  currentAllocationRoundURL,
  getHousingCoApplicantInfoURL,
  myCurrentApplicationsForAreaURL,
  housingServiceAreasURL,
  addRenewalApplicationURL,
  addApplicationURL,
} from '../api/housing/housing_api';
import {
  getQuarterCoApplicantInfoURL,
  applyQuarterURL,
} from '../api/housing/quarter_api';
import { axiosFetch } from '../hooks/useAxios';

export function* handleHousingInfoText() {
  const url = getHousingTextsURL;
  try {
    const isLoaded = yield select(
      (state) => state.housing.housingInfoTextLoaded,
    );
    if (!isLoaded) {
      const data = yield call(axiosFetch, { url });
      yield put({ type: 'RECEIVE_HOUSING_INFO_TEXT', data });
    }
  } catch (e) {
    yield put({ type: 'SAGA_ERROR', e, url });
  }
}

export function* handleHousingAndServiceAreas(action) {
  const url = housingServiceAreasURL;
  const query = { applicationType: action.applicationType };
  try {
    const data = yield call(axiosFetch, { url, data: query });
    yield put({ type: 'RECEIVE_HOUSING_AND_SERVICE_AREAS', data });
  } catch (e) {
    yield put({ type: 'SAGA_ERROR', e, url, query });
  }
}

export function* handleAllocationRoundInfo(action) {
  const url = currentAllocationRoundURL;
  const query = { areaId: action.housingAreaId };
  try {
    const roundInfo = yield call(axiosFetch, { url, data: query });
    yield put({ type: 'RECEIVE_ALLOCATION_ROUND_INFO', roundInfo });
  } catch (e) {
    yield put({ type: 'SAGA_ERROR', e, url, query });
  }
}

export function* setPostalCode(action) {
  yield put({
    type: 'UPDATE_APPLICATION',
    applicationType: action.applicationType,
    key: 'currentHousingCounty',
    value: '',
  });
  yield call(delay, 500);

  const url = countyNameLookup;
  const query = { postalCode: action.postalCode };

  try {
    const data = yield call(axiosFetch, { url, data: query });
    if (data) {
      yield put({
        type: 'UPDATE_APPLICATION',
        applicationType: action.applicationType,
        key: 'currentHousingCounty',
        value: data.PostalLocation,
      });
      yield put({
        type: 'UPDATE_SINGLE_APPLICATION_ERROR',
        applicationType: action.applicationType,
        name: 'currentHousingZipCode',
      });
    }
  } catch (e) {
    yield put({
      type: 'UPDATE_SINGLE_APPLICATION_ERROR',
      applicationType: action.applicationType,
      name: 'currentHousingZipCode',
      error: {
        rule: 'postalServerError',
        field: 'currentHousingZipCode',
        serverMessage: e,
      },
    });
    yield put({ type: 'SAGA_ERROR', e, url, query });
  }
}

export function* handleSendApplication(action) {
  yield put({ type: 'SENDING_APPLICATION' });
  let url = '';
  if (action.application.type === 'housing') {
    url =
      action.application.isRenewal != null && action.application.isRenewal
        ? addRenewalApplicationURL
        : addApplicationURL;
  } else {
    url = applyQuarterURL;
  }

  const query = action.application;
  try {
    const dob = query.dateOfBirth.split('.');
    query.dateOfBirth = moment(new Date(`${dob[2]}-${dob[1]}-${dob[0]}`));
    query.wantedFromDate.utc(true);
  } catch (e) {} // eslint-disable-line

  try {
    yield call(axiosFetch, { url, data: query, method: 'POST' });
    yield put({
      type: 'RECEIVE_SEND_APPLICATION',
      applicationType: action.application.type,
    });
  } catch (e) {
    yield put({ type: 'ERROR_SEND_APPLICATION' });
    yield put({ type: 'SAGA_ERROR', e, url, query });
  }
}

export function* fetchApplicationsInArea(action) {
  yield put({ type: 'FETCHING_APPLICATIONS' });
  const url = myCurrentApplicationsForAreaURL;
  const query = { areaId: action.housingAreaId };

  try {
    const applications = yield call(axiosFetch, { url, data: query });
    yield put({
      type: 'RECEIVE_APPLICATIONS_IN_AREA',
      applicationType: action.applicationType,
      areaId: action.housingAreaId,
      applications,
    });
  } catch (e) {
    yield put({ type: 'SAGA_ERROR', e, url, query });
  }
}

export function* fetchHousingCoApplicant(action) {
  yield put({ type: 'FETCHING_HOUSING_CO_APPLICANT' });
  let url = '';
  if (action.applicationType === 'housing') {
    url = getHousingCoApplicantInfoURL;
  } else {
    url = getQuarterCoApplicantInfoURL;
  }

  if (!action.areaId) {
    yield put({
      type: 'RECEIVE_HOUSING_CO_APPLICANT',
      data: {},
      applicationType: action.applicationType,
      areaId: action.areaId,
    });
  } else {
    const query = {
      areaId: action.areaId,
    };
    try {
      const data = yield call(axiosFetch, { url, data: query });
      // const data = coApplicantInfoMockupData;
      yield put({
        type: 'RECEIVE_HOUSING_CO_APPLICANT',
        data,
        applicationType: action.applicationType,
        areaId: action.areaId,
      });
    } catch (e) {
      yield put({ type: 'SAGA_ERROR', e, url, query });
    }
  }
}
