import { useSlides } from 'contexts/index'
import InspectionSlides from './InspectionSlides';

const InspectionSection = () => {

    const {goToSlide} = useSlides();

  return (
    <InspectionSlides goToSlide={goToSlide}/>
    )
}

export default InspectionSection