import env from 'env';

const { LOCK: lock } = env;

const baseLock = `${lock}/ReceptionPage`;
export const getReceptionPageTabsURL = `${baseLock}/GetReceptionPageTabs`;

// this is everything associated with the guardpage
export const getBookingsURL = `${baseLock}/getbookings`;
export const requestNewKeyURL = `${baseLock}/RequestNewKey`;
export const requestNewKeyMyPageURL = `${baseLock}/RequestNewKeyMyPage`;
export const getBookingStatusesURL = `${baseLock}/GetBookingStatuses`;
export const receptionTextsURL = `${baseLock}/ReceptionTexts`;
export const checkInBookingURL = `${baseLock}/CheckInBooking`;
export const getGuardPageExcelFileURL = `${baseLock}/GetGuardpageExcelFile`;

// Arrival list
export const getArrivalListURL = `${lock}/ArrivalList`; // date as param
export const getArrivalListLocationURL = `${baseLock}/ReceptionTexts`; // Arena name is the location

// Fire list
export const getFireListURL = `${lock}/FireList`; // currentDate as param
export const getFireListLocationURL = `${baseLock}/ReceptionTexts`; // Arena name is the location

export default baseLock;
