import Slide from 'components/Slides/Slide';
import { HousingQuarter } from 'contexts/HousingContext';
import { useHousing, useSlides } from 'contexts/index';
import { connect } from 'react-redux';
import { DeliberateAny } from 'types/DelibrateAny';

import ApplicantCategory from '../ApplicantCategory';
import {
  housing,
  housingTitle,
  paths,
  quarter,
  quarterTitle,
} from '../HousingSection';

const UnconnectedApplicantCategorySlide = ({
  appType,
  isRenewalHousing,
  isRenewalQuarter,
  applicationQ,
  applicationH,
}) => {
  const { goToSlide } = useSlides();
  const { applicationType } = useHousing();
  const isRenewalApplication = isRenewalHousing || applicationH.isRenewal || isRenewalQuarter || applicationQ.isRenewal
  
  return (
    <Slide
      path={`${appType === HousingQuarter.Housing ? housing : quarter}${
        paths.applicantCategory
      }`}
      title={appType === HousingQuarter.Housing ? housingTitle : quarterTitle}
      showCount
    >
      <ApplicantCategory
        goToSlide={goToSlide}
        applicationType={applicationType}
        nextSlidePath={`${
          applicationType === HousingQuarter.Quarter ? quarter : housing
        }${isRenewalApplication ? paths.personalInfo : paths.serviceLocation}`}
        isRenewalHousing={isRenewalHousing || applicationH.isRenewal}
        isRenewalQuarter={isRenewalQuarter || applicationQ.isRenewal}
      />
    </Slide>
  );
};

export default connect((state: DeliberateAny) => ({
  isRenewalHousing: state.housing.isRenewalHousing,
  isRenewalQuarter: state.housing.isRenewalQuarter,
  applicationQ: state.housing[`${HousingQuarter.Quarter}Application`],
  applicationH: state.housing[`${HousingQuarter.Housing}Application`],
}))(UnconnectedApplicantCategorySlide);
