import { call, put, select } from 'redux-saga/effects';
import {
  staticFilterOptionsURL,
  myDifsURL,
} from '../api/basenproff/basenproff_api';
import { dataOptionsURL } from '../api/housing/housing_api';
import { axiosFetch } from '../hooks/useAxios';
import { getDropzoneOptionsURL } from 'api/cms/cms_api';

function* handleStaticFilterOptions() {
  try {
    const isLoaded = yield select(
      (state) => state.options.staticFilterOptionsLoaded,
    );
    if (!isLoaded) {
      const options = yield call(axiosFetch, { url: staticFilterOptionsURL });

      yield put({ type: 'RECEIVE_STATIC_FILTER_OPTIONS', options });
    }
  } catch (e) {
    yield put({ type: 'SAGA_ERROR', e, url: staticFilterOptionsURL });
  }
}

function* handleHousingDataOptions() {
  const url = dataOptionsURL;
  try {
    const isLoaded = yield select(
      (state) => state.options.housingDataOptionsLoaded,
    );
    if (!isLoaded) {
      const options = yield call(axiosFetch, { url });
      yield put({ type: 'RECEIVE_HOUSING_DATA_OPTIONS', options });
    }
  } catch (e) {
    yield put({ type: 'SAGA_ERROR', e, url });
  }
}

function* handleMyDifs() {
  const url = myDifsURL;

  try {
    const isLoaded = yield select((state) => state.options.myDifsLoaded);
    if (!isLoaded) {
      const myDifs = yield call(axiosFetch, { url });
      yield put({ type: 'RECEIVE_MY_DIFS', myDifs });
    }
  } catch (e) {
    yield put({ type: 'SAGA_ERROR', e, url });
  }
}
function* handleLoggedInUser(action) {
  try {
    yield put({ type: 'RECEIVE_LOGGEDIN_USER', user: action.user });
  } catch (e) {
    yield put({ type: 'SAGA_ERROR', e });
  }
}

function* handleDropzoneOptions() {
  const url = getDropzoneOptionsURL;

  try {
    const isLoaded = yield select(
      (state) => state.options.dropzoneOptionsLoaded,
    );
    if (!isLoaded) {
      const dropzoneOptions = yield call(axiosFetch, { url });
      yield put({ type: 'RECEIVE_DROPZONE_OPTIONS', dropzoneOptions });
    }
  } catch (e) {
    yield put({ type: 'SAGA_ERROR', e, url });
  }
}

export {
  handleStaticFilterOptions,
  handleHousingDataOptions,
  handleMyDifs,
  handleLoggedInUser,
  handleDropzoneOptions,
};
