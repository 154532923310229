/* eslint-disable */

import { bookeeProfileByIdURL } from "api/booking/booking_api";
import * as bookingConstants from "constants/bookingEnums";
import FBHelpers from "helpers/_helpers";
import {
  bookingRoomTypesWithCount,
  hasSharedRooms,
  isBlockBooking,
  subBookingBookedByOrganizer,
} from "helpers/bookingHelpers";
import { axiosFetch } from "hooks/useAxios";
import { GuestListModal } from "pages/MyPage/TabMyBookings/BookingModals";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DeliberateAny } from "types/DelibrateAny";
import { v4 as uuidv4 } from "uuid";

const Details = (props) => {
  const [guestListModalIsOpen, setGuestListModalIsOpen] = useState(
    props.items.item.SubBookings.map(() => false)
  );
  const [personalData, setPersonalData] = useState<DeliberateAny>();
  useEffect(() => {
    const url = bookeeProfileByIdURL;
    const query = {
      BookeeID: props.profile,
    };
    try {
      axiosFetch({ url, data: query }).then((response) =>
        setPersonalData(response)
      );
    } catch (e) {}
  }, []);

  const handleViewGuestListModal = (event, i) => {
    event.preventDefault();
    const newArray = [...guestListModalIsOpen];
    newArray[i] = true;
    setGuestListModalIsOpen(newArray);
  };

  const printGuestListBlock = () => {
    const item = props.items.item;
    if (item.SubBookings && item.SubBookings.length > 0) {
      return (
        <ul>
          {item.SubBookings.map((subBooking, i) => {
            const roomListSubBooking = bookingRoomTypesWithCount(subBooking);
            return (
              <li key={uuidv4()} className="guest-list-room">
                <p className="half-margin">
                  Bestiller {i + 1} : {subBooking.Bookee}
                </p>
                <p className="no-margin">
                  Pris:{" "}
                  <span className="red">
                    {FBHelpers.formatCurrency(subBooking.PaymentInfo.Price)},-
                  </span>
                </p>
                {!subBookingBookedByOrganizer(item, subBooking) && (
                  <p className="no-margin">
                    {subBooking.GroupInfo.GuestCount} gjest(er)
                  </p>
                )}
                {Object.keys(roomListSubBooking).map((key) => (
                  <p key={uuidv4()} className="no-margin">
                    {roomListSubBooking[key].count}{" "}
                    {roomListSubBooking[key].VBProductId === 7 &&
                      roomListSubBooking[key].count > 1 &&
                      "senger i "}
                    {roomListSubBooking[key].VBProductId === 7 &&
                      roomListSubBooking[key].count < 1 &&
                      "seng i "}
                    {roomListSubBooking[key].name}
                  </p>
                ))}
                <p>
                  <Link
                    to="Se gjesteliste"
                    onClick={(event) => handleViewGuestListModal(event, i)}
                  >
                    Se gjesteliste
                  </Link>
                  {!subBooking.GroupInfo.NameListComplete && "ufullstendig"}
                </p>
              </li>
            );
          })}
        </ul>
      );
    } else if (item.RequestState === bookingConstants.BookingStates.NEW) {
      return (
        <p>
          Bestillingen er ikke godkjent enda. <br />
          Når bestillingen er godkjent vil du få en referansekode som du kan
          dele med deltakerene dine.
        </p>
      );
    }
    return (
      <p>
        Ingen har benyttet seg av referansekoden din enda. <br /> Har du delt
        den med deltakerene dine?
      </p>
    );
  };

  const printGuestList = () => (
    <div className="print-guest-list">
      <ul>
        {props.items.item.RoomInfo.sort((a, b) => a.Index - b.Index).map(
          (room) => (
            <li key={room.Id} className="guest-list-room">
              <p className="room-index red no-margin">
                {`Rom ${room.Index + 1}`}
              </p>
              <h3 className="half-margin">{room.RoomTypeName}</h3>
              <table className="names half-margin">
                <thead>
                  <tr className="list-header">
                    <th className="firstname left-align">Fornavn</th>
                    <th className="left-align">Etternavn</th>
                    <th className="left-align">E-post</th>
                    <th className="left-align">Mobiltelefon</th>
                  </tr>
                </thead>
                <tbody>
                  {room.NameListInfo.NameListItems &&
                    room.NameListInfo.NameListItems.map((guest) => (
                      <tr key={uuidv4()}>
                        <td
                          data-th="Fornavn"
                          className={guest.FirstName ? "" : "italic"}
                        >
                          {guest.FirstName ? guest.FirstName : "navn mangler"}
                        </td>
                        <td data-th="Etternavn">
                          {guest.LastName ? guest.LastName : ""}
                        </td>
                        <td data-th="E-post">
                          {guest.Email ? guest.Email : ""}
                        </td>
                        <td data-th="Telefon">
                          {guest.Phone ? guest.Phone : ""}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {room.Requirements && room.Requirements.length > 0 && (
                <div className="requirements">
                  <p className="no-margin">
                    <strong>Tilpasninger</strong>
                  </p>
                  <p className="no-margin">{room.Requirements}</p>
                </div>
              )}
            </li>
          )
        )}
      </ul>
    </div>
  );

  return (
    <div className="reservationContainer">
      <div className="flex-row">
        {/* Wrapper venstre */}
        {personalData && (
          <div className="user-info">
            <p>
              <b>E-post:</b> {personalData.PrimaryEmail}
            </p>
            <p>
              <b>Sekundær E-post:</b> {personalData.SecondaryEmail}
            </p>
            <p>
              <b>Telefon:</b> {personalData.Phone}
            </p>
            <p>
              <b>Ansattnummer:</b> {personalData.EmployeeNumber}
            </p>
          </div>
        )}

        {/* Wrapper Høyre */}
        <div className="booking-item">
          {isBlockBooking(props.items.item) ? (
            <div className="print-guest-list block-booking">
              <h3>
                Bestilte{" "}
                {hasSharedRooms(props.items.item) ? "rom/senger" : "rom"}
              </h3>
              <div style={{ fontSize: "16px" }}>{printGuestListBlock()}</div>
            </div>
          ) : (
            printGuestList()
          )}
        </div>
      </div>
      <div className="flex-row">
        <div className="booking-comments">
          {props.items.item.Message && props.items.item.Message.length > 0 && (
            <div className="Kommentar">
              <strong>Kommentar ved godkjenning</strong>
              <p>{props.items.item.Message}</p>
            </div>
          )}
          {props.items.item.GroupInfo &&
            props.items.item.GroupInfo.UserComment &&
            props.items.item.GroupInfo.UserComment.length > 0 && (
              <div className="onsker&tilpasninger">
                <strong>Ønsker og tilpasninger</strong>
                <p>{props.items.item.GroupInfo.UserComment}</p>
              </div>
            )}
          {props.items.item.GroupInfo &&
            props.items.item.GroupInfo.Reason &&
            props.items.item.GroupInfo.Reason.length > 0 && (
              <div className="beskrivelse">
                <strong>Beskrivelse</strong>
                <p>{props.items.item.GroupInfo.Reason}</p>
              </div>
            )}
        </div>
      </div>
      {props.items.item.SubBookings &&
        props.items.item.SubBookings.length > 0 &&
        props.items.item.SubBookings.map((subBooking, i) => (
          <GuestListModal
            key={subBooking.Id}
            isOpen={guestListModalIsOpen[i]}
            setGuestListModalIsOpen={setGuestListModalIsOpen}
            subBooking={subBooking}
            guestListModalIsOpen={guestListModalIsOpen}
            position={i}
            requirements={true}
          />
        ))}
    </div>
  );
};

export default Details;
