import './MapLocation.scss';

import { useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import Modal from '../Modal/Modal';
import MapLocationSelector, { MapLocation } from './MapLocationSelector';

type PropsType = {
  modalData: DeliberateAny;
};

const MapPickerModal = ({ modalData }: PropsType) => {
  const [selectedLocation, setSelectedLocation] = useState<MapLocation | null>(
    null,
  );

  return (
    <Modal
      isOpen={modalData.isOpen}
      onClose={() => {
        modalData?.onClose();
      }}
      ariaLabel={modalData.title}
      size={modalData.size}
      submit={{
        text: modalData.submitText,
        color: 'red',
        onClick: () => {
          if (selectedLocation) modalData.onYes(selectedLocation);
        },
      }}
      cancel={{
        text: modalData.cancelText,
        onClick: () => modalData?.onClose(),
      }}
      flipActionOrder={modalData.flipActionOrder}
    >
      <header>
        <h2>{modalData.title}</h2>
      </header>
      <div className="map-picker">
        <div className="map-container"></div>
        <div className="location-info">
          <h3>Se leirene ved å klikke i kartet</h3>
          <MapLocationSelector
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            locations={modalData.arenas}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MapPickerModal;
