import './Booking.scss';

import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Split, { SplitPosition } from 'components/Split/Split';
import Stepper from 'components/Stepper/Stepper';
import { useNewBooking } from 'contexts/index';
import { formatToNorwegianPrice } from 'helpers/rooms';
import { useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { RoomFormItemType } from 'types/BookingTypes/roomTypes';
import { DeliberateAny } from 'types/DelibrateAny';

import Yup from '../utils/yupExtensions';
import BookingContactInfo from './Booking/BookingContactInfo';
import BookingOrder from './Booking/BookingOrder';
import BookingPaymentInfo from './Booking/BookingPaymentInfo';
import BookingRooms from './Booking/BookingRooms';

export type BookingForm = {
  orderType?: string;
  location?: string;
  guestCount?: number;
  arriveDeparture?: DateObject[];
  rooms?: RoomFormItemType[];
};

export interface IBookingFormProps {
  next?: () => void;
  previous?: () => void;
  nav?: (step: number) => void;
  validation?: Yup.ObjectSchema<
    DeliberateAny,
    Yup.AnyObject,
    DeliberateAny,
    ''
  >;
}

const steps = [
  'Bestillingstype',
  'Romtype',
  'Kontaktopplysninger',
  'Betalingsopplysninger',
];

const Booking = () => {
  const {
    bookingError,
    splitValues,
    errors,
    setErrors,
    bookingid,
    reservationId,
    deleteReservation,
    setRooms,
  } = useNewBooking();
  const [step, setStep] = useState(0);
  const [allowedSteps, setAllowedSteps] = useState([0]);

  const [bookingRoomsNextTriggered, setBookingRoomsNextTriggered] =
    useState<boolean>(false);
  const [bookingContactInfoNextTriggered, setBookingContactInfoNextTriggered] =
    useState<boolean>(false);

  const goToStep = async (stepItem: number) => {
    if (stepItem > step) {
      if (Object.keys(errors).length) {
        return;
      }
    } else {
      setErrors({});
    }

    if (stepItem === 2) {
      if (reservationId && bookingRoomsNextTriggered) {
        setRooms(undefined);
        await deleteReservation(reservationId);
      }
    }

    setAllowedSteps((x) => [...x, stepItem]);
    setStep(stepItem);
  };

  const [pressedOrderButton, setPressedOrderButton] = useState<boolean>(false);

  const handleOrderBooking = async () => {
    if (!pressedOrderButton) {
      setPressedOrderButton(true);
    }
  };

  const BookingOrderValidation = Yup.object().shape({
    orderType: Yup.string().required(),
    guestCount: Yup.number().required().min(1),
    location: Yup.string().required(),
    arriveDeparture: Yup.array().of(Yup.number()).required().dateRange(),
  });

  const BookingRoomsValidation = Yup.object().shape({
    rooms: Yup.number()
      .required('Det må velges minst ett rom før du kan gå videre')
      .min(1, 'Det må velges minst ett rom før du kan gå videre'),
    tooFewRooms: Yup.bool().when('rooms', {
      is: (rooms: number) => rooms > 0,
      then: (schema) =>
        schema.oneOf([false], 'Det er for få rom for antall gjester'),
      otherwise: (schema) => schema,
    }),
    tooManyRooms: Yup.bool().when('rooms', {
      is: (rooms: number) => rooms > 0,
      then: (schema) =>
        schema.oneOf([false], 'Det er for mange rom for antall gjester'),
      otherwise: (schema) => schema,
    }),
  });

  return (
    <>
      <Split
        position={SplitPosition.Bottom}
        className={step < 1 ? 'outside' : ''}
      >
        <div className="booking-split">
          <div className="booking-split-left">
            <div className="booking-split-left-total">
              Totalpris{' '}
              {`(${splitValues?.nights} netter, ${splitValues?.rooms} rom)`}
            </div>
            <div className="booking-split-left-price">
              {formatToNorwegianPrice(splitValues?.price ?? 0)}
            </div>
          </div>
          <div className="booking-split-right">
            <ButtonRow>
              <ButtonRow.Right
                onClick={() => {
                  goToStep(Math.max(step - 1, 0));
                }}
                className="secondary"
              >
                Tilbake
              </ButtonRow.Right>
              {step < steps.length - 1 ? (
                <ButtonRow.Right
                  onClick={() => {
                    switch (step) {
                      case 1:
                        setBookingRoomsNextTriggered(true);
                        break;
                      case 2:
                        setBookingContactInfoNextTriggered(true);
                        break;
                    }
                    goToStep(Math.min(step + 1, steps.length - 1));
                  }}
                  className="primary"
                >
                  Neste
                </ButtonRow.Right>
              ) : (
                <ButtonRow.Right
                  onClick={() => {
                    handleOrderBooking();
                  }}
                  className="primary"
                >
                  Bestill
                </ButtonRow.Right>
              )}
            </ButtonRow>
          </div>
        </div>
      </Split>

      <div className="fb-booking">
        <Stepper
          steps={steps}
          activeStep={step}
          setActiveStep={setStep}
          allowedSteps={allowedSteps}
          title={bookingid ? 'Endre overnatting' : 'Bestill overnatting'}
        />

        {Object.entries(bookingError).length > 0 && (
          <div className="fb-new fb-bo-container">
            <AlertMessage variant={AlertType.Error} list>
              {Object.entries(bookingError).map(
                ([key, value]: DeliberateAny) => (
                  <p key={key}>{value}</p>
                ),
              )}
            </AlertMessage>
          </div>
        )}
        {step === 0 && (
          <BookingOrder
            next={() => goToStep(1)}
            validation={BookingOrderValidation}
          />
        )}
        {step === 1 && (
          <BookingRooms
            nav={setStep}
            next={() => goToStep(2)}
            validation={BookingRoomsValidation}
            nextTriggered={bookingRoomsNextTriggered}
          />
        )}

        {step === 2 && (
          <BookingContactInfo
            next={() => goToStep(3)}
            nav={setStep}
            nextTriggered={bookingContactInfoNextTriggered}
          />
        )}

        {step === 3 && (
          <BookingPaymentInfo
            pressedOrderButton={pressedOrderButton}
            setPressedOrderButton={setPressedOrderButton}
          />
        )}
      </div>
    </>
  );
};

export default Booking;
