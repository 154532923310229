// @flow
 
/* eslint react/prop-types: 0 */

import { useState } from 'react';
import Select from 'components/form/Select/Select';
import Input from 'components/form/Input/Input';

const ServiceOrderFilter = ({
  setFilterAndSearch,
  opstat,
  optype,
  loading,
}) => {
  const initialStates = {
    StateFilter: '',
    TypeFilter: '',
    ServiceOrderNumber: '',
  };

  // filter-Object that is sent on submit
  const [filterState, setFilterState] = useState(initialStates);

  const handleFilterChange = (name, value) => {
    const val = value === -1 ? '' : value;
    setFilterState({ ...filterState, [name]: val });
  };

  const handleSubmit = () => {
    setFilterAndSearch(filterState);
  };

  const handleClear = () => {
    setFilterState(initialStates);
    setFilterAndSearch(initialStates);
  };

  const [showOnMobile, setShowOnMobile] = useState(false);

  return (
    <div>
      <div className="row double-margin">
        <div className="case-filter form">
          <header className="hide-for-medium hide-for-large buttons margin">
            <button
              className="btn light-grey"
              onClick={() => setShowOnMobile(!showOnMobile)}
            >
              Filtrer
            </button>
          </header>
          <div
            className={`small-expandable ${
              showOnMobile ? 'mobile-filter-visible' : ''
            }`}
          >
            <div className="content-group grey padded expand-container">
              <div className="input-container">
                <div className="flex-row margin">
                  <Input
                    title="SO-nummer"
                    name="ServiceOrderNumber"
                    type="text"
                    id="ServiceOrderNumber"
                    value={filterState.ServiceOrderNumber}
                    onChange={handleFilterChange}
                  />
                  <Select
                    name="StateFilter"
                    label="Status"
                    helpText="Status beskrivelser nytt"
                    options={opstat.map((opt) => ({
                      key: opt.Key,
                      value: opt.Value,
                    }))}
                    value={filterState.StateFilter}
                    onChange={handleFilterChange}
                    showAllOption
                    loading={loading}
                  />
                  <Select
                    name="TypeFilter"
                    label="Type"
                    options={optype.map((opt) => ({
                      key: opt.Key,
                      value: opt.Value,
                    }))}
                    value={filterState.TypeFilter}
                    onChange={handleFilterChange}
                    showAllOption
                    loading={loading}
                  />
                </div>
              </div>
              <div className="button-row">
                <div>
                  {' '}
                  <button className="btn red" onClick={handleSubmit}>
                    Filtrer
                  </button>{' '}
                  <button className="btn grey" onClick={handleClear}>
                    Tøm filter
                  </button>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceOrderFilter;
