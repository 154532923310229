/* eslint react/prop-types: 0 */

import PasteInNames from "components/booking/PasteInNames";
import Modal from "components/modals/Modal/Modal";
import React, { useRef } from "react";
import { DeliberateAny } from "types/DelibrateAny";

const PasteInNamesModal = ({
  isOpen,
  setPasteInNamesModalIsOpen,
  setRoomInfo,
  roomInfo,
}) => {
  const inputRef = useRef<DeliberateAny>();

  const handleCloseModal = () => {
    inputRef.current.handleClick();
    setPasteInNamesModalIsOpen(false);
  };

  return (
    <Modal
      title="Lim inn gjesteliste"
      ariaLabel="Lim inn gjesteliste"
      isOpen={isOpen}
      submit={{
        text: "Ok",
        onClick: () => handleCloseModal(),
        ignoreOnClose: true,
      }}
      cancel={{
        text: "Avbryt",
        onClick: () => setPasteInNamesModalIsOpen(false),
      }}
      onClose={() => setPasteInNamesModalIsOpen(false)}
    >
      <PasteInNames
        setRoomInfo={setRoomInfo}
        roomInfo={roomInfo}
        bookingModal
        ref={inputRef}
      />
    </Modal>
  );
};

export default PasteInNamesModal;
