import './Pagination.scss';

import { LeftIcon, RightIcon } from 'components/BasenIcon';
import Button from 'components/buttons/Button/Button';
import { Dispatch, FC, useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import { v4 } from 'uuid';

interface IClientPaginationProps {
  items: DeliberateAny[];
  setItems: Dispatch<DeliberateAny[]>;
  itemsPerPage?: number;
}

const ClientPagination: FC<IClientPaginationProps> = ({
  items,
  setItems,
  itemsPerPage = 10,
}) => {
  const [current, setCurrent] = useState(1);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  useEffect(() => {
    setItems(items.slice((current - 1) * itemsPerPage, current * itemsPerPage));
  }, [current]);

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    } else setCurrent(1);
  };

  const handleNext = () => {
    if (current < totalPages) {
      setCurrent(current + 1);
    } else setCurrent(totalPages);
  };

  const handlePage = (page: number) => {
    setCurrent(page);
  };

  const paginationRange = () => {
    const totalPageCount = Math.ceil(items.length / itemsPerPage);
    const siblingCount = 1;
    const maxElementCount = siblingCount + 6; // Max 7 elements

    const getRange = (start, end) => {
      const length = end - start + 1;
      return Array.from({ length }, (_, index) => index + start);
    };

    if (maxElementCount >= totalPageCount) {
      return getRange(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(current - siblingCount, 1);
    const rightSiblingIndex = Math.min(current + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = getRange(1, leftItemCount);

      return [...leftRange, '...', totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = getRange(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );
      return [firstPageIndex, '...', ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = getRange(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
    }
    return [];
  };

  const renderPageController = () => {
    return (
      <>
        {paginationRange().map((page) => {
          if (page == '...') {
            return (
              <li key={`paginator_ellipsis_${v4()}`} className="ellipsis">
                ...
              </li>
            );
          }
          return (
            <li key={`paginator_page_${page}`}>
              <Button
                className={`tertiary ${page == current ? 'active' : ''}`}
                onClick={() => handlePage(page)}
              >
                {page}
              </Button>
            </li>
          );
        })}
      </>
    );
  };

  return (
    <>
      {totalPages > 1 && (
        <div className="paginator">
          <Button
            className="tertiary"
            icon={LeftIcon}
            onClick={handlePrevious}
          ></Button>
          <div className="page-numbers">{renderPageController()}</div>
          <Button
            className="tertiary"
            icon={RightIcon}
            onClick={handleNext}
          ></Button>
        </div>
      )}
    </>
  );
};

export default ClientPagination;
