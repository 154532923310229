import { confirmURL } from "api/cms/cms_api";
import Loader from "components/Loader/Loader";
import { useLogin } from "contexts/index";
import { LoginPath } from "contexts/LoginContext";
import useAxios from "hooks/useAxios";
import { useEffect } from "react";
import { useLocation } from "react-use";
import { createUrlWithQuery, extractQueryParams } from "utils/helpers";

import BackButton from "./BackButton";

const Confirm = () => {
  const location = useLocation();
  const { setPath } = useLogin();
  const { sendRequest, requestLoading, requestError } = useAxios();

  const confirmIdpCode = async () => {
    const params = extractQueryParams(location.href ?? "");
    const url = createUrlWithQuery(confirmURL, params);

    await sendRequest(
      {
        url,
        method: "GET",
      },
      () => setPath(LoginPath.setup)
    );
  };

  useEffect(() => {
    confirmIdpCode();
  }, []);

  return (
    <div data-flip-id="new-user" className="flip-card-front scrollable">
      <BackButton />
      <Loader isLoading={requestLoading}>
        <p>
          Det oppstod en feil ved kommunikasjon med IDPorten. Forsøk igjen
          seinere.
        </p>
      </Loader>
    </div>
  );
};

export default Confirm;
