import env from 'env';
import { createContext, FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchLoggedInUser } from '../actions/optionsActions';
import useAxios from '../hooks/useAxios';
import { IWrapper } from '../interfaces/IWrapper';
import { DeliberateAny } from '../types/DelibrateAny';
import { useLogin } from './';
import { LoginPath } from './LoginContext';

export const GET_USER_URL = '/User/GetUserInfoForCurrentUser/';
export const POST_USER_URL = '/User/ConfirmUserProfile/';
export const GET_USER_PARAMS = {
  Username: true,
  XpandUserId: true,
  Firstname: true,
  Lastname: true,
  Name: true,
  Employeenumber: true,
  Email: true,
  Secondaryemail: true,
  Countrycode: true,
  Phone: true,
  ProfileCheck: true,
  Clientsquareaccess: true,
  Widgetconfigs: true,
  ReceiveHousingSms: true,
  IsValidatedUser: true,
  IsGuardOnlyUser: true,
  AdditionalServiceAccess: true,
  PreventProfileCheckDialog: true,
  HasConsentedStoringPersonalData: true,
  DateConsentedStoringPersonalData: true,
  JwtToken: true,
};

export type ConfirmUserProfileDto = {
  FirstName: string;
  LastName: string;
  PrimaryEmail: string;
  SecondaryEmail: string;
  CountryCode: string;
  Phone: string;
  HasConsentedStoringPersonalData: boolean;
  ProfileCheck: boolean;
};

export const UserContext = createContext({} as DeliberateAny);

export const UserContextProvider: FC<IWrapper> = ({ children }) => {
  const { CMS: cmsUrl } = env;
  const { setPath, setShow } = useLogin();
  const { requestLoading, sendRequest } = useAxios();
  const [user, setUser] = useState<DeliberateAny>(null);
  const [hasUser, setHasUser] = useState(false);
  const [hasProffAccess, setHasProffAccess] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user !== undefined && user !== null) {
      dispatch(fetchLoggedInUser(user));
    }

    // setHasUser(!!(user && user?.EmployeeNumber !== '0000'));
    // setHasProffAccess(!!(user && user?.ClientSquareAccess === 'True'));
  }, [user]);

  const fetchUser = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: cmsUrl + GET_USER_URL,
        params: GET_USER_PARAMS,
        withCredentials: true,
      },
      (result) => {
        setHasUser(!!(result && result?.EmployeeNumber !== '0000'));
        setHasProffAccess(!!(result && result?.ClientSquareAccess === 'True'));
        setUser(result);
      },
    );
  };

  useEffect(() => {
    if (
      user &&
      (user.HasConsentedStoringPersonalData !== 'True' ||
        user.ProfileCheck === 'True') &&
      hasUser
    ) {
      setPath(LoginPath.update);
      setShow(true);
    }
  }, [hasUser]);

  const updateUser = async (userData: ConfirmUserProfileDto) => {
    if (!userData.HasConsentedStoringPersonalData) return false;

    await sendRequest(
      {
        method: 'POST',
        url: cmsUrl + POST_USER_URL,
        data: userData,
        withCredentials: true,
      },
      () => {
        fetchUser();
      },
    );

    return true;
  };

  const values = useMemo(
    () => ({
      user,
      requestLoading,
      isLoading: requestLoading,
      loading: requestLoading,
      fetchUser,
      hasUser,
      hasProffAccess,
      updateUser,
    }),
    [user, requestLoading, hasUser, hasProffAccess],
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
