import './FireListHeader.scss';

import Button from 'components/buttons/Button/Button';
import DateInput from 'components/DateInput/DateInput';
import ComboMultiSelect, { ValueOption } from 'components/form/ComboMultiSelect/ComboMultiSelect';
import { formatDate } from 'helpers/valueHelpers';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';
import { KeyValueType } from 'types/commonTypes';

import { FireType } from '../TabFireListPage';

interface IFireListProps {
  setSelectedFilters: (item: any) => void;
  selectedDate: Date;
  setSelectedDate: (item: Date) => void;
  triggerListUpdate: () => void;
  lastUpdated: Date;
  fireList: { totalFireList: FireType[]; filteredFireList: FireType[] };
  printFireList: () => void;
  isLoading?: boolean;
}

const FireListHeader: FC<IFireListProps> = ({
  setSelectedFilters,
  selectedDate,
  setSelectedDate,
  triggerListUpdate,
  lastUpdated,
  fireList: { totalFireList, filteredFireList },
  printFireList,
  isLoading,
}) => {
  const [totalGuestNumber, setTotalGuestNumber] = useState<number>();
  const [filteredGuestNumber, setFilteredGuestNumber] = useState<number>();

  const [selectedCheckInStatus, setSelectedCheckInStatus] = useState<
    KeyValueType[]
  >([]);
  const [buildingFilterOptions, setBuildingFilterOptions] = useState<
    KeyValueType[]
  >([]);
  const [selectedBuildings, setSelectedBuildings] = useState<KeyValueType[]>(
    [],
  );

  const formatDateTime = (date) => {
    return `${new Date(date).toLocaleDateString('no', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })} - ${new Date(date).toLocaleTimeString('no', {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  };

  useEffect(() => {
    fetchBuildingFilterOptions();
  }, [selectedDate, totalFireList]);

  useEffect(() => {
    setSelectedFilters({
      selectedBuildings: selectedBuildings,
      selectedCheckInStatus: selectedCheckInStatus,
    });
  }, [selectedBuildings, selectedCheckInStatus]);

  useEffect(() => {
    setTotalGuestNumber(totalFireList?.length);
    setFilteredGuestNumber(filteredFireList?.length);
  }, [totalFireList, filteredFireList]);

  const fetchBuildingFilterOptions = () => {
    const uniqueList = Array.from(
      new Set(
        totalFireList.map((person) => {
          return person.buildingId ? person.buildingId : '';
        }),
      ),
    ).map((buildingId) => {
      if (!buildingId || buildingId === '') {
        return { key: 'unset', value: 'Ikke tilordnet' };
      }
      return { key: buildingId, value: buildingId };
    });

    // Remove any duplicates for 'unset'
    const filteredList = uniqueList.filter((option) => option.key !== 'unset');

    // Prepend 'alle' and 'unset' at the beginning
    setBuildingFilterOptions([
      { key: 'alle', value: 'Alle' },
      { key: 'unset', value: 'Ikke tilordnet' },
      ...filteredList,
    ]);
  };

  // Reset filters
  const resetFilters = () => {
    setSelectedCheckInStatus([]);
    setSelectedBuildings([]);
  };

  // Event handlers
  const onDateSelected = (date: Moment) => {
    setSelectedDate(date.toDate());
  };

  const updateList = () => {
    triggerListUpdate();
  };

  const render = () => (
    <div className="arrival-list-header grey">
      <div className="left-side">
        <div className="date-input no-print">
          <div className="date-input-field">
            <DateInput
              title="Dato"
              name="arrival"
              selectedDate={moment(selectedDate).startOf('D')}
              selectionSpan={365}
              onSelectDate={onDateSelected}
            />
          </div>
          {!moment().isSame(selectedDate, 'day') && (
            <Button
              style={{
                height: 66,
                marginTop: 'auto',
                marginRight: 'auto',
                whiteSpace: 'nowrap',
              }}
              onClick={() => setSelectedDate(new Date())}
              disabled={moment().isSame(selectedDate, 'day')}
            >
              I dag
            </Button>
          )}
        </div>
        <div className="number-of-guests print-only">
          <div>
            <span>Dato:</span>
            <span className="bold">{formatDate(selectedDate)}</span>
          </div>
        </div>

        <div className="number-of-guests">
          {filteredGuestNumber != totalGuestNumber && (
            <div>
              <span>Antall beboere i filter:</span>
              <span>{filteredGuestNumber}</span>
            </div>
          )}
          <div>
            <span>Totalt antall beboere på lokasjon:</span>
            <span>{totalGuestNumber}</span>
          </div>
        </div>
        <div>
          <div className="last-updated">
            <div>{`Sist oppdatert: `}</div>
            <div>{formatDateTime(lastUpdated)}</div>
          </div>
          <Button
            className="no-print"
            style={{ marginTop: '1.5em' }}
            onClick={updateList}
          >
            Oppdater liste
          </Button>
        </div>
      </div>
      <div className="right-side">
        <div className="building-input">
          <span>Bygg</span>
          <span className="building-input-field no-print">
            <ComboMultiSelect
              initial="Velg noe"
              name={'selectBuildings'}
              placeHolder="Velg filter"
              helpText="Bygning"
              options={buildingFilterOptions.map((option) => {
                return {
                  key: option.key,
                  value: option.value,
                } as ValueOption;
              })}
              values={selectedBuildings}
              onChange={(values) => {
                setSelectedBuildings(values);
              }}
              isLoading={isLoading}
            />
          </span>
        </div>
        <div className="print-only">
          {selectedBuildings?.length === 0
            ? buildingFilterOptions
                .filter((x) => !['alle', 'unset'].includes(x.key))
                .sort((a, b) => a.value.localeCompare(b.value))
                .map((x) => x.value)
                .join(', ')
            : selectedBuildings
                .sort((a, b) => a.value.localeCompare(b.value))
                .map((x) => x.value)
                .join(', ')}
        </div>

        <div className="button-group no-print margin-top-auto">
          <Button onClick={() => resetFilters()}>Tøm filter</Button>
          <Button
            disabled={isLoading}
            color="red"
            onClick={() => printFireList()}
          >
            Skriv ut brannliste
          </Button>
        </div>
      </div>
    </div>
  );

  return render();
};

export default FireListHeader;
