import './SelectRoomType.scss';

import { AlertType } from 'components/AlertMessage/AlertMessage';
import Badge from 'components/Badge/Badge';
import * as I from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import Counter from 'components/Counter/Counter';
import Image from 'components/ImageComponents/Image';
import Loader from 'components/Loader/Loader';
import { useNewBooking } from 'contexts/index';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { RoomType } from 'types/BookingTypes/roomTypes';

const SelectRoomType: FC = () => {
  const {
    errors,
    bookingSearch,
    roomCounts,
    setRoomCounts,
    roomInfo,
    availableLoading,
  } = useNewBooking();
  const [showAllFacilitiesItem, setShowAllFacilitiesItem] = useState<string[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (roomInfo && (roomInfo?.length ?? 0) > 0) {
      const newRoomCount = {};
      for (const roomI of roomInfo) {
        newRoomCount[roomI.roomType.roomTypeId] = roomI.defaultSelectedCount;
      }
      setRoomCounts(newRoomCount);
      setIsLoading(false);
    }
  }, [roomInfo]);

  const fetchRoomAvailability = (roomTypeId: string) => {
    const room = roomInfo?.find(
      (room) => room.roomType.roomTypeId == roomTypeId,
    );
    return room ? room.availableCount : 0;
  };

  const getNrOfDays = () => {
    if (bookingSearch.arriveDeparture) {
      return moment(bookingSearch.arriveDeparture?.[1]?.toDate()).diff(
        moment(bookingSearch.arriveDeparture?.[0]?.toDate()),
        'days',
      );
    }
    return 0;
  };

  const addShowFacilities = (roomTypeId: string) => {
    setShowAllFacilitiesItem([...showAllFacilitiesItem, roomTypeId]);
  };

  const removeShowFacilities = (roomTypeId: string) => {
    setShowAllFacilitiesItem(
      showAllFacilitiesItem.filter((item) => item != roomTypeId),
    );
  };

  const renderRoomFacilities = (roomType: RoomType) => {
    const showAllFacilities = showAllFacilitiesItem.includes(
      roomType.roomTypeId,
    );
    return (
      <div
        dangerouslySetInnerHTML={{ __html: roomType.facilities }}
        className={`facility-list ${showAllFacilities ? 'open' : ''}`}
      />
    );
  };

  const updateForm = (id: string, value: number) => {
    const updatedRoomCounts = {
      ...roomCounts,
      [id]: value,
    };
    setRoomCounts(updatedRoomCounts);
  };

  const renderRoomTypes = () => {
    if (roomInfo) {
      return roomInfo.map((room) => {
        return (
          <div
            key={`room-type-${room.roomType.roomTypeId}`}
            className="room-type-container"
          >
            <div className="room-type-booking-image-container ">
              <Image
                image={room.roomType.roomIcon}
                className="room-type-booking-image"
              />
            </div>
            <div className="room-type-facilities">
              <div>
                <label>{room.roomType.heading}</label>
                {renderRoomFacilities(room.roomType)}
              </div>

              <div
                className="facility-button"
                onClick={
                  showAllFacilitiesItem.includes(room.roomType.roomTypeId)
                    ? () => removeShowFacilities(room.roomType.roomTypeId)
                    : () => addShowFacilities(room.roomType.roomTypeId)
                }
              >
                Vis fasiliteter og regler
                <div
                  className={`facility-icon ${showAllFacilitiesItem.includes(room.roomType.roomTypeId) ? 'open' : ''}`}
                >
                  <BasenIcon icon={I.DownIcon} />
                </div>
              </div>
            </div>

            <div className="room-type-selection">
              {room.availableCount > 0 && (
                <Badge
                  variant={AlertType.Special1}
                >{`${Math.round(room.roomType.price ?? 0)},- for ${getNrOfDays()} ${getNrOfDays() != 1 ? 'netter' : 'natt'}`}</Badge>
              )}
              <Counter
                name={room.roomType.roomTypeId}
                label={'Antall rom'}
                formHolder={roomCounts}
                updateForm={updateForm}
                errors={errors}
                max={fetchRoomAvailability(room.roomType.roomTypeId)}
                min={0}
              />
              <div>{`(${fetchRoomAvailability(room.roomType.roomTypeId)} ledig)`}</div>
            </div>
          </div>
        );
      });
    }
  };
  return (
    <div className="flex-column gap">
      <span className="select-room-header">Velg romtype</span>
      <Loader isLoading={isLoading || availableLoading}>
        <div className="room-types">{renderRoomTypes()}</div>
      </Loader>
    </div>
  );
};

export default SelectRoomType;
