import './Table.scss';

import * as I from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import HelpText from 'components/HelpText';
import ClientPagination from 'components/Pagination/ClientPagination';
import ServerPagination from 'components/Pagination/ServerPagination';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import { v4 } from 'uuid';

export type TableColumnType<T> = {
  key: string;
  label: string;
  conditionalRendering?: (item: T) => React.ReactNode;
  sortable?: boolean;
  helpText?: string;
};

export enum TableMobileView {
  CardView = 'card-view',
  MinimalView = 'minimal-view',
}

interface PaginationProps {
  type: 'client' | 'server';
  itemsPerPage?: number;
  fetchItems?: (page: number, itemsPerPage: number) => void;
}

interface ITablePropsType<T> {
  columns: TableColumnType<T>[];
  items: T[];
  className?: string;
  mobileView?: { viewType: TableMobileView; priorityColumnKeys?: string[] };
  pagination?: PaginationProps;
  triggerServerFetch?: (sortField: string, sortOrder: string) => void;
  expandableContent?: (item: T) => React.ReactNode;
  totalItems?: number;
  currentPage?: number;
  onPageChange?: (page: number) => void;
  onItemClick?: (item: T) => void; // New prop for item click handler
}

const Table = <T,>({
  columns,
  items,
  className,
  mobileView,
  triggerServerFetch,
  expandableContent,
  pagination,
  totalItems,
  currentPage = 1,
  onPageChange = () => {},
  onItemClick = () => {}, // Default to a no-op if not provided
}: ITablePropsType<T>) => {
  const [orderedItems, setOrderedItems] = useState<DeliberateAny>(items);
  const [sortField, setSortField] = useState<string>('');
  const [order, setOrder] = useState<string>('asc');
  const [expandedContent, setExpandedContent] = useState<Set<string>>(
    new Set(),
  );

  useEffect(() => {
    setOrderedItems(items);
  }, [items]);

  const handleSortingChange = (key: string) => {
    const sortOrder = key === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(key);
    setOrder(sortOrder);
    if (triggerServerFetch) {
      triggerServerFetch(key, sortOrder);
    } else {
      handleSorting(key, sortOrder);
    }
  };

  const handleSorting = (key: string, sortOrder: string) => {
    const sortedItems = [...items].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];
      if (aValue instanceof Object) {
        const properties = Object.getOwnPropertyNames(aValue);
        aValue = aValue[properties[0]];
        bValue = bValue[properties[0]];
      }

      if (aValue instanceof Date) {
        aValue = moment(aValue, 'YYMMDD');
        bValue = moment(bValue, 'YYMMDD');
        return (aValue - bValue) * (sortOrder === 'asc' ? 1 : -1);
      }
      return (
        aValue
          .toString()
          .localeCompare(bValue.toString(), 'no', { numeric: true }) *
        (sortOrder === 'asc' ? 1 : -1)
      );
    });
    setOrderedItems(sortedItems);
  };

  const handleExpandableContent = (rowId: string) => {
    const updatedExpandedContent = new Set(expandedContent);
    if (expandedContent.has(rowId)) {
      updatedExpandedContent.delete(rowId);
    } else {
      updatedExpandedContent.add(rowId);
    }
    setExpandedContent(updatedExpandedContent);
  };

  const renderColumns = () => (
    <tr>
      {columns.map((column) => {
        const isPriorityColumn =
          mobileView?.priorityColumnKeys?.includes(column.key) ?? false;
        return (
          <th
            key={column.key}
            className={isPriorityColumn ? 'priority-column' : ''}
            onClick={
              column.sortable
                ? () => handleSortingChange(column.key)
                : undefined
            }
          >
            <div
              className={`sortable ${sortField === column.key ? 'active' : ''}`}
            >
              {column.helpText ? (
                <HelpText content={column.helpText}>{column.label}</HelpText>
              ) : (
                <span>{column.label}</span>
              )}
              {column.sortable && (
                <BasenIcon
                  icon={
                    sortField === column.key && order === 'asc'
                      ? I.UpIcon
                      : I.DownIcon
                  }
                  size="s"
                />
              )}
            </div>
          </th>
        );
      })}
    </tr>
  );

  const renderItems = () => {
    return orderedItems.map((item) => {
      const id = item?.id ?? v4();
      const isRowExpanded = expandedContent.has(id);

      return (
        <React.Fragment key={id}>
          <tr
            tabIndex={0}
            onClick={() => {
              onItemClick(item); // Call onItemClick when a row is clicked
              expandableContent && handleExpandableContent(id);
            }}
          >
            {columns.map((column) => {
              const value = column.conditionalRendering
                ? column.conditionalRendering(item)
                : item[column.key];
              const isPriorityColumn =
                mobileView?.priorityColumnKeys?.includes(column.key) ?? true;

              return (
                <td
                  key={column.key}
                  className={isPriorityColumn ? 'priority-column-value' : ''}
                  data-name={column.label}
                >
                  {value}
                </td>
              );
            })}
          </tr>
          {expandableContent && isRowExpanded && (
            <tr className="expanded-content">
              <td colSpan={columns.length}>
                <div className="content-container">
                  {expandableContent(item)}
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="fb-table">
      <table
        className={`${className ?? ''} ${mobileView?.viewType ?? ''} ${
          expandableContent ? 'collapsible' : ''
        }`}
      >
        <thead>{renderColumns()}</thead>
        <tbody>{renderItems()}</tbody>
      </table>
      {pagination && pagination.type === 'client' && (
        <ClientPagination
          items={items}
          setItems={setOrderedItems}
          itemsPerPage={pagination.itemsPerPage}
        />
      )}
      {pagination && pagination.type === 'server' && pagination.fetchItems && (
        <ServerPagination
          fetchItems={pagination.fetchItems}
          itemsPerPage={pagination.itemsPerPage}
          totalItems={totalItems ?? 0}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default Table;
