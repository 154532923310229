import { format } from 'date-fns';
import env from 'env';

const { BOOKINGV2: baseBooking } = env;

export enum ConfirmType {
  Provider,
  Delayed,
  Io,
  Invoice,
}

// Arena Endpoints
export const arenaUrl = `${baseBooking}/api/arena`;
export const arenaByIdUrl = (locationId: string) =>
  `${baseBooking}/api/arena/${locationId}`;
export const arenaAvailabilityUrl = (locationId: string) =>
  `${baseBooking}/api/arena/${locationId}/Availability`;
export const arenaAvailableRoomsUrl = (locationId: string) =>
  `${baseBooking}/api/arena/${locationId}/AvailableRooms`;
export const arenaAvailableRoomsForBookingUrl = (
  locationId: string,
  arrivalDate: Date,
  departureDate: Date,
  numberOfGuests: number,
  bookingId: string,
) => {
  const queryString = new URLSearchParams({
    arrivalDate: format(arrivalDate, 'yyyy-MM-dd'),
    departureDate: format(departureDate, 'yyyy-MM-dd'),
    numberOfGuests: numberOfGuests.toString(),
    bookingId,
  }).toString();

  return `${baseBooking}/api/arena/${locationId}/AvailableRoomsForBooking?${queryString}`;
};

// Booking Endpoints
export const bookingByIdUrl = (bookingId: string) =>
  `${baseBooking}/api/booking/${bookingId}`;
export const bookingUpcomingUrl = (
  pageNumber: number = 1,
  itemsPerPage: number = 10,
) =>
  `${baseBooking}/api/booking/upcoming?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;

export const bookingHistoryUrl = (
  pageNumber: number = 1,
  itemsPerPage: number = 10,
) =>
  `${baseBooking}/api/booking/history?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`;

export const bookingResendReceiptUrl = (bookingId: string, email: string) =>
  `${baseBooking}/api/booking/${bookingId}/ResendReceipt?email=${email}`;
export const bookingPreviewCancellationUrl = (bookingId: string) =>
  `${baseBooking}/api/booking/${bookingId}/PreviewCancellation`;
export const bookingCancelUrl = (bookingId: string) =>
  `${baseBooking}/api/booking/${bookingId}/cancel`;

// Booking Change Endpoint
export const bookingChangeUrl = `${baseBooking}/api/bookingChange`;
export const bookingChangeConfirmUrl = (
  bookingChangeId: string,
  confirmType: ConfirmType,
) => {
  const endpoint =
    confirmType === ConfirmType.Delayed
      ? 'confirmWithDelayedPayment'
      : confirmType === ConfirmType.Invoice
        ? 'confirmWithInvoice'
        : confirmType === ConfirmType.Io
          ? 'confirmWithIo'
          : confirmType === ConfirmType.Provider
            ? 'confirmWithPaymentProvider'
            : 'Confirm type missing';
  return `${baseBooking}/api/bookingChange/${bookingChangeId}/${endpoint}`;
};

// Booking Options Endpoint
export const bookingOptionsUrl = `${baseBooking}/api/bookingoptions`;

// Booking Change Payment Method Endpoints
export const payByProviderUrl = (bookingId: string) =>
  `${baseBooking}/api/booking/${bookingId}/PaymentInfo/PayByProvider`;
export const payByIoUrl = (bookingId: string) =>
  `${baseBooking}/api/booking/${bookingId}/PaymentInfo/PayByIo`;
export const payByInvoiceUrl = (bookingId: string) =>
  `${baseBooking}/api/booking/${bookingId}/PaymentInfo/PayByInvoice`;

// Reservation Endpoints
export const reservationUrl = `${baseBooking}/api/reservation`;
export const reservationByIdUrl = (reservationId: string) =>
  `${baseBooking}/api/reservation/${reservationId}`;
export const reservationDeleteByIdUrl = (reservationId: string) =>
  `${baseBooking}/api/reservation/${reservationId}`;
export const reservationUpdateByIdUrl = (reservationId: string) =>
  `${baseBooking}/api/reservation/${reservationId}`;
export const reservationConfirmWithPaymentProviderUrl = (
  reservationId: string,
) =>
  `${baseBooking}/api/reservation/${reservationId}/confirmWithPaymentProvider`;
export const reservationConfirmWithDelayedPaymentUrl = (
  reservationId: string,
) =>
  `${baseBooking}/api/reservation/${reservationId}/confirmWithDelayedPayment`;
export const reservationConfirmWithIoUrl = (reservationId: string) =>
  `${baseBooking}/api/reservation/${reservationId}/confirmWithIo`;
export const reservationConfirmWithInvoiceUrl = (reservationId: string) =>
  `${baseBooking}/api/reservation/${reservationId}/confirmWithInvoice`;

export const verifyAuthorizationPayment = (
  payeeDomain: string,
  orderReference: string,
) =>
  `${baseBooking}/api/payment/VerifyAuthorization/${payeeDomain}/${orderReference}`;
export const limitedBookingUrl = (bookingId: string) =>
  `${baseBooking}/api/booking/${bookingId}/limited`;

// Exporting the base URL
export default baseBooking;
