// @flow
import { getSignedDocumentPathURL } from '../api/basenproff/basenproff_api';

export const SIGNATURE_STATE_SUCCESS = 'success';
export const SIGNATURE_STATE_ERROR = 'error';
export const SIGNATURE_STATE_REJECTION = 'rejection';

export const SIGNED_ADDITIONAL_SERVICES_WARNING = 'Merk at signerte dokumenter er unntatt offentlighet jmfr. Offentlighetsloven § 13, 1. ledd. Hvis dokumentene lastes ned etter signering må de derfor behandles ihht gjeldende rutiner for dokumenter unntatt offentlighet.';

export const getSignedDocumentPath = (statusQueryToken: string) =>
  `${getSignedDocumentPathURL}${statusQueryToken}`;
