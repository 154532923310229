import Loader from "components/Loader/Loader";
import Slide from "components/Slides/Slide";
import { useBooking, useUser } from "contexts/index";
import { useNavigate } from "react-router-dom";

import PaymentInfo from "../PaymentInfo";

const PaymentInfoSlide = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const {
    item,
    setItem,
    parentBooking,
    numberOfGuests,
    isBlockBooking,
    arrivalDate,
    departureDate,
    selectedLocation,
    numberOfDaysToFocePayment,
    roomInfo,
    roomInfoPerRoomType,
    setMarkup,
  } = useBooking();

  if (!selectedLocation) {
    navigate("/booking");
    return <Loader isLoading />;
  }

  return (
    <Slide
      path="payment-info"
      title={`Overnatting på ${selectedLocation.Name}`}
      slideSize="none"
    >
      <PaymentInfo
        selectedLocation={selectedLocation}
        arrivalDate={arrivalDate}
        departureDate={departureDate}
        roomInfoPerRoomType={roomInfoPerRoomType}
        numberOfGuests={numberOfGuests}
        parentBooking={parentBooking}
        isBlockBooking={isBlockBooking}
        item={item}
        setItem={setItem}
        setMarkup={setMarkup}
        roomInfo={roomInfo}
        numberOfDaysToFocePayment={numberOfDaysToFocePayment}
        userData={user}
      />
    </Slide>
  );
};

export default PaymentInfoSlide;
