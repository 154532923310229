import { ReactElement } from "react";

type PropsType = {
  children?: ReactElement | Array<ReactElement>,
  white?: boolean,
};

const DetailsDivider = (props: PropsType) => (
  <div className={`details-divider ${props.white ? 'white' : ''}`}>
    {props.children}
  </div>
);

export default DetailsDivider;
