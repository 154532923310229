import './TabMyServiceOrders.scss';

import {
  searchSOforCurrentUserURL,
  staticFilterOptionsURL,
} from 'api/basenproff/basenproff_api';
import Loader from 'components/Loader/Loader';
import Pagination from 'components/Pagination/Pagination';
import ExportList from 'components/serviceorders/ServiceOrderExportList';
import ServiceOrderList from 'components/serviceorders/ServiceOrderList';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import ServiceOrderFilter from './ServiceOrderFilter';

const serviceOrderSearchBaseURL = searchSOforCurrentUserURL;
const filterOptionsURL = staticFilterOptionsURL;

const TabMyServiceOrders = () => {
  const { sendRequest } = useAxios();
  const [items, setItems] = useState<DeliberateAny>([]);
  const [mappedItems, setMappedItems] = useState([]);
  const [searchQueryParam, setSearchQueryParam] = useState({
    SkipCount: 0,
    TakeCount: 10,
  });
  const [pageNumber, setPageNumber] = useState(1);

  // Two objects for holding drop-down data elements
  const [optionsStatus, setOptionsStatus] = useState<DeliberateAny>([]);
  const [optionsOrderTypes, setOptionsOrderTypes] = useState<DeliberateAny>([]);

  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(true);

  // GET calls
  const fetchItems = async (query?) => {
    setLoading(true);
    let addQuery = '';
    if (query) {
      addQuery = '?' + query;
    }
    await sendRequest(
      {
        method: 'GET',
        url: serviceOrderSearchBaseURL + addQuery,
        withCredentials: true,
      },
      (response) => {
        setItems(response);
      },
    ).finally(() => {
      setLoading(false);
    });
  };

  const fetchFilterOptions = async () => {
    setLoadingFilter(true);
    await sendRequest(
      {
        method: 'GET',
        url: filterOptionsURL,
        withCredentials: true,
      },
      (response) => {
        setOptionsStatus(response.ServiceOrderStates);
        setOptionsOrderTypes(response.ServiceOrderTypes);
      },
    ).finally(() => {
      setLoadingFilter(false);
    });
  };

  // Calls setFilter from ServiceOrderFilter. setFilter runs ajax and updates items
  const buildQuery = (query) =>
    Object.keys(query)
      .map((key) => key + '=' + query[key])
      .join('&');

  useEffect(() => {
    void fetchFilterOptions();
    const query = buildQuery(searchQueryParam);
    void fetchItems(query);
  }, [optionsStatus.length]);

  useEffect(() => {
    if (items.length && optionsStatus) {
      const mappedItems = items.map((item) => ({
        ...item,
        ServiceOrderStatePrintable:
          optionsStatus.find((o) => {
            return o.Key === item.ServiceOrderState;
          })?.Value ?? '',
      }));
      setMappedItems(mappedItems);
    } else {
      setMappedItems([]);
    }
  }, [items, optionsStatus.length]);

  // OBS: FilterSearch returns items outside filter state (e.g. returns item with ServiceOrderState = 0 even when filter is set to StateFilter = 4)
  // Search query looks right, but request response still give items. Check backend?
  const setFilterAndSearch = (filter) => {
    setLoading(true);
    setPageNumber(1);
    const query = buildQuery({ ...searchQueryParam, ...filter, SkipCount: 0 });
    setSearchQueryParam({ ...searchQueryParam, ...filter, SkipCount: 0 });
    void fetchItems(query);
  };

  const navigateToPage = (pagenum) => {
    setLoading(true);
    const page = { SkipCount: (pagenum - 1) * searchQueryParam.TakeCount };
    const query = buildQuery({ ...searchQueryParam, ...page });
    void fetchItems(query);
  };

  const serverPaging = {
    pageNumber,
    pageSize: 10,
    numItems: items.length,
    onChangePageNumber: (number) => {
      setPageNumber(number);
      navigateToPage(number);
    },
  };

  const setPaginationCount = (count) => {
    setLoading(true);
    const query = buildQuery({
      ...searchQueryParam,
      SkipCount: 0,
      TakeCount: count,
    });
    setSearchQueryParam({
      ...searchQueryParam,
      SkipCount: 0,
      TakeCount: count,
    });
    void fetchItems(query);
  };

  return (
    <div className="service-order">
      <div style={{ padding: '22px 0 22px 0' }}>
        <h2>Mine registrerte saker</h2>
        Her finner du oversikt over alle dine registrerte saker. Klikk på en
        enkeltsak for å se ytterligere informasjon og alternativer for
        oppfølging.
      </div>

      <ServiceOrderFilter
        setFilterAndSearch={setFilterAndSearch}
        loading={loadingFilter}
        opstat={optionsStatus}
        optype={optionsOrderTypes}
      />

      <Loader isLoading={loading}>
        <div className="button-row half-margin">
          <Pagination
            setPaginationCount={setPaginationCount}
            paginationCount={searchQueryParam.TakeCount}
          />
          <p className="no-margin">
            <ExportList items={mappedItems} />
          </p>
        </div>
        <ServiceOrderList
          items={mappedItems}
          serverPaging={serverPaging}
          urlRoot={''}
          update={fetchItems}
        />
      </Loader>
    </div>
  );
};

export default TabMyServiceOrders;
