import "./DownloadFileArea.scss";

import DownloadButton from "components/buttons/DownloadButton/DownloadButton";
import DownloadLink from "components/DownloadLink/DownloadLink";

import type { ClientLinkType } from "types/commonTypes";
const DownloadFileArea = (props: ClientLinkType) => (
  <div className="download-file-area margin">
    <p>
      <DownloadLink href={props.to}>{props.name}</DownloadLink>
    </p>
    <DownloadButton color="red" href={props.to}>
      Last ned
    </DownloadButton>
  </div>
);

export default DownloadFileArea;
