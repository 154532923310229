import './MyPage.scss';

import Banner from 'components/Banner/Banner';
import { useApplication, useUser } from 'contexts/index';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const MyPage = () => {
  const location = useLocation();
  const { user } = useUser();
  const { heading } = useApplication();
  const [fadeIn, setFadeIn] = useState(false);

  const renderBanner = () => {
    if (location.pathname.includes('receipt')) {
      return (
        <Banner centerAlign={true}>
          <h2 className="fade-in">Overnatting bekreftet</h2>
          <p className="fade-in">
            En kopi av reservasjonen er sendt til {user!.Email}. Kvittering blir
            sendt på e-post når oppholdet belastes ved avreise. Ta vare på
            kvitteringene for dokumentasjon til reiseregninger
          </p>
        </Banner>
      );
    } else {
      return (
        <Banner>
          <h1 className={`${fadeIn ? 'fade-in' : ''}`}>{heading}</h1>
        </Banner>
      );
    }
  };

  useEffect(() => {
    setFadeIn(true);
    const timeoutId = setTimeout(() => setFadeIn(false), 2000); // Must match the animation ++
    return () => clearTimeout(timeoutId);
  }, [heading]);

  useEffect(() => {
    renderBanner();
  }, [location]);

  return (
    <>
      {renderBanner()}
      <div className="fb-main">
        <Outlet />
      </div>
    </>
  );
};

export default MyPage;
