import './FireList.scss';

import List from 'components/List/List';
import UncontrolledColumnHeaders from 'components/List/UncontrolledColumnHeaders';
import ListItem from 'components/ListItem/ListItem';
import { Fragment } from 'react/jsx-runtime';
import { ListColumnType } from 'types/listTypes';
import { v4 as uuidv4 } from 'uuid';

import { FireType } from '../TabFireListPage';

const FireList = ({ fireList }: { fireList: FireType[] }) => {
  const fireListColumns: ListColumnType[] = [
    {
      label: 'Bygg-Rom',
      name: 'buildingIdRoomName',
      className: 'fire-roomname',
    },
    {
      label: 'Innsjekksstatus',
      name: 'checkinStatus',
      className: 'fire-checkin',
    },
    {
      label: 'Navn',
      name: 'guestName',
      className: 'fire-guestname',
    },
    {
      label: 'Telefon',
      name: 'guestPhone',
      className: 'fire-guestphone',
      renderFunction: (value) => {
        const usernamePattern = /\b[a-zA-Z]+[-]?[0-9]+\b/i;
        const hasUsername = usernamePattern.test(value.guestPhone);
        const username = hasUsername
          ? value.guestPhone.match(usernamePattern)[0]
          : '';

        return (
          <>
            <span className={'no-print'}>{value.guestPhone}</span>
            <span className="print-only">
              {hasUsername ? username : value.guestPhone}
            </span>
          </>
        );
      },
    },
    {
      label: 'Forlegningstype',
      name: 'accommodationType',
      className: 'fire-accommodationtype',
    },
  ];

  const renderItem = (item) => {
    const index = fireList.findIndex((i) => i === item) - 1;
    const buildingPrevious =
      fireList[index]?.buildingIdRoomName.split('_')[0] ?? '';
    const buildingCurrent = item.buildingIdRoomName.split('_')[0];

    return (
      <Fragment key={uuidv4()}>
        {(buildingPrevious !== buildingCurrent || buildingCurrent === '') && (
          <>
            {buildingCurrent && index >= 0 && renderNewPage()}

            {(buildingPrevious !== buildingCurrent || index < 0) && (
              <h2 style={{ padding: 5, marginTop: 10 }} className="print-only">
                {buildingCurrent ? buildingCurrent : 'Ikke tilordnet'}
              </h2>
            )}
            {buildingCurrent && (
              <UncontrolledColumnHeaders
                columns={fireListColumns}
                items={fireList}
                className="print-only-flex column-print"
              />
            )}
          </>
        )}
        <ListItem columns={fireListColumns} item={item} />
      </Fragment>
    );
  };

  const renderNewPage = () => <div className="new-page" />;

  const returnItem = () => (
    <div id="fire-list-container" className="printable">
      <div className="fire-list">
        <List
          items={fireList}
          columns={fireListColumns}
          renderItem={renderItem}
          noItemsMessage={'Ingen personer for søket'}
        />
      </div>
    </div>
  );
  return returnItem();
};

export default FireList;
