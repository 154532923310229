import {
  addFileServiceOrder,
  clearServiceOrder,
  closeServiceOrderSentConfirmation,
  removeFileServiceOrder,
  sendServiceOrder,
  toggleConsentSubcontractorServiceOrder,
  toggleHasPetServiceOrder,
  toggleIsUnclassifiedServiceOrder,
  updateFileDescriptionServiceOrder,
  updateServiceOrder,
  updateServiceOrderAllErrors,
  updateServiceOrderOneError,
} from 'actions/serviceOrdersActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import DateInput from 'components/DateInput/DateInput';
import BasenDropzone from 'components/Dropzone/Dropzone';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import HasPetConfirmation from 'components/form/HasPetConfirmation/HasPetConfirmation';
import Input from 'components/form/Input/Input';
import Select from 'components/form/Select/Select';
import SubcontractorConfirmation from 'components/form/SubcontractorConfirmation/SubcontractorConfirmation';
import TextArea from 'components/form/TextArea/TextArea';
import UnclassifiedConfirmation from 'components/form/UnclassifiedConfirmation/UnclassifiedConfirmation';
import Loader from 'components/Loader/Loader';
import ConfirmationModal from 'components/modals/ConfirmationModal/ConfirmationModal';
import RouterModal from 'components/modals/RouterModal/RouterModal';
import connect from 'helpers/connectWithRouter';
import {
  MAX_SERVICE_ORDERS_TITLE_LENGTH,
  trimTitle,
} from 'helpers/serviceOrderHelpers';
import useAxios from 'hooks/useAxios';
import moment, { Moment } from 'moment';
import { FC, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';
import * as yup from 'yup';

import type { SendServiceOrderType } from 'types/serviceOrderTypes';
import type { BasenFileType, KeyValueType } from 'types/commonTypes';
type ServiceOrderFieldType = {
  disabled?: boolean;
  label?: string;
  value?: string | Moment | boolean;
  required?: boolean;
};

type ServiceOrderSelectorType = {
  value?: string;
  label: string;
  options: Array<KeyValueType>;
};

type ServiceOrderFieldsType = {
  title?: ServiceOrderFieldType;
  building?: ServiceOrderFieldType;
  rentalId?: ServiceOrderFieldType;
  description?: ServiceOrderFieldType;
  deliveryDate?: ServiceOrderFieldType;
  resignationDate?: ServiceOrderFieldType;
  toDate?: ServiceOrderFieldType;
  fromDate?: ServiceOrderFieldType;
  files?: ServiceOrderFieldType;
  selector?: ServiceOrderSelectorType;
  hasPet?: ServiceOrderFieldType;
  housingArea?: ServiceOrderFieldType;
  isShortRenewal?: ServiceOrderFieldType;
  keyCmObj?: ServiceOrderFieldType;
  regionNumber?: ServiceOrderFieldType;
  isCancellation?: ServiceOrderFieldType;
};

type PropsType = {
  title: string;
  ariaLabel: string;
  fields: ServiceOrderFieldsType;
  path: string;
  serviceOrder: SendServiceOrderType;
  files: Array<BasenFileType>;
  errors?: object;
  navigate: NavigateFunction;
  location: Location;
  clearServiceOrder: () => void;
  sendServiceOrder: () => void;
  updateServiceOrder: (key: string, value: string | Moment | number) => void;
  updateServiceOrderAllErrors: (errors: object) => void;
  updateServiceOrderOneError: (name: string) => void;
  addFileServiceOrder: (file: object) => void;
  removeFileServiceOrder: (file: object) => void;
  updateFileDescriptionServiceOrder: (
    fileName: string,
    description: string,
  ) => void;
  isUnclassified: boolean;
  consentSubcontractor: boolean;
  hasPet: boolean;
  isLoading: boolean;
  serviceOrderSent: boolean;
  closeServiceOrderSentConfirmation: () => void;
  toggleIsUnclassifiedServiceOrder: () => void;
  toggleConsentSubcontractorServiceOrder: () => void;
  toggleHasPetServiceOrder: () => void;
  onClose?: () => void;
  loading?: boolean;
  url?: string;
  preSendServiceOrder?: (serviceOrder?: SendServiceOrderType) => void;
};

const UnconnectedSendServiceOrderModal: FC<PropsType> = (props) => {
  const { requestLoading, sendRequest } = useAxios();

  const [serviceOrderSent, setServiceOrderSent] = useState(false);

  const setFieldValues = () =>
    Object.keys(props.fields).forEach((key) => {
      const field = props.fields[key];

      if (field && field.value && key === 'title') {
        props.updateServiceOrder(key, trimTitle(field.value));
      } else if (field && field.value !== null) {
        props.updateServiceOrder(key, field.value);
      }
    });

  const handleCloseModal = () => {
    props.clearServiceOrder();
    setServiceOrderSent(false);

    if (props.onClose) {
      props.onClose();
    } else {
      props.navigate(-1);
    }
  };

  const submitServiceOrder = async () => {
    const so = props.serviceOrder;
    if (props.preSendServiceOrder) props.preSendServiceOrder(so);

    await sendRequest(
      {
        method: 'POST',
        url: props.url,
        withCredentials: true,
        data: so,
      },
      (response) => {
        setServiceOrderSent(true);
      },
    );
  };

  const validationContext = {
    descriptionRequired:
      props.fields.description && props.fields.description.required,
    selectorRequired: props.fields.selector !== undefined,
  };

  const validationSchema = yup
    .object({
      title: yup.string().required('Title is required'),
      description: yup.string().when('$descriptionRequired', {
        is: true,
        then: (schema) => schema.required('required'),
      }),
      selector: yup.string().when('$selectorRequired', {
        is: true,
        then: (schema) => schema.required('required'),
      }),
      isUnclassified: yup.bool().oneOf([true]).required('required'),
    })
    .defined();

  const formProps = {
    errors: props.errors,
    form: {
      ...props.serviceOrder,
      isUnclassified: props.isUnclassified,
      consentSubcontractor: props.consentSubcontractor,
      hasPet: props.hasPet,
    },
    onSubmit: props.url ? submitServiceOrder : props.sendServiceOrder,
    onChange: props.updateServiceOrder,
    updateAllValidationErrors: props.updateServiceOrderAllErrors,
    updateOneValidationError: props.updateServiceOrderOneError,
    schema: validationSchema,
    schemaContex: validationContext,
  };

  return (
    <div>
      <ConfirmationModal
        isOpen={serviceOrderSent}
        onClose={handleCloseModal}
        title="Takk!"
        infoText="Din henvendelse har blitt sendt."
      />
      {!serviceOrderSent && (
        <RouterModal
          {...props}
          size="medium"
          unclassifiedStamp
          overrideButtonRow
          onOpen={setFieldValues}
        >
          <Loader isLoading={props.isLoading || requestLoading}>
            <FormWrapper {...formProps}>
              <div className="content-group padded grey margin">
                {props.fields.title && (
                  <div className="row margin">
                    <Input
                      name="title"
                      value={props.serviceOrder.title || ''}
                      title={
                        props.fields.title.label
                          ? props.fields.title.label
                          : 'Overskrift'
                      }
                      type="text"
                      disabled={props.fields.title.disabled}
                    />
                  </div>
                )}

                {props.fields.building && (
                  <div className="row margin">
                    <Input
                      name="building"
                      value={props.serviceOrder.building || ''}
                      title={
                        props.fields.building.label
                          ? props.fields.building.label
                          : 'Inventarnr'
                      }
                      type="number"
                      disabled={props.fields.building.disabled}
                    />
                  </div>
                )}

                {props.fields.rentalId && (
                  <div className="row margin">
                    <Input
                      name="rentalId"
                      value={props.serviceOrder.rentalId || ''}
                      title={
                        props.fields.rentalId.label
                          ? props.fields.rentalId.label
                          : 'Leie-ID'
                      }
                      type="text"
                      disabled={props.fields.rentalId.disabled}
                    />
                  </div>
                )}

                {props.fields.selector && (
                  <div className="row margin">
                    <Select
                      defaultChoice={props.fields.selector.label}
                      name="selector"
                      label={props.fields.selector.label}
                      options={props.fields.selector.options}
                      value={props.serviceOrder.selector}
                      onChange={props.updateServiceOrder}
                    />
                  </div>
                )}

                {props.fields.description && (
                  <div className="row margin">
                    <TextArea
                      name="description"
                      value={props.serviceOrder.description || ''}
                      title={
                        props.fields.description.label
                          ? props.fields.description.label
                          : 'Beskrivelse'
                      }
                      disabled={props.fields.description.disabled}
                    />
                  </div>
                )}

                {props.fields.deliveryDate && (
                  <div className="row margin">
                    <DateInput
                      name="deliveryDate"
                      title={
                        props.fields.deliveryDate.label
                          ? props.fields.deliveryDate.label
                          : 'Ønsket leveringsdato'
                      }
                      selectedDate={props.serviceOrder.deliveryDate}
                      minDate={moment()}
                      {...props.fields.deliveryDate}
                    />
                  </div>
                )}

                {props.fields.resignationDate && (
                  <div className="row margin">
                    <DateInput
                      name="resignationDate"
                      title={
                        props.fields.resignationDate.label
                          ? props.fields.resignationDate.label
                          : 'Vi ønsker å si opp leieforholdet fra'
                      }
                      selectedDate={props.serviceOrder.resignationDate}
                      minDate={moment()}
                      {...props.fields.resignationDate}
                    />
                  </div>
                )}

                {props.fields.fromDate && (
                  <div className="row margin">
                    <DateInput
                      name="fromDate"
                      title={
                        props.fields.fromDate.label
                          ? props.fields.fromDate.label
                          : 'Fra dato'
                      }
                      selectedDate={props.serviceOrder.fromDate}
                      minDate={moment()}
                      {...props.fields.fromDate}
                    />
                  </div>
                )}

                {props.fields.toDate && (
                  <div className="row margin">
                    <DateInput
                      title={
                        props.fields.toDate.label
                          ? props.fields.toDate.label
                          : 'Til dato'
                      }
                      selectedDate={props.serviceOrder.toDate}
                      minDate={moment()}
                      name="toDate"
                      {...props.fields.toDate}
                    />
                  </div>
                )}

                {props.fields.files && (
                  <div className="row">
                    <BasenDropzone
                      title={'Vedlegg'}
                      files={props.files}
                      addFile={props.addFileServiceOrder}
                      removeFile={props.removeFileServiceOrder}
                      updateFileDescription={
                        props.updateFileDescriptionServiceOrder
                      }
                    />
                  </div>
                )}
              </div>

              <div className="margin">
                <UnclassifiedConfirmation
                  checked={props.isUnclassified}
                  onChange={props.toggleIsUnclassifiedServiceOrder}
                />
                {props.fields.hasPet && (
                  <div>
                    <SubcontractorConfirmation
                      checked={props.consentSubcontractor}
                      onChange={props.toggleConsentSubcontractorServiceOrder}
                    />
                    <HasPetConfirmation
                      checked={props.hasPet}
                      onChange={props.toggleHasPetServiceOrder}
                    />
                  </div>
                )}
              </div>

              <ButtonRow>
                <ButtonRow.Right color="grey" onClick={handleCloseModal}>
                  Avbryt
                </ButtonRow.Right>
                <ButtonRow.Right
                  color={props.isLoading ? 'grey' : 'red'}
                  disabled={props.isLoading}
                  type="submit"
                >
                  {props.isLoading ? 'Sender...' : 'Send'}
                </ButtonRow.Right>
              </ButtonRow>
            </FormWrapper>
          </Loader>
        </RouterModal>
      )}
    </div>
  );
};

export default connect(
  (state: DeliberateAny) => ({
    isLoading: state.serviceOrders.isLoading,
    serviceOrderSent: state.serviceOrders.serviceOrderSent,
    serviceOrder: state.serviceOrders.serviceOrder,
    files: state.serviceOrders.files,
    errors: state.serviceOrders.errors,
    isUnclassified: state.serviceOrders.isUnclassified,
    consentSubcontractor: state.serviceOrders.consentSubcontractor,
    hasPet: state.serviceOrders.hasPet,
  }),
  {
    sendServiceOrder,
    updateServiceOrder,
    updateServiceOrderAllErrors,
    updateServiceOrderOneError,
    addFileServiceOrder,
    removeFileServiceOrder,
    updateFileDescriptionServiceOrder,
    clearServiceOrder,
    closeServiceOrderSentConfirmation,
    toggleIsUnclassifiedServiceOrder,
    toggleConsentSubcontractorServiceOrder,
    toggleHasPetServiceOrder,
  },
)(UnconnectedSendServiceOrderModal);
