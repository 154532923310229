import SafeHtmlRenderer from 'components/SafeHtmlRenderer';
import DOMPurify from 'dompurify';
import { isHtml } from 'helpers/html';
import objectHash from 'object-hash';
import { Component, createRef, Fragment, ReactNode } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { DeliberateAny } from 'types/DelibrateAny';

import BoxClose from '../../buttons/BoxClose/BoxClose';
import ButtonRow from '../../buttons/ButtonRow/ButtonRow';
import UnclassifiedStamp from '../../form/UnclassifiedStamp/UnclassifiedStamp';

export type ModalSize = 'small' | 'medium' | 'large' | 'max' | 'default';

export type ModalButtonType = {
  hide?: boolean;
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  color?: string;
  ignoreOnClose?: boolean;
};

type ModalSubmitButtonType = ModalButtonType & {
  closeModalAfterSubmitSuccess?: boolean;
};

export type ModalPropsType = {
  title?: string;
  description?: string;
  ariaLabel?: string;
  unclassifiedStamp?: boolean;
  children?: DeliberateAny;
  size?: ModalSize;
  submit?: ModalSubmitButtonType;
  cancel?: ModalButtonType;
  overrideButtonRow?: boolean;
  onOpen?: () => void;
  scrolling?: boolean;
  flipActionOrder?: boolean;
  dataTestIdPrefix?: string;
  isLoading?: boolean;
};

type PropsType = ModalPropsType & {
  isOpen?: boolean;
  onClose?: () => void;
};

class Modal extends Component {
  props: PropsType;
  contentElement!: HTMLElement;
  nodeRef: React.RefObject<HTMLDivElement>;

  constructor(props: PropsType) {
    super(props);
    this.props = props;
    this.nodeRef = createRef();
  }

  componentDidUpdate(prevProps: PropsType) {
    if (this.contentElement && !prevProps.isOpen && this.props.isOpen) {
      this.contentElement.focus();

      if (this.props.onOpen) {
        this.props.onOpen();
      }
    }
  }

  onKeyDown = (event: DeliberateAny) => {
    if (event.key === 'Escape' && this.props.onClose) {
      this.props.onClose();
    }
  };

  handleOnSubmit = () => {
    let submitSuccess: DeliberateAny = null;

    if (this.props.submit && this.props.submit.onClick) {
      submitSuccess = this.props.submit.onClick();
    }

    if (
      this.props.onClose &&
      ((submitSuccess &&
        this.props.submit &&
        this.props.submit.closeModalAfterSubmitSuccess) ||
        !(this.props.submit && this.props.submit.ignoreOnClose))
    ) {
      this.props.onClose();
    }
  };

  handleOnCancel = () => {
    if (this.props.cancel && this.props.cancel.onClick) {
      this.props.cancel.onClick();
    } else if (this.props.onClose) {
      this.props.onClose();
    }
  };

  renderCancelButton = () => {
    return (
      !(this.props.cancel && this.props.cancel.hide) && (
        <ButtonRow.Right
          onClick={this.handleOnCancel}
          color={
            this.props.cancel && this.props.cancel.color
              ? this.props.cancel.color
              : 'grey'
          }
        >
          {this.props.cancel && this.props.cancel.text
            ? this.props.cancel.text
            : 'Avbryt'}
        </ButtonRow.Right>
      )
    );
  };

  renderSubmitButton = () =>
    !(this.props.submit && this.props.submit.hide) && (
      <ButtonRow.Right
        onClick={this.handleOnSubmit}
        color={
          this.props.submit && this.props.submit.color
            ? this.props.submit.color
            : 'red'
        }
        disabled={this.props.submit && this.props.submit.disabled}
        data-testid={`${this.props.dataTestIdPrefix}-button-submit`}
      >
        {this.props.submit && this.props.submit.text
          ? this.props.submit.text
          : 'Ok'}
      </ButtonRow.Right>
    );

  generateHash = (props) => {
    const { children, ...serializableProps } = props;
    return objectHash(serializableProps);
  };

  renderChild = (child) => {
    if (!child) return;

    const key =
      child.key || child.props ? this.generateHash(child.props) : child;
    try {
      if (child.type === 'p' && child.props.children?.length)
        return <SafeHtmlRenderer key={key} response={child.props.children} />;
      else return <Fragment key={key}>{child}</Fragment>;
    } catch (error) {
      return <Fragment key={key}>{child}</Fragment>;
    }
  };

  render() {
    return (
      <TransitionGroup>
        {this.props.isOpen && (
          <CSSTransition
            classNames={'modal'}
            timeout={400}
            nodeRef={this.nodeRef}
          >
            <div className="modal-container">
              <div
                ref={this.nodeRef}
                className={`modal modal-${
                  this.props.size ? this.props.size : 'default'
                }`}
                aria-label={this.props.ariaLabel ?? this.props.title}
              >
                <div className="overlay" />
                <div
                  className={
                    this.props.scrolling ? 'content scrollable' : 'content'
                  }
                  onKeyDown={this.onKeyDown}
                  ref={(element) => {
                    if (element as HTMLDivElement)
                      this.contentElement = element as HTMLDivElement;
                  }}
                  tabIndex={-1}
                >
                  {this.props.unclassifiedStamp && <UnclassifiedStamp />}
                  <header>
                    {this.props.title && <h2>{this.props.title}</h2>}
                    {this.props.description && (
                      <div>
                        {!isHtml(this.props.description ?? '') && (
                          <p>{this.props.description}</p>
                        )}
                        {isHtml(this.props.description ?? '') && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                this.props.description,
                              ),
                            }}
                          />
                        )}
                      </div>
                    )}
                  </header>

                  {this.props.children && Array.isArray(this.props.children)
                    ? this.props.children.map((x) => {
                        return this.renderChild(x);
                      })
                    : this.renderChild(this.props.children)}

                  {!this.props.overrideButtonRow && !this.props.isLoading && (
                    <div>
                      {!this.props.submit && !this.props.cancel ? (
                        <ButtonRow>
                          <ButtonRow.Right
                            onClick={this.handleOnCancel}
                            color="red"
                          >
                            Lukk
                          </ButtonRow.Right>
                        </ButtonRow>
                      ) : this.props.flipActionOrder ? (
                        <ButtonRow>
                          {this.renderSubmitButton()}
                          {this.renderCancelButton()}
                        </ButtonRow>
                      ) : (
                        <ButtonRow>
                          {this.renderCancelButton()}
                          {this.renderSubmitButton()}
                        </ButtonRow>
                      )}

                      {this.props.onClose && (
                        <BoxClose
                          onClick={this.props.onClose}
                          title="Lukk popup"
                          topRight
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  }
}

export default Modal;
