import './DownloadIcon.scss';

import {
    JSXElementConstructor, MouseEvent, ReactElement, ReactNode, ReactPortal, useState
} from 'react';

import { useModal } from '../../../contexts';
import { axiosFetch } from '../../../hooks/useAxios';
import { DeliberateAny } from '../../../types/DelibrateAny';
import DownloadIcon from './DownloadIcon';

const DownloadButton = (props: {
  disabled?: boolean;
  href: DeliberateAny;
  className?: string | undefined;
  color?: DeliberateAny;
  children:
    | string
    | number
    | boolean
    | ReactElement<DeliberateAny, string | JSXElementConstructor<DeliberateAny>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
}) => {
  const { showModal } = useModal();
  const [downloading, setDownloading] = useState(false);
  const handleClick = (event: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.disabled) {
      event.preventDefault();
    } else {
      setDownloading(true);
      axiosFetch(
        { url: props.href, responseType: "blob", withCredentials: true },
        null,
        false
      )
        .then((response) => {
          const fileName = response.headers["content-disposition"]
            ? response.headers["content-disposition"]
                .split("filename=")[1]
                .split(";")[0]
            : "filename_local";
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", fileName.replace(/"/g, ""));
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch(() =>
          showModal({
            title: "Beklager",
            infoText: "Det skjedde en feil",
          })
        )
        .finally(() => setDownloading(false));
    }
  };

  return (
    <button
      onClick={(event: DeliberateAny) => handleClick(event)}
      className={
        props.className
          ? props.className
          : ` \
          btn \
          ${props.color ? props.color : "grey"} \
          ${props.disabled ? "disabled" : ""} \
        `
      }
      tabIndex={props.disabled ? -1 : undefined}
      style={{ display: "flex", alignItems: "center" }}
    >
      <span>{props.children}</span>
      <div className="dl-icon">
        <DownloadIcon animate={downloading} />
      </div>
    </button>
  );
};

export default DownloadButton;
