import "./PortfolioPlansList.scss";

import { openPortfolioPlanModal } from "actions/myPortfolioActions";
import List from "components/List/List";
import ListItem from "components/ListItem/ListItem";
import Loader from "components/Loader/Loader";
import connect from "helpers/connectWithRouter";
import React, { Component } from "react";

import type { PlanItemType } from "types/planTypes";

type PropsType = {
  items: Array<PlanItemType>;
  openPortfolioPlanModal: (selectedPlanId: string) => void;
  isLoading: boolean;
};

const columns = [
  { label: "Prosjektnavn", name: "Heading", className: "heading" },
  { label: "Status", name: "State", className: "status" },
  { label: "Type", name: "Type", className: "type" },
];

class UnconnectedPortfolioPlansList extends Component {
  declare props: PropsType;

  renderItem = (item: PlanItemType) => (
    <ListItem
      key={item.Id}
      item={item}
      columns={columns}
      customDetailsButton={{
        onClick: () => this.props.openPortfolioPlanModal(item.Id),
        icon: "pop-up",
        elementType: "a",
      }}
    />
  );

  render() {
    return (
      <Loader isLoading={this.props.isLoading}>
        <div className="portfolio-plans-list">
          <List
            columns={columns}
            renderItem={this.renderItem}
            items={this.props.items}
            noItemsMessage={<p>Fant ingen planer</p>}
          />
        </div>
      </Loader>
    );
  }
}

export default connect(null, {
  openPortfolioPlanModal,
})(UnconnectedPortfolioPlansList);
