// @flow

import React, { Component } from 'react';
import connect from 'helpers/connectWithRouter';
import List from 'components/List/List';
import { resetMyRentalsExpandMe } from 'actions/myRentalsActions';
import type { RentalItemType } from 'types/myRentalsTypes';
import rentalColumns from './RentalColumns';
import './RentalColumns.scss';
import RentalItem from './RentalItem/RentalItem';

type PropsType = {
  items: Array<RentalItemType>;
  resetMyRentalsExpandMe: () => void;
};

export class UnconnectedRentalsList extends Component {
  declare props: PropsType;

  componentDidMount() {
    this.props.resetMyRentalsExpandMe();
  }

  renderItem = (item: RentalItemType) => {
    return (
      <RentalItem
        key={item.ContractNumber}
        item={item}
        columns={rentalColumns}
        mountAsExpanded={this.props.items.length === 1}
      />
    );
  };

  render() {
    return (
      <div className="rental-columns">
        <List
          columns={rentalColumns}
          renderItem={this.renderItem}
          noItemsMessage={<p>Ingen leieforhold registrert</p>}
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(null, {
  resetMyRentalsExpandMe,
})(UnconnectedRentalsList);
