// @flow
import type { SignatureJobXpandRequestType } from "../types/signatureTypes";

function createSignatureJobForXpandDocument(
  jobRequest: SignatureJobXpandRequestType
) {
  return { type: "CREATE_SIGNATURE_JOB_XPAND_DOCUMENT", jobRequest };
}

function uploadSignedRentalContractDocument(
  rentalId: string,
  documentId: string,
  statusQueryToken: string
) {
  return {
    type: "UPLOAD_SIGNED_RENTAL_CONTRACT_DOCUMENT",
    rentalId,
    documentId,
    statusQueryToken,
  };
}

function uploadSignedAdditionalServicesDocument(
  documentId: string,
  revision: string,
  statusQueryToken: string
) {
  return {
    type: "UPLOAD_SIGNED_ADDITIONAL_SERVICES_DOCUMENT",
    documentId,
    revision,
    statusQueryToken,
  };
}

export {
  createSignatureJobForXpandDocument,
  uploadSignedRentalContractDocument,
  uploadSignedAdditionalServicesDocument,
};
