const generateFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((element) => {
    let verdi = '';
    if (typeof data[element] === 'object') {
      verdi = JSON.stringify(data[element]);
    } else {
      verdi = data[element];
    }
    formData.append(element, verdi);
  });
  return formData;
};

/*
const generateFormData = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((element) => { formData.append(element, data[element]); });
  return formData;
};
*/

export default generateFormData;
