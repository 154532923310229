import HelpText from 'components/HelpText';
import { FC } from 'react';

import { IFormPropBase, ValueOption } from './FormPropBase';

interface ICheckboxGroupProps extends IFormPropBase {
  options: ValueOption[];
  initial?: ValueOption;
}

const CheckboxGroup: FC<ICheckboxGroupProps> = ({
  name,
  label,
  formHolder,
  updateForm,
  errors,
  helpInfo,
  options,
  initial,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const currentValues = formHolder[name] || [];

    if (currentValues.includes(value)) {
      updateForm(
        name,
        currentValues.filter((v) => v !== value),
      );
    } else {
      updateForm(name, [...currentValues, value]);
    }
  };

  return (
    <div className="fb-form-field">
      <HelpText content={helpInfo}>
        <label className={`fb-form-label ${errors[name] ? 'error' : ''}`}>
          {errors[name] ? errors[name] : label ? label : name}
        </label>
      </HelpText>

      <div>
        {options?.map((opt, i) => {
          return (
            <label className="checkbox" key={`${name}_${i}`}>
              <input
                disabled={opt.disabled}
                type="checkbox"
                checked={
                  formHolder[name]
                    ? formHolder[name]?.includes(opt.value)
                    : initial === opt
                }
                name={name}
                value={opt.value}
                onChange={handleChange}
              />
              <span className="form-option">{opt.label}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default CheckboxGroup;
