import Dropzone from 'components/Dropzone/Dropzone';
import HasPetConfirmation from 'components/form/HasPetConfirmation/HasPetConfirmation';
import Input from 'components/form/Input/Input';
import SubcontractorConfirmation from 'components/form/SubcontractorConfirmation/SubcontractorConfirmation';
import TextArea from 'components/form/TextArea/TextArea';
import UnclassifiedConfirmation from 'components/form/UnclassifiedConfirmation/UnclassifiedConfirmation';
import { FC } from 'react';
import Space from 'src/layout/Space';
import { DeliberateAny } from 'types/DelibrateAny';

interface InputFieldProps {
  id: string;
  label?: string;
  placeholder?: string;
  value: string;
  updateField: (name: string, value: string) => void;
  minLength?: number;
  maxLength?: number;
  errors?: DeliberateAny;
}

export const InputField: FC<InputFieldProps> = ({
  id,
  label,
  placeholder,
  value,
  updateField,
  maxLength,
  minLength,
  errors,
}) => (
  <div className="row margin" id={`error_${id}`}>
    <Input
      type="text"
      maxLength={maxLength}
      placeholder={placeholder}
      name={id}
      value={value}
      minLength={minLength}
      onChange={(name, val) => updateField(name, val)}
      title={label ?? ""}
      errors={errors}
    />
  </div>
);

interface TextAreaFieldProps {
  id: string;
  label: string;
  rows: number;
  value: string;
  updateField: (name: string, value: string) => void;

  errors?: DeliberateAny;
}

export const TextAreaField: FC<TextAreaFieldProps> = ({
  id,
  label,
  rows,
  value,
  updateField,
  errors,
}) => (
  <div className="row margin" id={`error_${id}`}>
    <TextArea
      name={id}
      rows={rows}
      value={value}
      onChange={(name, val) => updateField(name, val)}
      title={label}
      errors={errors}
    />
  </div>
);

interface File {
  file: {
    preview?: string;
    name: string;
  };
  title: string;
}

interface AttachmentSectionProps {
  files: File[];
  addAttachment: (file: any) => void;
  removeAttachment: (file: File) => void;
  updateAttachmentDescription: (fileName: string, fileTitle: string) => void;
}

export const AttachmentSection: FC<AttachmentSectionProps> = ({
  files,
  addAttachment,
  removeAttachment,
  updateAttachmentDescription,
}) => (
  <div className="row margin">
    <div className="content-group grey padded">
      Vedlegg
      <Dropzone
        files={files}
        addFile={addAttachment}
        removeFile={removeAttachment}
        updateFileDescription={updateAttachmentDescription}
        formKey="attachments"
        maxFiles={10}
        showSizeInKB
        deleteButtonStyle="nocolor"
      />
    </div>
    <Space height="1rem" />
  </div>
);

interface AdditionalConfirmationsProps {
  formHolder: {
    isUnclassified: boolean;
    ConsentSubcontractorAccess: boolean;
    hasPet: boolean;
  };
  updateFormHolder: (name: string, value: boolean) => void;
  errors: DeliberateAny;
}

export const AdditionalConfirmations: FC<AdditionalConfirmationsProps> = ({
  formHolder,
  updateFormHolder,
  errors,
}) => (
  <>
    <div className="row margin">
      <UnclassifiedConfirmation
        errors={errors}
        checked={formHolder.isUnclassified}
        onChange={() =>
          updateFormHolder(
            "isUnclassified",
            !(formHolder.isUnclassified ?? false)
          )
        }
      />
    </div>
    <div className="row margin">
      <SubcontractorConfirmation
        checked={formHolder.ConsentSubcontractorAccess}
        onChange={() =>
          updateFormHolder(
            "ConsentSubcontractorAccess",
            !formHolder.ConsentSubcontractorAccess
          )
        }
      />
    </div>
    <div className="row margin">
      <HasPetConfirmation
        checked={formHolder.hasPet}
        onChange={() => updateFormHolder("hasPet", !formHolder.hasPet)}
      />
    </div>
  </>
);
