import { useModal, useUser } from "contexts/index";
import moment from "moment";
import { useEffect, useState } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import { SOonEmailURL } from "../../api/basenproff/basenproff_api";
import useAxios, { axiosFetch } from "../../hooks/useAxios";
import { ModalTypes } from "constants/modalTypes";

const ExportList = (items) => {
  const { user } = useUser();
  const { sendRequest } = useAxios();
  const { showModal, setModal } = useModal();
  const [fields, setFields] = useState([
    {
      name: "to",
      type: "select",
      label: "Til",
      options: ["Error! Lukk vinduet og forsøk på nytt."],
    },
    {
      name: "subject",
      type: "textfield",
      label: "Emne",
      disabled: true,
      initialValue: `Liste over henvendelser ${moment().format("DD.MM.YY")}`,
    },
  ]);

  useEffect(() => {
    const getUserData = (data) => {
      setFields([
        {
          name: "to",
          type: "select",
          label: "Til",
          options: [data.Email, data.SecondaryEmail],
        },
        {
          name: "subject",
          type: "textfield",
          label: "Emne",
          disabled: true,
          initialValue: `Liste over henvendelser ${moment().format(
            "DD.MM.YY"
          )}`,
        },
      ]);
    };
    getUserData(user);
  }, [user]);

  const getItemsForExport = () => {
    const exportItems: DeliberateAny = [];
    const arrayCopy = JSON.parse(JSON.stringify(items));
    arrayCopy.items.forEach((item) => {
      const miniItem = item;
      delete miniItem.Documents;
      exportItems.push(miniItem);
      if (miniItem.Related) {
        miniItem.Related.reverse().forEach((related) => {
          const newRelated = related;
          delete newRelated.Documents;
          exportItems.push(newRelated);
        });
      }
    });
    return exportItems;
  };

  const sendSOonEmail = async (data) => {
    await sendRequest({
      method: "POST",
      url: SOonEmailURL,
      withCredentials: true,
      data,
    });
  };

  const excelExport = (subject: string, email: string) => {
    const data = {
      serviceOrders: getItemsForExport(),
      dif: "",
      bra: "",
      subject: subject,
      to: email,
    };

    sendSOonEmail(data);
  };

  const clickExport = () => {
    showModal({
      modalType: ModalTypes.ExportList,
      title: "Få listen på e-post",
      fields: fields,
      submitAction: (subject: string, email: string) => {
        excelExport(subject, email);
      },
    });
  };

  return <a onClick={clickExport}>Få listen på e-post (Excel)</a>;
};

export default ExportList;
