// @flow

import {
  fetchGuestsNewCheckinStatus,
  fetchGuestsNewKey,
  toggleGuestModal,
  updateGuestPhone,
  updateGuestsFilter,
} from "actions/guardPageActions";
import ButtonRow from "components/buttons/ButtonRow/ButtonRow";
import Input from "components/form/Input/Input";
import ListItem from "components/ListItem/ListItem";
import Loader from "components/Loader/Loader";
import Modal from "components/modals/Modal/Modal";
import DOMPurify from "dompurify";
import connect from "helpers/connectWithRouter";
import React, { Component } from "react";
import { DeliberateAny } from "types/DelibrateAny";

import type { GuestItemPropsType } from "types/guardPageTypes";

const guestDetails = [
  { label: "Gjest", name: "GuestName" },
  { label: "Telefonnummer", name: "GuestPhone" },
  { label: "Fra dato", name: "FromDate" },
  { label: "Til dato", name: "ToDate" },
];

const getDetails = () => [...guestDetails];

export class UnconnectedGuestItem extends Component {
  declare props: GuestItemPropsType;

  state: {
    showGuestCheckInModal: boolean;
    showNewCheckinKeyModal: boolean;
    optimisticCheckinState: boolean;
  };

  constructor(props: GuestItemPropsType) {
    super(props);

    this.state = {
      showGuestCheckInModal: false,
      showNewCheckinKeyModal: false,
      optimisticCheckinState: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.ModalGuestBeenCheckedInn !==
      prevProps.ModalGuestBeenCheckedInn &&
      this.props.ModalGuestBeenCheckedInn &&
      !this.state.optimisticCheckinState
    ) {
       
      this.setState({ optimisticCheckinState: true });
    }
  }

  onOpenNewKeyModal = () => {
    this.toggleState("showNewCheckinKeyModal");
    // setter store-variable requestNewKey til verdien av telefonnummer til aktiv liste element
    this.props.updateGuestsFilter(
      "requestNewKey",
      this.props.item?.Phone || ""
    );
  };

  showCheckInBtn = () => this.props.item.ShowCheckInButton;
  showNewKeyButton = () => this.props.item.ShowNewKeyButton;

  handleRedirect = (path: string) => {
    this.props.navigate(path);
  };

  guestItemPath = () =>
    `${this.props.location.pathname}/${this.props.item.BookingId}`;

  extendedApplicationChoiceModal = () =>
    `${this.guestItemPath()}/sjekk-inn-gjest`;

  changeExtendedApplicationType = (
    name: string,
    selectValue: string | boolean | number
  ) => {
    this.setState({ extendedApplicationLink: selectValue.toString() });
  };

  toggleState = (stateProperty: string) => {
    this.setState({ [stateProperty]: !this.state[stateProperty] });
  };

  checkInButton = () => (
    <ButtonRow.Right
      color="red"
      onClick={() => this.toggleState("showGuestCheckInModal")}
    >
      Innsjekk
    </ButtonRow.Right>
  );

  newCheckinKey = () => (
    <ButtonRow.Right onClick={() => this.onOpenNewKeyModal()}>
      Send nøkkel
    </ButtonRow.Right>
  );

  updateGuestStatusToCheckin = () => {
    this.toggleState("showGuestCheckInModal");
    this.props.fetchGuestsNewCheckinStatus(
      this.props.item,
      this.props.guestsFilter
    );
  };

  sendNewCheckinKey = () => {
    this.toggleState("showNewCheckinKeyModal");
    this.props.fetchGuestsNewKey(this.props.item, this.props.requestNewKey);
  };

  renderExtraDescriptions = (descriptionList) =>
    descriptionList.map((desc, i) => (
      <p key={i.toString()}>
        <b>{`${desc.Key} `}</b>
        <span>{desc.Value}</span>
      </p>
    ));

  renderExtendedDetails = () => (
    <React.Fragment>
      {this.props.item.ExtraDescriptions &&
        this.renderExtraDescriptions(this.props.item.ExtraDescriptions)}
      <ButtonRow>
        {this.showCheckInBtn() && this.checkInButton()};
        {this.showNewKeyButton() && this.newCheckinKey()};
      </ButtonRow>
    </React.Fragment>
  );

  renderCheckInButton = () => (
    <ButtonRow>
      <ButtonRow.Right color="red" onClick={this.updateGuestStatusToCheckin}>
        Sjekk inn gjest
      </ButtonRow.Right>
    </ButtonRow>
  );

  renderNewCheckinKeyButton = () => (
    <ButtonRow>
      <ButtonRow.Right color="red" onClick={this.sendNewCheckinKey}>
        Send nøkkel
      </ButtonRow.Right>
    </ButtonRow>
  );

  render() {
    return (
      <Loader isLoading={this.props.isLoading}>
        <div>
          <React.Fragment>

            <ListItem
              item={{
                ...this.props.item,
                CheckInStatus: this.state.optimisticCheckinState
                  ? "Ankommet"
                  : this.props.item.CheckInStatus,
              }}
              columns={this.props.columns}
              details={getDetails()}
              extendedDetailsContainer={this.renderExtendedDetails()}
              mountAsExpanded={
                this.props.item.ExpandMe || this.props.mountAsExpanded
              }
              minimalDetails
              passStylingToList={{ styleInHalf: true }}
            />
            <Modal
              isOpen={this.state.showGuestCheckInModal}
              onClose={() => this.toggleState("showGuestCheckInModal")}
              ariaLabel="Innsjekk-prosess"
              size="medium"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    this.props.guestsRules?.CheckInRules || ""
                  ),
                }}
              />

              <div className="flex-row margin">{this.renderCheckInButton()}</div>
            </Modal>

            <Modal
              isOpen={this.state.showNewCheckinKeyModal}
              onClose={() => this.toggleState("showNewCheckinKeyModal")}
              ariaLabel="Innsjekk-prosess"
              size="medium"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    this.props.guestsRules?.RenewKeyRules || ""
                  ),
                }}
              />

              <div className="flex-row margin">
                <Input
                  id="selectNewKey"
                  name="selectNewKey"
                  type="text"
                  value={this.props.requestNewKey}
                  onChange={this.props.updateGuestPhone}
                />
                {this.renderNewCheckinKeyButton()}
              </div>
            </Modal>
            <Modal
              isOpen={this.props.ModalGuestBeenCheckedInn}
              onClose={() => {
                this.props.toggleGuestModal();
              }}
              ariaLabel="Gjest godkjent innsjekking"
              size="medium"
            >
              <h2> Gjest er nå sjekket inn! </h2>
            </Modal>
          </React.Fragment>
        </div>
      </Loader>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    requestNewKey: state.guardPage.search.filter.requestNewKey,
    isLoading: state.guardPage.search.isLoading,
    guestsRules: state.guardPage.search.guestsInfoText,
    guestsFilter: state.guardPage.search.filter,
    ModalGuestBeenCheckedInn: state.guardPage.search.ModalGuestBeenCheckedInn,
    ModalMessage: state.guardPage.search.ModalMessage,
  }),
  {
    updateGuestPhone,
    fetchGuestsNewCheckinStatus,
    toggleGuestModal,
    fetchGuestsNewKey,
    updateGuestsFilter,
  }
)(UnconnectedGuestItem);
