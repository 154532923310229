// @flow

import { DeliberateAny } from "../types/DelibrateAny";
import type { InspectionType } from "../types/inspectionTypes";

export function fetchInspectionTypes(
  rentalobjectId: string,
  inspectionId: string
) {
  return { type: "FETCH_INSPECTION_TYPES", rentalobjectId, inspectionId };
}

export function sendInspectionForm(
  rentalobjectId: string,
  inspectionId: string,
  inspectionForm: DeliberateAny,
  isComplete: boolean
) {
  return {
    type: "SEND_INSPECTION_FORM",
    rentalobjectId,
    inspectionId,
    inspectionForm,
    isComplete,
  };
}

export function selectInspectionType(inspectionType: InspectionType) {
  return { type: "SELECT_INSPECTION_TYPE", inspectionType };
}

export function updateInspectionForm(key: string, value: string) {
  return { type: "UPDATE_INSPECTION_FORM", key, value };
}

export function updateInspectionRoom(roomQuestions: DeliberateAny) {
  return { type: "UPDATE_INSPECTION_ROOM", roomQuestions };
}

export function updateAllInspectionFormErrors(errors: DeliberateAny) {
  return { type: "UPDATE_INSPECTION_FORM_ALL_ERRORS", errors };
}

export function updateSingleInspectionFormError(
  name: string,
  error: DeliberateAny
) {
  return { type: "UPDATE_SINGLE_INSPECTION_FORM_ERROR", name, error };
}
