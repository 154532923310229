import "./ChildInput.scss";

import { Component } from "react";

import BoxClose from "../../components/buttons/BoxClose/BoxClose";
import Input from "../../components/form/Input/Input";
import { DeliberateAny } from "../../types/DelibrateAny";

type PropsType = {
  onChange: (name: string, value: string) => void;
  removeChild: (index: number) => void;
  index: number;
  errors: DeliberateAny;
  dateOfBirth: string;
  onBlur?: () => void;
};

class ChildInput extends Component {
  declare props: PropsType;

  removeChild = () => {
    this.props.removeChild(this.props.index);
  };

  renderChild = () => (
    <div className="child-input">
      {this.props.index > 0 && (
        <BoxClose
          title="Fjern barn"
          onClick={this.removeChild}
          topRight
          invertedColors
        />
      )}
      <div className="flex-row">
        <Input
          errors={this.props.errors}
          title="Fødselsdato (DD.MM.YYYY)"
          type="text"
          onChange={this.props.onChange}
          name={`children[${this.props.index}].dateOfBirth`}
          value={this.props.dateOfBirth}
          onBlur={this.props.onBlur}
        />
      </div>
    </div>
  );

  render() {
    return this.renderChild();
  }
}

export default ChildInput;
