import Slide from 'components/Slides/Slide';

import ExtraServicesList from '../ExtraServicesList';

const ExtraServicesListSlide = () => {
  return (
    <Slide
      path="extraservices"
      slideSize="large"
      title={'Velg type tilleggstjeneste'}
    >
      <ExtraServicesList />
    </Slide>
  );
};

export default ExtraServicesListSlide;
