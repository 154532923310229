// @flow

import moment from 'moment';

export const possiblyCastStringToNumber = (value: string): number | string => {
  const numberValue = parseFloat(value);
  if (value === numberValue.toString()) {
    return numberValue;
  }
  return value;
};

export const possiblyCastStringToBooleanOrNumber = (value: string): boolean | number | string => {
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  }
  return possiblyCastStringToNumber(value);
};

export const isServerDate = (value: any) => (
  typeof value === 'string' && moment(value, moment.ISO_8601).isValid()
);

export const formatDate = (value: any) => moment(value).format('DD.MM.YYYY');

export const stringIsEmpty = (str?: string) => (
  !str || str.length < 0
);

export const upperCaseFirstLetter = (string: string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const removeWhiteSpaces = (string: string) => string.replace(/\s/g, '');
