import { Component, ReactNode } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

type PropsType = {
  children?: ReactNode | Array<ReactNode>;
  isLoading?: boolean;
  noBackground?: boolean;
  onLoaderClick?: () => void;
};

class Loader extends Component {
  declare props: PropsType;
  declare checkCompleteIntervalId: DeliberateAny;
  declare startCheckCompleteTimeoutId: DeliberateAny;
  declare loadingCompleteTimeoutId: DeliberateAny;

  state: {
    loading?: boolean;
    loaded: boolean;
  };

  constructor(props: PropsType) {
    super(props);

    this.state = {
      loading: props.isLoading,
      loaded: false,
    };

    if (props.isLoading) {
      this.startCheckCompleteInterval();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    if (!this.state.loading && nextProps.isLoading) {
      this.setState({ loading: true });
      this.startCheckCompleteInterval();
    }
  }

  componentWillUnmount() {
    clearInterval(this.checkCompleteIntervalId);
    clearTimeout(this.startCheckCompleteTimeoutId);
    clearTimeout(this.loadingCompleteTimeoutId);
  }

  startCheckCompleteInterval() {
    clearTimeout(this.startCheckCompleteTimeoutId);
    this.startCheckCompleteTimeoutId = setTimeout(() => {
      if (!this.props.isLoading) {
        this.loadingComplete();
      } else {
        this.checkCompleteIntervalId = setInterval(() => {
          if (!this.props.isLoading) {
            this.loadingComplete();
            clearInterval(this.checkCompleteIntervalId);
          }
        }, 100);
      }
    }, 100);
  }

  loadingComplete() {
    this.setState({ loaded: true });
    clearTimeout(this.loadingCompleteTimeoutId);
    this.setState({ loading: false, loaded: false });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <div
            style={{
              position: 'relative',
              height: this.props.noBackground ? 0 : 300,
            }}
          >
            <div className={`loading ${this.state.loaded ? 'loaded' : ''}`}>
              <div className="loader">
                <div
                  className={`loader-wrapper ${
                    this.props.onLoaderClick ? 'pointer' : ''
                  }`}
                  onClick={this.props.onLoaderClick}
                >
                  <div className="cube-wrapper">
                    <div className="thecube">
                      <div className="cube c1" />
                      <div className="cube c2" />
                      <div className="cube c4" />
                      <div className="cube c3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>{this.props.children}</>
        )}
      </>
    );
  }
}

export default Loader;
