import './BookingContactInfo.scss';

import CheckboxGroup from 'components/FormComponents/CheckboxGroup';
import HelpText from 'components/HelpText';
import Modal, { ModalSize } from 'components/ModalComponents/Modal';
import { IGuestList } from 'contexts/BookingContextNew';
import { useNewBooking } from 'contexts/index';
import { FC, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

enum GuestListOption {
  GuestListPostponed = 'guestListPostponed',
  UnnamedGuests = 'unnamedGuests',
  CivilGuests = 'civilGuests',
}

const BookingGuestListInfo: FC = () => {
  const { bookingOptions, errors, bookingSearch, guestList, setGuestList } =
    useNewBooking();
  const [guestListOption, setGuestListOption] = useState<string[]>();

  const helpText = (
    <div className="guest-list-helptext">
      <p>
        Vi må vite kontaktopplysningene til alle gjester. Det er kun mulig å
        utsette innsending av gjesteliste for gruppebestillinger (bestilling av
        5 eller flere rom).
      </p>
      <p>
        Senest 7 dager før oppholdet skal Forsvarsbygg ha mottatt en fullstendig
        gjesteliste fra bestilleren. Ved reservasjoner der deltakerne betaler
        selv, er det den enkelte deltakerens ansvar å sende inn gjestelisten.
        Gjestelisten kan lastes opp på Min side i etterkant av bestilling.
      </p>
    </div>
  );

  const [showGuestListOptionModal, setShowGuestListOptionModal] =
    useState<boolean>(false);
  const [orderForOthers, setOrderForOthers] = useState<boolean>(false);
  const [shieldedUnit, setShieldedUnit] = useState<boolean>(false);

  const handleCheckBoxGuestListChange = (
    formId: string,
    formValue: DeliberateAny[],
  ) => {
    if (
      formValue &&
      (bookingOptions?.orderForOthers || bookingOptions?.shieldedUnit)
    ) {
      setShieldedUnit(false);
      setOrderForOthers(false);
      switch (formValue[formValue.length - 1]) {
        case GuestListOption.UnnamedGuests:
          setShieldedUnit(true);
          break;
        case GuestListOption.CivilGuests:
          setOrderForOthers(true);
          break;
      }
      if (guestListOption && guestListOption.length <= formValue.length) {
        setShowGuestListOptionModal(true);
      }
    }
    const newGuestListObject = guestList ? guestList : ({} as IGuestList);
    formValue.forEach((item) => (newGuestListObject[item] = true));

    setGuestListOption(formValue);
    setGuestList(newGuestListObject);
  };

  const renderGuestListOptionModal = () => {
    if (orderForOthers) {
      return (
        <Modal
          header={bookingOptions!.orderForOthers.heading}
          content={bookingOptions!.orderForOthers.text}
          size={ModalSize.Small}
          onClose={() => setShowGuestListOptionModal(false)}
          overrideCancelButtonText="Lukk"
          showCancelButton
        />
      );
    } else if (shieldedUnit) {
      return (
        <Modal
          header={bookingOptions!.shieldedUnit.heading}
          content={bookingOptions!.shieldedUnit.text}
          size={ModalSize.Small}
          onClose={() => setShowGuestListOptionModal(false)}
          overrideCancelButtonText="Lukk"
          showCancelButton
        />
      );
    }
  };

  const memorizedOptions = [
    {
      label: 'Utsett innsending av gjesteliste',
      value: GuestListOption.GuestListPostponed,
      disabled: bookingSearch.guestCount ? bookingSearch.guestCount < 5 : false,
    },
    {
      label:
        'Jeg bestiller for en skjermet enhet og vil derfor ikke levere gjesteliste i Basen',
      value: GuestListOption.UnnamedGuests,
    },
    {
      label:
        'Jeg har med gjester som ikke jobber i forsvarssektoren, eller bestiller for andre enn meg selv',
      value: GuestListOption.CivilGuests,
    },
  ];

  return (
    <div>
      <HelpText content={helpText}>
        <span className="guest-list-header">Gjesteliste</span>
      </HelpText>
      <div className="guest-list-options">
        <CheckboxGroup
          options={memorizedOptions}
          name="guestListOption"
          label="Gjesteliste"
          formHolder={{ guestListOption }}
          updateForm={handleCheckBoxGuestListChange}
          errors={errors}
        />
      </div>
      {showGuestListOptionModal && renderGuestListOptionModal()}
    </div>
  );
};

export default BookingGuestListInfo;
