import {
  toggleQuarterApplicationApproved,
  toggleQuarterApplicationAssignment,
} from 'actions/housingAdminActions';
import Checkbox from 'components/form/Checkbox/Checkbox';
import List from 'components/List/List';
import connect from 'helpers/connectWithRouter';
import moment from 'moment';
import { Component } from 'react';
import { PropWrapper } from 'types/PropNavigationBase';

import QuarterApplicationItem from './QuarterApplicationItem';
import './QuarterApplicationsList.scss';

import type { QuarterApplicationType } from 'types/housingAdminTypes';
import type { UncontrolledSortOptionsType } from 'types/listTypes';
type PropsType = PropWrapper & {
  items: Array<QuarterApplicationType>;
  listType: string;
  toggleQuarterApplicationAssignment: (
    applicationId: string,
    assignmentIsOK: boolean,
  ) => void;
  toggleQuarterApplicationApproved: (
    applicationId: string,
    isApproved: boolean,
  ) => void;
  uncontrolledSortOptions: UncontrolledSortOptionsType;
};

export class UnconnectedQuarterApplicationsList extends Component {
  declare props: PropsType;

  setupColumns = () => {
    if (this.props.listType === 'unprocessed') {
      return [
        ...this.baseColumns(),
        this.availableColumns.IsAssignmentDocumentationReceived,
        this.availableColumns.IsApproved,
      ];
    } else if (this.props.listType === 'processed') {
      return [
        ...this.baseColumns(),
        this.availableColumns.DateProcessed,
        this.availableColumns.Status,
      ];
    }
    return this.baseColumns();
  };

  baseColumns = () => [
    this.availableColumns.LastName,
    this.availableColumns.FirstName,
    this.availableColumns.DateApplied,
    this.availableColumns.ApplicantAge,
    this.availableColumns.ServiceAreaName,
    this.availableColumns.Type,
    this.availableColumns.WantedFromDate,
    this.availableColumns.CoApplicantName,
  ];

  availableColumns = {
    LastName: {
      name: 'LastName',
      label: 'Etternavn',
      className: 'name',
    },
    FirstName: {
      name: 'FirstName',
      label: 'Fornavn',
      className: 'name',
    },
    DateApplied: {
      label: 'Dato søkt',
      name: 'DateApplied',
      renderFunction: (application: QuarterApplicationType) => (
        <div>{moment(application.DateApplied).format('DD.MM.YYYY')}</div>
      ),
      className: 'date-field',
    },
    WantedFromDate: {
      label: 'Ønsket fra',
      name: 'WantedFromDate',
      renderFunction: (application: QuarterApplicationType) => (
        <div>
          {application.WantedFromDate
            ? moment(application.WantedFromDate).format('DD.MM.YYYY')
            : ''}
        </div>
      ),
      className: 'date-field',
    },
    ApplicantAge: {
      label: 'Alder',
      name: 'ApplicantAge',
      className: 'age',
    },
    ServiceAreaName: {
      label: 'Tjenestested',
      name: 'ServiceAreaName',
      className: 'service-area',
    },
    IsAssignmentDocumentationReceived: {
      label: 'Beordring',
      name: 'IsAssignmentDocumentationReceived',
      renderFunction: (application: QuarterApplicationType) => (
        <Checkbox
          disabled={
            application.loadingAssignmentToggle || application.IsApproved
          }
          key={application.Id}
          checked={application.IsAssignmentDocumentationReceived}
          onChange={() => this.toggleApplicationAssignment(application)}
          name="IsAssignmentDocumentationReceived"
        />
      ),
      className: 'order-checked',
    },
    DateProcessed: {
      label: 'Dato behandlet',
      name: 'ProcessedDate',
      renderFunction: (application: QuarterApplicationType) => (
        <div>{moment(application.ProcessedDate).format('DD.MM.YYYY')}</div>
      ),
      className: 'processed-date',
    },
    IsApproved: {
      label: 'Godkjent',
      name: 'IsApproved',
      renderFunction: (application: QuarterApplicationType) => (
        <Checkbox
          disabled={
            !!application.ProcessedDate ||
            application.loadingApprovedToggle ||
            !application.IsAssignmentDocumentationReceived
          }
          key={application.Id}
          checked={application.IsApproved}
          onChange={() => this.toggleApplicationApproved(application)}
          name="IsApproved"
        />
      ),
      className: 'is-approved',
    },
    Status: {
      label: 'Status',
      name: 'IsApproved',
      renderFunction: (application: QuarterApplicationType) =>
        application.IsApproved ? (
          <strong className="green">Godkjent</strong>
        ) : (
          <strong className="red">Avslått</strong>
        ),
      className: 'status',
    },
    Type: {
      label: 'Type',
      name: 'IsRenewal',
      renderFunction: (application: QuarterApplicationType) => (
        <div>{application.IsRenewal ? 'Forlengelse' : 'Ny'}</div>
      ),
      className: 'type',
    },
    CoApplicantName: {
      label: 'Medsøker',
      name: 'CoApplicantName',
      className: 'name',
    },
  };

  toggleApplicationAssignment = (application: QuarterApplicationType) =>
    this.props.toggleQuarterApplicationAssignment(
      application.Id,
      application.IsAssignmentDocumentationReceived,
    );

  toggleApplicationApproved = (application: QuarterApplicationType) =>
    this.props.toggleQuarterApplicationApproved(
      application.Id,
      application.IsApproved,
    );

  renderItem = (item: QuarterApplicationType) => (
    <QuarterApplicationItem
      key={item.Id}
      application={item}
      columns={this.setupColumns()}
      hideDetails={
        this.props.listType === 'approve-allocations' ||
        this.props.listType === 'processed'
      }
    />
  );

  render() {
    return (
      <div className={`applications-list ${this.props.listType}`}>
        <List
          columns={this.setupColumns()}
          renderItem={this.renderItem}
          {...this.props}
        />
        {this.props.children}
      </div>
    );
  }
}

export default connect(null, {
  toggleQuarterApplicationAssignment,
  toggleQuarterApplicationApproved,
})(UnconnectedQuarterApplicationsList);
