import Slide from 'components/Slides/Slide';
import { HousingQuarter } from 'contexts/HousingContext';
import { useHousing, useSlides } from 'contexts/index';

import { housing, housingTitle, paths, quarter, quarterTitle } from '../HousingSection';
import LivingCondition from '../LivingCondition';

const LivingConditionSlide = ({ appType }) => {
  const { applicationType, loading } = useHousing();
  const { goToSlide } = useSlides();

  return (
    <Slide
      path={`${appType === HousingQuarter.Housing ? housing : quarter}${
        paths.livingCondition
      }`}
      title={appType === HousingQuarter.Housing ? housingTitle : quarterTitle}
      loading={loading}
      showCount
    >
      <LivingCondition
        goToSlide={goToSlide}
        applicationType={applicationType}
        defaultTo={`${appType === HousingQuarter.Housing ? housing : quarter}${
          paths.applicantCategory
        }`}
        nextSlidePath={`${
          appType === HousingQuarter.Housing ? housing : quarter
        }${paths.summary}`}
      />
    </Slide>
  );
};

export default LivingConditionSlide;
