import NotificationsListSignalR from './NotificationsListSignalR';

const MyNotifications = () => {
  return (
    <div className="max-width-large" style={{ padding: '22px 0 0 0' }}>
      <h2>Mine varsler</h2>
      <NotificationsListSignalR />
    </div>
  );
};

export default MyNotifications;
