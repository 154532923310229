import { CSSProperties, FC } from 'react';
import { ImageResponse } from 'types/imageTypes';
import './Image.scss';

interface IImagePropsType {
  image: ImageResponse;
  className?: string;
  style?: CSSProperties;
}
const Image: FC<IImagePropsType> = ({ image, className, style }) => {
  const BASE_PATH = process.env.NODE_ENV === 'production' ? '/Content' : '';

  const defaultImage = {
    altText: 'Ukjent bilde',
    description: 'unkown image',
    url: `${BASE_PATH}/assets/img/room_no_image.png`,
  } as ImageResponse;

  const handleOnError = (e) => {
    e.target.onError = null;
    e.target.src = defaultImage.url;
  };
  const renderImage = () => {
    if (image) {
      return (
        <img
          className={className}
          style={style}
          alt={image.altText}
          src={image.url}
          draggable={false}
          onError={(e) => handleOnError(e)}
        />
      );
    } else {
      return (
        <img
          className={className}
          style={style}
          src={defaultImage.url}
          alt={defaultImage.altText}
          draggable={false}
          onError={(e) => handleOnError(e)}
        />
      );
    }
  };
  return <div className="image-container">{renderImage()}</div>;
};

export default Image;
