import './MyRentals.scss';

import { fetchHousingAndServiceAreas } from 'actions/housingActions';
import {
  fetchDocumentLinks,
  fetchMyRentals,
  fetchRejectionReasons,
  fetchRentalContractTexts,
} from 'actions/myRentalsActions';
import { fetchStaticFilterOptions } from 'actions/optionsActions';
import { uploadSignedRentalContractDocument } from 'actions/signatureActions';
import Select from 'components/form/Select/Select';
import Loader from 'components/Loader/Loader';
import ConfirmationModal from 'components/modals/ConfirmationModal/ConfirmationModal';
import {
  SIGNATURE_STATE_ERROR,
  SIGNATURE_STATE_REJECTION,
  SIGNATURE_STATE_SUCCESS,
} from 'constants/signatureConstants';
import connect from 'helpers/connectWithRouter';
import {
  getParamFromURL,
  getStatusQueryToken,
} from 'helpers/difiRedirectUrlParser';
import { Component } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import RelatedServiceOrdersList from './RelatedServiceOrdersList/RelatedServiceOrdersList';
import RentalsList from './RentalsList/RentalsList';

import type { RentalsType } from 'types/myRentalsTypes';
type PropsType = {
  rentals: RentalsType;
  serviceAreasLoaded: boolean;
  rentalContractTextsLoaded: boolean;
  documentLinksLoaded: boolean;
  staticFilterOptions: DeliberateAny[];
  staticFilterOptionsLoaded: boolean;
  fetchMyRentals: (rentalId?: string) => void;
  fetchHousingAndServiceAreas: () => void;
  fetchRentalContractTexts: () => void;
  fetchRejectionReasons: () => void;
  fetchDocumentLinks: () => void;

  resetMyRentals: () => void;

  uploadSignedRentalContractDocument: (
    rentalId: string,
    documentId: string,
    statusQueryToken: string,
  ) => void;
};

const STATEFILTER_ACTIVE = '4';

export class UnconnectedMyRentals extends Component {
  declare props: PropsType;
  declare state: {
    showSigningSuccessModal: boolean;
    showSigningRejectionModal: boolean;
    showSigningErrorModal: boolean;
    showModal: boolean;
    selectedRentalId: string | number;
    userData: DeliberateAny;
  };

  constructor(properties: PropsType, user: DeliberateAny) {
    super(properties);
    this.state = {
      showSigningSuccessModal: false,
      showSigningRejectionModal: false,
      showSigningErrorModal: false,
      showModal: false,
      selectedRentalId: -1,
      userData: user,
    };

    this.fetchAll();

    const statusQueryToken = getStatusQueryToken();
    if (statusQueryToken) {
      this.handleRedirectFromSigningService(statusQueryToken);
    } else {
      this.props.fetchMyRentals();
    }
  }

  componentsDidMount() {
    this.props.resetMyRentals();
  }

  fetchAll() {
    this.props.fetchHousingAndServiceAreas();
    this.props.fetchRentalContractTexts();
    this.props.fetchRejectionReasons();
    this.props.fetchDocumentLinks();
  }

  handleRedirectFromSigningService = (statusQueryToken: string) => {
    const signingState = getParamFromURL('signingState');
    const documentId = getParamFromURL('documentId');
    const rentalId = getParamFromURL('rentalId');
    if (!this.props.staticFilterOptionsLoaded) fetchStaticFilterOptions();
    if (rentalId && documentId) {
      switch (signingState) {
        case SIGNATURE_STATE_SUCCESS:
          this.state.showSigningSuccessModal = true;
          this.props.uploadSignedRentalContractDocument(
            rentalId,
            documentId,
            statusQueryToken,
          );
          break;

        case SIGNATURE_STATE_REJECTION:
          this.state.showSigningRejectionModal = true;
          this.props.fetchMyRentals(rentalId);
          break;

        case SIGNATURE_STATE_ERROR:
          this.state.showSigningErrorModal = true;
          this.props.fetchMyRentals(rentalId);
          break;

        default:
          this.props.fetchMyRentals(rentalId);
          break;
      }
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  renderRentalIdSelect = () => (
    <div className="margin">
      <Select
        defaultChoice="Ingen leieforhold registrert"
        loading={this.props.rentals.isLoading}
        name="rentalId"
        label="Velg leie-id"
        options={this.props.rentals.items.map((rental) => ({
          key: rental.RentalId,
          value: rental.RentalId,
        }))}
        onChange={(name: string, val: number | string) =>
          this.setState({ selectedRentalId: val })
        }
        value={this.state.selectedRentalId}
        autoSelectFirstOption
      />
    </div>
  );

  renderMyRentals = () => (
    <div>
      <div className="margin">
        <h2>Leieforhold</h2>
        <ConfirmationModal
          isOpen={this.state.showSigningSuccessModal}
          onClose={() => this.setState({ showSigningSuccessModal: false })}
          title="Signering fullført"
          infoText="Ditt dokument relatert til leieforholdet er signert."
        />
        <ConfirmationModal
          isOpen={this.state.showSigningRejectionModal}
          onClose={() => this.setState({ showSigningRejectionModal: false })}
          title="Signering avbrutt"
          infoText="Du har avbrutt signering og dokumentet er ikke blitt signert. Du kan starte signering på nytt."
        />
        <ConfirmationModal
          isOpen={this.state.showSigningErrorModal}
          onClose={() => this.setState({ showSigningErrorModal: false })}
          title="Signering feilet"
          infoText="Det skjedde en feil ved signering og dokumentet er ikke blitt signert. Du kan starte signering på nytt."
        />
        <Loader
          isLoading={
            this.props.rentals.isLoading ||
            !this.props.serviceAreasLoaded ||
            !this.props.rentalContractTextsLoaded ||
            !this.props.documentLinksLoaded
          }
        >
          <RentalsList items={this.props.rentals.items} />
        </Loader>
      </div>
      <h2>Feil og mangler</h2>
      {this.renderRentalIdSelect()}
      {this.state.selectedRentalId !== -1 && (
        <RelatedServiceOrdersList
          rentalId={this.state.selectedRentalId}
          typeFilter="Feilmelding"
          stateFilter={STATEFILTER_ACTIVE}
        />
      )}
    </div>
  );

  render() {
    return (
      <div className="max-width-large" style={{ padding: '22px 0' }}>
        <div className="row">
          {this.renderMyRentals()}
          <ConfirmationModal
            isOpen={this.state.showModal}
            onClose={this.closeModal}
            title="Se og følg opp dine leieforhold"
            html={`
              <p>Be om tilgang til å se og følge opp alle dine leieforhold</p>
              <p>Denne funksjonen må aktiveres av
              <a data-action='dismiss' href='#/contact'> Forsvarsbygg servicesenter</a></p>
              <p>Ta kontakt for å få tilgang</p>`}
            infoText={''}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    rentals: state.myRentals.rentals,
    rentalContractTexts: state.myRentals.rentalContractTexts,
    rentalContractTextsLoaded: state.myRentals.rentalContractTextsLoaded,
    serviceAreasLoaded: state.housing.serviceAreasLoaded,
    documentLinksLoaded: state.myRentals.documentLinksLoaded,
    staticFilterOptions: state.options.staticFilterOptions,
    staticFilterOptionsLoaded: state.options.staticFilterOptionsLoaded,
  }),
  {
    fetchMyRentals,
    uploadSignedRentalContractDocument,
    fetchHousingAndServiceAreas,
    fetchRentalContractTexts,
    fetchRejectionReasons,
    fetchDocumentLinks,
    fetchStaticFilterOptions,
  },
)(UnconnectedMyRentals);
