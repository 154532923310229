// @flow

import './ProgressTracker.scss';

import FBHelpers from 'helpers/_helpers';
import { mediaQueries, MediaQuery } from 'helpers/mediaQuery';
import React, { Component } from 'react';

import type { ProgressPointsType } from 'types/commonTypes';
type PlanstatusType = {
  STARTED: number;
  NOTSTARTED?: number;
  PLANNING?: number;
  DONE: number;
  STOPPED?: number;
  CANCELLED?: number;
  POSTPONED?: number;
};

type PropsType = {
  currentStatus: number;
  currentProgress: number;
  previousStatus: number;
  alternateProgressPoints?: Array<ProgressPointsType>;
  pointStatuses: PlanstatusType;
  styleAllSmall?: boolean;
  styleWithTheTextUnderneath?: boolean;
};

const planStatuses = {
  NOTSTARTED: 0,
  PLANNING: 1,
  STARTED: 2,
  DONE: 3,
  STOPPED: 4,
  CANCELLED: 5,
  POSTPONED: 6,
};

const progressPoints = [
  {
    text: 'ikke påbegynt',
  },
  {
    text: 'under planlegging',
  },
  {
    text: 'påbegynt',
  },
  {
    text: '25%',
    small: true,
  },
  {
    text: '50%',
    small: true,
  },
  {
    text: '75%',
    small: true,
  },
  {
    text: 'ferdig',
  },
];

class ProgressTracker extends Component {
  declare props: PropsType;

  declare activePointElement: HTMLElement | null;
  declare mainElement: HTMLElement | null;
  declare wrapperElement: HTMLElement | null;

  state: {
    activePointOffset: number;
    wrapperHeight?: number | null;
    expanded: boolean;
  };

  static defaultProps = {
    pointStatuses: planStatuses,
  };

  constructor(props: PropsType) {
    super(props);

    this.state = {
      activePointOffset: 0,
      wrapperHeight: null,
      expanded: false,
    };
  }

  componentDidMount() {
    // setTimoeout because we have to wait for CanJS to actually attach stuff to dom. Without it
    // .offsetHeight will be null
    setTimeout(() => {
      const mainHalfHeight = (this.mainElement?.offsetHeight || 0) / 2;
      const pointHalfHeight = this.activePointOffsetHeight() / 2;
      const activePointOffset =
        this.activePointOffsetTop() + (pointHalfHeight - mainHalfHeight);

      this.setState({ activePointOffset });
    });
  }

  getActivePoint = (status: number, previousStatus: number): number =>
    !this.props.alternateProgressPoints && this.isStopped(status)
      ? previousStatus
      : status;

  getProgressPoints = () =>
    this.props.alternateProgressPoints || progressPoints;

  getStatusAndProgress = (status: number, progress: number): number => {
    if (status < this.props.pointStatuses.STARTED) {
      return status;
    } else if (status === this.props.pointStatuses.STARTED) {
      return status + progress;
    }
    return (
      status +
      this.getProgressPoints().filter((point) =>
        Object.prototype.hasOwnProperty.call(point, 'small'),
      ).length
    );
  };

  getStoppedStatus = (status: number): string | null => {
    switch (status) {
      case this.props.pointStatuses.STOPPED:
        return 'Tiltak stoppet';
      case this.props.pointStatuses.CANCELLED:
        return 'Tiltak utgår';
      case this.props.pointStatuses.POSTPONED:
        return 'Tiltak utsatt';
      default:
        return null;
    }
  };

  activePointOffsetTop = () =>
    this.activePointElement && this.activePointElement.offsetTop
      ? this.activePointElement.offsetTop
      : 0;

  activePointOffsetHeight = () =>
    this.activePointElement && this.activePointElement.offsetHeight
      ? this.activePointElement.offsetHeight
      : 0;

  expandProgressTracker = (): void => {
    if (FBHelpers.isMobile()) {
      this.setState({
        expanded: !this.state.expanded,
        wrapperHeight: this.wrapperElement?.clientHeight,
      });
    }
  };

  isStopped = (status: number): boolean => {
    switch (status) {
      case this.props.pointStatuses.STOPPED:
      case this.props.pointStatuses.CANCELLED:
      case this.props.pointStatuses.POSTPONED:
        return true;
      default:
        return false;
    }
  };

  render() {
    const activePoint = this.getStatusAndProgress(
      this.getActivePoint(this.props.currentStatus, this.props.previousStatus),
      this.props.currentProgress,
    );
    const stoppedText = !this.props.alternateProgressPoints
      ? this.getStoppedStatus(this.props.currentStatus)
      : null;
    const findProgressPoints = this.getProgressPoints();
    return (
      <div
        onClick={this.expandProgressTracker}
        ref={(element) => {
          this.mainElement = element;
        }}
        className={`progress-tracker \
         ${this.state.expanded ? 'progress-tracker--expanded' : ''} \
        `}
        style={{
          height: this.state.expanded
            ? (this.state.wrapperHeight ?? 'auto')
            : 'auto',
        }}
      >
        <div
          ref={(element) => {
            this.wrapperElement = element;
          }}
          className="progress-point-trackers__wrapper"
        >
          <div
            className="progress-trackers__points"
            style={{
              transform: !this.state.expanded
                ? `translateY(-${this.state.activePointOffset}px)`
                : 'none',
            }}
          >
            {findProgressPoints.map((point, i) => [
              <span
                key={point ? point.text : i}
                className={`progress-point \
                ${
                  point.small || this.props.styleAllSmall
                    ? 'progress-point--small'
                    : ''
                } \
                ${i < activePoint ? 'progress-point--complete' : ''} \
                ${i === activePoint ? 'progress-point--current' : ''} \
                ${
                  i === activePoint && stoppedText
                    ? 'progress-point--stopped'
                    : ''
                } \
                `}
                ref={(element) => {
                  if (i === activePoint) {
                    this.activePointElement = element;
                  }
                }}
              >
                <span
                  className="point-text"
                  style={
                    this.props.styleWithTheTextUnderneath
                      ? { position: 'relative', top: '120%' }
                      : {}
                  }
                >
                  {point.text}
                </span>
                <MediaQuery query={mediaQueries.small}>
                  {i === activePoint && stoppedText && (
                    <span className="stopped-text">{stoppedText}</span>
                  )}
                </MediaQuery>
              </span>,
              i < findProgressPoints.length - 1 && (
                <i key={`${point.text}-line`} className="progress-line">
                  <MediaQuery query={mediaQueries.mediumUp}>
                    {i === activePoint && stoppedText && (
                      <span className="stopped-text">{stoppedText}</span>
                    )}
                  </MediaQuery>
                </i>
              ),
            ])}
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressTracker;
