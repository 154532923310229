import env from 'env';

const { BOOKING: baseBooking } = env;
const { CMS: baseCms } = env;

const userBookingController = `${baseBooking}/UserBooking`;
const adminBookingController = `${baseBooking}/booking`;
const cmsBookingController = `${baseCms}/CmsBooking`;
const basenController = `${baseCms}/Basen`;
const unnamedController = `${baseBooking}/unnamed`;

export const arenainfoURL = `${cmsBookingController}/ArenaInfo`;
export const ledigeRomMedPrisURL = `${userBookingController}/LedigeRomMedPris`;
export const priserURL = `${userBookingController}/priser`;
export const bookingOptionsURL = `${userBookingController}/bookingOptions`;
export const blokkbestillingURL = `${userBookingController}/blokkbestilling`;
export const kundenummerURL = `${userBookingController}/kundenummer`;
export const bestillURL = `${userBookingController}/bestill`;
export const avbestillURL = `${userBookingController}/avbestill`;
export const endreURL = `${userBookingController}/endre`;
export const beholdromURL = `${userBookingController}/beholdrom`;
export const betalendringURL = `${userBookingController}/betalendring`;
export const betalURL = `${userBookingController}/betal`;
export const aktiveURL = `${userBookingController}/aktive`;
export const historiskeURL = `${userBookingController}/historiske`;
export const betalingsMidlerForBrukerURL = `${userBookingController}/BetalingsMidlerForBruker`;
export const resendReceiptURL = `${userBookingController}/ResendReceipt`;

export const bookeeProfileByIdURL = `${adminBookingController}/BookeeProfileById`;
export const locationsURL = `${adminBookingController}/Locations`;
export const searchURL = `${adminBookingController}/Search`;
export const auditEntriesURL = `${adminBookingController}/AuditEntries`;

export const getUnnamedBookingURL = `${unnamedController}/`; // add bookingRequestId as param after
export const getAvailabilityByRoomTypeURL = `${unnamedController}/availability`;
export const getArenaCheckInKeyURL = `${unnamedController}/locationKeys/`; // add arenaId as param after, requires credentials
export const getArenaForLocationKeyURL = `${unnamedController}/arena/info`;
export const getArenaInfoWithReferenceCodeURL = `${unnamedController}/rooms`; //add query with checkInReferenceCode
export const patchReleaseRoomURL = `${unnamedController}/release`; //add query with checkInReferenceCode and roomId
export const patchNewPinURL = `${unnamedController}/newpin`;

export const helpTextsURL = `${basenController}/Hjelp`;

export default baseBooking;
