import {
    setHousingAdminReferenceType, setHousingAdminRegion, toggleFavoriteHousingAdminArea
} from 'actions/housingAdminActions';
import Checkbox from 'components/form/Checkbox/Checkbox';
import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import Select from 'components/form/Select/Select';
import Loader from 'components/Loader/Loader';
import connect from 'helpers/connectWithRouter';
import { hideFromApplication, isFavoriteAreaProperty } from 'helpers/housingAdminHelpers';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropNavigation } from 'types/PropNavigationBase';
import { getParentPath } from 'utils/helpers';

import Modal from '../Modal/Modal';

import type { LabelValueType } from "types/commonTypes";
import type { HousingAreaType } from "types/housingAdminTypes";
type PropsType = PropNavigation & {
  region: string;
  path: string;
  regions: Array<string>;
  referenceType: string;
  referenceTypes: Array<LabelValueType>;
  housingAreas: Array<HousingAreaType>;
  setHousingAdminRegion: (region: string) => void;
  setHousingAdminReferenceType: (referenceType: string) => void;
  toggleFavoriteHousingAdminArea: (
    areaId: string,
    referenceType: string,
    isFavorite: boolean
  ) => void;
  loadingHousingAreas: boolean;
};

export const UnconnectedSettingsModal = (props: PropsType) => {
  const toggleArea = (area) => {
    const isFavorite = area[isFavoriteAreaProperty(props.referenceType)];
    props.toggleFavoriteHousingAdminArea(
      area.Id,
      props.referenceType,
      isFavorite
    );
  };

  return (
    <Modal
      isOpen={props.params.modal === props.path}
      title="Innstillinger"
      ariaLabel="Boligsekretær innstillinger"
      onClose={() => props.navigate(getParentPath(props.location.pathname))}
    >
      <p>Velg de områdene du har ansvar for, så legges de til som favoritter</p>
      <div className="margin">
        <RadioButtonGroup
          name="referenceType"
          options={props.referenceTypes}
          checkedOption={props.referenceType}
          onChange={(radioName, referenceType) =>
            props.setHousingAdminReferenceType(referenceType.toString())
          }
        />
      </div>

      <Loader isLoading={props.loadingHousingAreas}>
        <div className="margin">
          <span>Velg boansvarsområde</span>
          <Select
            name="region"
            value={props.region}
            onChange={(selectName, region) =>
              props.setHousingAdminRegion(region.toString())
            }
            options={props.regions.map((region) => ({
              value: region,
              key: region,
            }))}
          />
        </div>

        <div className="margin">
          <span>Velg boområder</span>
          {props.housingAreas
            .filter(
              (area) =>
                area.Region === props.region &&
                !area[hideFromApplication(props.referenceType)]
            )
            .map((area) => (
              <Checkbox
                disabled={area.loadingToggle}
                key={area.Id}
                checked={area[isFavoriteAreaProperty(props.referenceType)]}
                onChange={() => toggleArea(area)}
                name={area.Name}
                label={area.Name}
              />
            ))}
        </div>
      </Loader>
    </Modal>
  );
};

export default connect(
  (state: DeliberateAny) => ({
    region: state.housingAdmin.region,
    regions: state.housingAdmin.regions,
    housingAreas: state.housingAdmin.housingAreas,
    referenceType: state.housingAdmin.referenceType,
    referenceTypes: state.housingAdmin.referenceTypes,
    loadingHousingAreas: state.housingAdmin.loadingHousingAreas,
  }),
  {
    setHousingAdminRegion,
    setHousingAdminReferenceType,
    toggleFavoriteHousingAdminArea,
  }
)(UnconnectedSettingsModal);
