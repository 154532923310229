import './RadioButtonGroup.scss';

import { useUpdateEffect } from 'react-use';

import { possiblyCastStringToBooleanOrNumber } from '../../../helpers/valueHelpers';
import { DeliberateAny } from '../../../types/DelibrateAny';
import HelpText from '../../HelpText/HelpText';
import FormErrors from '../FormErrors';

type PropsType = {
  options: DeliberateAny[];
  name: string;
  legend?: string;
  onChange?: (name: string, value: string | number | boolean) => void;
  onBlur?: (name: string) => void;
  checkedOption?: string | number | boolean;
  errors?: DeliberateAny;
  heading?: string;
  row?: boolean;
  disabled?: boolean;
};

const RadioButtonGroup = (props: PropsType) => {
  const {
    options,
    name,
    legend,
    checkedOption,
    errors,
    heading,
    onChange,
    onBlur,
    row,
    disabled,
  } = props;
  const error = errors && errors[name];

  useUpdateEffect(() => {
    if (onBlur) onBlur(name);
  }, [checkedOption]);

  const handleChange = (e: DeliberateAny) => {
    const value = possiblyCastStringToBooleanOrNumber(e.target.value);
    if (onChange) {
      onChange(name, value);
    }
  };

  return (
    <div className="radio-button-group">
      {heading && <span className="heading">{heading}</span>}
      <fieldset>
        <legend className={`${error ? 'invalid' : ''}`}>
          {legend} {error && <FormErrors rule={error} />}
        </legend>
        <div className={row ? 'row' : ''}>
          {options &&
            options.map(({ label, value, info }) => (
              <label className="radio" key={label}>
                <input
                  disabled={disabled}
                  type="radio"
                  checked={checkedOption === value}
                  name={name}
                  value={value}
                  onChange={handleChange}
                />
                <span className="indicator" />
                <span className="label">
                  {label}
                  <span style={{ position: 'absolute', right: '-240px' }}>
                    {info && <HelpText helpText={info} />}
                  </span>
                </span>
              </label>
            ))}
        </div>
      </fieldset>
    </div>
  );
};

export default RadioButtonGroup;
