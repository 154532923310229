import { sendServiceOrder } from "actions/serviceOrdersActions";
import connect from "helpers/connectWithRouter";
import { ADMIN_SERVICE_ORDER_TYPE } from "helpers/serviceOrderHelpers";
import moment from "moment";
import { DeliberateAny } from "types/DelibrateAny";

import SendServiceOrderModal from '../SendServiceOrderModal/SendServiceOrderModal';

import type { SendServiceOrderType } from "types/serviceOrderTypes";
import { registerBuildingAdministrativeServiceOrderURL } from "api/basenproff/basenproff_api";
type PropsType = {
  path: string;
  inventoryNumber: number;
  inventoryName: string;
  serviceOrder: SendServiceOrderType;
  sendServiceOrder: (serviceOrder?: SendServiceOrderType) => void;
};

const fromLabel = "Bygget har nedsatt aktivitet fra";
const toLabel = "Bygget tas i ordinær bruk igjen fra";

const editDescription = (serviceOrder?: SendServiceOrderType) => {
  if (serviceOrder) {
    const fromDate = serviceOrder.fromDate
      ? serviceOrder.fromDate.format("DD.MM.YYYY")
      : "";
    const toDate = serviceOrder.toDate
      ? serviceOrder.toDate.format("DD.MM.YYYY")
      : "";
    const comment = serviceOrder.description ? serviceOrder.description : "";
    return `${fromLabel} ${fromDate}\n${toLabel} ${toDate}\nKommentar: ${comment}`;
  }

  return "";
};

  const preSendServiceOrder = (props: PropsType) => {
    if(props?.serviceOrder)
      props.serviceOrder.description = editDescription(props.serviceOrder);
  }

const UnconnectedDecreasedActivityServiceOrderModal = (props: PropsType) => (
  <SendServiceOrderModal
    title="Meld nedsatt aktivitet"
    description={`
      I perioder med liten eller ingen aktivitet i bygget kan det være fornuftig å
      redusere varme og ventilasjon for å spare energi og kostnader. Takk for at du melder ifra.
    `}
    fields={{
      title: {
        value: `Melding av nedsatt aktivitet «${props.inventoryName}»`,
        disabled: true,
      },
      building: { value: props.inventoryNumber, disabled: true },
      description: { label: "Kommentar", required: true },
      fromDate: { value: moment(), label: fromLabel },
      toDate: { value: moment(), label: toLabel },
      serviceOrderType: { value: ADMIN_SERVICE_ORDER_TYPE },
    }}
    url = {registerBuildingAdministrativeServiceOrderURL}
    preSendServiceOrder= {() => preSendServiceOrder(props)}
    {...props}
  />
);

export default connect(
  (state: DeliberateAny) => ({
    serviceOrder: state.serviceOrders.serviceOrder,
  }),
  { sendServiceOrder }
)(UnconnectedDecreasedActivityServiceOrderModal);
