import { forwardRef, useImperativeHandle, useState } from 'react';

import ConfirmationModal from '../modals/ConfirmationModal/ConfirmationModal';
import { useModal } from 'contexts/index';

const PasteInNames = ({ setRoomInfo, roomInfo, bookingModal }, ref) => {
  const [textArea, setTextArea] = useState('');
  const [modalState, setModalState] = useState(false);
  const handleClick = () => {
    const names = textArea.split('\n');
    const originalNames = [...names];
    const newRoomInfo = [...roomInfo];
    let allocatedRooms = 0;

    newRoomInfo.forEach((room) => {
      if (room.NameListInfo.NameListItems) {
        room.NameListInfo.NameListItems.forEach((guest) => {
          names.forEach((roomGuest, i) => {
            if (
              guest.FirstName.trim().length === 0 &&
              allocatedRooms < originalNames.length
            ) {
              allocatedRooms += 1;
              const formattedRoomGuest = roomGuest.split(',');
              const name = formattedRoomGuest[0].split(' ');
              if (name.length > 2) {
                for (i; i < name.length - 1; i++) {
                  guest.FirstName += ' ' + name[i];
                }
                guest.LastName = name[name.length - 1];
              } else {
                guest.FirstName = name[0];
                guest.LastName = name[name.length - 1];
              }
              guest.Phone = formattedRoomGuest[1]?.trim();
              guest.Email = formattedRoomGuest[2]?.trim();
              names.splice(i, 1);
            }
          });
        });
      }
    });

    if (allocatedRooms < originalNames.length) {
      if (allocatedRooms + 1 == originalNames.length) {
        originalNames[originalNames.length - 1] != '' && setModalState(true);
      } else {
        setModalState(true);
      }
    }
    setRoomInfo(newRoomInfo);
    setTextArea('');
  };

  useImperativeHandle(ref, () => ({
    handleClick: () => {
      handleClick();
    },
  }));

  const renderTextArea = () => (
    <textarea
      className="half-margin"
      name="paste-names"
      cols={30}
      rows={7}
      value={textArea}
      onChange={(e) => setTextArea(e.target.value)}
    />
  );
  const renderText = () => (
    <div>
      <p className="margin">
        Du kan enten fylle inn listen selv eller lime den inn fra et dokument
        eller regneark. Pass på at hver deltaker står på en egen linje med{' '}
        <strong>
          fullt navn, mobil (må inkludere landskode) og epost (valgfritt).
        </strong>
      </p>
      Eks.: <br />
      <strong>Ola Nordmann</strong>
      <br />
      <strong>Kari Nordmann, +4790000000, kari.nordmann@forsvarsbygg.no</strong>
    </div>
  );

  const modal = () => (
    <ConfirmationModal
      title="Flere navn enn plasser"
      infoText="Du har lagt inn flere navn enn du har angitt plasser."
      isOpen={modalState}
      onClose={() => setModalState(false)}
    />
  );

  const renderBookingModalLayout = () => (
    <div>
      {renderText()}
      <div className="margin">
        <label>
          <span>Navn</span>
          {renderTextArea()}
        </label>
      </div>
      {modal()}
    </div>
  );

  const renderBookingComponentLayout = () => (
    <div className="margin">
      <div className="flex-row" style={{ width: '100%' }}>
        <div>
          {renderTextArea()}
          <button
            onClick={() => handleClick()}
            className="btn grey float-right"
          >
            Legg til
          </button>
        </div>
        <div>
          <h3>Lim inn gjesteliste</h3>
          {renderText()}
        </div>
      </div>
      {modal()}
    </div>
  );
  return bookingModal
    ? renderBookingModalLayout()
    : renderBookingComponentLayout();
};
export default forwardRef(PasteInNames);
