export const tryParseJson = (json) => {
  try {
    const o = JSON.parse(json);
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (err) {
    // IGNORE
  }
  return null;
};

export default { tryParseJson };
