import './Input.scss';

import HelpText from 'components/HelpText';
import { FC } from 'react';

import { IFormPropBase } from './FormPropBase';

interface IInputProps extends IFormPropBase {
  initial?: string;
  rows?: number;
  cols?: number;
  maxChar?: number;
}

const TextAreaInput: FC<IInputProps> = ({
  name,
  label,
  formHolder,
  updateForm,
  errors,
  helpInfo,
  disabled,
  required,
  initial,
  placeHolder,
  rows,
  cols,
  maxChar,
}) => {
  const handleChange = (e) => {
    updateForm(name, e.target.value);
  };

  return (
    <div className="fb-form-field input-container">
      <HelpText content={helpInfo}>
        <label className={`fb-form-label ${errors[name] ? 'invalid' : ''}`}>
          {errors[name] ? errors[name] : label ? label : name}
        </label>
      </HelpText>
      <div className="form-textarea-input">
        <textarea
          rows={rows}
          cols={cols}
          maxLength={maxChar}
          className={`input-field ${disabled ? 'disabled' : ''} ${errors[name] ? 'error' : ''}`}
          onChange={handleChange}
          value={formHolder[name] ? formHolder[name] : initial}
          required={required}
          disabled={disabled}
          placeholder={placeHolder}
        />
        {maxChar && (
          <div style={{ float: 'right', paddingTop: '0.5em' }}>
            {formHolder[name] ? formHolder[name].length : 0}/{maxChar} tegn
          </div>
        )}
      </div>
      {errors[name] && (
        <label className={`${errors[name] ? 'error' : ''}`}></label>
      )}
    </div>
  );
};

export default TextAreaInput;
