import { serviceOrderShortcutsURL } from 'api/cms/cms_api';
import Loader from 'components/Loader/Loader';
import useAxios from 'hooks/useAxios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { DeliberateAny } from 'types/DelibrateAny';

const ReportingStart = () => {
  const { sendRequest, requestLoading } = useAxios();
  const [forms, setForms] = useState<DeliberateAny>();

  const fetchForms = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: serviceOrderShortcutsURL,
        withCredentials: true,
      },
      setForms,
    );
  };

  useEffectOnce(() => {
    void fetchForms();
  });

  const createFormUrl = (form: DeliberateAny) => {
    switch (form.FormType) {
      case 'Tilleggstjenester':
        return '/reporting/extraservices';
      case 'Valgt plassering':
        return `/reporting/faultform/${form.FormType}/${form.Category}`;
      default:
        return `/reporting/faultform/${form.FormType}`;
    }
  };

  return (
    <div className="slide-reporting-proff-type">
      <div className="slide-navigation max-width-large sticky-top">
        <h2>Type henvendelse</h2>
      </div>

      <div className="scrollable sticky-middle max-width-large">
        <div className="row trailer">
          <h2 className="unclassified-stamp no-margin">Ugradert</h2>
        </div>

        <h2>Hva gjelder henvendelsen?</h2>

        <Loader isLoading={requestLoading || !forms}>
          <ul role="toolbar" className="link-blocks">
            {forms?.map((form) => (
              <li key={form.Id}>
                <Link to={createFormUrl(form)} className="no-underline">
                  {form.ShortcutIcon && (
                    <div className="icon">
                      <img
                        src={form.ShortcutIcon.Url}
                        alt={form.ShortcutIcon.AltText}
                      />
                    </div>
                  )}
                  <div className="text">
                    <div className="h3-wrapper">
                      <h3>{form.Title}</h3>
                    </div>
                    <p>{form.Description}</p>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </Loader>
      </div>
    </div>
  );
};

export default ReportingStart;
