// @flow

import React, { Component } from 'react';
// import type { ServerPagingType } from 'types/listTypes';
import '../Paging.scss';

class ServerPaging extends Component {
  // props: ServerPagingType;

  onFirstPageClick = () => {
    this.props.onChangePageNumber(1);
  };

  onNextPageClick = () => {
    this.props.onChangePageNumber(this.props.pageNumber + 1);
  };

  onPreviousPageClick = () => {
    this.props.onChangePageNumber(this.props.pageNumber - 1);
  };

  render() {
    return (
      <div className="paging">
        <button
          onClick={this.onNextPageClick}
          className={`${this.props.pageSize * this.props.pageNumber > this.props.numItems ? '' : 'red'} link-style`}
          disabled={
            this.props.pageSize * this.props.pageNumber > this.props.numItems
          }
        >
          Hent flere varslinger &raquo;
        </button>
      </div>
    );
  }
}

export default ServerPaging;
