import { useUser } from 'contexts/index';
import { useSearchParams } from 'react-router-dom';

import iconPaperPlane from '../../assets/img/icon-paperplane.svg';

type PropsType = {
  link?: string;
};

const Confirmation = ({ link }: PropsType) => {
  const { hasProffAccess } = useUser();
  const [searchParams, _] = useSearchParams();
  if (!link) {
    const returnTab = searchParams.get('returnTab');

    if (hasProffAccess) {
      link = `#/basen-proff/${returnTab ? returnTab : 'tab-service-orders'}`;
    } else {
      link = '#';
    }
  }
  return (
    <div className="absolute-center center-content center">
      <div className="row trailer ">
        <img
          style={{ width: '100px', display: 'inline-block' }}
          src={iconPaperPlane}
          alt="Paperplane icon"
        />
      </div>
      <h3>Din henvendelse er sendt til Forsvarsbygg.</h3>
      <a href={link} className="btn red">
        Tilbake til oversikten
      </a>
    </div>
  );
};

export default Confirmation;
