// @flow

import './Checkbox.scss';

import _ from 'lodash/fp';
import { ChangeEvent, Component, ReactElement } from 'react';

import { DeliberateAny } from '../../../types/DelibrateAny';
import FormErrors from '../FormErrors';

type PropsType = DeliberateAny & {
  onChange: (name: string, checked: boolean) => void;
  onBlur?: (name: string) => void;
  name: string;
  checked?: boolean;
  label?: string | ReactElement;
  description?: ReactElement;
  errors?: DeliberateAny;
  disabled?: boolean;
  className?: string;
  'data-testid'?: string;
};

class Checkbox extends Component {
  declare props: PropsType;

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    if (this.props.checked !== nextProps.checked && this.props.onBlur) {
      this.props.onBlur(this.props.name);
    }
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.name, event.target.checked);
    }
  };

  handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur(this.props.name);
    }
  };

  labelKey = _.uniqueId('checkbox-');

  render() {
    const { errors, ...props } = this.props;
    const error = errors && errors[this.props.name];
    errors;

    return (
      <div className={this.props.className}>
        <input
          {...props}
          checked={this.props?.value ?? this.props?.checked ?? false}
          type="checkbox"
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          id={this.labelKey}
        />
        <label
          htmlFor={this.labelKey}
          className={`checkbox-label ${error ? 'invalid' : ''}`}
        >
          {this.props.label}
          {error && (
            <span>
              {' '}
              <FormErrors rule={error} />
            </span>
          )}
        </label>
        {this.props.description}
      </div>
    );
  }
}

export default Checkbox;
