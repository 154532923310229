import { useEffect, useState } from 'react';

function useLocalStorage<T>(key: string, initialValue: string) {
  const [storedValue, setStoredValue] = useState<string>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? item : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: string) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, value);
      window.dispatchEvent(new Event('storage_' + key));
    } catch (error) {
      // ignore
    }
  };

  const removeItem = () => {
    try {
      setStoredValue(initialValue);
      window.localStorage.removeItem(key);
      window.dispatchEvent(new Event('storage_' + key));
    } catch (error) {
      // ignore
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const item = window.localStorage.getItem(key);
      setStoredValue(item ? item : initialValue);
    };

    window.addEventListener('storage_' + key, handleStorageChange);
    return () => {
      window.removeEventListener('storage_' + key, handleStorageChange);
    };
  }, [key, initialValue]);

  return [storedValue, setValue, removeItem] as const;
}

export default useLocalStorage;
