import { debug } from 'utils/helpers';

function convertKeysToUpper(obj) {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    newObj[key.toUpperCase()] = obj[key];
  });
  return newObj;
}

export function capitalizeKeys(obj) {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
    newObj[capitalizedKey] = obj[key];
  });
  return newObj;
}

// MUST BE capitalizeKeys
function createLocationServiceOrderDtoFromInput(input) {
  return {
    ...input,
    DistrictId: input.RegionNumber || '',
    EstablishmentId: `${input.Establishment}` || '',
    BuildingCode: input.Building || '',
    CategoryId: input.CategoryId || '',
    CaseTypeId: input.CaseTypeId || 0,
    IoNumber: input.IoNumber || '',
    IsPriceEnquiry: input.IsPriceEnquiry,
  };
}

const generateFormData = (
  data,
  files,
  formName,
  fileFieldName,
  clean = n => {
    return n;
  },
) => {
  const formData = new FormData();
  const payLoadData = { ...data };
  payLoadData.Documents = [];

  for (let i = 0; i < files.length; i += 1) {
    const item = files[i];

    formData.append(`file[${i}]`, item.file);
    payLoadData.Documents.push({
      Name: item.file.name,
      Title: item.title.length > 0 ? item.title : item.file.name,
      size: item.file.size,
      lastModifiedDate: item.file.lastModifiedDate,
      type: item.file.type,
    });
  }
  formData.append(fileFieldName, payLoadData.Documents);

  let dto = createLocationServiceOrderDtoFromInput(capitalizeKeys(payLoadData));

  if (clean) dto = clean(dto);

  formData.append(formName, JSON.stringify(dto));

  return formData;
};

export default generateFormData;
