import dev from './dev.json';
import local from './local.json';
import overrideFile from './override.json';
import prod from './prod.json';
import test from './qa.json';
import stage from './stage.json';

const environments = {
  'basen-dev.forsvarsbygg.no': dev,
  'basen-qa.forsvarsbygg.no': test,
  'basen-stage.forsvarsbygg.no': stage,
  'basen.forsvarsbygg.no': prod,
  localhost: local,
};

const hostname =
  typeof window !== 'undefined' ? window.location.hostname : 'localhost';
const currentEnv = overrideFile?.active
  ? overrideFile
  : environments[hostname] || local;

export const env = currentEnv;

export const isDev = hostname === 'localhost';

export default env;
