import { frontpageSettingsUrl } from 'api/core/core_api';
import Modal from 'components/modals/Modal/Modal';
import { ModalTypes } from 'constants/modalTypes';
import useAxios from 'hooks/useAxios';
import React, {
  createContext,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IWrapper } from 'src/interfaces/IWrapper';
import { DeliberateAny } from 'types/DelibrateAny';

import { useLogin, useUser } from './';

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function throttle(func: Function, wait: number, options: DeliberateAny = {}) {
  let context: any, args: any[], result: any;
  let timeout: DeliberateAny | null = null;
  let previous = 0;
  const { leading = true, trailing = true } = options;

  const later = () => {
    previous = leading ? Date.now() : 0;
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) {
      context = null;
      args = [];
    }
  };

  return function (this: any, ...argsPassed: any[]) {
    const now = Date.now();
    if (!previous && !leading) previous = now;
    const remaining = wait - (now - previous);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    context = this;
    args = argsPassed;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) {
        context = null;
        args = [];
      }
    } else if (!timeout && trailing) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

export const InactivityContext = createContext({});

export const InactivityContextProvider: FC<IWrapper> = ({ children }) => {
  const { logout } = useLogin();
  const { user, hasUser } = useUser();
  const { sendRequest } = useAxios();

  const [showAlert, setShowAlert] = useState(false);
  const [showLogout, setShowLogout] = useState(false);

  const [alertDuration, setAlertDuration] = useState(60 * 1000 * 60);
  const [loginDuration, setLoginDuration] = useState(90 * 1000 * 60);
  const [alertMsg, setAlertMsg] = useState(
    'Vi oppdaget at du har vært inaktiv en stund, av sikkerhetshensyn vil du bli logget ut.',
  );
  const [logoutMsg, setLogoutMsg] = useState(
    'Vi oppdaget at du har vært inaktiv en stund, av sikkerhetshensyn er du blitt logget ut. Vennligst logg inn på nytt for å fortsette å bruke applikasjonen.',
  );

  const [currentHash, setCurrentHash] = useState(window.location.hash);

  useEffect(() => {
    if (window.location.search.includes('forceReload=true')) {
      // Remove the 'forceReload' query parameter
      const newUrl = window.location.href
        .replace('?forceReload=true', '')
        .replace('&forceReload=true', '');
      window.history.replaceState({}, document.title, newUrl);

      // Force a reload
      window.location.reload();
    }

    const handleHashChange = () => {
      setCurrentHash(window.location.hash.replace('#', ''));
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const sessionExpiryTimeRef = useRef<number | null>(null);
  const sessionAlertTimeRef = useRef<number | null>(null);

  const pauseRef = useRef(false);

  const updateSession = () => {
    if (loginDuration)
      sessionExpiryTimeRef.current = Date.now() + loginDuration;
    if (alertDuration) sessionAlertTimeRef.current = Date.now() + alertDuration;

    if (!sessionExpiryTimeRef.current || !sessionAlertTimeRef.current)
      setTimeout(updateSession, 1000);
  };

  const resetSessionExpiry = () => {
    if (pauseRef.current) return;

    if (handleLogoutCheck()) {
      pauseRef.current = true;
      return;
    }

    if (handleAlertCheck()) {
      pauseRef.current = true;
      return;
    }

    updateSession();
  };

  const throttleResetSessionExpiry = useCallback(
    throttle(resetSessionExpiry, 1000),
    [loginDuration, alertDuration],
  );

  const handleLogoutCheck = useCallback(() => {
    const currentTime = Date.now();
    if (
      sessionExpiryTimeRef.current &&
      currentTime > sessionExpiryTimeRef.current
    ) {
      updateSession();
      handleShowLogoutModal();
      return true;
    }
    return false;
  }, [loginDuration]);

  const handleAlertCheck = useCallback(() => {
    const currentTime = Date.now();
    if (
      sessionAlertTimeRef.current &&
      currentTime > sessionAlertTimeRef.current
    ) {
      updateSession();
      handleShowAlertModal();
      return true;
    }
    return false;
  }, [alertDuration]);

  const handleShowLogoutModal = () => {
    setShowLogout(true);
  };

  const handleShowAlertModal = async () => {
    setShowAlert(true);
  };

  const logoutSilent = () => {
    logout(false);
  };

  const redirectToLogin = () => {
    window.location.href = '/#';
    window.location.reload();
  };

  useEffect(() => {
    const getInacativityDuration = async () => {
      if (!hasUser) return;
      await sendRequest(
        {
          method: 'GET',
          url: frontpageSettingsUrl,
          params: {
            propertyNames:
              'LogoutInactive,AlertInactive,LogoutInactiveMsg,AlertInactiveMsg',
          },
          withCredentials: true,
        },
        (result) => {
          if (!result) return;

          const valueObject: DeliberateAny = {};
          result.forEach((fpProp) => {
            if (fpProp.Value) valueObject[fpProp.Name] = fpProp.Value;
          });

          if (valueObject.LogoutInactive)
            setLoginDuration(valueObject.LogoutInactive * 1000 * 60);
          if (valueObject.LogoutInactiveMsg)
            setLogoutMsg(valueObject.LogoutInactiveMsg);
          if (valueObject.AlertInactive)
            setAlertDuration(valueObject.AlertInactive * 1000 * 60);
          if (valueObject.AlertInactiveMsg)
            setAlertMsg(valueObject.AlertInactiveMsg);

          resetSessionExpiry();
        },
        user,
      );
    };
    if (user) getInacativityDuration();
  }, [user, hasUser]);

  useEffect(() => {
    document.addEventListener('mousemove', throttleResetSessionExpiry);
    document.addEventListener('keypress', resetSessionExpiry);

    return () => {
      document.removeEventListener('mousemove', throttleResetSessionExpiry);
      document.removeEventListener('keypress', resetSessionExpiry);
    };
  }, [loginDuration, alertDuration, location]);

  return (
    <InactivityContext.Provider value={{}}>
      <Fragment>{children}</Fragment>
      <Modal
        isOpen={showAlert}
        title="Inaktivitet!"
        description={alertMsg}
        submit={{
          text: 'Logg ut',
          onClick() {
            logout();
          },
        }}
        cancel={{
          text: 'Forbli innlogget',
          onClick() {
            setShowAlert(false);
            updateSession();
            pauseRef.current = false;
          },
        }}
      />
      <Modal
        isOpen={showLogout}
        title="Logget ut!"
        description={logoutMsg}
        onOpen={logoutSilent}
        onClose={redirectToLogin}
        submit={{
          text: 'Ok',
          onClick() {
            pauseRef.current = false;
          },
        }}
        cancel={{
          hide: true,
        }}
      />
    </InactivityContext.Provider>
  );
};

export default InactivityContext;
