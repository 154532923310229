import './BookingContactInfo.scss';

import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import { useNewBooking } from 'contexts/index';
import { roomCapacity } from 'helpers/rooms';
import { labelYupError } from 'helpers/yupLocales';
import { FC, useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import Yup from 'utils/yupExtensions';

const BookingContactInfoValidation = Yup.object().shape({
  guestListOption: Yup.string(),
  rooms: Yup.array().of(
    Yup.object().shape({
      guests: Yup.array().of(
        Yup.object().shape({
          fullName: Yup.string().when(
            'guestListOption',
            (guestListOption, schema) => {
              return guestListOption
                ? schema.required('Påkrevd å skrive inn alle gjestenavn')
                : schema;
            },
          ),
          mobile: Yup.string().when(
            'guestListOption',
            (guestListOption, schema) => {
              return guestListOption
                ? schema.required('Påkrevd å skrive inn alle mobilnumre')
                : schema;
            },
          ),
          additionalInfo: Yup.string(), // Optional field
        }),
      ),
    }),
  ),
  tooFewRooms: Yup.bool().when('rooms', {
    is: (rooms: number) => rooms > 0,
    then: (schema) =>
      schema.oneOf(
        [false],
        'Det er for mange gjester ift. rom. Vennligst gå tilbake for å legge til flere rom',
      ),
    otherwise: (schema) => schema,
  }),
});

interface IBookingErrorsProps {
  nextTriggered: boolean;
}

const BookingErrors: FC<IBookingErrorsProps> = ({ nextTriggered }) => {
  const { rooms, errors, setErrors, bookingSearch, roomInfo, roomCounts } =
    useNewBooking();

  const [showErrors, setShowErrors] = useState<boolean>(false);

  const contextNames = {
    guestListOption: 'Gjesteliste',
    missingGuestName: 'Gjestenavn',
    missingGuestMobile: 'Mobilnumre',
  };

  useEffect(() => {
    handleValidation();
  }, [rooms]);

  const handleValidation = async () => {
    if (!roomInfo) return;

    try {
      const capacity = roomCapacity(roomInfo, roomCounts);
      const tooFewRooms = capacity.maxGuests < bookingSearch.guestCount;

      const validationObject = {
        rooms,
        tooFewRooms,
      };

      await BookingContactInfoValidation.validate(validationObject, {
        abortEarly: false,
        context: contextNames,
      });

      setErrors({});
    } catch (err: DeliberateAny) {
      const validationErrors = {};
      err.inner.forEach(
        (error) =>
          (validationErrors[error.path] = labelYupError(error, contextNames)),
      );
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    if (nextTriggered) {
      setShowErrors(true);
    }
  }, [nextTriggered]);

  if (!showErrors) {
    return;
  }

  if (Object.keys(errors).length <= 0) {
    return;
  }

  return (
    <AlertMessage className="sticky-top" variant={AlertType.Error}>
      {[...new Set(Object.values(errors))].map((err) => (
        <div key={err}>{err}</div>
      ))}
    </AlertMessage>
  );
};

export default BookingErrors;
