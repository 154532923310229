/* eslint-disable */

import '../../Reservations.scss';

import { fetchAuditEntries } from 'actions/reservationActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import ListItem from 'components/ListItem/ListItem';
import Modal from 'components/modals/Modal/Modal';
import env from 'env';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeliberateAny } from 'types/DelibrateAny';

import { getNewJwtToken } from '../../../../hooks/useAxios';
import Details from './ReservationDetails';

const UnconnectedGuestItem = (props) => {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const reservationHistory = useSelector(
    (state: DeliberateAny) => state.reservation.history,
  );
  const reservationProfile = useSelector(
    (state: DeliberateAny) => state.reservation.profile,
  );

  const dispatch = useDispatch();

  const toggleState = () => {
    setShowHistoryModal(!showHistoryModal);

    if (!showHistoryModal) {
      fetchHistory();
    }
  };

  const history = () => (
    <ButtonRow.Right color="white" onClick={toggleState}>
      Se historikk
    </ButtonRow.Right>
  );

  const handlePrintButton = async () => {
    const jwtToken = await getNewJwtToken();
    const url = `${env.BOOKING}/booking/printbooking?id=${props.item.Id}`;

    const headers = {
      Authorization: `Bearer ${jwtToken}`,
    };

    try {
      const response = await fetch(url, { headers });

      if (response.ok) {
        // Handle successful response
        const blob = await response.blob();
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
      } else {
        // Handle error response
        // You can check the response status and handle the error accordingly
      }
    } catch (error) {
      // Handle network or other errors
    }
  };

  const skrivUt = () => (
    <ButtonRow.Right
      color="white"
      onClick={() => {
        handlePrintButton();
      }}
    >
      Skriv ut
    </ButtonRow.Right>
  );

  const detailsInfo = () => {
    if (reservationProfile) {
      return (
        <div>
          <Details items={props} profile={props.item.BookeeId} />
        </div>
      );
    }
    return null;
  };

  const fetchHistory = () => dispatch(fetchAuditEntries(props.item.Id));

  const renderExtendedDetails = () => (
    <React.Fragment>
      <ButtonRow>
        {history()}
        {skrivUt()}
      </ButtonRow>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ListItem
        item={props.item}
        columns={props.columns}
        mountAsExpanded={props.item.expandMe || props.mountAsExpanded}
        renderDetailsFunction={detailsInfo}
        extendedDetailsContainer={renderExtendedDetails()}
        minimalDetails
      />
      <Modal
        isOpen={showHistoryModal}
        onClose={toggleState}
        ariaLabel="Innsjekk-prosess"
        size="medium"
      >
        <div className="container">
          <div className="flex-row margin">
            <h2>Reservasjonshistorikk</h2>
          </div>
          {reservationHistory && (
            <div className="flex-row margin">
              <div className="#">
                <h3>Historikk</h3>
                {reservationHistory.map((item) => (
                  <p key={item.Id}>{item.Event}</p>
                ))}
              </div>
              <div className="*">
                <h3>Tidspunkt</h3>
                {reservationHistory.map((item) => (
                  <p key={item.Id}>
                    {moment(item.EventDate).format('DD.MM.YY - hh:mm')}
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default UnconnectedGuestItem;
