/* eslint react/prop-types: 0 */

import "./HousingApplicationsOverview.scss";

import {
  fetchHousingRound,
  fetchHousingTexts,
  fetchVacantFromOffsetDaysHousing,
} from "actions/housingAdminActions";
import DownloadButton from "components/buttons/DownloadButton/DownloadButton";
import DateInput from "components/DateInput/DateInput";
import Loader from "components/Loader/Loader";
import { useSlides } from "contexts/index";
import { getRoundTitle } from "helpers/housingAdminHelpers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route, useParams } from "react-router-dom";
import { DeliberateAny } from "types/DelibrateAny";

import { housingListExcelDocumentURL } from "../../../../api/housing/housing_api";
import HousingPropertiesList from "./HousingPropertiesList/HousingPropertiesList";

const HousingProperties = (props) => {
  const [date, setDate] = useState(null);

  const dispatch = useDispatch();
  const params = useParams();
  const { goToSlide } = useSlides();

  const housingArea = useSelector(
    (state: DeliberateAny) => state.housingAdmin.housingArea,
    shallowEqual
  );

  const formatMomentToString = (d) => d.format("YYYY-MM-DD");

  useEffect(() => {
    if (housingArea.selectedAllocationRound) {
      dispatch(fetchVacantFromOffsetDaysHousing());
      dispatch(fetchHousingTexts());
    } else {
      goToSlide(`/boligsekretær/boligsøknader/${params.housingAreaId}`);
    }
  }, []);

  useEffect(() => {
    if (!isNaN(housingArea.vacantFromStandardOffsetDays)) {
      const dateWithOffset = moment().add(
        housingArea.vacantFromStandardOffsetDays,
        "d"
      );
      search(dateWithOffset);
    }
  }, [housingArea.vacantFromStandardOffsetDays]);

  const search = (selectedDate) => {
    dispatch(
      fetchHousingRound(
        housingArea.selectedAllocationRound.Round.Id,
        formatMomentToString(selectedDate)
      )
    );
    setDate(selectedDate);
  };

  return (
    <Loader
      isLoading={
        housingArea.loadingHousingTexts || housingArea.loadingHousingRound
      }
    >
      {housingArea.selectedAllocationRound &&
        housingArea.housingTexts &&
        date && (
          <div>
            <h2>
              {getRoundTitle(
                housingArea.selectedAllocationRound,
                housingArea.housingRoundDataOptions
              )}
            </h2>
            <div>
              <h2>
                Tilgjengelige boliger fra dato {formatMomentToString(date)}
              </h2>

              {housingArea.housingRound.HousingListItems.length ? (
                <p>
                  Det er{" "}
                  <strong className="red">
                    {housingArea.housingRound.HousingListItems.length}
                  </strong>{" "}
                  ledige boliger til fordeling.
                  <br />
                </p>
              ) : (
                <p>Ingen boliger lagt til i fordelingsrunden.</p>
              )}
              <div className="date-search-container">
                <h3>Velg dato for boligsøk</h3>
                <DateInput
                  small
                  name="housing_search_date"
                  minDate={moment()}
                  selectedDate={date}
                  onChange={(name, selectedDate) => search(selectedDate)}
                />
                <DownloadButton
                  disabled={
                    housingArea.housingRound.HousingListItems.length === 0
                  }
                  href={`${housingListExcelDocumentURL}?allocationRoundId=${
                    housingArea.selectedAllocationRound.Round.Id
                  }&vacantFrom=${formatMomentToString(date)}`}
                >
                  Last ned
                </DownloadButton>
              </div>
            </div>
            {housingArea.housingRound.HousingListItems && (
              <div style={{ marginTop: "22px" }}>
                <HousingPropertiesList
                  items={housingArea.housingRound.HousingListItems}
                />
              </div>
            )}
          </div>
        )}
    </Loader>
  );
};

export default HousingProperties;

// (props) => (
//   <Route
//     location={props.location}
//     path={`${props.match.url}/:housingAreaId`}
//     component={HousingProperties}
//   />
// );
