import './Accordion.scss';

import { DownIcon, UpIcon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

interface AccordionProps {
  title: string;
  children: ReactNode  ;
  className?: string;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`fb-accordion ${className ?? ''}`}>
      <div className="fb-accordion-header" onClick={toggleAccordion}>
        <h3>{title}</h3>
        <div className={`fb-accordion-icon ${isOpen ? 'open' : ''}`}>
          <BasenIcon icon={isOpen ? UpIcon : DownIcon} />
        </div>
      </div>
      <div
        className={`fb-accordion-content-container ${isOpen ? 'open' : 'closed'}`}
        ref={contentRef}
      >
        <div className="fb-accordion-content">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
