import { mapArrayObjectsToCamel } from "helpers/commonReducerHelpers";

const initialState = {
  favorites: {
    items: [],
    pageSize: 8,
    pageNumber: 0,
    sortByColumn: "Name",
    sortOrderAscending: false,
    isLoading: false,
  },
  search: {
    items: [],
    filter: {
      pageSize: 8,
      pageNumber: 1,
      sortByColumn: "Name",
    },
    filterMetaData: {
      loadingEstablishments: false,
      searchType: "buildingSearch",
    },
    isLoading: false,
    firstSearch: true,
  },
  plans: {
    items: [],
    showModal: false,
    selectedPlan: null,
    isLoading: false,
  },
  renters: {
    items: [],
    isLoading: false,
  },
  buildingTypes: [],
  buildingTypesLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "RECEIVE_BUILDING_TYPES":
      return {
        ...state,
        buildingTypesLoaded: true,
        buildingTypes: mapArrayObjectsToCamel(action.buildingTypes),
      };
    case "FETCH_PORTFOLIO_FAVORITES":
      return {
        ...state,
        favorites: {
          ...state.favorites,
          isLoading: true,
        },
      };

    case "RECEIVE_PORTFOLIO_FAVORITES":
      return {
        ...state,
        favorites: {
          ...state.favorites,
          items: [...action.data.Buildings, ...action.data.SurfaceOfLands],
          isLoading: false,
        },
      };

    case "SORT_PORTFOLIO_FAVORITES":
      return {
        ...state,
        favorites: {
          ...state.favorites,
          items: action.sortedItems,
        },
      };

    case "UPDATE_PORTFOLIO_FAVORITES_METADATA":
      return {
        ...state,
        favorites: {
          ...state.favorites,
          [action.key]: action.value,
        },
      };

    case "RECEIVE_ADD_PORTFOLIO_FAVORITE": {
      const itemToUpdate = action.item;
      const newFavorite = { ...itemToUpdate, IsFavorite: true };
      const updatedSearchItems = state.search.items.map((item) => {
        if (item.InventoryNumber === itemToUpdate.InventoryNumber) {
          return newFavorite;
        }
        return item;
      });

      return {
        ...state,
        search: {
          ...state.search,
          items: updatedSearchItems,
        },
        favorites: {
          ...state.favorites,
          items: [...state.favorites.items, newFavorite],
        },
      };
    }

    case "RECEIVE_TOGGLE_PLAN_FAVORITE": {
      const planToUpdate = action.item;
      const updatedPlansItems = state.plans.items.map((plan) => {
        if (plan.Id === planToUpdate.Id) {
          return {
            ...plan,
            UserExtra: {
              ...plan.UserExtra,
              IsFavorite: !planToUpdate.UserExtra.IsFavorite,
            },
          };
        }
        return plan;
      });

      return {
        ...state,
        plans: {
          ...state.plans,
          items: updatedPlansItems,
        },
      };
    }

    case "RECEIVE_REMOVE_PORTFOLIO_FAVORITE": {
      const itemToUpdate = action.item;
      const updatedSearchItems = state.search.items.map((item) => {
        if (item.InventoryNumber === itemToUpdate.InventoryNumber) {
          return { ...itemToUpdate, IsFavorite: false };
        }
        return item;
      });

      const updatedFavoriteItems = state.favorites.items.filter(
        (item) => item.InventoryNumber !== itemToUpdate.InventoryNumber
      );

      return {
        ...state,
        search: {
          ...state.search,
          items: updatedSearchItems,
        },
        favorites: {
          ...state.favorites,
          items: updatedFavoriteItems,
        },
      };
    }

    case "FETCH_PORTFOLIO_SEARCH":
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: true,
          firstSearch: false,
        },
      };

    case "RECEIVE_PORTFOLIO_SEARCH":
      return {
        ...state,
        search: {
          ...state.search,
          items: action.data,
          isLoading: false,
        },
      };

    case "UPDATE_PORTFOLIO_FILTER":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            [action.key]: action.value,
          },
        },
      };

    case "RESET_PORTFOLIO_FILTER":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            region: state.search.filter.region,
            dif: state.search.filter.dif,
            ...initialState.search.filter,
          },
        },
      };

    case "RECEIVE_PORTFOLIO_FILTER_ESTABLISHMENTS":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            establishment: "",
          },
          filterMetaData: {
            ...state.search.filterMetaData,
            establishments: mapArrayObjectsToCamel(action.value),
            loadingEstablishments: false,
          },
        },
      };

    case "UPDATE_PORTFOLIO_FILTER_METADATA":
      return {
        ...state,
        search: {
          ...state.search,
          filterMetaData: {
            ...state.search.filterMetaData,
            [action.key]: action.value,
          },
        },
      };

    case "FETCHING_PORTFOLIO_FILTER_ESTABLISHMENTS":
      return {
        ...state,
        search: {
          ...state.search,
          filterMetaData: {
            ...state.search.filterMetaData,
            loadingEstablishments: true,
          },
        },
      };

    case "FETCH_PORTFOLIO_RENTERS":
      return {
        ...state,
        renters: {
          ...state.renters,
          isLoading: true,
        },
      };

    case "RECEIVE_PORTFOLIO_RENTERS":
      return {
        ...state,
        renters: {
          items: action.data,
          isLoading: false,
        },
      };

    case "FETCH_PORTFOLIO_PLANS":
      return {
        ...state,
        plans: {
          ...state.plans,
          isLoading: true,
        },
      };

    case "RECEIVE_PORTFOLIO_PLANS":
      return {
        ...state,
        plans: {
          ...state.plans,
          items: action.data,
          isLoading: false,
        },
      };

    case "OPEN_PORTFOLIO_PLAN_MODAL":
      return {
        ...state,
        plans: {
          ...state.plans,
          showModal: true,
          selectedPlan: state.plans.items.find(
            (plan) =>
              // $FlowFixMe
              plan.Id === action.selectedPlanId
          ),
        },
      };

    case "CLOSE_PORTFOLIO_PLAN_MODAL":
      return {
        ...state,
        plans: {
          ...state.plans,
          showModal: false,
          selectedPlan: null,
        },
      };

    case "SET_PORTFOLIO_BRAS_OPTIONS":
      return {
        ...state,
        search: {
          ...state.search,
          filterMetaData: {
            ...state.search.filterMetaData,
            bras: action.bras,
          },
        },
      };

    default:
      return state;
  }
};
