import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";

interface NavProps {
  to: string;
  text: string;
  alsoActiveWith?: string[];
}

const Nav: FC<NavProps> = ({ to, text, alsoActiveWith }) => {
  const location = useLocation();
  const isActive =
    alsoActiveWith?.includes(location.pathname) || location.pathname === to;

  return (
    <NavLink to={to} className="navlink">
      <li className={`tab${isActive ? "-active" : ""}`}>{text}</li>
    </NavLink>
  );
};

export default Nav;
