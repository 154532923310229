export default function renderIcon(guestItem) {
  const housingIcon = {
    src: "/globalassets/ikoner/AM_bolig-black.svg",
    alt: "Langtidsovernatting",
  };

  const bookingIcon = {
    src: "/globalassets/ikoner/AM_overnatting-black.svg",
    alt: "Korttidsovernatting",
  };

  let icon;

  switch (guestItem.Origin) {
    case 0:
      icon = <img src={bookingIcon.src} alt={bookingIcon.alt} />;
      break;
    case 1:
      icon = <img src={housingIcon.src} alt={housingIcon.alt} />;
      break;
    default:
      icon = <span>Noe gikk galt</span>;
      break;
  }

  return icon;
}
