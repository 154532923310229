// @flow
import { call, put } from "redux-saga/effects";
import {
  saveInspectionURL,
  getInspectionFormURL,
} from "../api/basenproff/basenproff_api";
import { axiosFetch } from "../hooks/useAxios";

function* handleFetchInspectionTypes(action) {
  // url will be changed when we have the correct one. this is just a type of inspection
  const url = `${getInspectionFormURL}?rentalobjectid=${action.rentalobjectId}&inspectionid=${action.inspectionId}`;

  try {
    const inspectionTypes = yield call(axiosFetch, { url });
    yield put({ type: "RECEIVE_INSPECTION_TYPES", inspectionTypes });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleSendInspection(action) {
  // url will be changed when we have the correct one. this is just a type of inspection
  const url = saveInspectionURL;
  const query = {
    rentalObjectId: action.rentalobjectId,
    inspectionid: action.inspectionId,
    inspectionForm: action.inspectionForm,
    complete: action.isComplete,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({ type: "SENDING_INSPECTION_SUCCESS" });
  } catch (e) {
    yield put({ type: "SAVED_INSPECTION FAILED" });
  }
}

export { handleFetchInspectionTypes, handleSendInspection };
