import { verifyIdp, verifyUserURL } from "api/cms/cms_api";
import { useLogin } from "contexts/index";
import env from "env";
import useAxios from "hooks/useAxios";
import { useEffect, useState } from "react";

import BackButton from "./BackButton";

const Verify = () => {
  const { setPath, path, actionKey } = useLogin();
  const { sendRequest, requestLoading, requestError } = useAxios();
  const [verify, setVerify] = useState(false);

  const verifyActionKey = async () => {
    await sendRequest(
      {
        url: verifyUserURL + "?actionKey=" + actionKey,
        method: "GET",
      },
      () => {
        setVerify(true);
      }
    );
  };

  useEffect(() => {
    if (actionKey) verifyActionKey();
  }, [actionKey]);

  const handleClick = async () => {
    if (!verify) return;
    window.location.href = env["LOCAL_URL"]
      ? `${verifyIdp}?localUrl=${env["LOCAL_URL"]}`
      : verifyIdp;
  };

  return (
    <div data-flip-id="new-user" className="flip-card-front scrollable">
      <BackButton />

      <h2>Verifiser bruker</h2>
      <p>Vennligst verifiser din bruker hos ID-Porten.</p>
      <div className="row trailer">
        <button
          onClick={handleClick}
          disabled={!verify}
          className={!verify ? "gray btn" : "btn red"}
        >
          {!verify ? "  ...  " : "Fortsett"}
        </button>
      </div>
      <div className="modal-servicecenter">
        <p>
          <strong>Forsvarsbygg servicesenter</strong>
          <br />
          <strong className="red">468 70 400</strong>
        </p>
      </div>
    </div>
  );
};

export default Verify;
