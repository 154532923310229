// @flow

import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { DeliberateAny } from 'types/DelibrateAny';

import {
  signableDocumentsURL,
  uploadSignedRentalProtocolURL,
  documentFileNameURL,
} from "../api/basenproff/basenproff_api";
import { axiosFetch } from "../hooks/useAxios";

export function* handleSignableDocuments(): Generator<
  CallEffect | PutEffect,
  void,
  void
> {
  //Changed from ActionType to CallEffect | PutEffect
  try {
    const data = yield call(axiosFetch, { url: signableDocumentsURL });
    yield put({ type: "RECEIVE_SIGNABLE_DOCUMENTS", data });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url: signableDocumentsURL });
  }
}

export function* handleUploadSignedRentalProtocol(
  action: DeliberateAny
): Generator<DeliberateAny, void, void> {
  const url = uploadSignedRentalProtocolURL;
  const query = {
    inventoryType: action.inventoryType,
    inventoryNumber: action.inventoryNumber,
    documentId: action.documentId,
    rentalId: action.rentalId,
    statusQueryToken: action.statusQueryToken,
  };

  try {
    yield call(axiosFetch, {
      url,
      data: query,
      method: "POST",
      withCredentials: true,
    });
    // Fetch signable documents when signed document has been uploaded to xpand
    const data = yield call(axiosFetch, { url: signableDocumentsURL });
    yield put({ type: "RECEIVE_SIGNABLE_DOCUMENTS", data });
  } catch (e) {
    yield put({
      type: "SAGA_ERROR_UPLOAD_SIGNED_RENTAL_PROTOCOL",
      e,
      url,
      query,
    });
  }
}

export function* handleGetDocumentFileName(
  action: DeliberateAny
): Generator<DeliberateAny, void, void> {
  const url = documentFileNameURL;
  const fileName = yield call(axiosFetch, {
    url: `${url}`,
    withCredentials: true,
  });
  yield put({
    type: "RECEIVE_SIGNED_DOCUMENT_FILE_NAME",
    fileName,
    statusQueryToken: action.statusQueryToken,
  });
}
