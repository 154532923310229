import { ValueOption } from 'components/FormComponents/FormPropBase';
import PurposeSelect from 'components/ModalComponents/PurposeSelect';
import { useMyPage } from 'contexts/index';
import { FC } from 'react';
import { DeliberateAny, SetState } from 'types/DelibrateAny';

interface IPurposeFormProps {
  errors: DeliberateAny;
  purpose: DeliberateAny;
  setPurpose: SetState<DeliberateAny>;
}

const fieldNames = {
  purposeOption: 'Oppholdets formål',
  purposeDescription: 'Beskrivelse',
  paymentOption: ' ',
};

const PurposeForm: FC<IPurposeFormProps> = ({
  errors,
  purpose,
  setPurpose,
}) => {
  const { bookingOptions } = useMyPage();

  return (
    <div className="payment-info-container">
      <PurposeSelect
        purposeOptions={(bookingOptions?.purposeOptions ?? []).map((item) => {
          return { label: item, value: item } as ValueOption;
        })}
        formholder={purpose}
        updateForm={(n, v) => {
          setPurpose((x) => {
            const updatedForm = { ...x, [n]: v };
            return updatedForm;
          });
        }}
        bookingError={errors}
        optionFieldName={fieldNames.purposeOption}
        descriptionFieldName={fieldNames.purposeDescription}
      />
    </div>
  );
};

export default PurposeForm;
