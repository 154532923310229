import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { DeliberateAny } from '../../types/DelibrateAny';
import Input from '../form/Input/Input';
import RegionSelectErrorWrapper from '../form/RegionSelect/RegionSelectErrorWrapper';
import Select from '../form/Select/Select';

const LocationInfoSelect = ({
  userLoggedIn,
  otherPlacement,
  regionNumber,
  establishment,
  building,
  location,
  errors,
  updateFormHolder,
  regionOptions,
  establishmentOptions,
  buildingOptions,
  updateWhenRegionChange,
  updateWhenEstablishmentChange,
  updateWhenBuildingChange,
  establishmentsWait,
  buildingsWait,
  loading,
  datatestIdPrefix,
}: DeliberateAny) => {
  if (userLoggedIn) {
    const isBuildingWaitIncluded = buildingOptions
      ? buildingOptions
          .map((b: DeliberateAny) => b.code)
          .includes(
            buildingsWait && buildingsWait[0] ? buildingsWait[0].code : "---"
          )
      : false;

    const isEstablishmentWaitIncluded = establishmentOptions
      ? establishmentOptions
          .map((e: DeliberateAny) => e.key)
          .includes(
            establishmentsWait && establishmentsWait[0]
              ? establishmentsWait[0].key
              : "---"
          )
      : false;

    const removeSessionBuilding = () => sessionStorage.removeItem("building");

    return (
      <Fragment>
        {otherPlacement && (
          <Fragment>
            <div className="row margin" id="error_region">
              <RegionSelectErrorWrapper
                options={regionOptions}
                onChange={(n, v) => {
                  removeSessionBuilding();
                  updateWhenRegionChange(n, v);
                }}
                value={regionNumber}
                errors={errors}
                name="region"
                includeShowAll={false}
                autoSelectFirstRegion={false}
                defaultChoice="Velg distrikt"
                title="Distrikt"
                data-testid={`${datatestIdPrefix}-region`}
              />
            </div>
            <div className="row margin" id="error_establishment">
              <Select
                onChange={updateWhenEstablishmentChange}
                options={
                  regionNumber &&
                  (isEstablishmentWaitIncluded || !establishmentsWait) &&
                  establishmentOptions
                    ? establishmentOptions
                    : establishmentsWait
                }
                errors={errors}
                loading={!establishmentsWait && loading.establishment}
                value={establishment}
                dependentOn="distrikt"
                name="establishment"
                label="Etablissement"
                defaultChoice="Velg etablissement"
                data-testid={`${datatestIdPrefix}-establishment`}
              />
            </div>
            <div className="row margin" id="error_building">
              <Select
                errors={errors}
                onChange={updateWhenBuildingChange}
                options={
                  establishment > 0 &&
                  (isBuildingWaitIncluded || !buildingsWait) &&
                  buildingOptions
                    ? buildingOptions.map((item: DeliberateAny) => ({
                        key: item.code,
                        value: item.value,
                      }))
                    : buildingsWait
                }
                loading={!buildingsWait && loading.building}
                value={building}
                dependentOn="etablissement"
                name="building"
                label="Bygning/Uteareal (valgfritt)"
                defaultChoice="Velg bygning/uteareal"
                data-testid={`${datatestIdPrefix}-building`}
              />
            </div>
          </Fragment>
        )}

        <div className="row margin">
          <Input
            errors={errors}
            name="location"
            placeholder="Eksempel: Skivebua til venstre for flaggstanga"
            type="text"
            value={location}
            onChange={(name, val) => updateFormHolder(name, val)}
            title="Lokasjon (valgfritt)"
          />
        </div>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <div className="row margin" id="error_location">
        <Input
          errors={errors}
          type="text"
          name="location"
          value={location}
          onChange={(name, val) => updateFormHolder(name, val)}
          title="Leir eller boligadresse"
          autoComplete="noauto"
        />
      </div>
      <div className="row margin" id="error_building">
        <Input
          errors={errors}
          type="text"
          name="building"
          value={building}
          onChange={(name, val) => updateFormHolder(name, val)}
          title="Bygning/bygningsnummer"
        />
      </div>
    </Fragment>
  );
};

LocationInfoSelect.propTypes = {
  userLoggedIn: PropTypes.bool,
  otherPlacement: PropTypes.bool,
  regionNumber: PropTypes.any,
  establishment: PropTypes.any,
  building: PropTypes.any,
  location: PropTypes.string,
  errors: PropTypes.any,
  formHolder: PropTypes.any,
  regionOptions: PropTypes.any,
  establishmentOptions: PropTypes.array,
  buildingOptions: PropTypes.array,
  updateFormHolder: PropTypes.func,
  updateWhenRegionChange: PropTypes.func,
  updateWhenEstablishmentChange: PropTypes.func,
  updateWhenBuildingChange: PropTypes.func,
  establishmentsWait: PropTypes.array,
  buildingsWait: PropTypes.array,
  loading: PropTypes.object,
  datatestIdPrefix: PropTypes.string,
};

export default LocationInfoSelect;
