import './Payment.scss';

import { isValidPaymentUrl } from 'helpers/url';
import useQuery from 'hooks/useQuery';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Payment = () => {
  const paymentLoading = useRef(false);

  const addIFrameToBody = (url: string) => {
    const div = document.createElement('div');
    div.id = 'payment_form_container';

    const container = document.createElement('div');
    container.classList.add('payment-form-container');
    container.style.display = 'none';

    const cancelButton = document.createElement('span');
    cancelButton.classList.add('cancel-button');

    const script = document.createElement('script');
    script.src = url ?? '';

    // Mutation observer to monitor when iframe from script is added
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLIFrameElement) {
            // Show the container once the iframe is added
            node.onload = () => {
              container.style.display = 'flex';
              observer.disconnect(); // Stop observing once the iframe is finished loading
            };
          }
        });
      });
    });

    // Start observing changes within the container
    observer.observe(container, { childList: true, subtree: true });

    div.appendChild(container);
    container.appendChild(cancelButton);
    container.appendChild(script);

    cancelButton.onclick = () => {
      window.location.href = '/';
      div.remove();
      //5mar7
    };

    document.body.appendChild(div);
  };

  const { source } = useQuery();
  const nav = useNavigate();

  useEffect(() => {
    if (!paymentLoading.current) {
      paymentLoading.current = true;
      if (!isValidPaymentUrl(source)) nav('/');
      else addIFrameToBody(source);
    }
  }, [source]);

  return (
    <div className="fb-payment">
      {isValidPaymentUrl(source) ? null : <div>Laster...</div>}
    </div>
  );
};

export default Payment;
