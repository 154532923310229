/* eslint react/prop-types: 0 */

import './WidgetWasteRecycling.scss';

import { fetchStaticFilterOptions } from "actions/optionsActions";
import Select from "components/form/Select/Select";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartsMore from "highcharts/highcharts-more";
import React, { useEffect, useRef, useState } from "react";
import ReactImageGallery from "react-image-gallery";
import { connect } from "react-redux";
import { DeliberateAny } from "types/DelibrateAny";

import { depositDataURL, establishmentsURL } from '../../../../api/basenproff/basenproff_api';
import { axiosFetch } from '../../../../hooks/useAxios';

HighChartsMore(Highcharts);

const WidgetWasteRecycling = ({
  deleteModule,
  saveModule,
  id,
  marketingArea,
  establishmentNumber,
  regions,
}) => {
  const [wasteRecyclingName, setWasteRecyclingName] = useState();
  const [wasteRecyclingInfo, setWasteRecyclingInfo] =
    useState<DeliberateAny[]>();
  const [widgetSettings, setWidgetSettings] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(marketingArea);
  const [establishmentList, setEstablishmentList] = useState<DeliberateAny>();
  const [selectedEstablishment, setSelectedEstablishment] =
    useState<DeliberateAny>(establishmentNumber);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!selectedRegion && !selectedEstablishment) {
      setWidgetSettings(true);
    }

    if (!regions) {
      fetchStaticFilterOptions();
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (selectedRegion) {
      if (selectedRegion !== marketingArea) {
        setSelectedEstablishment(undefined);
        setEstablishmentList(undefined);
      }
      axiosFetch({
        url: establishmentsURL,
        data: {
          MarketingArea: selectedRegion,
        },
      }).then((response) => {
        if (isSubscribed) {
          setEstablishmentList(
            response.map((est) => ({ key: est.Key, value: est.Value }))
          );
        }
      });
    }
     
    return () => {
      isSubscribed = false;
    };
  }, [selectedRegion]);

  useEffect(() => {
    let isSubscribed = true;
    if (
      selectedRegion &&
      selectedEstablishment &&
      regions &&
      establishmentList
    ) {
      const region = regions.find(
        (el) => el.code === selectedRegion.toString()
      );
      const establishment = establishmentList.find(
        (el) => el.key === selectedEstablishment.toString()
      );

      if (region && establishment) {
        axiosFetch({
          url: `${depositDataURL}/${region.caption}/${
            establishment.value.split(" ")[1]
          }/quarter`,
        }).then((response) => {
          if (response.length && isSubscribed) {
            setWasteRecyclingName(response[0].Establishment);
            const info: DeliberateAny[] = [];
            response[0].DepositDataPeriods.sort(
              (a, b) => a.Year - b.Year || a.QuarterNumber - b.QuarterNumber
            );
            response[0].DepositDataPeriods.forEach((period) => {
              if (!info.some((el: DeliberateAny) => el.Year === period.Year)) {
                info.push({ Year: period.Year, info: [{ ...period }] });
              } else {
                info.find((el) => el.Year === period.Year).info.push(period);
              }
            });
            setWasteRecyclingInfo(info);
            setActiveIndex(info.length - 1);
          } else {
            setWasteRecyclingInfo([]);
          }
        });
      }
    }
     
    return () => {
      isSubscribed = false;
    };
  }, [regions, establishmentList, selectedEstablishment]);

  const ref = useRef<DeliberateAny>(null);

  const renderItem = (settings: DeliberateAny) => {
    const renderOptions = {
      chart: {
        type: "columnrange",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        width: ref.current ? ref.current.offsetWidth : 0,
        height: ref.current ? ref.current.offsetHeight : 0,
        spacingBottom: 5,
        spacingLeft: 52,
        spacingRight: 52,
      },
      title: {
        text: `${wasteRecyclingName} ${settings.Year}`,
        align: "center",
        style: {
          fontSize: "12px",
        },
      },

      tooltip: {
        enabled: false,
      },

      xAxis: {
        categories: settings.info.map((el) => el.Period),
      },

      plotOptions: {
        series: {
          borderWidth: 1,
          borderColor: "#c1002b",
          showInLegend: false,
        },
      },

      yAxis: {
        title: {
          text: "Tonn",
        },
        min: 0,
      },

      credits: {
        enabled: false,
      },

      series: [
        {
          name: wasteRecyclingName,
          _colorIndex: 0,
          dataLabels: {
            color: "white",
            enabled: true,
            format: "{point.label}",
            style: {
              fontSize: "12px",
              color: "white",
              textOutline: "0px white",
            },
          },
          data: settings.info.flatMap((el, index) => [
            {
              color: "#c1002b",
              high: (el.Volume * el.SeparationPercentage) / 100,
              label: `${el.SeparationPercentage}%`,
              low: 0,
              x: index,
            },
            {
              color: "white",
              high: el.Volume,
              label: " ",
              low: (el.Volume * el.SeparationPercentage) / 100,
              x: index,
            },
          ]),
        },
      ],
    };

    return <HighchartsReact options={renderOptions} highcharts={Highcharts} />;
  };

  const handleSave = () => {
    setWidgetSettings(false);
    saveModule(id, {
      MarketingArea: selectedRegion,
      EstablishmentNumber: selectedEstablishment,
    });
  };

  return (
    <div
      className={`widget kvartalet carousel${
        widgetSettings ? " show-settings" : ""
      }`}
    >
      <div className="widget-wrapper">
        <div className="widget-inner-wrapper">
          <div className="header-wrapper">
            <header>
              <button
                className="btn-widget-settings"
                onClick={() => setWidgetSettings(!widgetSettings)}
              />
              <h3>Sorteringsgrad avfall</h3>
            </header>
            <div className="widget-settings-wrapper">
              <div className="widget-settings">
                <div className="widget-specific-settings margin">
                  <span>Velg distrikt</span>
                  <Select
                    name="region"
                    options={
                      regions &&
                      regions.map((region) => ({
                        key: region.code,
                        value: region.caption,
                      }))
                    }
                    value={selectedRegion}
                    onChange={(name, value) => setSelectedRegion(value)}
                    defaultChoice="Velg distrikt"
                    loading={!regions}
                  />
                  <span>Velg etablissement</span>
                  <Select
                    name="establishment"
                    options={establishmentList}
                    onChange={(name, value) => setSelectedEstablishment(value)}
                    value={selectedEstablishment}
                    defaultChoice={
                      selectedRegion > 0
                        ? "Velg etablissement"
                        : "Velg distrikt først"
                    }
                    loading={!establishmentList && selectedRegion}
                    dependentOn="distrikt først"
                  />
                </div>

                <button className="btn white" onClick={() => deleteModule(id)}>
                  Fjern Modul
                </button>
                <button
                  className="btn white align-right"
                  onClick={() => handleSave()}
                >
                  Lagre
                </button>
              </div>
            </div>
            <div
              className={`widget-content-container${
                !wasteRecyclingInfo ? " widget-loading" : ""
              }`}
            >
              <div className="widget-loader" />
              <div className="widget-content" ref={ref}>
                {wasteRecyclingInfo && wasteRecyclingInfo.length ? (
                  <ReactImageGallery
                    items={wasteRecyclingInfo.map((el: DeliberateAny) => ({
                      original: el.image,
                      renderItem: () => renderItem(el),
                    }))}
                    showPlayButton={false}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    startIndex={activeIndex}
                    onSlide={(index) => setActiveIndex(index)}
                    infinite={false}
                  />
                ) : (
                  <p className="widget-no-data">
                    Fant ingen data for de valgte innstillingene
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state: DeliberateAny) => ({
    regions: state.options.staticFilterOptions.marketingAreas,
    orderStates: state.options.serviceOrderStates,
  }),
  { fetchStaticFilterOptions }
)(WidgetWasteRecycling);
