import { codeURL, loginURL } from 'api/cms/cms_api';
import Spinner from 'components/Spinner/Spinner';
import { useLogin, useUser } from 'contexts/index';
import { LoginPath } from 'contexts/LoginContext';
import DOMPurify from 'dompurify';
import { isHtml } from 'helpers/html';
import useAxios from 'hooks/useAxios';
import useToken from 'hooks/useToken';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';

import CloseButton from './CloseButton';

const Login = () => {
  const { setToken } = useToken();
  const navigate = useNavigate();
  const { sendRequest, requestLoading: loading, requestError } = useAxios();
  const { setPath, setShow, path, loginUrl } = useLogin();
  const { fetchUser } = useUser();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');

  const validate = (): boolean => {
    if (!username || !password) {
      return false;
    }
    return true;
  };

  const handleLoginResponse = (response: DeliberateAny) => {
    setCode(response.TwoFactKey || '');
    setPath(LoginPath.code);
  };

  const handleLoggedInResponse = async (response: DeliberateAny) => {
    if (response) {
      if (response.authToken) setToken(response.authToken);
      await fetchUser();
      setPath(null);
      setShow(false);

      if (loginUrl) {
        const returnUrl = `${loginUrl}`;
        const hash = !returnUrl.startsWith('/');

        if (hash) navigate('/' + returnUrl);
        else window.location.href = window.location.origin + loginUrl;
      }
    }
  };

  const handleLogin = async () => {
    if (!validate()) return;

    await sendRequest(
      {
        method: 'POST',
        url: loginURL,
        data: { UserName: username, Password: password },
        withCredentials: true,
      },
      handleLoginResponse,
    );
  };

  const handleCode = async () => {
    await sendRequest(
      {
        method: 'POST',
        url: codeURL,
        data: {
          UserName: username,
          Password: password,
          TwoFactKey: code,
        },
      },
      handleLoggedInResponse,
    );
  };

  const handleLoginOnEnter = (key: string) => {
    if (key === 'Enter') {
      handleLogin();
    }
  };

  const handleCodeOnEnter = (key: string) => {
    if (key === 'Enter') {
      handleCode();
    }
  };

  return (
    <>
      {(!path || path === LoginPath.login) && (
        <div className="flip-card-front scrollable">
          <CloseButton />
          <h2>Logg inn</h2>
          <div className="form">
            <div className="row margin">
              <label>
                <span>Brukernavn</span>
                <input
                  type="text"
                  id="username"
                  name="username"
                  autoFocus
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onKeyDown={(keyItem) => handleLoginOnEnter(keyItem.key)}
                />
              </label>
            </div>
            <div className="row margin">
              <label htmlFor="password">
                <span>Passord</span>
                <input
                  type="password"
                  className=""
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(keyItem) => handleLoginOnEnter(keyItem.key)}
                />
              </label>
            </div>
            <div className="invalid">
              {isHtml(requestError) ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(requestError),
                  }}
                />
              ) : (
                <p>{requestError}</p>
              )}
            </div>

            <div className="flex-row">
              <div>
                <button
                  onClick={handleLogin}
                  className="btn red"
                  style={{
                    display: 'flex',
                    height: 44,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {loading ? <Spinner white /> : 'Logg inn'}
                </button>
              </div>
              <div className="login-links">
                <a
                  data-show-flip="new-user"
                  className="js-flip underlined btn-new-user pointer"
                  onClick={() => setPath(LoginPath.register)}
                >
                  Ny bruker
                </a>

                <a
                  className="js-flip underlined pointer"
                  data-show-flip="reset-password"
                  href="#"
                  onClick={() => setPath(LoginPath.forgot)}
                >
                  Glemt passord
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      {path === LoginPath.code && (
        <div className="flip-card-front scrollable">
          <CloseButton />
          <h2>Logg inn - steg 2</h2>

          <div className="form">
            <div className="row margin">
              <label>
                <span>Engangskode</span>
                <input
                  type="number"
                  id="twofactkey"
                  name="twofactkey"
                  autoFocus
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  onKeyDown={(keyItem) => handleCodeOnEnter(keyItem.key)}
                />
              </label>
            </div>
            <div className="invalid">
              <p>{requestError}</p>
            </div>
            <div className="flex-row margin">
              <div>
                <button
                  onClick={handleCode}
                  className="btn red"
                  style={{
                    display: 'flex',
                    height: 44,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {loading ? <Spinner white /> : 'Verifiser'}
                </button>
              </div>
            </div>
            <p>
              Om du ikke har fått tilsendt engangskode på sms eller e-post i
              løpet av noen sekunder,{' '}
              <a href="#/contact">kontakt Forsvarsbygg servicesenter</a> for å
              verifisere om du har riktig mobilnummer og e-postadresse på Min
              side.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
