import './Privacy.scss';

import env from 'src/environments';

const Privacy = () => {
  return (
    <iframe className="privacy" src={`${env.BASE_URL}/basen/personvern`} />
  );
};

export default Privacy;
