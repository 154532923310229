import moment from 'moment';
import connect from 'helpers/connectWithRouter';
import { sendServiceOrder } from 'actions/serviceOrdersActions';
import { PROPERTY_SERVICE_ORDER_TYPE } from 'helpers/serviceOrderHelpers';
import type { RentalItemType } from 'types/myRentalsTypes';
import type { SendServiceOrderType } from 'types/serviceOrderTypes';
import SendServiceOrderModal from '../SendServiceOrderModal/SendServiceOrderModal';
import { DeliberateAny } from 'types/DelibrateAny';
import { registerShortRentalRenewalServiceOrder } from 'api/basenproff/basenproff_api';

type PropsType = {
  path: string;
  item: RentalItemType;
  serviceOrder: SendServiceOrderType;
  sendServiceOrder: (serviceOrder: SendServiceOrderType) => void;
  onClose: () => void;
  description: string;
};

const toLabel = 'Jeg ønsker å forlenge kontrakten til';

const editDescription = (serviceOrder?: SendServiceOrderType) => {
  if (serviceOrder) {
    const date = serviceOrder.toDate
      ? serviceOrder.toDate.format('DD.MM.YYYY')
      : '';
    const comment = serviceOrder.description ? serviceOrder.description : '';
    return `${toLabel} ${date}\nBegrunnelse: ${comment}`;
  }

  return '';
};

const preSendServiceOrder = (props: PropsType) => {
  if (props?.serviceOrder)
    props.serviceOrder.description = editDescription(props.serviceOrder);
};

const UnconnectedResignationServiceOrderModal = (props: PropsType) => (
  <SendServiceOrderModal
    title="Søknad om forlengelse"
    fields={{
      title: {
        value: `Søknad om forlengelse for «${props.item.RentalId}»`,
        disabled: true,
      },
      rentalId: { value: props.item.RentalId, disabled: true },
      description: { label: 'Begrunnelse' },
      toDate: {
        value: moment(props.item.RentContractToDate),
        label: toLabel,
        minDate: moment(props.item.RentContractToDate),
        maxDate: moment(props.item.RentContractToDate).add(3, 'months'),
      },
      serviceOrderType: { value: PROPERTY_SERVICE_ORDER_TYPE },
      keyCmObj: { value: props.item.KeyCmObj },
      regionNumber: { value: props.item.RegionNumber },
      housingArea: { value: props.item.HousingArea },
      isShortRenewal: { value: true },
    }}
    preSendServiceOrder={() => preSendServiceOrder(props)}
    url={registerShortRentalRenewalServiceOrder}
    {...props}
  />
);

export default connect(
  (state: DeliberateAny) => ({
    serviceOrder: state.serviceOrders.serviceOrder,
  }),
  { sendServiceOrder },
)(UnconnectedResignationServiceOrderModal);
