import { setHousingArea, updateApplication } from 'actions/housingActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import ListItem from 'components/ListItem/ListItem';
import ExtendedApplicationServiceOrderModal from 'components/serviceorders/ExtendedApplicationServiceOrderModal/ExtendedApplicationServiceOrderModal';
import RentalTerminationModal from 'components/serviceorders/RentalTerminationModal/RentalTerminationModal';
import connect from 'helpers/connectWithRouter';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropNavigation } from 'types/PropNavigationBase';

import rentalColumns from '../RentalColumns';
import RentalDocumentsList from './RentalDocumentsList/RentalDocumentsList';
import {
  CeasedDescription,
  ExtendedApplicationChoiceModal,
  RelevantDocumentsAndLinks,
  RenewalDescription,
  SendNewKeyModal,
} from './RentalItemComponents';
import RentalStatusbarBox from './RentalStatusbarBox/RentalStatusbarBox';

import type { ServerLinkType } from 'types/commonTypes';
import type { ListColumnType } from 'types/listTypes';
import type {
  RentalItemType,
  RentalContractTextsType,
} from 'types/myRentalsTypes';
import type { HousingAreaType } from 'types/housingTypes';

export type RentalItemPropsType = PropNavigation & {
  item: RentalItemType;
  userData: DeliberateAny;
  columns: Array<ListColumnType>;
  rentalContractTexts: RentalContractTextsType;
  documentLinks: Array<ServerLinkType>;
  updateApplication: (
    applicationType: string,
    key: string,
    value: string | boolean,
  ) => void;
  mountAsExpanded?: boolean;
  housingAreas: Array<HousingAreaType>;
  setHousingArea: (name: string, value: string) => void;
};

const housingSpecificDetails = [
  { label: 'Størrelse', suffix: 'm²', name: 'RentedArea' },
];

const quarterSpecificDetails = [{ label: 'Romnummer', name: 'RoomNumber' }];

export const CONTRACT_TYPE_HOUSING = 0;

export const UnconnectedRentalItem: FC<RentalItemPropsType> = (props) => {
  const rentalItemPath = () =>
    `${props.location.pathname}/${props.item.RentalId}`;

  const shortExtendedApplicationPath = () =>
    `${props.item.RentalId}/kort-forlengelse`;

  const extendedApplicationChoiceModal = () => {
    return `${props.item.RentalId}/søk-om-forlengelse`;
  };

  const terminationModalPath = () => `${props.item.RentalId}/si-opp-kontrakt`;
  const serviceOrderModalPath = () => ({
    pathname: `/reporting/faultform/Leieforhold/${props.item.RentalId}`,
  });

  useEffect(() => {
    const applicationType =
      props.item.ContractType === CONTRACT_TYPE_HOUSING ? 'housing' : 'quarter';

    props.updateApplication(applicationType, 'isRenewal', false);
  }, []);

  const [extendedApplicationLink, setExtendedApplicationLink] = useState(
    shortExtendedApplicationPath(),
  );
  const [_, setSendNewKeyModalIsOpen] = useState(false);

  const rentalDetails = [
    { label: 'Etablissementnavn', name: 'EstablishmentName' },
    { label: 'Sone', name: 'Zone' },
    { label: 'Leilighetstype', name: 'ApartmentType' },
    {
      label: props.rentalContractTexts.RentText
        ? props.rentalContractTexts.RentText
        : 'Pris per måned',
      suffix: 'kr',
      name: 'RentPerMonth',
    },
    {
      label: props.rentalContractTexts.CompensationText
        ? props.rentalContractTexts.CompensationText
        : 'Kompensasjon',
      suffix: 'kr',
      name: 'CompensationPerMonth',
    },
    { label: 'Kompensasjon', suffix: 'kr', name: 'CompensationPerMonth' },
    { label: 'Leiekontrakt løper fra', name: 'RentContractStartDate' },
    { label: 'Leiekontrakt løper til', name: 'RentContractToDate' },
  ];

  const getDetails = (item: RentalItemType) => {
    let miniDetails: DeliberateAny;
    if (item.CompensationPerMonth === 0) {
      // Vi må fjerne fra eksisterende da rekkefølgen på radene er viktig.
      // Kan ikke pushe da denne skal inn etter RentPerMonth
      miniDetails = rentalDetails.filter(
        (e) => e.name !== 'CompensationPerMonth',
      );
    } else {
      miniDetails = rentalDetails;
    }
    return item.ContractType === CONTRACT_TYPE_HOUSING
      ? [...rentalColumns, ...housingSpecificDetails, ...miniDetails]
      : [...rentalColumns, ...quarterSpecificDetails, ...miniDetails];
  };

  const contractToDateInOverAMonth = () =>
    moment(props.item.RentContractToDate).isAfter(moment().add(30, 'days'));

  const contractStartDateIsHere = () =>
    moment().isAfter(
      moment(props.item.RentContractStartDate).subtract(1, 'days'),
    );

  const showExtendedBtn = () =>
    !props.item.HasRenewalApplication &&
    !props.item.IsTerminated &&
    !props.item.IsCeased &&
    contractToDateInOverAMonth();

  const longExtendedApplicationPath = () => {
    const applicationType =
      props.item.ContractType === CONTRACT_TYPE_HOUSING ? 'bolig' : 'kvarter';
    return `/forsvarsbolig/${applicationType}-målgruppe`;
  };

  const showResignationBtn = () =>
    !props.item.IsTerminated &&
    !props.item.IsCeased &&
    contractToDateInOverAMonth() &&
    contractStartDateIsHere();

  const handleRedirect = (path: string) => {
    if (path === longExtendedApplicationPath()) {
      const applicationType =
        props.item.ContractType === CONTRACT_TYPE_HOUSING
          ? 'housing'
          : 'quarter';
      props.updateApplication(applicationType, 'rentalId', props.item.RentalId);
      props.updateApplication(applicationType, 'isRenewal', true);
      const renewalArea = props.housingAreas.filter(
        (area) => area.value === props.item.HousingArea,
      );

      if (renewalArea && renewalArea.length > 0) {
        props.updateApplication(
          applicationType,
          'housingAreaId',
          renewalArea[0].key,
        );
        props.setHousingArea(renewalArea[0].name, renewalArea[0].key);
      }
    }
    sessionStorage.setItem('rentalId', props.item.RentalId);

    props.navigate(path);
  };

  const changeExtendedApplicationType = (
    name: string,
    selectValue: string | boolean | number,
  ) => {
    setExtendedApplicationLink(selectValue.toString());
  };

  const renderExtendedDetails = () => (
    <>
      <RentalStatusbarBox item={props.item} />
      {props.item.Documents.length > 0 && (
        <RentalDocumentsList
          rentalId={props.item.RentalId}
          documents={props.item.Documents}
        />
      )}

      {props.item.IsCeased && <CeasedDescription {...props} />}
      {props.item.HasRenewalApplication && <RenewalDescription />}
      <RelevantDocumentsAndLinks {...props} />

      <ExtendedApplicationChoiceModal
        {...props}
        path={extendedApplicationChoiceModal()}
        onSubmit={() => handleRedirect(extendedApplicationLink)}
        selectedExtendedApplicationLink={extendedApplicationLink}
        longExtendedApplicationPath={longExtendedApplicationPath()}
        shortExtendedApplicationPath={shortExtendedApplicationPath()}
        changeExtendedApplicationType={changeExtendedApplicationType}
      />

      <ExtendedApplicationServiceOrderModal
        path={shortExtendedApplicationPath()}
        description={props.rentalContractTexts.KortForlengelseBeskrivelse}
        item={props.item}
        onClose={() => handleRedirect(rentalItemPath())}
      />

      <RentalTerminationModal
        path={terminationModalPath()}
        item={props.item}
        contractType={
          props.item.ContractType === CONTRACT_TYPE_HOUSING
            ? 'Housing'
            : 'Quarter'
        }
      />

      <ButtonRow>
        {props.item.ShowNewKeyButton && (
          <ButtonRow.RightOther>
            <SendNewKeyModal
              setSendNewKeyModalIsOpen={setSendNewKeyModalIsOpen}
              booking={props.item}
            />
          </ButtonRow.RightOther>
        )}
        {showExtendedBtn() && (
          <ButtonRow.Right
            key="2"
            onClick={() => handleRedirect(extendedApplicationChoiceModal())}
          >
            Søk om forlengelse
          </ButtonRow.Right>
        )}

        {showResignationBtn() && (
          <ButtonRow.Right
            key="3"
            onClick={() => handleRedirect(terminationModalPath())}
          >
            Si opp kontrakt
          </ButtonRow.Right>
        )}
        <ButtonRow.Right
          key="4"
          onClick={() => handleRedirect(serviceOrderModalPath().pathname)}
        >
          Meld inn feil og mangler
        </ButtonRow.Right>
      </ButtonRow>
    </>
  );

  return (
    <ListItem
      item={props.item}
      columns={props.columns}
      details={getDetails(props.item)}
      extendedDetailsContainer={renderExtendedDetails()}
      mountAsExpanded={props.item.expandMe || props.mountAsExpanded}
      minimalDetails
      passStylingToList={{ styleInHalf: true }}
    />
  );
};

export default connect(
  (state: DeliberateAny, props: RentalItemPropsType) => ({
    rentalContractTexts: state.myRentals.rentalContractTexts,
    documentLinks:
      state.myRentals.documentLinks[
        `${
          props.item.ContractType === CONTRACT_TYPE_HOUSING
            ? 'Housing'
            : 'Quarter'
        }DocumentLinks`
      ],
    housingAreas: state.housing.housingAreas,
  }),
  (dispatch, props) =>
    bindActionCreators(
      {
        updateApplication,
        setHousingArea: (name, value) =>
          setHousingArea(
            props.item.ContractType === CONTRACT_TYPE_HOUSING
              ? 'housing'
              : 'quarter',
            value,
          ),
      },
      dispatch,
    ),
)(UnconnectedRentalItem);
