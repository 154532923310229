import {
  bestillURL,
  betalingsMidlerForBrukerURL,
  kundenummerURL,
} from 'api/booking/booking_api';
import { orgnrBRlookup, postalCodeLookup } from 'api/core/core_api';
import Button from 'components/buttons/Button/Button';
import Input from 'components/form/Input/Input';
import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import Select from 'components/form/Select/Select';
import IosPadding from 'components/Ios/IosPadding';
import UserNotificationSmsCheckbox from 'components/UserNotificationPreference/UserNotificationSmsCheckbox';
import * as bookingConstants from 'constants/bookingEnums';
import { ModalTypes } from 'constants/modalTypes';
import { useModal } from 'contexts/index';
import FBHelpers from 'helpers/_helpers';
import { getTotalPrice } from 'helpers/bookingHelpers';
import { axiosFetch } from 'hooks/useAxios';
import verifone_logo from 'images/verifone_logo.svg';
import vipps_logo from 'images/vipps_logo.svg';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';

import BookingSummary from './BookingSummary';

const PaymentInfo = ({
  selectedLocation,
  arrivalDate,
  departureDate,
  roomInfoPerRoomType,
  numberOfGuests,
  parentBooking,
  isBlockBooking,
  item,
  setItem,
  setMarkup,
  roomInfo,
  numberOfDaysToFocePayment,
  userData,
}) => {
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState('Bestill');
  const [reason, setReason] = useState<DeliberateAny>();
  const [description, setDescription] = useState('');
  const [bookingName, setBookingName] = useState('');
  const [specialRequest, setSpecialRequest] = useState('');
  const [whoPays, setWhoPays] = useState<DeliberateAny>(-1);
  const [IONumber, setIONumber] = useState('');
  const [customerNumbers, setCustomerNumbers] = useState<DeliberateAny[]>([]);
  const [selectedCustomerNumber, setSelectedCustomerNumber] =
    useState<DeliberateAny>({
      key: -1,
      value: 'Velg avdeling',
    });
  const [organisationNumber, setOrganisationNumber] = useState('');
  const [businessName, setBusinessName] = useState({
    value: '',
    placeholder: 'Hentes automatisk fra Org. nr',
  });
  const [billingZipCode, setBillingZipCode] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [invoiceManager, setInvoiceManager] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [receiveSMS, setReceiveSMS] = useState(false);
  const [validation, setValidation] = useState({
    zipCode: true,
    city: true,
    reason: true,
    whoPays: true,
    description: true,
    IONumber: true,
    selectedCustomerNumber: true,
    organisationNumber: true,
    businessName: true,
    address: true,
    bookingName: true,
  });
  const [errors, setErrors] = useState<DeliberateAny>([]);
  const [paymentOptions, setPayentOptions] = useState<DeliberateAny[]>([]);
  const [availablePaymentMethods, setAvailablePaymentMethods] =
    useState<DeliberateAny>({});
  const { showModal, close } = useModal();

  useEffect(() => {
    if (selectedLocation) {
      document.getElementById('nav')?.scrollIntoView();
      axiosFetch({ url: kundenummerURL }).then((res) => {
        // TODO : Find out what page is missing
        if (res?.map)
          setCustomerNumbers(
            res?.map((cusNo) => ({
              key: cusNo.Number,
              value: cusNo.Department,
            })) ?? [],
          );
      });
    } else {
      navigate('/booking');
    }
  }, []);

  useEffect(() => {
    const getPaymentData = async () => {
      const options = await axiosFetch({ url: betalingsMidlerForBrukerURL });
      setAvailablePaymentMethods(options);
      const array: DeliberateAny[] = [];
      Object.entries(options).forEach((o) => {
        const key = o[0];
        const value = o[1];
        switch (key) {
          case 'CreditCard':
            if (value)
              array.push({
                label: (
                  <span>
                    Online betaling hos{' '}
                    <img
                      src={verifone_logo}
                      alt=""
                      style={{
                        width: '100px',
                        verticalAlign: 'text-bottom',
                        display: 'inline',
                      }}
                    />
                  </span>
                ),
                value: 0,
              });
            break;
          case 'InvoiceDefence':
            if (value) {
              array.push({
                label: 'Faktura til Forsvaret med IO-nummer',
                value: 1,
              });
            }
            break;
          case 'InvoiceOtherCompanies':
            if (value) {
              array.push({
                label: 'Faktura til andre virksomheter, (ikke privatpersoner)',
                value: 2,
              });
            }
            break;
          default:
            break;
        }
      });
      setPayentOptions(array);
    };

    getPaymentData();
  }, []);

  const isForcedPayment =
    moment(moment(item.arrivalDate).format('YYYY-MM-DD')).diff(
      moment().format('YYYY-MM-DD'),
      'days',
    ) <= numberOfDaysToFocePayment;

  const renderDescription = () => (
    <div className="sub-booking-only margin">
      <h2>Bestilling: {parentBooking.GroupInfo.EventName}</h2>
      <p className="margin">{parentBooking.GroupInfo.Reason}</p>
    </div>
  );

  const renderReason = () => {
    const reasonArr = [
      { key: 1, value: 'Øvelse' },
      { key: 2, value: 'Kurs' },
      { key: 3, value: 'Tjenestereise' },
      { key: 4, value: 'Annet' },
    ];
    return (
      <div className="hide-for-sub-booking">
        <h2>Formål</h2>
        <div className="row margin">
          <div className="content-group padded grey margin">
            <Select
              name="Reason"
              errors={errors}
              label="Oppholdets formål"
              options={reasonArr}
              defaultChoice="Velg"
              value={reason}
              onChange={(name, value) => setReason(value)}
              className="margin"
            />
            {reason === 4 && (
              <p className="italic js-priority-message">
                Bestiller du rom til private formål risikerer du å miste rommet.
                Hvis noen på tjenestereise trenger overnatting vil de bli
                prioritert. Har du ikke fått beskjed om dette innen en måned før
                ankomst, er rommet ditt.
              </p>
            )}
            {isBlockBooking && (
              <div className="margin">
                <Input
                  title="Navn på bestilling"
                  errors={errors}
                  type="text"
                  value={bookingName}
                  name="BookingName"
                  onChange={(name, value) => setBookingName(value)}
                />
              </div>
            )}
            <Input
              title={
                reason === 2 || reason === 4
                  ? 'Beskrivelse - inntil 35 tegn (Kun ugradert)'
                  : 'Beskrivelse - inntil 35 tegn (valgfritt - Kun ugradert)'
              }
              errors={errors}
              type="text"
              value={description}
              name="Description"
              onChange={(name, value) => setDescription(value)}
              maxLength={35}
              placeholder="Kurs: Krig og etikk"
            />
            {isBlockBooking && (
              <div style={{ marginTop: '22px' }}>
                <Input
                  title="Ønsker og tilpasninge"
                  type="text"
                  value={specialRequest}
                  name="SpecialRequest"
                  placeholder="Eks. Alle deltakerne i samme kaserne"
                  onChange={(name, value) => setSpecialRequest(value)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderPaymentOptions = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showOrgErrorMsg, setShowOrgErrorMsg] = useState(false);

    const renderPaymentDetails = () => {
      const orgNoLookup = () => {
        axiosFetch({
          url: `${orgnrBRlookup}/${organisationNumber}`,
          method: 'GET',
        })
          .then((res) => {
            if (res.IllegalOrgNbr) {
              setBusinessName({
                placeholder: 'Ulovlig organisasjonsnummer',
                value: 'Ulovlig organisasjonsnummer',
              });
              setValidation({ ...validation, organisationNumber: false });
              setShowOrgErrorMsg(true);
            } else {
              setBusinessName({
                ...businessName,
                value: res.Name,
              });
              setValidation({ ...validation, organisationNumber: true });
              setShowOrgErrorMsg(false);
            }
          })
          .catch(() => {
            setBusinessName({
              placeholder: 'Ingen match i Brønnøysundregisteret',
              value: 'Ingen match i Brønnøysundregisteret',
            });
            setShowOrgErrorMsg(false);
            setValidation({ ...validation, organisationNumber: false });
          });
      };

      const zipCodeLookup = () => {
        axiosFetch({
          url: postalCodeLookup,
          data: { postalCode: billingZipCode },
          method: 'GET',
        })
          .then((res) => {
            setBillingCity(res.PostalLocation);
          })
          .catch((res) => {
            setBillingCity(res.response.data.Text);
          });
      };

      if (whoPays === 0) {
        return (
          <div className="content-group padded grey margin">
            <p className="half-margin">
              Betalingsmåte:{' '}
              <strong>
                Betal med kort
                {availablePaymentMethods && availablePaymentMethods.Vipps && (
                  <span>
                    {' '}
                    eller
                    <img
                      src={vipps_logo}
                      alt=""
                      style={{
                        width: '100px',
                        verticalAlign: 'middle',
                        display: 'inline',
                      }}
                    />
                  </span>
                )}
                hos Verifone
              </strong>
            </p>
            <p className="no-margin">
              Beløpet trekkes etter gjennomført opphold (på avreisedato).
            </p>
          </div>
        );
      } else if (whoPays === 1) {
        return (
          <div>
            <p className="margin">
              Ansvarlig bestiller: {userData.Name} <br />
              Ansattnummer: {userData.EmployeeNumber}
            </p>
            <div className="content-group padded grey margin">
              <div className="margin">
                <Input
                  title="IO - innkjøpsordrenummer"
                  type="text"
                  value={IONumber}
                  name="IONumber"
                  maxLength={10}
                  minLength={10}
                  onChange={(name, value) => setIONumber(value)}
                  errors={errors}
                />
              </div>

              <Select
                label="Avdeling som skal faktureres"
                name="CustomerNumber"
                options={customerNumbers.map((cusNo) => ({
                  key: cusNo.key,
                  value: cusNo.value,
                }))}
                value={selectedCustomerNumber.key}
                onChange={(name, value) =>
                  setSelectedCustomerNumber({ key: value.toString() })
                }
                defaultChoice="Velg avdeling"
                errors={errors}
              />
            </div>
          </div>
        );
      } else if (whoPays === 2) {
        return (
          <div className="content-group padded grey margin">
            {showOrgErrorMsg && (
              <div className="invalid no-text">
                <span>
                  Faktura til valgt organisasjonsnummer er ikke tillatt.
                  Vennligst velg et annet organisasjonsnummer eller annen
                  betalingsmåte.
                </span>
              </div>
            )}
            <div className="flex-row margin">
              <Input
                title="Org.nr"
                type="text"
                placeholder="9 siffer - Eks. 986105174"
                value={organisationNumber}
                name="OrganisationNumber"
                maxLength={9}
                minLength={9}
                onChange={(name, value) => setOrganisationNumber(value)}
                errors={errors}
                onBlur={orgNoLookup}
              />
              <Input
                title="Virksomhet"
                type="text"
                placeholder={businessName.placeholder}
                value={businessName.value}
                name="BusinessName"
                errors={errors}
                disabled
              />
              <Input
                title="Fakturaadresse"
                type="text"
                value={billingAddress}
                name="Address"
                errors={errors}
                onChange={(name, value) => setBillingAddress(value)}
              />
            </div>
            <div className="flex-row">
              <Input
                title="Postnummer"
                type="text"
                value={billingZipCode}
                name="ZipCode"
                errors={errors}
                onChange={(name, value) => setBillingZipCode(value)}
                onBlur={zipCodeLookup}
              />
              <Input
                title="Sted"
                type="text"
                placeholder="Fyll ut postnummer"
                value={billingCity}
                name="City"
                errors={errors}
                disabled
              />
              <Input
                title="Fakturaansvarlig"
                type="text"
                value={invoiceManager}
                name="InvoiceManager"
                onChange={(name, value) => setInvoiceManager(value)}
              />
            </div>
          </div>
        );
      }
      return null;
    };

    return (
      <div>
        <div
          className={`radio-container margin who-pays ${
            errors.WhoPays ? 'invalid' : ''
          } `}
        >
          <RadioButtonGroup
            options={paymentOptions}
            name="WhoPays"
            checkedOption={whoPays}
            onChange={(name, value) => setWhoPays(value)}
          />
        </div>
        {renderPaymentDetails()}
      </div>
    );
  };

  const renderBlockBookingPaymentInfo = () => {
    if (isForcedPayment) {
      return (
        <div>
          <p>
            Det er under {numberOfDaysToFocePayment} dager før ankomst. Du må
            garanteres med en betalingsmåte.
          </p>
          {renderPaymentOptions()}
        </div>
      );
    }
    return (
      <div className="row block-booking-only">
        <div className="content-group padded grey margin">
          <p className="margin">
            Du har huket av for at deltakerne skal betale selv ved hjelp av en
            referansekode. De bruker da referansekoden som opprettes til å
            bestille rom fra din reservasjon.
          </p>
          <p>
            NB. Deltakerne må ha bestilt rom innen{' '}
            <span className="red">
              {numberOfDaysToFocePayment} dager før ankomst.
            </span>
          </p>
        </div>
      </div>
    );
  };

  const handleOpenTermsAndConditionsModal = (event) => {
    event.preventDefault();

    const url = `/basen/vilkar?urlSegment=${
      selectedLocation.VBUseIntegration
        ? 'vilkarintegrert'
        : 'vilkarikkeintegrert'
    }`;
    showModal({
      url,
      selector: '.editor-content',
      size: 'medium',
    });
  };

  const handleOrder = () => {
    const getTypeOfTravel = () => {
      switch (reason) {
        case 1:
          return 'Øvelse';
        case 2:
          return 'Kurs';
        case 3:
          return 'Tjenestereise';
        case 4:
          return 'Annet';
        default:
          return 'Velg';
      }
    };

    const getWhoPays = () => {
      switch (whoPays) {
        case 0:
          return 'me';
        case 1:
          return 'defense';
        case 2:
          return 'others';
        default:
          return 'me';
      }
    };

    setButtonText('Sender...');
    const obj = { ...item };

    // GroupInfo
    if (whoPays === 1) {
      obj.GroupInfo.CustomerName = customerNumbers.find(
        (no) => no.key === selectedCustomerNumber.key,
      ).value;
      obj.GroupInfo.CustomerNumber = selectedCustomerNumber.key;
      obj.GroupInfo.IONumber = IONumber;
    } else if (whoPays === 2) {
      obj.GroupInfo.BillOwner = invoiceManager;
      obj.GroupInfo.BillingAddress = billingAddress;
      obj.GroupInfo.BillingLocation = billingCity;
      obj.GroupInfo.BillingZipCode = billingZipCode;
      obj.GroupInfo.ContactPerson = organisationNumber;
      obj.GroupInfo.CustomerName = businessName.value;
    }

    if (isBlockBooking) {
      obj.GroupInfo.EventName = bookingName;
      obj.GroupInfo.ReferenceKey = 'NYBLOKK';
      obj.GroupInfo.UserComment = specialRequest;
      obj.GroupInfo.Reason = description;
      obj.TypeOfTravel = getTypeOfTravel();
    } else if (parentBooking) {
      obj.GroupInfo.EventName = parentBooking.GroupInfo.EventName;
      obj.GroupInfo.ReferenceKey = parentBooking.GroupInfo.ReferenceKey;
      obj.ParentBookingId = parentBooking.Id;
    } else {
      obj.GroupInfo.Reason = description;
      obj.TypeOfTravel = getTypeOfTravel();
    }
    // PaymentInfo
    obj.PaymentInfo = {
      ConfirmUsingSMS: receiveSMS,
      PayByCard: whoPays === 0,
      PaymentMethod: whoPays === 0 ? 1 : 2,
      Price: getTotalPrice(roomInfo, selectedLocation.Rooms, isBlockBooking),
    };
    obj.RoomInfo.map((e) => delete e.Id);
    // måste göra nedan för att property-namnet ska vara who-pays vilket krävs av backend
    obj['who-pays'] = getWhoPays();

    setItem(obj);

    if (!isForcedPayment && isBlockBooking && whoPays === -1) {
      obj.PaymentInfo.PaymentMethod = 0;
    }

    if (isForcedPayment && isBlockBooking) {
      obj.GroupInfo.KeepRooms = true;
    }

    axiosFetch({ url: bestillURL, data: obj, method: 'POST' }, null, true)
      .then((response) => {
        if (response.Success === false) {
          if (response.DataType === 'html' || response.DataType === 'string') {
            showModal({
              title: 'Feil bestilling',
              infoText: response.Data,
            });
          }
          if (
            response.DataType === 'json' &&
            response.Data &&
            response.Data.Title &&
            response.Data.Text
          ) {
            showModal({
              title: response.Data.Title,
              text: response.Data.Text,
            });
          }
          setButtonText('Bestill');
        } else if (
          isBlockBooking &&
          isForcedPayment &&
          obj.PaymentInfo.PaymentMethod ===
            bookingConstants.PaymentMethods.PAYBYCARD
        ) {
          showModal({
            modalType: ModalTypes.CustomForm,
            title: 'Fullfør booking',
            infoText: `Det er ${numberOfDaysToFocePayment} dager eller mindre til din ankomst, du må stille med betalingsgaranti innen ${
              response.CardTimeOut
            } minutter.
            Registrer betalingsopplysninger nå, eller gjør det fra min side innen ${
              response.CardTimeOut
            } minutter.
            Beløpet er ${FBHelpers.formatCurrency(response.Amount, ' ', true)}`,
            submitText: 'Registrer betalingsopplysninger',
            submitAction: () =>
              axiosFetch(
                {
                  url: response.PayUrl,
                },
                null,
                false,
                true,
              ).then((res) => {
                if (res.data.Success) {
                  window.location.href = res.data.Data;
                } else {
                  showModal({
                    infoText: res.data.Data,
                  });
                }
              }),
            cancelText: 'Min side',
            cancelAction: () => {
              close();
              navigate('/my-page/tab-my-bookings');
            },
            flipActionOrder: true,
          });
        } else if (
          obj.PaymentInfo.PaymentMethod ===
          bookingConstants.PaymentMethods.PAYBYCARD
        ) {
          showModal({
            modalType: ModalTypes.CustomForm,
            title: 'Fullfør booking',
            infoText: `Din reservasjon må bekreftes med registrering av betalingsopplysninger innen ${
              response.CardTimeOut
            } minutter.
            Registrer nå, eller gjør det fra min side innen ${
              response.CardTimeOut
            } minutter.
            Beløpet er ${FBHelpers.formatCurrency(response.Amount, ' ', true)}`,
            submitText: 'Registrer betalingsopplysninger',
            submitAction: () =>
              axiosFetch(
                {
                  url: response.PayUrl,
                },
                null,
                false,
                true,
              ).then((res) => {
                if (res.data.Success) {
                  window.location.href = res.data.Data;
                } else {
                  showModal({
                    infoText: res.data.Data,
                  });
                }
              }),
            cancelText: 'Min side',
            cancelAction: () => navigate('/my-page/tab-my-bookings'),
            flipActionOrder: true,
          });
        } else {
          setMarkup(response);

          navigate('/booking/confirmation-info');
        }
      })
      .catch(() => {
        // If someone booked the rooms you selected while you were completing your booking
        setButtonText('Bestill');
      });
  };

  const validateFields = () => {
    // check invalid

    const errorRules = {
      WrongFormat: { rule: 'wrongFormat' },
      Required: { rule: 'required' },
      PostalCode: { rule: 'postalCode' },
    };

    const formErrors: DeliberateAny = {};
    if (!parentBooking) {
      if (!((reason as number) > 0)) formErrors.Reason = errorRules.Required;
      if (isBlockBooking && !bookingName) {
        formErrors.BookingName = errorRules.Required;
      }
      if ((reason === 2 || reason === 4) && !description) {
        formErrors.Description = errorRules.Required;
      }
    }
    if (!isBlockBooking || (isBlockBooking && isForcedPayment)) {
      if (!(whoPays > -1)) formErrors.WhoPays = errorRules.Required;

      switch (whoPays) {
        case 0:
          break;

        case 1:
          if (!IONumber) formErrors.IONumber = errorRules.Required;
          else if (
            !(
              IONumber.length === 10 &&
              (IONumber.startsWith('45') || IONumber.startsWith('47'))
            )
          ) {
            formErrors.IONumber = errorRules.WrongFormat;
          }
          if (!(selectedCustomerNumber.key > -1)) {
            formErrors.CustomerNumber = errorRules.Required;
          }
          break;

        case 2:
          if (!organisationNumber) {
            formErrors.OrganisationNumber = errorRules.Required;
          } else if (
            !(
              organisationNumber.length === 9 &&
              /^[0-9]+$/.test(organisationNumber)
            )
          ) {
            formErrors.OrganisationNumber = errorRules.WrongFormat;
          } else if (!validation.organisationNumber) {
            formErrors.OrganisationNumer = errorRules.WrongFormat;
          }
          if (!businessName.value) {
            formErrors.BusinessName = errorRules.Required;
          }
          if (!billingAddress) formErrors.Address = errorRules.Required;
          if (!billingZipCode) formErrors.ZipCode = errorRules.Required;
          else if (!/^\d{4}$/.test(billingZipCode)) {
            formErrors.ZipCode = errorRules.PostalCode;
          }
          if (!billingCity || billingCity === 'Postnummeret er ikke gyldig') {
            formErrors.City = errorRules.Required;
          }
          break;

        default:
          break;
      }
    }
    if (Object.keys(formErrors).length !== 0) {
      setErrors(formErrors);
      document.getElementById('nav')?.scrollIntoView();
    } else {
      handleOrder();
    }
  };

  return (
    <div>
      <div className="row margin hide-for-small">
        <h3>Din bestilling</h3>
        <div className="booking-summary booking-summary-top border-bottom">
          <BookingSummary
            arrivalDate={arrivalDate}
            departureDate={departureDate}
            roomInfoPerRoomType={roomInfoPerRoomType}
            numberOfGuests={numberOfGuests}
            selectedLocation={selectedLocation}
            roomInfo={roomInfo}
            isBlockBooking={isBlockBooking}
          />
        </div>
      </div>
      <div className="max-width">
        {parentBooking ? renderDescription() : renderReason()}
        <h2>Betalingsopplysninger</h2>
        {isBlockBooking
          ? renderBlockBookingPaymentInfo()
          : renderPaymentOptions()}
        <div className="row margin">
          <UserNotificationSmsCheckbox
            name="receiveSMS"
            label="Huk av her for å være trygg på å få viktige beskjeder også på SMS. Dette er spesielt viktig om du kun har 2-niva epost."
            checked={receiveSMS}
            onChange={() => setReceiveSMS(!receiveSMS)}
            notificationDomain="Booking"
          />
        </div>
        <div className="row double-margin">
          <p className="no-margin">
            Ved å trykke bestill bekrefter jeg at jeg har lest{' '}
            <Link
              to="vilkårene for tjenesten"
              onClick={(e) => handleOpenTermsAndConditionsModal(e)}
            >
              vilkårene for tjenesten
            </Link>
          </p>
        </div>
      </div>
      <div className="row double-margin max-width">
        <h2 className="border-bottom">Din bestilling</h2>
        <div className="booking-summary booking-summary-bottom">
          <BookingSummary
            arrivalDate={arrivalDate}
            departureDate={departureDate}
            roomInfoPerRoomType={roomInfoPerRoomType}
            numberOfGuests={numberOfGuests}
            selectedLocation={selectedLocation}
            roomInfo={roomInfo}
            isBlockBooking={isBlockBooking}
          />
          <div className="button-wrapper float-right">
            <Button
              color="red"
              onClick={() => validateFields()}
              disabled={
                buttonText === 'Sender...' ||
                (isBlockBooking && isForcedPayment && whoPays === -1)
              }
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
      <IosPadding />
    </div>
  );
};

export default PaymentInfo;
