// @flow

import './SignableDocumentItem.scss';

import { createSignatureJobForXpandDocument } from 'actions/signatureActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import DownloadLink from 'components/DownloadLink/DownloadLink';
import List from 'components/List/List';
import ListItem from 'components/ListItem/ListItem';
import RejectSigningModal from 'components/modals/RejectSigningModal/RejectSigningModal';
import { SIGNED_RENTAL_PROTOCOL_WARNING } from 'constants/rentalsConstants';
import {
  SIGNATURE_STATE_ERROR,
  SIGNATURE_STATE_REJECTION,
  SIGNATURE_STATE_SUCCESS,
} from 'constants/signatureConstants';
import connect from 'helpers/connectWithRouter';
import queryString from 'query-string';
import { Component } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';
import { SignatureJobXpandRequestType } from 'types/signatureTypes';

import { proffBaseUrl } from '../../../../../api/basenproff/basenproff_api';

import type { ListColumnType } from 'types/listTypes';
import type { SignableDocumentType } from 'types/signableDocumentsTypes';
import SigningModal from 'components/modals/SigningModal/SigningModal';
type PropsType = {
  item: SignableDocumentType;
  columns: Array<ListColumnType>;
  createSignatureJobForXpandDocument: (
    jobRequest: SignatureJobXpandRequestType,
  ) => string;
  location: Location;
  navigate: NavigateFunction;
};
const PORTOFOLIO_CONTRACT_SIGNATURE_CHANNEL =
  'my-portfolio:sign-rentalprotocol';

const columnDetailsHorizontal = [
  { label: 'Leietaker', name: 'TenantName', className: 'tenant-name' },
  {
    label: 'Brukende avdeling',
    name: 'BRA',
    className: 'building-type-name',
  },
  { label: 'Leie-ID', name: 'RentalId', className: 'rental-id' },
  { label: 'Leid areal', name: 'RentedArea', className: 'total-bta' },
  {
    label: 'Leid i %',
    name: 'RentedPercentage',
    renderFunction: (item: SignableDocumentType) => (
      <div>
        {item.TotalBTA
          ? `${Math.round((item.RentedArea / item.TotalBTA) * 100)} %`
          : 'N/A'}
      </div>
    ),
    className: 'total-bta-prc',
  },
  { label: 'Arealtype', name: 'AreaType', className: 'area-type' },
  {
    label: 'Status på kontrakt',
    name: 'ContractStatus',
    className: 'contract-status',
  },
];

const columnDetailsVertical = [
  { label: 'KNR/GNR/BNR/SNR', name: 'KnrGnrBnrSnr' },
  { label: 'Byggeår', name: 'BuiltYear' },
];

export class UnconnectedSignableDocumentItem extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    // OBS: Fix this
    // FB.mediator.subscribe(this.getChannelName(), this.createSignatureJob);
  }

  getChannelName = () =>
    `${PORTOFOLIO_CONTRACT_SIGNATURE_CHANNEL}${this.props.item.DocumentId}`;

  createSignatureJob = (ssn: string, rejectionReason?: string  ) => {
    const createUrl = (signingState) =>
      `#/basen-proff/tab-my-portfolio?${queryString.stringify({
        inventoryType: this.props.item.ReferenceType,
        inventoryNumber: this.props.item.InventoryNumber,
        documentId: this.props.item.DocumentId,
        rentalId: this.props.item.RentalId,
        signingState,
      })}`;
    const completionUrl = createUrl(SIGNATURE_STATE_SUCCESS);
    const errorUrl = createUrl(SIGNATURE_STATE_ERROR);
    const rejectionUrl = createUrl(SIGNATURE_STATE_REJECTION);

    this.props.createSignatureJobForXpandDocument({
      identityNumber: ssn,
      completionUrl,
      rejectionUrl,
      errorUrl,
      rentalId: this.props.item.RentalId,
      documentId: this.props.item.DocumentId,
      fileName: this.props.item.FileName,
      revision: this.props.item.Revision,
    });
  };

  startSigningModalPath = () =>
    `${this.props.location.pathname}/${this.props.item.DocumentId}/start-signering`;

  handleOpenStartSigningModal = () => {
    this.props.navigate(this.startSigningModalPath());
  };

  SigningModal = () => (
    <SigningModal
      path={this.startSigningModalPath()}
      documentId={this.props.item.DocumentId}
      title="Start elektronisk signering"
      ariaLabel="Start elektronisk signering"
      description={SIGNED_RENTAL_PROTOCOL_WARNING}
      acceptSigning={this.createSignatureJob}
    ></SigningModal>
  );

  rejectSigningModalPath = () =>
    `${this.props.location.pathname}/${this.props.item.DocumentId}/avslå-signering`;

  handleOpenRejectSigningModal = () =>
    this.props.navigate(this.rejectSigningModalPath());

  RejectSigningModal = () => (
    <RejectSigningModal
      title="Avslå signering"
      ariaLabel="Avslå dokument"
      description="Ved å trykke avslå under vil du avslå signering av valgt dokument."
      path={this.rejectSigningModalPath()}
      documentId={this.props.item.DocumentId}
    />
  );

  renderExtendedDetails = () => (
    <div className="sign-doc-listitem">
      <List
        extraListStyle={'no-border-list'}
        items={[this.props.item]}
        columns={columnDetailsHorizontal as DeliberateAny[]}
        renderItem={() => (
          <ListItem
            key={this.props.item.RentalId}
            item={this.props.item}
            columns={columnDetailsHorizontal as DeliberateAny[]}
          />
        )}
        noItemsMessage={<p>Ingen protokoll til signering</p>}
      />

      <div className="doc-signing">
        <h3>Dokument til signering: </h3>
        <p>
          <DownloadLink
            href={`${proffBaseUrl}/${this.props.item.DocumentPath}`}
          >
            {this.props.item.FileName}
          </DownloadLink>
        </p>
      </div>
      <ButtonRow>
        <ButtonRow.Left onClick={this.handleOpenStartSigningModal} color="red">
          Start signering
        </ButtonRow.Left>
        <ButtonRow.Left onClick={this.handleOpenRejectSigningModal} color="red">
          Avslå signering
        </ButtonRow.Left>
      </ButtonRow>
      <this.SigningModal />
    </div>
  );

  render() {
    return [
      <ListItem
        key="1"
        item={this.props.item}
        columns={this.props.columns}
        details={columnDetailsVertical}
        extendedDetailsContainer={this.renderExtendedDetails()}
        minimalDetails
      />,
      <this.RejectSigningModal key="2" />,
    ];
  }
}

export default connect(null, {
  createSignatureJobForXpandDocument,
})(UnconnectedSignableDocumentItem);
