// @flow

import {
  clearApplication,
  updateQuarterApplication,
} from 'actions/housingActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import DetailsCards from 'components/DetailsCards/DetailsCards';
import Loader from 'components/Loader/Loader';
import Modal from 'components/modals/Modal/Modal';
import { useHousing, useSlides } from 'contexts/index';
import DOMPurify from 'dompurify';
import connect from 'helpers/connectWithRouter';
import housing_svg from 'images/FB_bolig.svg';
import quarter_svg from 'images/FB_kvarter.svg';
import { useToggle } from 'react-use';
import { DeliberateAny } from 'types/DelibrateAny';

import { HousingTexts } from '../../static';

const UnconnectedHousing = () => {
  const { housingTexts, loading } = useHousing();
  const { baseUrl, goToSlide } = useSlides();

  const [
    showQuarterApplicationInfoModal,
    toggleShowQuarterApplicationInfoModal,
  ] = useToggle(false);
  const [
    showHousingApplicationInfoModal,
    toggleShowHousingApplicationInfoModal,
  ] = useToggle(false);
  const [showQuarterTerms, toggleShowQuarterTerms] = useToggle(false);
  const [showHousingTerms, toggleShowHousingTerms] = useToggle(false);

  const startHousingApplication = () => {
    clearApplication();
    goToSlide(`/${baseUrl}/bolig-målgruppe`);
  };

  const startQuarterApplication = () => {
    clearApplication();
    goToSlide(`/${baseUrl}/kvarter-målgruppe`);
  };

  if (!housingTexts) {
    <Loader isLoading />;
  }

  return (
    <Loader isLoading={loading}>
      {housingTexts && (
        <div style={{ maxWidth: '800px' }}>
          <h1>Søk bolig og kvarter</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(housingTexts?.OverallDescriptionHtml),
            }}
          />
          <div className="margin">
            <ButtonRow>
              <ButtonRow.Left
                onClick={() => toggleShowHousingApplicationInfoModal()}
              >
                Les om boligsøknadsprosessen
              </ButtonRow.Left>
              <ButtonRow.Right
                onClick={() => toggleShowQuarterApplicationInfoModal()}
              >
                Les om kvartersøknadsprosessen
              </ButtonRow.Right>
            </ButtonRow>
          </div>

          <h2>Velg søknadstype</h2>

          <Modal
            isOpen={showHousingApplicationInfoModal}
            onClose={() => toggleShowHousingApplicationInfoModal()}
            ariaLabel="Søknadsprosess"
            size="medium"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  housingTexts?.AboutHousingApplicationProcessHtml,
                ),
              }}
            />
          </Modal>
          <Modal
            isOpen={showQuarterApplicationInfoModal}
            onClose={() => toggleShowQuarterApplicationInfoModal()}
            ariaLabel="Søknadsprosess"
            size="medium"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  housingTexts?.AboutQuarterApplicationProcessHtml,
                ),
              }}
            />
          </Modal>
          <Modal
            isOpen={showHousingTerms}
            onClose={() => toggleShowHousingTerms()}
            ariaLabel="Søknadsprosess"
            size="medium"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(housingTexts?.HousingConditionsHtml),
              }}
            />
          </Modal>
          <Modal
            isOpen={showQuarterTerms}
            onClose={() => toggleShowQuarterTerms()}
            ariaLabel="Søknadsprosess"
            size="medium"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(housingTexts?.QuarterConditionsHtml),
              }}
            />
          </Modal>

          <DetailsCards
            expandable
            cards={[
              {
                header: 'Bolig',
                description: HousingTexts.DetailsCards.housingDescription,
                iconSrc: housing_svg,
                details: (
                  <div>
                    <div
                      className="margin border-bottom"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          housingTexts?.HousingApplicationPreparationHtml,
                        ),
                      }}
                    />
                    <ButtonRow>
                      <ButtonRow.Left onClick={() => toggleShowHousingTerms()}>
                        Vilkår for bolig
                      </ButtonRow.Left>
                      <ButtonRow.Right
                        color="red"
                        onClick={startHousingApplication}
                      >
                        Søk om ny bolig
                      </ButtonRow.Right>
                    </ButtonRow>
                  </div>
                ),
              },
              {
                header: 'Kvarter',
                description: HousingTexts.DetailsCards.quarterDescription,
                iconSrc: quarter_svg,
                details: (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          housingTexts?.QuarterApplicationPreparationHtml,
                        ),
                      }}
                    />
                    <div className="margin border-bottom" />
                    <ButtonRow>
                      <ButtonRow.Left onClick={() => toggleShowQuarterTerms()}>
                        Vilkår for kvarter
                      </ButtonRow.Left>
                      <ButtonRow.Right
                        color="red"
                        onClick={startQuarterApplication}
                      >
                        Søk om nytt kvarter
                      </ButtonRow.Right>
                    </ButtonRow>
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}
    </Loader>
  );
};

export default connect(
  (state: DeliberateAny) => ({
    quarter: state.housing.quarterApplication,
    housing: state.housing.housingApplication,
  }),
  {
    updateQuarterApplication,
  },
)(UnconnectedHousing);
