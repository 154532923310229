import React, { useEffect, useState } from 'react';

interface TimeLeft {
  dager?: number;
  timer?: number;
  minutter?: number;
  sekunder?: number;
}

interface CountDownProps {
  targetDate: Date | string;
}

const CountDown: React.FC<CountDownProps> = ({ targetDate }) => {
  const calculateTimeLeft = (): TimeLeft => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft: TimeLeft = {};

    if (difference > 0) {
      timeLeft = {
        dager: Math.floor(difference / (1000 * 60 * 60 * 24)),
        timer: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutter: Math.floor((difference / 1000 / 60) % 60),
        sekunder: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval) => {
    const value = timeLeft[interval as keyof TimeLeft];
    if (value) {
      timerComponents.push(
        <span key={interval}>
          {value} {interval}{' '}
        </span>,
      );
    }
  });

  return (
    <strong>
      {timerComponents.length ? timerComponents : <span>Tiden er ute!</span>}
    </strong>
  );
};

export default CountDown;
