import '../forms.scss';

import { ChangeEvent, FC, ReactElement } from 'react';

import { DeliberateAny } from '../../../types/DelibrateAny';
import FormErrors from '../FormErrors';

export interface IInputProps {
  disabled?: boolean;
  onChange?: (name: string, value: string) => void;
  onBlur?: (name: string, value: string) => void;
  errors?: DeliberateAny;
  errorcustomtext?: string;
  value?: string | number;
  type?: string;
  name: string;
  title?: string | ReactElement;
  autoComplete?: string;
  'data-testid'?: string;
  maxLength?: number;
  minLength?: number;
  placeholder?: string;
  id?: string;
  className?: string;
}

const Input: FC<IInputProps> = ({
  name,
  onBlur,
  onChange,
  type = 'text',
  disabled = false,
  value = '',
  title,
  errors,
  errorcustomtext,
  ...otherProps
}) => {
  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(name, event.target.value);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (type === 'number' && inputValue !== '' && isNaN(Number(inputValue))) {
      return;
    }

    if (onChange) {
      onChange(name, inputValue);
    }
  };

  const error = errors && errors[name];

  return (
    <label className={`${error ? 'invalid' : ''}`}>
      <span>
        {title}{' '}
        {error && <FormErrors errortext={errorcustomtext} rule={error} />}
      </span>
      <input
        name={name}
        {...otherProps}
        type={type}
        disabled={disabled}
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
    </label>
  );
};

export default Input;
