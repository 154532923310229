// @flow

import { DeliberateAny } from '../types/DelibrateAny';

import type {
  HousingApplicationType,
  QuarterApplicationType,
  ServiceAreaType,
} from '../types/housingTypes';

export function clearApplication() {
  return { type: 'CLEAR_APPLICATION' };
}

export function updateApplication(
  applicationType: string,
  key: string,
  value: string | boolean,
) {
  return { type: 'UPDATE_APPLICATION', applicationType, key, value };
}

export function updateQuarterApplication() {
  return { type: 'UPDATE_QUARTER_APPLICATION' };
}

export function fetchHousingInfoText() {
  return { type: 'FETCH_HOUSING_INFO_TEXT' };
}

export function fetchHousingAndServiceAreas(applicationType: string) {
  return { type: 'FETCH_HOUSING_AND_SERVICE_AREAS', applicationType };
}

export function updateAllApplicationErrors(
  applicationType: string,
  errors: DeliberateAny,
) {
  return { type: 'UPDATE_ALL_APPLICATION_ERRORS', applicationType, errors };
}

export function updateSingleApplicationError(
  applicationType: string,
  name: string,
  error: DeliberateAny,
) {
  return {
    type: 'UPDATE_SINGLE_APPLICATION_ERROR',
    applicationType,
    name,
    error,
  };
}

export function setServiceArea(
  applicationType: string,
  serviceArea: ServiceAreaType,
) {
  return {
    type: 'SET_SERVICE_AREA_IN_APPLICATION',
    applicationType,
    serviceArea,
  };
}

export function setHousingArea(applicationType: string, housingAreaId: string) {
  return {
    type: 'SET_HOUSING_AREA_IN_APPLICATION',
    applicationType,
    housingAreaId,
  };
}

export function fetchAllocationRoundInfo(housingAreaId: string) {
  return { type: 'FETCH_ALLOCATION_ROUND_INFO', housingAreaId };
}

export function removeChildren() {
  return { type: 'REMOVE_CHILDREN' };
}

export function removeChild(index: number) {
  return { type: 'REMOVE_CHILD', index };
}

export function addChild() {
  return { type: 'ADD_CHILD' };
}

export function clearPetInfo(applicationType: string) {
  return { type: 'CLEAR_PET_INFO', applicationType };
}

export function setPostalCode(
  applicationType: string,
  key: string,
  postalCode: string,
) {
  return { type: 'SET_POSTAL_NUMBER', applicationType, key, postalCode };
}

export function addCoApplicant(applicationType: string) {
  return { type: 'ADD_CO_APPLICANT', applicationType };
}

export function removeCoApplicant(applicationType: string, index: number) {
  return { type: 'REMOVE_CO_APPLICANT', applicationType, index };
}

export function removeCoApplicantAllButFirst(applicationType: string) {
  return { type: 'REMOVE_CO_APPLICANT_ALL_BUT_FIRST', applicationType };
}

export function sendApplication(
  application: HousingApplicationType | QuarterApplicationType,
) {
  return { type: 'SEND_APPLICATION', application };
}

export function fetchApplicationsInArea(
  applicationType: string,
  housingAreaId: string,
) {
  return { type: 'FETCH_APPLICATIONS_IN_AREA', applicationType, housingAreaId };
}

export function fetchHousingCoApplicant(
  applicationType: string,
  areaId: string,
) {
  return { type: 'FETCH_HOUSING_CO_APPLICANT', applicationType, areaId };
}
