/* eslint react/prop-types: 0 */

import List from "components/List/List";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import HousingPropertiesColumns from "./HousingPropertiesColumns";
import HousingPropertiesItem from "./HousingPropertiesItem";

const HousingPropertiesList = ({ items }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const renderItem = (item) => (
    <HousingPropertiesItem
      key={uuidv4()}
      item={item}
      columns={HousingPropertiesColumns}
    />
  );
  return (
    <List
      columns={HousingPropertiesColumns}
      renderItem={renderItem}
      items={items}
      clientPaging={{
        pageNumber,
        pageSize: 10,
        pageCount,
        numItems: items.length,
        onChangePageNumber: (number) => setPageNumber(number),
        onSetPageCount: (number) => setPageCount(number),
      }}
    />
  );
};
export default HousingPropertiesList;
