// @flow

import { sendServiceOrder } from "actions/serviceOrdersActions";
import connect from "helpers/connectWithRouter";
import { PROPERTY_SERVICE_ORDER_TYPE } from "helpers/serviceOrderHelpers";
import moment from "moment";

import SendServiceOrderModal from "../SendServiceOrderModal/SendServiceOrderModal";

import type { SendServiceOrderType } from "types/serviceOrderTypes";
import { registerBuildingRentalTerminationServiceOrderURL } from "api/basenproff/basenproff_api";
import { DeliberateAny } from "types/DelibrateAny";
type PropsType = {
  path: string;
  inventoryNumber: number;
  inventoryName: string;
  keyCmObj: string;
  regionNumber: string;
  serviceOrder: SendServiceOrderType;
  sendServiceOrder: (serviceOrder?: SendServiceOrderType) => void;
};

const resignationLabel = "Vi ønsker å si opp leieforholdet fra";

const editDescription = (serviceOrder?: SendServiceOrderType) => {
  if (serviceOrder) {
    const date = serviceOrder.resignationDate
      ? serviceOrder.resignationDate.format("DD.MM.YYYY")
      : "";
    const comment = serviceOrder.description ? serviceOrder.description : "";
    return `${resignationLabel} ${date}\nKommentar: ${comment}`;
  }

  return "";
};

const preSendServiceOrder = (props: PropsType) => {
  if(props?.serviceOrder)
    props.serviceOrder.description = editDescription(props.serviceOrder);
}

const UnconnectedBuildingResignationServiceOrderModal = (props: PropsType) => (
  <SendServiceOrderModal
    title="Meld ønske om oppsigelse"
    description={`
      Ved å melde ønske om oppsigelse starter en intern prosess hos Forsvarsbygg.
      Du vil bli kontaktet av Forsvarsbygg for den videre prosessen.
    `}
    fields={{
      title: {
        value: `Ønske om oppsigelse for «${props.inventoryName}»`,
        disabled: true,
      },
      building: { value: props.inventoryNumber, disabled: true },
      description: { label: "Kommentar", required: true },
      resignationDate: { value: moment(), label: resignationLabel },
      serviceOrderType: { value: PROPERTY_SERVICE_ORDER_TYPE },
      regionNumber: { value: props.regionNumber },
      keyCmObj: { value: props.keyCmObj },
    }}
    url = {registerBuildingRentalTerminationServiceOrderURL}
    preSendServiceOrder = {() => preSendServiceOrder(props)}
    {...props}
  />
);

export default connect(
  (state: DeliberateAny) => ({
    serviceOrder: state.serviceOrders.serviceOrder,
  }),
  { sendServiceOrder }
)(UnconnectedBuildingResignationServiceOrderModal);
