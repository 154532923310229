/* eslint react/prop-types: 0 */

import "./HousingApplicationsOverview.scss";

import {
  fetchHousingAllocations,
  fetchHousingTexts,
} from "actions/housingAdminActions";
import {
  applicationsAsPdfURL,
  housingAllocationExcelDocumentURL,
} from "api/housing/housing_api";
import DownloadButton from "components/buttons/DownloadButton/DownloadButton";
import Loader from "components/Loader/Loader";
import { useSlides } from "contexts/index";
import { getRoundTitle } from "helpers/housingAdminHelpers";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DeliberateAny } from "types/DelibrateAny";

import HousingAllocationsList from "./HousingAllocationsList/HousingAllocationsList";

const HousingAllocations = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { goToSlide } = useSlides();

  const housingArea = useSelector(
    (state: DeliberateAny) => state.housingAdmin.housingArea,
    shallowEqual
  );

  useEffect(() => {
    if (housingArea.selectedAllocationRound) {
      dispatch(fetchHousingTexts());
      dispatch(
        fetchHousingAllocations(housingArea.selectedAllocationRound.Round.Id)
      );
    } else {
      goToSlide(`/boligsekretær/boligsøknader/${params.housingAreaId}`);
    }
  }, []);

  return (
    <Loader
      isLoading={
        housingArea.loadingHousingTexts || housingArea.loadingHousingAllocations
      }
    >
      {housingArea.selectedAllocationRound && housingArea.housingTexts && (
        <div>
          <h2>
            {getRoundTitle(
              housingArea.selectedAllocationRound,
              housingArea.housingRoundDataOptions
            )}
          </h2>
          <div>
            <h2>Tildelingsliste</h2>
            <p>{housingArea.housingTexts.AllocationDistributionInfo}</p>
            <div className="LinkButtons">
              <div>
                <DownloadButton
                  href={`${housingAllocationExcelDocumentURL}?allocationRoundId=${housingArea.selectedAllocationRound.Round.Id}`}
                >
                  Last ned (Excel)
                </DownloadButton>
                <DownloadButton
                  href={`${applicationsAsPdfURL}?roundId=${housingArea.selectedAllocationRound.Round.Id}`}
                >
                  Last ned (PDF)
                </DownloadButton>
              </div>
            </div>
          </div>
          {housingArea.housingAllocations && (
            <div style={{ marginTop: "22px" }}>
              <HousingAllocationsList items={housingArea.housingAllocations} />
            </div>
          )}
        </div>
      )}
    </Loader>
  );
};

export default HousingAllocations;
