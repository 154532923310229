import Loader from 'components/Loader/Loader';
import { useUser } from 'contexts/index';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { verifyAuthorizationPayment } from '../../api/booking/booking_api_v2';
import useAxios from '../../hooks/useAxios';

const PaymentProviderCallback = () => {
  const { hasUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { payeeDomain: payeeDomainParam, orderReference: orderReferenceParam } =
    useParams();
  const { sendRequest } = useAxios();

  const [isLoading, setIsLoading] = useState(true);

  const verifyAuthorization = async (domain: string, orderRef: string) => {
    const searchParams = new URLSearchParams(location.search);

    const checkoutId = searchParams.get('checkout_id');
    const transactionId = searchParams.get('transaction_id');
    const autoCapture = searchParams.get('autoCapture');

    await sendRequest(
      {
        method: 'POST',
        url: verifyAuthorizationPayment(domain, orderRef),
        params: {
          checkoutId: checkoutId,
          transactionId: transactionId,
          autoCapture: autoCapture ? autoCapture : false,
        },
      },
      (response: string) => {
        removePaymentProviderIframe();
        setIsLoading(false);

        if (hasUser) navigate(`/mypage/booking/${response}/receipt`);
        else navigate(`/limited/booking/${response}/receipt`);
      },
    );
    setIsLoading(false);
  };

  const removePaymentProviderIframe = () => {
    const container = document.getElementById('payment_form_container');
    if (container) {
      container.remove();
    }
  };

  useEffect(() => {
    if (location.search && payeeDomainParam && orderReferenceParam) {
      // const verifyUrl = buildUrlQuery(payeeDomainParam, orderReferenceParam);
      verifyAuthorization(payeeDomainParam, orderReferenceParam);
    } else {
      navigate('/');
    }
  }, [location]);

  return <Loader isLoading={isLoading} />;
};

export default PaymentProviderCallback;
