import './BasenProff.scss';

import Loader from "components/Loader/Loader";
import Modal from "components/modals/Modal/Modal";
import { useUser } from "contexts/index";
import { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { DeliberateAny } from "types/DelibrateAny";

import PortfolioSearch from './TabMyPortfolio/MyPortfolio/PortfolioSearch/PortfolioSearch';
import ViewBuilding from './TabMyPortfolio/MyPortfolio/ViewBuilding/ViewBuilding';

const BasenProff = ({
  portfolioSearch,
  viewBuilding,
  setPortfolioSearch,
  setViewBuildning,
}) => {
  const { user, fetchUser, requestLoading } = useUser();

  let defaultState;
  const url = window.location.hash;
  switch (url) {
    case "#/basen-proff/tab-dashboard":
      defaultState = [true, false, false, false];
      break;
    case "#/basen-proff/tab-service-orders":
      defaultState = [false, true, false, false];
      break;
    case "#/basen-proff/tab-my-portfolio":
      defaultState = [false, false, true, false];
      break;
    case "#/basen-proff/tab-additionalservices":
      defaultState = [false, false, false, true];
      break;
    default:
      defaultState = [true, false, false, false];
      break;
  }

  const [tabActive, setTabActive] = useState(defaultState);

  useEffect(() => {
    if (!user && !requestLoading) fetchUser();
  }, [user]);

  return (
    <div>
      <Loader isLoading={requestLoading}>
        <div className="app-root">
          <div className="slides">
            <div
              className={`slide slide-basen-proff-landing ${
                !portfolioSearch && !viewBuilding ? "active-slide" : ""
              }`}
            >
              <div className="scrollable sticky-middle">
                <h1 className="hide-for-ms">Basen PROFF</h1>
                <div className="tabs-wrapper">
                  <div className="tabs">
                    <ul className="navlinkWrapper">
                      <NavLink
                        className="navlink"
                        to="/basen-proff/tab-dashboard"
                        onClick={() =>
                          setTabActive([true, false, false, false])
                        }
                      >
                        <li
                          className={`tab${
                            tabActive[0] === true ? "-active" : ""
                          }`}
                        >
                          Oversikt
                        </li>
                      </NavLink>
                      <NavLink
                        className="navlink"
                        to="/basen-proff/tab-service-orders"
                        onClick={() =>
                          setTabActive([false, true, false, false])
                        }
                      >
                        <li
                          className={`tab${
                            tabActive[1] === true ? "-active" : ""
                          }`}
                        >
                          Henvendelser
                        </li>
                      </NavLink>
                      <NavLink
                        className="navlink"
                        to="/basen-proff/tab-my-portfolio"
                        onClick={() =>
                          setTabActive([false, false, true, false])
                        }
                      >
                        <li
                          className={`tab${
                            tabActive[2] === true ? "-active" : ""
                          }`}
                        >
                          Min portefølje
                        </li>
                      </NavLink>
                      <NavLink
                        className="navlink"
                        to="/basen-proff/tab-additionalservices"
                        onClick={() =>
                          setTabActive([false, false, false, true])
                        }
                      >
                        <li
                          className={`tab${
                            tabActive[3] === true ? "-active" : ""
                          }`}
                        >
                          Tilleggstjenester
                        </li>
                      </NavLink>
                    </ul>
                  </div>
                  <div className="panels">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
            <div className={`slide ${portfolioSearch ? "active-slide" : ""}`}>
              <PortfolioSearch setPortfolioSearch={setPortfolioSearch} />
            </div>
            <div className={`slide ${viewBuilding ? "active-slide" : ""}`}>
              <ViewBuilding
                setViewBuildning={setViewBuildning}
                item={viewBuilding}
              />
            </div>
          </div>
        </div>
      </Loader>
    </div>
  );
};

export default BasenProff;
