import env from 'env';
import useAxios from 'hooks/useAxios';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { IWrapper } from '../interfaces/IWrapper';
import { useUser } from './';
import signalRService from './SignalRService';

export interface UserNotificationDto {
  Id: string;
  Created: Date;
  Message: string;
  Acknowledged: boolean;
  NotificationSource: string;
}

export interface RecentUserNotificationDto {
  ItemsTotalCount: number;
  PageNumber: number;
  PageSize: number;
  Items: UserNotificationDto[];
}

export interface SignalRContextProps {
  notifications: UserNotificationDto[];
  setNotifications: Dispatch<SetStateAction<UserNotificationDto[]>>;
}

export const SignalRContext = createContext({} as SignalRContextProps);

export const SignalRContextProvider: FC<IWrapper> = ({ children }) => {
  const { CORE: coreUrl } = env;

  const { user, hasUser } = useUser();
  const { sendRequest } = useAxios();
  const [notifications, setNotifications] = useState<UserNotificationDto[]>([]);

  const endpointPath = '/usernotification/unacknowledged';
  const hubPath = '/UserNotification';

  const fetchNotifications = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: coreUrl + endpointPath,
        params: { skipCount: 0, takeCount: 10 },
        withCredentials: true,
      },
      (res) => {
        setNotifications(res);
      },
    );
  };

  const updateNotifications = (notification: UserNotificationDto) => {
    setNotifications((prevState) =>
      sortNotifications([...prevState, notification]),
    );
  };

  const sortNotifications = (notifications: UserNotificationDto[]) => {
    return notifications.sort(
      (a, b) =>
        Date.parse(b.Created.toString()) - Date.parse(a.Created.toString()),
    );
  };

  useEffect(() => {
    if (hasUser) {
      fetchNotifications();

      signalRService.startConnection(coreUrl, hubPath, user.JwtToken);
      signalRService.onReceiveCreatedNotification(updateNotifications);
      signalRService.lostConnection();
    }
  }, [hasUser]);

  useEffect(() => {
    window.addEventListener('beforeunload', () =>
      signalRService.stopConnection(),
    );
    return () => {
      window.removeEventListener('beforeunload', () =>
        signalRService.stopConnection(),
      );
    };
  }, []);

  const values = useMemo(
    () => ({
      notifications,
      setNotifications,
    }),
    [notifications, setNotifications],
  );

  return (
    <SignalRContext.Provider value={values}>{children}</SignalRContext.Provider>
  );
};
