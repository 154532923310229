import './BookingRooms.scss';

import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import BookingSearch from 'components/BookingComponents/BookingSearch';
import SelectRoomType from 'components/BookingComponents/SelectRoomType';
import { useNewBooking } from 'contexts/index';
import { roomCapacity } from 'helpers/rooms';
import { labelYupError } from 'helpers/yupLocales';
import { FC, useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import { IBookingFormProps } from '../Booking';
import AboutArena from './AboutLocation';

interface IBookingRoomsProps extends IBookingFormProps {
  nextTriggered: boolean;
}

const BookingRooms: FC<IBookingRoomsProps> = ({
  validation,
  nextTriggered,
  nav,
}) => {
  const {
    roomInfo,
    roomCounts,
    errors,
    bookingSearch,
    setErrors,
    splitValues,
    availableLoading,
  } = useNewBooking();

  const [validationTried, setValidationTried] = useState(false);

  const fieldNames = {
    rooms: 'Informasjon om rom for bestilling',
    enoughRoomsForGuests: 'Nok rom til antall gjester',
  };

  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    handleValidation();
    if (nextTriggered) {
      setShowErrors(true);
    }
  }, [nextTriggered]);

  useEffect(() => {
    if (validationTried) handleValidation();
  }, [splitValues]);

  const handleValidation = async () => {
    if (!roomInfo) return;
    try {
      const capacity = roomCapacity(roomInfo, roomCounts);
      const tooManyRooms = capacity.minGuests > bookingSearch.guestCount;
      const tooFewRooms = capacity.maxGuests < bookingSearch.guestCount;
      const validationObject = {
        rooms: splitValues?.rooms,
        tooManyRooms,
        tooFewRooms,
      };

      setValidationTried(true);
      if (validation) {
        await validation.validate(validationObject, {
          abortEarly: false,
          context: fieldNames,
        });
      }
      setErrors({});
    } catch (err: DeliberateAny) {
      const validationErrors = {};
      err.inner.forEach(
        (error) =>
          (validationErrors[error.path] = labelYupError(error, fieldNames)),
      );
      setErrors(validationErrors);
    }
  };

  return (
    <div className="fb-new fb-br-container">
      <BookingSearch nav={nav} />
      {/* TODO: Too slow, shows errors on room change */}
      {!availableLoading &&
        showErrors &&
        (errors['rooms'] ||
          errors['tooFewRooms'] ||
          errors['tooManyRooms']) && (
          <AlertMessage className="sticky-top" variant={AlertType.Error}>
            {[...new Set(Object.values(errors))].map((err) => (
              <div key={err}>{err}</div>
            ))}
          </AlertMessage>
        )}
      <SelectRoomType />
      <AboutArena locationId={bookingSearch?.location ?? ''} />
    </div>
  );
};

export default BookingRooms;
