import { arenaAvailableRoomsForBookingUrl } from 'api/booking/booking_api_v2';
import { getPrice } from 'contexts/BookingContextNew';
import { useMyPage } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import { FC, useEffect } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useParams } from 'react-router-dom';
import { AvailableRoomType } from 'types/BookingTypes/roomTypes';
import { DeliberateAny, SetState } from 'types/DelibrateAny';
import DateRangeSelector from 'components/ModalComponents/ChangeBooking/DateRangeSelector';

export interface ChangeRequestResponse {
  changeRequestId: string;
  confirmationDeadline: Date;
}

interface IArrivalDepartureFormProps {
  errors: DeliberateAny;
  unavailableDates: Date[];
  arrivalDeparture: DateObject[];
  setArrivalDeparture: SetState<DateObject[]>;
  setQuoteId: SetState<string>;
  setRoomInfo: SetState<AvailableRoomType[]>;
  setIsFetchingAvailableRooms: SetState<boolean>;
}

const ArrivalDepartureForm: FC<IArrivalDepartureFormProps> = ({
  errors,
  unavailableDates,
  arrivalDeparture,
  setArrivalDeparture,
  setQuoteId,
  setRoomInfo,
  setIsFetchingAvailableRooms,
}) => {
  const { bookingItem } = useMyPage();
  const { bookingid } = useParams();
  const { sendRequest } = useAxios();

  const fetchAvailableRoomTypes = async () => {
    if (!bookingItem?.locationId) return;
    if (!bookingid) return;
    const arrDate = arrivalDeparture?.[0].toDate();
    const depDate = arrivalDeparture?.[1].toDate();

    setIsFetchingAvailableRooms(true);
    await sendRequest(
      {
        method: 'GET',
        url: arenaAvailableRoomsForBookingUrl(
          bookingItem.locationId,
          arrivalDeparture?.[0].toDate(),
          arrivalDeparture?.[1].toDate(),
          0,
          bookingid,
        ),
      },
      (response) => {
        const mappedResponse: AvailableRoomType[] = response.availableRooms.map(
          (room) => {
            const roomPrice = getPrice(
              room.priceList,
              room.standardCapacity,
              arrDate,
              depDate,
            );
            const newObject = {
              roomType: room.roomType,
              availableCount: room.availableCount,
              defaultSelectedCount: room.defaultSelectedCount,
              priceList: room.priceList,
            } as AvailableRoomType;

            newObject.roomType.price = roomPrice
              ? roomPrice
              : room.priceList[0]?.priceValue;
            return newObject;
          },
        );
        setRoomInfo(
          mappedResponse.sort(
            (item1, item2) =>
              item1.roomType.standardCapacity - item2.roomType.standardCapacity,
          ),
        );
        setQuoteId(response.quoteId);
      },
    );
    setIsFetchingAvailableRooms(false);
  };

  useEffect(() => {
    if (bookingItem?.locationId && arrivalDeparture) fetchAvailableRoomTypes();
  }, [bookingItem?.locationId, arrivalDeparture]);

  return (
    <DateRangeSelector
      arrivalDeparture={arrivalDeparture}
      setArrivalDeparture={setArrivalDeparture}
      unavailableDates={unavailableDates}
      errors={errors}
    />
  );
};

export default ArrivalDepartureForm;
