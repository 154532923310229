import './ShareModal.scss';

import Checkbox from 'components/form/Checkbox/Checkbox';
import Input from 'components/form/Input/Input';
import Loader from 'components/Loader/Loader';
import { useEffect, useState } from 'react';

import { shareL3ItemURL } from '../../../api/basenproff/basenproff_api';
import { useUser } from '../../../contexts';
import useAxios from '../../../hooks/useAxios';
import { DeliberateAny } from '../../../types/DelibrateAny';
import Modal, { ModalSize } from '../Modal/Modal';

type PropsType = {
  title: string;
  infoText: string;
  html: string;
  isOpen: boolean;
  size?: ModalSize;
  onClose: () => void;
  onOpen: () => void;
  attr: Record<string, DeliberateAny>;
};

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

const ShareModal = (props: PropsType) => {
  const { sendRequest, requestError } = useAxios();
  const { user, requestLoading } = useUser();
  const [loading, setLoading] = useState(false);
  const [to, setTo] = useState('');
  const [include, setInclude] = useState(false);
  const [copy, setCopy] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (requestError) {
      setErrors(requestError);
      setLoading(false);
    }
  }, [requestError]);

  const handleShare = async () => {
    setLoading(true);
    setErrors({});
    if (to === '') {
      setErrors({ til: { rule: 'required' } });
      setLoading(false);
      return;
    } else if (!emailRegex.test(to)) {
      setErrors({ til: { rule: 'email' } });
      setLoading(false);
      return;
    }

    await sendRequest(
      {
        method: 'POST',
        url: shareL3ItemURL,
        data: {
          ItemId: props.attr.ItemId,
          Comment: include ? props.attr.Comment : null,
          SendTo: to,
          CopyTo: copy ? user.SecondaryEmail : null,
        },
      },
      () => {
        setLoading(false);
        props.onClose();
      },
    );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      ariaLabel={props.title}
      size={props.size}
      cancel={{
        text: 'Avbryt',
      }}
      submit={{
        text: 'Del',
        color: 'red',
        ignoreOnClose: true,
        onClick: handleShare,
        disabled: loading,
      }}
    >
      <Loader isLoading={requestLoading}>
        <header>
          <h2>{props.title}</h2>
          {props.html !== undefined && props.html !== '' ? (
            <p dangerouslySetInnerHTML={{ __html: props.html }} />
          ) : (
            <p>{props.infoText}</p>
          )}
        </header>
        <div className="ctrl">
          <Input disabled title="Fra" value={user.Name} name="fra" />
          <Input
            title="Til"
            name="til"
            value={to}
            onChange={(name, value) => setTo(value)}
            errors={errors}
          />
          <Checkbox
            label="Inkluder min kommentar"
            value={include}
            onChange={(_, value) => setInclude(value)}
          />
          <Checkbox
            label="Send kopi til meg selv på sekundær e-post"
            value={copy}
            onChange={(_, value) => setCopy(value)}
          />
        </div>
      </Loader>
    </Modal>
  );
};

export default ShareModal;
