import { myRentalContractsURL } from "api/basenproff/basenproff_api";
import useAxios from "hooks/useAxios";
import { useCallback, useEffect, useState } from "react";
import { DeliberateAny } from "types/DelibrateAny";

// Define return type of the custom hook
type RentalContractsHookResult = {
  requestError: DeliberateAny;
  rentalContracts: DeliberateAny[];
  requestLoading: boolean;
  fetchRentalContracts: () => void;
};

export const useRentalContracts = (
  user: {
    Username?: string;
  },
  fetchAtOnce?: boolean,

  onSuccess?: (items: DeliberateAny[]) => void,
  onError?: () => void
): RentalContractsHookResult => {
  const { requestError, sendRequest, requestLoading } = useAxios();
  const [rentalContracts, setRentalContracts] = useState<DeliberateAny[]>([]);

  const getRentalCache = useCallback(() => {
    if (user.Username) {
      const cachedData = sessionStorage.getItem(`rentals_${user.Username}`);
      if (cachedData) {
        const { items, expires } = JSON.parse(cachedData);
        const now = new Date();

        if (now < new Date(expires)) {
          return items; // Cache is still valid
        }
      }
    }
    return null; // Cache is expired or doesn't exist
  }, [user.Username]);

  const setRentalCache = useCallback(
    (items: DeliberateAny[]) => {
      if (user.Username) {
        const now = new Date();
        const expires = new Date(now.getTime() + 15 * 60000); // Adds 15 minutes

        sessionStorage.setItem(
          `rentals_${user.Username}`,
          JSON.stringify({ items, expires })
        );
      }
    },
    [user.Username]
  );

  const fetchRentalContracts = useCallback(async () => {
    const cachedContracts = getRentalCache();
    if (cachedContracts === null) {
      let success = false;
      await sendRequest(
        {
          method: "GET",
          url: myRentalContractsURL,
        },
        (response) => {
          success = true;
          setRentalCache(response);
          setRentalContracts(response);
          if (onSuccess) onSuccess(response);
        }
      ).catch(() => {
        setRentalContracts([]);
      })
      .finally( () => {
        if(!success && onError) onError();
      });
    } else {
      setRentalContracts(cachedContracts);
      if (onSuccess) onSuccess(cachedContracts);
    }
  }, [getRentalCache, sendRequest, setRentalCache]);

  // Automatically fetch rental contracts once user data is available
  useEffect(() => {
    if (user.Username && fetchAtOnce) {
      fetchRentalContracts();
    }
  }, [user, fetchRentalContracts]);

  return {
    requestError,
    rentalContracts,
    requestLoading: requestLoading,
    fetchRentalContracts,
  };
};

export default useRentalContracts;
