import './List.scss';

const ControlledColumnHeaders = (props) => {
  return (
    <div className="overview-container">
      {props.columns.map((column) => (
        <div
          key={column.label}
          className={`sortable ascending 
                ${
                  props.controlledSortOptions.sortByColumn === column.name
                    ? 'sorting-header'
                    : ''
                }  ${column.className}
              `}
          onClick={() => props.controlledSortOptions.onSortColumn(column.name)}
        >
          {column.label}
        </div>
      ))}
    </div>
  );
};

export default ControlledColumnHeaders;
