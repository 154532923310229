import './Select.scss';

import _ from 'lodash/fp';
import React, { Component } from 'react';

import { possiblyCastStringToNumber } from '../../../helpers/valueHelpers';
import { DeliberateAny } from '../../../types/DelibrateAny';
import HelpText from '../../HelpText/HelpText';
import FormErrors from '../FormErrors';

type PropsType = {
  disabled: boolean;
  loading?: boolean;
  label?: string;
  options: DeliberateAny; //$ReadOnlyArray<KeyValueType>,
  onChange?: (name: string, value: number | string) => void;
  onBlur?: (name: string) => void;
  value?: number | string | null;
  name: string;
  'data-testid'?: string;
  'data-val'?: string;
  'data-val-required'?: string;
  'data-validators'?: string;
  dependentOn?: string;
  showAllOption?: boolean;
  defaultChoice?: string;
  errors?: DeliberateAny;
  autoSelectFirstOption?: boolean;
  className?: string;
  helpText?: string;
};

class Select extends Component {
  declare props: PropsType;

  static defaultProps = {
    disabled: false,
  };

  constructor(props: PropsType) {
    super(props);
    if (this.props.defaultChoice && this.props.showAllOption) {
      throw new Error(
        'These two options shouldn´t be used together. DefaultChoice should be used' +
          'when the user has to make a choice in the dropdown, and then make the default choice un-' +
          'selectable. The showAllOption should be used in for example filtering where it´s allowed' +
          'to filter on all the values in the select.',
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    if (this.props.value !== nextProps.value && this.props.onBlur) {
      this.props.onBlur(this.props.name);
    }
  }

  UNSAFE_componentWillUpdate(nextProps: PropsType) {
    const trySetFirstOption =
      this.props.autoSelectFirstOption &&
      (this.props?.options?.length ?? 0) === 0;
    if (
      trySetFirstOption &&
      (nextProps.options?.length ?? 0) > 0 &&
      this.props.onChange
    ) {
      this.props.onChange(this.props.name, nextProps.options[0].key);
    }
  }

  onChange = (event: DeliberateAny) => {
    const value = possiblyCastStringToNumber(event.target.value);
    if (this.props.onChange) {
      this.props.onChange(this.props.name, value);
    }
  };

  renderOptions = () =>
    this.props.options ? (
      [
        this.props.showAllOption ? (
          <option key="all" value="-1">
            Vis alle
          </option>
        ) : (
          ''
        ),
        this.props.defaultChoice ? (
          <option key="all" value="-1" disabled>
            {this.props.defaultChoice}
          </option>
        ) : (
          ''
        ),
        this.props.options.map(({ key, value }: DeliberateAny, i: number) => (
          <option key={key + i} value={key}>
            {value}
          </option>
        )),
      ]
    ) : (
      <option>{`Velg ${this.props.dependentOn}`}</option>
    );

  render() {
    const { errors } = this.props;
    const error = errors && errors[this.props.name];
    return (
      <div className={this.props.className ? this.props.className : ''}>
        <label
          className={`${error ? 'invalid' : ''}`}
          htmlFor={this.props.name}
        >
          <React.Fragment>
            {this.props.helpText ? (
              <HelpText helpText={this.props.helpText}>
                {this.props.label} {error && <FormErrors rule={error} />}
              </HelpText>
            ) : (
              <React.Fragment>
                <span>{this.props.label}</span>{' '}
                {error && <FormErrors rule={error} />}
              </React.Fragment>
            )}
          </React.Fragment>
          <div className="custom-select">
            <select
              id={this.props.name}
              name={this.props.name}
              onChange={this.onChange}
              value={_.isNil(this.props.value) ? -1 : this.props.value}
              disabled={this.props.disabled}
              data-testid={this.props['data-testid']}
              data-val={this.props['data-val']}
              data-val-required={this.props['data-val-required']}
              data-validators={this.props['data-validators']}
            >
              {this.props.loading ? (
                <option>Laster...</option>
              ) : (
                this.renderOptions()
              )}
            </select>
          </div>
        </label>
      </div>
    );
  }
}

export default Select;
