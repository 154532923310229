import { useLocation } from 'react-router-dom';

const useQuery = (): Record<string, string> => {
  const searchParams = new URLSearchParams(useLocation().search);
  const queryObject: Record<string, string> = {};

  searchParams.forEach((value, key) => {
    queryObject[key] = value;
  });

  return queryObject;
};

export default useQuery;
