import { fetchHousingTexts } from "actions/housingAdminActions";
import {
  applicationsAsPdfURL,
  housingAllocationExcelDocumentURL,
} from "api/housing/housing_api";
import Button from "components/buttons/Button/Button";
import DownloadButton from "components/buttons/DownloadButton/DownloadButton";
import Loader from "components/Loader/Loader";
import { useSlides } from "contexts/index";
import { getRoundTitle } from "helpers/housingAdminHelpers";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DeliberateAny } from "types/DelibrateAny";

const ProcessApplications = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { goToSlide } = useSlides();

  const housingArea = useSelector(
    (state: DeliberateAny) => state.housingAdmin.housingArea,
    shallowEqual
  );

  useEffect(() => {
    if (housingArea.selectedAllocationRound) {
      dispatch(fetchHousingTexts());
    } else {
      goToSlide(`/boligsekretær/boligsøknader/${params.housingAreaId}`);
    }
  }, []);

  return (
    <Loader isLoading={housingArea.loadingHousingTexts}>
      {housingArea.selectedAllocationRound && housingArea.housingTexts && (
        <div>
          <h2>
            {getRoundTitle(
              housingArea.selectedAllocationRound,
              housingArea.housingRoundDataOptions
            )}
          </h2>
          <div className="admin-process-applications">
            <div className="content-wrapper">
              <h2>Last ned dokumenter til behandling</h2>
              <p>
                De innkomne søknadene samles i et Excel-dokument, slik at du kan
                kvalitetssikre innholdet. Noen felter er ferdig fylt ut fra
                Basen, mens andre må fylles ut med informasjon fra SAP og andre
                systemer. Dette er grunnlaget for behandling i boligrådet.
                <br />
                <br />I tillegg kan du laste ned en PDF-fil for å lese
                kommentarfeltene i detalj.
              </p>
              <p>
                Antall søknader:{" "}
                <strong className="red">
                  {" "}
                  {housingArea.selectedAllocationRound.ApplicationCount}
                </strong>
              </p>
              <div className="button-row">
                <div>
                  <DownloadButton
                    href={`${applicationsAsPdfURL}?roundId=${housingArea.selectedAllocationRound.Round.Id}`}
                    disabled={
                      housingArea.selectedAllocationRound.ApplicationCount < 1
                    }
                  >
                    Last ned søknader (PDF)
                  </DownloadButton>
                  <DownloadButton
                    href={`${housingAllocationExcelDocumentURL}?allocationRoundId=${housingArea.selectedAllocationRound.Round.Id}`}
                  >
                    Last ned behandlingark
                  </DownloadButton>
                </div>
                <div>
                  <Button
                    color="red"
                    onClick={() =>
                      goToSlide(
                        `/boligsekretær/boligråd/${params.housingAreaId}`
                      )
                    }
                  >
                    Klargjør til boligråd
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Loader>
  );
};

export default ProcessApplications;

// props => (
//   <Route
//     location={props.location}
//     path={`${props.match.url}/:housingAreaId`}
//     component={ProcessApplications}
//   />
// );
