// @flow

import {
  updateSingleError,
  mapArrayObjectsToCamel,
  ucfirst,
} from "helpers/commonReducerHelpers";

export const initialState = {
  isLoading: false,
  reportingSent: false,
  files: [],
  isUnclassified: false,
  consentSubcontractor: false,
  hasPet: false,
  errors: {},
  reporting: {
    DidConsentSubcontractor: false,
    ServiceOrderType: "",
    Reporter: "",
    applicationType: "",
    Documents: [],
  },
  search: {
    items: [],
    filter: {
      // Denne skal populeres med valgte (selected) form data
      pageSize: 8,
      pageNumber: 1,
      sortByColumn: "Name",
    },
    filterMetaData: {
      // Denne skal populeres med mulige options i select felt.
      loadingEstablishments: false,
      loadingBuildings: false,
    },
    firstSearch: true,
  },
};

export default (state = initialState, action) => {
  const key = ["isUnclassified", "applicationType"].includes(action.key)
    ? action.key
    : ucfirst(action.key);
  switch (action.type) {
    case "SEND_REPORTING":
      return {
        ...state,
        isLoading: true,
      };

    case "SEND_REPORTING_SUCCESS":
      return {
        ...state,
        isLoading: false,
        reportingSent: true,
      };

    case "SAGA_ERROR_SEND_REPORTING":
      return {
        ...state,
        isLoading: false,
      };

    case "UPDATE_REPORTING":
      return {
        ...state,
        reporting: {
          ...state.reporting,
          [key]: action.value,
        },
      };

    case "UPDATE_REPORTING_ALL_ERRORS":
      return {
        ...state,
        errors: action.errors,
      };

    case "UPDATE_REPORTING_ONE_ERROR":
      return {
        ...state,
        errors: updateSingleError({ ...state.errors }, action),
      };

    case "ADD_FILE_REPORTING": {
      return {
        ...state,
        files: [
          ...state.files,
          {
            file: action.item,
            title: "",
          },
        ],
      };
    }

    case "REMOVE_FILE_REPORTING": {
      const itemToRemove = action.item;
      // NY KOD
      const index = action.index;
      const identicalNames =
        state.files.map((item) => item.file.name === itemToRemove.file.name)
          .length > 1;
      let updatedFiles = [];
      if (identicalNames && typeof index !== "undefined") {
        updatedFiles = state.files.filter(
          (item, i) => item.file.name !== itemToRemove.file.name || index !== i
        );
      } else {
        updatedFiles = state.files.filter(
          (item) => item.file.name !== itemToRemove.file.name
        );
      }
      // NY KOD

      // const updatedFiles = state.files.filter(item => (
      //   item.file.name !== itemToRemove.file.name
      // ));

      return {
        ...state,
        files: updatedFiles,
      };
    }

    case "UPDATE_FILE_DESCRIPTION_REPORTING": {
      const fileName = action.fileName;
      const newTitle = action.title;

      // NY KOD
      const index = action.index;
      const identicalNames =
        state.files.map((item) => item.file.name === fileName).length > 1;
      let updatedFiles = [];
      if (identicalNames && typeof index !== "undefined") {
        updatedFiles = state.files.map((item, i) => {
          if (item.file.name === fileName && index === i) {
            return {
              ...item,
              title: newTitle,
            };
          }
          return item;
        });
      } else {
        updatedFiles = state.files.map((item) => {
          if (item.file.name === fileName) {
            return {
              ...item,
              title: newTitle,
            };
          }
          return item;
        });
      }
      // NY KOD

      // const updatedFiles = state.files.map((item) => {
      //   if (item.file.name === fileName) {
      //     return {
      //       ...item,
      //       title: newTitle,
      //     };
      //   }
      //   return item;
      // });

      return {
        ...state,
        files: updatedFiles,
      };
    }

    case "CLEAR_REPORTING": {
      return {
        ...initialState,
        reportingSent: state.reportingSent,
      };
    }

    case "CLOSE_REPORTING_SENT_CONFIRMATION": {
      return {
        ...initialState,
      };
    }

    case "TOGGLE_IS_UNCLASSIFIED_REPORTING": {
      return {
        ...state,
        isUnclassified: !state.isUnclassified,
      };
    }

    case "TOGGLE_CONSENT_SUBCONTRACTOR_REPORTING": {
      return {
        ...state,
        consentSubcontractor: !state.consentSubcontractor,
        reportin: {
          ...state.reporting,
          didConsentSubcontractor: !state.consentSubcontractor,
        },
      };
    }

    case "TOGGLE_HAS_PET_REPORTING": {
      return {
        ...state,
        hasPet: !state.hasPet,
        reporting: {
          ...state.reporting,
          hasPet: !state.hasPet,
        },
      };
    }
    // Setter state loading = for Establishments dropdown. Prints 'Loading'
    case "FETCHING_REPORTING_FILTER_ESTABLISHMENTS":
      return {
        ...state,
        search: {
          ...state.search,
          filterMetaData: {
            ...state.search.filterMetaData,
            loadingEstablishments: true,
          },
        },
      };
    case "FETCHING_REPORTING_FILTER_BUILDINGS":
      return {
        ...state,
        search: {
          ...state.search,
          filterMetaData: {
            ...state.search.filterMetaData,
            loadingBuildings: true,
          },
        },
      };
    // yield put({ type: 'RECEIVE_REPORTING_FILTER_ESTABLISHMENTS', value: data, key: action.key });
    case "RECEIVE_REPORTING_FILTER_ESTABLISHMENTS":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            establishment: "",
          },
          filterMetaData: {
            ...state.search.filterMetaData,
            establishments: mapArrayObjectsToCamel(action.value),
            loadingEstablishments: false,
          },
        },
      };
    case "RECEIVE_REPORTING_FILTER_BUILDINGS":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            building: "",
          },
          filterMetaData: {
            ...state.search.filterMetaData,
            buildings: mapArrayObjectsToCamel(action.value),
            loadingBuildings: false,
          },
        },
      };

    case "UPDATE_REPORTING_FILTER":
      return {
        ...state,
        search: {
          ...state.search,
          filter: {
            ...state.search.filter,
            [action.key]: action.value,
          },
        },
      };

    default:
      return state;
  }
};
