import './ApplicationSummary.scss';

import {
  clearApplication,
  sendApplication,
  updateAllApplicationErrors,
  updateApplication,
  updateSingleApplicationError,
} from 'actions/housingActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Checkbox from 'components/form/Checkbox/Checkbox';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import UnclassifiedStamp from 'components/form/UnclassifiedStamp/UnclassifiedStamp';
import RenderApplicationSummary from 'components/HousingApplication/RenderApplicationSummary';
import Link from 'components/Link/Link';
import Loader from 'components/Loader/Loader';
import Modal from 'components/modals/Modal/Modal';
import RouterModal from 'components/modals/RouterModal/RouterModal';
import DOMPurify from 'dompurify';
import connect from 'helpers/connectWithRouter';
import { APPLICANT_SPECIAL_CATEGORY } from 'helpers/housingHelpers';
import moment from 'moment';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropSlideNavigation } from 'types/PropNavigationBase';
import { getSiblingPath } from 'utils/helpers';
import * as yup from 'yup';

import UserNotificationSmsCheckbox from '../../components/UserNotificationPreference/UserNotificationSmsCheckbox';

import type {
  HousingApplicationType,
  QuarterApplicationType,
  HousingAreaType,
} from 'types/housingTypes';
import type { RoundInfoType } from 'types/housingAdminTypes';
const locationAfterSentApplication = '/my-page/tab-my-housing-applications';

type PropsType = PropSlideNavigation & {
  applicationType: string;
  application: HousingApplicationType | QuarterApplicationType;
  housingArea: HousingAreaType;
  serviceAreaName: string;
  sendingApplication: boolean;
  sentApplication: boolean;
  errors: object;
  updateAllApplicationErrors: (errors: object) => void;
  updateSingleApplicationError: (name: string, errors: object) => void;

  nextSlidePath: string;
  clearApplication: () => void;
  updateApplication: (name: string, value: string | boolean) => void;
  updateApplicationWithType: (
    applicationType: string,
    name: string,
    value: string | boolean,
  ) => void;
  sendApplication: (
    application: HousingApplicationType | QuarterApplicationType,
  ) => void;
  defaultTo: string;
  housingDataOptionsLoaded: boolean;
  roundInfo: RoundInfoType;
  housingConditionsHtml: string;
  quarterConditionsHtml: string;
  defaultDocumentationDeadline: string;
  userData: DeliberateAny;
};

export class UnconnectedApplicationSummary extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);

    if (!props.housingDataOptionsLoaded) {
      props.goToSlide(
        getSiblingPath(props.location.pathname, props.defaultTo),
        true,
      );
    }

    const requiredDocumentation = this.getRequiredDocumentationList();
    this.props.updateApplication(
      'requiredDocumentation',
      requiredDocumentation.length > 0 ? requiredDocumentation.join(', ') : '',
    );

    this.props.updateApplication(
      'receiveSMSNotifications',
      this.props.userData.ReceiveHousingSms === 'true',
    );

    if (this.props.roundInfo) {
      this.props.updateApplication(
        'documentationDeadline',
        moment(this.props.roundInfo.applicationDeadline)
          .add(7, 'd')
          .toISOString(),
      );
    } else {
      this.props.updateApplication(
        'documentationDeadline',
        this.props.defaultDocumentationDeadline,
      );
    }
  }

  componentWillUnmount() {
    if (this.props.sentApplication) {
      this.props.clearApplication();
    }
  }

  onSubmit = () => {
    this.props.sendApplication(this.props.application);
    this.props.updateApplicationWithType(
      this.props.applicationType,
      'isRenewal',
      false,
    );
  };

  onCloseSentModal = () => {
    this.props.navigate(locationAfterSentApplication);
  };

  getRequiredDocumentationList = () => {
    const docs: DeliberateAny = [];

    if (
      this.props.application.type === 'housing' &&
      this.props.application.isPregnant
    ) {
      docs.push(
        'Erklæring fra lege eller jordmor (ved avkryssing på at det ventes barn)',
      );
    }

    const special =
      this.props.application.applicantCategory ===
      APPLICANT_SPECIAL_CATEGORY.toString();

    if (special) {
      docs.push(
        'Dokumentasjon for spesielt grunnlag (der dette er valgt som målgruppe)',
      );
    }

    if (this.props.application.assignmentDocumentation) {
      docs.push('Ettersending av beordring');
    }

    return docs;
  };

  toggleCheckbox = (name: string, value: boolean) => {
    this.props.updateApplication(name, value);
  };

  routerModalPath = `${location.pathname}/modal`;
  viewSummaryPath = () => this.routerModalPath;

  renderRequiredDocumentation = () => {
    const requirementsList = this.getRequiredDocumentationList();
    const housingApplicationText = () => (
      <span>
        Basert på dine valg må du levere dokumentasjon på følgende for å få
        poeng med i beregningen:
        <ul className="document-list">
          {requirementsList.map((requirement) => (
            <li key={requirement}>{requirement}</li>
          ))}
        </ul>
        <span className="bg-gray">
          <strong className="red">MERK!</strong> Du mister viktige poeng i
          vurderingen om nødvendige vedlegg ikke er ettersendt innen
          søknadsfristens utløp.
        </span>
      </span>
    );
    const noDocNeededText = () => (
      <p className="no-doc">
        Basert på dine opplysninger trenger du ikke sende inn noen
        dokumentasjon. Det kan likevel bli etterspurt i forbindelse med
        kontroller på et senere tidspunkt.
      </p>
    );
    const quarterApplicationText = () => (
      <span>
        Basert på dine valg må du levere dokumentasjon på følgende for å få
        søknaden godkjent:
        <ul className="document-list">
          {requirementsList.map((requirement) => (
            <li key={requirement}>{requirement}</li>
          ))}
        </ul>
        <span>
          <strong className="red">MERK!</strong> Søknaden kan ikke godkjennes om
          nødvendige vedlegg ikke er ettersendt på FISBasis innen{' '}
          {moment(this.props.application.documentationDeadline).format(
            'DD.MM.YYYY',
          )}
          .
        </span>
      </span>
    );

    if (requirementsList.length > 0) {
      return (
        <div className="margin">
          <h4>Dokumentasjonskrav</h4>
          {this.props.applicationType === 'housing'
            ? housingApplicationText()
            : quarterApplicationText()}
        </div>
      );
    }
    return noDocNeededText();
  };

  validationSchema = yup
    .object({
      saveInfo: yup.bool().oneOf([true], 'required'),
      terms: yup.bool().oneOf([true], 'required'),
    })
    .defined();

  render() {
    const formProps = {
      errors: this.props.errors,
      form: {
        ...this.props.application,
      },
      schema: this.validationSchema,
      onSubmit: this.onSubmit,
      onChange: this.props.updateApplication,
      updateAllValidationErrors: this.props.updateAllApplicationErrors,
      updateOneValidationError: this.props.updateSingleApplicationError,
    };
    const applicationTypeName =
      this.props.applicationType === 'housing' ? 'bolig' : 'kvarter';
    const conditionsHtml =
      this.props.applicationType === 'housing'
        ? this.props.housingConditionsHtml
        : this.props.quarterConditionsHtml;

    return (
      <div>
        <UnclassifiedStamp />
        <h1>Oppsummering</h1>
        <div className="margin">
          <RenderApplicationSummary application={this.props.application} />
        </div>
        {this.renderRequiredDocumentation()}
        <RouterModal
          path={this.routerModalPath}
          size="medium"
          ariaLabel="Vis vilkår"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(conditionsHtml),
            }}
          />
        </RouterModal>
        <FormWrapper {...formProps}>
          <Checkbox
            className="margin"
            type="checkbox"
            checked={this.props.application.terms}
            onChange={this.toggleCheckbox}
            name="terms"
            label={
              <span>
                Jeg bekrefter at jeg har lest
                <Link to={this.viewSummaryPath()}> vilkårene</Link> for{' '}
                {applicationTypeName} og jeg har ikke lagt ved gradert
                informasjon i søknaden.
              </span>
            }
            errors={this.props.errors}
          />
          <Checkbox
            className="margin"
            type="checkbox"
            checked={this.props.application.saveInfo}
            onChange={this.toggleCheckbox}
            name="saveInfo"
            label="Jeg godtar at Forsvarsbygg og Forsvaret lagrer mine personopplysninger"
            errors={this.props.errors}
          />
          <UserNotificationSmsCheckbox
            className="margin"
            checked={this.props.application.receiveSMSNotifications}
            onChange={this.toggleCheckbox}
            name="receiveSMSNotifications"
            label="Jeg ønsker å motta varsler om søknaden på SMS."
            notificationDomain="Applications"
          />
          <div className="margin">
            <ButtonRow>
              <ButtonRow.Right
                color="red"
                type="submit"
                disabled={!this.props.housingArea}
              >
                {this.props.housingArea
                  ? 'Send inn søknad'
                  : 'Finner ikke bo-område. Kontakt forsvarsbygg'}
              </ButtonRow.Right>
            </ButtonRow>
          </div>
        </FormWrapper>
        <Modal
          isOpen={this.props.sendingApplication}
          cancel={{ hide: true }}
          submit={{ hide: true }}
          ariaLabel="Sender boligsøknad"
        >
          <Loader isLoading />
        </Modal>
        <Modal
          isOpen={this.props.sentApplication}
          cancel={{ hide: true }}
          submit={{
            text: 'Gå til min side',
            onClick: this.onCloseSentModal,
          }}
          ariaLabel="Boligsøknad sendt inn"
        >
          <h2>Søknaden er registrert!</h2>
          <p>
            Du har søkt om {applicationTypeName} på {this.props.serviceAreaName}
            . En kopi av søknaden er sendt til{' '}
            {this.props.userData.SecondaryEmail || this.props.userData.Email}
          </p>
          {this.props.application.requiredDocumentation.length > 0 && (
            <div className="margin">
              <p>
                Nødvendig dokumentasjon ettersendes til boligsekretariatet. Se
                kontaktinformasjon ved å klikke på «Gå til min side»
              </p>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny, props: PropsType) => ({
    errors: state.housing.errors,
    application: state.housing[`${props.applicationType}Application`],
    housingArea: state.housing.housingArea,
    serviceAreaName: state.housing.serviceAreaName,
    sendingApplication: state.housing.sendingApplication,
    sentApplication: state.housing.sentApplication,
    housingDataOptionsLoaded: state.options.housingDataOptionsLoaded,
    roundInfo: state.housing.roundInfo,
    defaultDocumentationDeadline:
      state.options.housingDataOptions.defaultDocumentationDeadline,
    housingConditionsHtml: state.housing.housingInfoText.HousingConditionsHtml,
    quarterConditionsHtml: state.housing.housingInfoText.QuarterConditionsHtml,
  }),
  (dispatch, props) =>
    bindActionCreators(
      {
        clearApplication,
        updateApplication: (...args: [string, string | boolean]) =>
          updateApplication(props.applicationType, ...args),
        updateApplicationWithType: (...args: [string, string | boolean]) =>
          updateApplication(props.applicationType, ...args),
        updateAllApplicationErrors: (...args: [DeliberateAny]) =>
          updateAllApplicationErrors(props.applicationType, ...args),
        updateSingleApplicationError: (...args: [string, DeliberateAny]) =>
          updateSingleApplicationError(props.applicationType, ...args),
        sendApplication,
      },
      dispatch,
    ),
)(UnconnectedApplicationSummary);
