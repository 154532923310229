import { useRef } from 'react';

export const useMutex = () => {
  const lock = useRef(false);

  const isLocked = (): boolean => lock.current;

  const lockMutex = (): void => {
    lock.current = true;
  };

  const unlockMutex = (): void => {
    lock.current = false;
  };

  return {
    isLocked,
    lock: lockMutex,
    unlock: unlockMutex,
  };
};
