/* eslint react/prop-types: 0 */

import List from 'components/List/List';
import Loader from 'components/Loader/Loader';
import { useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import { v4 as uuidv4 } from 'uuid';

import {
  allocationRoundURL,
  housingServiceAreasURL,
  myApplicationsURL,
} from '../../../api/housing/housing_api';
import { axiosFetch } from '../../../hooks/useAxios';
import HousingApplicationsColumns from './HousingApplicationsColumns';
import HousingApplicationsItem from './HousingApplicationsItem';

const HousingApplicationsList = () => {
  const [applications, setApplications] = useState<DeliberateAny>();
  const [runUseEffect, setRunUseEffect] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const renderItem = (item) => (
    <HousingApplicationsItem
      key={uuidv4()}
      item={item}
      columns={HousingApplicationsColumns}
      setRunUseEffect={setRunUseEffect}
    />
  );

  useEffect(() => {
    const asyncFunction = async () => {
      const myApplications = await axiosFetch({ url: myApplicationsURL });
      const housingServiceAreas = await axiosFetch({
        url: housingServiceAreasURL,
      });

      const allocationRounds = myApplications.MyHousingApplications.map(
        async (el) => {
          if (!el.IsRenewal) {
            const response = await axiosFetch({
              url: `${allocationRoundURL}?roundId=${el.AllocationRoundId}`,
            });

            // Må sette opp både formatet for vintertid og sommertid
            if (
              response.ApplicationDeadline === '0001-01-01T00:00:00+00:00' ||
              response.ApplicationDeadline === '0001-01-01T00:00:00+01:00'
            ) {
              response.deadline = 'Fortløpende tildeling';
            } else {
              response.deadline = new Date(
                response.ApplicationDeadline
              ).toLocaleDateString('ru');
            }
            return response;
          }
          return false;
        }
      );

      const deadlines = (await Promise.all(allocationRounds)).filter(
        (x) => x.ApplicationDeadline > '1001-01-01'
      );

      const mergedList = myApplications.MyHousingApplications.concat(
        myApplications.MyQuarterApplications
      );

      const myHousingApplicationList = mergedList.map((el) => {
        const serviceArea = el.ServiceAreaId
          ? housingServiceAreas.ServiceAreas.find(
              (e) => e.Id === el.ServiceAreaId
            )
          : null;
        const serviceAreaArenaId = serviceArea ? serviceArea.ArenaId : null;

        return {
          StateDescriptionHtml: el.StateDescriptionHtml ?? '',
          DateApplied: el.DateApplied,
          HousingSecretaryEmail: el.HousingSecretaryEmail
            ? el.HousingSecretaryEmail
            : housingServiceAreas.HousingAreas.find(
                (e) => e.Id === el.HousingAreaId
              ).HousingSecretaryEmail,
          ApplicationNumber: el.ApplicationNumber,
          RequiredDocumentation: el.RequiredDocumentation,
          ArenaId: serviceAreaArenaId,
          HousingAreaName: el.HousingAreaName,
          ServiceAreaName: el.ServiceAreaName,
          ApplicationState: el.ApplicationState
            ? el.ApplicationState
            : el.State,
          ApplicationType: el.Details ? 'quarter' : 'housing',
          ApplicationTypeName: el.Details ? 'Kvarter' : 'Bolig',
          Deadline:
            el.AllocationRoundId && !el.IsRenewal
              ? deadlines.find((e) => e.Id === el.AllocationRoundId)?.deadline
              : // ?? "Fortløpende tildeling"
                'Fortløpende tildeling',
          CommentsForApplicant: el.CommentsForApplicant,
          AssignmentDocumentationReceivedDeadline: el.Details
            ? el.Details.AssignmentDocumentationReceivedDeadline
            : el.AssignmentDocumentationReceivedDeadline,
          Id: el.Id,
          FullName: el.Details ? el.Details.FullName : el.FullName,
          EmployeeNumber: el.Details
            ? el.Details.EmployeeNumber
            : el.EmployeeNumber,
          DateOfBirth: el.Details ? el.Details.DateOfBirth : el.DateOfBirth,
          ApplicantId: el.Details ? el.Details.ApplicantId : el.ApplicantId,
          ApplicantCategory: el.Details
            ? el.Details.ApplicantCategory
            : el.ApplicantCategory,
          NewWorkTitle: el.Details ? el.Details.NewWorkTitle : el.NewWorkTitle,
          IsDefenceEmployee: el.Details
            ? el.Details.IsDefenceEmployee
            : el.IsDefenceEmployee,
          AssignmentFromDate: el.Details
            ? el.Details.AssignmentFromDate
            : el.AssignmentFromDate,
          AssignmentToDate: el.Details
            ? el.Details.AssignmentToDate
            : el.AssignmentToDate,
          AssignmentDocumentation: el.Details
            ? el.Details.AssignmentDocumentation
            : el.AssignmentDocumentation,
          CurrentHousingSituation: el.Details
            ? el.Details.CurrentHousingSituation
            : el.CurrentHousingSituation,
          CurrentHousingZipCode: el.Details
            ? el.Details.CurrentHousingZipCode
            : el.CurrentHousingZipCode,
          HousingPreferences: el.Details
            ? el.Details.HousingPreferences
            : el.HousingPreferences,
          SharedHousingAcceptable: el.Details
            ? el.Details.SharedHousingAcceptable
            : el.SharedHousingAcceptable,
          IsMainApplicant: el.Details
            ? el.Details.IsMainApplicant
            : el.IsMainApplicant,
          WantedFromDate: el.Details
            ? el.Details.WantedFromDate
            : el.WantedFromDate,
          ServiceAreaId: el.Details
            ? el.Details.ServiceAreaId
            : el.ServiceAreaId,
          HousingAreaId: el.Details
            ? el.Details.HousingAreaId
            : el.HousingAreaId,
          ApplicationPoints: el.ApplicationPoints,
          MaritalStatus: el.MaritalStatus,
          IsPregnant: el.IsPregnant,
          Housing: el.Housing,
          Children:
            el.Children &&
            el.Children.map((child) => ({
              name: child.Name,
              dateOfBirth: child.DateOfBirth,
            })),
          PetCount: el.PetCount,
          PetInfo: el.PetInfo,
          PrimaryEmail: el.PrimaryEmail,
          OtherInfo: el.OtherInfo,
          CurrentHousingCounty: el.CurrentHousingCounty,
          RejectionReason: el.RejectionReason,
        };
      });

      setApplications(
        myHousingApplicationList.sort((a, b) => {
          const dateA = a.DateApplied.toUpperCase();
          const dateB = b.DateApplied.toUpperCase();
          if (dateA < dateB) {
            return 1;
          }
          if (dateA > dateB) {
            return -1;
          }
          return 0;
        })
      );
    };
    asyncFunction();
  }, [runUseEffect]);

  return (
    <div>
      <Loader isLoading={!applications}>
        {applications && (
          <List
            columns={HousingApplicationsColumns}
            renderItem={renderItem}
            items={applications}
            clientPaging={{
              pageNumber,
              pageSize: 5,
              pageCount,
              numItems: applications.length,
              onChangePageNumber: (number) => setPageNumber(number),
              onSetPageCount: (number) => setPageCount(number),
            }}
            noItemsMessage={<p>Ingen leieforhold registrert</p>}
          />
        )}
      </Loader>
    </div>
  );
};

export default HousingApplicationsList;
