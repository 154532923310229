import useLocalStorage from 'hooks/useLocalStorage';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';

import { useLogin, useUser } from '../../contexts';
import Loader from '../Loader/Loader';

interface ProtectedRouteProps {
  children: ReactElement;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const [jwt] = useLocalStorage('jwtToken', '');
  const navigate = useNavigate();
  const location = useLocation();
  const { user, hasUser, requestLoading } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const { setShow, setLoginUrl } = useLogin();

  useEffect(() => {
    setIsLoading(requestLoading);
    if (!requestLoading && !hasUser && !!user) {
      setShow(true);
      setLoginUrl('/#' + location.pathname);
      navigate('/');
    }
  }, [requestLoading, navigate, user, jwt, hasUser]);

  if (isLoading) {
    return <Loader isLoading={true} />;
  }

  return hasUser ? children : null;
};

export default ProtectedRoute;
