import './ComboMultiSelect.scss';

import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { KeyValueType } from 'types/commonTypes';

export type ValueOption = {
  key: string;
  value: string;
  disabled?: boolean;
};

export interface IComboSelectBoxProps {
  name: string;
  label?: ReactNode;
  helpText?: string;
  disabled?: boolean;
  placeHolder?: string;
  required?: boolean;
  initial?: string;
  options: ValueOption[];
  requiredChars?: number;
  values: KeyValueType[];
  onChange: (values: KeyValueType[]) => void;
  isLoading?: boolean;
  loadingText?: string;
}

const ComboMultiSelect: FC<IComboSelectBoxProps> = ({
  name,
  options,
  disabled,
  placeHolder,
  requiredChars = 0,
  required,
  values,
  onChange,
  isLoading,
  loadingText,
}) => {
  const noMatch: ValueOption[] = [
    { key: 'nocontent', value: 'Ingen treff', disabled: true },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [chosen, setChosen] = useState<ValueOption[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const searchRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [filteredOptions, setFilteredOptions] =
    useState<ValueOption[]>(options);

  useEffect(() => {
    const filtered = options.filter(
      (option) =>
        option.value.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.key.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredOptions(filtered && filtered.length ? filtered : noMatch);
  }, [options, searchTerm]);

  useEffect(() => {
    setIsOpen(searchTerm.length >= requiredChars);
  }, [searchTerm]);

  useEffect(() => {
    const selectedOptions = options.filter((option) => {
      return values.map((valueItem) => valueItem.key).includes(option.key);
    });
    setChosen(selectedOptions);
  }, [values, options]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleItemClick = (item: ValueOption) => {
    if (item.disabled) return;

    let newSelection: KeyValueType[];

    if (values.map((valueItem) => valueItem.key).includes(item.key)) {
      newSelection = values.filter((valueItem) => valueItem.key != item.key);
    } else {
      newSelection =
        item.key === 'alle'
          ? [{ key: item.key, value: item.value }]
          : [
              ...values.filter((item) => item.key !== 'alle'),
              { key: item.key, value: item.value },
            ];
    }
    onChange(newSelection);
  };

  useEffect(() => {
    setIsOpen(false);
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef} className="combo-select-box-container">
      <div className="combo-input">
        <input
          name={name}
          ref={searchRef}
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder={
            isLoading
              ? loadingText
                ? loadingText
                : 'laster...'
              : chosen.length
                ? chosen.map((item) => item.value).join(', ')
                : placeHolder
          }
          disabled={disabled}
          className={`combo-input-field ${disabled ? 'disabled' : ''}`}
          onFocus={() => setIsOpen(searchTerm.length >= requiredChars)}
          required={required}
        />
        {isOpen && (
          <ul className="dropdown-list">
            {filteredOptions.map((item) => (
              <li
                key={item.value}
                className={`dropdown-item ${item.disabled ? 'disabled' : ''}`}
                onClick={() => handleItemClick(item)}
              >
                {item.value}
                {values
                  .map((valueItem) => valueItem.value)
                  .includes(item.value) && (
                  <span className="selected-icon">✔</span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ComboMultiSelect;
