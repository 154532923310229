import './MyAdditionalServiceOrders.scss';

import { fetchStaticFilterOptions } from 'actions/optionsActions';
import Loader from 'components/Loader/Loader';
import Pagination from 'components/Pagination/Pagination';
import useAxios from 'hooks/useAxios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DeliberateAny } from 'types/DelibrateAny';

import { myAdditionalServiceOrdersURL } from '../../../../api/basenproff/basenproff_api';
import AdditionalServicesServiceOrderList from '../AdditionalServicesServiceOrders/AdditionalServicesServiceOrderList';

const serviceOrderSearchBase = myAdditionalServiceOrdersURL;

const MyAdditionalServiceOrders = ({
  staticFilterOptions,
  staticFilterOptionsLoaded,
}) => {
  const [searchQueryParam, setSearchQueryParam] = useState({
    SkipCount: 0,
    TakeCount: 10,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [additionalServices, setAdditionalServices] = useState<DeliberateAny[]>(
    [],
  );
  const [optionsStatus, setOptionsStatus] = useState<DeliberateAny[]>([]);

  const {
    requestLoading: additionalServicesLoading,
    sendRequest: fetchAdditionalServices,
  } = useAxios();

  const asyncEffect = async (url) => {
    if (!staticFilterOptionsLoaded) {
      fetchStaticFilterOptions();
    }

    const getExtraServicesData = (data) => {
      setAdditionalServices(data);
    };
    fetchAdditionalServices(
      {
        method: 'GET',
        url: url !== undefined ? url : serviceOrderSearchBase,
      },
      getExtraServicesData,
    );
  };

  const buildQuery = (query) =>
    Object.keys(query)
      .map((key) => key + '=' + query[key])
      .join('&');

  useEffect(() => {
    const query = buildQuery(searchQueryParam);
    asyncEffect(serviceOrderSearchBase + '?' + query);
  }, []);

  useEffect(() => {
    if (staticFilterOptionsLoaded) {
      setOptionsStatus(staticFilterOptions.serviceOrderStates);

      if (additionalServices.length > 0) {
        setAdditionalServices(
          additionalServices.map((item) => ({
            ...item,
            ServiceOrderStatePrintable:
              optionsStatus.length > 0
                ? optionsStatus.find((o) => o.key === item.ServiceOrderState)
                    .value
                : '',
          })),
        );
      }
    }
  }, [additionalServices.length, staticFilterOptionsLoaded]);

  const navigateToPage = (pagenum) => {
    const page = { SkipCount: (pagenum - 1) * searchQueryParam.TakeCount };
    const query = buildQuery({ ...searchQueryParam, ...page });
    asyncEffect(serviceOrderSearchBase + '?' + query);
  };

  const serverPaging = {
    pageNumber,
    pageSize: 10,
    numItems: additionalServices.length,
    onChangePageNumber: (number) => {
      setPageNumber(number);
      navigateToPage(number);
    },
  };

  const setPaginationCount = (count) => {
    const query = buildQuery({
      ...searchQueryParam,
      SkipCount: 0,
      TakeCount: count,
    });
    setSearchQueryParam({
      ...searchQueryParam,
      SkipCount: 0,
      TakeCount: count,
    });
    asyncEffect(serviceOrderSearchBase + '?' + query);
  };

  return (
    <div className="max-width tab-service-orders" style={{ display: 'block' }}>
      <div className="service-orders">
        <div className="row">
          <h2>Mine tilleggstjeneste-avtaler</h2>
        </div>
        <div className="row">
          <p>
            <strong>
              Her ser du en oversikt over dine serviceordre av typen
              tilleggstjenester.
            </strong>
          </p>
        </div>
        <Loader isLoading={additionalServicesLoading}>
          <div className="button-row half-margin">
            <Pagination
              setPaginationCount={setPaginationCount}
              paginationCount={searchQueryParam.TakeCount}
            />
          </div>
          <AdditionalServicesServiceOrderList
            items={additionalServices}
            // navigateToPage={navigateToPage}
            serverPaging={serverPaging}
            urlRoot="/basenproff"
          />
        </Loader>
      </div>
    </div>
  );
};

export default connect(
  (state: DeliberateAny) => ({
    staticFilterOptions: state.options.staticFilterOptions,
    staticFilterOptionsLoaded: state.options.staticFilterOptionsLoaded,
  }),
  { fetchStaticFilterOptions },
)(MyAdditionalServiceOrders);
