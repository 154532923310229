// @flow

import { fetchHousingInfoText } from 'actions/housingActions';
import { fetchHousingDataOptions } from 'actions/optionsActions';
import LabelValueList, {
  LabelValueListItemType,
} from 'components/LabelValueList/LabelValueList';
import Loader from 'components/Loader/Loader';
import SafeHtmlRenderer from 'components/SafeHtmlRenderer';
import connect from 'helpers/connectWithRouter';
import {
  getLabelFromValue,
  getNameFromId,
  getValueFromKey,
} from 'helpers/housingAdminHelpers';
import { IS_MAIN_APPLICANT_LABEL } from 'helpers/housingHelpers';
import { isServerDate } from 'helpers/valueHelpers';
import _ from 'lodash/fp';
import moment, { Moment } from 'moment';
import { Component } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import {
  ApplicationLabelsType,
  HousingApplicationType,
  HousingRenewalApplicationType,
  InfoTextType,
  QuarterApplicationType,
} from 'types/housingTypes';
import { HousingDataOptionsType } from 'types/optionsTypes';

import { tryParseJson } from '../../helpers/json';

export const applicationLabels: ApplicationLabelsType & DeliberateAny = {
  // Order of properties defines order in summmary view!
  fullName: 'Navn',
  employeeNumber: 'Ansattnummer',
  dateOfBirth: 'Fødseldato',
  applicantId: 'Brukernavn',
  primaryEmail: 'E-postadresse',
  secondaryEmail: 'Sekundær e-postadresse',
  cellPhone: 'Mobiltelefon',
  type: 'Søknadstype',
  applicationNumber: 'Søknadsnummer',
  applicationPoints: 'Antall poeng',
  dateApplied: 'Søknad registrert',
  applicantCategory: 'Personellkategori',
  newWorkTitle: 'Ny tjenestestilling',
  isDefenceEmployee: 'Arbeidsgiver',
  assignmentDocumentation: 'Dokumentasjon av beordring',
  serviceAreaId: 'Tjenestested',
  housingAreaId: 'Boområde',
  maritalStatus: 'Sivilstand for fremtidig boforhold',
  significantOtherBirthdate: 'Samboers fødselsdato',
  cohabitantJointAdr: 'Felles adresse i Folkeregisteret',
  isCohabitationLongTerm: 'Samboerskap over 9 mnd?',
  isPregnant: 'Venter barn',
  children: 'Barn',
  petCount: 'Antall dyr',
  petInfo: 'Type husdyr',
  otherInfo: 'Opplysninger om husdyrforhold',
  currentHousingSituation: 'Nåværende boforhold',
  currentHousingSituation_0: 'Eier egen bolig',
  currentHousingSituation_1: 'Leier militært',
  currentHousingUnitNumber: 'Leilighetsnummer',
  currentHousingAddress: 'Adresse/leir',
  currentHousingCounty: 'Kommune',
  currentHousingZipCode: 'Postnummer',
  housingPreferences: 'Særskilte behov',
  sharedHousingAcceptable: 'Villig til å bo i kollektiv',
  isMainApplicant: IS_MAIN_APPLICANT_LABEL,
  wantedFromDate: 'Boenhet ønskes fra',
  wantedToDate: 'Boenhet ønskes til',
  coApplicants: 'Medsøkere',
};

type PropsType = {
  application:
    | HousingApplicationType
    | QuarterApplicationType
    | HousingRenewalApplicationType;
  housingDataOptions: HousingDataOptionsType;
  fetchHousingDataOptions: () => void;
  fetchHousingInfoText: () => void;
  isLoading: boolean;
  infoText: InfoTextType;
};

export class UnconnectedRenderApplicationSummary extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    this.props.fetchHousingDataOptions();
    this.props.fetchHousingInfoText();
  }

  getApplicationSummary = (
    application:
      | HousingApplicationType
      | QuarterApplicationType
      | HousingRenewalApplicationType,
  ): Array<LabelValueListItemType> => {
    const unorderdedSummaryList = this.getUnorderedSummary(application);
    const sortOrder = _.values(applicationLabels);
    return unorderdedSummaryList.sort(
      (a, b) => sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label),
    );
  };

  getUnorderedSummary = (
    application:
      | HousingApplicationType
      | QuarterApplicationType
      | HousingRenewalApplicationType,
  ): Array<LabelValueListItemType> =>
    Object.keys(application)
      .filter((key) => {
        const hasContent = (obj, prop) =>
          Object.prototype.hasOwnProperty.call(obj, prop) &&
          !_.isNil(obj[prop]) &&
          obj[prop].toString().length > 0;
        const passRenewalCheck = (obj, prop) => {
          return !obj.isRenewal
            ? prop !== 'wantedToDate'
            : prop !== 'applicationNumber';
        };
        const passSumibCheck = (obj, prop) => {
          return obj.applicantCategory === 10
            ? prop === 'wantedToDate'
            : prop !== 'wantedToDate';
        };

        return (
          hasContent(application, key) &&
          hasContent(applicationLabels, key) &&
          (passRenewalCheck(application, key) ||
            passSumibCheck(application, key))
        );
      })
      .map((key) => {
        const label = applicationLabels[key];
        let value;
        switch (key) {
          case 'serviceAreaId':
            if (application[key]) {
              value = getNameFromId(
                this.props.housingDataOptions.ServiceAreas,
                application[key] ?? '',
              );
            }
            break;
          case 'housingAreaId':
            if (application[key]) {
              value = getNameFromId(
                this.props.housingDataOptions.HousingAreas,
                application[key] ?? '',
              );
            }
            break;
          case 'applicantCategory':
            value = getLabelFromValue(
              this.props.housingDataOptions[
                `${this.categoryType(application.type)}Categories`
              ],
              application[key],
            );
            value = tryParseJson(value.toString())
              ? tryParseJson(value.toString()).text
              : value;
            break;
          case 'assignmentDocumentation':
            value = application[key] ? 'Ja' : 'Nei';
            break;
          case 'currentHousingSituation':
            value = getValueFromKey(
              this.props.housingDataOptions.livingConditionOptions,
              application[key],
            );
            break;
          case 'cohabitantJointAdr':
            switch (parseInt(application.cohabitantJointAdr ?? '-1', 10)) {
              case 1:
                value = 'Under 9 måneder';
                break;
              case 2:
                value = '9 måneder eller lengre';
                break;
              default:
                break;
            }
            break;
          case 'isCohabitationLongTerm':
            value = application.isCohabitationLongTerm ? 'Ja' : 'Nei';
            break;

          case 'currentHousingSituation_0':
            value = application.currentHousingSituation_0 ? 'Ja' : 'Nei';
            break;

          case 'currentHousingSituation_1':
            value = 'Nei';
            if (application.isRentingMilitary) {
              switch (application.isRentingMilitary) {
                case 1:
                  value = 'Kvarter';
                  break;
                case 2:
                  value = 'Bolig';
                  break;
                default:
                  break;
              }
            }
            break;

          case 'maritalStatus':
            if (
              application.type === 'housing' ||
              application.type === 'housingrenewal'
            ) {
              value = getValueFromKey(
                this.props.housingDataOptions.maritalStatusOptions,
                application.maritalStatus,
              );
            }
            break;
          case 'children':
            value = application[key].map((child) => ({
              list: [
                {
                  label: 'Fødselsdato',
                  value: isServerDate(child.dateOfBirth)
                    ? moment(child.dateOfBirth).format('DD.MM.YYYY')
                    : child.dateOfBirth,
                },
              ],
            }));
            break;
          case 'coApplicants':
            value = application[key].map((coApplicant) => ({
              list: [
                {
                  label: 'Navn',
                  value: coApplicant.name,
                },
                {
                  label: 'Ansattnummer',
                  value: coApplicant.employeeNumber,
                },
              ],
            }));
            break;
          case 'type':
            if (
              application[key] === 'housing' ||
              application[key] === 'housingrenewal'
            ) {
              value = 'Bolig';
            } else {
              value = 'Kvarter';
            }
            break;
          case 'isPregnant':
          case 'sharedHousingAcceptable':
          case 'isMainApplicant':
            if (application[key]) {
              value = 'Ja';
            } else {
              value = 'Nei';
            }
            break;
          case 'isDefenceEmployee':
            if (application[key]) {
              value = this.props.infoText.EmployeeTypeDefenseText;
            } else {
              value = this.props.infoText.EmployeeTypeOtherText;
            }
            break;
          default:
            value = application[key];
        }
        if (value instanceof moment) {
          if (this.invalidMoment(moment(value))) {
            value = moment(value).format('DD.MM.YYYY');
            if (moment(value, 'DD.MM.YYYY').year() >= 9999) value = '';
          } else {
            value = '';
          }
        }
        if (
          typeof value === 'string' &&
          key !== 'currentHousingZipCode' &&
          key !== 'cellPhone' &&
          key !== 'employeeNumber' &&
          isServerDate(value)
        ) {
          if (this.invalidMoment(value)) {
            value = moment(value).format('DD.MM.YYYY');
            if (moment(value, 'DD.MM.YYYY').year() >= 9999) value = '';
          } else {
            value = '';
          }
        }
        return {
          label,
          value,
        };
      });

  invalidMoment = (myMoment: Moment | string) => {
    const minDate = moment.utc('2000-01-01');
    return moment.utc(myMoment).isAfter(minDate);
  };

  categoryType = (categoryType: string) => {
    return categoryType === 'housingRenewalArea' ? 'housing' : categoryType;
  };

  renderApplicationPointsInformation = () => (
    <div className="margin">
      <h4>{this.props.infoText.ApplicationPointsInfoHeading}</h4>
      <SafeHtmlRenderer
        response={this.props.infoText.ApplicationPointsInfoTextHtml}
      />
    </div>
  );

  render() {
    return (
      <Loader isLoading={this.props.isLoading}>
        {!this.props.isLoading && (
          <div>
            <div className="margin">
              <LabelValueList
                items={this.getApplicationSummary(this.props.application)}
                style={{
                  receiptStyle: true,
                }}
              />
            </div>
            {this.categoryType(this.props.application.type) === 'housing' &&
              this.renderApplicationPointsInformation()}
          </div>
        )}
      </Loader>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    housingDataOptions: state.options.housingDataOptions,
    isLoading:
      state.housing.isLoading || !state.options.housingDataOptionsLoaded,
    infoText: state.housing.housingInfoText,
  }),
  {
    fetchHousingDataOptions,
    fetchHousingInfoText,
  },
)(UnconnectedRenderApplicationSummary);
