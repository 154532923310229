import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

interface SlideInfo {
  position: string;
  element: React.ReactElement;
}

interface SlidesProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  startSlide?: string;
}

const SlidesWithoutRoute: React.FC<SlidesProps> = ({ children, className, startSlide }) => {
  const [currentSlide, setCurrentSlide] = useState<
    React.ReactElement | undefined
  >(undefined);
  const [currentPosition, setCurrentPosition] = useState<string>("");

  const nodeRef = useRef(null);

  const location = useLocation();

  const findSlideByPath = (path: string): SlideInfo | undefined => {
    const slidesArray = React.Children.toArray(
      children
    ) as React.ReactElement[];
    const index = slidesArray.findIndex((slide) => slide.props.path === path);

    if (index >= 0) {
      return {
        position: String(index),
        element: slidesArray[index],
      };
    }
    if(startSlide){
      const getIndex = slidesArray.findIndex((slide) => encodeURIComponent(slide.props.path) === startSlide);
      if (getIndex >= 0) {
        return {
          position: String(getIndex),
          element: slidesArray[getIndex],
        };
      }
    }
    return undefined;
  };

  const updateSlideFromPath = () => {
    const path = location.pathname.split("/").pop() || "";
    const slideInfo = findSlideByPath(path);
    if (slideInfo) {
      setCurrentSlide(slideInfo.element);
      setCurrentPosition(slideInfo.position);
    } else {
      //   navigate("/"); // Navigate to a default path if no matching slide is found
    }
  };

  useEffect(() => {
    updateSlideFromPath();
  }, [location.pathname]);

  if (!currentSlide) {
    return null; // or a loader/placeholder component
  }

  return (
    <TransitionGroup className={`slide-wrapper ${className || ""}`}>
      <CSSTransition
        key={currentPosition}
        timeout={500}
        classNames="slide-transition"
        nodeRef={nodeRef}
      >
        {currentSlide}
    </CSSTransition>
    </TransitionGroup>
  );
};

export default SlidesWithoutRoute;
