import { FC, useState } from 'react';
import Modal, { ModalSize } from 'components/ModalComponents/Modal';
import { useNewBooking } from 'contexts/index';
import ConfirmWithInvoiceForm, {
  IInvoiceClaim,
} from 'src/modules/MyPage/ConfirmWithInvoiceForm';
import { DeliberateAny, SetState } from 'types/DelibrateAny';
import useAxios from 'hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { reservationConfirmWithInvoiceUrl } from 'api/booking/booking_api_v2';
import Yup from 'utils/yupExtensions';
import { labelYupError } from 'helpers/yupLocales';

interface IConfirmWithInvoiceModal {
  setShowModal: SetState<boolean>;
  setIsLoading: SetState<boolean>;
}

const ConfirmWithInvoiceModal: FC<IConfirmWithInvoiceModal> = ({
  setShowModal,
  setIsLoading,
}) => {
  const { bookingOptions, paymentInfo, reservationId, setErrors, errors } =
    useNewBooking();
  const { sendRequest } = useAxios();
  const navigate = useNavigate();

  const [invoiceOptions, setInvoiceOptions] = useState<IInvoiceClaim>({
    orgNumber: '',
    orgName: '',
    invoiceAddress: '',
    postalCode: '',
    postalCity: '',
    invoiceResponsible: '',
  });

  const InvoiceValidation = Yup.object().shape({
    orgNumber: Yup.string().required(),
    orgName: Yup.string().required(),
    invoiceAddress: Yup.string().required(),
    postalCode: Yup.string().required(),
    postalCity: Yup.string().required(),
    invoiceResponsible: Yup.string().required(),
  });

  const fieldNames = {
    orgNumber: 'Organisasjonsnummer',
    orgName: 'Virksomhet',
    postalCode: 'Postnummer',
    postalCity: 'Poststed',
    invoiceAddress: 'Adresse',
    invoiceResponsible: 'Navn på ansvarlige',
  };

  const handleOnSubmitPaymentModal = async () => {
    try {
      if (InvoiceValidation)
        await InvoiceValidation.validate(invoiceOptions, {
          abortEarly: false,
          context: fieldNames,
        });
      setErrors({});

      await orderBookingConfirmWithInvoice();
    } catch (err: DeliberateAny) {
      const validationErrors = {};
      err.inner.forEach(
        (error) =>
          (validationErrors[error.path] = labelYupError(error, fieldNames)),
      );
      setErrors(validationErrors);
    }
  };

  const orderBookingConfirmWithInvoice = async () => {
    setIsLoading(true);
    setShowModal(false);

    if (reservationId) {
      await sendRequest(
        {
          method: 'POST',
          url: reservationConfirmWithInvoiceUrl(reservationId),
          data: {
            purpose: paymentInfo.purposeOption,
            description: paymentInfo.purposeDescription,
            orgNumber: invoiceOptions.orgNumber,
            orgName: invoiceOptions.orgName,
            invoiceAddress: invoiceOptions.invoiceAddress,
            postalCode: invoiceOptions.postalCode,
            postalCity: invoiceOptions.postalCity,
            invoiceResponsible: invoiceOptions.invoiceResponsible,
          },
        },
        (response) => {
          navigate(`/mypage/booking/${response.bookingId}/receipt`);
        },
        null,
        (error) => {
          // TODO: Error handling
        },
      );
    }

    setIsLoading(false);
  };

  return (
    <Modal
      size={ModalSize.Medium}
      className="fit-content-modal"
      showCancelButton
      header={'Betaling for overnatting'}
      content={
        bookingOptions ? (
          <ConfirmWithInvoiceForm
            bookingOptions={bookingOptions}
            bookingError={errors}
            invoiceOptions={invoiceOptions}
            setInvoiceOptions={setInvoiceOptions}
          />
        ) : (
          <></>
        )
      }
      onClose={() => setShowModal(false)}
      onSubmit={() => handleOnSubmitPaymentModal()}
    />
  );
};

export default ConfirmWithInvoiceModal;
