import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './Link.scss';

type PropsType = {
  to: string;
  children?: ReactNode | ReactNode[];
  className?: string;
};

const Link = (props: PropsType) => <RouterLink className="link" {...props} />;

export default Link;
