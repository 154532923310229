// @flow

import "./ButtonCards.scss";

import { Component } from "react";

type ButtonCardType = {
  onClick: () => void;
  header: string;
  description: string;
  iconSrc: string;
  iconAlt?: string;
};

type PropsType = {
  cards: Array<ButtonCardType>;
};

class ButtonCards extends Component {
  declare props: PropsType;

  renderBlocks = () =>
    this.props.cards.map((block) => (
      <button key={block.header} onClick={block.onClick}>
        <div className="icon">
          <img src={block.iconSrc} alt={block.iconAlt} />
        </div>
        <div className="text">
          <h3 className="margin">{block.header}</h3>
          <p>{block.description}</p>
        </div>
      </button>
    ));

  render() {
    return <div className="button-cards">{this.renderBlocks()}</div>;
  }
}

export default ButtonCards;
