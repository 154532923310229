import * as I from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import React from 'react';

interface RoomFacilitiesProps {
  facilitiesHtml: string;
  roomTypeId: string;
  showAllFacilities: boolean;
  toggleFacilities: (roomTypeId: string) => void;
}

const RoomFacilities: React.FC<RoomFacilitiesProps> = ({
  facilitiesHtml,
  roomTypeId,
  showAllFacilities,
  toggleFacilities,
}) => (
  <div className="room-type-facilities" style={{ padding: 0 }}>
    <div
      dangerouslySetInnerHTML={{ __html: facilitiesHtml }}
      className={`facility-list ${showAllFacilities ? 'open' : ''}`}
    />
    <div
      className="facility-button"
      onClick={() => toggleFacilities(roomTypeId)}
    >
      Vis fasiliteter og regler
      <div className={`facility-icon ${showAllFacilities ? 'open' : ''}`}>
        <BasenIcon icon={I.DownIcon} />
      </div>
    </div>
  </div>
);

export default RoomFacilities;
