import './Input.scss';

import HelpText from 'components/HelpText';
import { FC, useEffect } from 'react';

import { IFormPropBase } from './FormPropBase';

interface IInputProps extends IFormPropBase {
  initial?: string;
  maxLength?: number;
}

const TextInput: FC<IInputProps> = ({
  name,
  label,
  formHolder,
  updateForm,
  errors,
  helpInfo,
  disabled,
  required,
  initial,
  placeHolder,
  maxLength,
}) => {
  useEffect(() => {
    if (!formHolder[name] && initial) {
      updateForm(name, initial);
    }
  }, []);

  const handleChange = (e) => {
    updateForm(name, e.target.value);
  };

  return (
    <div className="fb-form-field input-container">
      <HelpText content={helpInfo}>
        <label className={`fb-form-label ${errors[name] ? 'invalid' : ''}`}>
          {errors[name] ? errors[name] : label ? label : name}
        </label>
      </HelpText>
      <div className="form-text-input">
        <input
          className={`input-field ${disabled ? 'disabled' : ''} ${errors[name] ? 'invalid' : ''}`}
          type="text"
          maxLength={maxLength}
          onChange={handleChange}
          value={formHolder[name]}
          required={required}
          disabled={disabled}
          placeholder={placeHolder}
        />
      </div>
      {errors[name] && (
        <label className={`${errors[name] ? 'invalid' : ''}`}></label>
      )}
    </div>
  );
};

export default TextInput;
