import env from 'env';

import { DeliberateAny } from '../types/DelibrateAny';

export const debug = (message?: unknown, ...optionalParams: unknown[]) => {
  if (env.LOCAL) console.log(message, ...optionalParams);
};

export function isIOS() {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !(window as Window & { MSStream?: DeliberateAny }).MSStream
  );
}

export function isAndroid() {
  return /android/i.test(navigator.userAgent);
}

export const isDev = (hostname: string | null | undefined = null) => {
  if (hostname === null) hostname = location.hostname;
  return location.hostname === 'localhost';
};

type GenericObject = { [key: string]: unknown };

export const isSortedByProperty = <T extends GenericObject>(
  arr: T[],
  property: keyof T,
): boolean => {
  return arr.every((obj, i) => {
    if (i === arr.length - 1) return true;
    return obj[property] <= arr[i + 1][property];
  });
};

export const getParentPath = (pathName: string) => {
  if (!pathName || pathName === '/' || pathName === '\\') {
    return ''; // No parent path can be determined
  }

  const normalizedPath =
    pathName.endsWith('/') || pathName.endsWith('\\')
      ? pathName.slice(0, -1)
      : pathName;

  // Support both Unix and Windows paths
  const parts = normalizedPath.split(/[/\\]/);
  parts.pop();

  // Return the parent path. If parts is empty, it means the path was directly under root
  return parts.length ? parts.join('/') : '/';
};

export const getSiblingPath = (current: string, next: string) => {
  if (!next)
    throw new Error(
      'The next segment must be provided to construct a sibling path.',
    );

  return `${getParentPath(current)}/${next}`;
};

export function strToBool(str: string) {
  if (str === 'true') {
    return true;
  } else if (str === 'false') {
    return false;
  } else {
    throw new Error('Invalid input');
  }
}

export function boolToStr(bool: boolean) {
  return bool ? 'true' : 'false';
}

export function createUrlWithQuery(
  base: string,
  params: { [key: string]: any },
): string {
  const url = new URL(base);
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    searchParams.append(key, value.toString());
  });

  url.search = searchParams.toString();
  return url.toString();
}

export function extractQueryParams(url: string): { [key: string]: string } {
  const urlObj = new URL(url);
  const hash = urlObj.hash; // This includes everything after the '#' including it
  const queryString = hash.substring(hash.indexOf('?') + 1); // Get substring after '?'
  const params = new URLSearchParams(queryString);
  const paramsObj: { [key: string]: string } = {};
  params.forEach((value, key) => {
    paramsObj[key] = value;
  });

  return paramsObj;
}
