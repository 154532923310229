import './guest-list.scss';

import Button from 'components/buttons/Button/Button';
import { isSharedRoom } from 'helpers/bookingHelpers';
import { useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

const GuestList = ({
  setRoomInfo,
  roomInfo,
  roomArray,
  numberOfRooms,
  setNumberOfRooms,
  isSwitching,
  setIsSwitching,
  switchFrom,
  setSwitchFrom,
  simplified,
  validField,
  setValidField,
  availableRoom,
  priceList,
}) => {
  const handleRemoveGuest = (roomId, guestIndex) => {
    const newRoomInfo = [...roomInfo];
    newRoomInfo.find((room) => room.Id === roomId).NameListInfo.NameListItems =
      newRoomInfo
        .find((room) => room.Id === roomId)
        .NameListInfo.NameListItems.filter((_, i) => i !== guestIndex);

    const editingRoom = newRoomInfo.find((room) => room.Id === roomId);
    let newPriceList: DeliberateAny[] = [];
    if (priceList[0].PriceList === undefined) {
      newPriceList = priceList;
    } else {
      newPriceList = priceList.find(
        (findRoomPL) => findRoomPL.RoomTypeId === editingRoom.RoomTypeId,
      ).PriceList;
    }
    newRoomInfo.find((room) => room.Id === roomId).VBPriceId =
      newPriceList.find((price) => price.Persons === guestIndex)?.PriceId;
    setRoomInfo(newRoomInfo);
  };

  const handleAddExtraGuest = (roomId) => {
    const newRoomInfo = [...roomInfo];
    const roomArrayLength = newRoomInfo.find((room) => room.Id === roomId)
      .NameListInfo.NameListItems.length;

    const theRoom = newRoomInfo.find((room) => room.Id === roomId);

    theRoom.NameListInfo.NameListItems[roomArrayLength] = {
      FirstName: '',
      LastName: '',
      Email: '',
      Phone: '+47',
      EmployeeNumber: '',
    };
    let newPriceList: DeliberateAny[] = [];
    if (priceList[0].PriceList === undefined) {
      newPriceList = priceList;
    } else {
      newPriceList = priceList.find(
        (room) => room.RoomTypeId === theRoom.RoomTypeId,
      ).PriceList;
    }
    // +1 fordi roomArrayLength ikke er oppdatert med den nye gjesten enda, det skjer
    // ikke før i setRoomInfo,og vi er på jakt etter priceId som tilsvarer antall gjester

    const roomPrice = newPriceList.find(
      (price) => price.Persons === roomArrayLength + 1,
    );
    theRoom.VBPriceId = roomPrice.PriceId;

    // Oppdater reservasjonen med den nye infoen, theRoom peker på objektet i newRoomInfo,
    // så endringer har blitt gjennomført på det opprinnelige objektet i newRoomInfo
    setRoomInfo(newRoomInfo);
    setValidField(
      newRoomInfo.map((room) => ({
        id: room.Id,
        guests: room.NameListInfo.NameListItems.map(() => ({
          firstName: true,
          lastName: true,
          phone: true,
          email: true,
        })),
      })),
    );
  };

  const handleRemoveRoom = (roomId) => {
    const typeOfRoom = roomInfo.find((room) => room.Id === roomId).RoomTypeId;
    setNumberOfRooms(
      [...numberOfRooms].map((room) =>
        room.id === typeOfRoom ? { ...room, count: room.count - 1 } : room,
      ),
    );
    setRoomInfo([...roomInfo].filter((room) => room.Id !== roomId));
  };

  const handleSwitch = (roomId, guestIndex) => {
    if (isSwitching) {
      const newRoomInfo = [...roomInfo];
      const switchTo = newRoomInfo.find((room) => room.Id === roomId)
        .NameListInfo.NameListItems[guestIndex];
      newRoomInfo.find((room) => room.Id === roomId).NameListInfo.NameListItems[
        guestIndex
      ] = newRoomInfo.find(
        (room) => room.Id === switchFrom.Id,
      ).NameListInfo.NameListItems[switchFrom.Switch.indexOf(true)];

      newRoomInfo.find(
        (room) => room.Id === switchFrom.Id,
      ).NameListInfo.NameListItems[switchFrom.Switch.indexOf(true)] = switchTo;
      setRoomInfo(newRoomInfo);
      setSwitchFrom({
        Id: '',
        Switch: [false, false],
      });
    } else {
      const newSwitchFrom = switchFrom;
      newSwitchFrom.Id = roomId;
      newSwitchFrom.Switch[guestIndex] = true;
      //   newSwitchFrom.find(switch => switch.Id === roomId) = !newSwitchFrom[roomIndex];
      setSwitchFrom(newSwitchFrom);
    }
    setIsSwitching(!isSwitching);
  };

  const handleEmptyFields = (roomId, guestIndex) => {
    const newRoomInfo = [...roomInfo];
    newRoomInfo.find((room) => room.Id === roomId).NameListInfo.NameListItems[
      guestIndex
    ] = {
      FirstName: '',
      LastName: '',
      Email: '',
      Phone: '',
      EmployeeNumber: '',
    };
    setRoomInfo(newRoomInfo);
  };

  const handleChangeGuestInfo = (name, value, roomId, guestIndex) => {
    const newRoomInfo = [...roomInfo];

    switch (name) {
      case 'FirstName':
        newRoomInfo.find(
          (room) => room.Id === roomId,
        ).NameListInfo.NameListItems[guestIndex].FirstName = value;
        break;
      case 'LastName':
        newRoomInfo.find(
          (room) => room.Id === roomId,
        ).NameListInfo.NameListItems[guestIndex].LastName = value;
        break;
      case 'Email':
        newRoomInfo.find(
          (room) => room.Id === roomId,
        ).NameListInfo.NameListItems[guestIndex].Email = value;
        break;
      case 'Phone':
        newRoomInfo.find(
          (room) => room.Id === roomId,
        ).NameListInfo.NameListItems[guestIndex].Phone = value;
        break;
      case 'Requirements':
        newRoomInfo.find((room) => room.Id === roomId).Requirements = value;
        break;
      default:
        break;
    }

    setRoomInfo(newRoomInfo);
  };

  const getExtraRoomPrice = (guestAmount) =>
    (availableRoom.PriceList.find(
      (guests) => guests.Persons === guestAmount + 1,
    )?.PriceValue ?? 0) -
    (availableRoom.PriceList.find((guests) => guests.Persons === guestAmount)
      ?.PriceValue ?? 0);

  return (
    <div data-room-list="" className="room-list">
      {roomArray?.map((bookedRoom, y) => (
        <div key={`${bookedRoom.Id}_${y}`} className="room-guests">
          {!simplified && (
            <button
              onClick={() => handleRemoveRoom(bookedRoom.Id)}
              className="box-close top-right"
            />
          )}
          <div className="row room-type">
            <h3>{`${isSharedRoom(bookedRoom) ? 'Seng' : 'Rom'} ${y + 1}`}</h3>
            <span className="">{bookedRoom.RoomTypeName}</span>
          </div>
          <div className="room-wrapper flex-row auto-width">
            <div className="guests">
              <ul data-list="standard" className="guest-list">
                {bookedRoom?.NameListInfo?.NameListItems?.map(
                  (roomGuest, i) => (
                    <div key={bookedRoom.Id + '_' + i}>
                      <li
                        className={
                          switchFrom.Id === bookedRoom.Id &&
                          switchFrom.Switch[i]
                            ? 'guest half-margin switch-from'
                            : 'guest half-margin'
                        }
                      >
                        <div className="flex-row switcharoo ">
                          <button
                            className="btn"
                            title="Bytt rom"
                            data-action="switcharoo"
                            onClick={() => handleSwitch(bookedRoom.Id, i)}
                          />
                        </div>
                        <div className="flex-row half-margin auto-width guest-settings ">
                          <label className="booking-input-field">
                            <span className="bold">Fornavn *</span>
                            <input
                              className={
                                validField.find(
                                  (room) => room.id === bookedRoom.Id,
                                ).guests[i].firstName
                                  ? ''
                                  : 'invalid'
                              }
                              data-validators="person-name"
                              type="text"
                              placeholder="Fornavn"
                              data-name="FirstName"
                              value={
                                roomGuest.FirstName ? roomGuest.FirstName : ''
                              }
                              onChange={(e) =>
                                handleChangeGuestInfo(
                                  'FirstName',
                                  e.target.value,
                                  bookedRoom.Id,
                                  i,
                                )
                              }
                            />
                          </label>
                          <label className="booking-input-field">
                            <span className="bold">Etternavn *</span>
                            <input
                              className={
                                validField.find(
                                  (room) => room.id === bookedRoom.Id,
                                ).guests[i].lastName
                                  ? ''
                                  : 'invalid'
                              }
                              data-validators="person-name"
                              type="text"
                              placeholder="Etternavn"
                              data-name="LastName"
                              value={
                                roomGuest.LastName ? roomGuest.LastName : ''
                              }
                              onChange={(e) =>
                                handleChangeGuestInfo(
                                  'LastName',
                                  e.target.value,
                                  bookedRoom.Id,
                                  i,
                                )
                              }
                            />
                          </label>
                          <label className="booking-input-field">
                            <span className="bold">Mobiltelefon *</span>
                            <input
                              className={
                                validField.find(
                                  (room) => room.id === bookedRoom.Id,
                                ).guests[i].phone
                                  ? ''
                                  : 'invalid'
                              }
                              data-validators="phone-with-country-code"
                              type="text"
                              placeholder="Telefon"
                              data-name="Phone"
                              value={roomGuest.Phone ? roomGuest.Phone : ''}
                              onChange={(e) =>
                                handleChangeGuestInfo(
                                  'Phone',
                                  e.target.value,
                                  bookedRoom.Id,
                                  i,
                                )
                              }
                            />
                          </label>
                          <label className="booking-input-field">
                            <span>E-post</span>
                            <input
                              data-optional=""
                              data-validators="email"
                              type="text"
                              placeholder="E-post"
                              data-name="Email"
                              value={roomGuest.Email ? roomGuest.Email : ''}
                              onChange={(e) =>
                                handleChangeGuestInfo(
                                  'Email',
                                  e.target.value,
                                  bookedRoom.Id,
                                  i,
                                )
                              }
                            />
                          </label>
                          <button
                            className="btn grey room-guest-row-btn"
                            data-action="emptyfields"
                            onClick={() => handleEmptyFields(bookedRoom.Id, i)}
                          >
                            Tøm alle felt
                          </button>
                          {i > 0 && (
                            <button
                              className="btn grey room-guest-row-btn"
                              data-action="remove-row"
                              onClick={() =>
                                handleRemoveGuest(bookedRoom.Id, i)
                              }
                            >
                              Fjern gjest
                            </button>
                          )}
                        </div>
                      </li>
                    </div>
                  ),
                )}
              </ul>

              {bookedRoom.NameListInfo.NameListItems &&
                availableRoom.StandardCapacity > 1 &&
                !simplified &&
                bookedRoom.NameListInfo.NameListItems.length === 1 && (
                  <button
                    data-action="add-guest"
                    data-guest-type="standard"
                    onClick={() => {
                      handleAddExtraGuest(bookedRoom.Id);
                    }}
                    className="btn red half-margin"
                  >
                    Legg til en gjest (0,- pr natt)
                  </button>
                )}
              {availableRoom.ExtraCapacity > 0 &&
                bookedRoom.NameListInfo.NameListItems.length <=
                  availableRoom.ExtraCapacity && (
                  <div>
                    <h5 className="extra-guests-header half-margin">{`Ekstra gjester (maks ${availableRoom.ExtraCapacity})`}</h5>
                    {!simplified && (
                      <button
                        data-action="add-guest"
                        data-guest-type="standard"
                        onClick={() => {
                          handleAddExtraGuest(bookedRoom.Id);
                        }}
                        className="btn red half-margin"
                      >
                        {`Legg til ekstra gjest (${getExtraRoomPrice(
                          bookedRoom.NameListInfo.NameListItems.length,
                        )},- for valgt periode)`}
                      </button>
                    )}
                  </div>
                )}
            </div>
            <div className="room-comment">
              <label>
                <span>Spesielle ønsker/behov</span>
                <textarea
                  value={bookedRoom.Requirements ? bookedRoom.Requirements : ''}
                  onChange={(e) => {
                    handleChangeGuestInfo(
                      'Requirements',
                      e.target.value,
                      bookedRoom.Id,
                      null,
                    );
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default GuestList;
