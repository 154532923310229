import PropTypes from 'prop-types';

import { DeliberateAny } from '../../types/DelibrateAny';
import Checkbox from '../form/Checkbox/Checkbox';

const Consent = ({ formHolder, updateFormHolder, errors }: DeliberateAny) => {
  return (
    <div className="row margin" id="error_personalConsent">
      <div className="content-group grey padded">
        <p>
          <strong>Samtykke for lagring av personopplysninger:</strong>
        </p>
        <p>
          For å kunne melde inn sak elektronisk må du samtykke til at ditt Navn,
          mobilnummer, ansattnummer (ikke påkrevd), e-postadresse og alternativ
          e-post adresse (ikke påkrevd) lagres hos oss.
        </p>
        <p>
          Trykk her for å lese{' '}
          <a
            title="Forsvarsbyggs personvernerklæring"
            href="/link/e91f6647791144649d4faed21740ce88.aspx"
            target="_blank"
            rel="noopener"
          >
            Forsvarsbyggs personvernerklæring.
          </a>
        </p>
        <Checkbox
          errors={errors}
          checked={formHolder.personalConsent}
          name="personalConsent"
          onChange={(name: string, val: DeliberateAny) =>
            updateFormHolder(name, val)
          }
          label="Jeg godtar betingelsene i personvernerklæringen."
        />
      </div>
    </div>
  );
};

export default Consent;
