// @flow

import React, { Component } from 'react';
import moment from 'moment';
import connect from 'helpers/connectWithRouter';
import type { SearchRentalsResultItemType } from 'types/serviceOrderTypes';
import type { ListColumnType } from 'types/listTypes';
import ListItem from 'components/ListItem/ListItem';

type PropsType = {
    searchRentalsItem: SearchRentalsResultItemType,
    hideDetails?: boolean,
    columns: Array<ListColumnType>,
};

class UnconnectedInspectionErrorListItem extends Component {
    declare props: PropsType;

    printNonEmptyListItem(label: string, value: string) {
      return value && value.length > 0 && (
        <li>
          <span className="label">{label}</span>
          <span className="value">{value}</span>
        </li>
      );
    }

    renderDetailsFunction = () => (
      <React.Fragment>
        <div className="details">
          <div data-controller="service-order-attributes">
            <ul className="label-value-list">
              {this.printNonEmptyListItem('SO-nummer', this.props.searchRentalsItem.ServiceOrderNumber)}
              {this.printNonEmptyListItem('Type', this.props.searchRentalsItem.ServiceOrderType)}
              {this.printNonEmptyListItem('Opprettet', moment(this.props.searchRentalsItem.CreatedDate).format('DD.MM.YY'))}
              {this.printNonEmptyListItem('Forsvarets IOnr', this.props.searchRentalsItem.IONumber)}
              {this.printNonEmptyListItem('Etablissement', this.props.searchRentalsItem.Establishment)}
              {this.printNonEmptyListItem('Bygning', this.props.searchRentalsItem.Building)}
              {this.printNonEmptyListItem('Lokasjon', this.props.searchRentalsItem.Location)}
              {this.printNonEmptyListItem('Inventarnr', this.props.searchRentalsItem.BuildingNumber)}
              <br />
              {this.printNonEmptyListItem('Beskrivelse', this.props.searchRentalsItem.Description)}
            </ul>
          </div>
        </div>
      </React.Fragment>
    )

    render() {
      return (
        <ListItem
          item={this.props.searchRentalsItem}
          columns={this.props.columns}
          renderDetailsFunction={this.props.hideDetails ?
            undefined : () => this.renderDetailsFunction()
          }
        />
      );
    }
}

export default connect(
  null, null,
)(UnconnectedInspectionErrorListItem);
