import Spinner from 'components/Spinner/Spinner';
import { useLogin, useUser } from 'contexts/index';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// ?ReturnUrl=/episerver/cms -> basen.fb.no/episerver/cms
// ?ReturnUrl=react-module/part -> basen.fb.no/#/react-module/part

const Login = () => {
  const { hasUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { setShow, setLoginUrl } = useLogin();

  useEffect(() => {
    const returnUrl = new URLSearchParams(location.search).get('ReturnUrl');
    if (hasUser) navigate(returnUrl ?? '/');
    else {
      setShow(true);
      setLoginUrl(returnUrl);
      navigate('/');
    }
  }, [hasUser, location, navigate]);

  if (hasUser)
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
        }}
      >
        <Spinner size="giga" />
      </div>
    );

  return <div />;
};

export default Login;
