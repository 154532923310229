// @flow
import { Component, ReactNode } from 'react';
import connect from 'helpers/connectWithRouter';
import { fetchStaticFilterOptions } from 'actions/optionsActions';
import { searchRentalServiceOrder } from 'actions/serviceOrdersActions';
import type { SearchFilterType, SearchRentalsQueryResultType, SearchRentalsResultItemType } from 'types/serviceOrderTypes';
import InspectionErrorListItem from './InspectionErrorListItem';
import './InspectionErrorList.scss';
import { DeliberateAny } from 'types/DelibrateAny';
import Loader from 'components/Loader/Loader';
import List from 'components/List/List';
import { ListColumnType } from 'types/listTypes';
import moment from 'moment';

type PropsType = {
  searchRentalServiceOrder: (string, SearchFilterType) => void,
  rentalId: string,
  searchResultQuery: SearchRentalsQueryResultType,
  fetchStaticFilterOptions: () => void,
  serviceOrderStates: Array<DeliberateAny>,
  serviceOrderStatesLoaded: boolean,
};


class UnconnectedInspectionErrorList extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    this.props.fetchStaticFilterOptions();
    this.props.searchRentalServiceOrder(this.props.rentalId, {
      typeFilter: 'Feilmelding',
      stateFilter: '4',
    });

  }

  getServiceOrderState = (state: string) => {
    if (this.props.serviceOrderStates) {
      const value = this.props.serviceOrderStates.find(x => x.key === state);
      if (value) {
        return value.value;
      }
    }
    return state;
  }

  renderCreatedDate = (searchItem: SearchRentalsResultItemType): ReactNode => {
    const renderItem = moment(searchItem.CreatedDate).format('DD.MM.YY');
    return <div>{renderItem}</div>
  }

  renderStatus = (searchItem: SearchRentalsResultItemType): ReactNode => {
    const renderItem = this.getServiceOrderState(searchItem.ServiceOrderState);
    return <div>{renderItem}</div>
  }

  columns: ListColumnType[] = [
    {
      name: 'Title',
      label: 'Henvendelse',
      className: 'title',
    },
    {
      name: 'CreatedDate',
      label: 'Dato',
      renderFunction: this.renderCreatedDate,
      className: 'date',
    },
    {
      name: 'ServiceOrderNumber',
      label: 'SO-number',
      className: 'serviceorder',
    },
    {
      name: 'ServiceOrderState',
      label: 'Status',
      renderFunction: this.renderStatus,
      className: 'state',
    },
  ]

  renderItem = (searchItem: SearchRentalsResultItemType) => {
    return (
      <InspectionErrorListItem
        key={searchItem.ServiceOrderNumber}
        searchRentalsItem={searchItem}
        columns={this.columns}
      />
    );
  }

  render() {
    const hasSearchResult = this.props.searchResultQuery && this.props.searchResultQuery.searchResult;
    const isLoading = !this.props.serviceOrderStatesLoaded || !this.props.searchResultQuery;
    return (
      <Loader isLoading={isLoading}>
        {hasSearchResult && (
          <div className="inspection-case-list">
            <p>Det kan finnes feil og mangler som enda ikke er fikset.
              Dersom det finnes ligger de i listen under.
              Disse behøver ikke registreres på nytt.
            </p>
            <List
            columns={this.columns}
            renderItem={this.renderItem}
            items={this.props.searchResultQuery.searchResult}
            />
          </div>
        )}
      </Loader>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    searchResultQuery: state.serviceOrders.searchQuery,
    serviceOrderStates: state.options.staticFilterOptions.serviceOrderStates,
    serviceOrderStatesLoaded: state.options.staticFilterOptionsLoaded,
  }),
  {
    searchRentalServiceOrder,
    fetchStaticFilterOptions,
  },
)(UnconnectedInspectionErrorList);
