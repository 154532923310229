import ImageCarousel from 'components/ImageComponents/ImageCarousel';
import { FC, useEffect, useState } from 'react';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import { v4 as uuidv4 } from 'uuid';
import Button from 'components/buttons/Button/Button';
import Modal from 'components/ModalComponents/Modal';
import Accordion from 'components/Accordian/Accordion';
import useAxios from 'hooks/useAxios';
import { arenaByIdUrl } from 'api/booking/booking_api_v2';
import Loader from 'components/Loader/Loader';
import './AboutLocation.scss';
import {
  GetArenaInformationResponse,
  HtmlTextField,
} from 'types/BookingTypes/arenaTypes';
import RenderHtmlTextField from '../MyPage/RenderHtmlTextField';

interface IAboutArenaProps {
  locationId: string; //guid
}

const AboutArena: FC<IAboutArenaProps> = ({ locationId }) => {
  const { sendRequest } = useAxios();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [arenaInfo, setArenaInfo] = useState<GetArenaInformationResponse>();
  const [showMoreAboutFacilities, setShowMoreAboutFacilities] =
    useState<boolean>(false);
  const [moreAboutFacilitiesExist, setMoreAboutFacilitiesExist] =
    useState<boolean>(false);

  useEffect(() => {
    fetchArenaInformation();
  }, []);

  const fetchArenaInformation = async () => {
    setIsLoading(true);
    await sendRequest(
      { method: 'GET', url: arenaByIdUrl(locationId) },
      (response: GetArenaInformationResponse) => {
        setArenaInfo(response);
      },
    );
    setIsLoading(false);
  };

  const renderMoreAboutFacilitiesModal = (facilityInfo: HtmlTextField[]) => {
    return (
      <div className="al-facilities-modal-content">
        <RenderHtmlTextField
          textArea={facilityInfo}
          displayAsAccordion={true}
        />
      </div>
    );
  };

  useEffect(() => {
    if (arenaInfo && arenaInfo.facilityInfo.length > 0) {
      setMoreAboutFacilitiesExist(true);
    }
  }, [arenaInfo]);

  const renderAboutLocation = () => {
    return (
      <div>
        <span className="al-header">Om stedet</span>
        <Loader isLoading={isLoading}>
          {arenaInfo && (
            <div className="al-container">
              <div className="al-info">
                <ImageCarousel
                  images={[arenaInfo.image, ...arenaInfo.images]}
                />
                <div>{arenaInfo.ingress}</div>

                {arenaInfo && (
                  <div>
                    <Accordion title={'Åpningstider'}>
                      <RenderHtmlTextField textArea={arenaInfo.openingInfo} />
                    </Accordion>
                    <Accordion title={'Kontakt'}>
                      <RenderHtmlTextField textArea={arenaInfo.contactInfo} />
                    </Accordion>
                  </div>
                )}
              </div>
              <div className="al-facilities">
                <div className="al-facility-header">Spesielt ved leiren</div>
                {arenaInfo.facilities?.map((facility) => {
                  const iconItem = <BasenIcon icon={facility.url}></BasenIcon>;
                  return (
                    <span key={uuidv4()} className="al-facility-item">
                      {iconItem}
                      {facility.altText}
                    </span>
                  );
                })}
                {moreAboutFacilitiesExist && (
                  <Button
                    className="secondary"
                    onClick={() => setShowMoreAboutFacilities(true)}
                  >
                    Les mer om fasilitetene
                  </Button>
                )}
              </div>
            </div>
          )}
        </Loader>
        {showMoreAboutFacilities && arenaInfo && (
          <Modal
            header={'Mer om fasilitetene'}
            content={renderMoreAboutFacilitiesModal(arenaInfo.facilityInfo)}
            onClose={() => setShowMoreAboutFacilities(false)}
            className="al-facilities-modal"
            showCancelIcon
          ></Modal>
        )}
      </div>
    );
  };

  return renderAboutLocation();
};

export default AboutArena;
