// @flow

import { Component } from "react";
// import type { ClientPagingType } from 'types/listTypes';
import "../Paging.scss";

class ClientPaging extends Component {
  // props: ClientPagingType;

  constructor(props) {
    super(props);
    this.updatePageCount();
  }

  componentDidUpdate() {
    this.updatePageCount();
  }

  onFirstPageClick = () => {
    this.props.onChangePageNumber(0);
  };

  onNextPageClick = () => {
    this.props.onChangePageNumber(this.props.pageNumber + 1);
  };

  onPreviousPageClick = () => {
    this.props.onChangePageNumber(this.props.pageNumber - 1);
  };

  onLastPageClick = () => {
    if (this.props.pageCount) {
      this.props.onChangePageNumber(this.props.pageCount - 1);
    }
  };

  updatePageCount = () => {
    let newPageCount;

    if (this.props.numItems <= this.props.pageSize) {
      newPageCount = 1;
    } else {
      newPageCount = Math.ceil(
        parseFloat(this.props.numItems / this.props.pageSize)
      );
    }

    if (newPageCount !== this.props.pageCount) {
      this.props.onSetPageCount(newPageCount);
    }
  };

  render() {
    return (
      <div className="paging">
        <button
          onClick={this.onFirstPageClick}
          className="red hide-for-small link-style"
          disabled={this.props.pageNumber === 0}
        >
          <span className="double-arrows">&laquo;&laquo;</span> Første
        </button>
        <button
          onClick={this.onPreviousPageClick}
          className="red link-style"
          disabled={this.props.pageNumber === 0}
        >
          &laquo; Forrige
        </button>
        <span>
          ( Side {this.props.pageNumber + 1} / {this.props.pageCount} )
        </span>
        <button
          onClick={this.onNextPageClick}
          className="red link-style"
          disabled={this.props.pageNumber + 1 === this.props.pageCount}
        >
          Neste &raquo;
        </button>
        <button
          onClick={this.onLastPageClick}
          className="red link-style hide-for-small"
          disabled={this.props.pageNumber + 1 === this.props.pageCount}
        >
          Siste <span className="double-arrows">&raquo;&raquo;</span>
        </button>
      </div>
    );
  }
}

export default ClientPaging;
