import Loader from 'components/Loader/Loader';
import DOMPurify from 'dompurify';
import env from 'env';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';
import useAxios from "../hooks/useAxios";

const BookingReceipt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false)
  const [html, setHtml] = useState<DeliberateAny>();
  const { sendRequest } = useAxios();

  const fetchReceipt = async () => {
    setIsFetching(true)
    await sendRequest(
      {
        method: 'GET',
        url: env.CMS + location.pathname + queryParams
      },
      (res: string) => {
        setIsLoading(false);
        setHtml(res);
      });
  };

  const removeQueryParamsFromUrl = () => {
    navigate(location.pathname, { replace: true });
  }

  useEffect(() => {
    if (location.search) {
      setQueryParams(location.search);
      removeQueryParamsFromUrl();
    }
    if (queryParams) {
      fetchReceipt();
    }
    if (!isFetching && !location.search && !queryParams) {
      setIsLoading(false); // this is a fallback if somone reload this page after query params is removed.
      navigate('/');
    }
  }, [location, navigate, queryParams]);

  return (
    <Loader isLoading={isLoading}>
      {html && (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(html),
          }}
        />
      )}
    </Loader>
  );
};

export default BookingReceipt;