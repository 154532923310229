 

import './Sandbox.css';

import Accordion from 'components/Accordian/Accordion';
import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import Badge from 'components/Badge/Badge';
import * as I from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import Button from 'components/buttons/Button/Button';
import Counter from 'components/Counter/Counter';
import CheckboxGroup from 'components/FormComponents/CheckboxGroup';
import ComboSelectBox from 'components/FormComponents/ComboSelectBox';
import DateSelector, { DatePickerMode } from 'components/FormComponents/DateSelector';
import RadioButtonGroup from 'components/FormComponents/RadioButtonGroup';
import Select from 'components/FormComponents/Select';
import HelpText from 'components/HelpText';
import Split, { SplitPosition } from 'components/Split/Split';
import Stepper from 'components/Stepper/Stepper';
import BookingSummary from 'components/Summary/BookingSummary';
import Summary from 'components/Summary/Summary';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import GridContainer from 'src/layout/GridContainer/GridContainer';
import Menu from 'src/layout/Menu';
import TabContainer from 'src/layout/TabContainer/TabContainer';
import { DeliberateAny } from 'types/DelibrateAny';

import ImageExample from './ImageExample';
import ModalExample from './ModalExample';

const Sandbox = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4'];
  const [active1Tab, setActive1Tab] = useState<string>('ComboSelectBox');
  const [active2Tab, setActive2Tab] = useState<string>('buttons');
  const [formHolder, setFormHolder] = useState<DeliberateAny>({});
  const [formErrors, setFormErrors] = useState<DeliberateAny>({});

  const [splitPosition, setSplitPosition] = useState<SplitPosition>(
    SplitPosition.Bottom,
  );
  const [hideSplit, setHideSplit] = useState(false);

  // Values for paginator
  const [activePage, setActivePage] = useState(1);
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(70);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const handleChange = (name: string, value: DeliberateAny) => {
    const updated: DeliberateAny = {};
    updated[name] = value;
    setFormHolder({ ...formHolder, ...updated });
  };

  const changePage = (pageNumber: number) => {
    const lastPage = Math.ceil(totalNumberOfItems / itemsPerPage);
    if (pageNumber < 1) {
      setActivePage(1);
    } else if (pageNumber > lastPage) {
      setActivePage(lastPage);
    } else {
      setActivePage(pageNumber);
    }
  };

  return (
    <div className="sandbox">
      <Split
        position={splitPosition}
        hide={hideSplit}
        onClose={() => setHideSplit(true)}
      >
        <section className="flex flex-space-between fill-x gap flex-align-center">
          <h2>Split</h2>
          <div className="flex">
            {splitPosition === SplitPosition.Bottom ? (
              <Button
                onClick={() => setSplitPosition(SplitPosition.Top)}
                className={'tertiary'}
                icon={I.UpIcon}
              >
                Flytt opp
              </Button>
            ) : (
              <Button
                onClick={() => setSplitPosition(SplitPosition.Bottom)}
                className={'tertiary'}
                icon={I.DownIcon}
              >
                Flytt ned
              </Button>
            )}
          </div>
          <p style={{ maxWidth: '60%' }}>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error
            accusamus, eaque corrupti obcaecati maiores numquam corporis?
            Incidunt illum dolore suscipit! Harum et architecto asperiores
            expedita perferendis nemo perspiciatis quasi ad.
          </p>
          <p style={{ fontWeight: 600 }}>165.000,-</p>
        </section>
      </Split>
      <article>
        <div className="banner">
          <h1>Sandbox - Components</h1>
        </div>

        <section>
          <h2>Form</h2>
          <TabContainer
            activeTab={active1Tab}
            onTabChange={setActive1Tab}
            content={[
              {
                id: 'ComboSelectBox',
                content: (
                  <ComboSelectBox
                    initial="Velg noe"
                    name="comboSelect"
                    placeHolder="Skriv for å filtrere..."
                    label={'ComboSelectBox Label'}
                    formHolder={formHolder}
                    updateForm={handleChange}
                    errors={formErrors}
                    helpInfo={'Kan endres i Sandbox'}
                    requiredChars={2}
                    options={[
                      { label: 'Ikke valgt', value: '0' },
                      { label: 'Valgt 1', value: '1' },
                      { label: 'Ikke valgt', value: '2' },
                      { label: 'Disabled 3', value: '3', disabled: true },
                    ]}
                  />
                ),
              },
              {
                id: 'DateSelector',
                content: (
                  <div className="fb-form">
                    <DateSelector
                      mode={DatePickerMode.Single}
                      name={'dateSelect1'}
                      label={'DateSelector (single)'}
                      formHolder={formHolder}
                      updateForm={handleChange}
                      errors={formErrors}
                      unavailableDays={[
                        new Date().setDate(new Date().getDate() + 4),
                        new Date().setDate(new Date().getDate() + 5),
                        new Date().setDate(new Date().getDate() + 14),
                        new Date().setDate(new Date().getDate() + 15),
                        new Date().setDate(new Date().getDate() + 16),
                        new Date().setDate(new Date().getDate() + 27),
                      ]}
                    />

                    <DateSelector
                      mode={DatePickerMode.Range}
                      name={'dateSelect2'}
                      label={'DateSelector (range)'}
                      formHolder={formHolder}
                      updateForm={handleChange}
                      errors={formErrors}
                      helpPanel={
                        <AlertMessage variant={AlertType.Info}>
                          <p>
                            For bestilling med under to dager til ankomst,
                            vennligst kontakt{' '}
                            <Link to={'/'}> Forsvarsbygg Servicesenter</Link>
                          </p>
                        </AlertMessage>
                      }
                      minDate={new Date().setDate(new Date().getDate() + 2)}
                      unavailableDays={[
                        new Date().setDate(new Date().getDate() + 4),
                        new Date().setDate(new Date().getDate() + 13),
                        new Date().setDate(new Date().getDate() + 14),
                        new Date().setDate(new Date().getDate() + 20),
                        new Date().setDate(new Date().getDate() + 21),
                        new Date().setDate(new Date().getDate() + 24),
                      ]}
                    />

                    <pre
                      style={{
                        borderRadius: 4,
                        background: 'white',
                        padding: 10,
                      }}
                    >
                      {JSON.stringify(formHolder, null, 4)}
                    </pre>
                  </div>
                ),
              },
              {
                id: 'Select & Combo',
                content: (
                  <div className="fb-form">
                    <Select
                      disabled
                      initial="Velg noe"
                      name="select1"
                      label={'Disabled Label'}
                      formHolder={formHolder}
                      updateForm={handleChange}
                      errors={formErrors}
                      helpInfo={'Kan endres i Sandbox'}
                      options={[]}
                    />
                    <Select
                      initial="Velg noe"
                      name="select2"
                      label={'Select Label'}
                      formHolder={formHolder}
                      updateForm={handleChange}
                      errors={formErrors}
                      helpInfo={'Kan endres i Sandbox'}
                      options={[
                        { label: 'Ikke valgt', value: '0' },
                        { label: 'Valgt 1', value: '1' },
                        { label: 'Ikke valgt', value: '2' },
                        { label: 'Disabled 3', value: '3', disabled: true },
                      ]}
                    />
                    <ComboSelectBox
                      name="combo1"
                      label={'Combo Label'}
                      formHolder={formHolder}
                      updateForm={handleChange}
                      helpInfo={'Kan endres i Sandbox'}
                      errors={formErrors}
                      options={[
                        { label: 'Ikke valgt', value: '0' },
                        { label: 'Valgt 1', value: '1' },
                        { label: 'Ikke valgt', value: '2' },
                        { label: 'Disabled 3', value: '3', disabled: true },
                      ]}
                    />
                  </div>
                ),
              },
            ]}
          />
        </section>

        <section>
          <h2>Stepper</h2>
        </section>
        <Stepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          steps={steps}
          title="Basen stepper"
          showProgressBar={true}
        />

        <section>
          <h2>Menu</h2>
          <Menu />
        </section>

        <section>
          <h2>Accordion</h2>
          <Accordion title={'Accordion text'}>
            This is just plain text.
          </Accordion>
          <Accordion title={'Accordion JSX'}>
            <p>This is just plain JSX.</p>
          </Accordion>
          <Accordion title={'Accordion Inception'}>
            <Accordion title={'Accordion Inception'}>
              <p>This is just plain JSX.</p>
            </Accordion>
          </Accordion>
        </section>

        <section>
          <ModalExample />
        </section>

        <section>
          <h2>Tab container</h2>
          <TabContainer
            activeTab={active2Tab}
            onTabChange={setActive2Tab}
            tabs={[
              { id: 'buttons', label: 'Buttons' },
              { id: 'icons', label: 'Icons' },
              { id: 'alert', label: 'Alert' },
              { id: 'help', label: 'Helptext' },
              { id: 'rnc', label: 'Radio & Checkbox' },
              { id: 'badge', label: 'Badge' },
              { id: 'table', label: 'Table' },
              { id: 'counter', label: 'Counter' },
              { id: 'images', label: 'Images' },
              { id: 'summary', label: 'Summary' },
            ]}
            content={[
              {
                id: 'buttons',
                content: (
                  <>
                    <h2 id="button">Button</h2>

                    <GridContainer cols={5} style={{ gap: 20 }} centerItems>
                      <h3></h3>
                      <h3>Text only</h3>
                      <h3>Lefts icon</h3>
                      <h3>Right icon</h3>
                      <h3>Icon only</h3>
                      <h3>Default</h3>
                      <Button className="primary">Primary</Button>

                      <Button className="primary" icon={I.Notification0Icon}>
                        Primary
                      </Button>

                      <Button
                        className="primary"
                        icon={I.Notification0Icon}
                        iconRight
                      >
                        Primary
                      </Button>

                      <Button className="primary" icon={I.Notification0Icon} />
                      <h3>Focus</h3>
                      <Button className="primary focused">Primary</Button>

                      <Button
                        className="primary focused"
                        icon={I.Notification0Icon}
                      >
                        Primary
                      </Button>

                      <Button
                        className="primary focused"
                        icon={I.Notification0Icon}
                        iconRight
                      >
                        Primary
                      </Button>

                      <Button
                        className="primary focused"
                        icon={I.Notification0Icon}
                      />
                      <h3>Disabled</h3>
                      <Button disabled className="primary">
                        Primary
                      </Button>

                      <Button
                        disabled
                        className="primary"
                        icon={I.Notification0Icon}
                      >
                        Primary
                      </Button>

                      <Button
                        disabled
                        className="primary"
                        icon={I.Notification0Icon}
                        iconRight
                      >
                        Primary
                      </Button>

                      <Button
                        disabled
                        className="secondary"
                        icon={I.Notification0Icon}
                      />

                      <h3>Default</h3>
                      <Button className="secondary">Secondary</Button>

                      <Button className="secondary" icon={I.SuccessIcon}>
                        Secondary
                      </Button>

                      <Button
                        className="secondary"
                        icon={I.SuccessIcon}
                        iconRight
                      >
                        Secondary
                      </Button>

                      <Button className="secondary" icon={I.SuccessIcon} />
                      <h3>Focus</h3>
                      <Button className="secondary focused">Secondary</Button>

                      <Button
                        className="secondary focused"
                        icon={I.SuccessIcon}
                      >
                        Secondary
                      </Button>

                      <Button
                        className="secondary focused"
                        icon={I.SuccessIcon}
                        iconRight
                      >
                        Secondary
                      </Button>

                      <Button
                        className="secondary focused"
                        icon={I.SuccessIcon}
                      />
                      <h3>Disabled</h3>
                      <Button disabled className="secondary">
                        Secondary
                      </Button>

                      <Button
                        disabled
                        className="secondary"
                        icon={I.SuccessIcon}
                      >
                        Secondary
                      </Button>

                      <Button
                        disabled
                        className="secondary"
                        icon={I.SuccessIcon}
                        iconRight
                      >
                        Secondary
                      </Button>

                      <Button
                        disabled
                        className="secondary"
                        icon={I.SuccessIcon}
                      />

                      <h3>Default</h3>
                      <Button className="tertiary">Tertiary</Button>

                      <Button className="tertiary" icon={I.WarningIcon}>
                        Tertiary
                      </Button>

                      <Button
                        className="tertiary"
                        icon={I.WarningIcon}
                        iconRight
                      >
                        Tertiary
                      </Button>

                      <Button className="tertiary" icon={I.WarningIcon} />
                      <h3>Focus</h3>
                      <Button className="tertiary focused">Tertiary</Button>

                      <Button className="tertiary focused" icon={I.WarningIcon}>
                        Tertiary
                      </Button>

                      <Button
                        className="tertiary focused"
                        icon={I.WarningIcon}
                        iconRight
                      >
                        Tertiary
                      </Button>

                      <Button
                        className="tertiary focused"
                        icon={I.WarningIcon}
                      />
                      <h3>Disabled</h3>
                      <Button disabled className="tertiary">
                        Tertiary
                      </Button>

                      <Button
                        disabled
                        className="tertiary"
                        icon={I.WarningIcon}
                      >
                        Tertiary
                      </Button>

                      <Button
                        disabled
                        className="tertiary"
                        icon={I.WarningIcon}
                        iconRight
                      >
                        Tertiary
                      </Button>

                      <Button
                        disabled
                        className="tertiary"
                        icon={I.WarningIcon}
                      />
                    </GridContainer>
                  </>
                ),
              },
              {
                id: 'icons',
                content: (
                  <>
                    <h2 id="icon">Icons</h2>

                    <h3 style={{ marginTop: 24, textAlign: 'left' }}>
                      Global 1
                    </h3>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: 10,
                      }}
                    >
                      <BasenIcon icon={I.ApplicationIcon} />
                      <BasenIcon icon={I.Bell0Icon} />
                      <BasenIcon icon={I.Bell1Icon} />
                      <BasenIcon icon={I.ChatIcon} />

                      <BasenIcon icon={I.ShieldLockIcon} />

                      <BasenIcon icon={I.GearIcon} />
                      <BasenIcon icon={I.IntoIcon} />
                      <BasenIcon icon={I.KeyIcon} />

                      <BasenIcon icon={I.UserGearIcon} />
                      <BasenIcon icon={I.BedIcon} />
                      <BasenIcon icon={I.BookIcon} />

                      <BasenIcon icon={I.DocIcon} />
                      <BasenIcon icon={I.FlagIcon} />
                      <BasenIcon icon={I.HomeIcon} />
                    </div>
                    <h3 style={{ marginTop: 24, textAlign: 'left' }}>Help</h3>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: 10,
                      }}
                    >
                      <BasenIcon icon={I.Question0Icon} />
                      <BasenIcon icon={I.Question1Icon} />
                    </div>

                    <h3 style={{ marginTop: 24, textAlign: 'left' }}>
                      Global 2
                    </h3>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: 10,
                      }}
                    >
                      <BasenIcon icon={I.Notification0Icon} />
                      <BasenIcon icon={I.Notification1Icon} />
                      <BasenIcon icon={I.MenuIcon} />
                      <BasenIcon icon={I.ProfileIcon} />
                      <BasenIcon icon={I.CloseIcon} />
                      <BasenIcon icon={I.WarningIcon} />
                      <BasenIcon icon={I.CheckIcon} />
                      <BasenIcon icon={I.SuccessIcon} />
                    </div>
                    <h3 style={{ marginTop: 24, textAlign: 'left' }}>
                      Chevron
                    </h3>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: 10,
                      }}
                    >
                      <BasenIcon icon={I.LeftIcon} />
                      <BasenIcon icon={I.RightIcon} />

                      <BasenIcon icon={I.UpIcon} />
                      <BasenIcon icon={I.DownIcon} />
                    </div>
                    <h3 style={{ marginTop: 24, textAlign: 'left' }}>
                      Location
                    </h3>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: 10,
                      }}
                    >
                      <BasenIcon icon={I.LocationGymIcon} />
                      <BasenIcon icon={I.LocationSkiIcon} />
                      <BasenIcon icon={I.LocationSwimIcon} />
                      <BasenIcon icon={I.LocationHikeIcon} />
                      <BasenIcon icon={I.LocationStoreIcon} />
                    </div>
                  </>
                ),
              },
              {
                id: 'alert',
                content: (
                  <>
                    <h2 id="alert">Alert Message</h2>

                    <GridContainer
                      cols={3}
                      style={{ gridTemplateColumns: '10% 60% 30%' }}
                    >
                      <h3></h3>
                      <h3>Desktop & tablet</h3>
                      <h3>Mobile</h3>

                      <h3>Info - Large</h3>
                      <AlertMessage variant={AlertType.Info}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Facilis laudantium dignissimos quod magnam dolore ipsa
                        totam incidunt. Accusamus libero dolorem, voluptatibus
                        eveniet deleniti aliquam laborum!
                      </AlertMessage>
                      <AlertMessage className="mobile" variant={AlertType.Info}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Facilis laudantium dignissimos quod magnam dolore ipsa
                        totam incidunt. Accusamus libero dolorem, voluptatibus
                        eveniet deleniti aliquam laborum!
                      </AlertMessage>
                      <h3>Info - Small</h3>
                      <AlertMessage variant={AlertType.Info}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </AlertMessage>
                      <AlertMessage className="mobile" variant={AlertType.Info}>
                        Lorem ipsum dolor sit amet!
                      </AlertMessage>

                      <h3>Success - Large</h3>
                      <AlertMessage variant={AlertType.Success}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Facilis laudantium dignissimos quod magnam dolore ipsa
                        totam incidunt. Accusamus libero dolorem, voluptatibus
                        eveniet deleniti aliquam laborum!
                      </AlertMessage>
                      <AlertMessage
                        className="mobile"
                        variant={AlertType.Success}
                      >
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Facilis laudantium dignissimos quod magnam dolore ipsa
                        totam incidunt. Accusamus libero dolorem, voluptatibus
                        eveniet deleniti aliquam laborum!
                      </AlertMessage>
                      <h3>Success - Small</h3>
                      <AlertMessage variant={AlertType.Success}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </AlertMessage>
                      <AlertMessage
                        className="mobile"
                        variant={AlertType.Success}
                      >
                        Lorem ipsum dolor sit amet!
                      </AlertMessage>

                      <h3>Warning - Large</h3>
                      <AlertMessage variant={AlertType.Warning}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Facilis laudantium dignissimos quod magnam dolore ipsa
                        totam incidunt. Accusamus libero dolorem, voluptatibus
                        eveniet deleniti aliquam laborum!
                      </AlertMessage>
                      <AlertMessage
                        className="mobile"
                        variant={AlertType.Warning}
                      >
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Facilis laudantium dignissimos quod magnam dolore ipsa
                        totam incidunt. Accusamus libero dolorem, voluptatibus
                        eveniet deleniti aliquam laborum!
                      </AlertMessage>
                      <h3>Warning - Small</h3>
                      <AlertMessage variant={AlertType.Warning}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </AlertMessage>
                      <AlertMessage
                        className="mobile"
                        variant={AlertType.Warning}
                      >
                        Lorem ipsum dolor sit amet!
                      </AlertMessage>

                      <h3>Error - Large</h3>
                      <AlertMessage variant={AlertType.Error}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Facilis laudantium dignissimos quod magnam dolore ipsa
                        totam incidunt. Accusamus libero dolorem, voluptatibus
                        eveniet deleniti aliquam laborum!
                      </AlertMessage>
                      <AlertMessage
                        className="mobile"
                        variant={AlertType.Error}
                      >
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Facilis laudantium dignissimos quod magnam dolore ipsa
                        totam incidunt. Accusamus libero dolorem, voluptatibus
                        eveniet deleniti aliquam laborum!
                      </AlertMessage>
                      <h3>Error - Small</h3>
                      <AlertMessage variant={AlertType.Error}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </AlertMessage>
                      <AlertMessage
                        className="mobile"
                        variant={AlertType.Error}
                      >
                        Lorem ipsum dolor sit amet!
                      </AlertMessage>
                    </GridContainer>
                  </>
                ),
              },
              {
                id: 'help',
                content: (
                  <section>
                    <h2>Helptext</h2>
                    <HelpText
                      content={
                        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus impedit aperiam animi eos laboriosam saepe incidunt temporibus! Nesciunt, iusto facere!'
                      }
                    >
                      <h3>Lorem, ipsum dolor.</h3>
                    </HelpText>
                    <HelpText
                      content={
                        <>
                          <h3>Lorem, ipsum dolor.</h3>
                          <p>Lorem ipsum dolor sit amet consectetur.</p>
                          <h3>Lorem ipsum dolor sit.</h3>
                          <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Harum, deserunt?
                          </p>
                          <h3>Lorem, ipsum.</h3>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing.
                          </p>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. A amet qui necessitatibus.
                          </p>
                        </>
                      }
                    >
                      <div className="flex gap">
                        <BasenIcon icon={I.LocationHikeIcon} />
                        <h3>Lorem, ipsum dolor.</h3>
                        <p>
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit. A amet qui necessitatibus.
                        </p>
                      </div>
                    </HelpText>
                  </section>
                ),
              },
              {
                id: 'rnc',
                content: (
                  <>
                    <h2>Radio & Checkbox</h2>
                    <GridContainer cols={3} gap={100}>
                      <RadioButtonGroup
                        label="Radio buttons"
                        name="rdefault"
                        formHolder={{ rdefault: '1' }}
                        errors={{}}
                        updateForm={() => {}}
                        helpInfo={'Kan ikke endres i Sandbox'}
                        options={[
                          { label: 'Ikke valgt', value: '0' },
                          { label: 'Valgt', value: '1' },
                          { label: 'Ikke valgt', value: '2' },
                        ]}
                      />

                      <RadioButtonGroup
                        disabled
                        label="Radio buttons Disabled"
                        name="rdisabled"
                        formHolder={{ rdisabled: '1' }}
                        errors={{}}
                        updateForm={() => {}}
                        helpInfo={'Kan ikke endres i Sandbox'}
                        options={[
                          { label: 'Ikke valgt', value: '0' },
                          { label: 'Valgt', value: '1' },
                          { label: 'Ikke valgt', value: '2' },
                        ]}
                      />

                      <CheckboxGroup
                        label="Checkboxes"
                        name="cdefault"
                        formHolder={{ cdefault: ['1'] }}
                        errors={{}}
                        updateForm={() => {}}
                        helpInfo={'Kan ikke endres i Sandbox'}
                        options={[
                          { label: 'Ikke valgt', value: '0' },
                          { label: 'Valgt 1', value: '1' },
                          { label: 'Ikke valgt', value: '2' },
                          { label: 'Disabled 3', value: '3', disabled: true },
                        ]}
                      />
                    </GridContainer>
                  </>
                ),
              },
              {
                id: 'badge',
                content: (
                  <>
                    <h2>Badge</h2>
                    <GridContainer cols={4}>
                      <h3>Info</h3>
                      <h3>Success</h3>
                      <h3>Warning</h3>
                      <h3>Error</h3>
                      <Badge variant={AlertType.Info}>Lorem, ipsum.</Badge>
                      <Badge variant={AlertType.Success}>Lorem, ipsum.</Badge>
                      <Badge variant={AlertType.Warning}>Lorem, ipsum.</Badge>
                      <Badge variant={AlertType.Error}>Lorem, ipsum.</Badge>
                      <Badge showIcon variant={AlertType.Info}>
                        Lorem, ipsum.
                      </Badge>
                      <Badge showIcon variant={AlertType.Success}>
                        Lorem, ipsum.
                      </Badge>
                      <Badge showIcon variant={AlertType.Warning}>
                        Lorem, ipsum.
                      </Badge>
                      <Badge showIcon variant={AlertType.Error}>
                        Lorem, ipsum.
                      </Badge>
                    </GridContainer>
                  </>
                ),
              },
              {
                id: 'table',
                content: (
                  <>
                    <h2>Table</h2>
                    <h2>Pagination</h2>
                    {/* Named ServerPagination until old Pagination is no longer in use */}
                    {/* <ServerPagination
                      itemsPerPage={itemsPerPage}
                      totalNumberOfItems={totalNumberOfItems}
                      activePage={activePage}
                      triggerChangePage={(item) => changePage(item)}
                    /> */}
                  </>
                ),
              },
              {
                id: 'counter',
                content: (
                  <div className="fb-form">
                    <Counter
                      name="counter"
                      label="Counter label"
                      formHolder={formHolder}
                      updateForm={handleChange}
                      errors={{}}
                      min={0}
                      max={10}
                    />
                  </div>
                ),
              },
              {
                id: 'images',
                content: <ImageExample />,
              },
              {
                id: 'summary',
                content: (
                  <div>
                    <h3>Generic Summary component</h3>
                    <Summary
                      sections={[
                        { header: 'This', content: 'is' },
                        { header: 'a', content: 'generic' },
                        { header: 'summary', content: 'component' },
                      ]}
                    />
                    <h3>Booking Summary component</h3>
                    <BookingSummary
                      booking={{
                        locationId: '',
                        locationName: 'Kolsås leir',
                        arrivalDate: new Date(2024, 3, 17).toString(),
                        departureDate: new Date(2024, 3, 19).toString(),
                        bookingCreatedDate: new Date(2024, 3, 15).toString(),
                        description: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Facilis laudantium dignissimos quod magnam dolore ipsa
                        totam incidunt. Accusamus libero dolorem, voluptatibus
                        eveniet deleniti aliquam laborum!`,
                        rooms: [{ roomTypeId: '123456789', index: 1 }],
                        bookingIdentifier: '093456',
                        price: 360,
                        paymentInfo: {
                          paymentMethod:
                            'Registrer betalingsopplysninger senere',
                          statusInfo: {
                            status: AlertType.Error,
                            text: 'Kort mangler',
                          },
                        },
                      }}
                    />
                  </div>
                ),
              },
            ]}
          />
        </section>
      </article>
    </div>
  );
};

export default Sandbox;
