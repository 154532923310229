import useLocalStorage from './useLocalStorage';

const useToken = () => {
  const [token, setToken, remove] = useLocalStorage('jwtToken', '');

  const getToken = () => {
    return token?.replace(/"/g, '') || null;
  };
  const saveToken = (userToken: string) => {
    setToken(userToken);
  };

  return {
    getToken,
    setToken: saveToken,
    remove,
  };
};

export default useToken;
