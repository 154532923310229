import './ChangeBooking.scss';

import {
  arenaAvailableRoomsForBookingUrl,
  bookingChangeUrl,
} from 'api/booking/booking_api_v2';
import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import BlinkOn from 'components/BlinkOn/BlinkOn';
import { IBookingSplitValue } from 'contexts/BookingContextNew';
import { useLogin, useMyPage } from 'contexts/index';
import { formatToNorwegianPrice, roomCapacity } from 'helpers/rooms';
import { calculateSplitValues } from 'helpers/splitValues';
import useAxios from 'hooks/useAxios';
import { FC, useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useParams } from 'react-router-dom';
import MyBookingChangePaymentModal from 'src/modules/MyPage/MyBookingChangePaymentModal';
import { ChangeBookingRequest, RoomWithGuest } from 'types/bookingRequests';
import { AvailableRoomType } from 'types/BookingTypes/roomTypes';
import { DeliberateAny, SetState } from 'types/DelibrateAny';
import Modal, { ModalSize } from '../Modal';
import Yup from 'utils/yupExtensions';
import { bookingValidations, handleValidation } from 'utils/formValidation';
import Loader from 'components/Loader/Loader';
import RoomForm from './RoomForm';
import ArrivalDepartureForm from './ArrivalDepartureForm';
import PurposeForm from './PurposeForm';

const ArrivalValidation = Yup.object().shape({
  arrivalDeparture: bookingValidations.arrivalDeparture,
  rooms: bookingValidations.rooms,
  tooFewRooms: bookingValidations.tooFewRooms,
  tooManyRooms: bookingValidations.tooManyRooms,
  purposeOption: bookingValidations.purposeOption,
  purposeDescription: bookingValidations.purposeDescription,
});

export interface ChangeRequestResponse {
  changeRequestId: string;
  confirmationDeadline: Date;
}

interface IChangeBookingProps {
  onClose: SetState<boolean>;
}

const ChangeBooking: FC<IChangeBookingProps> = ({ onClose }) => {
  const alwaysUnavailableDates: Date[] = [];

  const { bookingItem } = useMyPage();
  const { bookingid } = useParams();
  const { setMsg } = useLogin();
  const { sendRequest } = useAxios();
  const {
    sendRequest: sendRequestChange,
    requestLoading: changeRequestLoading,
  } = useAxios();

  const [splitValues, setSplitValues] = useState<IBookingSplitValue>();
  const [arrivalDeparture, setArrivalDeparture] = useState<DateObject[]>([
    new DateObject(bookingItem?.arrivalDate ?? new Date()),
    new DateObject(bookingItem?.departureDate ?? new Date()),
  ]);
  const [errors, setErrors] = useState<DeliberateAny>({});
  const [roomInfo, setRoomInfo] = useState<AvailableRoomType[]>([]);
  const [quoteId, setQuoteId] = useState('');
  const [showChangePayment, setShowChangePayment] = useState(false);
  const [unavailableDates, setUnavailableDates] = useState<Date[]>(
    alwaysUnavailableDates,
  );
  const [roomCount, setRoomCount] = useState<{ [key: string]: number }>(
    getRoomTypeGuestCount(bookingItem),
  );
  const [itemRooms, setItemRooms] = useState<RoomWithGuest[]>(
    bookingItem?.rooms ?? [],
  );
  const [purpose, setPurpose] = useState<DeliberateAny>({
    purposeOption: bookingItem?.purpose,
    purposeDescription: bookingItem?.description,
  });
  const [changeRequestResponse, setChangeRequestResponse] =
    useState<ChangeRequestResponse>();
  const [isFetchingAvailableRooms, setIsFetchingAvailableRooms] =
    useState<boolean>(false);

  const {
    sendRequest: sendFetchAvailableDatesRequest,
    requestLoading: isFetchingAvailableDates,
  } = useAxios();

  function getRoomTypeGuestCount(bookingData) {
    const roomTypeGuestCount = {};

    bookingData.rooms.forEach((room) => {
      const roomTypeId = room.roomTypeId;
      const guestCount = room.guests.length;

      if (roomTypeGuestCount[roomTypeId]) {
        roomTypeGuestCount[roomTypeId] += guestCount;
      } else {
        roomTypeGuestCount[roomTypeId] = guestCount;
      }
    });

    return roomTypeGuestCount;
  }

  const fetchAvailableDates = async (locationId: string) => {
    if (!bookingid) return;

    await sendFetchAvailableDatesRequest(
      {
        method: 'GET',
        url: arenaAvailableRoomsForBookingUrl(
          locationId,
          arrivalDeparture?.[0].toDate(),
          arrivalDeparture?.[1].toDate(),
          0,
          bookingid,
        ),
      },
      (r) => {
        setUnavailableDates([
          ...alwaysUnavailableDates,
          ...r.unavailableDates.map((date) => new Date(date)),
        ]);
      },
    );
  };

  const handleSubmitChange = async () => {
    if (!bookingid) {
      setMsg('Ukjent booking id!');
      return;
    }

    if (itemRooms && roomInfo && roomCount) {
      const capacity = roomCapacity(roomInfo, roomCount);

      const numGuests = itemRooms
        .map((r) => r.guests?.length ?? 0)
        .reduce((prev, cur) => prev + cur);

      const validationResponse = await handleValidation(
        ArrivalValidation,
        {
          arrivalDeparture: arrivalDeparture,
          rooms: itemRooms,
          tooFewRooms: capacity.maxGuests < numGuests,
          tooManyRooms: capacity.minGuests > numGuests,
          purposeOption: purpose.purposeOption,
          purposeDescription: purpose.purposeDescription,
        },
        {},
      );

      if (validationResponse.success) {
        setErrors({});

        await sendChangeRequest(bookingid);
      } else {
        setErrors(validationResponse.errors);
      }
    }
  };

  const sendChangeRequest = async (bookingId) => {
    const changeBookingRequest: ChangeBookingRequest = {
      bookingId: bookingId,
      quoteId: quoteId,
      arrivalDate: arrivalDeparture[0].format('YYYY-MM-DD'),
      departureDate: arrivalDeparture[1].format('YYYY-MM-DD'),
      guestListPostponed: false, // TODO
      unnamedGuests: false, // TODO
      civilGuests: false, // TODO,
      rooms: itemRooms, // Assuming itemRooms is already in the correct format
      description: purpose.purposeDescription,
      purpose: purpose.purposeOptions,
    };

    const { submit, ...restErrors } = errors;
    setErrors({
      ...restErrors,
    });

    await sendRequestChange(
      {
        method: 'POST',
        url: bookingChangeUrl,
        data: changeBookingRequest,
      },
      (res) => {
        setChangeRequestResponse(res);
        setShowChangePayment(true);
      },
      null,
      (err) => {
        const response = err.response.data;
        setErrors({
          ...errors,
          submit:
            response?.detail ??
            response?.title ??
            'Kunne ikke gjennomføre endring!',
        });
      },
    );
  };

  const IsUnchangedBooking = () => {
    if (bookingItem) {
      const initialItems = {
        arrivalDate: new DateObject(bookingItem.arrivalDate),
        departureDate: new DateObject(bookingItem.departureDate),
        rooms: bookingItem.rooms,
        numRooms: bookingItem.rooms?.length ?? 0,
        description: bookingItem.description,
        purpose: bookingItem.purpose,
      };
      const currentItems = {
        arrivalDate: arrivalDeparture[0],
        departureDate: arrivalDeparture[1],
        rooms: itemRooms,
        numRooms: itemRooms.length,
        description: purpose.purposeDescription,
        purpose: purpose.purposeOption,
      };
      return JSON.stringify(initialItems) === JSON.stringify(currentItems);
    }
    return false;
  };

  const getSubmitText = () => {
    let submit = {
      text: '',
      disable: true,
    };

    if (splitValues === undefined) {
      submit.text = 'Laster...';
      return submit;
    }

    if (splitValues?.rooms === 0) {
      submit.text = 'Ingen valgte rom';
      return submit;
    }

    if (IsUnchangedBooking()) {
      submit.text = 'Ingen endringer';
      return submit;
    }

    submit.text = `Endre (${formatToNorwegianPrice(splitValues?.price ?? 0)})`;
    submit.disable = false;
    return submit;
  };

  useEffect(() => {
    const updatedValues = calculateSplitValues(
      arrivalDeparture,
      roomCount,
      roomInfo,
    );
    setSplitValues(updatedValues);
    return () => {
      setSplitValues(undefined);
    };
  }, [arrivalDeparture, roomInfo, roomCount]);

  useEffect(() => {
    if (bookingItem?.locationId) {
      if (bookingItem.locationId && bookingItem.locationId !== '') {
        fetchAvailableDates(bookingItem.locationId);
      }
    }
  }, [bookingItem?.locationId]);

  useEffect(() => {
    IsUnchangedBooking();
  }, [itemRooms]);

  return (
    <>
      <Modal
        header={'Endre overnatting'}
        content={
          <div className="fb-change-booking">
            {errors.submit && (
              <AlertMessage className="sticky-top" variant={AlertType.Error}>
                <BlinkOn trigger={errors.submit}>{errors.submit}</BlinkOn>
              </AlertMessage>
            )}
            <Loader isLoading={isFetchingAvailableDates}>
              <ArrivalDepartureForm
                errors={errors}
                unavailableDates={unavailableDates}
                arrivalDeparture={arrivalDeparture}
                setArrivalDeparture={setArrivalDeparture}
                setQuoteId={setQuoteId}
                setRoomInfo={setRoomInfo}
                setIsFetchingAvailableRooms={setIsFetchingAvailableRooms}
              />
              <Loader isLoading={isFetchingAvailableRooms}>
                <div className="fb-change-rooms room-types">
                  <RoomForm
                    errors={errors}
                    arrivalDeparture={arrivalDeparture}
                    roomInfo={roomInfo}
                    roomCount={roomCount}
                    setRoomCount={setRoomCount}
                    itemRooms={itemRooms}
                    setItemRooms={setItemRooms}
                  />
                  <PurposeForm
                    errors={errors}
                    purpose={purpose}
                    setPurpose={setPurpose}
                  />
                </div>
              </Loader>
            </Loader>
          </div>
        }
        onClose={() => onClose(false)}
        showCancelButton={true}
        showCancelIcon={true}
        loadingSubmit={changeRequestLoading}
        onSubmit={handleSubmitChange}
        disableSubmit={getSubmitText().disable}
        overrideSubmitButtonText={getSubmitText().text}
        size={ModalSize.Large}
      />

      <MyBookingChangePaymentModal
        showModal={showChangePayment}
        setShowModal={setShowChangePayment}
        changeRequestResponse={changeRequestResponse}
        purpose={purpose.purposeOption}
        description={purpose.purposeDescription}
      />
    </>
  );
};

export default ChangeBooking;
