import { removeFirstError } from "actions/errorActions";
import Modal from "components/modals/Modal/Modal";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { DeliberateAny } from "types/DelibrateAny";

const GlobalError = ({ errors }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<DeliberateAny>();

  const onClose = () => {
    dispatch(removeFirstError());
  };

  useEffect(() => {
    setError(errors[0]?.error.response.data);
  }, [errors]);

  return (
    <Modal
      isOpen={errors && error}
      onClose={onClose}
      title={errors && error && error.Title}
    >
      <p>{errors && error && error.Text}</p>
    </Modal>
  );
};

export default connect(
  (state: DeliberateAny) => ({
    errors: state.errors,
  }),
  removeFirstError
)(GlobalError);
