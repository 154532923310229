/* eslint react/prop-types: 0 */

import './WidgetKvartalet.scss';
import './image-gallery.scss';

import { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import { DeliberateAny } from 'types/DelibrateAny';

import { proffQuartersURL } from '../../../../api/basenproff/basenproff_api';
import { axiosFetch } from '../../../../hooks/useAxios';

const WidgetKvartalet = ({ deleteModule, saveModule, id }) => {
  const [quarterInfo, setQuarterInfo] = useState<DeliberateAny[]>();
  const [widgetSettings, setWidgetSettings] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    let isSubscribed = true;
    const url = proffQuartersURL;
    axiosFetch({ url }).then((response) => {
      if (isSubscribed) {
        setQuarterInfo(response);
        setActiveIndex(response.length - 1);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, []);

  const renderItem = (quarter, year, links, img) => (
    <div
      className="flex-row defined-widths horizontal-padding"
      style={{ height: "250px", marginLeft: "30px", marginRight: "30px" }}
    >
      <div className="half">
        <img src={img} alt="" />
      </div>
      <div className="half" style={{ textAlign: "left" }}>
        <h3 className="no-margin">{`Q${quarter} ${year}`}</h3>

        <ul>
          {links.map((link) => (
            <li key={link.MarketingArea} style={{ whiteSpace: "break-spaces" }}>
              <a href={link.Url} style={{ lineHeight: "20px" }}>
                {link.MarketingArea}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  const handleSave = () => {
    setWidgetSettings(false);
    saveModule(id);
  };

  return (
    <div
      className={`widget kvartalet carousel${
        widgetSettings ? " show-settings" : ""
      }`}
    >
      <div className="widget-wrapper">
        <div className="widget-inner-wrapper">
          <div className="header-wrapper">
            <header>
              <button
                className="btn-widget-settings"
                onClick={() => setWidgetSettings(!widgetSettings)}
              />
              <h3>Kvartalet</h3>
            </header>
            <div className="widget-settings-wrapper">
              <div className="widget-settings">
                <div className="widget-specific-settings margin" />
                <button className="btn white" onClick={() => deleteModule(id)}>
                  Fjern Modul
                </button>
                <button
                  className="btn white align-right"
                  onClick={() => handleSave()}
                >
                  Lagre
                </button>
              </div>
            </div>
            <div
              className={`widget-content-container${
                !quarterInfo ? " widget-loading" : ""
              }`}
            >
              <div className="widget-loader" />
              <div className="widget-content">
                <div className="photo">
                  {quarterInfo && (
                    <ReactImageGallery
                      items={quarterInfo.map((el, index) => ({
                        original: el.Thumbnail,
                        bulletClass:
                          index === activeIndex
                            ? "activeBulletClass"
                            : "bulletClass",
                        renderItem: () =>
                          renderItem(
                            el.QuarterNumber,
                            el.Year,
                            el.IssuLinks,
                            el.Thumbnail
                          ),
                      }))}
                      showPlayButton={false}
                      showThumbnails={false}
                      showFullscreenButton={false}
                      showBullets
                      onSlide={(index) => setActiveIndex(index)}
                      startIndex={activeIndex}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetKvartalet;
