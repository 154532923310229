import Loader from 'components/Loader/Loader';
import { useUser } from 'contexts/index';
import { Outlet } from 'react-router-dom';

import NoAuthReporting from './NoAuthReporting';

const ReportingSection = () => {
  const { hasUser, requestLoading } = useUser();

  return (
    <div>
      <Loader isLoading={requestLoading}>
        {!hasUser ? <NoAuthReporting /> : <Outlet />}
      </Loader>
    </div>
  );
};

export default ReportingSection;
