import List from "components/List/List";
import React, { Component } from "react";
import { GuestItemType } from "types/guardPageTypes";
import { v4 as uuidv4 } from "uuid";

import ReservationColumns from "./ReservationColumns";
import UnconnectedGuestItem from "./ReservationItem/ReservationItem";

type PropsType = {
  items: Array<GuestItemType>;
};

export class UnconnectedReservationList extends Component {
  declare props: PropsType;

  renderItem = (item: GuestItemType) => (
    <UnconnectedGuestItem
      key={uuidv4()}
      item={item}
      columns={ReservationColumns}
    />
  );

  render() {
    return (
      <div className="guest-columns">
        <List
          columns={ReservationColumns}
          renderItem={this.renderItem}
          noItemsMessage={<p>Ingen reservasjoner funnet.</p>}
          alternateRows
          {...this.props}
        />
      </div>
    );
  }
}

export default UnconnectedReservationList;
