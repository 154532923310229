import {
  fetchHousingAdminServiceAreas,
  updateHousingRenewalApplicationsPage,
} from 'actions/housingAdminActions';
import { fetchHousingDataOptions } from 'actions/optionsActions';
import {
  getProcessedRenewalAppsAsPDFURL,
  getUnprocessedRenewalAppsAsPDFURL,
} from 'api/housing/housing_api';
import DownloadButton from 'components/buttons/DownloadButton/DownloadButton';
import Loader from 'components/Loader/Loader';
import connect from 'helpers/connectWithRouter';
import {
  shouldUpdateAreaId,
  UpdateAreaIdType,
} from 'helpers/housingAdminHelpers';
import { Component } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import HousingRenewalApplicationsList from './HousingRenewalApplicationsList/HousingRenewalApplicationsList';

import type { HousingRenewalAreaType } from 'types/housingAdminTypes';
type PropsType = UpdateAreaIdType & {
  housingRenewalArea: HousingRenewalAreaType;
  loadingHousingAreas: boolean;
  housingDataOptionsLoaded: boolean;
  updateHousingRenewalApplicationsPage: (housingAreaId: string) => void;
  fetchHousingAdminServiceAreas: () => void;
  fetchHousingDataOptions: () => void;
};

export class UnconnectedHousingRenewalApplications extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    this.props.fetchHousingAdminServiceAreas();
    this.props.fetchHousingDataOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    if (shouldUpdateAreaId(this.props, nextProps)) {
      this.changeAreaId(nextProps);
    }
  }

  changeAreaId = (props: PropsType) => {
    const housingAreaId = props.params.housingAreaId || '';
    this.props.updateHousingRenewalApplicationsPage(housingAreaId);
  };

  unprocessedRenewalApplicationsDownloadLink = () =>
    `${getUnprocessedRenewalAppsAsPDFURL}?housingAreaId=${this.props.housingRenewalArea.Id}`;

  processedRenewalApplicationsDownloadLink = () =>
    `${getProcessedRenewalAppsAsPDFURL}?housingAreaId=${this.props.housingRenewalArea.Id}`;

  render() {
    return (
      <div className="scrollable sticky-middle max-width-large">
        <Loader
          isLoading={
            this.props.housingRenewalArea.loadingUnprocessedApplications ||
            this.props.housingRenewalArea.loadingProcessedApplications ||
            this.props.loadingHousingAreas ||
            !this.props.housingDataOptionsLoaded
          }
        >
          <div>
            <h2>{this.props.housingRenewalArea.Name}</h2>
            <div className="button-row">
              <div>
                <h3 className="flex-end">
                  Ubehandlede søknader:{' '}
                  <span className="red">
                    {
                      this.props.housingRenewalArea.unprocessedApplications
                        .length
                    }
                  </span>
                </h3>
              </div>
              <span style={{ marginBottom: '16px' }}>
                <div>
                  <DownloadButton
                    href={this.unprocessedRenewalApplicationsDownloadLink()}
                    disabled={
                      this.props.housingRenewalArea.unprocessedApplications
                        .length === 0
                    }
                  >
                    Last ned ubehandlede søknader (PDF)
                  </DownloadButton>
                </div>
              </span>
            </div>

            <div className="margin">
              <HousingRenewalApplicationsList
                items={this.props.housingRenewalArea.unprocessedApplications}
                listType="unprocessed"
                uncontrolledSortOptions={{
                  sortColumnOnMount: 'DateApplied',
                  sortDescending: true,
                }}
                noItemsMessage={<p>Ingen ubehandlede søknader</p>}
              />
            </div>
          </div>

          <div>
            <div className="button-row">
              <div>
                <h3 className="flex-end">
                  Historiske søknader:{' '}
                  <span className="red">
                    {this.props.housingRenewalArea.processedApplications.length}
                  </span>
                </h3>
              </div>
              <span style={{ marginBottom: '16px' }}>
                <div>
                  <DownloadButton
                    href={this.processedRenewalApplicationsDownloadLink()}
                    disabled={
                      this.props.housingRenewalArea.processedApplications
                        .length === 0
                    }
                  >
                    Last ned behandlede søknader (PDF)
                  </DownloadButton>
                </div>
              </span>
            </div>
            <div className="margin">
              <p>
                Under historiske søknader vises søknader om forlengelse av bolig
                som er godkjent eller avvist i løpet av de siste 30 dager.
              </p>
            </div>
            <HousingRenewalApplicationsList
              items={this.props.housingRenewalArea.processedApplications}
              listType="processed"
              uncontrolledSortOptions={{
                sortColumnOnMount: 'DateApplied',
                sortDescending: true,
              }}
              noItemsMessage={<p>Ingen historiske søknader</p>}
            />
          </div>
        </Loader>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    loadingHousingAreas: state.housingAdmin.loadingHousingAreas,
    housingRenewalArea: state.housingAdmin.housingRenewalArea,
    housingDataOptionsLoaded: state.options.housingDataOptionsLoaded,
  }),
  {
    updateHousingRenewalApplicationsPage,
    fetchHousingAdminServiceAreas,
    fetchHousingDataOptions,
  },
)(UnconnectedHousingRenewalApplications);
