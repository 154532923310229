// @flow

import _ from 'lodash/fp';

const signRentalTypeWarning = (rentalType: string) =>
  `Merk at signerte ${rentalType} er unntatt offentlighet jmfr. Offentlighetsloven § 13, 1. ledd. Hvis kontrakten lastes ned etter signering må den derfor behandles ihht gjeldende rutiner for dokumenter unntatt offentlighet.`;

const myKeys = (inNo: number) =>
  _.invert({
    deal: 0,
    moveIn: 1,
    moveOut: 3,
  })[inNo];

export const SIGNED_RENTAL_CONTRACT_WARNING =
  signRentalTypeWarning('husleiekontrakter');

export const SIGNED_RENTAL_PROTOCOL_WARNING =
  signRentalTypeWarning('leieprotokoller');

export const INSPECTION_STATUSES = {
  REGISTERED: 0,
  COMPLETED: 1,
  INSPECTION_RESULT_SEND: 3,
  VOID: 6,
};

export const RESERVED_STATUSES = {
  NOT_RESERVED: 0,
  OUR_PROPOSAL: 1,
  CUSTOMER_PROPOSAL: 2,
  RESERVED: 3,
};

export const RENTALSTATUSIMAGE = (rentalStatus: number) => {
  const BASE_PATH = process.env.NODE_ENV === 'production' ? '/Content' : '';
  const imageObj = {
    deal: {
      src: `${BASE_PATH}/assets/img/leieforhold_avtale.svg`,
      alt: 'ikon av en nøkkel og håndtrykk.',
    },
    moveIn: {
      src: `${BASE_PATH}/assets/img/leieforhold_inflytting.svg`,
      alt: 'ikon av en person som flytter esker inn i bygning.',
    },
    moveOut: {
      src: `${BASE_PATH}/assets/img/leieforhold_utflytting.svg`,
      alt: 'ikon av en person som flytter esker ut av bygning.',
    },
    default: {
      src: `${BASE_PATH}/assets/img/leieforhold_hus.svg`,
      alt: 'ikon som viser en bygning.',
    },
  };
  return imageObj[myKeys(rentalStatus)] || imageObj.default;
};
