import { FC, useState } from 'react';
import Modal, { ModalSize } from 'components/ModalComponents/Modal';
import { useNewBooking } from 'contexts/index';
import ConfirmWithIoForm, {
  IIoDepartment,
} from 'src/modules/MyPage/ConfirmWithIoForm';
import useAxios from 'hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { reservationConfirmWithIoUrl } from 'api/booking/booking_api_v2';
import { DeliberateAny, SetState } from 'types/DelibrateAny';
import Yup from 'utils/yupExtensions';
import { labelYupError } from 'helpers/yupLocales';

interface IConfirmWithIoModal {
  setShowModal: SetState<boolean>;
  setIsLoading: SetState<boolean>;
}

const ConfirmWithIoModal: FC<IConfirmWithIoModal> = ({
  setShowModal,
  setIsLoading,
}) => {
  const { bookingOptions, paymentInfo, reservationId, errors, setErrors } =
    useNewBooking();
  const { sendRequest } = useAxios();
  const navigate = useNavigate();

  const [ioDepartment, setIoDepartment] = useState<IIoDepartment>({
    department: '',
    ioNumber: '',
  });

  const IoValidation = Yup.object().shape({
    department: Yup.string().required(),
    ioNumber: Yup.string().required(),
  });

  const fieldNames = {
    department: 'Velg avdeling',
    ioNumber: 'IO Nummer',
  };

  const handleOnSubmitPaymentModal = async () => {
    try {
      if (IoValidation)
        await IoValidation.validate(ioDepartment, {
          abortEarly: false,
          context: fieldNames,
        });
      setErrors({});

      await orderBookingConfirmWithIO();
    } catch (err: DeliberateAny) {
      const validationErrors = {};
      err.inner.forEach(
        (error) =>
          (validationErrors[error.path] = labelYupError(error, fieldNames)),
      );
      setErrors(validationErrors);
    }
  };

  const orderBookingConfirmWithIO = async () => {
    setIsLoading(true);
    setShowModal(false);

    if (reservationId) {
      await sendRequest(
        {
          method: 'POST',
          url: reservationConfirmWithIoUrl(reservationId),
          data: {
            purpose: paymentInfo.purposeOption,
            description: paymentInfo.purposeDescription,
            ioNumber: ioDepartment.ioNumber,
            department: ioDepartment.department.toString(),
          },
        },
        (response) => {
          navigate(`/mypage/booking/${response.bookingId}/receipt`);
        },
        null,
        (error) => {
          // TODO: Error handling
        },
      );
    }

    setIsLoading(false);
  };

  return (
    <Modal
      size={ModalSize.Medium}
      className="fit-content-modal"
      showCancelButton
      header={'Betaling for overnatting'}
      content={
        bookingOptions ? (
          <ConfirmWithIoForm
            bookingOptions={bookingOptions}
            ioDepartment={ioDepartment}
            setIoDepartment={setIoDepartment}
            bookingError={errors}
          />
        ) : (
          <></>
        )
      }
      onClose={() => setShowModal(false)}
      onSubmit={() => handleOnSubmitPaymentModal()}
    />
  );
};

export default ConfirmWithIoModal;
