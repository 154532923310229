import { ValueOption } from 'components/FormComponents/FormPropBase';
import Select from 'components/FormComponents/Select';
import TextAreaInput from 'components/FormComponents/TextAreaInput';
import React from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

interface PurposeSelectProps {
  purposeOptions: ValueOption[];
  formholder: DeliberateAny;
  updateForm: (id: string, value: string | number) => void;
  bookingError: any;
  optionFieldName: string;
  descriptionFieldName: string;
}

const PurposeSelect: React.FC<PurposeSelectProps> = ({
  purposeOptions,
  formholder,
  updateForm,
  bookingError,
  optionFieldName,
  descriptionFieldName,
}) => {
  return (
    <div className="purpose-info">
      <span className="purpose-header">Formål</span>
      <div className="purpose-content">
        <div style={{ width: '70%' }}>
          <Select
            options={purposeOptions}
            name="purposeOption"
            label={optionFieldName}
            formHolder={formholder}
            updateForm={updateForm}
            errors={bookingError}
            placeHolder="Velg formål"
            required
          />
        </div>
        <TextAreaInput
          name="purposeDescription"
          label={descriptionFieldName}
          formHolder={formholder}
          updateForm={updateForm}
          errors={bookingError}
          rows={8}
          maxChar={350}
          required
        />
      </div>
    </div>
  );
};

export default PurposeSelect;
