import { useState, useEffect } from "react"; // Adjust the import path as necessary
import useAxios from "./useAxios";
import { DeliberateAny } from "types/DelibrateAny";

const useFetch = <T>(url: string, userData: DeliberateAny = null) => {
  const [data, setData] = useState<T | null>(null);
  const { requestLoading, requestError, sendRequest } = useAxios();

  useEffect(() => {
    const fetchData = () => {
      const axiosParams = {
        method: "GET",
        url: url,
        withCredentials: true,
      };

      const applyData = (fetchedData: T) => {
        setData(fetchedData);
      };

      sendRequest(axiosParams, applyData, userData);
    };

    fetchData();
  }, [url, userData, sendRequest]);

  return { data, isLoading: requestLoading, error: requestError };
};

export default useFetch;
