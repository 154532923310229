import { createContext, FC, Fragment, useMemo, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { IWrapper } from 'src/interfaces/IWrapper';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/design/_toast.scss';
import { DeliberateAny } from 'types/DelibrateAny';

export enum ToastType {
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export enum ToastPosition {
  TopLeft = 'top-left',
  TopCenter = 'top-center',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomCenter = 'bottom-center',
  BottomRight = 'bottom-right',
}

export enum ToastTheme {
  Light = 'light',
  Dark = 'dark',
  Colored = 'colored',
}

export interface ToastSettings {
  position?: ToastPosition;
  theme?: ToastTheme;
  className?: string;
  autoCloseTimeMs?: number;

  hideProgressBar?: boolean;
  newestOnTop?: boolean;
  draggable?: boolean;
  pauseOnHover?: boolean;
  closeOnClick?: boolean;
}

export interface ToastContextState {
  createToastMessage: (
    message: string,
    toastType?: ToastType,
    toastSettings?: ToastSettings,
  ) => void;
  createPromiseToastMessage: (
    promiseFunction: Promise<DeliberateAny>,
    promiseParams: { pending: string; success?: string; error?: string },
    toastSettings?: ToastSettings,
  ) => void;
}

export const ToastContext = createContext({} as ToastContextState);

export const ToastContextProvider: FC<IWrapper> = ({ children }) => {
  const toastSettings = {
    position: ToastPosition.BottomRight,
    theme: ToastTheme.Light,
    className: '',
    autoCloseTimeMs: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    draggable: false,
    pauseOnHover: true,
    closeOnClick: false,
  };

  const createToastMessage = (
    message: string,
    toastType?: ToastType,
    settings?: ToastSettings,
  ) => {
    if (!toastType) {
      toastType = ToastType.Info;
    }
    let customToastSettings = toastSettings;
    if (settings) {
      Object.keys(settings).forEach(
        (key) =>
          (customToastSettings = {
            ...customToastSettings,
            [key]: settings[key],
          }),
      );
    }
    switch (toastType) {
      case ToastType.Info:
        toast.info(message, customToastSettings);
        break;
      case ToastType.Success:
        toast.success(message, customToastSettings);
        break;
      case ToastType.Warning:
        toast.warning(message, customToastSettings);
        break;
      case ToastType.Error:
        toast.error(message, customToastSettings);
        break;
    }
  };

  const createPromiseToastMessage = async (
    promiseFunction: Promise<DeliberateAny>,
    promiseParams: { pending: string; success?: string; error?: string },
    settings?: ToastSettings,
  ) => {
    let customToastSettings = toastSettings;
    if (settings) {
      Object.keys(settings).forEach(
        (key) =>
          (customToastSettings = {
            ...customToastSettings,
            [key]: settings[key],
          }),
      );
    }
    await toast.promise(promiseFunction, promiseParams, customToastSettings);
  };

  const values = useMemo(
    () => ({
      createToastMessage,
      createPromiseToastMessage,
    }),
    [toastSettings],
  );

  return (
    <ToastContext.Provider value={values}>
      <Fragment>{children}</Fragment>
      <ToastContainer
        position={toastSettings.position}
        theme={toastSettings.theme}
        className={`toast-message ${toastSettings.className}`}
        autoClose={toastSettings.autoCloseTimeMs}
        hideProgressBar={toastSettings.hideProgressBar}
        newestOnTop={toastSettings.newestOnTop}
        draggable={toastSettings.draggable}
        pauseOnHover={toastSettings.pauseOnHover}
        closeOnClick={toastSettings.closeOnClick}
      />
    </ToastContext.Provider>
  );
};
