import { ReactNode } from 'react';

import Modal, { ModalSize } from '../Modal/Modal';

type PropsType = {
  title: string;
  infoText?: string | ReactNode;
  html?: string;
  isOpen: boolean;
  size?: ModalSize;
  onClose: () => void;
  onOpen?: () => void;
};

const ConfirmationModal = (props: PropsType) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      ariaLabel={props.title}
      size={props.size ?? 'small'}
      cancel={{
        hide: true,
      }}
      submit={{
        text: 'Lukk',
        color: 'red',
      }}
      dataTestIdPrefix="confirmation"
    >
      <header>
        <h2>{props.title}</h2>
        {props.html !== undefined && props.html !== '' ? (
          <p dangerouslySetInnerHTML={{ __html: props.html }} />
        ) : (
          <p>{props.infoText}</p>
        )}
      </header>
    </Modal>
  );
};

export default ConfirmationModal;
