import PropTypes from 'prop-types';
import { FC } from 'react';

interface ISpinnerProp {
  white?: boolean;
  size?: 'small' | 'medium' | 'large' | 'giga';
}

const Spinner: FC<ISpinnerProp> = ({ white, size }) => {
  const sizeMap = {
    small: 24,
    medium: 48,
    large: 96,
    giga: 128,
  };

  const spinnerSize = sizeMap[size || 'small']; // Default to 'medium' if size is not provided

  return (
    <svg
      width={spinnerSize}
      height={spinnerSize}
      viewBox="0 0 24 24"
      style={{ margin: 'auto' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        {`.spinner_z9k8 { transform-origin: center; animation: spinner_StKS .75s infinite linear; }
         @keyframes spinner_StKS { 100% { transform: rotate(360deg); } }`}
      </style>
      <path
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        opacity=".25"
        fill={white ? 'white' : 'black'}
      />
      <path
        d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
        className="spinner_z9k8"
        fill={white ? 'white' : 'black'}
      />
    </svg>
  );
};

Spinner.propTypes = {
  white: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'giga']),
};

export default Spinner;
