import { useLogin, useUser } from 'contexts/index';
import { useEffect } from 'react';

const Oops = () => {
  const { fetchUser } = useUser();
  const { setShow, setLoginUrl } = useLogin();

  const clearSession = async () => {
    // await logout(false);
    await fetchUser();

    setLoginUrl(`/#`);
  };

  useEffect(() => {
    clearSession();
  }, []);

  return (
    <div>
      <h2>Sesjonen er utløpt</h2>
      <p>
        Vennligst{' '}
        <button onClick={() => setShow(true)} className="btn link-style">
          logg inn
        </button>{' '}
        på nytt og prøv igjen.
      </p>
    </div>
  );
};

export default Oops;
