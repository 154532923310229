import "./HousingAllocationsColumnsPreview.scss";

const HousingAllocationsColumnsPreview = [
  {
    label: "Leietager",
    name: "Name",
    className: "name",
    renderFunction: (item) => (
      <div className="column-tenant description">
        <h5>{`${item.FirstName} ${item.LastName}`}</h5>
        <span className={"spanStyle"}>Ansattnummer: {item.EmployeeNumber}</span>
      </div>
    ),
  },

  {
    label: "Adresse",
    name: "Address",
    className: "address",
    renderFunction: (item) => (
      <div className="column-address">
        <strong>{item.HousingName}</strong>
        <br />
        Leie-ID: {item.HousingRentalId}
      </div>
    ),
  },
];

export default HousingAllocationsColumnsPreview;
