// @flow

import {
  sendInspectionForm,
  updateAllInspectionFormErrors,
  updateInspectionForm,
  updateInspectionRoom,
  updateSingleInspectionFormError,
} from 'actions/inspectionActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Checkbox from 'components/form/Checkbox/Checkbox';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import TextArea from 'components/form/TextArea/TextArea';
import LabelValueList from 'components/LabelValueList/LabelValueList';
import Link from 'components/Link/Link';
import RouterModal from 'components/modals/RouterModal/RouterModal';
import NumberInput from 'components/NumberInput/NumberInput';
import DOMPurify from 'dompurify';
import connect from 'helpers/connectWithRouter';
import {
  CREATEOBJECTRESPONSE,
  QUESTION_TYPE_NUMBER,
  SHOW_RESPONSE,
} from 'helpers/inspectionHelpers';
import { Component } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropSlideNavigation } from 'types/PropNavigationBase';
import * as yup from 'yup';

import type { RoomType } from 'types/inspectionTypes';
import type { RentalContractTextsType } from 'types/myRentalsTypes';
type PropsType = PropSlideNavigation & {
  nextSlidePath: string;
  room: RoomType;
  rentalId: string;
  inspectionId: string;
  updateInspectionForm: (key: string, value: string | number) => void;
  updateAllInspectionFormErrors: (errors: object) => void;
  updateSingleInspectionFormError: (name: string, errors: object) => void;
  updateInspectionRoom: (roomQuestions: object) => void;
  sendInspectionForm: (
    rentalobjectid: string,
    inspectionid: string,
    inspectionForm: object,
    isComplete: boolean,
  ) => void;
  inspectionForm: DeliberateAny;
  errors: object;
  saveInspectionLog: DeliberateAny;
  rentalContractTexts: RentalContractTextsType;
  onlyModals?: boolean;
};

export class UnconnectedInspectionPage extends Component {
  declare props: PropsType;
  state: {
    checkAllYes: boolean;
    acceptToSend: boolean;
    continueLaterSaved: boolean;
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      checkAllYes: false,
      acceptToSend: false,
      continueLaterSaved: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    if (nextProps.saveInspectionLog !== this.props.saveInspectionLog) {
      if (nextProps.saveInspectionLog.savingFailed) {
        this.props.navigate(this.saveErrorPath());
      } else {
        this.props.navigate(this.props.location.pathname);
      }
    }
  }

  onSubmit = () => {
    const roomQuestions: DeliberateAny[] = [];
    let counter = 0;

    const currentRoomAnswers = this.props.inspectionForm.Groups.filter(
      (room) => room.Code === this.props.room.Code,
    );

    if (currentRoomAnswers && currentRoomAnswers.length > 0) {
      currentRoomAnswers[0].Elements.forEach((question) => {
        if (
          question.ElementType === QUESTION_TYPE_NUMBER &&
          question.Response == null
        ) {
          roomQuestions.push({
            ...question,
            ...{ Response: CREATEOBJECTRESPONSE(0) },
          });
          counter += 1;
        } else {
          roomQuestions.push(question);
        }
      });
    }

    if (counter > 0) {
      this.updateRoom(roomQuestions);
    }

    if (!this.props.nextSlidePath) {
      this.setState({ acceptToSend: !this.state.acceptToSend });
      this.saveInspection();
    }

    if (this.props.nextSlidePath) {
      this.props.goToSlide(this.props.nextSlidePath);
    } else if (!this.props.saveInspectionLog.savingFailed) {
      this.props.navigate(this.inspectionDonePath());
    }
  };

  setAllYes = () => {
    const roomQuestions: DeliberateAny[] = [];
    if (!this.state.checkAllYes) {
      this.props.room.Elements.forEach((question) => {
        if (question.ElementType !== QUESTION_TYPE_NUMBER) {
          roomQuestions.push({
            ...question,
            ...{ Response: CREATEOBJECTRESPONSE('Ja') },
          });
        } else {
          roomQuestions.push(question);
        }
      });

      this.updateRoom(roomQuestions);
    }
    this.setState({ checkAllYes: !this.state.checkAllYes });
  };

  onChangeValue = (name: string, value: string | number | boolean) => {
    // name is from function getFormId, where we want to get questionIndex (index of "Elements")
    const regex = new RegExp(/\d+/, 'g');
    const match = name.match(regex);
    const questionIndex = match ? match[1] : null;

    const roomQuestions: DeliberateAny[] = [];
    this.props.inspectionForm.Groups[this.groupToUse()].Elements.forEach(
      (questionWithResponse) => {
        roomQuestions.push(questionWithResponse);
      },
    );

    if (questionIndex) {
      roomQuestions[questionIndex] = {
        ...roomQuestions[questionIndex],
        ...{ Response: CREATEOBJECTRESPONSE(value.toString()) },
      };
    }
    this.updateRoom(roomQuestions);
  };

  getFormId = (questionIndex: number) => {
    const roomIndex = this.props.inspectionForm.Groups.findIndex(
      (room) => room.Code === this.props.room.Code,
    );
    return `Groups[${roomIndex}].Elements[${questionIndex}]`;
  };

  updateRoom = (roomQuestions: Array<object>) => {
    const roomObj = this.props.inspectionForm.Groups.findIndex(
      (rooms) => rooms.Code === this.props.room.Code,
    );
    const updatedRoom = {
      ...this.props.room,
      ...{ Elements: [...roomQuestions] },
    };
    const groups = [...this.props.inspectionForm.Groups];
    groups.splice(roomObj, 1, updatedRoom);

    this.props.updateInspectionRoom(groups);
  };

  saveInspection = () => {
    this.props.sendInspectionForm(
      this.props.rentalId,
      this.props.inspectionId,
      this.props.inspectionForm,
      true,
    );
  };

  submitContinueLater = () => {
    this.saveInspection();
    if (!this.props.saveInspectionLog.savingFailed) {
      this.setState({ continueLaterSaved: true });
    }
  };

  generateValidatorConfig = () => {
    const validatorConfig = {};

    this.props.room.Elements.forEach((question, index) => {
      const formId = this.getFormId(index);

      validatorConfig[`${formId}`] = yup.string().required('required');
    });

    return yup.object().shape(validatorConfig).defined();
  };

  reportedIssues = () => {
    const questions: DeliberateAny[] = [];

    if (this.props.inspectionForm) {
      if (this.props.inspectionForm.Groups) {
        this.props.inspectionForm.Groups.forEach((room) => {
          room.Elements.forEach((element) => {
            questions.push({ ...element.Response, Caption: element.Caption });
          });
        });
      }
    }

    const responses = questions
      .filter((x) => x && x.YesNo === false && x.Comment !== null)
      .map((response) => ({
        label: response.Caption,
        value: response.Comment,
      }));

    if (responses && responses.length > 0) {
      return (
        <div>
          <p>
            Du har meldt inn <strong className="red">{responses.length}</strong>{' '}
            feil og mangler.
          </p>
          <LabelValueList
            items={responses}
            style={{
              color: 'grey',
              border: true,
              padding: true,
            }}
          />
        </div>
      );
    }
    return <p>Du har ikke meldt noen feil og mangler.</p>;
  };

  amountOfNumberQuestions = () => {
    const numberTypeQuestions = this.props.room.Elements.filter(
      (question) => question.ElementType === QUESTION_TYPE_NUMBER,
    );

    return numberTypeQuestions.length || 0;
  };

  continueLaterPath = () => `fortsett-senere`;
  saveErrorPath = () => `lagring-feilet`;
  inspectionDonePath = () => `befaring-fullført`;

  inspectionCompletedModal = () => (
    <RouterModal
      size="medium"
      title="Innflyttingsbefaringen er sendt!"
      ariaLabel="Innflyttingsbefaring fullført"
      path={this.inspectionDonePath()}
      submit={{
        text: 'Lukk',
        onClick: () => this.props.navigate('/my-page/tab-my-rentals'),
        ignoreOnClose: true,
      }}
      onClose={() => this.props.navigate('/my-page/tab-my-rentals')}
      cancel={{ hide: true }}
    >
      {this.props.rentalContractTexts.InspectionCompletedText ? (
        <div>
          <div
            className="no-margin"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                this.props.rentalContractTexts.InspectionCompletedText,
              ),
            }}
          />
          <p>
            <Link to="/my-page/tab-my-rentals">
              Gå til Min side - mine leieforhold
            </Link>
          </p>
        </div>
      ) : (
        <div>
          <p>
            Velkommen, nå har du offisielt flyttet inn! Du kan når som helst
            melde inn feil og mangler via Min side.
          </p>
          <p>
            <Link to="/my-page/tab-my-rentals">
              Gå til Min side - mine leieforhold
            </Link>
          </p>
        </div>
      )}
    </RouterModal>
  );

  saveErrorModal = () => (
    <RouterModal
      size="medium"
      title="Feil ved lagring av befaring"
      ariaLabel="Feil ved lagring av befaring"
      path={this.saveErrorPath()}
      submit={{
        text: 'Prøv å lagre igjen',
        onClick: () => this.saveInspection(),
      }}
    >
      {this.props.saveInspectionLog.savingFailed &&
      this.props.saveInspectionLog.savingFailed ? (
        <div
          className="no-margin"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              this.props.saveInspectionLog.savingFailed,
            ),
          }}
        />
      ) : (
        <p>
          Det oppstod en feil ved siste lagring av befaring. Befaring lagres
          hver gang du går til neste side - så om du har vært gjennom flere
          sider er det kun siste side som ikke ble lagret. Du kan forsøke å
          lagre igjen eller komme tilbake og fullføre befaringen senere.
        </p>
      )}
      {this.props.saveInspectionLog.savingFailedCount > 1 && (
        <div>
          <p>
            Det virker som vi ikke får kontakt med tjenesten for befaring. Det
            kan være lurt å prøve litt senere eller
            <a href="/#/contact">kontakte Forsvarsbygg Servicesenter</a>.
          </p>
          <p>
            <Link to="/my-page/tab-my-rentals">
              Gå til Min side - mine leieforhold
            </Link>
          </p>
        </div>
      )}
    </RouterModal>
  );

  continueLaterModal = () => (
    <RouterModal
      size="medium"
      title="Fortsett senere"
      ariaLabel="Fortsett inspeksjon senere"
      path={this.continueLaterPath()}
      submit={{
        text: 'Fortsett senere',
        onClick: () => this.submitContinueLater(),
      }}
    >
      <p>
        Vi lagrer det du har gjort hittil i befaringen. Frist for å fullføre er
        7 dager etter innflytting.
      </p>
    </RouterModal>
  );

  groupToUse = () => {
    const numbs = this.props.inspectionForm.Groups.findIndex(
      (roomChoose) => roomChoose.Code === this.props.room.Code,
    );
    if (numbs < 0) {
      return 0;
    }
    return numbs;
  };

  render() {
    const formProps = {
      errors: this.props.errors,
      onSubmit: this.onSubmit,
      onChange: this.props.updateInspectionForm,
      updateAllValidationErrors: this.props.updateAllInspectionFormErrors,
      updateOneValidationError: this.props.updateSingleInspectionFormError,
      form: this.props.inspectionForm,
      validatorConfig: this.generateValidatorConfig(),
    };

    if (this.props.onlyModals) {
      return (
        <>
          {this.continueLaterModal()}
          {this.saveErrorModal()}
          {this.inspectionCompletedModal()}
        </>
      );
    }

    return (
      <FormWrapper {...formProps}>
        <h1>{this.props.room.Caption}</h1>

        {this.amountOfNumberQuestions() > 0 ? (
          <div
            className="no-margin"
            dangerouslySetInnerHTML={{
              __html: this.props.rentalContractTexts.InspectionRoomInfoText,
            }}
          ></div>
        ) : (
          <div className="margin">
            <Checkbox
              errors={this.props.errors}
              className="margin"
              type="checkbox"
              checked={this.state.checkAllYes}
              onChange={() => this.setAllYes()}
              name="setAllYes"
              label={`Alt ser greit ut her. Marker alle felter som godkjent - ${this.props.room.Caption}`}
            />
          </div>
        )}

        {this.props.room.Elements.map((question, ind) => {
          const formId = this.getFormId(ind);
          const responseToUse = {
            ...this.props.inspectionForm.Groups[this.groupToUse()].Elements[
              ind
            ],
          };

          const visualResponse = responseToUse
            ? SHOW_RESPONSE(responseToUse)
            : null;

          return (
            <div key={formId}>
              <div className="row margin">
                {question.ElementType === QUESTION_TYPE_NUMBER ? (
                  <NumberInput
                    name={formId}
                    value={
                      !isNaN(visualResponse as any)
                        ? parseInt(visualResponse as any, 10)
                        : 0
                    }
                    title={question.Caption}
                    minValue={0}
                    onChange={this.props.updateInspectionForm}
                    small={true}
                  />
                ) : (
                  <RadioButtonGroup
                    name={formId}
                    checkedOption={visualResponse || false}
                    legend={question.Caption}
                    options={[
                      { label: 'Ja', value: 'Ja' },
                      { label: 'Nei', value: 'Nei' },
                      { label: 'Ikke aktuelt', value: 'Ikke aktuelt' },
                    ]}
                    errors={this.props.errors}
                    onChange={this.onChangeValue}
                  />
                )}
              </div>
              {visualResponse === 'Nei' && (
                <div className="row margin">
                  <TextArea
                    name={`${formId}_comment`}
                    value={responseToUse.Response.Comment || ''}
                    title={`Kommentar: ${question.Caption}`}
                    maxLength={220}
                  />
                </div>
              )}
            </div>
          );
        })}

        {this.state.continueLaterSaved && (
          <p>
            <em>Befaring mellomlagret. </em>
            <Link to="/my-page/tab-my-rentals">
              Gå til Min side - mine leieforhold
            </Link>
          </p>
        )}

        {!this.props.nextSlidePath && (
          <div className="margin">
            <h2>Oppsummering</h2>
            {this.reportedIssues()}

            <Checkbox
              className="margin"
              type="checkbox"
              checked={this.state.acceptToSend}
              onChange={() =>
                this.setState({ acceptToSend: !this.state.acceptToSend })
              }
              name="acceptSending"
              label={
                this.props.rentalContractTexts.InspectionCompletion ? (
                  <div
                    className="no-margin"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        this.props.rentalContractTexts.InspectionCompletion,
                      ),
                    }}
                  />
                ) : (
                  this.props.rentalContractTexts.InspectionCompletion ||
                  'Jeg bekrefter at info er korrekt'
                )
              }
            />
          </div>
        )}

        <ButtonRow>
          <ButtonRow.Right
            type="submit"
            color="red"
            disabled={!this.props.nextSlidePath && !this.state.acceptToSend}
          >
            {this.props.nextSlidePath ? 'Neste' : 'Fullfør'}
          </ButtonRow.Right>
        </ButtonRow>
      </FormWrapper>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    inspectionForm: state.inspection.inspectionForm,
    errors: state.inspection.errors,
    saveInspectionLog: state.inspection.saveInspectionLog,
    rentalContractTexts: state.myRentals.rentalContractTexts,
  }),
  {
    updateInspectionForm,
    updateInspectionRoom,
    updateAllInspectionFormErrors,
    updateSingleInspectionFormError,
    sendInspectionForm,
  },
)(UnconnectedInspectionPage);
