import * as I from 'components/BasenIcon';
import Button from 'components/buttons/Button/Button';
import PhoneInput from 'components/FormComponents/PhoneInput';
import TextInput from 'components/FormComponents/TextInput';
import React from 'react';
import { GuestInfo, RoomWithGuest } from 'types/bookingRequests';
import { DeliberateAny, SetState } from 'types/DelibrateAny';

interface GuestInfoFormProps {
  guests: GuestInfo[];
  roomIndex: number;
  errors: DeliberateAny;
  setItemRooms: SetState<RoomWithGuest[]>;
}

const GuestInfoForm: React.FC<GuestInfoFormProps> = ({
  guests,
  roomIndex,
  errors,
  setItemRooms,
}) => {
  const updateGuestInfo = (id, value, roomIndex, guestIndex) => {
    setItemRooms((prevItemRooms) => {
      const roomsWithRemovedGuest = [...prevItemRooms];
      if (roomsWithRemovedGuest[roomIndex].guests) {
        roomsWithRemovedGuest[roomIndex].guests[guestIndex][id] = value;
      }
      return roomsWithRemovedGuest;
    });
  };

  const handleRemoveGuestFromRoom = (roomIndex: number, guestIndex: number) => {
    setItemRooms((prevItemRooms) => {
      const roomsWithRemovedGuest = [...prevItemRooms];
      roomsWithRemovedGuest[roomIndex].guests = prevItemRooms[
        roomIndex
      ].guests?.filter((_, gi) => gi !== guestIndex);
      return roomsWithRemovedGuest;
    });
  };

  return (
    <div>
      {guests.map((guest, guestIndex) => {
        return (
          <div key={`guest-${guestIndex}`} className="guest-info">
            {guestIndex > 0 && (
              <div className="guest-list-button-group">
                <div className="guest-header">Gjest</div>
                <Button
                  icon={I.MinusIcon}
                  className="tertiary"
                  onClick={() =>
                    handleRemoveGuestFromRoom(roomIndex, guestIndex)
                  }
                >
                  Fjern gjest
                </Button>
              </div>
            )}
            <TextInput
              name="fullName"
              label="Fullt navn"
              initial={guest.fullName}
              formHolder={{ fullName: guest.fullName }}
              updateForm={(id, value) =>
                updateGuestInfo(id, value, roomIndex, guestIndex)
              }
              errors={{
                fullName:
                  errors[`rooms[${roomIndex}].guests[${guestIndex}].fullName`],
              }}
              required
            />
            <PhoneInput
              name="mobile"
              label="Mobilnummer"
              initial={guest.mobile}
              formHolder={{ mobile: guest.mobile }}
              updateForm={(id, value) =>
                updateGuestInfo(id, value, roomIndex, guestIndex)
              }
              errors={{
                mobile:
                  errors[`rooms[${roomIndex}].guests[${guestIndex}].mobile`],
              }}
              required
            />
            <TextInput
              name="additionalInfo"
              label="Spesielle behov/ønsker (valgfritt)"
              initial={guest.additionalInfo}
              formHolder={{ additionalInfo: guest.additionalInfo }}
              updateForm={(id, value) =>
                updateGuestInfo(id, value, roomIndex, guestIndex)
              }
              errors={{}}
            />
          </div>
        );
      })}
    </div>
  );
};

export default GuestInfoForm;
