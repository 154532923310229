import { uploadSignedAdditionalServicesDocument } from 'actions/signatureActions';
import ConfirmationModal from 'components/modals/ConfirmationModal/ConfirmationModal';
import {
  SIGNATURE_STATE_ERROR,
  SIGNATURE_STATE_REJECTION,
  SIGNATURE_STATE_SUCCESS,
} from 'constants/signatureConstants';
import {
  getParamFromURL,
  getStatusQueryToken,
} from 'helpers/difiRedirectUrlParser';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AdditionalServicesSignableDocuments from './AdditionalServicesSignableDocuments/AdditionalServicesSignableDocuments';
import MyAdditionalServiceOrders from './MyAdditionalServiceOrders/MyAdditionalServiceOrders';

function TabAdditionalServices() {
  const [showSigningSuccessModal, setShowSigningSuccessModal] = useState(false);
  const [showSigningRejectionModal, setShowSigningRejectionModal] =
    useState(false);
  const [showSigningErrorModal, setShowSigningErrorModal] = useState(false);
  const dispatch = useDispatch();

  const handleRedirectFromSigningService = (statusQueryToken) => {
    const signingState = getParamFromURL('signingState');
    const documentId = getParamFromURL('documentId');
    const revision = getParamFromURL('revision');

    switch (signingState) {
      case SIGNATURE_STATE_SUCCESS:
        setShowSigningSuccessModal(true);
        dispatch(
          uploadSignedAdditionalServicesDocument(
            documentId ?? '',
            revision ?? '',
            statusQueryToken,
          ),
        );
        break;

      case SIGNATURE_STATE_REJECTION:
        setShowSigningRejectionModal(true);
        break;

      case SIGNATURE_STATE_ERROR:
        setShowSigningErrorModal(true);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const statusQueryToken = getStatusQueryToken();
    if (statusQueryToken) {
      // If status query token has been added to url,
      // we have been redirected from Difi signing site.
      handleRedirectFromSigningService(statusQueryToken);
    }
  }, []);

  return (
    <div className="tab-panel max-width-large" style={{ display: 'block' }}>
      <ConfirmationModal
        isOpen={showSigningSuccessModal}
        onClose={() => setShowSigningSuccessModal(false)}
        title="Signering fullført"
        infoText="Ditt dokument relatert til serviceordren er signert."
      />
      <ConfirmationModal
        isOpen={showSigningRejectionModal}
        onClose={() => setShowSigningRejectionModal(false)}
        title="Signering avbrutt"
        infoText="Du har avbrutt signering og dokumentet er ikke blitt signert. Du kan starte signering på nytt."
      />
      <ConfirmationModal
        isOpen={showSigningErrorModal}
        onClose={() => setShowSigningErrorModal(false)}
        title="Signering feilet"
        infoText="Det skjedde en feil ved signering og dokumentet er ikke blitt signert. Du kan starte signering på nytt."
      />
      <AdditionalServicesSignableDocuments />
      <MyAdditionalServiceOrders />
    </div>
  );
}

export default TabAdditionalServices;
