import Banner from 'components/Banner/Banner';
import { useApplication } from 'contexts/index';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

const titles = {
  booking_terms: 'Vilkår for overnatting',
};

const Information = () => {
  const { heading, subHeading } = useApplication();
  const [localHeading, setLocalHeading] = useState('');
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const key = location.pathname.slice(1).replace(/\//g, '_');
    if (Object.keys(titles).includes(key)) {
      setLocalHeading(titles[key]);
    } else {
      setLocalHeading(heading);
    }
  }, [location, heading]);

  useEffect(() => {
    setFadeIn(true);
    const timeoutId = setTimeout(() => setFadeIn(false), 2000); // Must match the animation ++
    return () => clearTimeout(timeoutId);
  }, [localHeading]);

  return (
    <>
      <Banner>
        <h2 className={`${fadeIn && localHeading ? 'fade-in' : ''}`}>
          {localHeading}
        </h2>
        <p className={`${fadeIn && subHeading ? 'fade-in' : ''}`}>
          {subHeading}
        </p>
      </Banner>
      <div className="fb-main">
        <Outlet />
      </div>
    </>
  );
};

export default Information;
