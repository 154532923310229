import { useLogin } from 'contexts/index';
import { FC } from 'react';

interface IBackButtonProp {
  close?: boolean;
}

const BackButton: FC<IBackButtonProp> = ({ close }) => {
  const { setPath, setShow } = useLogin();
  return (
    <a
      href="#"
      className="js-flip-back flip-back icon-arrow-left"
      onClick={() => {
        if (close) setShow(false);
        setPath(undefined);
      }}
    >
      <span style={{ fontFamily: 'sans-serif' }}> Tilbake</span>
    </a>
  );
};

export default BackButton;
