import 'react-image-gallery/styles/css/image-gallery.css';
import './ImageCarousel.scss';

import { Component } from "react";
import ReactImageGallery from "react-image-gallery";
import { DeliberateAny } from "types/DelibrateAny";

type PropsType = {
  images: Array<object>;
  fixedImageHeight?: boolean;
};

class ImageCarousel extends Component {
  declare props: PropsType;

  mapImages = () =>
    this.props.images.map((image: DeliberateAny) => ({
      original: image.Url,
      thumbnail: image.Url,
      originalAlt: image.Alt,
      description: image.Description,
    }));

  renderItem = (item: DeliberateAny) => (
    <div
      className={`${
        this.props.fixedImageHeight ? "styles.background-image" : ""
      }`}
      style={
        this.props.fixedImageHeight
          ? { backgroundImage: `url(${item.original})` }
          : { background: "" }
      }
    >
      <img
        src={item.original}
        alt={item.originalAlt}
        srcSet={item.srcSet}
        sizes={item.sizes}
        title={item.originalTitle}
      />
      {item.description && (
        <span className="image-gallery-description">{item.description}</span>
      )}
    </div>
  );

  render() {
    return (
      <ReactImageGallery
        renderItem={this.renderItem}
        items={this.mapImages()}
        showPlayButton={false}
        {...this.props}
      />
    );
  }
}

export default ImageCarousel;
