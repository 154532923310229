import './FaultForm.scss';

import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import Loader from 'components/Loader/Loader';
import Spinner from 'components/Spinner/Spinner';
import { useUser } from 'contexts/index';
import useRentalContracts from 'hooks/useRentalContracts';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeliberateAny } from 'types/DelibrateAny';

import FaultFormChoose from './FaultFormChoose';
import FaultFormRental from './FaultFormRental';
import QuestionForm from './QuestionForm';

enum FormType {
  Rental,
  Choose,
}

type RadioFormType = {
  label: string;
  value: FormType;
};

const FormTypeOptions: RadioFormType[] = [
  { label: 'Leieforhold', value: FormType.Rental },
  { label: 'Velg', value: FormType.Choose },
];

const FaultForm = () => {
  const { user, hasUser } = useUser();
  const { type } = useParams();
  const [formType, setFormType] = useState(FormTypeOptions[0]);
  const [loading, setLoading] = useState(false);

  const { rentalContracts, requestLoading, fetchRentalContracts } =
    useRentalContracts(
      user,
      false,
      (contracts) => {
        if (contracts.length > 0 && type === 'Leieforhold')
          setFormType(FormTypeOptions[0]);

        if (type !== 'Leieforhold') setFormType(FormTypeOptions[1]);
      },
      () => {
        setFormType(FormTypeOptions[1]);
      },
    );

  const [lastUpdate, setLastUpdate] = useState(new Date());

  const [formHolder, setFormHolder] = useState<DeliberateAny>({});

  const isRentalContractAvailable =
    rentalContracts && rentalContracts.length > 0;

  useEffect(() => {
    if (hasUser) fetchRentalContracts();
  }, [hasUser, fetchRentalContracts]);

  useEffect(() => {
    setLastUpdate(new Date());
  }, [formType]);

  if (type === 'Spørsmål') return <QuestionForm />;

  return (
    <Loader isLoading={requestLoading && !isRentalContractAvailable}>
      <div className="scrollable sticky-middle max-width clientsquare-report">
        <h2 className="unclassified-stamp">Ugradert</h2>
        <h1>Meld inn sak</h1>
        {requestLoading && !isRentalContractAvailable ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: 'fit-content',
              gap: 5,
              padding: 6,
            }}
          >
            <Spinner />{' '}
            <span
              style={{
                verticalAlign: 'middle',
              }}
            >
              {'Henter leieforhold'}
            </span>
          </div>
        ) : (
          rentalContracts &&
          rentalContracts.length > 0 && (
            <RadioButtonGroup
              row
              disabled={loading}
              name={'formType'}
              legend="Plassering"
              checkedOption={formType?.value}
              options={FormTypeOptions}
              onChange={(_, value) =>
                setFormType(
                  FormTypeOptions.find((x) => x.value === value) ??
                    FormTypeOptions[0],
                )
              }
            />
          )
        )}
        {formType?.value === FormType.Choose ? (
          <FaultFormChoose
            formHolder={formHolder}
            setFormHolder={setFormHolder}
            setLoading={setLoading}
          />
        ) : (
          <FaultFormRental
            lastUpdate={lastUpdate}
            contractsLoading={requestLoading}
            rentalContracts={rentalContracts ?? []}
            formHolder={formHolder}
            setFormHolder={setFormHolder}
            setLoading={setLoading}
          />
        )}
      </div>
    </Loader>
  );
};

export default FaultForm;
