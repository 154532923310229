import './L3Comment.scss';

import { uiAddUserCommentURL } from 'api/core/core_api';
import { useModal } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import { useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import TextArea from '../../components/form/TextArea/TextArea';
import { ModalTypes } from '../../constants/modalTypes';

const L3Comment = ({ item }: DeliberateAny) => {
  const { sendRequest } = useAxios();
  const { showModal } = useModal();
  const [comment, setComment] = useState(item.L3Extra?.Comment);
  const [lockField, setLockField] = useState(false);

  const handleAddComment = async () => {
    setLockField(true);
    try {
      await sendRequest({
        method: 'POST',
        url: uiAddUserCommentURL,
        withCredentials: true,
        data: {
          ItemId: item.Id,
          Comment: comment,
        },
      });
    } finally {
      setLockField(false);
    }
  };

  const handleShare = () => {
    showModal({
      modalType: ModalTypes.Share,
      title: 'Del paragraf',
      infoText:
        'Her kan du dele hele avsnittet og din kommentar med andre på e-post.',
      attr: {
        Comment: comment,
        ItemId: item.Id,
      },
    });
  };

  return (
    <div className="l3-comments form">
      <label>
        <p>
          <strong>Min kommentar</strong>
        </p>
        <TextArea
          disabled={lockField}
          value={comment}
          onChange={(name, value) => setComment(value)}
          name={'Min kommentar'}
        />
      </label>
      <div className="comment-actions">
        <button
          className="float-right btn red save-comment"
          onClick={handleAddComment}
        >
          Lagre
        </button>
        <button
          className="share-comment float-right btn grey"
          onClick={handleShare}
        >
          Del
        </button>
      </div>
    </div>
  );
};

export default L3Comment;
