import './BookingContactInfo.scss';

import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import Spinner from 'components/Spinner/Spinner';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';

interface IBookingTimerProps {
  reservationExpiryDate: Moment | undefined;
}

const BookingTimer: FC<IBookingTimerProps> = ({ reservationExpiryDate }) => {
  const getRemainingTime = () => {
    const now = moment();
    const end = reservationExpiryDate
      ? moment(reservationExpiryDate)
      : moment().add(-1, 'm');
    const duration = moment.duration(end.diff(now));

    return {
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  };

  const [timeRemaining, setTimeRemaining] = useState(getRemainingTime());

  useEffect(() => {
    if (!reservationExpiryDate) return;

    const interval = setInterval(() => {
      const remainingTime = getRemainingTime();
      if (remainingTime.minutes <= 0 && remainingTime.seconds <= 0) {
        clearInterval(interval);
      }
      setTimeRemaining(remainingTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [reservationExpiryDate]);

  // TODO: What happens when time expires?

  if (!reservationExpiryDate) {
    return;
  }

  if (timeRemaining.minutes <= 0 && timeRemaining.seconds <= 0) {
    return (
      <AlertMessage className="sticky-top" variant={AlertType.Warning}>
        <Spinner />
      </AlertMessage>
    );
  }

  return (
    <AlertMessage
      className="sticky-top"
      variant={AlertType.Warning}
    >{`Du har ${timeRemaining.minutes} minutter og ${timeRemaining.seconds} sekunder før reservasjon av rommene kanselleres`}</AlertMessage>
  );
};

export default BookingTimer;
