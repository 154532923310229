export const shortMonths = [
  'jan',
  'feb',
  'mars',
  'april',
  'mai',
  'juni',
  'juli',
  'aug',
  'sept',
  'okt',
  'nov',
  'des',
];
