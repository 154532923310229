import { useSlides } from "contexts/index";
import hoistStatics from "hoist-non-react-statics";
import React from "react";
import { connect as reduxConnect } from "react-redux";
import { NavigateFunction, Params, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { AnyAction } from "redux-saga";

export type ParamsCollection = Readonly<Params<string>>

export const withRouterUsingChildren = (
  Component: React.ComponentType<any>
) => {
  const WithRouterComponent = React.forwardRef(
    (props: any, ref: React.Ref<any>) => {
      const location = useLocation();
      const navigate: NavigateFunction = useNavigate();
      const params: ParamsCollection = useParams();

      const componentProps = {
        ...props,
        location,
        navigate,
        params,
      };

      return <Component ref={ref} {...componentProps} />;
    }
  );

  WithRouterComponent.displayName = `withRouter(${
    Component.displayName || Component.name
  })`;

  return hoistStatics(WithRouterComponent, Component);
};

export const withRouterUsingChildrenSlides = (
  Component: React.ComponentType<any>
) => {
  const WithRouterComponent = React.forwardRef(
    (props: any, ref: React.Ref<any>) => {
      const location = useLocation();
      const navigate = useNavigate();
      const params = useParams();
      const { goToSlide } = useSlides();

      const componentProps = {
        ...props,
        location,
        navigate,
        params,
        goToSlide,
      };

      return <Component ref={ref} {...componentProps} />;
    }
  );

  WithRouterComponent.displayName = `withRouter(${
    Component.displayName || Component.name
  })`;

  return hoistStatics(WithRouterComponent, Component);
};

export const connectSlides =
  <TStateProps, TDispatchProps, TOwnProps, TState>(
    mapStateToProps?: MapStateToPropsParam<TStateProps, TOwnProps, TState>,
    mapDispatchToProps?: MapDispatchToPropsParam<TDispatchProps, TOwnProps>
  ) =>
  (component: React.ComponentType<any>) =>
    withRouterUsingChildrenSlides(
      reduxConnect(mapStateToProps, mapDispatchToProps)(component)
    );

const connect =
  <TStateProps, TDispatchProps, TOwnProps, TState>(
    mapStateToProps?: MapStateToPropsParam<TStateProps, TOwnProps, TState>,
    mapDispatchToProps?: MapDispatchToPropsParam<TDispatchProps, TOwnProps>
  ) =>
  (component: React.ComponentType<any>) =>
    withRouterUsingChildren(
      reduxConnect(mapStateToProps, mapDispatchToProps)(component)
    );

export default connect;

type MapStateToPropsParam<TStateProps, TOwnProps, TState> =
  | ((state: TState, ownProps: TOwnProps) => TStateProps)
  | null
  | undefined;

type MapDispatchToPropsParam<TDispatchProps, TOwnProps> =
  | ((dispatch: Dispatch<AnyAction>, ownProps: TOwnProps) => TDispatchProps)
  | TDispatchProps;
