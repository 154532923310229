import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import DateSelector, { DatePickerMode } from 'components/FormComponents/DateSelector';
import React from 'react';
import { DateObject } from 'react-multi-date-picker';
import { Link } from 'react-router-dom';

interface DateRangeSelectorProps {
  arrivalDeparture: DateObject[];
  setArrivalDeparture: (dates: DateObject[]) => void;
  unavailableDates: Date[];
  errors: Record<string, any>;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  arrivalDeparture,
  setArrivalDeparture,
  unavailableDates,
  errors,
}) => (
  <div className="fb-form">
    <DateSelector
      placeHolder="Velg dato"
      mode={DatePickerMode.Range}
      name="arrivalDeparture"
      label="Ankomst - Avreise"
      formHolder={{ arrivalDeparture }}
      updateForm={(_, value) => setArrivalDeparture(value)}
      errors={errors}
      helpPanel={
        <AlertMessage variant={AlertType.Info}>
          <p>
            For å endre bestilling med under to dager til ankomst, vennligst
            kontakt <Link to="/"> Forsvarsbygg Servicesenter</Link>
          </p>
        </AlertMessage>
      }
      minDate={new Date().setDate(new Date().getDate() + 1)}
      unavailableDays={unavailableDates}
    />
  </div>
);

export default DateRangeSelector;
