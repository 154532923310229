import { useEffect, useRef } from 'react';

function useEffectLocked(callback, dependencies) {
  const hasRunRef = useRef(false);

  useEffect(() => {
    if (!hasRunRef.current) {
      callback();
      hasRunRef.current = true;

      // Reset after the effect has completed
      setTimeout(() => {
        hasRunRef.current = false;
      }, 0);
    }
  }, dependencies);
}

export default useEffectLocked;
