import 'react-international-phone/style.css';
import './Input.scss';

import * as I from 'components/BasenIcon';
import Button from 'components/buttons/Button/Button';
import HelpText from 'components/HelpText';
import { FC, useEffect, useState } from 'react';
import {
  CountrySelector,
  defaultCountries,
  ParsedCountry,
  usePhoneInput,
} from 'react-international-phone';

import { IFormPropBase } from './FormPropBase';

interface IInputProps extends IFormPropBase {
  initial?: string;
}

const PhoneInput: FC<IInputProps> = ({
  name,
  label,
  formHolder,
  updateForm,
  errors,
  helpInfo,
  disabled,
  required,
  initial = '+47',
  placeHolder,
}) => {
  const [phoneInputValue, setPhoneInputValue] = useState<string>('');

  const phoneInput = usePhoneInput({
    defaultCountry: 'no',
  });

  useEffect(() => {
    if (initial.startsWith('+')) {
      const matchingCountries = defaultCountries.filter((country) =>
        initial.replace('+', '').startsWith(country[2]),
      );
      if (matchingCountries.length) {
        phoneInput.setCountry(matchingCountries[0][1]);
        setPhoneInputValue(initial.replace(`+${matchingCountries[0][2]}`, ''));
      }
    }

    if (initial.startsWith(`+${phoneInput.country.dialCode}`)) {
      setPhoneInputValue(
        initial.replace(`+${phoneInput.country.dialCode}`, ''),
      );
    }
  }, [initial]);

  const handleOnChange = (e) => {
    let countryCode = phoneInput.country.dialCode;
    let inputValue = e.target.value;
    if (inputValue) {
      const stringValue = e.target.value as string;
      if (stringValue.length > 1 && stringValue.startsWith('+')) {
        const matchingCountries = defaultCountries.filter((country) =>
          stringValue.replace('+', '').startsWith(country[2]),
        );
        if (matchingCountries.length) {
          countryCode = matchingCountries[0][2];
          phoneInput.setCountry(matchingCountries[0][1]);
          inputValue = inputValue.replace(`+${matchingCountries[0][2]}`, '');
        }
      }
    }
    setPhoneInputValue(inputValue ? inputValue : '');
    updateForm(
      name,
      inputValue ? `+${countryCode}${inputValue}` : `+${countryCode}`,
    );
  };

  const handleCountryOnSelect = (country: ParsedCountry) => {
    phoneInput.setCountry(country.iso2);
    updateForm(name, `+${country.dialCode}${phoneInputValue}`);
  };

  const renderPhoneInput2 = () => (
    <div className="fb-form-field input-container">
      <HelpText content={helpInfo}>
        <label className={`fb-form-label ${errors[name] ? 'invalid' : ''}`}>
          {errors[name] ? errors[name] : label ? label : name}
        </label>
      </HelpText>
      <div className="form-phone-input">
        <CountrySelector
          selectedCountry={phoneInput.country.iso2}
          onSelect={handleCountryOnSelect}
          renderButtonWrapper={({ children, rootProps }) => (
            <div className="flex">
              <Button
                className="secondary country-code"
                {...rootProps}
                icon={I.DownIcon}
                iconRight
              >
                +{phoneInput.country.dialCode}
              </Button>
            </div>
          )}
        />
        <input
          name={name}
          type="tel"
          disabled={disabled}
          className={`input-field ${disabled ? 'disabled' : ''} ${errors[name] ? 'invalid' : ''}`}
          onChange={(e) => handleOnChange(e)}
          required={required}
          placeholder={placeHolder}
          value={phoneInputValue}
        />
      </div>
    </div>
  );

  return renderPhoneInput2();
};

export default PhoneInput;
