import env from 'env';

const { CORE: baseCore } = env;
const { CMS: cmsUrl } = env;

// Controllers
const audit = 'Audit';
const bring = 'BringApi';
const bronnoysund = 'Bronnoysund';
const userInteraction = 'UserInteraction';
const favorite = 'Favorite';
const tagging = 'Tagging';
const comment = 'Comment';
const userNotification = 'UserNotification';

// AuditLog
export const addAuditEntryURL = `${baseCore}/${audit}/AddEntry`;
export const getAuditEntriesURL = `${baseCore}/${audit}/GetEntriesByReference`;

// Bring
export const postalCodeLookup = `${baseCore}/${bring}/postalcodelookup`;
export const countyNameLookup = 'depricated'; // <- ikke lengre i bruk

// Brønnøysund
export const orgnrBRlookup = `${baseCore}/${bronnoysund}/GetOrganizationInfo`;
export const getOrganizationInfoURL = `${baseCore}/${bronnoysund}/GetOrganizationInfo`;

// FrontPage
export const frontpageSettingsUrl = `${cmsUrl}/CmsData/FrontPageSettings`;

// UserInteraction
export const uiAddFavoriteURL = `${cmsUrl}/${favorite}/AddFavorite`;
export const uiRemoveFavoriteURL = `${cmsUrl}/${favorite}/RemoveFavorite`;
export const uiAddUserTagURL = `${cmsUrl}/${tagging}/AddUserTag`;
export const uiRemoveUserTagURL = `${cmsUrl}/${tagging}/RemoveUserTag`;
export const uiAddUserCommentURL = `${cmsUrl}/${comment}/AddUserComment`;
export const uiRemoveUserCommentURL = `${cmsUrl}/${comment}/RemoveUserComment`;

export const uiFavoritesURL = `${baseCore}/${userInteraction}/Favorites`;
export const uiFavoritesOfTypeURL = `${baseCore}/${userInteraction}/FavoritesOfType`;
export const uiIsFavoriteURL = `${baseCore}/${userInteraction}/IsFavorite`;
export const uiAddOrUpdateUserCommentURL = `${baseCore}/${userInteraction}/AddOrUpdateUserComment`;
export const uiItemCommentsURL = `${baseCore}/${userInteraction}/ItemComments`;
export const uiItemCommentURL = `${baseCore}/${userInteraction}/ItemComment`;
export const uiItemTagsURL = `${baseCore}/${userInteraction}/ItemTags`;

export const uiRecentUserNotificationsURL = `${baseCore}/${userNotification}`;
export const uiCountUserNotificationsURL = `${baseCore}/${userNotification}/Count`;

export default baseCore;
