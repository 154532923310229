import "./GuestColumns.scss";

import renderIcon from "./GuestItem/GuestIcon";

const guestColumns = [
  { label: "Navn", name: "GuestName", className: "guard-guest-name" },
  {
    label: "Opphold fra",
    name: "FromDate",
    className: "guard-from-date",
  },
  { label: "Opphold til", name: "ToDate", className: "guard-to-date" },
  { label: "Telefon", name: "GuestPhone", className: "guard-phone" },
  {
    label: "Innsjekkstatus",
    name: "CheckInStatus",
    className: "guard-status",
  },
  { label: "Bygg-Rom", name: "Location", className: "guard-room" },
  {
    label: "Ikon",
    Name: "OriginIcon",
    className: "guard-icon",
    renderFunction: renderIcon,
  },
];

export default guestColumns;
