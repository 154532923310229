import './ChangeSearchModal.scss';

import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import Counter from 'components/Counter/Counter';
import ComboSelectBox from 'components/FormComponents/ComboSelectBox';
import DateSelector, { DatePickerMode } from 'components/FormComponents/DateSelector';
import { useNewBooking } from 'contexts/index';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import Yup from 'utils/yupExtensions';

import Modal, { ModalSize } from './Modal';

interface IChangeSearchModalPropsType {
  onClose: () => void;
  onSubmit: () => void;
}

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  location: Yup.string()
    .required('Du må velge en lokasjon.')
    .min(2, 'Lokasjon må være minst 2 tegn.'),
  guestCount: Yup.number()
    .required('Du må oppgi antall gjester.')
    .min(1, 'Antall gjester må være minst 1.'),
  arriveDeparture: Yup.array()
    .of(Yup.number())
    .required('Angi datoer')
    .dateRange(),
});

const fieldNames = {
  orderType: 'Bestillingstype',
  location: 'Søk etter by eller sted',
  guestCount: 'Antall gjester',
  arriveDeparture: 'Ankomst - Avreise',
};

const ChangeSearchModal: FC<IChangeSearchModalPropsType> = ({
  onClose,
  onSubmit,
}) => {
  const {
    arenas,
    bookingSearch,
    setBookingSearch,
    setRooms,
    setRoomCounts,
    reservationId,
    deleteReservation,
    unavailableDates,
  } = useNewBooking();

  // Local BookingSearch States
  const [guestCount, setGuestCount] = useState(bookingSearch.guestCount);
  const [localLocation, setLocalLocation] = useState(bookingSearch.location);
  const [arriveDeparture, setArriveDeparture] = useState(
    bookingSearch.arriveDeparture,
  );

  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateForm = async () => {
    try {
      await validationSchema.validate(
        { location: localLocation, guestCount, arriveDeparture },
        { abortEarly: false },
      );
      setErrors({});
      return true;
    } catch (validationErrors: any) {
      const newErrors: Record<string, string> = {};
      validationErrors.inner.forEach((error: any) => {
        if (error.path) newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!(await validateForm())) return;
    setRooms(undefined);
    setRoomCounts({});
    if (reservationId) await deleteReservation(reservationId);
    setBookingSearch({
      guestCount,
      arriveDeparture,
      orderType: bookingSearch.orderType,
      location: localLocation,
    });
    if (onSubmit) onSubmit();
  };

  const renderModalContent = () => {
    return (
      <div className="change-search-modal-content fb-form">
        <ComboSelectBox
          name="location"
          label={fieldNames.location}
          options={
            arenas?.map((arena) => {
              return { value: arena.locationId, label: arena.name };
            }) ?? []
          }
          required
          placeHolder="Søk etter..."
          formHolder={{ location: localLocation }}
          updateForm={(_, v) => setLocalLocation(v)}
          errors={errors}
          requiredChars={2}
        />

        <Counter
          name="guestCount"
          label={fieldNames.guestCount}
          formHolder={{ guestCount }}
          updateForm={(_, v) => setGuestCount(v)}
          errors={errors}
          min={1}
          defaultValue={1}
        />

        <DateSelector
          placeHolder="Velg dato"
          mode={DatePickerMode.Range}
          name={'arriveDeparture'}
          label={fieldNames.arriveDeparture}
          formHolder={{ arriveDeparture }}
          updateForm={(_, v) => setArriveDeparture(v)}
          errors={errors}
          helpPanel={
            <AlertMessage variant={AlertType.Info}>
              <p>
                For bestilling med under to dager til ankomst, vennligst kontakt{' '}
                <Link to={'/'}> Forsvarsbygg Servicesenter</Link>
              </p>
            </AlertMessage>
          }
          minDate={new Date()}
          unavailableDays={unavailableDates}
        />
      </div>
    );
  };

  return (
    <Modal
      header={'Endre søk'}
      content={renderModalContent()}
      onClose={onClose}
      showCancelButton={true}
      onSubmit={handleSubmit}
      overrideSubmitButtonText="Søk etter overnatting"
      size={ModalSize.Medium}
      className="change-search-modal"
    />
  );
};

export default ChangeSearchModal;
