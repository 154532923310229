// @flow

import connect from "helpers/connectWithRouter";
import { Component } from "react";
import { KeyValueType } from "types/commonTypes";
import { DeliberateAny } from "types/DelibrateAny";

import Select from '../Select/Select';

type PropsType = {
  name: string;
  onChange: (rejectionReason: string) => void;
  rejectionReasonsLoaded: boolean;
  rejectionReasons: Array<KeyValueType>;
};

export class UnconnectedSigningRejectionReasonSelect extends Component {
  declare props: PropsType;

  state: {
    selectedRejectionReasonKey: string;
  };

  constructor(props: PropsType) {
    super(props);
    this.state = { selectedRejectionReasonKey: "-1" };
  }

  handleSetRejectionReason = (name: string, val: string | number) => {
    const reasonKeyValue = this.props.rejectionReasons.find(
      (x) => x.key === val
    );
    const reasonKey = reasonKeyValue ? reasonKeyValue.key : "-1";
    this.setState({ selectedRejectionReasonKey: reasonKey });
    const rejectionReason = reasonKeyValue ? reasonKeyValue.value : "";
    this.props.onChange(rejectionReason);
  };

  render() {
    return (
      <Select
        loading={!this.props.rejectionReasonsLoaded}
        defaultChoice="Velg avslagsårsak"
        name={this.props.name}
        label="Velg årsak til avslaget"
        options={this.props.rejectionReasons}
        onChange={this.handleSetRejectionReason}
        value={this.state.selectedRejectionReasonKey}
      />
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    rejectionReasonsLoaded: state.myRentals.rejectionReasonsLoaded,
    rejectionReasons: state.myRentals.rejectionReasons,
  }),
  {}
)(UnconnectedSigningRejectionReasonSelect);
