import {
  getNotificationPreferencesURL,
  updateNotificationPreferencesURL,
} from 'api/cms/cms_api';
import CheckboxGroup from 'components/FormComponents/CheckboxGroup';
import { IFormPropBase } from 'components/FormComponents/FormPropBase';
import Tooltip from 'components/Tooltip/Tooltip';
import useAxios from 'hooks/useAxios';
import { FC, useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

interface ISmsNotificationCheckboxProps extends IFormPropBase {
  notificationDomain: string;
}

const SmsNotificationCheckbox: FC<ISmsNotificationCheckboxProps> = (props) => {
  const { sendRequest } = useAxios();
  const [currentNotification, setCurrentNotification] =
    useState<DeliberateAny>();
  const [notificationPreferences, setNotificationPreferences] = useState<
    DeliberateAny[]
  >([]);

  const fetchSettings = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: getNotificationPreferencesURL,
      },
      (r) => {
        if (r && r.length > 0) {
          setNotificationPreferences(r);
          const notification = r.find(
            (n) => n.NotificationDomain === props.notificationDomain,
          );
          if (notification) {
            setCurrentNotification(notification);
            props.updateForm(props.name, notification.Sms ? [props.name] : []);
          }
        }
      },
    );
  };

  const updateSettings = async () => {
    const currentSetting = notificationPreferences.find(
      (n) => n.NotificationDomain === props.notificationDomain,
    );
    if (!currentSetting) return;
    const newSetting = {
      ...currentSetting,
      Sms: props.formHolder[props.name].includes[props.name],
    };

    const newSettings = [
      ...notificationPreferences.filter(
        (n) => n.NotificationDomain !== props.notificationDomain,
      ),
      newSetting,
    ];

    if (
      newSettings.filter((n) => !n.PrimaryEmail && !n.SecondaryEmail && !n.Sms)
        .length > 0
    )
      return;

    setNotificationPreferences(newSettings);
    await sendRequest({
      method: 'PUT',
      url: updateNotificationPreferencesURL,
      data: newSettings,
    });
  };

  useEffect(() => {
    if (notificationPreferences && notificationPreferences.length > 0)
      updateSettings();
  }, [props.formHolder[props.name]]);

  const isDisabled = () => {
    return (
      currentNotification &&
      !currentNotification.PrimaryEmail &&
      !currentNotification.SecondaryEmail
    );
  };

  useEffect(() => {
    fetchSettings();
  }, [props.notificationDomain]);

  return (
    <div>
      <Tooltip
        message="For å kunne slå av varsling på SMS må du gå til min side og velge en epostadresse som varslingsmetode."
        disabled={!isDisabled()}
        position={'center'}
        help
      >
        <CheckboxGroup
          {...props}
          options={[
            {
              label:
                'Jeg ønsker å motta viktige beskjeder på SMS. Dette er spesielt viktig om du kun har 2-nivå epost.',
              value: props.name,
              disabled: isDisabled(),
            },
          ]}
          label={' '}
        />
      </Tooltip>
    </div>
  );
};

export default SmsNotificationCheckbox;
