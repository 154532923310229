import './SelectLocation.scss';

import {
    fetchAllocationRoundInfo, fetchApplicationsInArea, setHousingArea, setServiceArea,
    updateAllApplicationErrors, updateApplication, updateSingleApplicationError
} from 'actions/housingActions';
import { fetchMyRentals } from 'actions/myRentalsActions';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
import Select from 'components/form/Select/Select';
import Link from 'components/Link/Link';
import Loader from 'components/Loader/Loader';
import DOMPurify from 'dompurify';
import connect from 'helpers/connectWithRouter';
import { allocationRoundIsApplicable } from 'helpers/housingHelpers';
import activeImg from 'images/FB_bolig_aktivrunde.svg';
import inactiveImg from 'images/FB_bolig_inaktivrunde.svg';
import moment from 'moment';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { DeliberateAny } from 'types/DelibrateAny';
import { PropSlideNavigation } from 'types/PropNavigationBase';
import { getSiblingPath } from 'utils/helpers';
import * as yup from 'yup';

import type {
  HousingApplicationType,
  HousingAreaType,
  InfoTextType,
  QuarterApplicationType,
  ServiceAreaType,
  AllocationRoundType,
} from 'types/housingTypes';
import type { RentalsType } from 'types/myRentalsTypes';
type PropsType = PropSlideNavigation & {
  applicationType: string;
  roundInfo: AllocationRoundType;
  roundInfoLoading: boolean;
  infoText: InfoTextType;
  housingArea: HousingAreaType;
  serviceAreaName: string;
  serviceAreaInHousingArea: boolean;
  application: HousingApplicationType | QuarterApplicationType;
  housingAreas: Array<HousingAreaType>;
  serviceAreas: Array<ServiceAreaType>;
  setServiceArea: (
    applicationType: string,
    serviceArea: ServiceAreaType,
  ) => void;
  updateSingleApplicationError: () => void;
  updateAllApplicationErrors: () => void;
  updateApplication: (key: string, val: string) => void;
  errors?: object;
  fetchAllocationRoundInfo: (areaId: string) => void;
  setHousingArea: (name: string, value: string) => void;
  nextSlidePath: string;
  defaultTo: string;
  housingDataOptionsLoaded: boolean;
  fetchApplicationsInArea: (housingAreaId: string) => void;
  hasApplicationInArea: boolean;
  hasApplicationSentToAllocationInArea: boolean;
  defaultDocumentationDeadline: string;
  fetchMyRentals: (rentalId?: string) => void;
  rentals: RentalsType;
  housingApplicationsIsLoading: boolean;
  userData: DeliberateAny;
};

type AllocationRoundPropsType = {
  roundInfo: AllocationRoundType;
  housingArea: HousingAreaType;
  roundInfoLoading?: boolean;
};

const AllocationRoundInfo = (props: AllocationRoundPropsType) => (
  <div>
    {allocationRoundIsApplicable(props.roundInfo) ? (
      <div>
        <h3 className="no-margin">Aktiv fordelingsrunde</h3>
        <div className="round-details-wrapper">
          <div className="icon-round">
            <img src={activeImg} alt="" />
          </div>
          <div className="padding round-details-text">
            {moment().isAfter(moment(props.roundInfo.ApplicationDeadline)) && (
              <div>
                Søknadsfristen har gått ut, men du kan fortsatt søke frem til
                boligsekretær stenger fordelingsrunden. Husk at du må levere
                dokumentasjon umiddelbart, og innen fordelingsrunden stenges.
                <br />
              </div>
            )}
            <br />
            Ved å søke på {props.housingArea.name} blir du en del av
            fordelingsrunden som har frist{' '}
            {moment(props.roundInfo.ApplicationDeadline).format('DD.MM.YYYY')}
          </div>
        </div>
      </div>
    ) : (
      <div>
        <h3 className="no-margin">Ingen aktiv fordelingsrunde</h3>
        <div className="round-details-wrapper">
          <div className="icon-round">
            <img src={inactiveImg} alt="" />
          </div>
          <div className="padding round-details-text">
            {props.housingArea.name} har ingen aktive fordelingsrunder. Du kan
            likevel sende inn en åpen søknad som vil bli behandlet fortløpende.
          </div>
        </div>
      </div>
    )}
  </div>
);

class UnconnectedSelectLocation extends Component {
  declare props: PropsType;

  constructor(props: PropsType) {
    super(props);
    if (!this.props.housingDataOptionsLoaded) {
      this.props.goToSlide(
        getSiblingPath(this.props.location.pathname, this.props.defaultTo),
        true,
      );
    }
    this.props.updateApplication('applicantId', this.props.userData.Email);
    this.props.updateApplication(
      'documentationDeadline',
      this.props.defaultDocumentationDeadline,
    );
    this.props.fetchMyRentals();
    if (this.props.application.serviceAreaId) {
      this.handleSetServiceArea('', this.props.application.serviceAreaId);
    }
  }

  onSubmit = () => {
    this.props.goToSlide(
      getSiblingPath(this.props.location.pathname, this.props.nextSlidePath),
    );
  };

  getContractsForSelectedArea = () => {
    if (!this.props.housingArea) {
      return [];
    }
    const selectedHousingArea = this.props.housingArea.value;

    if (this.props.applicationType === 'quarter') {
      return this.props.rentals.items.filter(
        (rental) => rental.HousingArea === selectedHousingArea,
      );
    }
    return [];
  };

  hasCurrentContract = () => {
    if (this.props.applicationType === 'quarter') {
      return this.getContractsForSelectedArea().some((rental) => {
        const state =
          rental.RentalStatusNames[rental.CurrentRentalStatusNameIndex];
        return (
          state === 'Venter på innflytting' ||
          state === 'Aktivt leieforhold' ||
          state === 'Venter på utflytting' ||
          state === 'Forlenget'
        );
      });
    }
    return false;
  };

  currentContractWarning = () => (
    <p>
      <strong className="red">MERK!</strong> Du har allerede en kontrakt for{' '}
      {this.props.housingArea.name}. Husk å si opp nåværende leieforhold om ny
      søknad godkjennes.
    </p>
  );

  hasContractForSigning = () => {
    if (this.props.applicationType === 'quarter') {
      return this.getContractsForSelectedArea().some(
        (rental) =>
          rental.RentalStatusNames[rental.CurrentRentalStatusNameIndex] ===
          'Kontrakt til signering',
      );
    }
    return false;
  };

  hasContractWarning = () => (
    <p>
      <strong className="red">MERK!</strong> Du har en kontrakt til signering
      for {this.props.housingArea.name}. Takk nei til kontrakt før du kan søke
      på nytt.
    </p>
  );

  hasContractInXpandQueue = () => {
    const xpandContracts = this.getContractsForSelectedArea();
    return (
      xpandContracts.length === 0 &&
      this.props.hasApplicationSentToAllocationInArea
    );
  };

  handleSetHousingArea = (name, value) => {
    const housingAreaId = value.toString();
    this.props.setHousingArea(name, housingAreaId);

    this.props.fetchApplicationsInArea(housingAreaId);

    if (this.props.applicationType === 'housing') {
      this.props.fetchAllocationRoundInfo(housingAreaId);
    }
  };

  handleSetServiceArea = (
    selectName: string,
    serviceAreaId: string | number,
  ) => {
    const serviceArea = this.props.serviceAreas.find(
      (area) => area.key === serviceAreaId,
    );

    if (serviceArea) {
      this.props.setServiceArea(this.props.applicationType, serviceArea);
      this.handleSetHousingArea('housingAreaId', serviceArea.housingAreaId);
    }
  };

  alreadyAppliedWarning = () => (
    <p>
      <strong className="red">MERK!</strong> Du har allerede en søknad for{' '}
      {this.props.housingArea.name}. Gå til{' '}
      <Link to="/my-page/tab-my-housing-applications">
        Min side - mine søknader
      </Link>{' '}
      for å se på den.
    </p>
  );

  showRoundInfo = () => !this.props.application.rentalId;

  render() {
    const validatorConfig = yup
      .object()
      .shape({
        serviceAreaId: yup.string().required('required'),
        housingAreaId: yup.string().required('required'),
      })
      .defined();

    const formProps = {
      errors: this.props.errors,
      form: {
        ...this.props.application,
      },
      onSubmit: this.onSubmit,
      onChange: this.props.updateApplication,
      updateAllValidationErrors: this.props.updateAllApplicationErrors,
      updateOneValidationError: this.props.updateSingleApplicationError,
      schema: validatorConfig,
      applicantCategory: { required: true },
    };

    return (
      <div>
        <FormWrapper {...formProps} unclassifiedStamp>
          <h1>Velg tjenestested</h1>
          <p>{this.props.infoText.ServiceAreaNotInHousingAreaWarning}</p>
          <div className="select-wrapper">
            <div className="flex-row margin">
              <Select
                onChange={this.handleSetServiceArea}
                defaultChoice="Velg tjenestested"
                name="serviceAreaId"
                label="Velg tjenestested"
                options={this.props.serviceAreas}
                value={this.props.application.serviceAreaId}
                data-testid="select-servicelocation"
                errors={this.props.errors}
              />
              <Select
                onChange={this.handleSetHousingArea}
                options={this.props.housingAreas}
                defaultChoice="Velg boområde"
                name="housingAreaId"
                label="Velg boområde"
                value={
                  this.props.application.housingAreaId !== ''
                    ? this.props.application.housingAreaId
                    : null
                }
                data-testid="select-housingarea"
                errors={this.props.errors}
              />
            </div>

            <Loader
              isLoading={
                this.props.roundInfoLoading ||
                this.props.housingApplicationsIsLoading
              }
            >
              {this.props.housingArea && (
                <div>
                  {(this.props.hasApplicationInArea ||
                    this.hasContractInXpandQueue()) &&
                    this.alreadyAppliedWarning()}
                  {this.hasCurrentContract() && this.currentContractWarning()}
                  {this.hasContractForSigning() && this.hasContractWarning()}
                  <h3>{this.props.housingArea.name} boområde</h3>
                  {!this.props.serviceAreaInHousingArea &&
                    this.props.serviceAreaName && (
                      <span className="red">
                        {`MERK: ${this.props.serviceAreaName} tilhører
                      ikke ${this.props.housingArea.name} boområde, og du er derfor ikke prioritert.`}
                      </span>
                    )}

                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(this.props.housingArea.info),
                    }}
                  />

                  {this.showRoundInfo() && (
                    <div className="round-info">
                      {this.props.applicationType === 'housing' ? (
                        <AllocationRoundInfo
                          roundInfoLoading={this.props.roundInfoLoading}
                          roundInfo={this.props.roundInfo}
                          housingArea={this.props.housingArea}
                        />
                      ) : (
                        <div>
                          Ved å søke kvarter i {this.props.housingArea.name} vil
                          søknaden behandles fortløpende, men tildeling vil
                          avhenge av om det er ledig kapasitet. Vær derfor
                          oppmerksom på at det kan være at du må stå på
                          venteliste i påvente av at det blir noe ledig.
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Loader>
          </div>
          <div className="margin">
            <ButtonRow>
              <ButtonRow.Right
                disabled={
                  this.props.housingApplicationsIsLoading ||
                  this.props.hasApplicationInArea ||
                  this.hasContractForSigning() ||
                  this.hasContractInXpandQueue()
                }
                color="red"
                type="submit"
              >
                Neste
              </ButtonRow.Right>
            </ButtonRow>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

export default connect(
  (state: DeliberateAny, props: PropsType) => ({
    application: state.housing[`${props.applicationType}Application`],
    errors: state.housing.errors,
    housingAreas: state.housing.housingAreas,
    serviceAreas: state.housing.serviceAreas,
    serviceAreaName: state.housing.serviceAreaName,
    serviceAreaInHousingArea: state.housing.serviceAreaInHousingArea,
    housingArea: state.housing.housingArea,
    roundInfo: state.housing.roundInfo,
    roundInfoLoading: state.housing.roundInfoLoading,
    infoText: state.housing.housingInfoText,
    defaultDocumentationDeadline:
      state.options.housingDataOptions.defaultDocumentationDeadline,
    housingDataOptionsLoaded: state.options.housingDataOptionsLoaded,
    hasApplicationInArea: state.housing.hasApplicationInArea,
    hasApplicationSentToAllocationInArea:
      state.housing.hasApplicationSentToAllocationInArea,
    rentals: state.myRentals.rentals,
    housingApplicationsIsLoading: state.housing.isLoading,
  }),
  (dispatch, props) =>
    bindActionCreators(
      {
        updateApplication: (...args: [string, string | boolean]) =>
          updateApplication(props.applicationType, ...args),
        setHousingArea: (_, value) =>
          setHousingArea(props.applicationType, value),
        updateAllApplicationErrors: (...args: [DeliberateAny]) =>
          updateAllApplicationErrors(props.applicationType, ...args),
        updateSingleApplicationError: (...args: [string, DeliberateAny]) =>
          updateSingleApplicationError(props.applicationType, ...args),
        fetchApplicationsInArea: (...args: [string]) =>
          fetchApplicationsInArea(props.applicationType, ...args),
        fetchAllocationRoundInfo,
        setServiceArea,
        fetchMyRentals: (...args) => fetchMyRentals(...args),
      },
      dispatch,
    ),
)(UnconnectedSelectLocation);
