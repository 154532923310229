import './DateInput.scss';

import _ from 'lodash/fp';
import moment, { Moment } from 'moment/min/moment-with-locales';
import { ReactElement, useEffect, useRef, useState } from 'react';

import { NUM_SATURDAY, NUM_SUNDAY } from '../../constants/dateConstants';
import { DeliberateAny } from '../../types/DelibrateAny';
import Checkbox from '../form/Checkbox/Checkbox';
import DatePicker from './DatePicker';

type PropsType = {
  title?: string | ReactElement;
  text?: string | ReactElement;
  placement?: string;
  className?: string;
  name?: string;
  showYear?: boolean;
  optional?: DeliberateAny;
  minDate?: Moment;
  maxDate?: Moment;
  selectedDate?: Moment;
  startDate?: Moment;
  selectionSpan?: DeliberateAny;
  small?: boolean;
  full?: boolean;
  row?: boolean;
  format?: string;
  maxDurationInDays?: DeliberateAny;
  durationValidationMessage?: string | ReactElement;
  disabled?: boolean;
  blanketIgnore?: boolean;
  onSelectDate?: (moment: Moment) => void;
  onChange?: (string: string, moment: Moment) => void;
  disableWeekends?: boolean;
  errors?: DeliberateAny[];
};

const DateInput: React.FC<PropsType> = ({
  title,
  text,
  placement,
  className,
  name,
  showYear,
  optional,
  minDate,
  maxDate,
  selectedDate: initialSelectedDate,
  startDate,
  selectionSpan,
  small,
  full,
  row,
  format = 'YYYY-MM-DDT12:00:00',
  maxDurationInDays,
  durationValidationMessage,
  disabled,
  blanketIgnore,
  onSelectDate,
  onChange,
  disableWeekends,
}) => {
  const [pickerVisible, setPickerVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const buttonElement = useRef<HTMLButtonElement | null>(null);

  const togglePicker = (show: boolean) => {
    if (!disabled) {
      setPickerVisible(show);
      if (!show && buttonElement.current) {
        buttonElement.current.focus();
      }
    }
  };

  const handleSelectDate = (date: Moment, hidePicker: boolean = true) => {
    if (hidePicker) {
      togglePicker(false);
    }

    if (onSelectDate) {
      onSelectDate(date);
    }

    if (onChange && name) {
      onChange(name, date);
    }
  };

  const getMinDate = () => {
    const minAfterStartDate = startDate
      ? startDate.clone().add(1, 'days')
      : null;
    const minDates = [minDate, minAfterStartDate].filter(Boolean) as Moment[];
    return minDates.length > 0 ? moment.max(minDates) : null;
  };

  const getMaxDate = () => {
    let maxDateBySelectionSpan;
    if (minDate && selectionSpan) {
      maxDateBySelectionSpan = minDate
        .clone()
        .add(Number(selectionSpan) - 1, 'days');
    }

    let maxDateByDuration;
    if (startDate && maxDurationInDays) {
      maxDateByDuration = startDate
        .clone()
        .add(Number(maxDurationInDays) - 1, 'days');
    }

    const maxDates = [
      maxDate,
      maxDateBySelectionSpan,
      maxDateByDuration,
    ].filter(Boolean) as Moment[];
    return maxDates.length > 0 ? moment.min(maxDates) : null;
  };

  const getSelectedDateWithinMinAndMax = (originalDate: Moment) => {
    const min = getMinDate();
    const max = getMaxDate();

    if (min && moment(originalDate).isBefore(min)) {
      return min;
    }

    if (max && moment(originalDate).isAfter(max)) {
      return max;
    }

    return moment(originalDate);
  };

  const getNextWeekday = (date: Moment) => {
    if (date.day() === NUM_SATURDAY) {
      return date.clone().add(2, 'days');
    }

    if (date.day() === NUM_SUNDAY) {
      return date.clone().add(1, 'day');
    }

    return date;
  };

  useEffect(() => {
    if (initialSelectedDate) {
      let selectedDate = getSelectedDateWithinMinAndMax(initialSelectedDate);
      if (disableWeekends) {
        selectedDate = getNextWeekday(selectedDate);
      }

      if (!moment(selectedDate).isSame(initialSelectedDate, 'date')) {
        handleSelectDate(selectedDate, false);
      }
    }
  }, [
    initialSelectedDate,
    minDate,
    maxDate,
    startDate,
    selectionSpan,
    maxDurationInDays,
    disableWeekends,
  ]);

  const handleCheckOptional = (value: boolean) => {
    setChecked(value);
    togglePicker(false);
    handleSelectDate(value ? optional?.checkedDate : optional?.uncheckedDate);
  };

  const selectedDate = initialSelectedDate
    ? moment(initialSelectedDate).locale('nb')
    : null;
  const minDateCalculated = getMinDate();
  const maxDateCalculated = getMaxDate();

  return (
    <div
      role="presentation"
      className={className}
      onKeyDown={(e) =>
        _.includes(e.key, ['Enter', 'Escape']) && e.preventDefault()
      }
    >
      <div
        className={`form-date-picker ${small ? 'small' : ''} ${full ? 'full' : ''} ${row ? 'row' : ''} ${disabled ? 'disabled' : ''} ${pickerVisible ? 'active' : ''}`}
      >
        <label>
          {title}
          <button
            className="datebutton-contained"
            type="button"
            disabled={disabled || checked}
            ref={buttonElement}
            onClick={() => togglePicker(!pickerVisible)}
          >
            {selectedDate ? (
              <div style={{ visibility: checked ? 'hidden' : 'visible' }}>
                <div className="day">{selectedDate.format('dddd')}</div>
                <div className="date">
                  <strong>{selectedDate.date()}</strong>{' '}
                  <span>{selectedDate.format('MMM')}</span>{' '}
                  {showYear && <span>{selectedDate.year()}</span>}
                </div>
              </div>
            ) : (
              <div className="select-message">Trykk for å velge dato</div>
            )}
          </button>
        </label>
        {optional && (
          <Checkbox
            name={`${name}_NoDate`}
            label="Ikke oppgi"
            onChange={() => handleCheckOptional(!checked)}
            checked={checked}
          />
        )}
        <input
          type="hidden"
          name={name}
          disabled={disabled}
          value={selectedDate ? selectedDate.format(format) : ''}
          data-blanket-ignore={blanketIgnore}
        />
      </div>

      {pickerVisible && (
        <DatePicker
          title={title}
          text={text}
          placement={placement}
          minDate={minDateCalculated}
          maxDate={maxDateCalculated}
          initialDate={selectedDate || moment()}
          startDate={startDate}
          durationValidationMessage={durationValidationMessage}
          onSelectDate={handleSelectDate}
          onClose={() => togglePicker(false)}
          disableWeekends={disableWeekends}
        />
      )}
    </div>
  );
};

export default DateInput;
