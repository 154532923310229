import './BookingContactInfo.scss';

import { reservationUrl } from 'api/booking/booking_api_v2';
import BookingSearch from 'components/BookingComponents/BookingSearch';
import RoomList from 'components/BookingComponents/RoomList';
import { useNewBooking, useUser } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import useEffectLocked from 'hooks/useEffectLocked';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';
import { RoomFormItemType } from 'types/BookingTypes/roomTypes';
import { DeliberateAny } from 'types/DelibrateAny';

import { IBookingFormProps } from '../Booking';
import BookingErrors from './BookingErrors';
import BookingGuestListInfo from './BookingGuestListInfo';
import BookingTimer from './BookingTimer';

type PriceResponseType = {
  name: string;
  persons: number;
  priceId: number;
  priceValue: number;
  validFrom: Date;
  validTo: Date;
};

type ReservationResponseType = {
  reservationId: string;
  reservationExpiryDate: string;
  canPostponeGuestList: boolean;
  rooms: {
    roomTypeId: string;
    index: number;
    priceList: DeliberateAny[];
    heading: string;
    maxGuests: number;
  }[];
};

interface IBookingContactInfo extends IBookingFormProps {
  nextTriggered: boolean;
}

const BookingContactInfo: FC<IBookingContactInfo> = ({
  nav,
  nextTriggered,
}) => {
  const { user } = useUser();
  const { sendRequest } = useAxios();
  const {
    rooms,
    setRooms,
    bookingSearch,
    quoteId,
    roomInfo,
    roomCounts,
    reservationId,
    setReservationId,
  } = useNewBooking();

  const [reservationExpiryDate, setReservationExpiryDate] = useState<Moment>();
  const [guestsInitialized, setGuestsInitialized] = useState<boolean>(false);

  const getPrice = (priceList: PriceResponseType[], guestNumber: number) => {
    const priceItems = priceList.filter((priceItem) => {
      const dateFrom = new Date(priceItem.validFrom);
      const dateTo = new Date(priceItem.validTo);
      const dateCheckFrom = bookingSearch.arriveDeparture?.[0].toDate();
      const dateCheckTo = bookingSearch.arriveDeparture?.[1].toDate();

      if (dateCheckFrom && dateCheckTo) {
        if (dateCheckFrom > dateFrom && dateCheckTo < dateTo) {
          return priceItem;
        }
      }
    });
    const priceItem = priceItems.find((room) => room.persons === guestNumber);
    return priceItem?.priceValue
      ? priceItem.priceValue
      : priceItems[0].priceValue * guestNumber;
  };

  const postReservation = async () => {
    const arrDate = bookingSearch.arriveDeparture?.[0].toDate();
    const depDate = bookingSearch.arriveDeparture?.[1].toDate();

    // TODO: Should have type for the posted data?
    const mappedRooms: DeliberateAny[] = [];
    for (const roomTypeId in roomCounts) {
      const numRooms = roomCounts[roomTypeId];
      if (numRooms > 0) {
        mappedRooms.push({ roomTypeId: roomTypeId, numberOfRooms: numRooms });
      }
    }

    const roomsHaveChanged =
      rooms &&
      mappedRooms
        .map((item) => item.numberOfRooms)
        .reduce((partialSum, a) => partialSum + a, 0) !== rooms.length;

    if (!reservationId || roomsHaveChanged) {
      await sendRequest(
        {
          method: 'POST',
          url: reservationUrl,
          data: {
            quoteId: quoteId,
            locationId: bookingSearch.location,
            arrivalDate: arrDate ? moment(arrDate).format('YYYY-MM-DD') : '',
            departureDate: depDate ? moment(depDate).format('YYYY-MM-DD') : '',
            rooms: mappedRooms,
          },
        },
        (response) => {
          const responseItem = response as ReservationResponseType;
          setReservationId(responseItem.reservationId);
          setReservationExpiryDate(moment(responseItem.reservationExpiryDate));

          let guestCountdown = bookingSearch.guestCount ?? 1;

          const mappedResponse = responseItem.rooms
            .sort((itemA, itemB) => itemA.index - itemB.index)
            .map((room) => {
              const rInfo = roomInfo?.find(
                (roomInfoItem) =>
                  roomInfoItem.roomType.roomTypeId === room.roomTypeId,
              );

              const standardCapacity = roomInfo
                ? (rInfo?.roomType.standardCapacity ?? 1)
                : 1;

              // TODO: Do we even know the guest count in each room? - given num guests > num rooms
              const numberOfGuestsInRoom =
                guestCountdown > standardCapacity
                  ? standardCapacity
                  : guestCountdown;
              guestCountdown -= numberOfGuestsInRoom;
              const priceForAddingGuest =
                getPrice(room.priceList, numberOfGuestsInRoom + 1) -
                getPrice(room.priceList, numberOfGuestsInRoom); // The diff between current numberOfGuests and adding another guest to the room

              return {
                roomTypeId: room.roomTypeId,
                heading: room.heading,
                maxCapacity: room.maxGuests,
                guests: [],
                index: room.index,
                price: priceForAddingGuest,
              } as RoomFormItemType;
            });
          setRooms(mappedResponse);
        },
      );
    }
  };

  useEffectLocked(() => {
    if (
      quoteId &&
      rooms?.length !== Object.values(roomCounts).reduce((sum, n) => sum + n, 0)
    ) {
      postReservation();
    }
  }, [quoteId, rooms, roomCounts]);

  const initGuests = () => {
    // Order the rooms by standard capacity - decreasing
    if (rooms && roomInfo && bookingSearch) {
      const sortByStandardCapacity = [...rooms].sort((roomA, roomB) => {
        const roomAI = roomInfo.find(
          (r) => r.roomType.roomTypeId === roomA.roomTypeId,
        );
        const roomASize = roomAI?.roomType.standardCapacity ?? 0;

        const roomBI = roomInfo?.find(
          (r) => r.roomType.roomTypeId === roomB.roomTypeId,
        );
        const roomBSize = roomBI?.roomType.standardCapacity ?? 0;

        return roomBSize - roomASize; // Large to small
      });

      // Add guests
      // Shouldn't ever be possible to get here with maxCapacity = 6 and 8 guests f.ex
      let updateRemainingGuests = bookingSearch.guestCount;
      let curRoomIndex = 0;
      while (updateRemainingGuests > 0) {
        curRoomIndex = curRoomIndex % sortByStandardCapacity.length;
        const curRoom = sortByStandardCapacity[curRoomIndex];
        if (curRoom.guests.length < curRoom.maxCapacity) {
          const guest = {
            fullName: '',
            mobile: '',
            additionalInfo: '',
          };
          curRoom.guests.push(guest);
          updateRemainingGuests -= 1;
        }
        curRoomIndex += 1;
      }

      // Sort by index -> increasing
      const sortByIndex = sortByStandardCapacity.sort(
        (roomA, roomB) => roomA.index - roomB.index,
      );

      // Update main guest
      sortByIndex[0].guests[0].fullName = user.Name;
      sortByIndex[0].guests[0].mobile = user.CountryCode + user.Phone;

      setRooms(sortByIndex);
    }
  };

  useEffect(() => {
    if (rooms && !guestsInitialized) {
      initGuests();
      setGuestsInitialized(true);
    }
  }, [rooms, guestsInitialized]);

  return (
    <div className="fb-new fb-bci-container">
      <BookingSearch nav={nav} />
      <BookingErrors nextTriggered={nextTriggered} />
      <BookingTimer reservationExpiryDate={reservationExpiryDate} />

      <div className="guest-list-container">
        <BookingGuestListInfo />
        <div>
          <span className="guest-list-header">Fyll ut kontaktopplysninger</span>
          <RoomList />
        </div>
      </div>
    </div>
  );
};

export default BookingContactInfo;
