import React from "react";

import { HousingContextProvider } from "../../contexts/HousingContext";
import HousingSlides from "./HousingSlides";

export const housing = "bolig-";
export const quarter = "kvarter-";
export const housingTitle = "Søk bolig";
export const quarterTitle = "Søk kvarter";

export const paths = {
  selectApplicationType: "velg-type",
  applicantCategory: "målgruppe",
  serviceLocation: "tjenestested",
  personalInfo: "persondata",
  familySituation: "familiesituasjon",
  livingCondition: "bosituasjon",
  summary: "oppsummering",
};

const HousingSection = () => {
  return (
    <HousingContextProvider>
      <HousingSlides />
    </HousingContextProvider>
  );
};

export default HousingSection;
