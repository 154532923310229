// @flow

import { takeEvery, takeLatest } from "redux-saga/effects";
import * as guardPage from "sagas/guardPageSaga";
import * as HousingAdmin from "sagas/housingAdminSaga";
import * as Housing from "sagas/housingSaga";
import {
  handleFetchInspectionTypes,
  handleSendInspection,
} from "sagas/inspectionSaga";
import * as MyPortfolio from "sagas/myPortfolioSaga";
import * as myRentals from "sagas/myRentalsSaga";
import * as Options from "sagas/optionsSaga";
import handleTogglePlanFavorite from "sagas/plansSaga";
import * as Reporting from "sagas/reportingSaga";
import * as Reservation from "sagas/reservationSaga";
import * as ServiceOrder from "sagas/serviceOrderSaga";
import * as SignableDocuments from "sagas/signableDocumentsSaga";
import handleCreateSignatureJobForXpandDocument from "sagas/signatureSaga";

import { DeliberateAny } from "../types/DelibrateAny";

function* sagas(): Generator<DeliberateAny, DeliberateAny, DeliberateAny> {
  yield takeEvery(
    "FETCH_STATIC_FILTER_OPTIONS",
    Options.handleStaticFilterOptions
  );
  yield takeEvery("FETCH_BUILDING_TYPES", MyPortfolio.handleBuildingTypes);
  yield takeEvery(
    "FETCH_HOUSING_DATA_OPTIONS",
    Options.handleHousingDataOptions
  );
  yield takeEvery("FETCH_MY_DIFS", Options.handleMyDifs);
  yield takeEvery("FETCH_LOGGEDIN_USER", Options.handleLoggedInUser);
  yield takeEvery("FETCH_DROPZONE_OPTIONS", Options.handleDropzoneOptions);
  yield takeEvery("TOGGLE_PLAN_FAVORITE", handleTogglePlanFavorite);
  yield takeEvery(
    "FETCH_PORTFOLIO_FAVORITES",
    MyPortfolio.handlePortfolioFavorites
  );
  yield takeEvery(
    "ADD_PORTFOLIO_FAVORITE",
    MyPortfolio.handleAddPortfolioFavorite
  );
  yield takeEvery(
    "REMOVE_PORTFOLIO_FAVORITE",
    MyPortfolio.handleRemovePortfolioFavorite
  );
  yield takeEvery(
    "UPDATE_PORTFOLIO_FILTER",
    MyPortfolio.handleUpdatePortfolioFilter
  );
  yield takeEvery("FETCH_PORTFOLIO_SEARCH", MyPortfolio.handlePortfolioSearch);
  yield takeEvery(
    "FETCH_PORTFOLIO_RENTERS",
    MyPortfolio.handleFetchPortfolioRenters
  );
  yield takeEvery("FETCH_PORTFOLIO_PLANS", MyPortfolio.handlePortfolioPlans);
  yield takeEvery(
    "SEND_RESIGNATION_SERVICE_ORDER",
    MyPortfolio.handleSendResignationServiceOrder
  );
  yield takeEvery(
    "SEND_DECREASED_ACTIVITY_SERVICE_ORDER",
    MyPortfolio.handleSendDecreasedActivityServiceOrder
  );
  yield takeEvery("SEND_FAULT_SERVICE_ORDER", () =>
    console.log("SEND_FAULT_SERVICE_ORDER")
  );
  yield takeEvery("SEND_DOCUMENT_SERVICE_ORDER", () =>
    console.log("SEND_DOCUMENT_SERVICE_ORDER")
  );
  yield takeEvery("FETCH_HOUSING_INFO_TEXT", Housing.handleHousingInfoText);
  yield takeEvery(
    "FETCH_HOUSING_AND_SERVICE_AREAS",
    Housing.handleHousingAndServiceAreas
  );
  yield takeEvery(
    "FETCH_ALLOCATION_ROUND_INFO",
    Housing.handleAllocationRoundInfo
  );
  yield takeLatest("SET_POSTAL_NUMBER", Housing.setPostalCode);
  yield takeEvery("SEND_APPLICATION", Housing.handleSendApplication);
  yield takeEvery(
    "FETCH_APPLICATIONS_IN_AREA",
    Housing.fetchApplicationsInArea
  );
  yield takeEvery(
    "FETCH_HOUSING_CO_APPLICANT",
    Housing.fetchHousingCoApplicant
  );
  yield takeEvery(
    "FETCH_HOUSING_ADMIN_SERVICE_AREAS",
    HousingAdmin.handleHousingAdminServiceAreas
  );
  yield takeEvery(
    "TOGGLE_FAVORITE_HOUSING_ADMIN_AREA",
    HousingAdmin.handleToggleFavoriteHousingAdminArea
  );
  yield takeEvery(
    "FETCH_HOUSING_ROUND_DATA_OPTIONS",
    HousingAdmin.handleHousingRoundDataOptions
  );
  yield takeEvery(
    "FETCH_HOUSING_ADMIN_DATA_OPTIONS",
    HousingAdmin.handleHousingAdminDataOptions
  );
  yield takeEvery(
    "FETCH_QUARTER_UNPROCESSED_APPLICATIONS",
    HousingAdmin.handleQuarterUnprocessedApplications
  );
  yield takeEvery(
    "FETCH_AVAILABLE_QUARTERS",
    HousingAdmin.handleQuartersAvailable
  );
  yield takeEvery(
    "FETCH_VACANT_FROM_OFFSET_DAYS_QUARTER",
    HousingAdmin.handleVacantFromOffsetDaysQuarter
  );
  yield takeEvery(
    "FETCH_VACANT_FROM_OFFSET_DAYS_HOUSING",
    HousingAdmin.handleVacantFromOffsetDaysHousing
  );
  yield takeEvery(
    "FETCH_HOUSING_UNPROCESSED_APPLICATIONS_COUNT",
    HousingAdmin.handleHousingUnprocessedApplicationsCount
  );
  yield takeEvery(
    "FETCH_CURRENT_ALLOCATION_ROUNDS",
    HousingAdmin.handleHousingCurrentAllocationRounds
  );
  yield takeEvery(
    "FETCH_PREVIOUS_ALLOCATION_ROUNDS",
    HousingAdmin.handleHousingPreviousAllocationRounds
  );
  yield takeEvery(
    "ADD_ALLOCATION_ROUND",
    HousingAdmin.handleAddAllocationRounds
  );
  yield takeEvery("FETCH_HOUSING_TEXTS", HousingAdmin.handleHousingTexts);
  yield takeEvery(
    "FETCH_ALLOCATION_ROUND",
    HousingAdmin.handleHousingAllocations
  );
  yield takeEvery("FETCH_HOUSING_ROUND", HousingAdmin.handleHousingRound);
  yield takeEvery(
    "REGISTER_SINGLE_HOUSING_ALLOCATION",
    HousingAdmin.handleRegisterSingleHousingAllocation
  );
  yield takeEvery(
    "REGISTER_HOUSING_ALLOCATIONS",
    HousingAdmin.handleRegisterAllocations
  );
  yield takeEvery(
    "CLOSE_HOUSING_ALLOCATION_ROUND",
    HousingAdmin.handleCloseHousingAllocationRound
  );
  yield takeEvery(
    "FETCH_HOUSING_RENEWAL_UNPROCESSED_APPLICATIONS",
    HousingAdmin.handleHousingRenewalUnprocessedApplications
  );
  yield takeEvery(
    "FETCH_QUARTER_PROCESSED_APPLICATIONS",
    HousingAdmin.handleQuarterProcessedApplications
  );
  yield takeEvery(
    "FETCH_HOUSING_RENEWAL_PROCESSED_APPLICATIONS",
    HousingAdmin.handleHousingRenewalProcessedApplications
  );
  yield takeEvery(
    "COMMENT_HOUSINGRENEWAL_APPLICATION",
    HousingAdmin.handleHousingRenewalCommentForHousingCoordinator
  );
  yield takeEvery(
    "UPDATE_QUARTER_APPLICATIONS_PAGE",
    HousingAdmin.handleUpdateQuarterApplicationsPage
  );
  yield takeEvery(
    "UPDATE_HOUSING_APPLICATIONS_PAGE",
    HousingAdmin.handleUpdateHousingApplicationsPage
  );
  yield takeEvery(
    "UPDATE_HOUSING_RENEWAL_APPLICATIONS_PAGE",
    HousingAdmin.handleUpdateHousingRenewalApplicationsPage
  );
  yield takeEvery(
    "FETCH_QUARTER_APPLICATION_SUMMARY",
    HousingAdmin.handleQuarterApplicationSummary
  );
  yield takeEvery(
    "FETCH_HOUSING_ADMIN_APPLICATION_COUNTS",
    HousingAdmin.handleHousingAdminApplicationCounts
  );
  yield takeEvery(
    "REJECT_QUARTER_APPLICATION",
    HousingAdmin.handleRejectQuarterApplication
  );
  yield takeEvery(
    "REJECT_HOUSINGRENEWAL_APPLICATION",
    HousingAdmin.handleRejectHousingRenewalApplication
  );
  yield takeEvery(
    "FETCH_HOUSING_RENEWAL_APPLICATION_SUMMARY",
    HousingAdmin.handleHousingRenewalApplicationSummary
  );
  yield takeEvery(
    "APPROVE_HOUSINGRENEWAL_APPLICATION",
    HousingAdmin.handleApproveHousingRenewalApplication
  );
  yield takeEvery(
    "SEND_MESSAGE_TO_QUARTER_APPLICANT",
    HousingAdmin.handleSendMessageToQuarterApplicant
  );
  yield takeEvery(
    "SEND_COMMENT_TO_HOUSING_COORDINATOR",
    HousingAdmin.handleSendCommentToHousingCoordinator
  );
  yield takeEvery(
    "SEND_QUARTER_APPLICATIONS_TO_ALLOCATION",
    HousingAdmin.handleSendQuarterApplicationsToAllocation
  );
  yield takeEvery(
    "TOGGLE_QUARTER_APPLICATION_ASSIGNMENT",
    HousingAdmin.handleToggleQuarterApplicationAssignment
  );
  yield takeEvery(
    "TOGGLE_QUARTER_APPLICATION_APPROVED",
    HousingAdmin.handleToggleQuarterApplicationApproved
  );
  yield takeEvery(
    "FETCH_SIGNABLE_DOCUMENTS",
    SignableDocuments.handleSignableDocuments
  );
  yield takeEvery(
    "UPLOAD_SIGNED_RENTAL_PROTOCOL",
    SignableDocuments.handleUploadSignedRentalProtocol
  );
  yield takeEvery(
    "FETCH_SIGNED_DOCUMENT_FILE_NAME",
    SignableDocuments.handleGetDocumentFileName
  );
  yield takeEvery("FETCH_LOCATION_GUESTS", guardPage.handleLocationGuests);
  yield takeEvery("FETCH_CHECKIN_STATUS", guardPage.handleGuestStatusCheck);
  yield takeEvery("FETCH_GUESTS_SEARCH", guardPage.handleGuestsSearch);
  yield takeEvery("FETCH_GUEST_CHECKIN_RULES", guardPage.handleGuestInfotext);
  yield takeEvery(
    "FETCH_GUESTS_NEW_CHECKIN_STATUS",
    guardPage.handleNewStatusForGuest
  );
  yield takeEvery("FETCH_MY_RENTALS", myRentals.handleMyRentals);
  yield takeEvery(
    "FETCH_RENTAL_CONTRACT_TEXTS",
    myRentals.handleRentalContractTexts
  );
  yield takeEvery("REJECT_SIGNING", myRentals.handleRejectSigning);
  yield takeEvery(
    "FETCH_RENTAL_CONTRACT_REJECTION_REASONS",
    myRentals.handleRejectionReasons
  );
  yield takeEvery(
    "CREATE_SIGNATURE_JOB_XPAND_DOCUMENT",
    handleCreateSignatureJobForXpandDocument
  );
  yield takeEvery(
    "UPLOAD_SIGNED_RENTAL_CONTRACT_DOCUMENT",
    myRentals.handleUploadSignedRentalContractDocument
  );
  yield takeEvery("SEND_SERVICE_ORDER", ServiceOrder.handleSendServiceOrder);
  yield takeEvery(
    "SEARCH_RENTAL_SERVICE_ORDER",
    ServiceOrder.handleSearchRentalServiceOrder
  );
  yield takeEvery(
    "UPLOAD_SIGNED_ADDITIONAL_SERVICES_DOCUMENT",
    ServiceOrder.handleUploadSignedAdditionalServicesDocument
  );
  yield takeEvery("FETCH_RENTAL_DOCUMENT_LINKS", myRentals.handleDocumentLinks);
  yield takeEvery(
    "RESPOND_TO_PROPOSED_INSPECTION_TIME",
    myRentals.handleRespondToProposedInspectionTime
  );
  yield takeEvery("FETCH_INSPECTION_TYPES", handleFetchInspectionTypes);
  yield takeEvery("SEND_INSPECTION_FORM", handleSendInspection);
  yield takeEvery("FETCH_GUESTS_NEW_KEY", guardPage.handleGuestsNewKey);
  yield takeEvery("SEND_REPORTING", Reporting.handleSendReporting);
  yield takeEvery(
    "UPDATE_REPORTING_FILTER",
    Reporting.handleUpdateReportingFilter
  );

  // for å få tak i dropzone options: handleDropzoneOptions
  yield takeEvery(
    "FETCH_RESERVATION_FILTER",
    Reservation.fetchReservationSearch
  );
  yield takeEvery(
    "FETCH_RESERVATION_LOCATION",
    Reservation.fetchLocationReservation
  );
  yield takeEvery(
    "FETCH_RESERVATION_BOOKEEID",
    Reservation.fetchBookerProfileId
  );
  yield takeEvery("FETCH_AUDIT_ENDTRIES", Reservation.fetchAuditEntries);
}

export default sagas;
