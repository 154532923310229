import { DeliberateAny } from "../../../types/DelibrateAny";
import Checkbox from "../Checkbox/Checkbox";

type PropsType = {
  checked: boolean;
  onChange?: (name: string, value: boolean) => void;
  errors?: DeliberateAny;
};

const HasPetConfirmation = (props: PropsType) => {
  const description = (
    <p>
      <br />
      Hvis det er en hund i bo-enheten må dette varsles til drift.
    </p>
  );

  return (
    <div className="content-group grey padded">
      <Checkbox
        {...props}
        type="checkbox"
        name="haspetConfirmation"
        label="Det er en hund i bo-enheten."
        description={description}
      />
    </div>
  );
};
export default HasPetConfirmation;
