import './Top.scss';

import { ReactSVG } from 'react-svg';

import logo from '../assets/img/logo.svg';
import Menu from './Menu';
import Notifications from './Notifications';

const Top = () => {
  return (
    <header className="fb-top">
      <div className="fb-top-logo">
        <a href={'/'}>
          <ReactSVG src={logo} />
        </a>
      </div>
      <div className="fb-top-icons">
        <Menu />
      </div>
    </header>
  );
};

export default Top;
