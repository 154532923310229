// @flow

import { DeliberateAny } from "../types/DelibrateAny";
import type {
  SendServiceOrderType,
  SearchFilterType,
} from "../types/serviceOrderTypes";

export function sendServiceOrder(serviceOrder?: SendServiceOrderType) {
  return { type: "SEND_SERVICE_ORDER", serviceOrder };
}

export function updateServiceOrder(key: string, value: string) {
  return { type: "UPDATE_SERVICE_ORDER", key, value };
}

export function updateServiceOrderAllErrors(errors: DeliberateAny) {
  return { type: "UPDATE_SERVICE_ORDER_ALL_ERRORS", errors };
}

export function updateServiceOrderOneError(name: string, error: DeliberateAny) {
  return { type: "UPDATE_SERVICE_ORDER_ONE_ERROR", name, error };
}

export function toggleIsUnclassifiedServiceOrder() {
  return { type: "TOGGLE_IS_UNCLASSIFIED_SERVICE_ORDER" };
}

export function toggleConsentSubcontractorServiceOrder() {
  return { type: "TOGGLE_CONSENT_SUBCONTRACTOR_SERVICE_ORDER" };
}

export function toggleHasPetServiceOrder() {
  return { type: "TOGGLE_HAS_PET_SERVICE_ORDER" };
}

export function addFileServiceOrder(item: DeliberateAny) {
  return { type: "ADD_FILE_SERVICE_ORDER", item };
}

export function removeFileServiceOrder(item: DeliberateAny) {
  return { type: "REMOVE_FILE_SERVICE_ORDER", item };
}

export function updateFileDescriptionServiceOrder(
  fileName: string,
  title: string
) {
  return { type: "UPDATE_FILE_DESCRIPTION_SERVICE_ORDER", fileName, title };
}

export function clearServiceOrder() {
  return { type: "CLEAR_SERVICE_ORDER" };
}

export function closeServiceOrderSentConfirmation() {
  return { type: "CLOSE_SERVICE_ORDER_SENT_CONFIRMATION" };
}

export function searchRentalServiceOrder(
  rentalId: string,
  searchFilter: SearchFilterType
) {
  return { type: "SEARCH_RENTAL_SERVICE_ORDER", rentalId, ...searchFilter };
}
