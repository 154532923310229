import './MyBooking.scss';

import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import Button from 'components/buttons/Button/Button';
import { useMyPage } from 'contexts/index';
import { humanReadableDate } from 'helpers/date';
import { useState } from 'react';

import MyBookingChangePaymentModal from './MyBookingChangePaymentModal';

const MyBookingAlertMessage = () => {
  const { bookingItem } = useMyPage();
  const [showChangePaymentModal, setShowChangePaymentModal] =
    useState<boolean>(false);

  const isAlertError = () => {
    return bookingItem?.paymentInfo.statusInfo.status == AlertType.Error;
  };

  const getAlertMessage = (): JSX.Element => {
    if (isAlertError()) {
      return (
        <div>
          Betalingsopplysninger må registreres innen
          {bookingItem?.paymentInfo?.paymentDeadline ? (
            <strong>
              {' ' +
                humanReadableDate(
                  bookingItem?.paymentInfo?.paymentDeadline,
                  true,
                  true,
                ).toLowerCase()}
              .
            </strong>
          ) : (
            '.'
          )}
        </div>
      );
    } else {
      return (
        <span>
          Overnattingen kan avbestilles kostnadsfritt frem til{' '}
          <strong>
            {humanReadableDate(
              bookingItem?.cancellationDeadline,
              true,
            ).toLowerCase()}
            .
          </strong>
        </span>
      );
    }
  };

  return (
    <div className="fb-alert-container fb-full-width">
      {bookingItem && (
        <AlertMessage
          className="fb-full-width"
          variant={bookingItem.paymentInfo.statusInfo.status}
        >
          {getAlertMessage()}
        </AlertMessage>
      )}
      {isAlertError() && (
        <div>
          <Button
            className="primary"
            onClick={() => setShowChangePaymentModal(true)}
          >
            Registrer betalingsopplysninger
          </Button>
          <MyBookingChangePaymentModal
            showModal={showChangePaymentModal}
            setShowModal={setShowChangePaymentModal}
            title={'Registrer betalingsopplysninger'}
          />
        </div>
      )}
    </div>
  );
};

export default MyBookingAlertMessage;
