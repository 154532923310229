// appInsights.ts
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      'InstrumentationKey=ffcbfcc6-06b2-44a7-a913-f38859a06445;IngestionEndpoint=https://norwayeast-0.in.applicationinsights.azure.com/;LiveEndpoint=https://norwayeast.livediagnostics.monitor.azure.com/;ApplicationId=00e2b516-214c-42bc-a02e-33102f7c1f1b',
    enableAutoRouteTracking: true,
    // ...other configurations
  },
});

appInsights.loadAppInsights();
