import { endrePassordUrl, verifyUserActionKeyURL } from 'api/cms/cms_api';
import Button from 'components/buttons/Button/Button';
import Spinner from 'components/Spinner/Spinner';
import { useLogin } from 'contexts/index';
import { LoginPath } from 'contexts/LoginContext';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';

import BackButton from './BackButton';

const ACTIONKEY_INVALID_MSG =
  'Denne linken er ikke gyldig. Gå til "Logg inn" og "Glemt passord" for å få en ny epost med lenke for å endre passord.';

const SetNewPassword = () => {
  const { sendRequest, requestLoading, requestError } = useAxios();
  const { actionKey, setPath, setShow, setMsg } = useLogin();

  const [valid, setValid] = useState<boolean>();
  const [validated, setValidated] = useState<boolean>(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const validatePasswords = () => {
    const hasMinLength = password1.length >= 8;
    const hasUpperCase = /[A-ZÆØÅ]/.test(password1);
    const hasLowerCase = /[a-zæøå]/.test(password1);
    const hasNumber = /\d/.test(password1);
    const passwordsMatch = password1 === password2;

    if (
      hasMinLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      passwordsMatch
    ) {
      setValid(true);
      setErrorMsg('');
    } else {
      setValid(false);
      if (!hasMinLength) setErrorMsg('Passordet må ha minst 8 tegn.');
      else if (!hasUpperCase)
        setErrorMsg('Passordet må inneholde store bokstaver.');
      else if (!hasLowerCase)
        setErrorMsg('Passordet må inneholde små bokstaver.');
      else if (!hasNumber)
        setErrorMsg('Passordet må inneholde minst ett tall.');
      else if (!passwordsMatch) setErrorMsg('Passordene må være like.');
    }
  };

  useEffect(() => {
    validatePasswords();
  }, [password1, password2]);

  const handleClick = async () => {
    setValidated(true);
    if (valid) {
      await sendRequest(
        {
          url: endrePassordUrl + '?actionKey=' + actionKey,
          method: 'POST',
          data: {
            password: password1,
            passwordRepeat: password2,
          },
        },
        () => {
          setPath();
          setShow(false);
        },
      );
    }
  };

  const handleVerifyKey = async () => {
    if (!actionKey) {
      setMsg(ACTIONKEY_INVALID_MSG);
      setPath(LoginPath.login);
    } else {
      await sendRequest(
        {
          url: verifyUserActionKeyURL + '?actionKey=' + actionKey,
          method: 'GET',
        },
        (res) => {
          if (!res.valid) {
            setMsg(ACTIONKEY_INVALID_MSG);
            setPath(LoginPath.login);
          }
        },
        null,
        (error) => {
          setMsg(ACTIONKEY_INVALID_MSG);
          setPath(LoginPath.login);
        }
      );
    }
  };

  useEffect(() => {
    handleVerifyKey();
  }, []);

  return (
    <div data-flip-id="new-user" className="flip-card-front scrollable">
      <BackButton />
      <h2>Tilbakestill passord</h2>

      <div className="row margin">
        <label>
          <span>Velg passord</span>
          <input
            type="password"
            autoFocus
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
          />
        </label>
      </div>
      <div className="row margin">
        <label htmlFor="password">
          <span>Gjenta passord</span>
          <input
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
        </label>
      </div>

      {errorMsg && validated && <p className="invalid">{errorMsg}</p>}
      {requestError && (
        <p className="invalid">
          Det har oppstått en feil. Forsøk igjen senere.
        </p>
      )}

      <p className="margin">
        Passordet må ha minst 8 tegn. Passordet må bestå av både små og store
        bokstaver fra A-Å og minst et tall.
      </p>

      <Button
        style={{
          display: 'flex',
          height: 44,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={handleClick}
        color={!valid ? 'grey' : 'red'}
      >
        {requestLoading ? <Spinner white /> : 'Endre passord'}
      </Button>
    </div>
  );
};

export default SetNewPassword;
