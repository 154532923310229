import "./CogButton.scss";

export type PropsType = {
  onClick: () => void;
  size?: string;
  title?: string;
  placement?: string;
};

const CogButton = (props: PropsType) => (
  <button
    title={`${props.title ? props.title : "Innstillinger"}`}
    className={`\
      cog-button \
      ${props.size ? props.size : "medium"} \
      ${props.placement ? props.placement : ""}\
    `}
    onClick={props.onClick}
  />
);

export default CogButton;
