import _ from 'lodash/fp';
import moment from 'moment';

export default (state = [], action) => {
  switch (action.type) {
    case 'REMOVE_FIRST_ERROR':
      return state.slice(1);

    case (action.type.match(/^SAGA_ERROR/) || {}).input:
      return _.concat(state, {
        url: action.url,
        error: action.e,
        timeStamp: moment(),
        query: action.query ? action.query : undefined,
      });

    default:
      return state;
  }
};
