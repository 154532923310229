import './Banner.scss';

import { FC } from 'react';
import { IWrapper } from 'src/interfaces/IWrapper';

interface IBannerProps extends IWrapper {
  centerAlign?: boolean;
}

const Banner: FC<IBannerProps> = ({ children, centerAlign = false }) => {
  return (
    <div className="fb-banner">
      <div className="fb-main">
        <div className="html-content">
          <div className={centerAlign ? 'fb-banner-center' : 'fb-banner-left'}>
            <div className="readable-content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
