import logoHvit2 from 'images/logo_fb_hvit_2.svg';

export const MenuFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="footer-content">
        <img className="frontpage-logo" src={logoHvit2} alt="Forsvarsbygg" />
        <h5 className="half-margin">BASEN &copy; {currentYear}</h5>
        <p className="margin">Basen er en tjeneste levert av Forsvarsbygg</p>
        <p className="margin">
          <a href="http://www.forsvarsbygg.no">Gå til forsvarsbygg.no</a>
        </p>
        <p className="margin">
          <a href="#/privacy">Basen personvernerklæring</a>
        </p>
      </div>
    </footer>
  );
};
