import { call, put } from "redux-saga/effects";
import { createSignatureJobXpandDocURL } from "../api/basenproff/basenproff_api";
import { axiosFetch } from "../hooks/useAxios";

function* handleCreateSignatureJobForXpandDocument(action) {
  const url = createSignatureJobXpandDocURL;
  const query = action.jobRequest;

  try {
    // Redirect to Difi signing service
    window.location = yield call(axiosFetch, {
      url,
      data: query,
      method: "POST",
      withCredentials: true,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

export default handleCreateSignatureJobForXpandDocument;
