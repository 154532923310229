// @flow

import { call, put } from "redux-saga/effects";
import { fetchHousingAdminApplicationCounts } from "actions/housingAdminActions";

import * as housingApi from "../api/housing/housing_api";
import {
  unprocessedQuarterApplicationsURL,
  availableQuarterForAreaURL,
  processedQuarterApplicationsURL,
  getQuarterApplicationURL,
  rejectQuarterApplicationURL,
  sendQuarterApplicationToAllocationURL,
  commentForHousingCoordinatorQuarterURL,
  updateQuarterAssignmentURL,
  updateApprovedQuarterURL,
  addMessageQuarterURL,
} from "../api/housing/quarter_api";
import { uiAddFavoriteURL, uiRemoveFavoriteURL } from "../api/core/core_api";
import { axiosFetch } from "../hooks/useAxios";

function* handleHousingAdminServiceAreas() {
  const url = housingApi.housingServiceAreasURL;
  try {
    const data = yield call(axiosFetch, { url });
    yield put({ type: "RECEIVE_HOUSING_ADMIN_SERVICE_AREAS", data });

    if (data && data.HousingAreas) {
      const favoriteAreaIds = data.HousingAreas.filter(
        (area) => area.IsHousingFavorite || area.IsQuarterFavorite
      ).map((area) => area.Id);

      yield put(fetchHousingAdminApplicationCounts(favoriteAreaIds));
    }
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleToggleFavoriteHousingAdminArea(action) {
  const url = action.isFavorite ? uiRemoveFavoriteURL : uiAddFavoriteURL;
  const query = {
    itemId: action.areaId,
    referenceType: action.referenceType,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "RECEIVE_TOGGLE_FAVORITE_HOUSING_ADMIN_AREA",
      areaId: action.areaId,
      referenceType: action.referenceType,
      isFavorite: !action.isFavorite,
    });

    if (!action.isFavorite) {
      yield put(fetchHousingAdminApplicationCounts([action.areaId]));
    }
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleQuarterUnprocessedApplications(action) {
  const url = unprocessedQuarterApplicationsURL;
  const query = { HousingAreaId: action.housingAreaId };

  try {
    const unprocessedApplications = yield call(axiosFetch, {
      url,
      data: query,
    });
    yield put({
      type: "RECEIVE_QUARTER_UNPROCESSED_APPLICATIONS",
      unprocessedApplications,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleHousingUnprocessedApplicationsCount(action) {
  const url = housingApi.unprocessedApplicationsCountURL;
  const query = { housingAreaId: action.housingAreaId };

  try {
    const unprocessedApplicationsCount = yield call(axiosFetch, {
      url,
      data: query,
    });
    yield put({
      type: "RECEIVE_HOUSING_UNPROCESSED_APPLICATIONS_COUNT",
      unprocessedApplicationsCount,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleHousingCurrentAllocationRounds(action) {
  const url = housingApi.currentAllocationRoundsURL;
  const query = { areaId: action.housingAreaId };

  try {
    const currentAllocationRounds = yield call(axiosFetch, {
      url,
      data: query,
    });
    yield put({
      type: "RECEIVE_HOUSING_CURRENT_ALLOCATION_ROUNDS",
      currentAllocationRounds,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleHousingPreviousAllocationRounds(action) {
  const url = housingApi.previousAllocationRoundsURL;
  const query = { areaId: action.housingAreaId };

  try {
    const previousAllocationRounds = yield call(axiosFetch, {
      url,
      data: query,
    });
    yield put({
      type: "RECEIVE_HOUSING_PREVIOUS_ALLOCATION_ROUNDS",
      previousAllocationRounds,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleHousingRoundDataOptions() {
  const url = housingApi.boligrundeDataOptionsURL;

  try {
    const housingRoundDataOptions = yield call(axiosFetch, { url });
    yield put({
      type: "RECEIVE_HOUSING_ROUND_DATA_OPTIONS",
      housingRoundDataOptions,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleHousingAdminDataOptions() {
  const url = housingApi.housingAdminDataOptionsURL;

  try {
    const housingAdminDataOptions = yield call(axiosFetch, { url });
    yield put({
      type: "RECEIVE_HOUSING_ADMIN_DATA_OPTIONS",
      housingAdminDataOptions,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleAddAllocationRounds(action) {
  const url = housingApi.boligrundeAddRoundURL;
  const query = {
    HousingAreaId: action.housingAreaId,
    ApplicationDeadline: action.applicationDeadline,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "FETCH_CURRENT_ALLOCATION_ROUNDS",
      housingAreaId: action.housingAreaId,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleRegisterSingleHousingAllocation(action) {
  const url = housingApi.registerSingleHousingAllocationURL;
  const query = action.singleAllocationItem;

  try {
    // respons fra denne requesten inneholder informasjon som kan vises til bruker
    // Tildeling av bolig LEIEID til BRUKERNAVN er registrert.
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "FETCH_ALLOCATION_ROUND",
      allocationRoundId: action.singleAllocationItem.AllocationRoundId,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleRegisterAllocations(action) {
  const url = housingApi.registerHousingAllocationURL;
  const query = {
    housingAllocationListItems: action.housingAllocationListItems,
    allocationRoundId: action.allocationRoundId,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "RECEIVE_REGISTER_HOUSING_ALLOCATIONS",
      registerAllocations: action.housingAllocationListItems,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleCloseHousingAllocationRound(action) {
  const url = housingApi.boligrundeCloseRoundURL;
  const query = { allocationRoundId: action.roundId };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "FETCH_CURRENT_ALLOCATION_ROUNDS",
      housingAreaId: action.housingAreaId,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleHousingAllocations(action) {
  const url = housingApi.housingAllocationsURL;
  const query = { allocationRoundId: action.allocationRoundId };

  try {
    const housingAllocations = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_HOUSING_ALLOCATIONS", housingAllocations });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleHousingRound(action) {
  const url = housingApi.housingAdminHousinginRoundURL;
  const query = {
    allocationRoundId: action.allocationRoundId,
    vacantFrom: action.vacantFromDate,
  };

  try {
    const housingRound = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_HOUSING_ROUND", housingRound });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleHousingTexts() {
  const url = housingApi.getHousingTextsURL;

  try {
    const housingTexts = yield call(axiosFetch, { url });
    yield put({ type: "RECEIVE_HOUSING_TEXTS", housingTexts });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleQuartersAvailable(action) {
  const url = availableQuarterForAreaURL;
  const query = {
    HousingAreaId: action.housingAreaId,
    vacantFrom: action.vacantFrom,
  };

  try {
    const availableQuarters = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_AVAILABLE_QUARTERS", availableQuarters });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleVacantFromOffsetDaysQuarter() {
  const url = housingApi.vacantFromStandardOffsetDaysURL;

  try {
    const offsetDays = yield call(axiosFetch, { url });
    yield put({ type: "RECEIVE_VACANT_FROM_OFFSET_DAYS_QUARTER", offsetDays });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleVacantFromOffsetDaysHousing() {
  const url = housingApi.vacantFromStandardOffsetDaysURL;

  try {
    const offsetDays = yield call(axiosFetch, { url });
    yield put({ type: "RECEIVE_VACANT_FROM_OFFSET_DAYS_HOUSING", offsetDays });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url });
  }
}

function* handleHousingRenewalUnprocessedApplications(action) {
  const url = housingApi.unprocessedApplicationsURL;
  const query = { HousingAreaId: action.housingAreaId };

  try {
    const unprocessedApplications = yield call(axiosFetch, {
      url,
      data: query,
    });
    yield put({
      type: "RECEIVE_HOUSING_RENEWAL_UNPROCESSED_APPLICATIONS",
      unprocessedApplications,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleQuarterProcessedApplications(action) {
  const url = processedQuarterApplicationsURL;
  const query = { HousingAreaId: action.housingAreaId };

  try {
    const processedApplications = yield call(axiosFetch, { url, data: query });
    yield put({
      type: "RECEIVE_QUARTER_PROCESSED_APPLICATIONS",
      processedApplications,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleHousingRenewalProcessedApplications(action) {
  const url = housingApi.processedApplicationsURL;
  const query = { HousingAreaId: action.housingAreaId };
  try {
    const processedApplications = yield call(axiosFetch, { url, data: query });
    yield put({
      type: "RECEIVE_HOUSING_RENEWAL_PROCESSED_APPLICATIONS",
      processedApplications,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleHousingRenewalCommentForHousingCoordinator(action) {
  put("COMMENT_HOUSINGRENEWAL_APPLICATION");
  const url = housingApi.commentForHousingCoordinatorURL;
  const query = {
    housingRenewalApplicationId: action.applicationId,
    comment: action.comment,
  };
  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "UPDATE_RENEWAL_APPLICATIONS_PAGE",
      housingAreaId: action.areaId,
    });
    yield put({ type: "BOOK_COMMENT_SENT_SUCCESS" });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleRejectHousingRenewalApplication(action) {
  put("REJECT_HOUSINGRENEWAL_APPLICATION");
  const url = housingApi.rejectURL;
  const query = {
    housingRenewalApplicationId: action.applicationId,
    reason: action.reason,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "UPDATE_HOUSING_RENEWAL_APPLICATIONS_PAGE",
      housingAreaId: action.areaId,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleApproveHousingRenewalApplication(action) {
  put("APPROVE_HOUSINGRENEWAL_APPLICATION");
  const url = housingApi.approveURL;
  const query = {
    housingRenewalApplicationId: action.applicationId,
    reason: action.reason,
    approvedTo: action.approvedTo,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "UPDATE_HOUSING_RENEWAL_APPLICATIONS_PAGE",
      housingAreaId: action.areaId,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleUpdateHousingRenewalApplicationsPage(action) {
  yield put({
    type: "SET_HOUSING_RENEWAL_HOUSING_AREA",
    housingAreaId: action.housingAreaId,
  });
  yield put({
    type: "FETCH_HOUSING_RENEWAL_UNPROCESSED_APPLICATIONS",
    housingAreaId: action.housingAreaId,
  });
  yield put({
    type: "FETCH_HOUSING_RENEWAL_PROCESSED_APPLICATIONS",
    housingAreaId: action.housingAreaId,
  });
  yield put(fetchHousingAdminApplicationCounts([action.housingAreaId]));
}

function* handleUpdateQuarterApplicationsPage(action) {
  yield put({
    type: "SET_QUARTER_HOUSING_AREA",
    housingAreaId: action.housingAreaId,
  });
  yield put({
    type: "FETCH_QUARTER_UNPROCESSED_APPLICATIONS",
    housingAreaId: action.housingAreaId,
  });
  yield put({
    type: "FETCH_AVAILABLE_QUARTERS",
    housingAreaId: action.housingAreaId,
    vacantFrom: action.vacantFromDate,
  });
  yield put({
    type: "FETCH_VACANT_FROM_OFFSET_DAYS_QUARTER",
  });
  yield put({
    type: "FETCH_QUARTER_PROCESSED_APPLICATIONS",
    housingAreaId: action.housingAreaId,
  });
  yield put(fetchHousingAdminApplicationCounts([action.housingAreaId]));
}

function* handleUpdateHousingApplicationsPage(action) {
  yield put({ type: "SET_HOUSING_AREA", housingAreaId: action.housingAreaId });
  yield put({
    type: "FETCH_HOUSING_UNPROCESSED_APPLICATIONS_COUNT",
    housingAreaId: action.housingAreaId,
  });
  yield put({
    type: "FETCH_CURRENT_ALLOCATION_ROUNDS",
    housingAreaId: action.housingAreaId,
  });
  yield put({
    type: "FETCH_PREVIOUS_ALLOCATION_ROUNDS",
    housingAreaId: action.housingAreaId,
  });
  // yield put(fetchHousingAdminApplicationCounts([action.housingAreaId]));
}

function* handleQuarterApplicationSummary(action) {
  const url = getQuarterApplicationURL;
  const query = { quarterApplicationId: action.applicationId };
  try {
    const summary = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_QUARTER_APPLICATION_SUMMARY", summary });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleHousingRenewalApplicationSummary(action) {
  const url = housingApi.getApplicationURL;
  const query = { housingRenewalApplicationId: action.applicationId };
  try {
    const summary = yield call(axiosFetch, { url, data: query });
    yield put({ type: "RECEIVE_HOUSING_RENEWAL_APPLICATION_SUMMARY", summary });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleHousingAdminApplicationCounts(action) {
  const url = housingApi.boligomradeApplicationCountsURL;

  try {
    if (action.areaIds.length > 0) {
      const favoriteAreas = yield call(
        axiosFetch,
        { url, data: { areaIds: action.areaIds } },
        null,
        true,
        true,
        true
      );
      yield put({
        type: "RECEIVE_HOUSING_ADMIN_APPLICATION_COUNTS",
        favoriteAreas,
      });
    } else {
      yield put({ type: "DECLINE_APPLICATION_COUNTS_NO_IDS_PROVIDED" });
    }
  } catch (e) {
    yield put({
      type: "SAGA_ERROR",
      e,
      url,
      data: { areaIds: action.areaIds },
    });
  }
}

function* handleRejectQuarterApplication(action) {
  put("REJECT_QUARTER_APPLICATION");
  const url = rejectQuarterApplicationURL;
  const query = {
    quarterApplicationId: action.applicationId,
    reason: action.reason,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "UPDATE_QUARTER_APPLICATIONS_PAGE",
      housingAreaId: action.areaId,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleSendMessageToQuarterApplicant(action) {
  const url = addMessageQuarterURL;
  const query = {
    quarterApplicationId: action.applicationId,
    message: action.message,
  };

  try {
    const userMessages = yield call(axiosFetch, {
      url,
      data: query,
      method: "POST",
    });
    yield put({
      type: "RECEIVE_SEND_MESSAGE_TO_QUARTER_APPLICANT",
      applicationId: action.applicationId,
      userMessages,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleToggleQuarterApplicationAssignment(action) {
  const url = updateQuarterAssignmentURL;
  const query = {
    quarterApplicationId: action.applicationId,
    assignmentIsOK: !action.assignmentIsOK,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "RECEIVE_TOGGLE_QUARTER_APPLICATION_ASSIGNMENT",
      applicationId: action.applicationId,
      assignmentIsOK: query.assignmentIsOK,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleToggleQuarterApplicationApproved(action) {
  const url = updateApprovedQuarterURL;
  const query = {
    quarterApplicationId: action.applicationId,
    isApproved: !action.isApproved,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "RECEIVE_TOGGLE_QUARTER_APPLICATION_APPROVED",
      applicationId: action.applicationId,
      isApproved: query.isApproved,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleSendQuarterApplicationsToAllocation(action) {
  put("SEND_QUARTER_APPLICATIONS_TO_ALLOCATION");
  const url = sendQuarterApplicationToAllocationURL;
  const query = action.applicationIds;
  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "UPDATE_QUARTER_APPLICATIONS_PAGE",
      housingAreaId: action.areaId,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

function* handleSendCommentToHousingCoordinator(action) {
  const url = commentForHousingCoordinatorQuarterURL;
  const query = {
    quarterApplicationId: action.applicationId,
    comment: action.comment,
  };

  try {
    yield call(axiosFetch, { url, data: query, method: "POST" });
    yield put({
      type: "RECEIVE_SEND_COMMENT_TO_HOUSING_COORDINATOR",
      applicationId: action.applicationId,
    });
  } catch (e) {
    yield put({ type: "SAGA_ERROR", e, url, query });
  }
}

export {
  handleHousingAdminServiceAreas,
  handleToggleFavoriteHousingAdminArea,
  handleQuarterUnprocessedApplications,
  handleQuartersAvailable,
  handleHousingRenewalUnprocessedApplications,
  handleQuarterProcessedApplications,
  handleHousingRenewalProcessedApplications,
  handleQuarterApplicationSummary,
  handleHousingRenewalApplicationSummary,
  handleRejectQuarterApplication,
  handleRejectHousingRenewalApplication,
  handleHousingRenewalCommentForHousingCoordinator,
  handleApproveHousingRenewalApplication,
  handleSendMessageToQuarterApplicant,
  handleHousingAdminApplicationCounts,
  handleToggleQuarterApplicationAssignment,
  handleSendCommentToHousingCoordinator,
  handleToggleQuarterApplicationApproved,
  handleSendQuarterApplicationsToAllocation,
  handleUpdateQuarterApplicationsPage,
  handleUpdateHousingRenewalApplicationsPage,
  handleHousingUnprocessedApplicationsCount,
  handleHousingCurrentAllocationRounds,
  handleHousingPreviousAllocationRounds,
  handleUpdateHousingApplicationsPage,
  handleHousingRoundDataOptions,
  handleHousingAdminDataOptions,
  handleAddAllocationRounds,
  handleHousingTexts,
  handleHousingAllocations,
  handleRegisterSingleHousingAllocation,
  handleHousingRound,
  handleRegisterAllocations,
  handleCloseHousingAllocationRound,
  handleVacantFromOffsetDaysQuarter,
  handleVacantFromOffsetDaysHousing,
};
