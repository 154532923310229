// @flow

import { respondToProposedInspectionTime } from 'actions/myRentalsActions';
import DateInput from 'components/DateInput/DateInput';
import FormWrapper from 'components/form/FormWrapper/FormWrapper';
// import RouterModal from 'components/modals/RouterModal/';
import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import TextArea from 'components/form/TextArea/TextArea';
import RouterModal from 'components/modals/RouterModal/RouterModal';
import DOMPurify from 'dompurify';
import connect from 'helpers/connectWithRouter';
import { MOVING_INTO_QUARTER } from 'helpers/inspectionHelpers';
import { upperCaseFirstLetter } from 'helpers/valueHelpers';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import {
  DwellingInspectionSummaryType,
  RentalContractTextsType,
  RentalItemType,
} from 'types/myRentalsTypes';
import * as yup from 'yup';

type PropsType = {
  path: string;
  rentalItem: RentalItemType;
  rentalContractTexts: RentalContractTextsType;
  activeInspection: DwellingInspectionSummaryType;
  commentText: string;
  onClose: () => void;
  respondToProposedInspectionTime: (
    rentalObjectId: string,
    inspectionId: string,
    accept: boolean,
    comment: string | null,
    inspectionTime: string | null,
  ) => void;
};

class UnconnectedInspectionTimeProposalYesNoModal extends Component {
  props!: PropsType;
  form: DeliberateAny;
  state: {
    selectedAnswer: number | string | boolean;
    message: string;
    errors: DeliberateAny;
    selectedDate: Moment;
    initialInspectionDate: Moment;
  };

  constructor(props: PropsType) {
    super(props);
    // $FlowFixMe Flow does not know about createRef
    this.form = React.createRef();

    const inspectionDate = moment(props.activeInspection.InspectionTime);

    this.state = {
      selectedAnswer: '',
      message: '',
      errors: {},
      selectedDate: inspectionDate,
      initialInspectionDate: inspectionDate,
    };
  }

  respondToInspectionTimeProposal() {
    const selectedAnswer = this.state.selectedAnswer;
    if (selectedAnswer === false || selectedAnswer === true) {
      const message = selectedAnswer === false ? this.state.message : null;
      const date =
        selectedAnswer === false &&
        !this.state.selectedDate.isSame(this.state.initialInspectionDate, 'day')
          ? this.state.selectedDate.format()
          : null;

      this.props.respondToProposedInspectionTime(
        this.props.rentalItem.RentalId,
        this.props.activeInspection.InspectionId,
        selectedAnswer,
        message,
        date,
      );
      return true;
    }
    return false;
  }

  generateYesNoText(selectedAnswer, isMovingIntoQuarter) {
    if (selectedAnswer === true) {
      if (isMovingIntoQuarter) {
        return this.props.rentalContractTexts.TimeProposalYesMessageForQuarter;
      }
      return this.props.rentalContractTexts.TimeProposalYesMessage;
    } else if (selectedAnswer === false) {
      if (isMovingIntoQuarter) {
        return this.props.rentalContractTexts.TimeProposalNoMessageForQuarter;
      }
      return this.props.rentalContractTexts.TimeProposalNoMessage;
    }
    return '';
  }

  changeState(event) {
    this.setState({ message: event.target.value });
  }

  renderDateInput() {
    return (
      <DateInput
        selectedDate={this.state.selectedDate}
        title="Innflyttingsdato"
        minDate={moment(this.props.activeInspection.InspectionTime)}
        maxDate={moment(this.props.activeInspection.InspectionTime).add(
          30,
          'd',
        )}
        onSelectDate={(selectedDateFromInput) => {
          this.setState({ selectedDate: selectedDateFromInput });
        }}
        className="margin"
      />
    );
  }

  render() {
    const formProps = {
      errors: this.state.errors,
      form: {
        inspectionProposalYesNo: this.state.selectedAnswer,
      },
      onSubmitWithResult: () => this.respondToInspectionTimeProposal(),
      updateAllValidationErrors: (errors: object) => {
        this.setState({ errors });
      },
      updateOneValidationError: () => {},
      onChange: () => {},
      onSubmit: () => {},
      schema: yup
        .object()
        .shape({
          inspectionProposalYesNo: yup.string().required('required'),
        })
        .defined(),
    };

    const inspectionTime = moment(this.props.activeInspection.InspectionTime);
    const isMovingIntoQuarter = MOVING_INTO_QUARTER(
      this.props.rentalItem.ContractType,
      this.props.activeInspection.IsMovingIn,
    );

    return (
      <RouterModal
        title="Svar på tidspunkt"
        ariaLabel="Svar på tidspunkt"
        path={this.props.path}
        onClose={() => this.props.onClose()}
        submit={{
          text: 'Bekreft',
          onClick: () => this.form.current.onSubmitWithResult(),
          ignoreOnClose: true,
          closeModalAfterSubmitSuccess: true,
        }}
      >
        <FormWrapper {...formProps} ref={this.form}>
          <div className="margin">
            {isMovingIntoQuarter ? (
              <p>
                Registrert dato for innflytting er:
                <br />
                <strong>
                  {upperCaseFirstLetter(inspectionTime.format('dddd Do MMMM'))}
                </strong>
              </p>
            ) : (
              <p>
                Tidspunkt for befaring er:
                <br />
                <strong>
                  {upperCaseFirstLetter(
                    inspectionTime.format('dddd Do MMMM [kl] HH:mm'),
                  )}
                </strong>
              </p>
            )}
            <RadioButtonGroup
              legend={
                isMovingIntoQuarter
                  ? 'Stemmer tidspunktet?'
                  : 'Passer tidspunktet?'
              }
              name="inspectionProposalYesNo"
              onChange={(name, value) =>
                this.setState({ selectedAnswer: value })
              }
              options={[
                {
                  label: 'Ja',
                  value: true,
                },
                {
                  label: 'Nei',
                  value: false,
                },
              ]}
              checkedOption={this.state.selectedAnswer}
            />
            <p
              className="margin"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  this.generateYesNoText(
                    this.state.selectedAnswer,
                    isMovingIntoQuarter,
                  ),
                ),
              }}
            />
            {isMovingIntoQuarter &&
              this.state.selectedAnswer === false &&
              this.renderDateInput()}
            {this.state.selectedAnswer !== false ? null : (
              <TextArea
                rows={3}
                paragraph="Kommenter:"
                name="inspectionComment"
                value={this.state.message}
                onChange={(name, value) => {
                  this.setState({ message: value });
                }}
                maxLength={100}
              />
            )}
          </div>
        </FormWrapper>
      </RouterModal>
    );
  }
}

export default connect(
  (state: DeliberateAny) => ({
    rentalContractTexts: state.myRentals.rentalContractTexts,
  }),
  {
    respondToProposedInspectionTime,
  },
)(UnconnectedInspectionTimeProposalYesNoModal);
