import './List.scss';

import ClientPaging from 'components/paging/ClientPaging/ClientPaging';
import ServerPaging from 'components/paging/ServerPaging/ServerPaging';
import { mediaQueries, MediaQuery } from 'helpers/mediaQuery';
import { Component, ReactNode } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';
import {
  ClientPagingType,
  ControlledSortOptionsType,
  ListColumnType,
  ServerPagingType,
  UncontrolledSortOptionsType,
} from 'types/listTypes';

import ControlledColumnHeaders from './ControlledColumnHeaders';
import UncontrolledColumnHeaders from './UncontrolledColumnHeaders';

type PropsType = {
  items: Array<DeliberateAny>;
  columns: Array<ListColumnType>;
  renderItem: (item: DeliberateAny) => ReactNode | Array<ReactNode>;
  uncontrolledSortOptions?: UncontrolledSortOptionsType;
  controlledSortOptions?: ControlledSortOptionsType;
  clientPaging?: ClientPagingType;
  serverPaging?: ServerPagingType;
  noItemsMessage?: ReactNode | ReactNode[];
  extraListStyle?: string;
  alternateRows?: boolean;
  extraHeaderStyle?: string;
};

class List extends Component {
  declare props: PropsType;

  getClientPagedItems = (clientPaging: ClientPagingType) => {
    const currentNumItems = this.props.items.length;

    const startIndex = clientPaging.pageNumber * clientPaging.pageSize;
    const endIndex = (clientPaging.pageNumber + 1) * clientPaging.pageSize;

    if (startIndex < currentNumItems && endIndex <= currentNumItems) {
      return this.props.items.slice(startIndex, startIndex + 10);
    } else {
      const oppositePageIndex =
        clientPaging.pageCount - clientPaging.pageNumber - 1;
      const oppositeStartIndex =
        (Math.floor(currentNumItems / 10) - oppositePageIndex) *
        clientPaging.pageSize;

      return this.props.items.slice(
        oppositeStartIndex,
        oppositeStartIndex + 10,
      );
    }
    /*
    // $FlowFixMe: flow doesn't understand that this.props.clientPaging is already checked
    const begin =
      (this.props.clientPaging?.pageNumber ?? 0) *
      (this.props.clientPaging?.pageSize ?? 0);
    // $FlowFixMe: flow doesn't understand that this.props.clientPaging is already checked
    const end =
      ((this.props.clientPaging?.pageNumber ?? 0) + 1) *
      (this.props.clientPaging?.pageSize ?? 0);
    return this.props.items.slice(begin, end);
    */
  };

  renderItems = () =>
    (this.props.clientPaging
      ? this.getClientPagedItems(this.props.clientPaging)
      : this.props.items
    ).map(this.props.renderItem);

  render() {
    return (
      <div>
        <ul
          className={`list ${
            this.props.extraListStyle ? this.props.extraListStyle : ''
          } ${this.props.alternateRows ? 'alternate' : ''}`}
        >
          <MediaQuery query={mediaQueries.mediumUp}>
            <li
              key={'list-header'}
              className={`list-header ${
                this.props.extraHeaderStyle ? this.props.extraHeaderStyle : ''
              }`}
            >
              {this.props.controlledSortOptions ? (
                <ControlledColumnHeaders
                  columns={this.props.columns}
                  controlledSortOptions={this.props.controlledSortOptions}
                />
              ) : (
                <UncontrolledColumnHeaders {...this.props} />
              )}
            </li>
          </MediaQuery>
          {this.props.items.length > 0 && <>{this.renderItems()}</>}
        </ul>

        {this.props.items.length === 0 ? (
          <div className="no-items">
            <>{this.props.noItemsMessage}</>
          </div>
        ) : (
          <div>
            {this.props.clientPaging && (
              <ClientPaging {...this.props.clientPaging} />
            )}
            {this.props.serverPaging && (
              <ServerPaging {...this.props.serverPaging} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default List;
