import './Modal.scss';

import * as I from 'components/BasenIcon';
import Button from 'components/buttons/Button/Button';
import { FC, ReactNode } from 'react';

export enum ModalSize {
  Small = 'small-modal',
  Medium = 'medium-modal',
  Large = 'large-modal',
}

interface IModalPropsType {
  header: string;
  content: ReactNode | ReactNode[];
  onClose: () => void;
  onSubmit?: () => void;
  disableSubmit?: boolean;
  loadingSubmit?: boolean;
  overrideSubmitButtonText?: string;
  overrideCancelButtonText?: string;
  showCancelButton?: boolean;
  showCancelIcon?: boolean;
  size?: ModalSize;
  className?: string;
}

const Modal: FC<IModalPropsType> = ({
  header,
  content,
  onClose,
  onSubmit,
  overrideSubmitButtonText,
  overrideCancelButtonText,
  disableSubmit,
  loadingSubmit,
  showCancelIcon,
  showCancelButton,
  size = ModalSize.Medium,
  className = '',
}) => {
  const handleBackgroundClose = (event) => {
    if (event.currentTarget === event.target) {
      onClose();
    }
  };

  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit();
    } else onClose();
  };

  return (
    <div className="modal-window" onClick={(e) => handleBackgroundClose(e)}>
      <div className={`modal-container ${size} ${className}`}>
        {showCancelIcon && (
          <div className="close-button">
            <Button
              className="tertiary"
              icon={I.CloseIcon}
              onClick={() => onClose()}
            >
              {className == 'image-modal' && <span>Lukk</span>}
            </Button>
          </div>
        )}
        <div className="modal-header">
          <span style={{ fontSize: '32px' }}>{header}</span>
        </div>
        <div className="modal-content">{content}</div>
        <div className="modal-buttons">
          {onSubmit != undefined && (
            <Button
              loading={loadingSubmit}
              disabled={disableSubmit}
              className="primary"
              onClick={() => {
                if (!loadingSubmit) handleOnSubmit();
              }}
            >
              {overrideSubmitButtonText ? overrideSubmitButtonText : 'OK'}
            </Button>
          )}
          {showCancelButton && (
            <Button className="tertiary" onClick={() => onClose()}>
              {overrideCancelButtonText ? overrideCancelButtonText : 'Avbryt'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
