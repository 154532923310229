import './DatePicker.scss';

import _ from 'lodash/fp';
import moment, { Moment } from 'moment';
import { ReactElement, useEffect, useRef, useState } from 'react';

import { NUM_SATURDAY, NUM_SUNDAY } from '../../constants/dateConstants';
import { DeliberateAny } from '../../types/DelibrateAny';

type PropsType = {
  title?: string | ReactElement;
  text?: string | ReactElement;
  placement?: string;
  initialDate: Moment;
  minDate: Moment | null;
  maxDate: Moment | null;
  startDate?: Moment;
  durationValidationMessage?: string | ReactElement;
  onSelectDate?: (moment: Moment) => void;
  onClose?: () => void;
  disableWeekends?: boolean;
};

const DatePicker: React.FC<PropsType> = ({
  title,
  text,
  placement,
  initialDate,
  minDate,
  maxDate,
  startDate,
  durationValidationMessage,
  onSelectDate,
  onClose,
  disableWeekends,
}) => {
  // Set locale when initializing state
  const [highlightSelected, setHighlightSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Moment>(
    initialDate.clone().locale('nb'),
  ); // Ensure locale is set

  const mainElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mainElement.current) {
      mainElement.current.focus();
    }
  }, []);

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      _.includes(event.key, [
        'Enter',
        'Escape',
        'ArrowDown',
        'ArrowUp',
        'ArrowRight',
        'ArrowLeft',
      ])
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const deltas = {
      ArrowUp: -7,
      ArrowDown: 7,
      ArrowLeft: -1,
      ArrowRight: 1,
    } as DeliberateAny;

    if (event.key === 'Enter') {
      handleSetSelectedDate(selectedDate, { confirm: true });
    } else if (event.key === 'Escape') {
      if (onClose) onClose();
    } else if (event.key in deltas) {
      const newSelectedDate = selectedDate
        .clone()
        .add(deltas[event.key], 'days')
        .locale('nb'); // Preserve locale
      handleSetSelectedDate(newSelectedDate, { highlightSelected: true });
    } else {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const getClosestSelectableDate = (date: Moment) => {
    const closestMinDate = minDate ? minDate.clone().locale('nb') : null; // Ensure locale is set
    const closestMaxDate = maxDate ? maxDate.clone().locale('nb') : null; // Ensure locale is set

    if (closestMinDate && moment(date).isBefore(closestMinDate)) {
      return closestMinDate;
    }

    if (closestMaxDate && moment(date).isAfter(closestMaxDate)) {
      return closestMaxDate;
    }

    if (disableWeekends && moment(date).day() === NUM_SATURDAY) {
      return moment(date).clone().locale('nb').subtract(1, 'day'); // Ensure locale is set
    }

    if (disableWeekends && moment(date).day() === NUM_SUNDAY) {
      return moment(date).clone().locale('nb').subtract(2, 'day'); // Ensure locale is set
    }

    return date.clone().locale('nb'); // Ensure locale is set
  };

  const getDateInfoObjects = () => {
    const firstDayOfMonth = moment(selectedDate).startOf('month').locale('nb'); // Ensure locale is set
    const date = moment(firstDayOfMonth)
      .subtract(firstDayOfMonth.isoWeekday() - 1, 'days') // Adjust for Monday start
      .locale('nb');
    const todayDate = moment().locale('nb'); // Ensure locale is set
    const dates: DeliberateAny[] = [];

    for (let i = 0; i < 42; i += 1) {
      const outOfMonth = date.month() !== selectedDate.month();
      dates.push({
        id: i,
        date: date.clone(),
        today: date.isSame(todayDate, 'date'),
        selected: date.isSame(selectedDate, 'date'),
        active: date.isSame(initialDate, 'date'),
        start: startDate && startDate.isSame(date, 'date'),
        inactive:
          outOfMonth || !isDateSelectable(date) || checkDisableWeekend(date),
        outOfMonth,
      });
      date.add(1, 'day');
    }

    return dates;
  };

  const handleSetSelectedDate = (
    date: Moment,
    {
      confirm = false,
      highlightSelected = false,
    }: { confirm?: boolean; highlightSelected?: boolean },
  ) => {
    const newSelectedDate = getClosestSelectableDate(date);
    setSelectedDate(newSelectedDate);
    setHighlightSelected(highlightSelected);

    if (confirm && onSelectDate) {
      onSelectDate(newSelectedDate);
    }
  };

  const checkDisableWeekend = (date: Moment) => {
    return disableWeekends
      ? date.day() === NUM_SATURDAY || date.day() === NUM_SUNDAY
      : false;
  };

  const isDateSelectable = (date: Moment) => {
    return moment(getClosestSelectableDate(date)).isSame(date, 'date');
  };

  const checkMaxDuration = () => {
    const endOfCurrentMonth = selectedDate.clone().endOf('month').locale('nb'); // Ensure locale is set
    return maxDate && endOfCurrentMonth.isAfter(maxDate, 'date');
  };

  const changeMonth = (amount: number) => {
    const newSelectedDate = selectedDate
      .clone()
      .add(amount, 'month')
      .locale('nb'); // Ensure locale is set
    handleSetSelectedDate(newSelectedDate, { highlightSelected: false });
  };

  const changeYear = (amount: number) => {
    const newSelectedDate = selectedDate
      .clone()
      .add(amount, 'year')
      .locale('nb'); // Ensure locale is set
    handleSetSelectedDate(newSelectedDate, { highlightSelected: false });
  };

  return (
    <div
      role="dialog"
      className={`datepicker ${placement ? `picker-placement-${placement}` : ''}`}
    >
      <div
        tabIndex={-1}
        ref={mainElement}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      >
        <h4>{title}</h4>
        <button
          className="top-right box-close"
          title="Steng datovelger"
          onClick={onClose}
        />
        <nav>
          <div className="nav-month">
            <button title="Forrige måned" onClick={() => changeMonth(-1)}>
              <span className="icon-arrow-left-triangle" />
            </button>
            <span className="date-select">
              {selectedDate.format('MMMM')}{' '}
              {/* No need for .locale('nb') as it's already set */}
            </span>
            <button title="Neste måned" onClick={() => changeMonth(1)}>
              <span className="icon-arrow-right-triangle" />
            </button>
          </div>
          <div className="nav-year">
            <button title="Forrige år" onClick={() => changeYear(-1)}>
              <span className="icon-arrow-left-triangle" />
            </button>
            <span className="date-select">{selectedDate.year()}</span>
            <button title="Neste år" onClick={() => changeYear(1)}>
              <span className="icon-arrow-right-triangle" />
            </button>
          </div>
        </nav>
        <div className="date-boxes">
          <span className="th">ma</span>
          <span className="th">ti</span>
          <span className="th">on</span>
          <span className="th">to</span>
          <span className="th">fr</span>
          <span className="th">lø</span>
          <span className="th">sø</span>
          {getDateInfoObjects().map((dateInfo) => (
            <button
              key={dateInfo.id}
              title={dateInfo.date.format('D. MMMM Y')}
              className={`day \
                ${dateInfo.today ? 'today' : ''} \
                ${dateInfo.selected && highlightSelected ? 'selected' : ''} \
                ${dateInfo.start ? 'start' : ''} \
                ${dateInfo.active ? 'active' : ''} \
                ${dateInfo.inactive && !dateInfo.start ? 'inactive' : ''} \
                ${dateInfo.outOfMonth ? 'out-of-month' : ''} \
              `}
              disabled={dateInfo.inactive}
              onClick={() =>
                handleSetSelectedDate(dateInfo.date, { confirm: true })
              }
              onFocus={() =>
                handleSetSelectedDate(dateInfo.date, {
                  highlightSelected: true,
                })
              }
            >
              <span>{dateInfo.date.date()}</span>
            </button>
          ))}
        </div>
        {text && <p className="datepicker__text">{text}</p>}
        {durationValidationMessage && (
          <p
            className={`datepicker__text__toggleable-wrapper ${
              checkMaxDuration() ? 'visible' : ''
            }`}
          >
            <span className="datepicker__text__toggleable-wrapper__text">
              {durationValidationMessage}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default DatePicker;
