import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';

import { UserNotificationDto } from './SignalRContext';

class SignalRService {
  private hubConnection: HubConnection | null = null;

  startConnection = async (url: string, path: string, token: string) => {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url + path, {
        accessTokenFactory: () => {
          return `${token}`;
        },
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();

    try {
      await this.hubConnection.start();
    } catch (error) {
      console.error('Error while establishing SignalR connection:', error);
    }
  };

  lostConnection = () => {
    if (this.hubConnection) {
      this.hubConnection.onclose((error) => {
        if (error) {
          console.error('Error while connection was closing', error);
        }
      });
    }
  };

  stopConnection = async () => {
    if (this.hubConnection) {
      await this.hubConnection.stop();
    }
  };

  onReceiveCreatedNotification = (
    callback: (notification: UserNotificationDto) => void,
  ) => {
    if (this.hubConnection) {
      this.hubConnection.on(
        'NotificationCreated',
        (notification: UserNotificationDto) => {
          callback(notification);
        },
      );
    }
  };
}

const signalRService = new SignalRService();

export default signalRService;
