// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import type { ServerLinkType, ClientLinkType } from "types/commonTypes";

type PropsType = {
  title?: string;
  clientLinks?: Array<ClientLinkType>;
  serverLinks?: Array<ServerLinkType>;
  children?: React.ReactNode | React.ReactNode[];
};

const LinkList = (props: PropsType) => (
  <div>
    {props.title && <strong>{props.title}</strong>}

    {props.clientLinks &&
      props.clientLinks.map((link) => (
        <p key={link.to}>
          <Link to={link.to}>{link.name}</Link>
        </p>
      ))}

    {props.serverLinks &&
      props.serverLinks.map((link) => (
        <p key={link.Url}>
          <a href={link.Url} target={link.Target}>
            {link.LinkText}
          </a>
        </p>
      ))}

    {props.children}
  </div>
);

export default LinkList;
