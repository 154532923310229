import './TabButtons.scss';

import Button from 'components/buttons/Button/Button';
import { FC, useState } from 'react';

export type Tab = {
  id: string;
  label: string;
  disabled?: boolean;
};

interface ITabButtonProps {
  active?: string;
  tabs: Tab[];
  onChange?: (id: string) => void;
}

const TabButtons: FC<ITabButtonProps> = ({ tabs, active, onChange }) => {
  const [localActive, setLocalActive] = useState(active ?? tabs[0].id);

  const handleToggle = (id: string) => {
    if (onChange) onChange(id);
    else setLocalActive(id);
  };

  return (
    <div className="fb-tab-buttons">
      {tabs?.map((tab) => {
        const variant = active
          ? active === tab.id
            ? 'tab-toggled'
            : 'tab-default'
          : localActive === tab.id
            ? 'tab-toggled'
            : 'tab-default';
        return (
          <Button
            className={variant}
            disabled={tab.disabled}
            key={tab.id}
            onClick={() => handleToggle(tab.id)}
          >
            {tab.label}
          </Button>
        );
      })}
    </div>
  );
};

export default TabButtons;
