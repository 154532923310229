import { DeliberateAny } from 'types/DelibrateAny';

export const UpdateFormByName = (
  name: string,
  value: DeliberateAny,
  setValue: (value: DeliberateAny) => void,
) => {
  setValue((prevForm) => ({
    ...prevForm,
    [name]: value,
  }));
};
