import { ProfileIcon, RightIcon, UserIcon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import { FC } from 'react';

interface IMenuItemProps {
  icon?: string;
  iconBackground?: boolean;
  title?: string;
  sub?: string;
  onClick?: () => void;
}

const MenuItem: FC<IMenuItemProps> = ({
  icon,
  iconBackground,
  title,
  sub,
  onClick,
}) => {
  return (
    <div
      className={`fb-menu-item ${iconBackground ? 'icon-bg' : ''}`}
      onClick={onClick}
    >
      {icon && <BasenIcon icon={icon} size={'xl'} />}
      <div className="fb-menu-text">
        {title && <div className="fb-menu-title">{title}</div>}
        {sub && <div className="fb-menu-subtitle">{sub}</div>}
      </div>
    </div>
  );
};

export default MenuItem;
