import { updateSingleError } from "helpers/commonReducerHelpers";
import { CREATEOBJECTRESPONSE } from "helpers/inspectionHelpers";

const initialState = {
  inspectionForm: {},
  inspectionTypes: [],
  inspectionTypesLoaded: false,
  errors: {},
  saveInspectionSuccess: true,
  saveInspectionLog: { savingFailed: false, savingFailedCount: 0 },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_INSPECTION_TYPES":
      return {
        ...state,
        inspectionTypesLoaded: false,
      };

    case "RECEIVE_INSPECTION_TYPES":
      return {
        ...state,
        inspectionForm: action.inspectionTypes,
        inspectionTypes: action.inspectionTypes,
        inspectionTypesLoaded: true,
      };

    case "UPDATE_INSPECTION_FORM": {
      const key = action.key.replace("_comment", "");
      const inspectionPathArray = key.split(".");
      const groupNo = inspectionPathArray[0].match(/\d+/)[0];
      const elementNo = inspectionPathArray[1].match(/\d+/)[0];
      const roomChosen = {
        ...state.inspectionForm.Groups[groupNo],
      };
      const isComment = action.key.indexOf("_comment") !== -1;

      const objToChange = roomChosen.Elements[elementNo];
      const newObj = {
        ...objToChange,
        ...{ Response: CREATEOBJECTRESPONSE(action.value, isComment) },
      };

      roomChosen.Elements.splice(elementNo, 1, newObj);
      const rooms = [...state.inspectionForm.Groups];
      const roomInd = rooms.findIndex(
        (curRoom) => curRoom.Code === roomChosen.Code
      );
      rooms.splice(roomInd, 1, roomChosen);

      return {
        ...state,
        inspectionForm: { ...state.inspectionForm, ...{ Groups: rooms } },
      };
    }

    case "UPDATE_INSPECTION_ROOM":
      return {
        ...state,
        inspectionForm: {
          ...state.inspectionForm,
          ...{ Groups: action.roomQuestions },
        },
      };

    case "UPDATE_INSPECTION_FORM_ALL_ERRORS":
      return {
        ...state,
        errors: action.errors,
      };

    case "UPDATE_SINGLE_INSPECTION_FORM_ERROR":
      return {
        ...state,
        errors: updateSingleError({ ...state.errors }, action),
      };

    case "SAVED_INSPECTION FAILED":
      return {
        ...state,
        saveInspectionLog: {
          savingFailed: true,
          savingFailedCount: state.saveInspectionLog.savingFailedCount + 1,
        },
      };

    case "SAVED_INSPECTION SUCCESS":
      return {
        ...state,
        saveInspectionLog: { savingFailed: false, savingFailedCount: 0 },
      };

    default:
      return state;
  }
};
