import { googleMapsSettingURL } from 'api/cms/cms_api';
import classNames from 'classnames';
import Loader from 'components/Loader/Loader';
import { useUser } from 'contexts/index';
import { useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSessionStorage } from 'react-use';

import { LoadScript } from '@react-google-maps/api';

const BookingSlides = () => {
  const location = useLocation();
  const { requestLoading } = useUser();
  const nodeRef = useRef(null);

  const [apiKey] = useSessionStorage('gm', '');

  const bookingClass = classNames('app-root', {
    booking:
      location.pathname === '/booking/' || location.pathname === '/booking',
    slides: true,
    scrollable: true,
  });

  return (
    <div>
      <Loader isLoading={requestLoading}>
        <div className={bookingClass}>
          <div className="scrollable">
            <TransitionGroup className={`slide-wrapper scrollable`}>
              <CSSTransition
                timeout={500}
                classNames="slide-transition"
                nodeRef={nodeRef}
              >
                <Outlet />
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </Loader>
    </div>
  );
};

export default BookingSlides;
