import { DeliberateAny } from 'types/DelibrateAny';
import { setLocale } from 'yup';

const locales = {
  no: {
    mixed: {
      required: '${path} er påkrevd',
      notType: '${path} må være en ${type}',
    },
    string: {
      min: '${path} må være minst ${min} tegn',
      max: '${path} kan være maksimalt ${max} tegn',
      email: '${path} må være en gyldig e-postadresse',
      url: '${path} må være en gyldig URL',
      length: '${path} må være nøyaktig ${length} tegn',
    },
    number: {
      min: '${path} må være større enn eller lik ${min}',
      max: '${path} må være mindre enn eller lik ${max}',
      positive: '${path} må være et positivt tall',
      negative: '${path} må være et negativt tall',
      integer: '${path} må være et heltall',
    },
    date: {
      min: '${path} må være etter ${min}',
      max: '${path} må være før ${max}',
    },
    array: {
      min: '${path} må inneholde minst ${min} elementer',
      max: '${path} kan inneholde maksimalt ${max} elementer',
    },
  },
};

export const configureYupLocale = (locale = 'no') => {
  if (locales[locale]) {
    setLocale(locales[locale]);
  } else {
    console.warn(`Locale ${locale} is not defined. Falling back to default.`);
    setLocale(locales.no); // Default to Norwegian if locale is not found
  }
};

export const labelYupError = (error: DeliberateAny, context: DeliberateAny) => {
  const fieldName = context[error.path] || error.path;
  return error.message.replace(error.path, fieldName);
};
