import { Outlet } from "react-router-dom";

import HousingApplications from "./HousingApplications/HousingApplications";
import HousingAdminApproveAllocations from "./HousingApplications/HousingApplicationsSlides/HousingAdminApproveAllocations";
import HousingAdminCouncil from "./HousingApplications/HousingApplicationsSlides/HousingAdminCouncil";
import HousingAllocations from "./HousingApplications/HousingApplicationsSlides/HousingAllocations";
import HousingProperties from "./HousingApplications/HousingApplicationsSlides/HousingProperties";
import ProcessApplications from "./HousingApplications/HousingApplicationsSlides/ProcessApplications";
import HousingRenewalApplications from "./HousingRenewalApplications/HousingRenewalApplications";
import QuarterApplications from "./QuarterApplications/QuarterApplications";

type SlideInfo = {
  ApplicationsTitle: string;
  Path: string;
  Element: React.ComponentType<any>;
};
type SlideDictionary = {
  [key: string]: SlideInfo;
};

export const HousingSlides: SlideDictionary = {
  Quarter: {
    ApplicationsTitle: "Behandle kvartersøknader",
    Path: "kvartersøknader/:housingAreaId/*",
    Element: QuarterApplications,
  },
  Housing: {
    ApplicationsTitle: "Behandle boligsøknader",
    Path: "boligsøknader/:housingAreaId",
    Element: HousingApplications,
  },
  HousingRenewal: {
    ApplicationsTitle: "Bolig forlengelse",
    Path: "boligforlengelser/:housingAreaId",
    Element: HousingRenewalApplications,
  },
  HousingAllocations: {
    ApplicationsTitle: "Se tildelingsliste",
    Path: "tildelingsliste/:housingAreaId",
    Element: HousingAllocations,
  },
  HousingProperties: {
    ApplicationsTitle: "Se Boliger",
    Path: "boligliste/:housingAreaId",
    Element: HousingProperties,
  },
  ProcessApplications: {
    ApplicationsTitle: "Behandle Søknader",
    Path: "behandle/:housingAreaId",
    Element: ProcessApplications,
  },
  HousingAdminCouncil: {
    ApplicationsTitle: "Boligrådsmøte",
    Path: "boligråd/:housingAreaId",
    Element: HousingAdminCouncil,
  },
  HousingAdminApproveAllocations: {
    ApplicationsTitle: "Godkjenn tildelingsliste",
    Path: "godkjenn/:housingAreaId",
    Element: HousingAdminApproveAllocations,
  },
};

const HousingAdminSection = () => {
  return <Outlet />;
};

export default HousingAdminSection;
