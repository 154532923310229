import './MyBooking.scss';

import { bookingResendReceiptUrl } from 'api/booking/booking_api_v2';
import Button from 'components/buttons/Button/Button';
import ChangeBooking from 'components/ModalComponents/ChangeBooking/ChangeBooking';
import Spinner from 'components/Spinner/Spinner';
import { useMyPage, useUser } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import { Fragment, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import MyBookingCancelModal from './MyBookingCancelModal';
import MyBookingChangePaymentModal from './MyBookingChangePaymentModal';

const MyBookingActions = () => {
  const { user } = useUser();
  const { bookingid } = useParams();
  const { sendRequest, requestLoading } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const { bookingItem } = useMyPage();

  const [showChangeModal, setShowChangeModal] = useState(false);
  const [showChangePaymentModal, setShowChangePaymentModal] =
    useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const handleResendReceipt = async () => {
    if (!bookingid || !user) return;
    await sendRequest({
      method: 'POST',
      url: bookingResendReceiptUrl(bookingid, user?.Email),
    });
  };

  const renderButtons = () => {
    if (location.pathname.includes('receipt')) {
      return (
        <div className="fb-booking-summary-buttons">
          <Button onClick={handleResendReceipt} className="secondary">
            Send kvittering
          </Button>
          <Button
            onClick={() => navigate(`/mypage/booking/${bookingid}`)}
            className="primary"
          >
            Gå til min side
          </Button>
        </div>
      );
    } else {
      return (
        <div className="fb-booking-summary-buttons">
          <div className="button-spacing">
            <Button
              onClick={() => setShowCancelModal(true)}
              className="secondary"
            >
              Avbestill overnatting
            </Button>
            <Button
              onClick={() => setShowChangeModal(true)}
              className="secondary"
            >
              Endre bestilling
            </Button>
            <Button
              onClick={() => setShowChangePaymentModal(true)}
              className="secondary"
            >
              Endre betalingsmåte
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <Fragment>
      {requestLoading ? (
        <Spinner></Spinner>
      ) : (
        <div className="readable-content">{renderButtons()}</div>
      )}

      <MyBookingChangePaymentModal
        showModal={showChangePaymentModal}
        setShowModal={setShowChangePaymentModal}
      />

      <MyBookingCancelModal
        showModal={showCancelModal}
        setShowModal={setShowCancelModal}
      />

      {showChangeModal && bookingItem && (
        <ChangeBooking onClose={() => setShowChangeModal(false)} />
      )}
    </Fragment>
  );
};

export default MyBookingActions;
