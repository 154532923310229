import Slide from "components/Slides/Slide";

import FaultForm from "../FaultForm";

const FaultFormSlide = () => {
  return (
    <Slide path="faultform" slideSize="large" title="Beskriv saken">
      <FaultForm />
    </Slide>
  );
};

export default FaultFormSlide;
