import List from 'components/List/List';
import Loader from 'components/Loader/Loader';
import { useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import BookingColumns from './BookingColumns';
import BookingItem from './BookingItem';

const BookingList = ({
  bookings,
  isActive,
  setActiveBookings,
  bookingOptions,
  userData,
  noItemsMessage,
}: DeliberateAny) => {
  const [loading] = useState(false);
  const renderItem = (booking) => (
    <BookingItem
      key={booking.Id}
      item={booking}
      columns={BookingColumns}
      isActive={isActive}
      setActiveBookings={setActiveBookings}
      items={bookings}
      bookingOption={
        bookingOptions &&
        bookingOptions.find(
          (option) => option.LocationId === booking.LocationId,
        )
      }
      userData={userData}
    />
  );

  return (
    <Loader isLoading={loading}>
      <List
        columns={BookingColumns}
        renderItem={renderItem}
        items={bookings}
        noItemsMessage={noItemsMessage}
      />
    </Loader>
  );
};

export default BookingList;
