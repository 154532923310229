// @flow
import type { HousingAreaType } from "../types/housingAdminTypes";
import type { KeyValueType, LabelValueType } from "../types/commonTypes";
import moment from 'moment';
import { Params } from 'react-router-dom';

import { DeliberateAny } from '../types/DelibrateAny';
import FBHelpers from './_helpers';

export const HOUSING_ADMIN_APP_TYPE = "admin";
export const HOUSING_COORDINATOR_APP_TYPE = "coordinator";
export const HOUSING_REFERENCE_TYPE = "4";
export const QUARTER_REFERENCE_TYPE = "5";
export const REFERENCE_TYPES: DeliberateAny = {
  [HOUSING_REFERENCE_TYPE]: "Housing",
  [QUARTER_REFERENCE_TYPE]: "Quarter",
};

export const ROUND_OPEN = "åpen";
export const ROUND_CLOSED = "lukket";
export const ROUND_ALLOCATED = "tildelt";
export const ROUND_FINISHED = "avsluttet";

export const RENTAL_CONTRACT_TYPE_HOUSING = 0;

export const isFavoriteAreaProperty = (referenceType: string) =>
  `Is${REFERENCE_TYPES[referenceType]}Favorite`;

export const hideFromApplication = (referenceType: string) =>
  `HideFrom${REFERENCE_TYPES[referenceType]}Application`;

export const filterFavoriteAreasByType = (
  areas: HousingAreaType[],
  referenceType: string
) =>
  areas.filter(
    (area: DeliberateAny) => area[isFavoriteAreaProperty(referenceType)]
  );

export const filterApprovedApplications = (applications: DeliberateAny) =>
  applications.filter(
    (application: DeliberateAny) => application.status === "approved"
  );

export const filterRegisteredApplications = (applications: DeliberateAny) =>
  applications.filter(
    (application: DeliberateAny) => application.status === "registered"
  );

export type UpdateAreaIdType = {
  loadingHousingAreas: boolean;
  params: Params;
};

export const shouldUpdateAreaId = (
  curProps: UpdateAreaIdType,
  nextProps: UpdateAreaIdType
) => {
  const initialLoad =
    curProps.loadingHousingAreas &&
    !nextProps.loadingHousingAreas &&
    nextProps.params.housingAreaId;
  const housingAreaIdChanged =
    !nextProps.loadingHousingAreas &&
    nextProps.params.housingAreaId !== curProps.params.housingAreaId;

  return initialLoad || housingAreaIdChanged;
};

export const getNameFromId = (areas: DeliberateAny[], id: string) => {
  const area = areas.find((a) => a.Id === id);
  return area ? area.Name : "";
};

export const getValueFromKey = (
  keyValues: Array<KeyValueType>,
  key: string | number
) => {
  const option = keyValues.find((o) => o.key === key);
  return option ? option.value : "";
};

export const getLabelFromValue = (
  labelValues: Array<LabelValueType>,
  value: string | number
) => {
  const option = labelValues.find((o) => o.value === value);
  return option ? option.label : "";
};

export const isRegularAllocationRound = (round: DeliberateAny) =>
  !FBHelpers.Date.isDateTimeMinValue(round.Round.ApplicationDeadline);

const allocationAcceptanceDeadlineActive = (round: DeliberateAny) =>
  FBHelpers.Date.isDateTimeMinValue(round.Round.AllocationAcceptanceDeadline) ||
  moment().isBefore(moment(round.Round.AllocationAcceptanceDeadline));

export const regularAllocationRoundExists = (housingRounds: DeliberateAny) =>
  housingRounds.find(
    (round: DeliberateAny) =>
      isRegularAllocationRound(round) &&
      allocationAcceptanceDeadlineActive(round)
  );

export const isOpenAllocationRound = (round: DeliberateAny) =>
  FBHelpers.Date.isDateTimeMinValue(round.Round.ApplicationDeadline);

export const roundIsAllocated = (
  round: DeliberateAny,
  dataOptions: DeliberateAny
) =>
  dataOptions.AllocationRoundStateOptions[round.Round.State].Value ===
  ROUND_ALLOCATED;

export const roundIsOpen = (round: DeliberateAny, dataOptions: DeliberateAny) =>
  dataOptions.AllocationRoundStateOptions[round.Round.State].Value ===
  ROUND_OPEN;

export const roundIsClosed = (
  round: DeliberateAny,
  dataOptions: DeliberateAny
) =>
  dataOptions.AllocationRoundStateOptions[round.Round.State].Value ===
  ROUND_CLOSED;

export const roundIsFinished = (
  round: DeliberateAny,
  dataOptions: DeliberateAny
) =>
  dataOptions.AllocationRoundStateOptions[round.Round.State].Value ===
  ROUND_FINISHED;

export const prettyPrintApplicationDeadline = (round: DeliberateAny) =>
  isRegularAllocationRound(round)
    ? moment(round.Round.ApplicationDeadline).format("DD.MM.YYYY")
    : "Fortløpende tildeling";

export const openRoundIsAllocatable = (
  round: DeliberateAny,
  dataOptions: DeliberateAny
) => !roundIsAllocated(round, dataOptions) && isOpenAllocationRound(round);

export const getRoundTitle = (
  round: DeliberateAny,
  dataOptions: DeliberateAny
) => {
  const deadlineDate = moment(round.Round.ApplicationDeadline).format(
    "DD.MM.YYYY"
  );
  const allocatedDate = moment(round.Round.FinallyAllocatedDate).format(
    "DD.MM.YYYY"
  );

  if (isOpenAllocationRound(round)) {
    if (roundIsAllocated(round, dataOptions)) {
      return `Åpen fordelingsrunde tildelt ${allocatedDate}`;
    }
    return "Åpen fordelingsrunde";
  }

  return `Fordelingsrunde ${deadlineDate}`;
};
