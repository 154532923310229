import env from 'env';

const { CMS: cmsUrl } = env;

// Controllers
const basen = 'Basen';
const cmsData = 'CmsData';
const auth = 'Auth';
const user = 'User';

const favorite = 'favorite';
const notificationPreferences = 'NotificationPreferences';

export const basenUrl = `${cmsUrl}/${basen}`;

// Endpoints
export const myTabsURL = `${cmsUrl}/${basen}/MyTabs`;
export const getProfileTextsURL = `${cmsUrl}/${basen}/GetProfileTexts`;

export const getMainNavigationData = `${cmsUrl}/${cmsData}/MainNavigationData`; // GET
export const getEtatData = `${cmsUrl}/${cmsData}/Etats`; // GET
export const alertMessageURL = `${cmsUrl}/${cmsData}/AlertMessage`; // GET
export const getInformation = `${cmsUrl}/${basen}/informasjon`;
export const getHousingHandbook = `${cmsUrl}/${cmsData}/housinghandbook`;
export const getContact = `${cmsUrl}/${basen}/kontakt`;

export const getTempUserInfo = `${cmsUrl}/${basen}/tempuserinfo`; // GET

export const getNotificationPreferencesURL = `${cmsUrl}/${notificationPreferences}`; // GET
export const updateNotificationPreferencesURL = `${cmsUrl}/${notificationPreferences}`; // PUT

export const verifyEmailURL = `${cmsUrl}/${auth}/VerifiserEpost`; // POST
export const confirmTwoFactorURL = `${cmsUrl}/${basen}/BekreftEngangskode`; // POST
export const sendTwoFactorCodeURL = `${cmsUrl}/${basen}/SendTwoFactorCode`; // POST

// Login
export const loginURL = `${cmsUrl}/${auth}/Login`; // POST
export const logoutURL = `${cmsUrl}/${auth}/Logout`; // POST
export const codeURL = `${cmsUrl}/${auth}/TwoFactor`; // POST
export const forgotURL = `${cmsUrl}/${basen}/Glemt`; // POST
export const registrerURL = `${cmsUrl}/${basen}/Register`; // POST
export const verifyUserURL = `${cmsUrl}/${basen}/Verify`; // POST
export const verifyUserActionKeyURL = `${cmsUrl}/${basen}/VerifyActionKey`; // POST
export const createUserURL = `${cmsUrl}/${basen}/Create`; // POST
export const verifyIdp = `${cmsUrl}/${basen}/VerifyAtIdp`; // NAVIGATE
export const confirmURL = `${cmsUrl}/${basen}/Bekreft`; // POST
export const endrePassordUrl = `${cmsUrl}/${basen}/EndrePassord`; // POST

// User
export const saveWidgetConfigURL = `${cmsUrl}/${user}/SaveWidgetConfigForCurrentUser`; // POST
export const updateProfileURL = `${cmsUrl}/${user}/UpdateProfile`; // PUT
export const updatePasswordURL = `${cmsUrl}/${user}/UpdatePassword`; // POST

// Dropzone
export const getDropzoneOptionsURL = `${cmsUrl}/${basen}/dropzoneoptions`; // GET"

// Favorites
export const addPortfolioFavoriteURL = `${cmsUrl}/${favorite}/AddStringIdFavorite`;
export const removePortfolioFavoriteURL = `${cmsUrl}/${favorite}/RemoveStringIdFavorite`;

// Texts
export const serviceOrderShortcutsURL = `${cmsUrl}/${cmsData}/ServiceOrderShortcutItems`; // GET

// Settings

export const googleMapsSettingURL = `${cmsUrl}/${cmsData}/GoogleMapsSettings`;

export const getFireListValidityInMinutesURL = `${cmsUrl}/${cmsData}/Lock/GetFireListValidityInMinutes`;

export default cmsUrl;
