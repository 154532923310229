import { useState } from 'react';

import { DeliberateAny } from '../../types/DelibrateAny';
import LabelValueList from '../LabelValueList/LabelValueList';
import Loader from '../Loader/Loader';
import Modal from '../modals/Modal/Modal';

const ApplicationSummaryModal = ({
  isOpen,
  setModalIsOpen,
  data,
  onSubmit,
}: DeliberateAny) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <Modal
      title="Oppsummering"
      ariaLabel="Oppsummering"
      unclassifiedStamp
      isOpen={isOpen}
      size="medium"
      onClose={() => {
        if (!submitted) setModalIsOpen(false);
      }}
      submit={{
        onClick: () => {
          setSubmitted(true);
          setModalIsOpen(false);
          onSubmit();
        },
        closeModalAfterSubmitSuccess: false,
        ignoreOnClose: true,
        disabled: submitted,
        text: submitted ? 'Sender..' : 'Send inn',
      }}
      dataTestIdPrefix="summary"
    >
      <Loader isLoading={!data}>
        {data && (
          <div className="margin">
            <p>
              Vennligst kontroller at informasjonen du har angitt er ugradert og
              riktig.
            </p>
            <LabelValueList
              items={data}
              style={{
                headerBreakLine: true,
              }}
            />
          </div>
        )}
      </Loader>
    </Modal>
  );
};

export default ApplicationSummaryModal;
