/* eslint-disable react-hooks/exhaustive-deps */
import './UnnamedBooking.scss';

import {
  getArenaForLocationKeyURL,
  getAvailabilityByRoomTypeURL,
  getUnnamedBookingURL,
  locationsURL,
  patchNewPinURL,
} from 'api/booking/booking_api';
import classNames from 'classnames';
import UnnamedRoomSelect from 'components/booking/UnnamedRoomSelect';
import Button from 'components/buttons/Button/Button';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import Input from 'components/form/Input/Input';
import HelpText from 'components/HelpText/HelpText';
import Loader from 'components/Loader/Loader';
import Slide from 'components/Slides/Slide';
import Spinner from 'components/Spinner/Spinner';
import { useLogin, useModal } from 'contexts/index';
import useAxios from 'hooks/useAxios';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { DeliberateAny } from 'types/DelibrateAny';

export interface Availability {
  roomtypeId: number;
  roomtypeDescription: string;
  availabilityCount: number;
}

interface Booking {
  arrivalDate: string;
  departureDate: string;
  purpose: string;
  availability: Availability[];
}

interface CheckinRequest {
  RoomTypeId: number;
  PersonalCode: string;
  CheckInReferenceCode: string;
  LocationKey: string;
}

interface CheckinResponse {
  roomName: string;
  pin: string;
}

// Anonym innsjekk
const UnnamedBooking = () => {
  const { arenaid } = useParams();
  const { setMsg } = useLogin();
  const navigate = useNavigate();

  const allLocationsRequest = useAxios();
  const locationRequest = useAxios();
  const availabilityRequest = useAxios();
  const checkinRequest = useAxios();
  const forgetRequest = useAxios();
  const { showModal } = useModal();

  const [locationName, setLocationName] = useState();
  const [referenceCode, setReferenceCode] = useState('');
  const [selectedRoom, setSelectedRoom] = useState(-1);
  const [codeWord, setCodeWord] = useState('');
  const [codeWordError, setCodeWordError] = useState(false);
  const [codeWordCheck, setCodeWordCheck] = useState('');
  const [codeWordCheckError, setCodeWordCheckError] = useState(false);
  const [checkin, setCheckin] = useState<CheckinResponse>();
  const [forgot, setForgot] = useState(false);

  const [forgotForm, setForgotForm] = useState<DeliberateAny>({});
  const [forgotErrors, setForgotErrors] = useState<DeliberateAny>();

  const [allLocations, setAllLocations] = useState<DeliberateAny[]>([]);

  const [booking, setBooking] = useState<Booking>();

  const fetchAllLocations = async () => {
    await allLocationsRequest.sendRequest(
      {
        method: 'GET',
        url: locationsURL,
      },
      (response) => {
        setAllLocations(response);
      },
    );
  };

  useEffect(() => {
    void fetchAllLocations();
  }, []);

  const fetchArenaInfo = async () => {
    await locationRequest.sendRequest(
      {
        method: 'GET',
        url: getArenaForLocationKeyURL,
        params: { locationKey: arenaid },
      },
      ({ name }) => setLocationName(name),
    );
  };

  useEffect(() => {
    if (arenaid) {
      void fetchArenaInfo();
    }
  }, [allLocations, arenaid]);

  const handleSubmitReferenceCode = async () => {
    await availabilityRequest.sendRequest(
      {
        method: 'GET',
        url: getAvailabilityByRoomTypeURL,
        params: {
          location: arenaid,
          code: referenceCode,
        },
      },
      (res: Booking) => {
        if (res.availability.length) setBooking(res);
        else setMsg('Ingen ledige rom med denne referansekoden');
      },
      null,
      (error) => console.error(error),
    );
  };

  const handleCheckIn = async () => {
    if (arenaid) {
      const data: CheckinRequest = {
        CheckInReferenceCode: referenceCode,
        PersonalCode: codeWord,
        RoomTypeId: selectedRoom,
        LocationKey: arenaid,
      };

      await checkinRequest.sendRequest(
        {
          method: 'POST',
          url: getUnnamedBookingURL,
          data,
        },
        setCheckin,
        null,
        (error) => {
          if (error?.response?.data) {
            showModal({
              title: error.response.data.title,
              infoText: error.response.data.detail,
            });
          } else {
            showModal({
              title: 'Noe gikk galt',
              infoText: 'Ta kontakt med resepsjonen',
            });
          }
        },
      );
    }
  };

  const nodeRef = useRef(null);
  const bookingClass = classNames('app-root', {
    booking: true,
    slides: true,
    scrollable: true,
  });

  const updateForgotForm = (name: string, value: boolean | number | string) => {
    const newForm = { ...forgotForm };
    newForm[name] = value;
    setForgotForm(newForm);
  };

  const validate = () => {
    const errors: DeliberateAny = {};
    let valid = true;

    // RoomNumber [required]
    if (!forgotForm.roomNumber) {
      errors.roomNumber = {
        rule: 'required',
      };
      valid = false;
    }

    // useCodeWord [required]
    if (!forgotForm.useCodeWord) {
      errors.useCodeWord = {
        rule: 'required',
      };
      valid = false;
    }

    setForgotErrors(errors);
    return valid;
  };

  const submitForgotForm = async () => {
    if (validate()) {
      await forgetRequest.sendRequest(
        {
          method: 'PATCH',
          url: patchNewPinURL,
          data: {
            RoomName: forgotForm.roomNumber,
            CheckInReferenceCode: referenceCode,
            PersonalCode: forgotForm.useCodeWord,
          },
        },
        (res) => setCheckin(res),
        null,
        (error) => {
          const errorinfo = error?.response?.data;
          if (errorinfo) {
            showModal({
              title: errorinfo.title,
              infoText: errorinfo.detail,
            });
          }
        },
      );
    }
  };

  return (
    <div>
      <Loader
        isLoading={
          allLocationsRequest.requestLoading || locationRequest.requestLoading
        }
      >
        <div className={bookingClass}>
          <div className="scrollable">
            <TransitionGroup className={`slide-wrapper scrollable`}>
              <CSSTransition
                timeout={500}
                classNames="slide-transition"
                nodeRef={nodeRef}
              >
                <Slide path="" backgroundImage slideSize="none">
                  <div className="form book form">
                    {booking && !checkin && (
                      <a
                        href="#"
                        className="js-flip-back flip-back icon-arrow-left"
                        onClick={(e) => {
                          e.preventDefault();
                          setBooking(undefined);
                          setSelectedRoom(-1);
                          setCodeWord('');
                          setCodeWordError(false);
                          setCodeWordCheck('');
                          setCodeWordCheckError(false);
                        }}
                      >
                        <span style={{ fontFamily: 'sans-serif' }}>
                          Tilbake
                        </span>
                      </a>
                    )}

                    {forgot && !checkin && (
                      <a
                        href="#"
                        className="js-flip-back flip-back icon-arrow-left"
                        onClick={(e) => {
                          e.preventDefault();
                          setForgotForm({});
                          setForgotErrors(undefined);
                          setForgot(false);
                        }}
                      >
                        <span style={{ fontFamily: 'sans-serif' }}>
                          Tilbake
                        </span>
                      </a>
                    )}

                    <h2>{locationName}</h2>
                    <div className="content-group grey padding half-margin">
                      {!booking && !checkin && (
                        <div className="book-wrapper">
                          <div className="reference-input">
                            {forgot ? (
                              <>
                                <input
                                  type="text"
                                  name="username"
                                  style={{
                                    position: 'absolute',
                                    left: '-9999px',
                                  }}
                                />
                                <input
                                  type="password"
                                  name="password"
                                  style={{
                                    position: 'absolute',
                                    left: '-9999px',
                                  }}
                                />

                                <Input
                                  data-testid="roomNumber"
                                  name="roomNumber"
                                  title={'Romnummer'}
                                  value={forgotForm.roomNumber}
                                  onChange={updateForgotForm}
                                  autoComplete="off"
                                  placeholder="Eks. 1223_1201"
                                  errors={forgotErrors}
                                />
                                <Input
                                  data-testid="useCodeWord"
                                  name="useCodeWord"
                                  title={'Personlig kode'}
                                  type="password"
                                  value={forgotForm.useCodeWord}
                                  onChange={updateForgotForm}
                                  autoComplete="off"
                                  placeholder="Din personlige kode"
                                  errors={forgotErrors}
                                />

                                <Button
                                  color="red"
                                  onClick={() => submitForgotForm()}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '50%',
                                    height: 44,
                                    alignSelf: 'flex-end',
                                  }}
                                >
                                  {forgetRequest.requestLoading ? (
                                    <Spinner white />
                                  ) : (
                                    'Hent ny PIN-kode'
                                  )}
                                </Button>
                                {forgetRequest.requestLoading && (
                                  <div style={{ marginTop: 16 }}>
                                    <span>
                                      Venter på at låsesystemet skal generere ny
                                      pin-kode. Dette kan ta opptil 1 minutt,
                                      vennligst vent.
                                    </span>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <label>
                                  <span style={{ whiteSpace: 'nowrap' }}>
                                    Oppgi referansekode
                                  </span>
                                  <div className="input-with-button">
                                    <input
                                      placeholder="Eks. 1A23BC"
                                      type="text"
                                      value={referenceCode}
                                      name="referenceCode"
                                      onChange={(e) =>
                                        setReferenceCode(e.target.value)
                                      }
                                    />
                                    <Button
                                      color="red"
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                      onClick={handleSubmitReferenceCode}
                                    >
                                      {availabilityRequest.requestLoading ? (
                                        <Spinner white />
                                      ) : (
                                        'Send'
                                      )}
                                    </Button>
                                  </div>
                                </label>
                                <a
                                  style={{ whiteSpace: 'nowrap' }}
                                  className="js-flip underlined pointer"
                                  data-show-flip="reset-password"
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (!referenceCode)
                                      setMsg(
                                        'Oppgi hvilken referansekode dette gjelder!',
                                      );
                                    else setForgot(true);
                                  }}
                                >
                                  Har du glemt PIN-koden til ditt kvarter?
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {booking && !checkin && (
                        <Fragment>
                          <dl className="margin" style={{ fontSize: 16 }}>
                            <dt>
                              <strong>Ankomst</strong>
                            </dt>
                            <dd>
                              {`${new Date(
                                booking.arrivalDate,
                              ).toLocaleDateString('no', {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                              })}`}
                            </dd>
                            <dt>
                              <strong>Avreise</strong>
                            </dt>
                            <dd>
                              {`${new Date(
                                booking.departureDate,
                              ).toLocaleDateString('no', {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                              })}`}
                            </dd>
                            <dt>
                              <strong>Formål</strong>
                            </dt>
                            <dd>{booking.purpose}</dd>
                          </dl>

                          <UnnamedRoomSelect
                            rooms={booking?.availability}
                            selected={selectedRoom}
                            setSelected={setSelectedRoom}
                          />
                          <div
                            style={{
                              marginTop: 16,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 8,
                            }}
                          >
                            <p>
                              Dersom du glemmer PIN-koden til rommet ditt, må du
                              oppgi denne personlige koden for å få generert en
                              ny PIN.
                            </p>
                            <Input
                              name="codeWord"
                              title="Velg en personlig kode"
                              type="password"
                              value={codeWord}
                              onChange={(_, value) => {
                                setCodeWordError(false);
                                setCodeWord(value);
                              }}
                              onBlur={(_, value) => {
                                setCodeWordError(value.length < 4);
                              }}
                              errors={
                                codeWordError
                                  ? {
                                      codeWord: {
                                        rule:
                                          codeWord && codeWord.length < 4
                                            ? 'length.min'
                                            : 'required',
                                        min: 4,
                                      },
                                    }
                                  : undefined
                              }
                            />
                          </div>
                          <div style={{ marginTop: 16 }}>
                            <Input
                              name="codeWordCheck"
                              title={'Gjenta personlig kode'}
                              type="password"
                              value={codeWordCheck}
                              onChange={(_, value) => {
                                setCodeWordCheckError(false);
                                setCodeWordCheck(value);
                              }}
                              onBlur={() => {
                                setCodeWordCheckError(
                                  codeWordCheck != codeWord,
                                );
                              }}
                              errors={
                                codeWordCheckError
                                  ? {
                                      codeWordCheck: {
                                        rule:
                                          codeWord &&
                                          codeWordCheck &&
                                          codeWordCheck != codeWord,
                                        min: 4,
                                      },
                                    }
                                  : undefined
                              }
                            />
                          </div>
                          {checkinRequest.requestLoading && (
                            <div style={{ marginTop: 16 }}>
                              <span>
                                Venter på at låsesystemet skal generere din
                                pin-kode. Dette kan ta opptil 1 minutt,
                                vennligst vent.
                              </span>
                            </div>
                          )}
                          <div style={{ marginTop: 16, float: 'right' }}>
                            <Button
                              style={{
                                display: 'flex',
                                height: 44,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              color="red"
                              onClick={handleCheckIn}
                              disabled={
                                selectedRoom === -1 ||
                                !codeWord ||
                                codeWord.length < 4 ||
                                !codeWordCheck ||
                                codeWord.length < 4 ||
                                codeWord !== codeWordCheck
                              }
                            >
                              {checkinRequest.requestLoading ? (
                                <Spinner white />
                              ) : (
                                'Sjekk inn og generer pin kode'
                              )}
                            </Button>
                          </div>
                        </Fragment>
                      )}
                      {checkin && (
                        <Fragment>
                          <dl
                            className="margin room-details"
                            style={{ fontSize: 16 }}
                          >
                            <dt>
                              <strong>Romnummer:</strong>
                            </dt>
                            <dd>{checkin.roomName}</dd>
                            <dt>
                              <strong>Pin-kode:</strong>
                            </dt>
                            <dd>{checkin.pin}</dd>
                          </dl>
                          <p>
                            Ta vare på PIN-koden. Dersom du mister den, må du
                            velge “Har du glemt PIN-koden til ditt kvarter?“ ved
                            starten av innsjekks prosessen og angi din
                            personlige kode.
                          </p>
                        </Fragment>
                      )}
                    </div>
                    <ButtonRow>
                      <ButtonRow.Right
                        color="red"
                        onClick={() => navigate('/')}
                      >
                        {checkin ? 'Ok' : 'Lukk'}
                      </ButtonRow.Right>
                    </ButtonRow>
                  </div>
                </Slide>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </Loader>
    </div>
  );
};

export default UnnamedBooking;
