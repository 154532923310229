import Slide from "components/Slides/Slide";
import { HousingQuarter } from "contexts/HousingContext";
import { useHousing, useSlides } from "contexts/index";
import { DeliberateAny } from "types/DelibrateAny";

import FamilySituation from "../FamilySituation";
import {
  housing,
  housingTitle,
  paths,
  quarter,
  quarterTitle,
} from "../HousingSection";

const FamilySituationSlide = ({ appType }) => {
  const { housingTexts, loading, dataOptions, applicationType } = useHousing();
  const { goToSlide } = useSlides();

  return (
    <Slide
      path={`${appType === HousingQuarter.Housing ? housing : quarter}${
        paths.familySituation
      }`}
      title={appType === HousingQuarter.Housing ? housingTitle : quarterTitle}
      showCount
      loading={loading || !dataOptions || !housingTexts}
    >
      {dataOptions && housingTexts && (
        <FamilySituation
          goToSlide={goToSlide}
          applicationType={applicationType}
          defaultTo={`${
            appType === HousingQuarter.Housing ? housing : quarter
          }${paths.applicantCategory}`}
          nextSlidePath={`${
            appType === HousingQuarter.Housing ? housing : quarter
          }${paths.livingCondition}`}
          infoText={housingTexts}
          maritalStatuses={dataOptions.MaritalStatusOptions.map(
            (x: DeliberateAny) => {
              return { key: x.Key, value: x.Value };
            }
          )}
          housingDataOptionsLoaded={loading}
        />
      )}
    </Slide>
  );
};

export default FamilySituationSlide;
