import { differenceInDays, format, startOfDay } from 'date-fns';

export function humanReadableDate(
  inputDate,
  showTime = false,
  ignoreToday = false,
) {
  const today = startOfDay(new Date());
  const input = startOfDay(new Date(inputDate));
  const dayDiff = differenceInDays(input, today);

  const formattedDate = showTime
    ? format(inputDate, 'dd.MM.yyyy, HH:mm')
    : format(inputDate, 'dd.MM.yyyy');
  const formattedTime = format(inputDate, 'HH:mm');

  if (dayDiff === 0) {
    return `${ignoreToday ? '' : 'I dag'}${showTime ? `${ignoreToday ? '' : ','} kl. ${formattedTime}` : ''}`;
  } else if (dayDiff === 1) {
    return `I morgen${showTime ? `, kl. ${formattedTime}` : ''}`;
  } else if (dayDiff === -1) {
    return `I går${showTime ? `, kl. ${formattedTime}` : ''}`;
  } else if (dayDiff > 1 && dayDiff <= 7) {
    return `Om ${dayDiff} dager${showTime ? ` (${formattedDate})` : ''}`;
  } else if (dayDiff < -1 && dayDiff >= -7) {
    return `${Math.abs(dayDiff)} dager siden${showTime ? ` (${formattedDate})` : ''}`;
  } else if (dayDiff > 7 && dayDiff <= 14) {
    return `Neste uke (${formattedDate})`;
  } else if (dayDiff < -7 && dayDiff >= -14) {
    return `Forrige uke (${formattedDate})`;
  } else if (dayDiff > 14) {
    return `Om ${Math.floor(dayDiff / 7)} uker (${formattedDate})`;
  } else if (dayDiff < -14) {
    return `${Math.abs(Math.floor(dayDiff / 7))} uker siden (${formattedDate})`;
  } else {
    return formattedDate;
  }
}

export function formatDateRange(arrival: Date, departure: Date): string {
  // Check if arrival and departure are valid Date objects
  if (!(arrival instanceof Date) || isNaN(arrival.getTime())) {
    throw new RangeError(`Invalid arrival date: ${arrival.toISOString()}`);
  }

  if (!(departure instanceof Date) || isNaN(departure.getTime())) {
    throw new RangeError(`Invalid departure date: ${departure.toISOString()}`);
  }

  // Create a formatter for day and month using the desired locale and options
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
  };
  const formatter = new Intl.DateTimeFormat('nb-NO', options);

  // Format both arrival and departure dates
  const formattedArrival = formatter.format(arrival);
  const formattedDeparture = formatter.format(departure);

  // Concatenate the formatted dates
  return `${formattedArrival} - ${formattedDeparture}`;
}
