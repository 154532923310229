import "./HousingApplicationsOverview.scss";

import {
  fetchVacantFromOffsetDaysHousing,
  registerHousingAllocations,
} from "actions/housingAdminActions";
import {
  housingListExcelDocumentURL,
  previewHousingAllocationRegistrationURL,
} from "api/housing/housing_api";
import ButtonRow from "components/buttons/ButtonRow/ButtonRow";
import DownloadButton from "components/buttons/DownloadButton/DownloadButton";
import DateInput from "components/DateInput/DateInput";
import Modal from "components/modals/Modal/Modal";
import { useSlides } from "contexts/index";
import { axiosFetch } from "hooks/useAxios";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DeliberateAny } from "types/DelibrateAny";
import Spinner from 'components/Spinner/Spinner';
import HousingAllocationList from "./HousingAllocationsList/HousingAllocationsList";

const PreviewModal = ({
  response,
  setTextArea,
  isOpen,
  setModalIsOpen,
  onSubmit,
}) => {
  const handleCloseModal = () => {
    setTextArea("");
    setModalIsOpen(false);
  };
  return (
    <Modal
      title="Forhåndsvisning av tildelingsliste"
      isOpen={isOpen}
      size="medium"
      onClose={() => handleCloseModal()}
      submit={{ onClick: () => onSubmit(), text: "Lagre" }}
      cancel={{ onClick: () => handleCloseModal(), text: "Lim inn på nytt" }}
    >
      <div className="margin">
        <HousingAllocationList items={response} isPreview />
      </div>
    </Modal>
  );
};

const ErrorModal = ({  
  errorText,
  isOpen,
  setModalIsOpen,}) => {

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  return (
    <Modal
    title="Feil ved generering av forhåndsvisning"
    isOpen={isOpen}
    size="medium"
    cancel={{hide:true}}
    onClose={() => handleCloseModal()}
    submit={{ text: "Lukk" }}
  >
    <div className="margin">
       {errorText}
    </div>
  </Modal>
  );
}

const HousingAdminCouncil = (props) => {
  const [textArea, setTextArea] = useState("");
  const [response, setResponse] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [date, setDate] = useState<Moment>();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [previewAllocationIsLoading, setPreviewAllocationIsLoading] = useState(false);

  const housingArea = useSelector(
    (state: DeliberateAny) => state.housingAdmin.housingArea,
    shallowEqual
  );

  const dispatch = useDispatch();
  const params = useParams();
  const { goToSlide } = useSlides();

  useEffect(() => {
    if (housingArea.registeredHousingAllocations) {
      goToSlide(`/boligsekretær/godkjenn/${params.housingAreaId}`);
    }
  }, [housingArea.registeredHousingAllocations]);

  useEffect(() => {
    if (housingArea.selectedAllocationRound) {
      dispatch(fetchVacantFromOffsetDaysHousing());
    } else {
      goToSlide(`/boligsekretær/boligsøknader/${params.housingAreaId}`);
    }
  }, []);

  useEffect(() => {
    setDate(moment().add(housingArea.vacantFromStandardOffsetDays, "d"));
  }, [housingArea.vacantFromStandardOffsetDays]);

  const previewAllocationsInRound = () => {
    setPreviewAllocationIsLoading(true);
    const url = previewHousingAllocationRegistrationURL;
    const query = {
      allocationDataCopiedFromExcel: textArea,
      allocationRoundId: housingArea.selectedAllocationRound.Round.Id,
    };
    axiosFetch({ url, data: query, method: "POST" })
    .then((res) => {
      setResponse(res);
      setShowPreviewModal(true);})
    .catch((res) => {
      setErrorResponse(res.response.data.Text);
      setShowErrorModal(true);})
    .finally(() => {
      setPreviewAllocationIsLoading(false);});
  };

  const formatMomentToString = (d) => d.format("YYYY-MM-DD");

  return (
    <div>
      {housingArea.selectedAllocationRound && date && (
        <div className="wrapper">
          <h2 className="double-margin">Boligrådsmøte</h2>
          <div className="date-search-container">
            <h3>Velg dato for boligarket</h3>
            <DateInput
              small
              name="housing_search_date"
              minDate={moment()}
              selectedDate={date}
              onSelectDate={(d) => setDate(d)}
            />
            <DownloadButton
              href={`${housingListExcelDocumentURL}?allocationRoundId=${
                housingArea.selectedAllocationRound.Round.Id
              }&vacantFrom=${formatMomentToString(date)}`}
            >
              Last ned
            </DownloadButton>
          </div>
          <div className="council-content content-info-icon">
            <ul className="margin">
              <li>
                <h3>
                  <span className="red">1.</span> Gå igjennom søknadene
                </h3>
                <p>
                  Gå igjennom de innkomne søknadene for boligrådet slik at de
                  får oversikt. Har de kommentarer til målgruppe eller poeng kan
                  du gjøre endringer direkte i behandingsarket.
                </p>
              </li>
              <li>
                <h3>
                  <span className="red">2.</span> Prioriter søkere
                </h3>
                <p>
                  Velg hvilke søkerne som skal få bolig i denne runden. Søkerne
                  med mest poeng skal prioriteres om ikke andre faglige
                  begrunnelser forekommer.
                </p>
              </li>
              <li>
                <h3>
                  <span className="red">3.</span> Fordel boliger
                </h3>
                <p>
                  Nå trenger du boligarket. Last ned boligarket. Gå igjennom
                  boligene med boligrådet, og få oversikt over boligene. Du
                  fordeler boliger fra boligarket til behandlingsarket ved å
                  kopiere feltene Leie-id og Benevnelse fra boligarket til den
                  aktuelle søkeren i behandlingsarket.
                </p>
              </li>
              <li>
                <h3>
                  <span className="red">4.</span> Lim inn ferdig behandlingsark
                </h3>
                <p>
                  Marker alle radene i tabellen [ctrl + a], kopier [ctrl + c] og
                  lim inn [ctrl + v] i feltet under.
                  <br />
                  Pass på at hver søker står på en egen linje, og at du ikke
                  gjør endringer i rekkefølgen på feltene i excelarket.
                </p>
                <textarea
                  value={textArea}
                  onChange={(e) => setTextArea(e.target.value)}
                />
              </li>
            </ul>
            <ButtonRow>
              <ButtonRow.Right
                disabled={!textArea}
                color="red"
                onClick={() => previewAllocationsInRound()}
              >
                {previewAllocationIsLoading ? <Spinner white /> : 'Se tildelingsliste'}
              </ButtonRow.Right>
            </ButtonRow>
          </div>
        </div>
      )}
      {showPreviewModal && (
        <PreviewModal
          isOpen={showPreviewModal}
          setModalIsOpen={setShowPreviewModal}
          response={response}
          setTextArea={setTextArea}
          onSubmit={() =>
            dispatch(
              registerHousingAllocations(
                response,
                housingArea.selectedAllocationRound.Round.Id
              )
            )
          }
        />
      )}
      {showErrorModal && (
        <ErrorModal 
        isOpen={showErrorModal}
        setModalIsOpen={setShowErrorModal}
        errorText={errorResponse}/>
      )}
    </div>
  );
};

export default HousingAdminCouncil;

// (props) => (
//   <Route
//     location={props.location}
//     path={`${props.match.url}/:housingAreaId`}
//     component={HousingAdminCouncil}
//   />
// );
