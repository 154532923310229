// @flow

import { requestNewKeyMyPageURL } from 'api/lock/lock_api';
import ArenaInfo from 'components/ArenaInfo/ArenaInfo';
import ButtonRow from 'components/buttons/ButtonRow/ButtonRow';
import DetailsDivider from 'components/DetailsDivider/DetailsDivider';
import Input from 'components/form/Input/Input';
import RadioButtonGroup from 'components/form/RadioButtonGroup/RadioButtonGroup';
import LabelValueList from 'components/LabelValueList/LabelValueList';
import Link from 'components/Link/Link';
import LinkList from 'components/LinkList/LinkList';
import Modal from 'components/modals/Modal/Modal';
import RouterModal from 'components/modals/RouterModal/RouterModal';
import { useModal, useUser } from 'contexts/index';
import DOMPurify from 'dompurify';
import { formatDate } from 'helpers/valueHelpers';
import { axiosFetch } from 'hooks/useAxios';
import { useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import { RentalItemPropsType } from './RentalItem';

const TerminatedDates = (props: RentalItemPropsType) => (
  <LabelValueList
    items={[
      {
        label: "Oppsagt dato",
        value: formatDate(props.item.TerminatedDate),
      },
      {
        label: "Ønsket utflyttingsdato",
        value: formatDate(props.item.DesiredMoveDate),
      },
    ]}
  />
);

export const TerminatedDescription = (props: RentalItemPropsType) => (
  <DetailsDivider>
    <TerminatedDates {...props} />
  </DetailsDivider>
);

export const CeasedDescription = (props: RentalItemPropsType) => (
  <DetailsDivider>
    <strong>Kontrakten er utløpt</strong>
    <TerminatedDates {...props} />
  </DetailsDivider>
);

export const RenewalDescription = () => (
  <DetailsDivider>
    <p>
      Det finnes en søknad om forlengelse på dette leieforholdet. Se søknaden på{" "}
      <Link to="/my-page/tab-my-housing-applications">
        Min side - Mine søknader
      </Link>
    </p>
  </DetailsDivider>
);

export const RelevantDocumentsAndLinks = (props: RentalItemPropsType) => (
  <DetailsDivider>
    <LinkList
      title="Relevante dokumenter og lenker"
      serverLinks={props.documentLinks}
      clientLinks={[
        { to: "/information", name: "Bolighåndboka" },
        { to: "/contact", name: "Kontakt Forsvarsbygg: Servicesenteret" },
      ]}
    >
      <ArenaInfo
        arenaId={props.item.ArenaId}
        arenaName={props.item.ArenaName}
      />
    </LinkList>
  </DetailsDivider>
);

type ExtendedApplicationPropsType = RentalItemPropsType & {
  path: string;
  onSubmit: () => void;
  selectedExtendedApplicationLink: string;
  longExtendedApplicationPath: string;
  shortExtendedApplicationPath: string;
  changeExtendedApplicationType?: DeliberateAny;
};

export const ExtendedApplicationChoiceModal = (
  props: ExtendedApplicationPropsType
) => {
  return (
    <RouterModal
      title="Søk om forlengelse"
      ariaLabel="Søk om forlengelse"
      path={props.path}
      submit={{
        text: "Velg",
        onClick: props.onSubmit,
        ignoreOnClose: true,
      }}
    >
      <div className="margin">
        <div
          className="margin"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              props.selectedExtendedApplicationLink ===
                props.longExtendedApplicationPath
                ? props.rentalContractTexts.LangForlengelseBeskrivelse
                : props.rentalContractTexts.KortForlengelseBeskrivelse
            ),
          }}
        />
        <RadioButtonGroup
          legend="Velg type forlengelse"
          name="extendedApplicationLink"
          onChange={props.changeExtendedApplicationType}
          options={[
            {
              label: "Opp til tre måneders forlengelse",
              value: props.shortExtendedApplicationPath,
            },
            {
              label: "Mer enn tre måneders forlengelse",
              value: props.longExtendedApplicationPath,
            },
          ]}
          checkedOption={props.selectedExtendedApplicationLink}
        />
      </div>
    </RouterModal>
  );
};

type SendNewKeyModalPropsType = {
  setSendNewKeyModalIsOpen: (isOpen: boolean) => void;
  booking: DeliberateAny;
};

export const SendNewKeyModal = (props: SendNewKeyModalPropsType) => {
  const { user } = useUser();
  const { showModal } = useModal();
  const [showNewKeyModal, setShowNewKeyModal] = useState(false);
  const booking = props.booking;
  let countrycodeFormatted = "";

  if (user.CountryCode) {
    if (user.CountryCode.startsWith("+") || user.CountryCode.startsWith("00")) {
      countrycodeFormatted = user.CountryCode;
    } else {
      countrycodeFormatted = `+${user.CountryCode}`;
    }
  } else {
    countrycodeFormatted = "";
  }

  const requestUrl = requestNewKeyMyPageURL;
  const [phonenumber, setPhonenumber] = useState(
    user.Phone ? countrycodeFormatted + user.Phone : "+47"
  );

  const isValidPhonenumber = () => /^(?:\+|00|)\d{1,16}$/.test(phonenumber);

  const handleCloseModal = () => {
    setShowNewKeyModal((x) => !x);
  };

  const handleRequestNewKey = () => {
    const allValid = isValidPhonenumber();
    if (allValid) {
      const query = {
        Reference: booking.HousingReference,
        PhoneNumber: phonenumber || "",
        Origin: 1, // 1 is Xpand as origin
        GuestReference: booking.GuestReference,
      };
      handleCloseModal();
      axiosFetch({
        url: requestUrl,
        data: query,
        method: "POST",
        withCredentials: true,
      })
        .then((res) => {
          if (res) {
            showModal({
              modalType: "default",
              title: res.Title ? res.Title : "Forespørsel sendt",
              text: res.Text ? res.Text : res,
            });
          } else {
            showModal({
              modalType: "default",
              title: res.Title ? res.Title : "Feil ved forespørsel om nøkkel",
              text: res.Text ? res.Text : res,
            });
          }
        })
        .catch((res) => {
          showModal({
            modalType: "default",
            title: res.Title ? res.Title : "Feil ved forespørsel om nøkkel",
            text: res.Text ? res.Text : res,
          });
        });
    }
  };

  return (
    <>
      <ButtonRow.Right key="1" onClick={() => setShowNewKeyModal(true)}>
        Send nøkkel
      </ButtonRow.Right>
      <Modal
        title="Send nøkkel"
        ariaLabel="Send nøkkel"
        isOpen={showNewKeyModal}
        onClose={() => handleCloseModal()}
        size="medium"
        submit={{
          text: "Send nøkkel",
          onClick: () => handleRequestNewKey(),
          ignoreOnClose: true,
        }}
      >
        <p>
          Send nøkkel til mobilnummeret oppført i feltet nedenfor. Dersom du
          ikke mottar nøkkel innen 5 minutter, ta kontakt med servicesenteret.
        </p>

        <div className="margin">
          <div className="margin">
            <div className="small-expandable">
              <div className="content-group grey padded expand-container">
                <div className="input-container">
                  <div className="flex-row margin">
                    <label className={isValidPhonenumber() ? "" : "invalid"}>
                      <span>Mobilnummer</span>
                      <Input
                        onChange={(undef, phone) => setPhonenumber(phone)}
                        value={phonenumber}
                        type="text"
                        name="phonenumber"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
