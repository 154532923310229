import { mapArrayObjectsToCamel } from "helpers/commonReducerHelpers";

export const initialState = {
  user: {},
  staticFilterOptions: {},
  staticFilterOptionsLoaded: false,
  housingDataOptions: {},
  housingDataOptionsLoaded: false,
  myDifs: [],
  myDifsLoaded: false,
  dropzoneOptions: {},
  dropzoneOptionsLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "RECEIVE_LOGGEDIN_USER":
      return { ...state, user: action.user };

    case "RECEIVE_STATIC_FILTER_OPTIONS":
      return {
        ...state,
        staticFilterOptionsLoaded: true,
        staticFilterOptions: {
          serviceOrderTypes: mapArrayObjectsToCamel(
            action.options.ServiceOrderTypes
          ),
          serviceOrderStates: mapArrayObjectsToCamel(
            action.options.ServiceOrderStates
          ),
          difs: mapArrayObjectsToCamel(action.options.Difs),
          bras: mapArrayObjectsToCamel(action.options.Bras),
          marketingAreas: action.options.MarketingAreas.map((area) => ({
            code: area.Code,
            caption: area.Caption,
          })),
        },
      };

    case "RECEIVE_HOUSING_DATA_OPTIONS":
      return {
        ...state,
        housingDataOptionsLoaded: true,
        housingDataOptions: {
          HousingAreas: action.options.HousingAreas,
          ServiceAreas: action.options.ServiceAreas,
          FAISSWarningHtml: action.options.FAISSWarningHtml,
          SUMIBWarningHtml: action.options.SUMIBWarningHtml,
          housingCategories: action.options.HousingApplicantCategoryOptions.map(
            (option) => ({
              label: option.Value,
              value: option.Key,
            })
          ),
          quarterCategories: action.options.QuarterApplicantCategoryOptions.map(
            (option) => ({
              label: option.Value,
              value: option.Key,
            })
          ),
          childRelationOptions: mapArrayObjectsToCamel(
            action.options.ChildRelationOptions
          ),
          maritalStatusOptions: mapArrayObjectsToCamel(
            action.options.MaritalStatusOptions
          ),
          livingConditionOptions: mapArrayObjectsToCamel(
            action.options.LivingConditionOptions
          ),
          assignmentDocumentationOptions:
            action.options.AssignmentDocumentationOptions.map((option) => ({
              label: option.Value,
              value: option.Key,
            })),
          defaultDocumentationDeadline:
            action.options.AssignmentDocumentationReceivedDeadline,
        },
      };

    case "RECEIVE_MY_DIFS":
      return {
        ...state,
        myDifsLoaded: true,
        myDifs: action.myDifs.map((dif) => ({
          key: dif.Key,
          value: dif.Value,
          bras: mapArrayObjectsToCamel(dif.Bras),
        })),
      };

    case "RECEIVE_DROPZONE_OPTIONS":
      return {
        ...state,
        dropzoneOptionsLoaded: true,
        dropzoneOptions: {
          Filetypes: action.dropzoneOptions.types,
          FileSize: action.dropzoneOptions.fileSize,
        },
      };

    default:
      return state;
  }
};
