import { CSSProperties, FC, useEffect, useState } from 'react';
import * as I from 'components/BasenIcon';
import { v4 as uuidv4 } from 'uuid';
import Image from './Image';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import Modal from 'components/ModalComponents/Modal';
import React from 'react';
import Button from 'components/buttons/Button/Button';
import { ImageResponse, ImageType } from 'types/imageTypes';
import './Image.scss';

interface IImageCarouselPropsType {
  images: ImageResponse[];
  withThumbnails?: boolean;
  className?: string;
  style?: CSSProperties;
}
const ImageCarousel: FC<IImageCarouselPropsType> = ({
  images,
  className = '',
  withThumbnails = true,
}) => {
  const [mappedImages, setMappedImages] = useState<ImageType[]>([]);
  const [activeImage, setActiveImage] = useState<ImageType>(mappedImages[0]);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    setMappedImages(mapImages());
    setActiveImage(mapImages()[0]);
  }, [images]);

  const mapImages = () => {
    return images.map((image, index) => {
      return { imageIndex: index + 1, imageSource: image } as ImageType;
    });
  };

  const selectActiveImage = (image: ImageType) => {
    setActiveImage(image);
  };

  const renderImages = () => {
    return mappedImages
      .filter((image) => image.imageIndex == activeImage.imageIndex)
      .map((image) => (
        <Image key={image.imageIndex} image={image.imageSource} />
      ));
  };

  const thumbnailRange = () => {
    const totalPageCount = Math.ceil(images.length);
    const siblingCount = 2;
    const maxElementCount = siblingCount + 5; // Max 7 elements

    const getRange = (start: number, end: number) => {
      const length = end - start + 1;
      return Array.from({ length }, (_, index) => index + start);
    };

    if (maxElementCount >= totalPageCount) {
      return getRange(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(activeImage.imageIndex - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      activeImage.imageIndex + siblingCount,
      totalPageCount,
    );

    const shouldShowLeftDots = leftSiblingIndex > 1;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 1 + 2 * siblingCount;
      const leftRange = getRange(1, leftItemCount);

      return ['prevArrow', ...leftRange, 'nextArrow'];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 1 + 2 * siblingCount;
      const rightRange = getRange(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );
      return ['prevArrow', ...rightRange, 'nextArrow'];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = getRange(leftSiblingIndex, rightSiblingIndex);
      return ['prevArrow', ...middleRange, 'nextArrow'];
    }
    return [];
  };

  const renderThumbnailsWithSides = () => {
    return (
      <>
        {thumbnailRange().map((rangeItem) => {
          if (rangeItem == 'prevArrow') {
            return (
              <div
                key="prev_image_arrow"
                className={`prev-image ${activeImage.imageIndex == 1 && 'hidden-button'}`}
                onClick={handleGetPrevImage}
              >
                <BasenIcon icon={I.LeftIcon} />
              </div>
            );
          }
          if (rangeItem == 'nextArrow') {
            return (
              <div
                key="next_image_arrow"
                className={`next-image ${activeImage.imageIndex == images.length && 'hidden-button'}`}
                onClick={handleGetNextImage}
              >
                <BasenIcon icon={I.RightIcon} />
              </div>
            );
          }
          return (
            <div
              onClick={() =>
                selectActiveImage(mappedImages[Number(rangeItem) - 1])
              }
              key={`thumbnail_${uuidv4()}`}
              className="image-container"
            >
              <Image
                key={`thumbnail_image${rangeItem}`}
                image={mappedImages[Number(rangeItem) - 1].imageSource}
                className={
                  activeImage && activeImage.imageIndex == rangeItem
                    ? 'active'
                    : ''
                }
              />
            </div>
          );
        })}
      </>
    );
  };

  const handleGetPrevImage = () => {
    if (activeImage.imageIndex != 1) {
      selectActiveImage(mappedImages[activeImage.imageIndex - 2]);
    }
  };
  const handleGetNextImage = () => {
    if (activeImage.imageIndex != images.length) {
      selectActiveImage(mappedImages[activeImage.imageIndex]);
    }
  };

  const renderThumbnails = () => {
    if (mappedImages.length > 5 && activeImage) {
      return renderThumbnailsWithSides();
    } else {
      return mappedImages.map((image, index) => (
        <div
          onClick={() => selectActiveImage(image)}
          key={`thumbnail_${index}`}
          className="image-container"
        >
          <Image
            key={`thumbnail_image${image.imageIndex}`}
            image={image.imageSource}
            className={
              activeImage && activeImage.imageIndex == image.imageIndex
                ? 'active'
                : ''
            }
          />
        </div>
      ));
    }
  };

  const renderModal = () => {
    return (
      <div className="modal-gallery">
        <Button
          className="secondary"
          style={{ border: 'none', alignSelf: 'end' }}
          icon={I.CloseIcon}
          onClick={() => setShowModal(false)}
        >
          Lukk
        </Button>
        <div className="gallery">
          <div
            className={`prev-image ${activeImage.imageIndex == 1 && 'hidden-button'}`}
            onClick={handleGetPrevImage}
          >
            <BasenIcon icon={I.LeftIcon} />
          </div>
          {renderImages()}
          <div
            key="next_image_arrow"
            className={`next-image ${activeImage.imageIndex == images.length && 'hidden-button'}`}
            onClick={handleGetNextImage}
          >
            <BasenIcon icon={I.RightIcon} />
          </div>
        </div>
        {withThumbnails && (
          <div className="thumbnails">{renderThumbnails()}</div>
        )}
      </div>
    );
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    const key = e.key;
    switch (key) {
      case 'ArrowRight':
        handleGetNextImage();
        break;
      case 'ArrowLeft':
        handleGetPrevImage();
        break;
    }
  };

  return (
    <div
      className={`image-carousel ${className}`}
      onKeyUp={(e) => handleKeyUp(e)}
      tabIndex={0}
    >
      {images && (
        <div className="initial-image">
          <Image image={images[0]} />
          <Button
            className="secondary show-images-button"
            onClick={() => setShowModal(true)}
          >
            Vis alle bilder
          </Button>
        </div>
      )}
      {showModal && (
        <Modal
          className="image-modal"
          header={''}
          content={renderModal()}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default ImageCarousel;
