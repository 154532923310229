import './Summary.scss';

import Badge from 'components/Badge/Badge';
import { FC } from 'react';
import { GetBookingResponse } from 'types/bookingRequests';

import Summary, { SummarySection } from './Summary';

interface IBookingSummaryPropsType {
  booking: GetBookingResponse;
}

export const formatFromToDate = (startDate: Date, endDate: Date) => {
  return `${startDate.toLocaleDateString('no', {
    month: 'long',
    day: '2-digit',
  })}-${endDate.toLocaleDateString('no', { year: 'numeric', month: 'long', day: '2-digit' })}`;
};

const formatDateTime = (date: Date) => {
  return `${date.toLocaleDateString('no', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};

const BookingSummary: FC<IBookingSummaryPropsType> = ({ booking }) => {
  const mapBookingToSummary = () => {
    return [
      { header: 'Sted', content: booking.locationName, columnSpan: 1 },
      {
        content: (
          <Badge
            className="badge-notification"
            variant={booking.paymentInfo.statusInfo.status}
          >
            {booking.paymentInfo.statusInfo.text ?? 'Mangler beskrivelse'}
          </Badge>
        ),
        columnSpan: 1,
        className: 'bookingStatus',
      },
      {
        header: 'Dato',
        content: formatFromToDate(
          new Date(booking.arrivalDate),
          new Date(booking.departureDate),
        ),
        columnSpan: 2,
      },
      { header: 'Beskrivelse', content: booking.description, columnSpan: 2 },
      { header: 'Formål', content: booking.purpose },
      { header: 'Antall rom', content: booking.rooms?.length },
      {
        header: 'Bestillingsdato',
        content: formatDateTime(new Date(booking.bookingCreatedDate)),
      },
      { header: 'BookingID', content: booking.bookingIdentifier },
      { header: 'Betalingsmetode', content: booking.paymentInfo.paymentMethod },
      { header: 'Pris', content: `${booking.price},-` },
    ] as SummarySection[];
  };

  const renderBookingSummary = () => {
    return <Summary sections={mapBookingToSummary()} />;
  };

  return renderBookingSummary();
};

export default BookingSummary;
