import HousingApplicationsList from './HousingApplicationsList';

const TabMyHousingApplications = () => (
  <div className="max-width-large" style={{ padding: '22px 0 0 0' }}>
    <h2>Mine bolig- og kvartersøknader</h2>
    <HousingApplicationsList />
  </div>
);

export default TabMyHousingApplications;
