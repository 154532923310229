import { ModalTypes } from 'constants/modalTypes';
import { useModal } from 'contexts/index';

import ActionModal from './ActionModal/ActionModal';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import ExportListModal from './ExportListModal/ExportListModal';
import GetModal from './GetModal/GetModal';
import ListModal from './ListModal/ListModal';
import ShareModal from './ShareModal/ShareModal';

const MainModal = ({ modalData }) => {
  const { show, setShow } = useModal();

  switch (modalData.modalType) {
    case ModalTypes.GetModal:
      return <GetModal {...modalData} isOpen={show} />;
    case ModalTypes.Share:
      return (
        <ShareModal
          title={modalData.title}
          html={modalData.html}
          infoText={modalData.infoText}
          size={modalData.size}
          isOpen={show}
          onOpen={modalData.onOpen}
          // submitText={modalData.submitText}
          // submitAction={modalData.submitAction}
          // cancelText={modalData.cancelText}
          // cancelAction={modalData.cancelAction}
          // flipActionOrder={modalData.flipActionOrder}
          onClose={() => setShow(false)}
          attr={modalData.attr}
        />
      );
    case ModalTypes.CustomForm:
      return (
        <ActionModal
          title={modalData.title}
          html={modalData.html}
          infoText={modalData.infoText}
          size={modalData.size}
          isOpen={show}
          submitText={modalData.submitText}
          submitAction={modalData.submitAction}
          cancelText={modalData.cancelText}
          cancelAction={modalData.cancelAction}
          flipActionOrder={modalData.flipActionOrder}
          onClose={() => setShow(false)}
        />
      );
    case ModalTypes.ExportList:
      return (
        <ExportListModal
          title={modalData.title}
          fields={modalData.fields}
          isOpen={show}
          submitText={modalData.submitText}
          submitAction={modalData.submitAction}
          cancelText={modalData.cancelText}
          cancelAction={modalData.cancelAction}
          onClose={() => setShow(false)}
        />
      );
    case ModalTypes.SearchableList:
      return (
        <ListModal
          title={modalData.title}
          // size={modalData.size}
          isOpen={show}
          data={modalData.data}
          submitAction={modalData.submitAction}
          onClose={() => setShow(false)}
        />
      );

    default:
      return (
        <ConfirmationModal
          title={modalData.title}
          html={modalData.html}
          infoText={modalData.infoText}
          size={modalData.size}
          isOpen={show}
          onOpen={modalData.onOpen}
          onClose={() => {
            if (modalData.onClose) modalData.onClose();
            setShow(false);
          }}
        />
      );
  }
};

export default MainModal;
