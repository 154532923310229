import { KeyValueType } from "types/commonTypes";

import Select from "../Select/Select";

type PropsType = {
  name: string;
  value?: string;
  disabled: boolean;
  buildingTypes?: Array<KeyValueType>;
  buildingTypesLoaded: boolean;
  onChange: (name: string, value: number | string) => void;
};

const BuildingTypeSelect = (props: PropsType) => (
  <Select
    onChange={props.onChange}
    value={props.value}
    disabled={props.disabled || !props.buildingTypes}
    options={props.buildingTypes}
    loading={!props.buildingTypesLoaded}
    name={props.name}
    showAllOption
  />
);

export default BuildingTypeSelect;
