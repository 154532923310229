import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import env from 'env';
import { useCallback, useState } from 'react';

/**
 * @typedef {Object} SendRequestParams
 * @property {any} axiosParams - Params for the request
 * @property {(any)=> void} applyData - What to do with the result
 * @property {any} user - Optional user object
 */

/**
 * @typedef {Object} useAxios
 * @property {string} requestError - Error message if request fails
 * @property {boolean} requestLoading - Loading status of the request
 * @property {(SendRequestParams)} sendRequest - Function for sending the request
 * (Runs applyData on success)
 */

/**
 * The hooks takes no props, but acts as a setup for the real work which is done in sendRequest.
 * sendRequest does all the work, as well as applying the result of the request to a given
 * function.
 *
 * @example <caption>Example usage of useAxios.</caption>
 * const { requestError, requestLoading, sendRequest } = useAxios();
 * @returns {useAxios}
 */

export const getNewJwtToken = async () => {
  const url = `${env.BASE_URL}/auth/RefreshJwt`;
  try {
    const result = await axiosFetch(
      { url, withCredentials: true },
      null,
      true,
      false,
      false,
    );

    return result.startsWith('<!DOCTYPE html>') ? null : result;
  } catch (error) {
    if (
      error &&
      error.response &&
      (error.response.status === 401 || error.response.status === 404)
    ) {
      // NOTHING?!
    } else if (!error.status) {
      // Network error!
      return -1;
    }
    return null;
  }
};

export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    let jsonPayload;

    // Use atob if in a browser environment
    jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload).exp;
  } catch (e) {
    return null;
  }
};

export const checkForNewJwtToken = async () => {
  let attempts = 1;
  const maximumAttempts = 20;
  return new Promise((resolve, reject) => {
    const id = setInterval(() => {
      const token = localStorage.getItem('jwtToken');
      if ((token && token !== 'refreshing') || attempts > maximumAttempts) {
        clearInterval(id);
        resolve(token);
      } else if (attempts < maximumAttempts) {
        attempts += 1;
      } else {
        clearInterval(id);
        reject();
      }
    }, 100);
  });
};

const dispatchJwtTokenChangedEvent = () => {
  window.dispatchEvent(new Event('storage_jwtToken'));
};

const handleUserToken = async () => {
  const token = localStorage.getItem('jwtToken');

  if (token === 'refreshing') {
    try {
      const newToken = await checkForNewJwtToken();
      return newToken;
    } catch (e) {
      localStorage.removeItem('jwtToken');
      dispatchJwtTokenChangedEvent();
      return null;
    }
  }
  const expireDate = parseJwt(token);

  if (moment.utc(expireDate * 1000).isBefore(moment.utc()) || !token) {
    localStorage.setItem('jwtToken', 'refreshing');
    dispatchJwtTokenChangedEvent();

    let newJwtToken = await getNewJwtToken();
    let iteration = 0;
    while (newJwtToken === -1 && iteration < 5) {
      iteration++;

      // eslint-disable-next-line no-await-in-loop
      await delay(1000 * iteration);

      // eslint-disable-next-line no-await-in-loop
      newJwtToken = await getNewJwtToken();
    }

    if (!newJwtToken || newJwtToken === -1) {
      localStorage.removeItem('jwtToken');
      dispatchJwtTokenChangedEvent();
      return null;
    }

    localStorage.setItem('jwtToken', newJwtToken);
    dispatchJwtTokenChangedEvent();

    return newJwtToken;
  }
  return token?.replace(/"/g, '');
};

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const useAxios = () => {
  const [requestLoading, setLoading] = useState(false);
  const [requestError, setError] = useState('');

  const sendRequest = useCallback(
    // eslint-disable-next-line no-unused-vars
    async (axiosParams, applyData, user = null, onError = (error) => {}) => {
      const jwtToken = await handleUserToken(user);

      axiosParams = { ...axiosParams, withCredentials: true };

      if (jwtToken)
        axiosParams.headers = {
          ...axiosParams.headers,
          ...{
            Authorization: `Bearer ${jwtToken}`,
            'X-Requested-With': 'XMLHttpRequest',
          },
        };

      if (
        axiosParams.method !== 'POST' &&
        axiosParams.method !== 'PUT' &&
        axiosParams.method !== 'PATCH' &&
        axiosParams.data !== undefined
      ) {
        axiosParams.params = axiosParams.data;
        axiosParams.data = {};
      } else if (axiosParams.data === undefined) {
        axiosParams.data = {};
      }

      setLoading(true);
      setError('');

      try {
        const result = await axios.request(axiosParams);
        if (result.data?.Data) {
          if (applyData) applyData(result.data.Data);
        } else if (applyData) applyData(result.data);
      } catch (error) {
        onError(error);
        setError(error.response?.data);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const sendRequests = useCallback(async (requests, user = null) => {
    const jwtToken = await handleUserToken(user);

    setLoading(true);
    setError('');

    const axiosRequests = requests.map((r) => {
      r.axiosParams.headers = {
        Authorization: `Bearer ${jwtToken}`,
        'X-Requested-With': 'XMLHttpRequest',
      };
      return axios.request(r.axiosParams);
    });

    await axios
      .all(axiosRequests)
      .then(
        axios.spread((...responses) => {
          responses.forEach((respons) => {
            const apply = requests.find(
              (req) => req.axiosParams.url === respons.config.url,
            ).applyData;
            apply(respons.data);
          });
        }),
      )
      .catch((errors) => {
        setError(errors);
      });
    setLoading(false);
  }, []);

  return { requestError, requestLoading, sendRequest, sendRequests };
};

export const axiosFetch = async (
  axiosParams,
  user = null,
  returnData = true,
  useParamSerializer = false,
  requireToken = true,
) => {
  axiosParams = { ...axiosParams, ...{ withCredentials: true } };

  let jwtToken = null;

  if (requireToken) {
    jwtToken = await handleUserToken(user);
    axiosParams.headers = {
      ...axiosParams.headers,
      ...{
        Authorization: `Bearer ${jwtToken}`,
        'X-Requested-With': 'XMLHttpRequest',
      },
    };
  } else {
    axiosParams.headers = {
      ...axiosParams.headers,
    };
  }

  if (
    axiosParams.method !== 'POST' &&
    axiosParams.method !== 'PUT' &&
    axiosParams.data !== undefined
  ) {
    axiosParams.params = axiosParams.data;
    axiosParams.data = {};
  } else if (axiosParams.data === undefined) {
    axiosParams.data = {};
  }

  if (useParamSerializer) {
    axiosParams.paramsSerializer = (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    };
  }

  return axios
    .request(axiosParams)
    .then((result) => {
      if (result.data.Success === false) {
        if (!returnData) {
          return result;
        }
        return result.data;
      }
      if (!returnData) {
        return result;
      }
      if (result.data.Data) {
        return result.data.Data;
      }

      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export default useAxios;
