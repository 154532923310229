import { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import "./ListModal.scss";
import { DeliberateAny } from "../../../types/DelibrateAny";

type PropsType = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  data: Array<DeliberateAny>;
  submitAction: (val: DeliberateAny) => void;
};

const ListModal = ({
  title,
  isOpen,
  onClose,
  data,
  submitAction,
}: PropsType) => {
  const [filterValue, setFilterValue] = useState("");
  const [filteredResults, setFilteredResults] = useState<DeliberateAny[]>();

  useEffect(() => {
    setFilteredResults(data);
  }, [data]);

  const filterResults = (val: DeliberateAny) => {
    const ReducedArr = val
      .split(" ")
      .reduce((acc: DeliberateAny, searchTerm: string) => {
        return acc.filter((name: DeliberateAny) => {
          return name.Value.toLowerCase().match(searchTerm);
        });
      }, data);
    setFilteredResults(ReducedArr);
    setFilterValue(val);
  };

  const handleClick = (event: DeliberateAny) => {
    let obj;
    if (event) {
      obj = data.find((o) => o.Key === event.target.id);
    } else {
      obj = {};
    }
    submitAction(obj);
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      ariaLabel={title}
      size="large"
      // overrideButtonRow
      scrolling
    >
      <header className="modal-header">
        <h2>{title}</h2>
        <input
          placeholder={
            data ? `Søk blant ${data.length} navn` : "Laster inn navn..."
          }
          type="text"
          value={filterValue}
          onChange={(event) => filterResults(event.target.value)}
        />
      </header>
      <div className="modal-body">
        {filteredResults ? (
          <div className="people-picker">
            <ul>
              <li id="show-all" onClick={handleClick}>
                Vis alle
              </li>
              {filteredResults.map((obj) => (
                <li
                  key={obj.Key}
                  id={obj.Key}
                  onClick={handleClick}
                  value={obj.Value}
                >
                  {obj.Value}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="loading">
            <p>
              Laster inn navn.
              <br />
              Dette kan ta noen sekunder.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ListModal;
