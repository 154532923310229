import moment from 'moment';

export const calculateSplitValues = (
  arriveDeparture,
  roomCounts: { [key: string]: number },
  roomInfo,
) => {
  const updatedSplitValues = { nights: 0, price: 0, rooms: 0 };

  if (arriveDeparture?.length === 2) {
    updatedSplitValues.nights = moment(arriveDeparture?.[1]?.toDate()).diff(
      moment(arriveDeparture?.[0]?.toDate()),
      'days',
    );
  }

  if (roomCounts) {
    updatedSplitValues.rooms = Object.values(roomCounts).reduce(
      (sum: number, count: number) => sum + count,
      0,
    );
  }

  if (roomInfo && roomCounts) {
    updatedSplitValues.price = Object.keys(roomCounts).reduce((sum, id) => {
      const count = roomCounts[id];
      const price = roomInfo.find((r) => r.roomType.roomTypeId === id)?.roomType
        .price;
      return sum + (price ?? 0) * count;
    }, 0);
  }

  return updatedSplitValues;
};
