import { getContact } from 'api/cms/cms_api';
import Loader from 'components/Loader/Loader';
import DOMPurify from 'dompurify';
import useFetch from 'hooks/useFetch';

const Contact = () => {
  const { isLoading, data: contact } = useFetch<string | Node>(getContact);

  const processContact = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Locate all `a` tags within `.contact-point-wrapper`
    const anchorTags = doc.querySelectorAll('.contact-point-wrapper a');
    anchorTags.forEach((anchorTag) => {
      if (
        anchorTag instanceof HTMLAnchorElement &&
        anchorTag.innerText.trim() === 'Chat'
      ) {
        anchorTag.removeAttribute('href');
        anchorTag.setAttribute(
          'onClick',
          "document.querySelector('.startBubble-0-0-1').click()",
        );
      }
    });

    return doc.body.innerHTML;
  };

  const sanitizedContact = contact
    ? processContact(DOMPurify.sanitize(contact))
    : null;

  return (
    <Loader isLoading={isLoading}>
      {sanitizedContact && (
        <div className="app-root contact">
          <span dangerouslySetInnerHTML={{ __html: sanitizedContact }} />
        </div>
      )}
    </Loader>
  );
};

export default Contact;
