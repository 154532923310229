import React from "react";
// import type { DetailsButtonType } from "types/listTypes";
import "./DetailsButton.scss";

const DetailsButton = (props) => {
  const Element = props.elementType ? props.elementType : "button";
  let style;
  if (props.icon) {
    style = props.icon;
  } else if (props.expanded) {
    style = "close";
  } else {
    style = "open";
  }

  const onClick = (event) => {
    event.preventDefault();
    props.onClick();
  };

  return (
    <div className={`details-button ${props.margin ? "margin-right" : ""}`}>
      <Element
        onClick={onClick}
        className={style}
        href="#"
        aria-expanded={props.expanded}
      >
        {props.title ? props.title : "Vis informasjon"}
      </Element>
    </div>
  );
};

export default DetailsButton;
