import './NavigationLink.scss';

import { RightIcon } from 'components/BasenIcon';
import BasenIcon from 'components/BasenIcon/BasenIcon';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface INavigationLink {
  title: string;
  href: string;
}

interface INavigationClickLink {
  title: string;
  onClick: () => void;
}

const isNavigationClickLink = (
  props: INavigationLink | INavigationClickLink,
): props is INavigationClickLink => {
  return (props as INavigationClickLink).onClick !== undefined;
};

const NavigationLink: FC<INavigationLink | INavigationClickLink> = (props) => {
  const { title } = props;
  const navigate = useNavigate();
  return (
    <div
      className="fb-nav-link pointer"
      onClick={() => {
        if ('href' in props && props.href) {
          navigate(props.href);
        } else if (isNavigationClickLink(props) && props.onClick) {
          props.onClick();
        }
      }}
    >
      <div className="fb-nav-link-header">
        <div className="fb-nav-link-text subtitle-xs">{title}</div>
        <BasenIcon icon={RightIcon} />
      </div>
    </div>
  );
};

export default NavigationLink;
