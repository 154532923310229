import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { DeliberateAny } from 'types/DelibrateAny';

import { l3URL } from '../../api/basenproff/basenproff_api';
import Loader from '../../components/Loader/Loader';
import useAxios from '../../hooks/useAxios';
import L3Filter from './L3Filter';
import L3Items from './L3Items';

const L3 = () => {
  const { requestLoading, sendRequest } = useAxios();
  const [l3Values, setL3Values] = useState<DeliberateAny>();

  // Filter
  const [subject, setSubject] = useState();
  const [tag, setTag] = useState();
  const [q, setQ] = useState<DeliberateAny>();
  const [qValue, setQValue] = useState('');

  const [u] = useState(new Date());

  useDebounce(
    () => {
      setQValue(q);
    },
    1500,
    [q],
  );

  const [filteredItems, setFilteredItems] = useState([]);

  const getL3Values = async () => {
    await sendRequest(
      {
        method: 'GET',
        url: l3URL,
      },
      (r) => {
        setL3Values(r);
      },
    );
  };

  const filterItems = (filterSubject, filterTag, filterQ) => {
    if (!l3Values?.ListItems) return;

    if (
      (!filterSubject || filterSubject === -1) &&
      (!filterTag || filterTag === -1) &&
      (!filterQ || filterQ === '')
    ) {
      setFilteredItems(l3Values.ListItems);
      return;
    }

    let output = l3Values.ListItems;

    if (filterSubject && filterSubject !== 'favs' && filterSubject !== -1)
      output = output.filter((i) => i.Subject === filterSubject);

    if (filterSubject === 'favs')
      output = output.filter((i) => i.L3Extra.IsFavorite);

    if (filterTag && filterTag !== -1)
      output = output.filter((i) =>
        i.L3Extra?.Tags?.includes(filterTag.toString()),
      );

    if (filterQ)
      output = output.filter((i) => {
        const regex = new RegExp(`\\b${qValue}\\w*`, 'gi');
        return (
          i.Details.match(regex) ||
          i.Example.match(regex) ||
          i.Name.match(regex)
        );
      });

    setFilteredItems(output);
  };

  useEffect(() => {
    if (!subject || subject === -1 || subject === 'favs') getL3Values();
  }, [u, subject]);

  useEffect(() => {
    filterItems(subject, tag, qValue);
  }, [subject, tag, qValue, l3Values?.ListItems]);

  return (
    <div className="l3-content">
      <Loader isLoading={requestLoading}>
        {l3Values && (
          <div>
            <h1>{l3Values.Heading}</h1>
            <p>
              <strong>{l3Values.MainIntro}</strong>
            </p>
            <p dangerouslySetInnerHTML={{ __html: l3Values.MainBody }} />
            <div className="divider" />
            <L3Filter
              subject={subject}
              tag={tag}
              q={q}
              setSubject={setSubject}
              setTag={setTag}
              setQ={setQ}
              items={l3Values.ListItems}
            />
            <L3Items items={filteredItems} />
          </div>
        )}
      </Loader>
    </div>
  );
};

export default L3;
