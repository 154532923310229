import { mapArrayObjectsToCamel } from "helpers/commonReducerHelpers";

const initialState = {
  rentals: {
    items: [],
    isLoading: true,
    receiptInspection: [],
  },
  rentalContractTextsLoaded: false,
  rentalContractTexts: {},
  rejectionReasonsLoaded: false,
  rejectionReasons: [],
  documentLinksLoaded: false,
  documentLinks: {},
  rentalCommentBOKSent: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "RECEIVE_MY_RENTALS": {
      const rentalId = action.rentalId;
      const numItems = action.data.length;

      const items = action.data.map((item) => ({
        ...item,
        expandMe: item.RentalId === rentalId || numItems === 1,
      }));

      return {
        ...state,
        rentals: {
          items,
          isLoading: false,
          receiptInspection: [...state.rentals.receiptInspection],
        },
      };
    }

    case "RECEIVE_RECEIPT_INSPECTION": {
      const rentalId = action.rentalId;
      const pushReceipt = {
        InspectionAction: "inspection_time_sent",
        RentalId: rentalId,
        IsSuccess: true,
      };

      return {
        ...state,
        rentals: {
          ...state.rentals,
          receiptInspection: [...state.rentals.receiptInspection, pushReceipt],
        },
      };
    }

    case "RESET_MY_RENTALS_EXPAND_ME": {
      const updatedItems = state.rentals.items.map((item) => ({
        ...item,
        expandMe: false,
      }));

      return {
        ...state,
        rentals: {
          ...state.rentals,
          items: updatedItems,
          receiptInspection: [...state.rentals.receiptInspection],
        },
      };
    }

    case "RECEIVE_RENTAL_CONTRACT_TEXTS": {
      return {
        ...state,
        rentalContractTexts: action.rentalContractTexts,
        rentalContractTextsLoaded: true,
      };
    }

    case "BOOK_COMMENT_SENT_SUCCESS": {
      return {
        ...state,
        rentalCommentBOKSent: true,
      };
    }

    case "RECEIVE_REJECTION_REASONS": {
      return {
        ...state,
        rejectionReasonsLoaded: true,
        rejectionReasons: mapArrayObjectsToCamel(action.data),
      };
    }

    case "RECEIVE_REJECT_SIGNING": {
      const documentId = action.documentId;
      const rejectionReason = action.rejectionReason;
      const updatedItems = state.rentals.items.map((item) => ({
        ...item,
        Documents: item.Documents.map((doc) => ({
          ...doc,
          State: doc.DocumentId === documentId ? rejectionReason : doc.State,
        })),
      }));

      return {
        ...state,
        rentals: {
          ...state.rentals,
          items: updatedItems,
          receiptInspection: [...state.rentals.receiptInspection],
        },
      };
    }

    case "RECEIVE_RENTAL_DOCUMENT_LINKS": {
      return {
        ...state,
        documentLinks: action.documentLinks,
        documentLinksLoaded: true,
      };
    }

    default:
      return state;
  }
};
