/* eslint react/prop-types: 0 */

import { getTotalNumberOfRooms } from 'helpers/bookingHelpers';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import GuestList from './GuestList';
import RoomFacilities from './RoomFacilities';
import { validateFields } from './ValidationFunctions';

const RoomInfoWrapper = (
  {
    numberOfRooms,
    setNumberOfRooms,
    availableRooms,
    roomInfo,
    setRoomInfo,
    isBlockBooking,
    onSubmit,
    noGuestList,
    givenPriceList,
    userData,
    showModal,
  },
  ref,
) => {
  const [isSwitching, setIsSwitching] = useState(false);
  const [switchFrom, setSwitchFrom] = useState({
    Id: '',
    Switch: [false, false],
  });
  const [validField, setValidField] = useState(
    roomInfo.map((room) => ({
      id: room.Id,
      guests:
        room.NameListInfo.NameListItems &&
        room.NameListInfo.NameListItems.map(() => ({
          firstName: true,
          lastName: true,
          phone: true,
          email: true,
        })),
    })),
  );

  const [priceList, setCurrentPriceList] = useState(givenPriceList);

  const formatPhone = () => {
    if (userData.CountryCode.indexOf('+') >= 0) {
      return userData.CountryCode + userData.Phone;
    }
    return `+${userData.CountryCode + userData.Phone}`;
  };

  const getEmail = () => {
    if (userData.Email) return userData.Email;
    return '';
  };

  const updateRoomReservation = (name, number) => {
    let newRoomInfo = [...roomInfo];
    const totalNumberOfRooms = getTotalNumberOfRooms(numberOfRooms);
    const findRoomNumber = numberOfRooms.find((room) => room.name === name);
    const VBPriceId =
      availableRooms.find((room) => room.Name === name).PriceList &&
      availableRooms.find((room) => room.Name === name).PriceList[0].PriceId;
    const VBProductId =
      availableRooms.find((room) => room.Name === name).VBRoomTypeId &&
      availableRooms.find((room) => room.Name === name).VBRoomTypeId;
    const priceListForRoom = availableRooms.find(
      (room) => room.Name === name,
    ).PriceList;
    setCurrentPriceList(priceListForRoom);
    if (findRoomNumber.count < number) {
      for (let i = 0; i < number - findRoomNumber.count; i += 1) {
        newRoomInfo.push({
          Id: uuidv4(),
          AdditionalBed: false,
          VBPriceId,
          RoomTypeId: findRoomNumber.id,
          RoomTypeName: findRoomNumber.name,
          NameListInfo: {
            NameListItems: isBlockBooking
              ? []
              : [
                  {
                    FirstName:
                      totalNumberOfRooms === 0 && !noGuestList
                        ? userData.FirstName
                        : '',
                    LastName:
                      totalNumberOfRooms === 0 && !noGuestList
                        ? userData.LastName
                        : '',
                    Phone:
                      totalNumberOfRooms === 0 && !noGuestList
                        ? formatPhone()
                        : '+47',
                    Email:
                      totalNumberOfRooms === 0 && !noGuestList
                        ? getEmail()
                        : '',
                  },
                ],
          },
          Requirements: '',
          VBProductId,
          Name: findRoomNumber.name,
          Index: roomInfo.length,
        });
      }
    } else if (findRoomNumber.count > number) {
      const roomTypes = newRoomInfo.filter(
        (room) => room.RoomTypeName === findRoomNumber.name,
      );
      for (let i = 0; i < findRoomNumber.count - number; i += 1) {
        roomTypes.pop();
      }
      newRoomInfo = newRoomInfo.filter(
        (roomType) => roomType.RoomTypeName !== findRoomNumber.name,
      );
      newRoomInfo = [...newRoomInfo].concat([...roomTypes]);
    }
    setRoomInfo(newRoomInfo);
    setValidField(
      newRoomInfo.map((room) => ({
        id: room.Id,
        guests:
          room.NameListInfo.NameListItems &&
          room.NameListInfo.NameListItems.map(() => ({
            firstName: true,
            lastName: true,
            phone: true,
            email: true,
          })),
      })),
    );
  };

  const handleChange = (name, number) => {
    setNumberOfRooms(
      [...numberOfRooms].map((room) => {
        return room.name === name ? { ...room, count: number } : room;
      }),
    );
    updateRoomReservation(name, number);
  };

  const handleSubmit = () => {
    const valid = validateFields(
      roomInfo,
      setValidField,
      isBlockBooking,
      noGuestList,
      showModal,
    );
    if (valid) {
      onSubmit();
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      handleSubmit();
    },
  }));

  return (
    <div
      key={roomInfo.Id}
      className={
        isSwitching
          ? 'guest-list-container margin is-switching'
          : 'guest-list-container margin'
      }
    >
      {availableRooms &&
        availableRooms.map((roomType, i) => (
          <div
            key={roomType.Id + '_' + i}
            className={
              !isBlockBooking &&
              numberOfRooms.find(
                (room) => room.RoomTypeId === roomType.RoomTypeId,
              )?.count > 0
                ? 'room has-rooms'
                : 'room'
            }
            style={{ width: '100%' }}
          >
            <div className="room-wrapper double-margin">
              <RoomFacilities
                room={roomType}
                numberOfRooms={
                  numberOfRooms.find((room) => room.id === roomType.RoomTypeId)
                    ?.count
                }
                handleChange={handleChange}
              />
              {!isBlockBooking && (
                <GuestList
                  roomInfo={roomInfo}
                  roomArray={roomInfo.filter(
                    (room) => room.RoomTypeId === roomType.RoomTypeId,
                  )}
                  setRoomInfo={setRoomInfo}
                  numberOfRooms={numberOfRooms}
                  setNumberOfRooms={setNumberOfRooms}
                  simplified={false}
                  switchFrom={switchFrom}
                  setSwitchFrom={setSwitchFrom}
                  validField={validField}
                  setValidField={setValidField}
                  isSwitching={isSwitching}
                  setIsSwitching={setIsSwitching}
                  availableRoom={roomType}
                  priceList={priceList}
                />
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default forwardRef(RoomInfoWrapper);
