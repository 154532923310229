import './BookingPaymentInfo.scss';

import {
    reservationConfirmWithDelayedPaymentUrl, reservationConfirmWithPaymentProviderUrl,
    reservationUpdateByIdUrl
} from 'api/booking/booking_api_v2';
import AlertMessage, { AlertType } from 'components/AlertMessage/AlertMessage';
import BookingSearch from 'components/BookingComponents/BookingSearch';
import PaymentInfo from 'components/BookingComponents/PaymentInfo';
import { ValueOption } from 'components/FormComponents/FormPropBase';
import Loader from 'components/Loader/Loader';
import ConfirmWithInvoiceModal from 'components/ModalComponents/ConfirmWithInvoiceModal';
import ConfirmWithIoModal from 'components/ModalComponents/ConfirmWithIoModal';
import PurposeSelect from 'components/ModalComponents/PurposeSelect';
import SmsNotificationCheckbox from 'components/SmsNotificationCheckbox/SmsNotificationCheckbox';
import { useNewBooking } from 'contexts/index';
import { addIFrameToBody } from 'helpers/paymentHelpers';
import { labelYupError } from 'helpers/yupLocales';
import useAxios from 'hooks/useAxios';
import { useMutex } from 'hooks/useMutex';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeliberateAny, SetState } from 'types/DelibrateAny';
import Yup from 'utils/yupExtensions';

interface IBookingPaymentInfoProps {
  pressedOrderButton: boolean;
  setPressedOrderButton: SetState<boolean>;
}

const fieldNames = {
  purposeOption: 'Oppholdets formål',
  purposeDescription: 'Beskrivelse',
  paymentOption: ' ',
};

const BookingPaymentInfo: FC<IBookingPaymentInfoProps> = ({
  pressedOrderButton,
  setPressedOrderButton,
}) => {
  const {
    guestList,
    reservationId,
    rooms,
    paymentInfo,
    setPaymentInfo,
    bookingOptions,
    errors,
    setErrors,
  } = useNewBooking();

  const { sendRequest } = useAxios();
  const navigate = useNavigate();
  const [purposeOptions, setPurposeOptions] = useState<ValueOption[]>([]);
  const [errorMessage, setErrorMessage] = useState<DeliberateAny>();
  const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);

  const [showIoModal, setShowIoModal] = useState<boolean>(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false);

  const BookingPurposeValidation = Yup.object().shape({
    purposeOption: Yup.string().required(),
    purposeDescription: Yup.string().required(),
    paymentOption: Yup.string().required('Betalingsmåte er påkrevd'),
  });

  const confirmLock = useMutex();

  const validatePurpose = useCallback(async () => {
    try {
      await BookingPurposeValidation.validate(paymentInfo, {
        abortEarly: false,
        context: fieldNames,
      });
      setErrors({});

      if (reservationId && bookingOptions && paymentInfo) {
        const availablePaymentOptions = bookingOptions.availablePaymentOptions;
        let url = '';

        switch (paymentInfo.paymentOption) {
          case availablePaymentOptions[0].identity:
            // Visbook
            url = reservationConfirmWithPaymentProviderUrl(reservationId);
            orderBooking(url);
            break;
          case availablePaymentOptions[1].identity:
            // Delayed payment
            url = reservationConfirmWithDelayedPaymentUrl(reservationId);
            orderBooking(url);
            break;
          case availablePaymentOptions[2].identity:
            setShowIoModal(true);
            break;
          case availablePaymentOptions[3].identity:
            setShowInvoiceModal(true);
            break;
          default:
            return;
        }
      }
    } catch (err: DeliberateAny) {
      const validationErrors = {};
      err.inner.forEach(
        (error) =>
          (validationErrors[error.path] = labelYupError(error, fieldNames)),
      );
      setErrors(validationErrors);
    }
  }, [bookingOptions, paymentInfo, reservationId]);

  const orderBooking = useCallback(
    async (url: string) => {
      if (confirmLock.isLocked()) {
        console.log('Action is already in progress');
        return;
      }

      confirmLock.lock();

      if (bookingOptions) {
        setPaymentIsLoading(true);

        const availablePaymentOptions = bookingOptions.availablePaymentOptions;

        await sendRequest(
          {
            method: 'POST',
            url: url,
            data: {
              purpose: paymentInfo.purposeOption,
              description: paymentInfo.purposeDescription,
            },
          },
          (response) => {
            switch (paymentInfo.paymentOption) {
              case availablePaymentOptions[0].identity:
                // Visbook
                addIFrameToBody(
                  response.paymentComponentSource,
                  setPaymentIsLoading,
                );
                break;
              case availablePaymentOptions[1].identity:
                // Delayed payment
                // TODO: missing delayed time on summary component
                navigate(`/mypage/booking/${response.bookingId}/receipt`);
                break;
            }
          },
        );

        setPaymentIsLoading(false);
      }
      confirmLock.unlock();
    },
    [bookingOptions, paymentInfo, confirmLock],
  );

  useEffect(() => {
    if (pressedOrderButton) {
      validatePurpose();

      setPressedOrderButton(false);
    }
  }, [pressedOrderButton]);

  useEffect(() => {
    if (bookingOptions) {
      setPurposeOptions(
        bookingOptions.purposeOptions.map((item) => {
          return { label: item, value: item } as ValueOption;
        }),
      );
    }
  }, [bookingOptions]);

  useEffect(() => {
    if (reservationId) {
      updateReservation();
    }
  }, [reservationId]);

  const updateForm = (id: string, value: string | number) => {
    const updatedPaymentInfo = {
      ...paymentInfo,
      [id]: value,
    };
    setPaymentInfo(updatedPaymentInfo);
  };

  const updateReservation = async () => {
    setErrorMessage('');
    if (reservationId && rooms) {
      // Guestlist possibly undefined
      const guestListPostponed = guestList?.guestListPostponed;
      const unnamedGuests = guestList?.unnamedGuests;
      const civilGuests = guestList?.civilGuests;

      await sendRequest(
        {
          method: 'PATCH',
          url: reservationUpdateByIdUrl(reservationId),
          data: {
            guestListPostponed: guestListPostponed,
            unnamedGuests: unnamedGuests,
            civilGuests: civilGuests,
            rooms: rooms,
          },
        },
        () => {},
        null,
        (error) => {
          const errorMessage =
            error?.response?.data?.detail ||
            'En ukjent feil har oppstått. Vennligst prøv igjen.';
          setErrorMessage(errorMessage);
        },
      );
    }
  };

  return (
    <Loader isLoading={paymentIsLoading}>
      <div className="fb-new fb-br-container">
        <BookingSearch />

        {errorMessage !== '' && (
          <AlertMessage className="sticky-top" variant={AlertType.Error}>
            {errorMessage}
          </AlertMessage>
        )}

        <div className="payment-info-container">
          <PurposeSelect
            purposeOptions={purposeOptions}
            formholder={paymentInfo}
            updateForm={updateForm}
            bookingError={errors}
            optionFieldName={fieldNames.purposeOption}
            descriptionFieldName={fieldNames.purposeDescription}
          />
          <PaymentInfo
            bookingOptions={bookingOptions}
            bookingError={errors}
            paymentInfo={paymentInfo}
            setPaymentInfo={setPaymentInfo}
          />
          <SmsNotificationCheckbox
            name={'smsOption'}
            label={' '}
            formHolder={paymentInfo}
            updateForm={updateForm}
            errors={errors}
            notificationDomain="Booking"
          />
          <div>
            <p>
              Ved å trykke bestill bekrefter jeg at jeg har lest{' '}
              <a href="/#/info/booking-terms" target="_blank">
                vilkår for overnatting
              </a>
            </p>
          </div>
        </div>

        {showIoModal && (
          <ConfirmWithIoModal
            setShowModal={setShowIoModal}
            setIsLoading={setPaymentIsLoading}
          />
        )}
        {showInvoiceModal && (
          <ConfirmWithInvoiceModal
            setShowModal={setShowInvoiceModal}
            setIsLoading={setPaymentIsLoading}
          />
        )}
      </div>
    </Loader>
  );
};

export default BookingPaymentInfo;
