import "../forms.scss";

import { ChangeEvent } from "react";

import FormErrors from "../FormErrors";
import { IInputProps } from "../Input/Input";

// type is omitted because it is not needed for textarea // henskjold
interface ITextAreaProps extends Omit<IInputProps, "type"> {
  paragraph?: string;
  rows?: number;
  className?: string;
}

const TextArea: React.FC<ITextAreaProps> = ({
  name = "",
  onBlur,
  onChange,
  title,
  paragraph,
  maxLength,
  value = "",
  errors,
  ...props
}) => {
  const handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur(name, event.target.value);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(name, event.target.value);
    }
  };

  const error = errors && errors[name];

  return (
    <label className={`${error ? "invalid" : ""}`}>
      <span>
        {title} {error && <FormErrors rule={error} />}
      </span>
      {paragraph && <p>{paragraph}</p>}
      <textarea
        {...props}
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
      {maxLength && value && <span>{maxLength - value.length} tegn igjen</span>}
    </label>
  );
};

export default TextArea;
