/* eslint react/prop-types: 0 */

import React, { useEffect, useState } from 'react';
import { DeliberateAny } from 'types/DelibrateAny';

import { uiAddUserTagURL, uiRemoveUserTagURL } from '../../api/core/core_api';
import Input from '../../components/form/Input/Input';
import useAxios from '../../hooks/useAxios';

const L3Tags = ({ item }) => {
  const { sendRequest } = useAxios();
  const [tagName, setTagName] = useState('');
  const [createdTags, setCreatedTags] = useState<DeliberateAny[]>([]);
  const [removedTags, setRemovedTags] = useState<DeliberateAny[]>([]);
  const [lockField, setLockField] = useState(false);
  const [sorted, setSorted] = useState<DeliberateAny[]>([]);

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && tagName.trim() !== '') {
      setLockField(true);
      await sendRequest(
        {
          method: 'POST',
          url: uiAddUserTagURL,
          data: {
            itemId: item.Id,
            tagText: tagName,
          },
        },
        () => {
          setCreatedTags((x) => [...x, tagName]);
          setRemovedTags((x) => x.filter((t) => t !== tagName));
          setTagName('');
          setLockField(false);
        },
      );
    }
  };

  const deleteTag = async (id, tag) => {
    setLockField(true);
    await sendRequest(
      {
        method: 'DELETE',
        url: uiRemoveUserTagURL,
        params: {
          itemId: id,
          tagText: tag,
        },
      },
      () => {
        setCreatedTags((x) => x.filter((t) => t !== tag));
        setRemovedTags((x) => [...x, tag]);
        setLockField(false);
      },
    );
  };

  useEffect(() => {
    const sortedTags = [...(item.L3Extra?.Tags || [])].sort();
    setSorted(sortedTags);
  }, [item.L3Extra?.Tags]);

  return (
    <div className="l3-tags">
      <div className="row">
        <strong>Mine tags</strong>
      </div>
      <div className="row">
        <div className="tags-wrapper">
          <div onKeyDown={handleKeyDown}>
            <Input
              disabled={lockField}
              placeholder="Lagre ved å trykke enter"
              value={tagName}
              name={''}
              onChange={(name, value) => setTagName(value)}
            />
          </div>
          <ul className="tags-list">
            {[...sorted, ...createdTags]
              .filter((t) => t.trim() !== '' && !removedTags?.includes(t))
              .map((tag, i) => (
                <li key={`${tag}_${i}`} data-tag={tag}>
                  <span>{tag}</span>
                  <button
                    className="remove-tag"
                    onClick={() => deleteTag(item.Id, tag)}
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default L3Tags;
