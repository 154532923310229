import './ArrivalList.scss';

import List from 'components/List/List';
import ListItem from 'components/ListItem/ListItem';
import { ListColumnType } from 'types/listTypes';
import { v4 as uuidv4 } from 'uuid';

const ArrivalList = ({ arrivals }) => {
  const arrivalsListColumns: ListColumnType[] = [
    {
      label: 'Navn',
      name: 'guestName',
      className: 'arrival-guestname',
    },
    {
      label: 'Telefon',
      name: 'guestPhone',
      className: 'arrival-guestphone',
    },
    {
      label: (
        <span dangerouslySetInnerHTML={{ __html: 'Innsjekk&shy;status' }} />
      ),
      name: 'checkinStatus',
      className: 'arrival-checkin',
    },
    {
      label: 'Opphold til',
      name: 'endDate',
      className: 'arrival-enddate',
    },
    {
      label: 'Bygg-Rom',
      name: 'buildingIdRoomName',
      className: 'arrival-roomname',
    },
    {
      label: 'Forlegningstype',
      name: 'accommodationType',
      className: 'arrival-accommodationtype',
    },
  ];

  const renderItem = (item) => (
    <ListItem key={uuidv4()} columns={arrivalsListColumns} item={item} />
  );

  const returnItem = () => (
    <div id="arrival-list-container">
      <div className="arrival-list">
        <List
          items={arrivals}
          columns={arrivalsListColumns}
          renderItem={renderItem}
          noItemsMessage={'Ingen ankomster for søket'}
        />
      </div>
    </div>
  );
  return returnItem();
};

export default ArrivalList;
