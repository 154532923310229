import './RenderHtmlTextField.scss';

import Accordion from 'components/Accordian/Accordion';
import { FC, Fragment } from 'react';
import { HtmlTextField } from 'types/BookingTypes/arenaTypes';

interface RenderHtmlTextFieldProps {
  textArea: HtmlTextField[];
  smallText?: boolean;
  displayAsAccordion?: boolean;
}

const RenderHtmlTextField: FC<RenderHtmlTextFieldProps> = ({
  textArea,
  smallText = true,
  displayAsAccordion = false,
}) => {
  const renderCorrectTag = (field: HtmlTextField, index: number) => {
    if (field.type === 0) {
      return (
        <div key={index} className="fb-html-header">
          <h2 className={`${smallText ? 'subtitle-s' : 'subtitle-m'}`}>
            {field.text}
          </h2>
        </div>
      );
    }

    if (field.type === 1) {
      return (
        <div key={index} className="fb-html-paragraph">
          <strong className={`${smallText ? 'body-bold-s' : 'body-bold-m'}`}>
            {field.text}
          </strong>
        </div>
      );
    }

    return (
      <div key={index} className="fb-html-text">
        {field.text.split('\n').map((line, i) => {
          if (line === '') {
            return <br key={i} />;
          }

          return (
            <p key={i} className={`${smallText ? 'body-s' : 'body-m'}`}>
              {line}
            </p>
          );
        })}
      </div>
    );
  };

  const mapTextAreaToAccordionMap = () => {
    const accordionMap = new Map<string, HtmlTextField[]>();
    let currentKey = '';
    textArea.forEach((htmlField) => {
      if (htmlField.type === 0) {
        currentKey = htmlField.text;
        accordionMap.set(currentKey, []);
      } else {
        const currentList = accordionMap.get(currentKey);
        if (currentList) {
          currentList.push(htmlField);
          accordionMap.set(currentKey, currentList);
        }
      }
    });
    return accordionMap;
  };

  if ((textArea?.length ?? 0) === 0) {
    return <div>Mangler informasjon...</div>;
  }

  if (displayAsAccordion) {
    return (
      <div>
        {Array.from(mapTextAreaToAccordionMap(), ([key, value], i) => {
          return (
            <Accordion key={i} title={key}>
              {value.map((htmlField, j) => {
                return renderCorrectTag(htmlField, j);
              })}
            </Accordion>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      {textArea.map((ci, idx) => {
        return renderCorrectTag(ci, idx);
      })}
    </div>
  );
};

export default RenderHtmlTextField;
